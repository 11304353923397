import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    DateFilterType,
    IFinancesInitialState,
    SelectEmployeeType,
    SpecialistRoleType,
} from "./finances.types";

const financesInitialState: IFinancesInitialState = {
    showAddCardModal: false,
    selectEmployee: null,
    currentPage: 1,
    dateFilterFrom: null,
    dateFilterTo: null,
    specialistRole: null,
};

const financesSlice = createSlice({
    name: "finances",
    initialState: financesInitialState,
    reducers: {
        setShowAddCardModal: (state, action: PayloadAction<boolean>) => {
            state.showAddCardModal = action.payload;
        },
        setSelectEmployee: (
            state,
            action: PayloadAction<SelectEmployeeType>
        ) => {
            state.selectEmployee = action.payload;
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        },
        setDateFilter: (
            state,
            action: PayloadAction<{
                type: "dateFilterFrom" | "dateFilterTo";
                value: DateFilterType;
            }>
        ) => {
            state[action.payload.type] = action.payload.value;
        },
        setSpecialistRole: (
            state,
            action: PayloadAction<SpecialistRoleType>
        ) => {
            state.specialistRole = action.payload;
        },
    },
});

export const financesReducer = financesSlice.reducer;
export const financesActions = financesSlice.actions;

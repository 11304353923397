import { ISocketClient } from "./AppSocketProvider.types";

export const useLeaveSocketClient = (socketClient: ISocketClient) => {
    const { client, channel } = socketClient;
    const isExistSocketClient = client && channel;

    return () => {
        if (isExistSocketClient) {
            client.leave(channel);
            client.disconnect();
            socketClient = { client: null, channel: null };
        }
    };
};

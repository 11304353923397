import { request } from "../request";

export const getNotificationsList = ({
    params,
}: {
    params?: Record<string, number>;
}) =>
    request({
        url: `/notifications/list`,
        method: "GET",
        params,
    });

export const viewNotification = (notificationId: string | number) =>
    request({
        url: `/notifications/${notificationId}/view`,
        method: "POST",
    });

export const viewAllNotification = () =>
    request({
        url: `/notifications/view_all`,
        method: "POST",
    });

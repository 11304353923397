import React, { useEffect, useState } from "react";
import { TranslationObject } from "../../models/translationModal";
import { useNavigate } from "react-router-dom";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { setCookie } from "../../untils/cookies";
import {
    checkIsTablet,
    checkIsMobile,
} from "../../store/directories/selectors";
import cn from "classnames";
import { useLogout } from "../../hooks/useLogout/useLogout";

interface SpecialistDraftNotidicationProps {
    entities?: TranslationObject;
    id: number | string;
}

export const SpecialistDraftNotidication: React.FC<SpecialistDraftNotidicationProps> = ({
	entities,
    id
}) => {
    const navigate = useNavigate();
    const currentLanguage = getCurrentLanguage();
    const [showMore, setShowMore] = useState(true);
    const logout = useLogout();

    const handleToRegistration = () => {
        id && setCookie('draft', String(id));

        logout(false);
        localStorage.removeItem('seenDraftPopUp');
        navigate(`/${currentLanguage}/specialist/registration`);
    }

    const isMobile = checkIsMobile();
    const isTablet = checkIsTablet();

    useEffect(() => {
        setShowMore(!(isMobile || isTablet));
    }, [isMobile, isTablet])

    return (
        <>
            <div className="cookies specialist-draft-notification">
                <div className="cookies__container">
                    <div className="cookies__container-text">
                        <p className="cookies__text">
                            <span className="cookies__text--short" dangerouslySetInnerHTML={{ __html: (entities?.specialist_details_draft_notice_short?.value || "") }}></span>

                            {(isMobile || isTablet) &&
                                <a
                                    className="link link--underline cookies__link-more"
                                    onClick={() => setShowMore(!showMore)}
                                >
                                    {!showMore
                                        ? entities?.common_expand?.value
                                        : entities?.common_collapse?.value}
                                </a>
                            }
                        </p>
                        <p className={cn("cookies__text cookies__text--expanded", { ["show"]: showMore, })}
                            dangerouslySetInnerHTML={{ __html: entities?.specialist_details_draft_notice_extended?.value || "" }}
                        />
                    </div>

                    <button
                        className="btn btn--rounded cookies__btn btn--green"
                        type="button"
                        onClick={handleToRegistration}
                    >
                        {entities?.registration_link_value?.value}
                    </button>
                </div>
            </div>
        </>
    );
}
import classNames from "classnames";
import React from "react";

type SlideProps = {
  children: React.ReactNode;
  isSpecialistPage?: boolean;
}

const Slide: React.FC<SlideProps> = ({ children, isSpecialistPage = false }) => {
  return (
    <div className={classNames("slide-block", {"slide-block--specialist": isSpecialistPage})}>
      {children}
    </div>
  )
}

export default Slide;
import React from "react";
import DateInput from "../DateInput";
import Input from "../Form/Input";
import { convertDateToJSDate } from "../../untils";
import { TranslationObject } from "../../models/translationModal";
import { createOrderState } from "../../store/order/createOrder.slice";
import { DateTime } from "luxon";

interface Props {
    entities?: TranslationObject;
    startAt?: string | null;
    endAt?: string | null;
    handleChange: (value: Date | string, name: keyof createOrderState) => void;
    allowSelectionOldDates?: boolean;
}

const DateStartAndEnd: React.FC<Props> = ({
    entities,
    startAt,
    handleChange,
    endAt,
    allowSelectionOldDates = true,
}) => {
    const onChange = (value: Date | string, type: keyof createOrderState) => {
        if (
            type === "started_at" &&
            endAt &&
            value &&
            new Date(value).getDay() >= new Date(endAt).getDay()
        ) {
            return;
        }
        handleChange(value, type);
    };

    const maxDate = endAt
        ? DateTime.fromISO(endAt)
              .minus({
                  day: 1,
              })
              .toJSDate()
        : undefined;

    const minDate = startAt
        ? DateTime.fromISO(startAt)
              .plus({
                  day: 1,
              })
              .toJSDate()
        : null;

    return (
        <>
            <div className="form__row form__row--big-margin">
                <fieldset>
                    <legend>{entities?.order_select_date?.value}</legend>
                    <DateInput
                        dateFormat="dd.MM.yyyy"
                        input={
                            <Input
                                placeholder=""
                                icon={{
                                    type: "calendar",
                                    width: 24,
                                    height: 24,
                                    className: "date-field__icon",
                                }}
                                classes={["date-start__choice-date-input"]}
                            />
                        }
                        value={startAt ? convertDateToJSDate(startAt) : null}
                        onChange={(value: Date | string) =>
                            onChange(value, "started_at")
                        }
                        allowSelectionOldDates={allowSelectionOldDates}
                    />
                </fieldset>
            </div>
            <div className="form__row">
                <fieldset>
                    <legend>{entities?.order_select_period?.value}</legend>
                    <div className="form__flex">
                        <div className="date-field form__field form__field--small-margin custom-input--small">
                            <DateInput
                                onChange={(value: Date | string) =>
                                    onChange(value, "started_at")
                                }
                                dateFormat="dd.MM.yyyy"
                                value={
                                    startAt
                                        ? convertDateToJSDate(startAt)
                                        : null
                                }
                                maxDate={maxDate}
                                input={
                                    <Input
                                        placeholder=" "
                                        span={{
                                            text: entities?.order_distance_from
                                                ?.value,
                                            className: "date-field__label",
                                        }}
                                        icon={{
                                            type: "calendar",
                                            width: 24,
                                            height: 24,
                                            className: "date-field__icon",
                                        }}
                                        classes={[
                                            "date-field form__field form__field--small-margin custom-input--small",
                                        ]}
                                    />
                                }
                                allowSelectionOldDates={allowSelectionOldDates}
                            />
                        </div>
                        <div className="date-field form__field form__field--small-margin custom-input--small">
                            <DateInput
                                dateFormat="dd.MM.yyyy"
                                onChange={(value: Date | string) =>
                                    onChange(value, "ended_at")
                                }
                                value={
                                    endAt ? convertDateToJSDate(endAt) : null
                                }
                                minDate={minDate}
                                input={
                                    <Input
                                        placeholder=" "
                                        span={{
                                            text: entities?.order_distance_to
                                                ?.value,
                                            className: "date-field__label",
                                        }}
                                        icon={{
                                            type: "calendar",
                                            width: 24,
                                            height: 24,
                                            className: "date-field__icon",
                                        }}
                                        classes={[
                                            "date-field form__field form__field--small-margin custom-input--small",
                                        ]}
                                    />
                                }
                                allowSelectionOldDates={allowSelectionOldDates}
                            />
                        </div>
                    </div>
                </fieldset>
            </div>
        </>
    );
};

export default DateStartAndEnd;

import { useAppDispatch } from "../../hooks/useAppDispatch";
import { util } from "../../store/user/user.api";

export const useUpdateUnviewedNotifications = () => {
    const dispatch = useAppDispatch();

    return (unviewedNotificationsCount: number) => {
        const isValidCount =
            typeof unviewedNotificationsCount === "number" &&
            !isNaN(unviewedNotificationsCount);

        if (!isValidCount) return;

        dispatch(
            util.updateQueryData("getProfile", undefined, (profile) => {
                profile.unviewed_notifications_count =
                    unviewedNotificationsCount;
            })
        );
    };
};

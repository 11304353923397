import Icon from "../Icon";
import React from "react";
import { Image } from "../../models/userModels";

interface Props {
    width?: number;
    height?: number;
    avatar?: Image;
    isSquare?: boolean;
    withOutPicture?: boolean
}

const Avatar: React.FC<Props> = ({
    avatar,
    width = 64,
    height = 64,
    isSquare = false,
    withOutPicture = false
}) => {

    if (avatar?.original_url && !withOutPicture) {
        return <picture>
            <img src={avatar?.original_url} alt="avatar" />
        </picture>
    } else if (withOutPicture && avatar?.original_url) {
        return <img src={avatar?.original_url} alt="avatar" />
    } else {
        return <Icon
            type={isSquare ? "square_with_photo" : "camera_with_ellipse"}
            width={width}
            height={height}
        />
    }
};

export default Avatar;

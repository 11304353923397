import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { directoriesActions } from "../store/directories/directories.slice";
import { registrationActions } from "../store/registration/registration.slise";
import { userActions } from "../store/user/user.slice";
import { filterOrdersActions } from "../store/orders/filterOrders.slice";
import { editOrderActions } from "../store/order/editOrder.slice";
import { createOrderActions } from "../store/order/createOrder.slice";
import { chatActions } from "../store/chat/chat.slice";
import { financesActions } from "../store/finances/finances.slice";
import { feedBackActions } from "../store/feedback/feebback.slice";
import { supportActions } from "../store/support/support.slice";
import { headerActions } from "../store/header/header.slice";

const actions = {
    ...directoriesActions,
    ...registrationActions,
    ...userActions,
    ...filterOrdersActions,
    ...editOrderActions,
    ...createOrderActions,
    ...chatActions,
    ...financesActions,
    ...feedBackActions,
    ...supportActions,
    ...headerActions,
};

export const useActions = () => {
    const dispatch = useDispatch();
    return bindActionCreators(actions, dispatch);
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IChatSliceInitialState {
    isSearch: boolean;
    searchPlaceholder?: string;
}

const chatSliceInitialState: IChatSliceInitialState = {
    isSearch: false,
    searchPlaceholder: ""
};

export const headerSlice = createSlice({
    name: "header",
    initialState: chatSliceInitialState,
    reducers: {
        resetHeaderState: () => chatSliceInitialState,
        setHeaderStateSearch: (state, action: PayloadAction<boolean>) => {
            state.isSearch = action.payload;
        },
        setHeaderStateSearchPlaceholder: (state, action: PayloadAction<string>) => {
            state.searchPlaceholder = action.payload;
        }
    },
});

export const headerReducer = headerSlice.reducer;
export const headerActions = headerSlice.actions;

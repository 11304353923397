import React, { useState } from "react";
import StepTwo from "./StepTwo";
import StepOne from "./StepOne";
import SubMenu from "../../components/SubMenu";
import { ComponentProps } from "../../models/models";
import RegisterForm from "../../components/Form/RegisterForm";
import { ProfilePayload } from "../../models/userModels";

interface Steps {
    one: Element;
    two: Element;
}

interface Props extends ComponentProps {
    profile?: ProfilePayload
}

const Auth: React.FC<ComponentProps> = ({
    isCustomerOnUrl,
    isSpecialistOnUrl,
    currentLanguage,
    entities,
    profile
}) => {

    const [step, setStep] = useState<string>("one");

    const steps = {
        one: (
            <StepOne
                currentLanguage={currentLanguage}
                entities={entities}
                setStep={setStep}
            />
        ),
        two: (
            <StepTwo
                currentLanguage={currentLanguage}
                entities={entities}
                changeStep={setStep}
            />
        ),
    };

    return (
        <main>
            <SubMenu entities={entities} profile={profile} />
            <RegisterForm
                isCustomerOnUrl={isCustomerOnUrl ?? false}
                isSpecialistOnUrl={isSpecialistOnUrl ?? false}
            >
                {steps[step as keyof Steps]}
            </RegisterForm>
        </main>
    );
};

export default Auth;

export const checkPhoneInString = (value: string): boolean =>
    /((\+)?\b(8|38|380|375|357)?([()\d\s-_']{2,6}))([\d\s-_']{7,14})|(^8|7|\+7)((\d{10})|(\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}))/gm?.test(
        value
    );

export const checkEmailInString = (value: string): boolean => /([a-zA-Z0-9._-]+@([a-zA-Z0-9_-]+\.)+[a-zA-Z0-9_-]+)/gi?.test(
    value
)

export const checkSocialLinkInString = (value: string): boolean => /(http:\/\/|https:\/\/|www.|vk|instagram|facebook|ru-ru.facebook|tiktok|tlgrm|t|viber:|wa|twitter)\.(com|ru|me)\/[a-zA-Z0-9._-]+/gi?.test(
    value
);

export const checkAtInString = (value: string): boolean => /(@)/gi?.test(
    value
)

export const checkStringForForbiddenWords = (value: string): boolean => {
    return checkPhoneInString(value) || checkEmailInString(value) || checkSocialLinkInString(value) || checkAtInString(value)
}

const utmWords = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'].join('|')

export const checkUtm = (): boolean => {
    return new RegExp('\\b' + utmWords + '\\b').test(window.location.search)
}
export const getCookie = (name: string) => {
    const matches = document.cookie.match(
        // eslint-disable-next-line
        new RegExp(
            "(?:^|; )" +
                // eslint-disable-next-line
                name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
                "=([^;]*)"
        )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

interface optionsProps {
    name: string;
    value: string;
    object?: any;
    path?: string;

    [key: string]: any;
}

export const deleteCookie = (name: string) => {
    setCookie(name, "", {
        "max-age": -1,
    });
};

export const setCookie = (name: string, value: string, object?: any) => {
    // eslint-disable-next-line
    let options: optionsProps = {
        path: "/",
        'max-age': 3600 * 24 * 31 * 12,
        ...object,
    };

    if (options.expires instanceof Date) {
        options.expires = object?.expires.toUTCString();
    }

    let updatedCookie =
        encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (const key in options) {
        updatedCookie += "; " + key;
        const optionValue = options[key];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }
    document.cookie = updatedCookie;
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { changeHeaders } from "../request";
import {
    attachNewBankCard,
    deleteBankCard,
    getBankCards,
    getPayments,
} from "./api";
import {
    AttachNewBankCardRequestData,
    AttachNewBankCardResponse,
    DeleteBankCardRequestData,
    DeleteBankCardResponse,
    getBankCardsResponse,
    GetPaymentsParams,
    GetPaymentsPayload,
    GetPaymentsResponse,
    IBankCard,
} from "../../models/financesModel";
import { EmptyPayload } from "../models";

export const financesApi = createApi({
    reducerPath: "finances/api",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
        prepareHeaders: (headers, { getState }) =>
            changeHeaders(headers, { getState }),
    }),
    tagTypes: ["Finances", "Localized"],
    endpoints: (build) => ({
        getBankCards: build.query<IBankCard[], void>({
            query: () => getBankCards(),
            transformResponse: (response: getBankCardsResponse) =>
                response.payload,
            providesTags: [{ type: "Finances", id: "CARDS" }],
        }),
        attachNewBankCard: build.mutation<
            EmptyPayload,
            AttachNewBankCardRequestData
        >({
            query: (body) => attachNewBankCard(body),
            transformResponse: (response: AttachNewBankCardResponse) =>
                response.payload,
            invalidatesTags: [{ type: "Finances", id: "CARDS" }],
        }),
        deleteBankCard: build.mutation<EmptyPayload, DeleteBankCardRequestData>(
            {
                query: (body) => deleteBankCard(body),
                transformResponse: (response: DeleteBankCardResponse) =>
                    response.payload,
                invalidatesTags: [{ type: "Finances", id: "CARDS" }],
            }
        ),
        getPayments: build.query<GetPaymentsPayload, GetPaymentsParams>({
            query: (params) => getPayments(params),
            transformResponse: (response: GetPaymentsResponse) =>
                response.payload,
            providesTags: [
                { type: "Finances", id: "PAYMENTS" },
                { type: "Localized" },
            ],
        }),
    }),
});

export const {
    util,
    endpoints: financesEndpoints,
    useAttachNewBankCardMutation,
    useDeleteBankCardMutation,
} = financesApi;

import React, { ReactNode, useState } from "react";
import { useLogout } from "../../hooks/useLogout/useLogout";
import Modal from "../Modal";
import { TranslationObject } from "../../models/translationModal";

interface LogoutModalButtonProps {
	entities?: TranslationObject;
	children?: ReactNode;
	isSpecialist: boolean;
	classes?: string[];
	text?: string;
	handleClick?: (isPrivate?: boolean) => void;
}

const LogoutModalButton: React.FC<LogoutModalButtonProps> = (props) => {
	const { entities, isSpecialist, classes, children, text = "", handleClick } = props;
	const logout = useLogout();
	const [visible, setVisible] = useState<boolean>(false);

	const handleLogout = () => {
		logout();
		setVisible(false);
	};

	const closeModal = () => {
		setVisible(false);
	};

	const createOrder = () => {
		if (isSpecialist) {
			setVisible(true);
			return;
		} else {
			handleClick?.(false);
		}
	};

	return (
		<>
			<div
				onClick={createOrder}
				className={`modal__button--open ${classes?.join(" ")}`}
			>
				{ children || text }
			</div>
			<Modal
				title={entities?.common_offer_order?.value}
				description={entities?.profile_logout_specialist?.value}
				visible={visible}
				onClose={closeModal}
			>
				<div className="modal__content-buttons">
					<button
						className="btn btn--transparent"
						type="button"
						onClick={closeModal}
					>
						{entities?.common_cancel_button?.value}
					</button>
					<button
						className="btn modal__content-button btn--bg-green"
						type="button"
						onClick={handleLogout}
					>
						{entities?.profile_logout_button_ok?.value}
					</button>
				</div>
			</Modal>
		</>
	);
};

export default LogoutModalButton;

import { request } from "../request";
import { IdProps } from "../models";
import { makeReviewPayData, makeReviewShareData } from "../../models/reviewModel";

export const getReviewsBySpecialistId = ({
    id,
    params,
}: {
    params?: Record<string, number>;
    id: number;
}) =>
    request({
        url: `/specialists/${id}/reviews`,
        params,
    });

export const getReviewsByCustomerId = ({
    id,
    params,
}: {
    params?: Record<string, number>;
    id: number;
}) =>
    request({
        url: `/customers/${id}/reviews`,
        params,
    });

export const readReview = ({ id }: IdProps) =>
    request({
        url: `/reviews/${id}/read`,
        method: "POST",
    });

export const getReviews = ({ params }: { params?: Record<string, number> }) =>
    request({
        url: `/reviews`,
        method: "GET",
        params,
    });

export const getTranslateReview = (id: number) =>
    request({
        url: `/reviews/${id}/translate`,
        method: "POST",
    });

export const getSpecialistPageTranslate = (id: number) =>
    request({
        url: `/specialists/${id}/translate`,
        method: "POST",
    });


export const makeReviewPay = ({ reviewId, body }: makeReviewPayData) =>
    request({
        url: `/reviews/${reviewId}/share`,
        body,
        method: "POST",
    });


export const share = ({ reviewId, language }: makeReviewShareData) =>
    request({
        url: `/share/review/${reviewId}/${language}`,
        method: "GET",
    });
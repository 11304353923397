import React from "react";
import Icon from "../../../../components/Icon";
import { IBankCard } from "../../../../models/financesModel";

interface BankCardProps {
    card: IBankCard;
    deleteBankCard?: (cardId: string) => unknown;
}

export const BankCard: React.FC<BankCardProps> = ({ card, deleteBankCard }) => {
    const { last_four, brand, id } = card || {};

    return (
        <div className="my-finances__bank-card bank-card">
            <div className="bank-card__info">
                <Icon
                    className="bank-card__info-icon__card"
                    type={"bank-card"}
                    width={40}
                    height={30}
                />
                {deleteBankCard && (
                    <button
                        className="bank-card__info--btn"
                        onClick={() => deleteBankCard(id)}
                    >
                        <Icon type={"basket"} width={18} height={22} />
                    </button>
                )}
            </div>
            <div className="bank-card__info">
                <p className="bank-card__info-card__number">
                    <span>****</span>
                    {last_four}
                </p>
                {brand === "visa" && (
                    <Icon type={"visa"} width={40} height={28} />
                )}
                {brand === "mastercard" && (
                    <Icon type={"master-card"} width={40} height={28} />
                )}
            </div>
        </div>
    );
};

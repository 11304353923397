import classNames from "classnames";
import Icon from "../../components/Icon";
import React, { ReactNode, useState } from "react";

interface Props {
    children: ReactNode;
    textButton: string;
    defaultIsOpen?: boolean;
}

const AccordionView: React.FC<Props> = ({
    children,
    textButton,
    defaultIsOpen,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen || false);

    return (
        <>
            <button
                className={classNames("accordion-form__item-subtitle", {
                    "is-open": isOpen,
                })}
                onClick={() => setIsOpen(!isOpen)}
                type="button"
            >
                {textButton}
                <Icon type={"arrow_down"} width={9} height={18} />
            </button>
            {isOpen && children}
        </>
    );
};

export default AccordionView;

import React from "react";

import Advertising from "../../components/Advertising";
import { BlocksBaseObject } from "../../models/homePageModel";
import { TranslationObject } from "../../models/translationModal";
import Heading from "../../components/Heading/index";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { isSpecialistUrl } from "../../store/directories/selectors";

import pictureServicesMobilePng from "../../img/content/poster/image3-mobile.png";
import pictureServicesMobilePng2x from "../../img/content/poster/image3-mobile-2x.png";
import pictureServicesMobileWebp from "../../img/content/poster/image3-mobile.webp";
import pictureServicesMobileWebp2x from "../../img/content/poster/image3-mobile-2x.webp";
import pictureServicesTabletPng from "../../img/content/poster/image3-tablet.png";
import pictureServicesTabletPng2x from "../../img/content/poster/image3-tablet-2x.png";
import pictureServicesTabletWebp from "../../img/content/poster/image3-tablet.webp";
import pictureServicesTabletWebp2x from "../../img/content/poster/image3-tablet-2x.webp";
import pictureServicesDesktopPng from "../../img/content/poster/image3-desktop.png";
import pictureServicesDesktopPng2x from "../../img/content/poster/image3-desktop-2x.png";
import pictureServicesDesktopWebp from "../../img/content/poster/image3-desktop.webp";
import pictureServicesDesktopWebp2x from "../../img/content/poster/image3-desktop-2x.webp";

import pictureServicesMobileBg from "../../img/content/poster/bg3-mobile.svg";
import pictureServicesTabletBg from "../../img/content/poster/bg3-tablet.svg";
import pictureServicesDesktopBg from "../../img/content/poster/bg3-desktop.svg";

const BaseBody: React.FC<{
    blocks: BlocksBaseObject;
    entities?: TranslationObject;
}> = ({ blocks, entities }) => {
    //скрыли блок по требованию заказчика
    const currentLanguage = getCurrentLanguage();
    const isSpecialist = isSpecialistUrl();
    const servicesPicture = {
        picture: {
            srcsets: [
                {
                    media: '(min-width: 1024px)',
                    type: 'image/webp',
                    srcSet: pictureServicesDesktopWebp + ', ' + pictureServicesDesktopWebp2x + ' 2x'
                },
                {
                    media: '(min-width: 1024px)',
                    type: 'image/png',
                    srcSet: pictureServicesDesktopPng + ', ' + pictureServicesDesktopPng2x + ' 2x'
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/webp',
                    srcSet: pictureServicesTabletWebp + ', ' + pictureServicesTabletWebp2x + ' 2x'
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/png',
                    srcSet: pictureServicesTabletPng + ', ' + pictureServicesTabletPng2x + ' 2x'
                },
                {
                    type: 'image/webp',
                    srcSet: pictureServicesMobileWebp + ', ' + pictureServicesMobileWebp2x + ' 2x'
                }
            ],
            src: pictureServicesMobilePng,
            srcSet: pictureServicesMobilePng2x + ' 2x'
        }
    }

    const servicesBg = {
        bg: {
            srcsets: [
                {
                    media: '(min-width: 1024px)',
                    type: 'image/svg+xml',
                    srcSet: pictureServicesDesktopBg
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/svg+xml',
                    srcSet: pictureServicesTabletBg
                },
            ],
            src: pictureServicesMobileBg
        }
    }

    const servicesButtons = (
        <>
            <a
                className="btn btn--bg-green"
                href={blocks?.specialist_button_title?.value?.url}
            >
                {blocks?.specialist_button_title?.value?.title}
            </a>
        </>
    );

    return (
        <div className="page__grid">
            {/*  <section className="section order-stats">
                <CountsBlock
                    satisfied_customers_count={
                        blocks?.satisfied_customers_count?.value
                    }
                    satisfied_customers_title={
                        blocks?.satisfied_customers_title?.value
                    }
                    specialists_count={blocks?.specialists_count?.value}
                    specialists_title={blocks?.specialists_title?.value}
                    deals_count={blocks?.deals_count?.value}
                    deals_title={blocks?.deals_title?.value}
                />
            </section>*/}
            <section className="useful-info section">
                <div className="useful-info__wrapper no-scrollbar">
                    <h2 className="title title--h2">
                        {blocks?.useful_ad_title?.value}
                    </h2>
                    <ul className="useful-info__list">
                        <Advertising
                            typeAdvertising={"thirdPartiesMain"}
                            entities={entities}
                            isHorizontal={!isSpecialist}
                        />
                    </ul>
                </div>
            </section>
            <section className="bonuses section">
                <div className="bonuses__content">
                    <div className="bonuses__content-text">
                        <h2 className="title title--h2">
                            {blocks?.bonus_program_title?.value}
                        </h2>

                        <div
                            className="bonuses__text"
                            dangerouslySetInnerHTML={{
                                __html: blocks?.bonus_program_text?.value,
                            }}
                        />
                        {!!blocks?.bonus_program_link && <a
                            className="btn btn--transparent"
                            href={blocks?.bonus_program_link?.value?.url}
                            target="_blank"
                        >
                            {blocks?.bonus_program_link?.value?.title}
                        </a>}
                    </div>
                    <div className="bonuses__background-image-wrapper">
                        {/* <picture className="bonuses__picture">
                            {picture.srcsets?.map((item, key) => (
                                <source
                                    key={key}
                                    media={item.media}
                                    type={item.type}
                                    srcSet={item.srcSet}
                                />
                            ))}
                            <img
                                src={picture.src}
                                srcSet={picture.srcSet}
                                width="600"
                                height="600"
                                alt=""
                            />
                        </picture> */}
                        {blocks?.bonus_program_image?.value && <img
                            src={blocks?.bonus_program_image?.value}
                            alt={blocks?.bonus_program_image?.title}
                        />}
                    </div>
                </div>
            </section>
            <Heading
                isSpecialist={isSpecialist}
                className='promo-services'
                title={blocks?.specialist_title?.value}
                text={blocks?.specialist_text?.value}
                buttons={blocks?.specialist_button_title && servicesButtons}
                picture={servicesPicture.picture}
                bg={servicesBg.bg}
                img={blocks?.specialist_image?.value}
                titleType="text"
            />
            <section className="specialists">
                <div className="specialists__wrapper no-scrollbar">
                    <h2 className="visually-hidden">
                        {entities?.finances_all_specialists?.value}
                    </h2>
                    <Advertising
                        typeAdvertising={"specialistsMainPage"}
                        entities={entities}
                    />
                    {/* <Advertising
                        typeAdvertising={"specialists"}
                        entities={entities}
                    /> */}
                </div>
            </section>
        </div>
    );
};
export default BaseBody;

import React from "react";
import Input, { InvalidFieldsProps } from "../Input";

interface IPhoneInput {
    phone: string;
    placeholder?: string;
    changePhone: (inputValue: string) => unknown;
    phoneIsConfirmed?: boolean;
    buttonPhoneConfirmedName?: string;
    clickPhoneConfirmed?: (
        event: React.MouseEvent<HTMLButtonElement>
    ) => unknown;
    phoneInputError?: unknown;
    phoneInputInvalidFields?: InvalidFieldsProps;
}

interface ProfilePhoneProps {
    legend: string;
    phoneInput: IPhoneInput;
    error: any;
    invalidFields?: InvalidFieldsProps;
    fieldName?: string;
}

export const ProfilePhone: React.FC<ProfilePhoneProps> = (props) => {
    const { legend, phoneInput, error, invalidFields, fieldName = 'phone' } =
        props || {};
    const {
        phone,
        placeholder = "",
        changePhone,
        phoneIsConfirmed,
        clickPhoneConfirmed,
        buttonPhoneConfirmedName,
    } = phoneInput || {};

    return (
        <>
            <div className="profile__fieldset profile__phone">
                <fieldset>
                    <legend>{legend}{legend && <span className="asterisk">*</span>}</legend>
                    <div className="form__row">
                        <div className="contacts-field is-valid">
                            <Input
                                classes={phoneIsConfirmed ? ["is-valid"] : []}
                                type="tel"
                                value={phone}
                                onChange={changePhone}
                                id={fieldName}
                                error={error}
                                invalidFields={invalidFields}
                                name={fieldName}
                                placeholder={placeholder}
                                icon={
                                    (phoneIsConfirmed && clickPhoneConfirmed)
                                        ? {
                                            type: "valid",
                                            className: "valid-icon",
                                            width: 24,
                                            height: 24,
                                        }
                                        : null
                                }
                                button={
                                    !phoneIsConfirmed && clickPhoneConfirmed && (
                                        <button
                                            className="btn-reset confirm-field__btn"
                                            onClick={clickPhoneConfirmed}
                                        >
                                            {buttonPhoneConfirmedName}
                                        </button>
                                    )
                                }
                            />
                        </div>
                    </div>
                </fieldset>
            </div>
        </>
    );
};

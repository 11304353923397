import React from "react";

interface Props {
    validationError: any;
    currentItem?: number | null | string;
    currentItems?: number[] | null | string;
    isMultiple?: boolean;
}

const ValidationError: React.FC<Props> = ({
    validationError,
    currentItem,
    currentItems,
    isMultiple,
}) => {
    if (!validationError && isMultiple ? currentItem : currentItems?.length) {
        return null;
    }

    return (
        <div className="new-order-start__field-error">{validationError}</div>
    );
};

export default ValidationError;

import AccordionView from "../AccordionView";
import WishesOrder from "../../../components/WishesOrder";
import React from "react";
import { TranslationObject } from "../../../models/translationModal";
import { getItemEditOrder } from "../../../store/order/selectors";
import { DESCRIPTION, PRICE } from "../../../store/order/constant";
import { useActions } from "../../../hooks/actions";
import { editOrderState } from "../../../store/order/editOrder.slice";

interface Props {
    entities?: TranslationObject;
    invalidFields: any;
    error: any;
}

const WishesEditOrder: React.FC<Props> = ({
    entities,
    invalidFields,
    error,
}) => {
    const { updateEditOrderField } = useActions();
    const price = getItemEditOrder(PRICE);
    const description = getItemEditOrder(DESCRIPTION);

    const onChange = (value: string | number, name: keyof editOrderState) => {
        updateEditOrderField({
            name,
            value,
        });
    };

    return (
        <AccordionView
            textButton={entities?.order_additional_data_title?.value || ""}
        >
            <div className="accordion-form__item-content">
                <div className="accordion-form__item-wrap">
                    <WishesOrder
                        entities={entities}
                        handleInputChangePrice={(value) =>
                            onChange(value, PRICE)
                        }
                        error={error}
                        price={price}
                        invalidFields={invalidFields}
                        handleInputChangeDescription={(value) =>
                            onChange(value, DESCRIPTION)
                        }
                        description={description}
                    />
                </div>
            </div>
        </AccordionView>
    );
};

export default WishesEditOrder;

import React, { useState } from "react";
import AccordionView from "../AccordionView";
import RepeatOrder from "../../../components/RepeatOrder";
import {
    getDays,
    getItemEditOrder,
    getWeekdays,
} from "../../../store/order/selectors";
import { REPEAT_DAY, REPEAT_RANGE } from "../../../store/order/constant";
import { useActions } from "../../../hooks/actions";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import { TranslationObject } from "../../../models/translationModal";

interface Props {
    entities?: TranslationObject;
}

const RepeatEditOrder: React.FC<Props> = ({ entities }) => {
    const { updateEditOrderField } = useActions();
    const currentLanguage = getCurrentLanguage();
    const [repeatDay, setRepeatDay] = useState<{
        value: number;
        label: number | string;
    } | null>(null);
    const repeatRange = getItemEditOrder(REPEAT_RANGE);
    const weekItems = getWeekdays(currentLanguage);
    const weekdays = weekItems?.map((day, key) => ({
        value: key + 1,
        label: day,
    }));
    const days = getDays();

    const handleChange = (value: string) => {
        if (value === "repeat_week_day") {
            setRepeatDay(weekdays[0]);
            changeRepeatDay(1);
        }
        if (value === "repeat_month_day") {
            setRepeatDay(days[0]);
            changeRepeatDay(1);
        }
        changeRepeatDay(null);
        updateEditOrderField({
            name: REPEAT_RANGE,
            value: value,
        });
    };

    const handleChangeRangeDay = (value: any) => {
        setRepeatDay(value);
        changeRepeatDay(value?.value);
    };

    const changeRepeatDay = (value: any) => {
        updateEditOrderField({
            name: REPEAT_DAY,
            value: value,
        });
    };

    return (
        <AccordionView textButton={entities?.order_periodicity?.value || ""}>
            <div className="accordion-form__item-content">
                <RepeatOrder
                    entities={entities}
                    handleChange={handleChange}
                    repeatRange={repeatRange}
                    repeatDay={repeatDay}
                    handleChangeRangeDay={handleChangeRangeDay}
                />
            </div>
        </AccordionView>
    );
};

export default RepeatEditOrder;

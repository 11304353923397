import React from "react";
import Icon from "../../../../../components/Icon";
import { Customer } from "../../../../../models/ordersModel";
import { TranslationObject } from "../../../../../models/translationModal";
import { getCurrentLanguage } from "../../../../../store/directories/selectors";
import { chooseDeclension } from "../../../../../untils";

interface ChatCustomerCardProps {
    entities?: TranslationObject;
    customer?: Customer;
    pinOrUnpinChat?: () => unknown;
    pinnedChatStatus: boolean;
    handleLinkReviews: (userId?: string | number) => unknown;
    handleOpenModalComplain: (userId?: string | number) => unknown;
}

export const ChatCustomerCard: React.FC<ChatCustomerCardProps> = (props) => {
    const {
        entities,
        customer,
        pinOrUnpinChat,
        pinnedChatStatus,
        handleLinkReviews,
        handleOpenModalComplain,
    } = props || {};

    const {
        id: customerId,
        avatar,
        name,
        family_name,
        reviews_count,
        languages,
        deals_count,
    } = customer || {};

    const currentLanguage = getCurrentLanguage();

    const dealCountsText = chooseDeclension(
        deals_count,
        entities?.specialist_details_deals?.value || "",
        currentLanguage === "ru"
    );

    const reviewCountsText = chooseDeclension(
        reviews_count,
        entities?.specialist_details_reviews_count?.value || "",
        currentLanguage === "ru",
    );

    return (
        <>
            <div className="user-card chat__header">
                <div className="avatar user-card__avatar">
                    {(avatar?.original_url && (
                        <img
                            src={avatar?.original_url}
                            width={138}
                            height={138}
                            alt="avatar"
                        />
                    )) || (
                            <Icon
                                type="camera_with_ellipse"
                                className="upload-foto__icon"
                                width={120}
                                height={120}
                            />
                        )}
                </div>
                <div className="user-card__info">
                    <h2 className="user-card__name title title--f24">
                        {`${name} ${family_name || ""}`}
                    </h2>
                    <div className="indicators user-card__indicators">
                        {typeof deals_count === "number" && (
                            <span className="indicators__deal">
                                {deals_count + "\u00A0" + dealCountsText}
                            </span>
                        )}
                        {reviews_count && reviews_count > 0 ? (
                            <a
                                onClick={() => handleLinkReviews(customerId)}
                                className="indicators__link-review link link--underline"
                            >
                                {reviews_count + "\u00A0" + reviewCountsText}
                            </a>
                        ) : (
                            <span className="indicators__link-review ">
                                {entities?.without_reviews?.value}
                            </span>
                        )}
                    </div>
                </div>
                {!!languages?.length && (
                    <div className="languages languages--full languages--big user-card__lang">
                        <p className="languages__title">
                            {entities?.common_communication_languages?.value}:
                        </p>
                        <div className="languages__wrapper">
                            <ul className="languages__list">
                                {languages?.map(({ id, name, flag }) => (
                                    <li
                                        className="languages__item popover"
                                        key={id}
                                    >
                                        <span className="popover__tip">
                                            {name}
                                        </span>
                                        <img
                                            src={flag?.original_url}
                                            width={22}
                                            height={16}
                                            alt={name}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
                <nav className="user-card__nav user-nav">
                    <button
                        className="btn-reset menu-toggler"
                        type="button"
                        aria-label="menu"
                    >
                        <span className="menu-toggler__item" />
                        <span className="menu-toggler__item" />
                        <span className="menu-toggler__item" />
                    </button>
                    <ul className="user-nav__list list-reset">
                        <li className="user-nav__item">
                            <button
                                className="btn-reset user-nav__btn"
                                type="button"
                                onClick={pinOrUnpinChat}
                            >
                                {pinnedChatStatus
                                    ? entities?.chat_unpin_chat?.value
                                    : entities?.chat_pin_chat?.value}
                            </button>
                        </li>
                        <li className="user-nav__item">
                            <button
                                onClick={() =>
                                    handleOpenModalComplain(customerId)
                                }
                                className="btn-reset user-nav__btn"
                                type="button"
                            >
                                {entities?.chat_complain_about_customer?.value}
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    );
};

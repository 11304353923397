import React from "react";
import { BankCard } from "../BankCard";
import { IBankCard } from "../../../../models/financesModel";

interface MainCardProps {
    mainCard: IBankCard;
}

export const MainCard: React.FC<MainCardProps> = ({ mainCard }) => {
    return (
        <div>
            <BankCard card={mainCard} />
        </div>
    );
};

import React, { useEffect, useRef, useState } from "react";
import Form from "../../components/Form/Form";
import { formatQueryString } from "../../untils";
import { OrderStepsProps } from "./index";
import ChooseAddress from "../../components/ChooseAddress";
import { useNavigate } from "react-router-dom";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { locationProps } from "../../models/orderModel";
import {
    IS_MY_ADDRESS,
    IS_SPECIALIST_ADDRESS,
    MY_ADDRESS,
    SPECIALIST_ADDRESS,
    SPECIALIST_IDS,
    SERVICE_CATEGORY_NAME,
    SERVICE_TYPE_NAME,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_SUB_TYPE_NAME,
    SPECIALIST_NAME
} from "../../store/order/constant";
import { createOrderState } from "../../store/order/createOrder.slice";
import {
    getItemCreateOrder,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import { useActions } from "../../hooks/actions";
import { endpoints } from "../../store/order/order.api";
import ErrorAddressModal from "./ErrorAddressModal";
import Advertising from "../../components/Advertising";
import { ProfilePayload } from "../../models/userModels";
import CancelOrderButton from "./Specialists/CancelOrderButton";
import Breadcrumbs from "../../components/Breadcrumbs";

interface Props extends OrderStepsProps {
    isMyAddress: boolean;
    isIndividualOrder: boolean;
    profile?: ProfilePayload;
    isAdvertising?: boolean;
}

const SelectAddress: React.FC<Props> = ({
    entities,
    onSubmit,
    error,
    isMyAddress,
    onSuccess,
    isSuccess,
    isIndividualOrder,
    profile,
    isAdvertising
}) => {
    const { updateCreateOrderField } = useActions();
    const [
        checkAddress,
        { error: errorCheckAddress, isSuccess: isSuccessCheckAddress },
    ] = endpoints.checkAddress.useLazyQuery();
    const [errorUpdate, setError] = useState(error);
    const currentLanguage = getCurrentLanguage();
    const navigate = useNavigate();
    const myLocation = getItemCreateOrder(MY_ADDRESS);
    const specialistLocation = getItemCreateOrder(SPECIALIST_ADDRESS);
    const location = !!isAdvertising || !isMyAddress ? specialistLocation : myLocation;
    const hasSpecialistAddress = getItemCreateOrder(IS_SPECIALIST_ADDRESS);
    const hasMyAddress = getItemCreateOrder(IS_MY_ADDRESS);
    const specialistIds = getItemCreateOrder(SPECIALIST_IDS);
    const specialistName = getItemCreateOrder(SPECIALIST_NAME);
    const needCheckAddress =
        isIndividualOrder && specialistIds && specialistIds?.length > 0;
    const [showErrorModal, setShowErrorModal] = useState(false);
    const needSelectMyAddress = !myLocation?.latitude || !myLocation?.longitude;
    const needSelectSpecialistAddress =
        !specialistLocation?.latitude || !specialistLocation?.longitude;
    const isDisabled = isMyAddress
        ? needSelectMyAddress
        : needSelectSpecialistAddress;
    const serviceSubTypesIdsLength = getItemCreateOrder(SERVICE_SUB_TYPE_IDS)?.length;

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) => {
        updateItemCreateOrder(
            {
                name,
                value,
            },
            updateCreateOrderField
        );
    };

    const mountProfileAddress = useRef(true);

    useEffect(() => {
        if (
            profile?.address &&
            profile?.latitude &&
            profile?.longitude &&
            mountProfileAddress.current &&
            !location
        ) {
            updateField({
                name: "my_address",
                value: {
                    latitude: profile?.latitude,
                    longitude: profile?.longitude,
                    address: profile?.address,
                },
            });
            mountProfileAddress.current = false;
        }
    }, [profile, mountProfileAddress, location]);

    useEffect((e) => {
        if (isSuccessCheckAddress) {
            onSubmit(e, getNextStep());
        }
    }, [isSuccessCheckAddress]);

    const changeLocation = (obj: locationProps) => {
        updateField({
            name: isMyAddress ? "my_address" : "specialist_address",
            value: isMyAddress
                ? {
                    latitude: obj?.latitude,
                    longitude: obj?.longitude,
                    address: obj?.address,
                }
                : {
                    latitude: obj?.latitude,
                    longitude: obj?.longitude,
                    radius: obj?.radius,
                    address: obj?.address,
                },
        });
    };

    const _onSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        _checkAddress(e);
    };

    useEffect(() => {
        if (errorCheckAddress) {
            updateField({ name: "hasErrorInAddress", value: true });
            setShowErrorModal(true);
        }
    }, [errorCheckAddress]);

    const _checkAddress = (e) => {
        if (needCheckAddress) {
            const data = {
                my_address: {
                    latitude: myLocation?.latitude,
                    longitude: myLocation?.longitude,
                },
                specialist_address: {
                    latitude: specialistLocation?.latitude,
                    longitude: specialistLocation?.longitude,
                    radius: specialistLocation?.radius,
                },
            };
            checkAddress({
                params: formatQueryString(data),
                id: specialistIds[0],
            });
        } else {
            onSubmit(e, getNextStep());
        }
    };

    const _onSuccess = () => {
        onSuccess(getNextStep());
    };

    const getNextStep = () => {
        let nextStep = "when-to-start";

        if (isMyAddress && hasSpecialistAddress) {
            nextStep = "select-specialist-address";
        }

        return nextStep;
    };

    useEffect(() => {
        if (isSuccess) {
            _onSuccess();
        }
    }, [isSuccess]);

    const _back = () => {
        if (!isMyAddress && hasMyAddress) {
            return navigate(
                `/${currentLanguage}/customer/create-order/select-my-address`
            );
        }
        navigate(`/${currentLanguage}/customer/create-order/meeting-point`);
    };

    const crumbsMy = [
        {
            name: getItemCreateOrder(SERVICE_CATEGORY_NAME) || entities?.service_task?.value,
            link: 'create-order'
        },
        {
            name: getItemCreateOrder(SERVICE_TYPE_NAME) || entities?.type_of_service_2?.value,
            link: 'create-order/type'
        },
        {
            name: getItemCreateOrder(SERVICE_SUB_TYPE_NAME) || serviceSubTypesIdsLength && `${entities?.subtype_of_service_2?.value} (${serviceSubTypesIdsLength})` || entities?.subtype_of_service_2?.value,
            link: 'create-order/subtype'
        },
        {
            name: entities?.choosing_a_specialist?.value,
            link: 'create-order/meeting-point'
        },
        {
            active: true,
            name: entities?.your_address?.value
        }
    ]

    const crumbsSpec = [
        {
            name: getItemCreateOrder(SERVICE_CATEGORY_NAME) || entities?.service_task?.value,
            link: 'create-order'
        },
        {
            name: getItemCreateOrder(SERVICE_TYPE_NAME) || entities?.type_of_service_2?.value,
            link: 'create-order/type'
        },
        {
            name: getItemCreateOrder(SERVICE_SUB_TYPE_NAME) || serviceSubTypesIdsLength && `${entities?.subtype_of_service_2?.value} (${serviceSubTypesIdsLength})` || entities?.subtype_of_service_2?.value,
            link: 'create-order/subtype'
        },
        {
            name: entities?.choosing_a_specialist?.value,
            link: 'create-order/meeting-point'
        },
        {
            active: true,
            name: entities?.specialists_address?.value
        }
    ]

    return (
        <>
            <ErrorAddressModal
                title={entities?.order_impossible_offer_to_specialists_title?.value || ''}
                onClose={() => setShowErrorModal(false)}
                visible={showErrorModal}
                description={entities?.order_impossible_offer_to_specialists_text?.value || ''}
                entities={entities}
                id={specialistIds && specialistIds[0]}
                name={specialistName}
            />
            <div className="inner-container">
                <div className="new-order__wrapper">
                    <Breadcrumbs crumbs={isMyAddress ? crumbsMy : crumbsSpec}/>
                    <div className="new-order__header">
                        <h2 className="title title--f46">
                            {isMyAddress
                                ? entities?.order_select_address_title?.value
                                : entities?.order_select_meeting_address_title?.value}
                        </h2>
                        <CancelOrderButton profile={profile} entities={entities}/>
                        <p className="subtitle">
                            {isMyAddress
                                ? entities?.order_select_address_text?.value
                                : entities?.order_select_meeting_address_text?.value}
                        </p>
                    </div>
                    <div className="new-order__form form">
                        <Form
                            error={errorUpdate}
                            onSubmit={_onSubmit}
                            isSuccess={isSuccess}
                        >
                            {() => (
                                <>
                                    <ChooseAddress
                                        changeLocation={changeLocation}
                                        setError={setError}
                                        withRadius={!isMyAddress}
                                        entities={entities}
                                        location={location}
                                        key={isMyAddress ? "is_my" : "isSpec"}
                                    />
                                    <div className="form__row form__controls">
                                        <button
                                            className="btn form__back-link btn--transparent"
                                            type="button"
                                            onClick={_back}
                                        >{entities?.common_back_button?.value}</button>
                                        <button
                                            className="btn btn--transparent btn--bg-blue"
                                            type="submit"
                                            disabled={isDisabled}
                                        >{entities?.login_continue_button?.value}</button>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>
                <ul className="advertising-list create-order">
                    <Advertising countCards={5} entities={entities} isHorizontal={true} />
                </ul>
            </div>
        </>
    );
};

export default SelectAddress;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "moment/locale/ru";
import { useActions } from "../../../../../hooks/actions";
import cn from "classnames";
import { useSendMessageToChatMutation } from "../../../../../store/chat/chat.api";
import {
    useSendSupportMessageToChatMutation,
    useSendSupportMessageToChatNotAuthMutation,
} from "../../../../../store/support/support.api";
import { SpecialistButtons } from "./SpecialistButtons";
import { CustomerButtons } from "./CustomerButtons";
import { IOffer } from "../../../../../models/ordersModel";
import { TranslationObject } from "../../../../../models/translationModal";
import Icon from "../../../../../components/Icon";
import { useNotification } from "../../../../../hooks/useNotification/useNotification";
import { checkStringForForbiddenWords } from "../../../../../untils/reg";
import { isSupportCountMsgMaxSelector } from "../../../../../store/support/support.selectors";

interface ChatFormProps {
    role?: string;
    chatId?: string | number;
    orderId?: string;
    offer?: IOffer | null;
    price?: number;
    entities?: TranslationObject;
    isSupport?: boolean;
    isAuth?: boolean;
}

export const ChatForm: React.FC<ChatFormProps> = ({
    role,
    chatId,
    orderId,
    offer,
    price,
    entities,
    isSupport,
    isAuth,
}) => {
    const [chatInputValue, setChatInputValue] = useState<string>("");
    const [showLexiconError, setShowLexiconError] = useState<boolean>(false);
    const [showLexiconRegularError, setShowLexiconRegularError] =
        useState<boolean>(false);
    const { addSupportMessageNotAuth } = useActions();
    const isSupportCountMsgMax = useSelector(isSupportCountMsgMaxSelector);
    const [sendMessageToChat] = useSendMessageToChatMutation();
    const [sendSupportMessageToChat] = useSendSupportMessageToChatMutation();
    const [sendSupportMessageToChatNotAuth, { isSuccess, data: supportMessageNotAuth }] = useSendSupportMessageToChatNotAuthMutation();

    const readySendMessage = chatId && chatInputValue;
    //const readySendMessage = chatId && chatInputValue && offer?.status !== "decline";
    const readySmNotAuth = !isAuth && chatInputValue && !isSupportCountMsgMax;

    const { setNotification } = useNotification();

    // Отправшик сообщений
    const senderMessage = (requestData, sender) => {
        sender(requestData)
            .unwrap()
            .then(() => setChatInputValue(""))
            .catch((err) => {
                if (err?.status === 422) {
                    return setShowLexiconError(true);
                }
                setNotification({
                    type: "warn",
                    description:
                        err?.message || entities?.failed_to_send_message?.value,
                });
            });
        setChatInputValue("");
    }

    // Метод для не авторизованного пользователя
    const handleSendNotAuth = () => {
        if (isAuth) {
            if (!readySendMessage) return;

            senderMessage({
                chatId,
                body: {
                    text: chatInputValue,
                },
            }, sendSupportMessageToChat);
        } else if (!isSupportCountMsgMax) {
            if (!readySmNotAuth) return;

            senderMessage({
                body: {
                    anonymous: localStorage.getItem('anonymous'),
                    text: chatInputValue,
                },
            }, sendSupportMessageToChatNotAuth);
        }
    };

    // Метод для авторизованного и не потдержка
    const handleSendAuth = () => {
        if (!readySendMessage) return;

        senderMessage({
            chatId,
            body: {
                text: chatInputValue,
            },
        }, sendMessageToChat);
    };

    const handleSendMessage = () => {
        if (isSupport) {
            handleSendNotAuth();
        } else {
            handleSendAuth();
        }
    };

    useEffect(() => {
        if (chatInputValue) {
            if (
                checkStringForForbiddenWords(chatInputValue)
            ) {
                setShowLexiconRegularError(true);
            } else if (showLexiconRegularError) {
                setTimeout(() => {
                    setShowLexiconRegularError(false);
                }, 2000);
            }
        } else if (!chatInputValue && showLexiconRegularError) {
            setShowLexiconRegularError(false);
        }
    }, [chatInputValue, showLexiconRegularError]);

    useEffect(() => {
        if (isSuccess && supportMessageNotAuth) {
            addSupportMessageNotAuth({
                ...supportMessageNotAuth,
            });
        }
    }, [supportMessageNotAuth]);

    return (
        <>
            <div className="chat__form form">
                {(showLexiconError || showLexiconRegularError) && (
                    <div className="notification-lexicon__error">
                        {entities?.message_violates_terms_of_system?.value}
                    </div>
                )}
                <div className="form__row">
                    <div className="custom-input form__field">
                        <label>
                            <input
                                type="text"
                                id="message"
                                name="message"
                                placeholder={
                                    entities?.chat_write_message_2?.value
                                }
                                value={chatInputValue}
                                onChange={(e) => {
                                    setChatInputValue(e.target.value);
                                    setShowLexiconError(false);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key !== "Enter") return;
                                    e.preventDefault();
                                    handleSendMessage();
                                }}
                            />
                        </label>
                        <button
                            className={cn("btn-send__message", {
                                disabled: isSupport && !isAuth ? !readySmNotAuth : !readySendMessage,
                            })}
                            type="button"
                            onClick={handleSendMessage}
                        >
                            <Icon
                                type={"send-message"}
                                height={44}
                                width={44}
                            />
                        </button>
                    </div>
                </div>
                {!isSupport && role === "specialist" && (
                    <SpecialistButtons
                        orderId={orderId}
                        price={price}
                        entities={entities}
                        offer={offer}
                    />
                )}
                {!isSupport && role === "customer" && offer && (
                    <CustomerButtons offer={offer} entities={entities} />
                )}
            </div>
        </>
    );
};

import Form from "../../../components/Form/Form";
import Category from "../Category";
import TypeEditOrder from "../Type";
import SubTypeEditOrder from "../SubType";
import FiltersEditOrder from "../Filters";
import MeetingPointEditOrder from "../MeetingPoint";
import SelectAddressEditOrder from "../SelectAddress";
import StartAtEditOrder from "../StartAt";
import RepeatEditOrder from "../Repeat";
import WishesEditOrder from "../Wishes";
import ButtonsEditOrder from "../Buttons";
import React from "react";
import { getItemEditOrder } from "../../../store/order/selectors";
import {
    IS_MY_ADDRESS,
    IS_SPECIALIST_ADDRESS,
} from "../../../store/order/constant";
import { TranslationObject } from "../../../models/translationModal";
import OrderName from "../OrderName";

interface Props {
    entities?: TranslationObject;
    save: (value: string, id: number) => void;
    isPrivate: boolean;
    isSuccess: boolean;
    onSubmit: (e: React.FormEvent<HTMLInputElement>) => void;
    isLoading?: boolean;
}

const BaseContainer: React.FC<Props> = ({
    entities,
    isSuccess,
    onSubmit,
    save,
    isPrivate,
    isLoading,
}) => {
    const isMyAddress = getItemEditOrder(IS_MY_ADDRESS);
    const isSpecialistAddress = getItemEditOrder(IS_SPECIALIST_ADDRESS);

    return (
        <div className="inner-container">
            <h1 className="title title--f46">
                {entities?.order_editing_title?.value}
            </h1>
            <div className="accordion-form form">
                <Form onSubmit={onSubmit} isSuccess={isSuccess}>
                    {(invalidFields, error) => (
                        <>
                            <ul className="accordion-form__list">
                                <OrderName entities={entities} />
                                <Category entities={entities} />
                                <TypeEditOrder entities={entities} />
                                <SubTypeEditOrder entities={entities} />
                                {!isPrivate && (
                                    <FiltersEditOrder entities={entities} />
                                )}
                                {!isPrivate && (
                                    <MeetingPointEditOrder
                                        entities={entities}
                                    />
                                )}
                                {!isPrivate && isMyAddress && (
                                    <SelectAddressEditOrder
                                        isMyAddress
                                        entities={entities}
                                    />
                                )}
                                {!isPrivate && isSpecialistAddress && (
                                    <SelectAddressEditOrder
                                        entities={entities}
                                    />
                                )}
                                <StartAtEditOrder entities={entities} />
                                <RepeatEditOrder entities={entities} />
                                <WishesEditOrder
                                    entities={entities}
                                    invalidFields={invalidFields}
                                    error={error}
                                />
                            </ul>
                            <ButtonsEditOrder
                                isLoading={isLoading}
                                entities={entities}
                                save={save}
                                isPrivate={isPrivate}
                            />
                        </>
                    )}
                </Form>
            </div>
        </div>
    );
};

export default BaseContainer;

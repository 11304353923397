import { request } from "../request";

export const setRegistrationCompany = (requestData: Record<string, any>) => {
    return request({
        url: `auth/register/store_company`,
        method: "POST",
        body: requestData,
    });
};

export const registerAsPrivatePerson = () => {
    return request({
        url: `auth/register/as_private_person`,
        method: "POST",
    });
};

export const getRegistrationCompany = (id: string) => {
    return request({
        url: `auth/register/send_reg_number`,
        params: {
            id: id
        }
    });
};

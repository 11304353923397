import { request } from "../request";
import { bodyChangePassword } from "../../models/authModel";
import { BodyData, BodyFormData, IdProps } from "../models";

export const changePassword = ({ body }: bodyChangePassword) =>
    request({
        url: `profile/password`,
        method: "POST",
        body,
    });

export const deleteProfile = () =>
    request({
        url: `profile/destroy`,
        method: "DELETE",
    });

export const updateProfile = ({ body }: BodyFormData) =>
    request({
        url: "profile/update",
        body,
        method: "POST",
    });

export const update = ({
    str,
    body,
}: {
    str: string;
    body: Record<string, string>;
}) =>
    request({
        url: `profile/${str}`,
        body,
        method: "POST",
    });

export const createUserAchievement = ({
    body,
    currentLanguage,
    token,
}: {
    body: Record<string, string>;
    currentLanguage: string;
    token: string;
}) =>
    request({
        url: `/achievements`,
        tkn: token,
        body,
        method: "POST",
        currentLanguage,
    });

export const updateUserAchievement = ({
    achievementId,
    body,
    currentLanguage,
    token,
}: {
    achievementId: number;
    body: Record<string, string>;
    currentLanguage: string;
    token: string;
}) =>
    request({
        url: `/achievements/${achievementId}`,
        tkn: token,
        body,
        method: "POST",
        currentLanguage,
    });

export const deleteUserAchievement = ({
    achievementId,
    currentLanguage,
    token,
}: {
    achievementId: number;
    currentLanguage: string;
    token: string;
}) =>
    request({
        url: `/achievements/${achievementId}`,
        tkn: token,
        method: "DELETE",
        currentLanguage,
    });

export const deleteUserAchievementDocument = ({
    achievementId,
    documentId,
}: {
    achievementId: number;
    documentId: number;
}) =>
    request({
        url: `/achievements/${achievementId}/documents/${documentId}`,
        method: "DELETE",
    });

export const deleteAvatar = () =>
    request({
        url: `profile/destroy_avatar`,
        method: "POST",
    });

export const getSpecialist = ({ id }: IdProps) =>
    request({
        url: `specialists/${id}/main_info`,
        method: "GET",
    });

export const getCustomer = ({ id }: IdProps) =>
    request({
        url: `customers/${id}/main_info`,
        method: "GET",
    });

export const getSpecialistDetail = ({ id }: IdProps) =>
    request({
        url: `specialists/${id}/detailed_info`,
        method: "GET",
    });

export const updateUserAddress = ({ body }: BodyData) =>
    request({
        url: `profile/update_address`,
        method: "POST",
        body,
    });

export const updateUserAboutMe = ({ body }: BodyData) =>
    request({
        url: `profile/about_me`,
        method: "POST",
        body,
    });

export const setSpecialistServiceCategory = ({
    body,
}: {
    body: {
        service_category_id: number;
    };
}) =>
    request({
        url: `specialist_services`,
        method: "POST",
        body,
    });

export const setSpecialistServiceType = ({
    specialistService,
    body,
    currentLanguage,
    token,
}: {
    specialistService: string | number;
    body:
        | {
              service_type_id: number;
          }
        | {
              custom_service_type: string;
          };
    currentLanguage: string;
    token: string;
}) =>
    request({
        url: `specialist_services/${specialistService}/store_service_type`,
        tkn: token,
        method: "POST",
        body,
        currentLanguage,
    });

export const setSpecialistServiceSubType = ({
    specialistService,
    body,
    currentLanguage,
    token,
}: {
    specialistService: string | number;
    body:
        | {
              service_sub_type_ids: number[];
          }
        | {
              custom_service_sub_type: string;
          };
    currentLanguage: string;
    token: string;
}) =>
    request({
        url: `specialist_services/${specialistService}/store_service_sub_types`,
        tkn: token,
        method: "POST",
        body,
        currentLanguage,
    });

export const setSpecialistNewService = ({
    specialistService,
    body,
}: {
    specialistService: string | number;
    body: Record<string, any>;
}) =>
    request({
        url: `specialist_services/${specialistService}`,
        method: "POST",
        body,
    });

export const deleteSpecialistService = (specialistServiceId: string | number) =>
    request({
        url: `specialist_services/${specialistServiceId}`,
        method: "DELETE",
    });

export const setConfirmEmployee = ({
    employeeId,
    body,
}: {
    employeeId: number | string;
    body: Record<string, any>;
}) =>
    request({
        url: `companies/employees/${employeeId}/confirm`,
        method: "POST",
        body,
    });

export const setAssignEmployeeManager = ({
    employeeId,
}: {
    employeeId: number | string;
}) =>
    request({
        url: `companies/employees/${employeeId}/assign_as_manager`,
        method: "POST",
    });

export const deleteEmployeeCompany = ({
    employeeId,
}: {
    employeeId: number | string;
}) =>
    request({
        url: `companies/employees/${employeeId}/delete`,
        method: "POST",
    });

export const setManagerCompany = (body: Record<string, any>) =>
    request({
        url: `companies/update`,
        method: "put",
        body,
    });

export const setNewComplaint = (body: Record<string, any>) =>
    request({
        url: `claims`,
        method: "POST",
        body,
    });

export const getCompanyEmployees = () =>
    request({
        url: `companies/employees`,
        method: "GET",
    });

export const getUserBonuses = () =>
    request({
        url: "bonuses",
        method: "GET",
    });

export const sendNotifyAdmin = (body: { comment: string }) =>
    request({
        url: "profile/notify_admin",
        method: "POST",
        body,
    });

export const deleteSpecialistServicePhoto = ({
    serviceId,
    photoId,
}: {
    serviceId: number;
    photoId: number;
}) =>
    request({
        url: `/specialist_services/${serviceId}/photos/${photoId}`,
        method: "DELETE",
    });

export const publishSpecialistProfile = () =>
    request({
        url: "profile/publish",
        method: "POST",
    });

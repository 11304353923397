import React, { useState } from "react";
import CategoryFilter from "./Category";
import TypeFilter from "./Type";
import SubTypesFilter from "./SubTypes";
import { TranslationObject } from "../../../models/translationModal";
import MeetingPoint from "./MeetingPoint";
import DateFilter from "./Date";
import { ProfilePayload } from "../../../models/userModels";
import Icon from "../../../components/Icon";
import { checkIsDesktop } from "../../../store/directories/selectors";
import MobileFilterTitle from "../../CreateOrder/Specialists/MobileFilters/FilterTitle";
import { getFilters } from "../../../store/orders/selectors";
import classnames from "classnames";
import {
    IS_MY_ADDRESS,
    IS_REMOTE,
    IS_SPECIALIST_ADDRESS,
} from "../../../store/order/constant";
import { useActions } from "../../../hooks/actions";
import Uninteresed from "./Uninteresed";

interface Props {
    entities?: TranslationObject;
    profile?: ProfilePayload;
}

const Filters: React.FC<Props> = ({ entities, profile }) => {
    const isDesktop = checkIsDesktop();
    const { clearOrdersFilters } = useActions();
    const [showShortFilters, setShowShortFilters] = useState<boolean>(true);
    const [activeMobileFilter, setActiveMobileFilter] = useState<string>("");
    const filters = getFilters();
    const hasMeetPoint =
        filters?.is_remote ||
        filters?.is_my_address ||
        filters?.is_specialist_address;

    const selectedCount =
        (filters?.started_at || filters?.ended_at ? 1 : 0) +
        (filters?.service_category_id ? 1 : 0) +
        (filters?.service_type_id ? 1 : 0) +
        (filters?.is_interesting ? 1 : 0) +
        (filters?.service_sub_type_ids &&
        filters?.service_sub_type_ids?.length > 0
            ? 1
            : 0) +
        (hasMeetPoint ? 1 : 0);

    if (showShortFilters && !isDesktop) {
        return (
            <button
                className="toggle-filter"
                aria-label={entities?.order_filters?.value}
                type="button"
                onClick={() => setShowShortFilters(false)}
            >
                {entities?.order_filters?.value}
                <Icon type="settings" />
                <span>{selectedCount}</span>
            </button>
        );
    }

    let meetPointCount = 0;

    if (filters[IS_REMOTE]) {
        meetPointCount++;
    }

    if (filters[IS_MY_ADDRESS]) {
        meetPointCount++;
    }
    if (filters[IS_SPECIALIST_ADDRESS]) {
        meetPointCount++;
    }

    const clearActiveFilter = () => setActiveMobileFilter("");

    return (
        <>
            {!isDesktop && (
                <div
                    className={classnames("filter__block", {
                        "is-open": !showShortFilters,
                    })}
                >
                    <button
                        className="filter__subtitle"
                        type="button"
                        onClick={() => setShowShortFilters(true)}
                    >
                        {entities?.order_filter?.value || ""}
                        <Icon type="arrow-left-pagination" />
                    </button>
                    <div className="filter__btn-subtitles">
                        <MobileFilterTitle
                            title={entities?.order_date?.value || ""}
                            count={
                                filters?.started_at || filters?.ended_at ? 1 : 0
                            }
                            onClick={() => setActiveMobileFilter("date")}
                        />
                        <MobileFilterTitle
                            title={entities?.common_show_not_uninteresting?.value || ''}
                            count={filters?.is_interesting ? 1 : 0}
                            onClick={() => setActiveMobileFilter("uninterested")}
                        />
                        <MobileFilterTitle
                            title={
                                entities?.order_service_category?.value || ""
                            }
                            count={filters?.service_category_id ? 1 : 0}
                            onClick={() => setActiveMobileFilter("category")}
                        />
                        {filters?.service_category_id && (
                            <MobileFilterTitle
                                title={
                                    entities?.order_service_type?.value || ""
                                }
                                count={filters?.service_type_id ? 1 : 0}
                                onClick={() => setActiveMobileFilter("type")}
                            />
                        )}
                        {filters?.service_type_id &&
                            filters?.service_category_id && (
                                <MobileFilterTitle
                                    title={
                                        entities?.order_sub_type?.value || ""
                                    }
                                    count={
                                        filters?.service_sub_type_ids
                                            ? filters?.service_sub_type_ids
                                                  ?.length
                                            : 0
                                    }
                                    onClick={() =>
                                        setActiveMobileFilter("sub_types")
                                    }
                                />
                            )}
                        <MobileFilterTitle
                            title={entities?.common_meeting_place?.value || ""}
                            count={meetPointCount}
                            onClick={() =>
                                setActiveMobileFilter("meeting_place")
                            }
                        />
                        <div className="filter__btns">
                            <button
                                className="btn btn btn--rounded btn--transparent filter__btn filter__btn--cancel"
                                type="button"
                                onClick={clearOrdersFilters}
                            >
                                {entities?.order_reset_filters?.value || ""}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <DateFilter
                entities={entities}
                profile={profile}
                activeMobileFilter={activeMobileFilter}
                setActiveMobileFilter={clearActiveFilter}
            />
            <Uninteresed
                entities={entities}
                profile={profile}
                activeMobileFilter={activeMobileFilter}
                setActiveMobileFilter={clearActiveFilter}
            />
            <CategoryFilter
                entities={entities}
                profile={profile}
                activeMobileFilter={activeMobileFilter}
                setActiveMobileFilter={clearActiveFilter}
            />
            <TypeFilter
                entities={entities}
                activeMobileFilter={activeMobileFilter}
                profile={profile}
                setActiveMobileFilter={clearActiveFilter}
            />
            <SubTypesFilter
                entities={entities}
                profile={profile}
                activeMobileFilter={activeMobileFilter}
                setActiveMobileFilter={clearActiveFilter}
            />
            <MeetingPoint
                entities={entities}
                profile={profile}
                activeMobileFilter={activeMobileFilter}
                setActiveMobileFilter={clearActiveFilter}
            />
        </>
    );
};

export default Filters;

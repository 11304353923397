import PageLoaders from "../../components/Loaders/PageLoaders";
import Heading from "../../components/Heading";
import React, { useState } from "react";
import { BlocksBaseObject } from "../../models/homePageModel";
import Advertising from "../../components/Advertising";
import { TranslationObject } from "../../models/translationModal";
import { Link } from "react-router-dom";
import { ProfilePayload } from "../../models/userModels";
import { getTkn } from "../../store/user/selectors";

const Price: React.FC<{
    currentLanguage: string;
    entities?: TranslationObject;
    profile?: ProfilePayload;
}> = ({ currentLanguage, entities, profile }) => {
    const token = getTkn();
    const [blocks, setBlocks] = useState<BlocksBaseObject | null>(null);
    const linkToReview =
        profile?.id && token
            ? `/${currentLanguage}/specialist/lk/reviews/${profile?.id}`
            : `/${currentLanguage}/specialist/auth`;
    const linkToOrders =
        profile?.id && token
            ? `/${currentLanguage}/specialist/lk/orders`
            : `/${currentLanguage}/specialist/auth`;
    const linkToAdvertising =
        profile?.id && token
            ? `/${currentLanguage}/specialist/lk/advertisement`
            : `/${currentLanguage}/specialist/auth`;

    return (
        <PageLoaders
            type="custom_pages"
            isCustomPage
            currentLanguage={currentLanguage}
            code="specialist_prices"
            updateData={setBlocks}
        >
            <>
                <main>
                    <div className="page page--specialists prices-page">
                        <div className="page__container container">
                            <Heading
                                isSpecialist
                                img={blocks?.main_block_image?.value}
                                text={blocks?.main_block_description_2?.value}
                                subtitle={
                                    blocks?.main_block_description_1?.value
                                }
                                className="promo promo-bonus page__promo"
                                title={blocks?.main_block_title?.value}
                            />
                            <div className="page__grid">
                                <section className="price-page">
                                    <div className="price-block price-page__block price-block_row">
                                        <article className="card price-block__card">
                                            <h2 className="card__title title title--f46">
                                                {blocks?.deal_price_block_title?.value}
                                            </h2>

                                            <div
                                                className="card__text text"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        blocks
                                                            ?.deal_price_block_description
                                                            ?.value || "",
                                                }}
                                            />

                                            <Link
                                                className="btn card__btn btn--bg-green btn--rounded"
                                                to={linkToOrders}
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        blocks?.deal_button_text
                                                            ?.value || "",
                                                }}
                                            ></Link>
                                        </article>
                                        <article className="card price-block__card">
                                            <h2 className="card__title title title--f46">
                                                {
                                                    blocks
                                                        ?.facebook_share_price_block_title
                                                        ?.value
                                                }
                                            </h2>

                                            <div
                                                className="card__text text"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        blocks
                                                            ?.facebook_share_price_block_description
                                                            ?.value || "",
                                                }}
                                            />
                                            <Link
                                                className="btn card__btn btn--bg-green btn--rounded"
                                                to={linkToReview}
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        blocks
                                                            ?.facebook_share_price_button_text
                                                            ?.value || "",
                                                }}
                                            ></Link>
                                        </article>
                                    </div>
                                    <div className="price-block price-page__block">
                                        <div className="price-block__image">
                                            <picture>
                                                <img
                                                    src={
                                                        blocks
                                                            ?.advertisement_price_block_image
                                                            ?.value
                                                    }
                                                    width="690"
                                                    height="690"
                                                    alt="Price block image"
                                                />
                                            </picture>
                                        </div>
                                        <div className="price-block__info">
                                            <h2 className="price-block__title title title--f46">
                                                {
                                                    blocks
                                                        ?.advertisement_price_block_title
                                                        ?.value
                                                }
                                            </h2>

                                            <div
                                                className="price-block__text text"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        blocks
                                                            ?.advertisement_price_block_description
                                                            ?.value || "",
                                                }}
                                            />

                                            <h3
                                                className="title title--h4"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        blocks
                                                            ?.advertisement_price_block_text
                                                            ?.value || "",
                                                }}
                                            />
                                            <div className="table">
                                                <table>
                                                    <tbody>
                                                        {blocks?.advertisement_price_block_table?.value?.map(
                                                            (
                                                                elem: Record<
                                                                    string,
                                                                    string
                                                                >,
                                                                key: number
                                                            ) => (
                                                                <tr key={key}>
                                                                    {Object.keys(
                                                                        elem
                                                                    ).map(
                                                                        (
                                                                            item,
                                                                            index
                                                                        ) => (
                                                                            <td
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {
                                                                                    elem[
                                                                                    item
                                                                                    ]
                                                                                }
                                                                            </td>
                                                                        )
                                                                    )}
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <Link
                                                className="btn price-block__btn btn--bg-green btn--rounded"
                                                to={linkToAdvertising}
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        blocks
                                                            ?.advertisement_price_block_button_text
                                                            ?.value || "",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="price-block price-block--reverse price-page__block">
                                        <div className="price-block__image">
                                            <picture>
                                                <img
                                                    src={
                                                        blocks
                                                            ?.rise_in_the_list_price_block_image
                                                            ?.value
                                                    }
                                                    width="690"
                                                    height="690"
                                                    alt="Price block image"
                                                />
                                            </picture>
                                        </div>
                                        <div className="price-block__info">
                                            <h2 className="price-block__title title title--f46">
                                                {
                                                    blocks
                                                        ?.rise_in_the_list_price_block_title
                                                        ?.value
                                                }
                                            </h2>
                                            <div
                                                className="price-block__text text"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        blocks
                                                            ?.rise_in_the_list_price_block_description
                                                            ?.value || "",
                                                }}
                                            />
                                            <h3
                                                className="title title--h4"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        blocks
                                                            ?.advertisement_price_block_text
                                                            ?.value || "",
                                                }}
                                            />
                                            <div className="table">
                                                <table>
                                                    <tbody>
                                                        {blocks?.rise_in_the_list_price_block_table?.value?.map(
                                                            (
                                                                elem: Record<
                                                                    string,
                                                                    string
                                                                >,
                                                                key: number
                                                            ) => (
                                                                <tr key={key}>
                                                                    {Object.keys(
                                                                        elem
                                                                    )?.map(
                                                                        (
                                                                            item,
                                                                            index
                                                                        ) => (
                                                                            <td
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {
                                                                                    elem[
                                                                                    item
                                                                                    ]
                                                                                }
                                                                            </td>
                                                                        )
                                                                    )}
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <Link
                                                className="btn price-block__btn btn--bg-green btn--rounded"
                                                to={linkToAdvertising}
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        blocks
                                                            ?.advertisement_price_block_button_text
                                                            ?.value || "",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="price-block price-page__block">
                                        <div className="price-block__image">
                                            <picture>
                                                <img
                                                    src={
                                                        blocks
                                                            ?.mailing_price_block_image
                                                            ?.value
                                                    }
                                                    width="690"
                                                    height="690"
                                                    alt="Price block image"
                                                />
                                            </picture>
                                        </div>
                                        <div className="price-block__info">
                                            <h2 className="price-block__title title title--f46">
                                                {
                                                    blocks
                                                        ?.mailing_price_block_title
                                                        ?.value
                                                }
                                            </h2>
                                            <div
                                                className="price-block__text text"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        blocks
                                                            ?.mailing_price_block_description
                                                            ?.value || "",
                                                }}
                                            />
                                            <h3
                                                className="title title--h4"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        blocks
                                                            ?.mailing_price_block_text
                                                            ?.value || "",
                                                }}
                                            />
                                            <Link
                                                className="btn price-block__btn btn--bg-green btn--rounded"
                                                to={linkToAdvertising}
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        blocks
                                                            ?.advertisement_price_block_button_text
                                                            ?.value || "",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </section>
                                <section className="service-partner">
                                    <div className="service-partner__wrapper">
                                        <ul className="service-partner__list no-scrollbar">
                                            <Advertising
                                                typeAdvertising={"thirdParties"}
                                                entities={entities}
                                            />
                                        </ul>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        </PageLoaders>
    );
};
export default Price;

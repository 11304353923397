import React from "react";
import Pagination from "../../../../components/Pagination";
import Icon from "../../../../components/Icon";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { IHistoryPayment } from "../../../../models/financesModel";
import moment from "moment";
import { useActions } from "../../../../hooks/actions";
import { TranslationObject } from "../../../../models/translationModal";

interface TablePaymentsProps {
    paymentsHistory: IHistoryPayment[];
    pages: number[];
    currentPage: number;
    entities?: TranslationObject;
}

export const TablePayments: React.FC<TablePaymentsProps> = ({
    paymentsHistory,
    pages,
    currentPage,
    entities,
}) => {
    const { width } = useWindowSize();

    const { setCurrentPage } = useActions();

    return (
        <>
            {width > 767 && (
                <table className="table-payments">
                    <tbody>
                        <tr className="table-payments__head">
                            <th>{entities?.finances_payment_purpose?.value}</th>
                            <th>{entities?.finances_payment_date?.value}</th>
                            <th>{entities?.finances_payment_amount?.value}</th>
                            <th>{entities?.finances_payment_card?.value}</th>
                        </tr>
                        {paymentsHistory?.map(
                            ({
                                id,
                                purpose,
                                created_at,
                                amount,
                                card_last_four,
                            }) => (
                                <tr key={id}>
                                    <td>{purpose}</td>
                                    <td>
                                        {moment(created_at).format(
                                            "DD.MM.YYYY"
                                        )}
                                    </td>
                                    <td>{amount} €</td>
                                    <td>ХХХХ ХХХХ ХХХХ {card_last_four}</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            )}
            {width <= 767 && (
                <div className="mobile-payments">
                    {paymentsHistory?.map(
                        ({
                            id,
                            purpose,
                            created_at,
                            amount,
                            card_last_four,
                        }) => (
                            <div
                                className="mobile-payments__payment payment"
                                key={id}
                            >
                                <div className="payment-row">
                                    <p className="payment-row__title">
                                        {
                                            entities?.finances_payment_purpose
                                                ?.value
                                        }
                                    </p>
                                    <p className="payment-row__info">
                                        {purpose}
                                    </p>
                                </div>
                                <div className="payment-row">
                                    <p className="payment-row__title">
                                        {entities?.finances_payment_date?.value}
                                    </p>
                                    <p className="payment-row__info">
                                        {moment(created_at).format(
                                            "DD.MM.YYYY"
                                        )}
                                    </p>
                                </div>
                                <div className="payment-row">
                                    <p className="payment-row__title">
                                        {
                                            entities?.finances_payment_amount
                                                ?.value
                                        }
                                    </p>
                                    <p className="payment-row__info">
                                        {amount} €
                                    </p>
                                </div>
                                <div className="payment-row">
                                    <p className="payment-row__title">
                                        {
                                            entities?.finances_payment_amount
                                                ?.value
                                        }
                                    </p>
                                    <div className="payment-row__card">
                                        <p className="payment-row__info">
                                            **** {card_last_four}
                                        </p>
                                        <Icon
                                            type={"visa"}
                                            width={40}
                                            height={28}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
            )}
            {!paymentsHistory?.length && (
                <>
                    <div className="table-payments__not-payments">
                        <div className="table-payments__not-payments__icon-box">
                            <Icon type={"sad-smile"} width={32} height={32} />
                        </div>
                        <p>
                            {
                                entities?.finances_have_not_made_transactions
                                    ?.value
                            }
                        </p>
                    </div>
                </>
            )}
            {(paymentsHistory?.length && (
                <Pagination
                    pages={pages}
                    changeCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    entities={entities}
                />
            )) ||
                null}
        </>
    );
};

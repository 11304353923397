import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { changeHeaders } from "../request";
import {
    acceptAndContinueSearch,
    acceptAndStopSearch,
    createNewChat,
    declineOfferOrder,
    getChatInfo,
    getChatMessages,
    makeOfferOrder,
    pinChat,
    sendMessageToChat,
    unpinChat,
    viewChatMessage,
} from "./api";
import {
    createNewChatRequestData,
    createNewChatResponse,
    GetChatMessagesPayload,
    GetChatMessagesRequest,
    GetChatMessagesResponse,
    IChatPayload,
    IMessage,
    makeOfferOrderRequestData,
    SendMessageToChatRequest,
    SendMessageToChatResponse,
    ViewChatMessageRequest,
} from "../../models/chatModels";
import { EmptyPayload } from "../models";

export const chatApi = createApi({
    reducerPath: "chat/api",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
        prepareHeaders: (headers, { getState }) =>
            changeHeaders(headers, { getState }),
    }),
    tagTypes: ["Chat"],
    endpoints: (build) => ({
        createNewChat: build.mutation<IChatPayload, createNewChatRequestData>({
            query: (requestData) => createNewChat(requestData),
            transformResponse: (response: createNewChatResponse) =>
                response.payload,
        }),
        getChatInfo: build.query<IChatPayload, string | number>({
            query: (chatId) => getChatInfo(chatId),
            providesTags: [{ type: "Chat" }],
            transformResponse: (response: createNewChatResponse) =>
                response.payload,
        }),
        pinChat: build.mutation<IChatPayload, string | number>({
            query: (chatId) => pinChat(chatId),
            invalidatesTags: [{ type: "Chat" }],
            transformResponse: (response: createNewChatResponse) =>
                response.payload,
        }),
        unpinChat: build.mutation<IChatPayload, string | number>({
            query: (chatId) => unpinChat(chatId),
            invalidatesTags: [{ type: "Chat" }],
            transformResponse: (response: createNewChatResponse) =>
                response.payload,
        }),
        sendMessageToChat: build.mutation<IMessage, SendMessageToChatRequest>({
            query: (requestData) => sendMessageToChat(requestData),
            transformResponse: (response: SendMessageToChatResponse) =>
                response.payload,
        }),
        getChatMessages: build.query<
            GetChatMessagesPayload,
            GetChatMessagesRequest
        >({
            query: (requestData) => getChatMessages(requestData),
            transformResponse: (response: GetChatMessagesResponse) =>
                response.payload,
        }),
        viewChatMessage: build.mutation<EmptyPayload, ViewChatMessageRequest>({
            query: (requestData) => viewChatMessage(requestData),
        }),
        makeOfferOrder: build.mutation<EmptyPayload, makeOfferOrderRequestData>(
            {
                query: (requestData) => makeOfferOrder(requestData),
                invalidatesTags: [{ type: "Chat" }],
            }
        ),
        acceptAndContinueSearch: build.mutation<EmptyPayload, number>({
            query: (orderOfferId) => acceptAndContinueSearch(orderOfferId),
            invalidatesTags: [{ type: "Chat" }],
        }),
        acceptAndStopSearch: build.mutation<EmptyPayload, number>({
            query: (orderOfferId) => acceptAndStopSearch(orderOfferId),
            invalidatesTags: [{ type: "Chat" }],
        }),
        declineOfferOrder: build.mutation<EmptyPayload, number>({
            query: (orderOfferId) => declineOfferOrder(orderOfferId),
            invalidatesTags: [{ type: "Chat" }],
        }),
    }),
});

export const {
    endpoints,
    util,
    useCreateNewChatMutation,
    usePinChatMutation,
    useUnpinChatMutation,
    useSendMessageToChatMutation,
    useViewChatMessageMutation,
    useMakeOfferOrderMutation,
    useAcceptAndContinueSearchMutation,
    useAcceptAndStopSearchMutation,
    useDeclineOfferOrderMutation,
} = chatApi;

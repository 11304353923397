import React, { ReactNode, useState } from "react";
import Modal from "../../Modal";
import { TranslationObject } from "../../../models/translationModal";

interface ConfirmDeleteModalProps {
    entities?: TranslationObject;
    children?: ReactNode;
    classes?: string[];
    ariaLabel?: string;
    handleClick: (isPrivate?: boolean) => void;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = (props) => {
    const { entities, classes, children, ariaLabel = "", handleClick } = props;
    const [visible, setVisible] = useState<boolean>(false);

    const closeModal = () => {
        setVisible(false);
    };

    const openModal = () => {
        setVisible(true);
    };

    const resetValue = () => {
        handleClick?.();
    }

    return (
        <>
            <button
                onClick={openModal}
                type="button"
                className={`modal__button--open ${classes?.join(" ")}`}
                aria-label={ariaLabel}
            >
                { children }
            </button>
            <Modal
                title={entities?.common_confirm_delete?.value}
                visible={visible}
                onClose={closeModal}
            >
                <div className="modal__content-buttons">
                    <button
                        className="btn btn--transparent"
                        type="button"
                        onClick={closeModal}
                    >
                        {entities?.common_cancel_button?.value}
                    </button>
                    <button
                        className="btn modal__content-button btn--bg-green"
                        type="button"
                        onClick={resetValue}
                    >
                        {entities?.profile_destroy_button_ok?.value}
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default ConfirmDeleteModal;

import React from "react";
import Icon from "../../../../components/Icon";
import { TranslationObject } from "../../../../models/translationModal";

interface AddNewCardProps {
    openAddCardModal: () => unknown;
    entities?: TranslationObject;
}

export const AddNewCard: React.FC<AddNewCardProps> = ({
    openAddCardModal,
    entities,
}) => {
    return (
        <div
            className="my-finances__bank-card add-new__card"
            onClick={openAddCardModal}
        >
            <Icon type={"plus"} width={20} height={20} />
            <p>{entities?.finances_attach_new_card?.value}</p>
        </div>
    );
};

import { endpoints } from "../../../store/review/review.api";
import _ from "lodash";
import Score from "../../../components/Score";
import Avatar from "../../../components/Avatar";
import { DateTime } from "luxon";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import classNames from "classnames";
import { TranslationObject } from "../../../models/translationModal";

const ReviewsOrders: React.FC<{ entities?: TranslationObject }> = ({ entities }) => {
    const { data: reviews } = endpoints.getReviews.useQuery({
        params: {
            page: 1,
            per_page: 3,
        },
    });
    const currentLanguage = getCurrentLanguage();

    if (reviews && reviews?.items?.length < 1) {
        return null;
    }

    return (
        <section className="reviews">
            <h2 className="title title--h4">{entities?.specialist_details_reviews?.value}</h2>
            <ul className="list-reset">
                {reviews?.items?.map((review) => (
                    <li
                        className="reviews__item"
                        key={_.uniqueId("review_specialist")}
                    >
                        <article className="review-card review-card--small">
                            <div className="review-card__header">
                                <Score
                                    width={24}
                                    height={24}
                                    score={review?.score}
                                    className="rating rating--lg"
                                />
                                <span
                                    className={classNames("review-card__tag", {
                                        visibility: review?.read_at,
                                    })}
                                >
                                    NEW
                                </span>
                                <div className="review-card__user">
                                    <div className="avatar avatar--rounded avatar--xxs">
                                        <Avatar
                                            width={40}
                                            height={40}
                                            avatar={review?.reviewer?.avatar}
                                        />
                                    </div>

                                    <h3 className="review-card__title title">
                                        {review?.reviewer?.name}{" "}
                                        {review?.reviewer?.family_name}
                                    </h3>
                                </div>
                            </div>
                            <p className="text">{review?.text}</p>
                            <time
                                className="review-card__date color-grey"
                                dateTime="2018-07-07"
                            >
                                {review?.created_at &&
                                    DateTime.fromSQL(
                                        review?.created_at
                                    )
                                        ?.setLocale(currentLanguage)
                                        ?.toFormat("dd MMMM yyyy")}
                            </time>
                        </article>
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default ReviewsOrders;

import { isCustomerUrl } from "../store/directories/selectors";
import { getUserRole } from "../store/user/selectors";

export function useUserRole(str?: string): { role: string } {
    let role: string | null = str || getUserRole();

    if (role && role !== "guest") {
        return { role };
    }

    role = isCustomerUrl() ? "customer" : "specialist";

    return { role };
}

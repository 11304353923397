import React from "react";
import { UserType } from "./index";
import Icon from "../Icon";

interface ChangeIconProps {
    userType: UserType;
    photo?: string | null;
}

export const ChangeIcon: React.FC<ChangeIconProps> = ({ userType, photo }) => {
    switch (userType) {
        case "customer":
            return (
                <>
                    <Icon
                        type={photo ? "delete-circle" : "plus-current-color"}
                        width={32}
                        height={32}
                        colorVariant={"blue"}
                    />
                </>
            );
        case "specialist":
            return (
                <>
                    <Icon
                        type={
                            photo
                                ? "delete-circle-current-color"
                                : "plus-current-color"
                        }
                        width={32}
                        height={32}
                        colorVariant={"greenLight"}
                    />
                </>
            );
    }
};

// LoadingContext.js
import React, { createContext, useContext, useState } from "react";
import WarnModal from "../../components/Modals/WarnModal";
import { TranslationObject } from "../../models/translationModal";

export interface INotification {
    type: "warn" | "confirm";
    description: string;
    title?: string;
}

const baseStateNotification: INotification = {
    type: "confirm",
    title: "",
    description: "",
};

const NotificationContext = createContext<{
    setNotification: React.Dispatch<React.SetStateAction<INotification>>;
}>({
    setNotification: () => null,
});

export const NotificationProvider: React.FC<{ children: React.ReactNode, entities?: TranslationObject }> = ({
    children, entities
}) => {
    const [notification, setNotification] = useState<INotification>(
        baseStateNotification
    );
    const value = { setNotification };
    const errorText = entities?.common_error?.value || ''
    const successText = entities?.common_saved?.value || ''

    return (
        <NotificationContext.Provider value={value}>
            <>
                {children}
                <WarnModal
                    visible={!!notification.description}
                    onClose={() => setNotification(baseStateNotification)}
                    title={
                        notification?.title ||
                        (notification.type === "confirm" ? successText : errorText)
                    }
                    description={notification.description}
                />
            </>
        </NotificationContext.Provider>
    );
};

export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error(
            "useNotification must be used within NotificationProvider"
        );
    }
    return context;
};

import React from "react";
import { OrderSpecialistItem } from "../../../models/orderModel";
import Card from "../../../components/CardSpecialist";
import { TranslationObject } from "../../../models/translationModal";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import Pagination from "../../../components/Pagination";

const List: React.FC<{
    entities?: TranslationObject;
    specialists: OrderSpecialistItem[];
    specialistIds: number[];
    pages: number[];
    currentPage: number;
    changeSpecialistIds: (value: number, name?: string) => void;
    changeCurrentPage: (value: number) => void;
    isEdit: boolean;
    isPrivate: boolean;
    isActiveSubmit?: boolean;
}> = ({
    entities,
    specialists,
    changeSpecialistIds,
    specialistIds,
    pages,
    changeCurrentPage,
    currentPage,
    isEdit,
    isPrivate,
    isActiveSubmit = true
}) => {
        const currentLanguage = getCurrentLanguage();
        const linkTo = `/${currentLanguage}/customer/${isEdit ? "edit-order" : "create-order"
            }/specialist`;

        return specialists?.length < 1 ? null : (
            <div className="find-pro__content">
                <ul className="find-pro__profiles-list">
                    {specialists.map(
                        (specialist: OrderSpecialistItem, key: number) => (
                            <Card
                                isUnresponsiveSpecialist={false}
                                linkTo={linkTo}
                                entities={entities}
                                key={key}
                                isSquare
                                isPrivate={isPrivate}
                                changeSpecialistIds={changeSpecialistIds}
                                specialist={specialist}
                                isAdd={specialistIds.indexOf(specialist?.id) !== -1}
                                pathNameRole="customer"
                                isEdit={isEdit}
                                isActiveSubmit={isActiveSubmit}
                            />
                        )
                    )}
                </ul>
                {pages?.length > 0 && (
                    <Pagination
                        entities={entities}
                        pages={pages}
                        changeCurrentPage={changeCurrentPage}
                        currentPage={currentPage}
                    />
                )}
            </div>
        );
    };

export default List;

import React from "react";
import Icon from "../../../../../components/Icon";
import { OrderSpecialistItem } from "../../../../../models/orderModel";
import { TranslationObject } from "../../../../../models/translationModal";
import { getCurrentLanguage } from "../../../../../store/directories/selectors";
import { chooseDeclension, getUrlTransliteName } from "../../../../../untils";
import { Link, generatePath } from "react-router-dom";

interface ChatSpecialistCardProps {
    specialist?: OrderSpecialistItem;
    pinOrUnpinChat?: () => unknown;
    pinnedChatStatus: boolean;
    handleLinkReviews: (userId?: string | number) => unknown;
    handleOpenModalComplain: (userId?: string | number) => unknown;
    entities?: TranslationObject;
}

export const ChatSpecialistCard: React.FC<ChatSpecialistCardProps> = (
    props
) => {
    const {
        specialist,
        pinOrUnpinChat,
        pinnedChatStatus,
        handleLinkReviews,
        handleOpenModalComplain,
        entities,
    } = props || {};

    const {
        id: specialistId,
        avatar,
        name = "",
        family_name = "",
        reviews_avg_score = "",
        deals_count,
        reviews_count,
        documents_is_verified,
        completed_orders_percentage,
        languages = [],
    } = specialist || {};

    const specialistFullName =
        name && family_name ? name + " " + family_name : "";
    const processedName = name ? getUrlTransliteName(name) : 'noname';

    const documentsStatus = documents_is_verified
        ? entities?.specialist_details_documents_verified?.value
        : entities?.specialist_details_documents_not_verified?.value;

    const currentLanguage = getCurrentLanguage();

    const dealCountsText = chooseDeclension(
        deals_count,
        entities?.specialist_details_deals?.value || "",
        currentLanguage === "ru"
    );

    const reviewCountsText = chooseDeclension(
        reviews_count,
        entities?.specialist_details_reviews_count?.value || "",
        currentLanguage === "ru"
    );

    const linkToSpecialistDetailPage =
        currentLanguage && specialistId
            ? generatePath("/:currentLanguage/specialist/:id", {
                  currentLanguage,
                  id: `${processedName}-${specialistId}`,
              })
            : "";

    return (
        <>
            <div className="user-card chat__header">
                <div className="avatar user-card__avatar">
                    {(avatar?.original_url && (
                        <img
                            src={avatar?.original_url}
                            width={138}
                            height={138}
                            alt="avatar"
                        />
                    )) || (
                        <Icon
                            type="camera_with_ellipse"
                            className="upload-foto__icon"
                            width={120}
                            height={120}
                        />
                    )}
                </div>
                <div className="user-card__info">
                    <Link to={linkToSpecialistDetailPage}>
                        <h2 className="user-card__name title title--f24">
                            {specialistFullName}
                        </h2>
                    </Link>
                    <div className="indicators user-card__indicators">
                        <span className="rating rating--middle">
                            {reviews_avg_score}
                            <Icon
                                type="star-current-color"
                                colorVariant={"light"}
                                width={12}
                                height={12}
                            />
                        </span>
                        {typeof deals_count === "number" && (
                            <span className="indicators__deal">
                                {deals_count + "\u00A0" + dealCountsText}
                            </span>
                        )}
                        {reviews_count && reviews_count > 0 ? (
                            <a
                                onClick={() => handleLinkReviews(specialistId)}
                                className="indicators__link-review link link--underline"
                            >
                                {reviews_count + "\u00A0" + reviewCountsText}
                            </a>
                        ) : (
                            <span className="indicators__link-review ">
                                {entities?.without_reviews?.value}
                            </span>
                        )}
                    </div>
                    <p className="user-note user-card__note">
                        {documents_is_verified && (
                            <Icon
                                className="user-note__icon"
                                type="ok_ellipse"
                                width={16}
                                height={16}
                            />
                        )}
                        {documentsStatus}
                    </p>
                    <p className="user-note user-card__note">
                        <svg
                            className="user-note__icon"
                            width={16}
                            height={16}
                            aria-hidden="true"
                        >
                            <use xlinkHref="#icon-like" />
                        </svg>
                        {typeof completed_orders_percentage === "number" &&
                            `${completed_orders_percentage}% ${entities?.order_completed_count?.value}`}
                    </p>
                </div>
                {!!languages?.length && (
                    <div className="languages languages--full languages--big user-card__lang">
                        <p className="languages__title">
                            {entities?.common_communication_languages?.value}:
                        </p>
                        <div className="languages__wrapper">
                            <ul className="languages__list">
                                {languages?.map(({ id, name, flag }) => (
                                    <li
                                        className="languages__item popover"
                                        key={id}
                                    >
                                        <span className="popover__tip">
                                            {name}
                                        </span>
                                        <img
                                            src={flag?.original_url}
                                            width={22}
                                            height={16}
                                            alt={name}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
                <nav className="user-card__nav user-nav">
                    <button
                        className="btn-reset menu-toggler"
                        type="button"
                        aria-label="menu"
                    >
                        <span className="menu-toggler__item" />
                        <span className="menu-toggler__item" />
                        <span className="menu-toggler__item" />
                    </button>
                    <ul className="user-nav__list list-reset">
                        <li className="user-nav__item">
                            <button
                                className="btn-reset user-nav__btn"
                                type="button"
                                onClick={pinOrUnpinChat}
                            >
                                {pinnedChatStatus
                                    ? entities?.chat_unpin_chat?.value
                                    : entities?.chat_pin_chat?.value}
                            </button>
                        </li>
                        <li className="user-nav__item">
                            <button
                                onClick={() =>
                                    handleOpenModalComplain(specialistId)
                                }
                                className="btn-reset user-nav__btn"
                                type="button"
                            >
                                {
                                    entities?.chat_complain_about_specialist
                                        ?.value
                                }
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    );
};

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface registrationState {
    phone: string;
    user_agreement: boolean;
    privacy_policy_agreement: boolean;
    name: string;
    family_name: string;
    language_ids: number[];
    password: string;
    password_confirmation: string;
    email: string;
    modaAuthShow?: boolean;
}

interface Item {
    name: string;
    value: string & boolean;
}

const initialState: registrationState = {
    phone: "+",
    user_agreement: false,
    privacy_policy_agreement: false,
    name: "",
    family_name: "",
    language_ids: [],
    password: "",
    password_confirmation: "",
    email: "",
    modaAuthShow: false,
};

export const registrationSlice = createSlice({
    name: "registration",
    initialState,
    reducers: {
        updateField(state, action: PayloadAction<Item>) {
            state[action.payload.name as keyof registrationState] =
                action.payload.value;
        },
        updateLanguageIds(state, action: PayloadAction<number>) {
            state.language_ids = state.language_ids.includes(action.payload)
                ? state.language_ids.filter((id) => id !== action.payload)
                : state.language_ids.concat(action.payload);
        },
        showModalAuth(state, action: PayloadAction<boolean>) {
            state.modaAuthShow = action.payload;
        },
        clearRegistrationState: () => initialState,
    },
});

export const registrationActions = registrationSlice.actions;
export const registrationReducer = registrationSlice.reducer;

import { ProfilePayload } from "../../../models/userModels";
import { TranslationObject } from "../../../models/translationModal";

export const getSpecialistRole = (
    profile?: ProfilePayload,
    entities?: TranslationObject
):
    | { role: "manager" | "employee" | "private person"; translate: string }
    | undefined => {
    if (!profile || !entities) return;

    const company = profile?.company;

    const companyManager = profile?.is_company_manager;

    if (company && companyManager) {
        return {
            role: "manager",
            translate:
                entities?.specialist_details_company_manager?.value ?? "",
        };
    }

    if (company && !companyManager) {
        return {
            role: "employee",
            translate:
                entities?.specialist_details_company_employee?.value ?? "",
        };
    }

    return {
        role: "private person",
        translate: entities?.specialist_details_individual_person?.value ?? "",
    };
};

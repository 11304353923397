import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    ServerResponse,
    CustomPagePayload,
    CustomPageResponse,
    paramPage,
    Language,
    LanguageRootObject,
    SubTypesObject,
    SubTypesPayload,
    FiltersPayload,
    FiltersObject,
    IdProps,
    TypesObject,
    TypesPayload,
    CategoriesRootObject,
    Category,
    DirectoryPayload,
} from "../../models/directoryModel";
import { TextPagePayload, TextPageResponse } from "../../models/textPageModel";
import {
    getPage,
    getServiceTypes,
    getServiceSubTypes,
    getTranslations,
    getFilters,
    getServiceCategories,
} from "./api";
import {
    Translation,
    TranslationRootObject,
} from "../../models/translationModal";
import { changeHeaders } from "../request";

export const directoriesApi = createApi({
    reducerPath: "directories/api",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
        prepareHeaders: (headers, { getState }) =>
            changeHeaders(headers, { getState }),
    }),
    tagTypes: ["Localized"],
    endpoints: (build) => ({
        getDirectories: build.query<DirectoryPayload, void>({
            query: () => `directories`,
            providesTags: [{ type: "Localized" }],
            transformResponse: (response: ServerResponse) => response.payload,
        }),
        getCustomPage: build.query<CustomPagePayload, paramPage>({
            query: ({ id, url }) => getPage({ id, url }),
            providesTags: [{ type: "Localized" }],
            transformResponse: (response: CustomPageResponse) =>
                response.payload,
        }),
        getTextPage: build.query<TextPagePayload, paramPage>({
            query: ({ id, url }) => getPage({ id, url }),
            providesTags: [{ type: "Localized" }],
            transformResponse: (response: TextPageResponse) => response.payload,
        }),
        getLanguages: build.query<Language[], void>({
            query: () => `languages`,
            transformResponse: (response: LanguageRootObject) =>
                response.payload?.languages,
            providesTags: [{ type: "Localized" }],
        }),
        getTranslations: build.query<Translation[], void>({
            query: () => getTranslations(),
            providesTags: [{ type: "Localized" }],
            transformResponse: (response: TranslationRootObject) =>
                response?.payload?.translations,
        }),
        getServiceCategories: build.query<Category[], void>({
            query: () => getServiceCategories(),
            providesTags: [{ type: "Localized" }],
            transformResponse: (response: CategoriesRootObject) =>
                response?.payload,
        }),
        getServiceTypes: build.query<TypesPayload[], IdProps>({
            query: ({ id }) => getServiceTypes({ id }),
            providesTags: [{ type: "Localized" }],
            transformResponse: (response: TypesObject) => response?.payload,
        }),
        getServiceSubTypes: build.query<SubTypesPayload[], IdProps>({
            query: ({ id }) => getServiceSubTypes({ id }),
            providesTags: [{ type: "Localized" }],
            transformResponse: (response: SubTypesObject) => response.payload,
        }),
        getFilters: build.query<FiltersPayload[], IdProps>({
            query: ({ id }) => getFilters({ id }),
            providesTags: [{ type: "Localized" }],
            transformResponse: (response: FiltersObject) => response?.payload,
        }),
    }),
});

export const { useGetDirectoriesQuery, endpoints, util } = directoriesApi;

import React, { ReactNode, useEffect, useState } from "react";
import { toArray } from "../../../lib/converters";
import classNames from "classnames";
import { InvalidFieldsProps } from "../Input";

const Checkbox: React.FC<{
    children: ReactNode;
    required?: boolean;
    value?: boolean;
    invalidFields?: InvalidFieldsProps;
    error?: any;
    name?: string;
    onChange?: () => void;
    classes?: string;
    disabled?: boolean
    showError?: boolean
}> = ({
    children,
    required,
    invalidFields,
    error,
    name,
    onChange,
    value,
    classes = "",
    disabled = false,
    showError = true
}) => {
    const [checked, setChecked] = useState<boolean>(false);

    const flattenName =
        name && name?.split(`]`)?.join(``)?.split(`[`)?.join(`.`);

    useEffect(() => {
        setChecked(value ?? false);
    }, [value]);

    const textError =
        flattenName &&
        !!invalidFields &&
        invalidFields?.has(flattenName) &&
        error?.errors[flattenName] &&
        toArray(error?.errors[flattenName]).join(`, `);

    const handleChange = () => {
        if (onChange) {
            return onChange();
        }
        setChecked(!checked);
    };

    return (
        <div
            className={classNames("custom-toggle custom-toggle--checkbox", {
                "is-invalid":
                    !!invalidFields &&
                    flattenName &&
                    invalidFields?.has(flattenName),
                [classes]: classes,
            })}
        >
            <label>
                <input
                    type="checkbox"
                    name={name}
                    required={required}
                    value={checked ? 1 : 0}
                    checked={checked}
                    onChange={handleChange}
                    disabled={disabled}
                />
                <span className="custom-toggle__icon"></span>
                <span className="custom-toggle__label">{children}</span>
            </label>
            {showError && !!invalidFields &&
                flattenName &&
                invalidFields.has(flattenName) && (
                    <div className="custom-toggle__error">{textError}</div>
                )}
        </div>
    );
};

export default Checkbox;

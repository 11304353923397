import React, { useEffect, useState } from "react";
import Input from "../../../components/Form/Input";
import LanguagesChecklist from "../../../components/LanguagesChecklist";
import { TranslationObject } from "../../../models/translationModal";
import { useActions } from "../../../hooks/actions";
import { LANGUAGE_IDS } from "../../../store/order/constant";
import {
    getItemCreateOrder,
    getItemEditOrder,
    updateItemCreateOrder,
} from "../../../store/order/selectors";
import { createOrderState } from "../../../store/order/createOrder.slice";
import Icon from "../../../components/Icon";
import { ProfilePayload } from "../../../models/userModels";

const LanguagesFilter: React.FC<{
    entities?: TranslationObject;
    isEdit: boolean;
    handleClearActiveMobileFilter: () => void;
    profile?: ProfilePayload;
}> = ({ entities, isEdit, handleClearActiveMobileFilter, profile }) => {
    const { updateEditOrderField, updateCreateOrderField } = useActions();
    const [search, setSearch] = useState<string>("");
    const languageIds = getItemCreateOrder(LANGUAGE_IDS) || [];
    const editOrderLanguagesIds = getItemEditOrder(LANGUAGE_IDS) || [];

    const handleChangeLanguagesEditOrder = (id: number) => {
        updateEditOrderField({
            name: LANGUAGE_IDS,
            value:
                editOrderLanguagesIds.indexOf(id) !== -1
                    ? editOrderLanguagesIds.filter(
                        (elem: number) => elem !== id
                    )
                    : editOrderLanguagesIds.concat(id),
        });
        return;
    };

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) =>
        updateItemCreateOrder(
            {
                name: name,
                value: value,
            },
            updateCreateOrderField
        );

    const handleChangeLanguages = (id: number) => {
        if (isEdit) {
            handleChangeLanguagesEditOrder(id);
        }
        handleChangeLanguagesCreateOrder(id);
    };

    const handleChangeLanguagesCreateOrder = (id: number) => {
        let ids = languageIds;
        if (languageIds.includes(id)) {
            ids = languageIds.filter((elem) => elem !== id);
        } else {
            ids = languageIds.concat(id);
        }
        updateField({
            name: LANGUAGE_IDS,
            value: ids,
        });
    };

    useEffect(() => {
        updateField({
            name: LANGUAGE_IDS,
            value: profile?.language_ids || []
        });
    }, [profile?.language_ids])

    return (
        <>
            <button
                className="filter__subtitle filter__subtitle--margin"
                type="button"
                onClick={handleClearActiveMobileFilter}
            >
                {entities?.registration_languages?.value}
                <Icon type="arrow-left-pagination" />
            </button>
            <div className="filter__wrap-block">
                <Input
                    placeholder=""
                    type="search"
                    value={search}
                    onChange={(value: string) => setSearch(value)}
                />
                <LanguagesChecklist
                    search={search}
                    languageIds={languageIds}
                    onChange={handleChangeLanguages}
                    entities={entities}
                    profile={profile}
                    isHideChecked
                />
            </div>
        </>
    );
};

export default LanguagesFilter;

import React from "react";
import Input, { InvalidFieldsProps } from "../Input";

interface INameInput {
    nameInputValue: string;
    nameInputChange: (value: string) => void;
    nameInputPlaceholder?: string;
    inputNameInvalidFields?: InvalidFieldsProps;
    inputNameError?: unknown;
}

interface IFamilyNameInput {
    familyNameInputValue: string;
    familyNameInputChange: (value: string) => void;
    familyNameInputPlaceholder?: string;
}

interface ProfileNameProps {
    legend: string;
    nameInput: INameInput;
    familyNameInput: IFamilyNameInput;
    error: any;
    invalidFields: any;
}

export const ProfileName: React.FC<ProfileNameProps> = (props) => {
    const { legend, nameInput, familyNameInput, error, invalidFields } =
        props || {};

    const {
        nameInputValue,
        nameInputPlaceholder = "",
        nameInputChange,
    } = nameInput || {};
    const {
        familyNameInputValue,
        familyNameInputPlaceholder = "",
        familyNameInputChange,
    } = familyNameInput || {};

    return (
        <>
            <div className="profile__fieldset profile__name">
                <fieldset>
                    <legend>{legend}{legend && <span className="asterisk">*</span>}</legend>
                    <div className="form__row">
                        <Input
                            type="text"
                            name="name"
                            value={nameInputValue}
                            onChange={nameInputChange}
                            id="name"
                            placeholder={nameInputPlaceholder}
                            invalidFields={invalidFields}
                            error={error}
                            maxLength={75}
                        />
                    </div>
                    <div className="form__row">
                        <Input
                            type="text"
                            name="family_name"
                            value={familyNameInputValue}
                            onChange={familyNameInputChange}
                            id="family_name"
                            placeholder={familyNameInputPlaceholder}
                            invalidFields={invalidFields}
                            error={error}
                            maxLength={75}
                        />
                    </div>
                </fieldset>
            </div>
        </>
    );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    CUSTOM_SERVICE_SUB_TYPE,
    CUSTOM_SERVICE_TYPE,
    DESCRIPTION,
    ENDED_AT,
    FILTER_PAGES,
    ID,
    IS_INDIVIDUAL,
    IS_MY_ADDRESS,
    IS_PRIVATE,
    IS_PUBLISHED,
    IS_REMOTE,
    IS_SPECIALIST_ADDRESS,
    LANGUAGE_IDS,
    MY_ADDRESS,
    PRICE,
    REPEAT_DAY,
    REPEAT_RANGE,
    SERVICE_CATEGORY_ID,
    SERVICE_CATEGORY_NAME,
    SERVICE_CATEGORY_SLUG,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_SUB_TYPE_NAME,
    SERVICE_SUB_TYPE_SLUG,
    SERVICE_TYPE_ID,
    SERVICE_TYPE_NAME,
    SERVICE_TYPE_SLUG,
    SPECIALIST_ADDRESS,
    SPECIALIST_IDS,
    SPECIALIST_NAME,
    STARTED_AT,
    STATUS,
    TITLE,
} from "./constant";
import { LocationData } from "../../models/orderModel";
import { getItemStore } from "../../untils";

export interface createOrderState {
    [SERVICE_CATEGORY_ID]?: number | null;
    [SERVICE_CATEGORY_NAME]?: string | null;
    [SERVICE_CATEGORY_SLUG]?: string | null;
    [CUSTOM_SERVICE_TYPE]?: string;
    [SERVICE_TYPE_ID]?: number | null;
    [SERVICE_TYPE_NAME]?: string | null;
    [SERVICE_TYPE_SLUG]?: string | null;
    [CUSTOM_SERVICE_SUB_TYPE]?: string;
    [SERVICE_SUB_TYPE_IDS]?: number[];
    [SERVICE_SUB_TYPE_NAME]?: string | null;
    [SERVICE_SUB_TYPE_SLUG]?: string | null;
    filter_pages?: {
        service_type_filter_id: number;
        service_type_filter_value_ids: number[];
        custom_filter_value?: string;
    }[];
    [IS_REMOTE]?: boolean;
    [IS_MY_ADDRESS]?: boolean;
    [IS_SPECIALIST_ADDRESS]?: boolean;
    [IS_SPECIALIST_ADDRESS]?: boolean;
    [MY_ADDRESS]?: LocationData | null;
    [SPECIALIST_ADDRESS]?: LocationData | null;
    [STARTED_AT]?: string;
    [ENDED_AT]?: string;
    [REPEAT_RANGE]?: string;
    [REPEAT_DAY]?: number | null;
    [PRICE]?: string | null;
    [DESCRIPTION]?: string;
    [ID]?: number | null;
    [TITLE]?: string;
    [STATUS]?: string;
    [IS_PRIVATE]?: boolean;
    [IS_PUBLISHED]?: boolean;
    [LANGUAGE_IDS]?: number[];
    [SPECIALIST_IDS]?: number[];
    [SPECIALIST_NAME]?: string;
    page?: number;
    activeStep?: string | null;
    userId?: number | null;
    [IS_INDIVIDUAL]?: boolean;
    hasErrorInAddress?: boolean;
    execution_at?: string;
    isAllowSelectSpecialist?: boolean;
    maxCountSearchAi?: number;
}

export const clearCreateOrderState: createOrderState = {
    activeStep: null,
    [SERVICE_CATEGORY_ID]: null,
    [SERVICE_CATEGORY_NAME]: null,
    [SERVICE_CATEGORY_SLUG]: null,
    [CUSTOM_SERVICE_TYPE]: "",
    [SERVICE_TYPE_ID]: null,
    [SERVICE_TYPE_NAME]: null,
    [SERVICE_TYPE_SLUG]: null,
    [CUSTOM_SERVICE_SUB_TYPE]: "",
    [SERVICE_SUB_TYPE_IDS]: [],
    [SERVICE_SUB_TYPE_NAME]: null,
    [SERVICE_SUB_TYPE_SLUG]: null,
    [FILTER_PAGES]: [],
    [IS_REMOTE]: false,
    [IS_MY_ADDRESS]: false,
    [IS_SPECIALIST_ADDRESS]: false,
    [MY_ADDRESS]: null,
    [SPECIALIST_ADDRESS]: null,
    [STARTED_AT]: "",
    [ENDED_AT]: "",
    [REPEAT_RANGE]: "",
    [REPEAT_DAY]: null,
    [PRICE]: null,
    [DESCRIPTION]: "",
    [ID]: null,
    [TITLE]: "",
    [STATUS]: "draft",
    [IS_PRIVATE]: false,
    [IS_PUBLISHED]: false,
    [LANGUAGE_IDS]: [],
    [SPECIALIST_IDS]: [],
    [SPECIALIST_NAME]: '',
    userId: null,
    [IS_INDIVIDUAL]: false,
    hasErrorInAddress: false,
    isAllowSelectSpecialist: true,
    maxCountSearchAi: 3,
};

export const getInitialState = () => {
    let obj: createOrderState = clearCreateOrderState;
    Object.keys(clearCreateOrderState).map((elem) => {
        obj = {
            ...obj,
            [elem]:
                getItemStore(elem) ||
                clearCreateOrderState[elem as keyof createOrderState],
        };
    });
    return obj;
};

const initialState = getInitialState();

export const createOrderSlice = createSlice({
    name: "createOrder",
    initialState,
    reducers: {
        updateCreateOrderField<
            createOrderState,
            K extends keyof createOrderState
        >(
            state: createOrderState,
            action: PayloadAction<{ name: K; value: createOrderState[K] }>
        ) {
            state[action.payload.name] = action.payload.value;
        },
        clearCreateOrderFields<
            createOrderState,
            K extends keyof createOrderState
        >(state: createOrderState, action: PayloadAction<K[]>) {
            const data = {
                ...(clearCreateOrderState as createOrderState),
            };
            if (action.payload?.length > 0) {
                action.payload?.forEach((elem) => delete data[elem]);
            }
            state = Object.assign(state, data);
        },
    },
});

export const createOrderActions = createOrderSlice.actions;
export const createOrderReducer = createOrderSlice.reducer;

import React from "react";
import classNames from "classnames";
import Icon from "../../Icon";
import { Link } from "react-router-dom";
import DeleteButton from "../../../pages/Profile/DeleteButton";
import LogoutButton from "../../../pages/Profile/LogoutButton";
import { TranslationObject } from "../../../models/translationModal";
import { getDirectories, getUrl } from "../../../store/directories/selectors";

interface ProfileButtonsProps {
    entities?: TranslationObject;
    showEmail: boolean;
    setShowEmail: () => unknown;
    setType: (type: string) => unknown;
    currentLanguage: string;
    contextUser?: "customer" | "specialist";
    isSpecialist?: boolean;
}

export const ProfileButtons: React.FC<ProfileButtonsProps> = (props) => {
    const {
        showEmail,
        setShowEmail,
        setType,
        entities,
        currentLanguage,
        isSpecialist = false,
        contextUser = "customer",
    } = props || {};

    const { data: directories } = getDirectories();

    const bonus = directories?.settings?.email_verification_bonus_amount || 0;

    const registrationEmailBonus =
        entities?.registration_email_bonus?.value?.replace(
            ":bonus",
            String(bonus)
        );

    const url = (str: string) => getUrl(str);

    return (
        <>
            <div className="profile__buttons">
                <div
                    className={classNames("profile__email-wrapper", {
                        "visually-hidden": showEmail,
                    })}
                >
                    <div className="profile__email-button-wrapper">
                        <button
                            className="profile__email-button link link--underline"
                            type="button"
                            onClick={setShowEmail}
                        >
                            {entities?.common_fill_email?.value}
                            <Icon
                                className="profile__email-icon"
                                type="email"
                                width={21}
                                height={20}
                            />
                        </button>
                    </div>
                    <p className="profile__email-text">
                        {registrationEmailBonus + " "}
                        <Link
                            to={url("bonuses_and_partners")}
                            className="link link--underline"
                        >
                            {entities?.registration_bonus_link?.value}
                        </Link>
                    </p>
                </div>
                <div className="profile__link-wrapper">
                    <a
                        className="profile__link-link link link--underline"
                        onClick={() => setType("password")}
                    >
                        {entities?.profile_change_password_link?.value}
                        <Icon
                            className="profile__link-icon"
                            type="shield-current-color"
                            width={16}
                            height={20}
                            colorVariant={
                                contextUser === "customer"
                                    ? "blue"
                                    : "greenLight"
                            }
                        />
                    </a>
                </div>
                <DeleteButton
                    isSpecialist={isSpecialist}
                    currentLanguage={currentLanguage}
                    entities={entities}
                />
                <LogoutButton
                    isSpecialist={isSpecialist}
                    currentLanguage={currentLanguage}
                    entities={entities}
                />
            </div>
        </>
    );
};

import React, { useState } from "react";
import Modal from "../../components/Modal";
import { ComponentProps } from "../../models/models";
import classNames from "classnames";
import { useLogout } from "../../hooks/useLogout/useLogout";

interface Props extends ComponentProps {
    isSpecialist: boolean;
}

const LogoutButton: React.FC<Props> = ({ entities, isSpecialist }) => {
    const [visible, setVisible] = useState<boolean>(false);

    const logout = useLogout();

    return (
        <>
            <Modal
                title={entities?.profile_logout_text?.value}
                visible={visible}
                onClose={() => setVisible(false)}
            >
                <div className="modal__content-buttons">
                    <button
                        className="btn btn--transparent"
                        type="button"
                        onClick={() => setVisible(false)}
                    >
                        {entities?.common_cancel_button?.value}
                    </button>
                    <button
                        className={classNames("btn modal__content-button", {
                            "modal__content-button--background": isSpecialist,
                            "modal__content-button--background-customer":
                                !isSpecialist,
                        })}
                        type="button"
                        onClick={logout}
                    >
                        {entities?.profile_logout_button_ok?.value}
                    </button>
                </div>
            </Modal>
            <button
                className="btn profile__button"
                type="button"
                onClick={() => setVisible(true)}
            >
                {entities?.logout_link_value?.value}
            </button>
        </>
    );
};

export default LogoutButton;

import React, { useEffect, useMemo, useState } from "react";

import AdvertisingCard from "./Item";
import AdvertisingBanner from "./Banner";
import {
    advertisementEndpoints,
    useSetThirdPartiesClickMutation,
} from "../../store/advertisement/advertisement.api";
import { TranslationObject } from "../../models/translationModal";
import _ from "lodash";
import { endpoints } from "../../store/user/user.api";
import LogoutModalButton from "../LogoutModalButton/LogoutModalButton";
import { ProfilePayload } from "../../models/userModels";
import Slider from "../Slider";
import Slide from "../Slider/Slide";

interface AdvertisingProps {
    countCards?: number;
    typeAdvertising?: "specialists" | "specialistsMainPage" | "thirdParties" | "thirdPartiesMain" | "specialistsCustomerPage";
    entities?: TranslationObject;
    isCustomerPage?: boolean;
    isHorizontal?: boolean;
}

const Advertising: React.FC<AdvertisingProps> = ({
    countCards = 5,
    typeAdvertising = "specialists",
    entities,
    isCustomerPage = false,
    isHorizontal = false
}) => {
    const [
        getAdvertisementSpecialists,
        { data: AdvertisementSpecialistsData, isSuccess: isSuccessSpecialist },
    ] = advertisementEndpoints.getAdvertisementSpecialists.useLazyQuery();
    const [
        getAdvertisementSpecialistsMainPage,
        { data: AdvertisementSpecialistsDataMainPage },
    ] = advertisementEndpoints.getAdvertisementSpecialistsMainPage.useLazyQuery();

    const [getThirdParties, { data: ThirdPartiesData }] =
        advertisementEndpoints.getThirdParties.useLazyQuery();

    const [getThirdPartiesMain, { data: ThirdPartiesMainData }] =
        advertisementEndpoints.getThirdPartiesMain.useLazyQuery();

    const { data: profile } = endpoints.getProfile.useQuery();
    const [setThirdPartiesClick] = useSetThirdPartiesClickMutation();
    const [isSpecialist, setIsSpecialist] = useState<boolean>(false);

    useEffect(() => {
        if (typeAdvertising === "specialists" || typeAdvertising === "specialistsCustomerPage") {
            getAdvertisementSpecialists();
        } else if (typeAdvertising === "specialistsMainPage") {
            getAdvertisementSpecialistsMainPage();
        } else if (typeAdvertising === "thirdParties") {
            getThirdParties();
        }  else if (typeAdvertising === "thirdPartiesMain") {
            getThirdPartiesMain();
        }
    }, [typeAdvertising]);

    useEffect(() => {
        setIsSpecialist(profile?.user_role === "specialist");
    }, [profile]);

    useEffect(() => {
        if (isSuccessSpecialist && typeAdvertising === "specialistsCustomerPage" && !AdvertisementSpecialistsData?.length) {
            getAdvertisementSpecialistsMainPage();
        }
    }, [isSuccessSpecialist])

    const AdvertisementSpecialists = useMemo(() => {
        if (countCards) {
            return AdvertisementSpecialistsData?.slice(0, countCards) || [];
        }
        return AdvertisementSpecialistsData || [];
    }, [countCards, AdvertisementSpecialistsData]);

    const AdvertisementSpecialistsMainPage = useMemo(() => {
        if (countCards) {
            return AdvertisementSpecialistsDataMainPage?.slice(0, countCards) || [];
        }
        return AdvertisementSpecialistsDataMainPage || [];
    }, [countCards, AdvertisementSpecialistsDataMainPage]);

    const ThirdParties = useMemo(() => {
        if (countCards) {
            return ThirdPartiesData?.slice(0, countCards) || [];
        }
        return ThirdPartiesData || [];
    }, [countCards, ThirdPartiesData]);

    const ThirdPartiesMain = useMemo(() => {
        if (countCards) {
            return ThirdPartiesMainData?.slice(0, countCards) || [];
        }
        return ThirdPartiesMainData || [];
    }, [countCards, ThirdPartiesMainData]);

    const arr = typeAdvertising === "thirdParties" ? ThirdParties : ThirdPartiesMain;

    return (
        <>
            {(typeAdvertising === "thirdParties" || typeAdvertising === "thirdPartiesMain")
                && <Slider length={arr.length} isSpecialistPage={!isHorizontal} isHorizontal={isHorizontal}>
                    <>
                        {
                            arr.map((el) => (
                                <Slide>
                                    <li
                                        className="useful-info__item"
                                        key={_.uniqueId("third_parties")}
                                        // onClick={() => setThirdPartiesClick(el?.id)}
                                    >
                                        <a
                                            className='useful-info__item__link'
                                            href={el?.url || ""}
                                            target="_blank"
                                        >
                                            <img
                                                className={"useful-info__item__img"}
                                                src={el?.photo?.original_url || ""}
                                                alt={el?.photo?.file_name || "advertisement"}
                                            />
                                        </a>
                                    </li>
                                </Slide>
                            ))
                        }
                    </>
                </Slider>
            }

            {/* {typeAdvertising === "thirdParties" && isSpecialist
                && <Slider length={ThirdParties.length} isSpecialistPage={true}>
                    <>
                        {
                            ThirdParties.map((el) => (
                                <Slide isSpecialistPage={true}>
                                    <li
                                        className="useful-info__item"
                                        key={_.uniqueId("third_parties")}
                                    >
                                        <LogoutModalButton
                                            isSpecialist={isSpecialist}
                                            entities={entities}
                                            classes={["useful-info__item__link"]}
                                        >
                                            <img
                                                className={"useful-info__item__img"}
                                                src={el?.photo?.original_url || ""}
                                                alt={el?.photo?.file_name || "advertisement"}
                                            />
                                        </LogoutModalButton>
                                    </li>
                                </Slide>
                            ))
                        }
                    </>
                </Slider>
            } */}

            {/* {typeAdvertising === "thirdPartiesMain"
                && <Slider length={ThirdPartiesMain.length} isHorizontal={true}>
                    <>
                        {
                            ThirdPartiesMain.map((el) => (
                                <Slide>
                                    <li
                                        className="useful-info__item"
                                        key={_.uniqueId("third_parties")}
                                    >
                                        <LogoutModalButton
                                            isSpecialist={isSpecialist}
                                            entities={entities}
                                            classes={["useful-info__item__link"]}
                                        >
                                            <img
                                                className={"useful-info__item__img"}
                                                src={el?.photo?.original_url || ""}
                                                alt={el?.photo?.file_name || "advertisement"}
                                            />
                                        </LogoutModalButton>
                                    </li>
                                </Slide>
                            ))
                        }
                    </>
                </Slider>
            } */}

            {(typeAdvertising === "specialists"
                || (typeAdvertising === "specialistsCustomerPage") && !!AdvertisementSpecialists.length)
                && <Slider
                        length={AdvertisementSpecialists.length}
                        isCustomerPage={isCustomerPage}
                        isHorizontal={isHorizontal}
                    >
                        <>
                            {
                                AdvertisementSpecialists?.map((advertisementSpecialists) => (
                                    <Slide>
                                        <AdvertisingCard
                                            key={_.uniqueId("ads")}
                                            id={advertisementSpecialists?.specialist_id}
                                            advertisementSpecialists={advertisementSpecialists}
                                            entities={entities}
                                        />
                                    </Slide>
                                ))
                            }
                        </>
                    </Slider>
            }

            {(typeAdvertising === "specialistsMainPage" || (typeAdvertising === "specialistsCustomerPage" && !AdvertisementSpecialists.length))
                && <Slider length={AdvertisementSpecialistsMainPage.length} isCustomerPage={isCustomerPage}>
                    <>
                        {
                            AdvertisementSpecialistsMainPage?.map((advertisementSpecialists) => (
                                <Slide>
                                    <AdvertisingBanner
                                        advertisementSpecialists={advertisementSpecialists}
                                        profile={profile}
                                        entities={entities}
                                    />
                                </Slide>
                            ))
                        }
                    </>
                </Slider>
            }
        </>
    );
};

export default React.memo(Advertising);

import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import DateInput from "../DateInput";
import Input from "../Form/Input";
import { convertDateToJSDate, convertDateFormatISO } from "../../untils";
import { TranslationObject } from "../../models/translationModal";

interface Props {
    visible: boolean;
    onClose: () => void;
    entities?: TranslationObject;
    save: (value: string) => void;
    date: string;
}

const ChangeDateModal: React.FC<Props> = ({
    visible,
    onClose,
    entities,
    save,
    date,
}) => {
    const [value, setValue] = useState<string>("");

    useEffect(() => {
        if (date) {
            setValue(date);
        }
    }, [date]);

    const onChange = (value: Date) => {
        const date = convertDateFormatISO(value);
        setValue(date);
    };

    return (
        <Modal
            title={entities?.order_execution_date?.value}
            visible={visible}
            onClose={onClose}
            modificators={["modal-order-date"]}
        >
            <>
                <div className="custom-input">
                    <DateInput
                        input={
                            <Input
                                placeholder=""
                                icon={{
                                    type: "calendar",
                                    width: 24,
                                    height: 24,
                                    className: "date-field__icon",
                                }}
                                classes={["custom-input--small"]}
                            />
                        }
                        value={value ? convertDateToJSDate(value) : null}
                        onChange={onChange}
                        dateFormat="dd.MM.yyyy"
                    />
                </div>
                <div className="modal__content-buttons">
                    <button
                        className="btn modal__content-button"
                        type="button"
                        onClick={() => save(value)}
                    >
                        {entities?.login_save_button?.value}
                    </button>
                </div>
            </>
        </Modal>
    );
};

export default ChangeDateModal;

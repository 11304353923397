import React, { useEffect, useState } from "react";
import Checkbox from "../Form/Checkbox";
import RadioInput from "../Form/RadioInput";
import Loader from "../Loader";
import { TranslationObject } from "../../models/translationModal";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { InvalidFieldsProps } from "../Form/Input";

const CheckList: React.FC<{
    onChange: (value: number) => void;
    name?: string;
    checkListName?: string;
    items: { name: string; id: number }[];
    ids?: number[];
    isMultiple?: boolean;
    isSuccess: boolean;
    id?: number | null;
    entities?: TranslationObject;
    inputName?: string;
    error?: any;
    invalidFields?: InvalidFieldsProps;
    classes?: string;
    showError?: boolean;
    isDefShowList?: boolean;
}> = ({ 
    items, 
    ids,
    name,
    isMultiple,
    onChange,
    isSuccess,
    id,
    entities,
    inputName,
    error,
    invalidFields,
    classes,
    showError,
    isDefShowList
}) => {
    const [list, setList] = useState<{ name: string; id: number }[]>([]);
    const [showLongList, setShowLongList] = useState(isDefShowList || false);

    useEffect(() => {
        if (isSuccess && Array.isArray(items)) {
            if (showLongList) {
                setList(items);
            } else {
                setList(items?.slice(0, 3));
            }
        }
    }, [isSuccess, items]);

    const handelClick = () => {
        if (!showLongList) {
            setList(items);
        } else {
            setList(items?.slice(0, 3));
        }

        setShowLongList(!showLongList);
    };

    return isSuccess ? (
        <>
            <SimpleBar autoHide={false} forceVisible={true} style={{ maxHeight: 500 }}>
                <div className="form__row">
                    {list?.map((elem: { name: string; id: number }, key: number) => {
                        const checked = isMultiple
                            ? ids?.includes(elem?.id)
                            : id
                            ? id === elem?.id
                            : false;
                        return isMultiple ? (
                            <Checkbox
                                key={key}
                                onChange={() => onChange(elem?.id)}
                                value={checked}
                                error={error}
                                invalidFields={invalidFields}
                                classes={classes}
                                name={inputName}
                                showError={showError}
                            >
                                {elem?.name}
                                {name}
                            </Checkbox>
                        ) : (
                            <RadioInput
                                onClick={() => onChange(elem?.id)}
                                key={key}
                                checked={checked}
                                label={elem?.name}
                                name={inputName}
                                error={error}
                                invalidFields={invalidFields}
                            />
                        );
                    })}
                </div>
            </SimpleBar>
            {(items?.length > 3 && !isDefShowList) && (
                <button
                    className="btn btn--view-all"
                    type="button"
                    onClick={handelClick}
                >
                    {showLongList
                        ? entities?.order_hide?.value
                        : entities?.order_show_all?.value}
                </button>
            )}
        </>
    ) : (
        <Loader />
    );
};

export default CheckList;

import cn from "classnames";
import { TranslationObject } from "../../../models/translationModal";
import { ProfilePayload } from "../../../models/userModels";
import Icon from "../../../components/Icon";
import { useActions } from "../../../hooks/actions";
import { IS_INTERESTING, STATUS } from "../../../store/order/constant";
import Checkbox from "../../../components/Form/Checkbox";
import { getFilter } from "../../../store/orders/selectors";

interface Props {
  entities?: TranslationObject;
  profile?: ProfilePayload;
  activeMobileFilter: string;
  setActiveMobileFilter: () => void;
}

const Uninteresed: React.FC<Props> = ({ entities, profile, activeMobileFilter, setActiveMobileFilter }) => {
  const { setOrdersFilter } = useActions();
  const isInteresting = getFilter(IS_INTERESTING);
  const isSpecialist = profile?.user_role === "specialist";

  const handleChange = (value: boolean) => {
    setOrdersFilter({
      name: STATUS,
      value: 'all',
    });

    setOrdersFilter({
        name: IS_INTERESTING,
        value,
    });
  };

  if (!isSpecialist) {
    return null;
  }

  return (
    <div
      className={cn("filter__block", { "is-open": activeMobileFilter === "uninterested",})}
    >
      <button
          className="filter__subtitle"
          type="button"
          onClick={setActiveMobileFilter}
      >
          {entities?.common_show_not_uninteresting?.value}
          <Icon type="arrow-left-pagination" width={9} height={18} />
      </button>
      <div className="filter__wrap-block">
        <Checkbox
            value={isInteresting}
            onChange={() => handleChange(!isInteresting)}
        >
          {entities?.common_not_interested?.value}
        </Checkbox>
      </div>
    </div>
  )
}

export default Uninteresed;
import React from "react";
import { endpoints } from "../../store/directories/directories.api";
import classNames from "classnames";
import TypeItem from "./TypeItem";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { TranslationObject } from "../../models/translationModal";
import { Category } from "../../models/directoryModel";

const Types: React.FC<{
    id: number;
    category: Category;
    entities?: TranslationObject;
    isSpecialist?: boolean;
}> = ({ id, entities, isSpecialist, category }) => {
    const currentLanguage = getCurrentLanguage();
    const { data: types } = endpoints.getServiceTypes.useQuery({
        id,
        currentLanguage,
    });

    return (
        <div
            className={classNames(
                "accordion__content accordion__content-parent"
            )}
        >
            <div className="accordion__wrapper">
                <div className="accordion">
                    {types?.map((type, key) => (
                        <TypeItem 
                            key={key + "type"}
                            category={category}
                            type={type}
                            entities={entities}
                            isSpecialist={isSpecialist}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Types;

import React, { useEffect, MouseEventHandler, useState } from "react";
import { Link } from "react-router-dom";
import { endpoints } from "../../store/order/order.api";
import { formatQueryString } from "../../untils";
import { getCurrentLanguage } from "../../store/directories/selectors";
import Form from "../Form/Form";
import Avatar from "../Avatar";
import ButtonsSpecialistPanel from "./Buttons";
import {
    getItemEditOrder,
    getItemsCreateOrder,
    getItemsEditOrder,
    keysFilter,
    updateItemCreateOrder,
} from "../../store/order/selectors";

import { ID, SPECIALIST_IDS } from "../../store/order/constant";
import { useActions } from "../../hooks/actions";
import { CurrentSpecialistsPayload } from "../../models/userModels";
import { TranslationObject } from "../../models/translationModal";
import { createOrderState } from "../../store/order/createOrder.slice";
import { editOrderState } from "../../store/order/editOrder.slice";

interface Props {
    classes?: string[];
    entities?: TranslationObject;
    onSubmit: (
        e?: React.FormEvent<HTMLInputElement>,
        nextStep?: string
    ) => Promise<{ data: any } | { error: any } | undefined>;
    isSuccess?: boolean;
    onSuccess: (value: string) => void;
    specialistIds: number[];
    clearSpecialistIds: MouseEventHandler<HTMLButtonElement>;
    isEdit?: boolean;
    updateOrder?: (value?: string, id?: number) => void;
    isPrivate: boolean;
}

const SpecialistPanel: React.FC<Props> = ({
    specialistIds,
    entities,
    clearSpecialistIds,
    onSubmit,
    isSuccess,
    classes,
    isEdit = false,
    updateOrder,
    isPrivate,
}) => {
    const { updateEditOrderField } = useActions();
    const [isAutoClearListSpecialist, setIsAutoClearListSpecialist] =
        useState<boolean>(false);
    const [getSpecialists, { data: specialists }] =
        endpoints.getCurrentSpecialists.useLazyQuery();
    const [getUnsuitableSpecialists, { data: unsuitableSpecialist }] =
        endpoints.getUnsuitableSpecialists.useLazyQuery();
    const currentLanguage = getCurrentLanguage();
    const shortListSpecialists = Array.isArray(specialists)
        ? specialists?.slice(0, 3)
        : [];
    const orderId = getItemEditOrder(ID);
    const { updateCreateOrderField } = useActions();

    const linkToSpecialistsList = `/${currentLanguage}/customer/${isEdit ? "edit-order" : "create-order"
        }/selected-specialists`;
    const hasSpecialist = specialistIds && specialistIds?.length > 0;
    const textLinkToSpecialistsList =
        isAutoClearListSpecialist && hasSpecialist
            ? entities?.order_specialists_do_not_match?.value ||
            "" + entities?.order_show_selected?.value
            : hasSpecialist && entities?.order_show_selected?.value;
    const bodyOrder = isEdit ? getItemsEditOrder() : getItemsCreateOrder();
    const shouldCheckIds = isEdit || isPrivate;

    const textSpecialist =
        isAutoClearListSpecialist && !hasSpecialist
            ? entities?.order_all_specialists_do_not_match?.value
            : `${entities?.order_edit_specialists_your_select?.value} ${specialists?.length}
                                     ${entities?.order_edit_specialists_your_select_count?.value}`;

    useEffect(() => {
        if (specialistIds?.length > 0) {
            getSpecialists({
                params: formatQueryString({
                    specialist_ids: specialistIds,
                }),
            });
        }
    }, [specialistIds]);

    const _updateOrder = () => {
        if (orderId && updateOrder) {
            updateOrder("draft", orderId);
        }
    };

    useEffect(() => {
        let data: editOrderState = {};
        keysFilter.forEach((elem) => {
            if (bodyOrder[elem]) {
                if (Array.isArray(bodyOrder[elem])) {
                    if (bodyOrder[elem]?.length < 1) {
                        return;
                    }
                }
                data = {
                    ...data,
                    [elem]: bodyOrder[elem],
                };
            }
        });

        if (specialistIds?.length > 0) {
            data.specialist_ids = specialistIds;
        }

        if (
            shouldCheckIds &&
            specialistIds?.length > 0 &&
            specialists &&
            specialists?.length > 0
        ) {
            getUnsuitableSpecialists({
                params: formatQueryString({
                    ...data,
                }),
            });
        }
    }, [specialistIds, specialists, bodyOrder, shouldCheckIds]);

    useEffect(() => {
        if (
            shouldCheckIds &&
            unsuitableSpecialist &&
            unsuitableSpecialist?.length > 0
        ) {
            setIsAutoClearListSpecialist(true);
            const items = specialistIds?.filter(
                (elem) =>
                    !unsuitableSpecialist?.find(
                        (item) => Number(elem) === Number(item)
                    )
            );
            const data = {
                name: SPECIALIST_IDS,
                value: items,
            };
            if (isEdit) {
                updateEditOrderField(data);
            } else {
                updateField(data);
            }
        }
    }, [unsuitableSpecialist]);

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) =>
        updateItemCreateOrder(
            {
                name: name,
                value: value,
            },
            updateCreateOrderField
        );

    if (specialistIds?.length < 1) {
        return null;
    }

    return (
        <Form
            className={classes ? `output ${classes.join(" ")}` : "output"}
            onSubmit={onSubmit}
            isSuccess={isSuccess}
        >
            {() => (
                <>
                    <div className="output__container">
                        <div className="output__wrap-info">
                            <ul className="output__list">
                                {shortListSpecialists?.map(
                                    (
                                        specialist: CurrentSpecialistsPayload,
                                        key: number
                                    ) => (
                                        <li className="output__item" key={key}>
                                            <Avatar
                                                withOutPicture
                                                width={48}
                                                height={48}
                                                avatar={specialist?.avatar}
                                            />
                                        </li>
                                    )
                                )}
                                {hasSpecialist &&
                                    specialists &&
                                    specialists?.length > 3 && (
                                        <li className="output__item output__item--link">
                                            <a
                                                className="link output__link"
                                                href="#"
                                                aria-label={
                                                    entities
                                                        ?.order_show_selected
                                                        ?.value
                                                }
                                            >
                                                +
                                                {specialists &&
                                                    specialists?.length - 3}
                                            </a>
                                            <span className="output__quantity">
                                                +{specialists?.length - 3}
                                            </span>
                                        </li>
                                    )}
                            </ul>
                            <div className="output__info">
                                <p className="output__text">{textSpecialist}</p>
                                <Link
                                    className="link output__link"
                                    to={linkToSpecialistsList}
                                >
                                    {textLinkToSpecialistsList}
                                </Link>
                            </div>
                        </div>
                        <ButtonsSpecialistPanel
                            specialistIds={specialistIds}
                            clearSpecialistIds={clearSpecialistIds}
                            hasSpecialist={hasSpecialist}
                            isEdit={isEdit}
                            entities={entities}
                            _updateOrder={_updateOrder}
                        />
                    </div>
                </>
            )}
        </Form>
    );
};
export default SpecialistPanel;

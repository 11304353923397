import React from "react";
import ReactDOM from "react-dom/client";
// import * as Sentry from "@sentry/react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";

import "./style.scss";
import { LoadingProvider } from "./hooks/useLoading";
// import ErrorBoundary from "./ErrorBoundary";

// Sentry.init({
//   dsn: "https://ac57c5429255c0c3445afcec8b4c7b6b@o4505980209004544.ingest.sentry.io/4505980422258688",
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracePropagationTargets: ["localhost", /^https:\/\/findpro-front\.grphn-dev\.ru/],
//     }),
//     new Sentry.Replay(),
//   ],

//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
      <BrowserRouter>
          <LoadingProvider>
              <App />
          </LoadingProvider>
      </BrowserRouter>
  </Provider>
);

import React, { Dispatch, SetStateAction } from "react";
import ChangeAddress from "../../../pages/Profile/ChangeAddress";
import { TranslationObject } from "../../../models/translationModal";

interface ProfileAddressProps {
    entities?: TranslationObject;
    address: string;
    setAddress: Dispatch<SetStateAction<string>>;
    isSpecialist?: boolean;
    updateAddressHandler?: React.MutableRefObject<() => unknown>;
    latitude?: number
    longitude?: number
}

export const ProfileAddress: React.FC<ProfileAddressProps> = (props) => {
    const {
        setAddress,
        address,
        entities,
        isSpecialist = false,
        updateAddressHandler,
        latitude,
        longitude
    } = props || {};

    return (
        <>
            <div className="profile__fieldset profile__address">
                <fieldset>
                    <legend>{entities?.profile_address?.value}</legend>
                    <div className="form__row">
                        <ChangeAddress
                            address={address}
                            setAddress={setAddress}
                            entities={entities}
                            latitude={latitude}
                            longitude={longitude}
                            isSpecialist={isSpecialist}
                            updateAddressHandler={updateAddressHandler}
                        />
                    </div>
                </fieldset>
            </div>
        </>
    );
};

import React, { useEffect, useState } from "react";
import { LocationData } from "../../../../models/orderModel";
import {
    getItemCreateOrder,
    updateItemCreateOrder,
} from "../../../../store/order/selectors";
import {
    IS_MY_ADDRESS,
    IS_REMOTE,
    IS_SPECIALIST_ADDRESS,
    MY_ADDRESS,
    SPECIALIST_ADDRESS,
} from "../../../../store/order/constant";
import BaseContainer from "./BaseContainer";
import { TranslationObject } from "../../../../models/translationModal";
import { createOrderState } from "../../../../store/order/createOrder.slice";
import { useActions } from "../../../../hooks/actions";

interface Props {
    entities?: TranslationObject;
}

const FilterToCreateOrder: React.FC<Props> = ({ entities }) => {
    const { updateCreateOrderField } = useActions();
    const [currentAddresses, setCurrentAddresses] = useState<string[]>([]);
    const myLocation = getItemCreateOrder(MY_ADDRESS);
    const specialistLocation = getItemCreateOrder(SPECIALIST_ADDRESS);
    const isMyAddress = getItemCreateOrder(IS_MY_ADDRESS);
    const isSpecialistAddress = getItemCreateOrder(IS_SPECIALIST_ADDRESS);
    const isRemote = getItemCreateOrder(IS_REMOTE);
    const [showMyAddressModal, setShowMyAddressModal] = useState(false);
    const [showSpecialistAddressModal, setShowSpecialistAddressModal] =
        useState(false);

    const clearAddress = (name: string) => {
        const isMyAddress = name === IS_MY_ADDRESS;
        updateField({
            name: isMyAddress ? MY_ADDRESS : SPECIALIST_ADDRESS,
            value: null,
        });
        updateField({
            name: isMyAddress ? IS_MY_ADDRESS : IS_SPECIALIST_ADDRESS,
            value: false,
        });
        setCurrentAddresses(currentAddresses.filter((elem) => elem !== name));
    };

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) =>
        updateItemCreateOrder(
            {
                name: name,
                value: value,
            },
            updateCreateOrderField
        );

    useEffect(() => {
        const items: string[] = [];
        if (isRemote) {
            items.push(IS_REMOTE);
        }

        if (isMyAddress && myLocation) {
            items.push(IS_MY_ADDRESS);
        }

        if (isSpecialistAddress && specialistLocation) {
            items.push(IS_SPECIALIST_ADDRESS);
        }
        setCurrentAddresses(items);
    }, [
        isMyAddress,
        myLocation,
        isSpecialistAddress,
        specialistLocation,
        isRemote,
    ]);

    const saveMyAddress = (location: LocationData) => {
        if (location?.latitude && location?.longitude) {
            updateField({
                name: IS_MY_ADDRESS,
                value: true,
            });
            updateField({
                name: MY_ADDRESS,
                value: {
                    latitude: location?.latitude,
                    longitude: location?.longitude,
                    address: location.address,
                },
            });
            setCurrentAddresses(currentAddresses.concat(IS_MY_ADDRESS));
        }
        setShowMyAddressModal(false);
    };

    const saveSpecialistAddress = (location: LocationData) => {
        if (!location) {
            return;
        }

        if (location?.latitude && location?.longitude) {
            updateField({
                name: IS_SPECIALIST_ADDRESS,
                value: true,
            });
            updateField({
                name: SPECIALIST_ADDRESS,
                value: {
                    latitude: location.latitude,
                    longitude: location.longitude,
                    address: location.address,
                    radius: location.radius,
                },
            });
            setCurrentAddresses(currentAddresses.concat(IS_SPECIALIST_ADDRESS));
        }
        setShowSpecialistAddressModal(false);
    };

    const handleChange = (str: keyof createOrderState) => {
        if (str === IS_MY_ADDRESS) {
            if (currentAddresses.includes(IS_MY_ADDRESS)) {
                return clearAddress(IS_MY_ADDRESS);
            }
            return setShowMyAddressModal(true);
        }
        if (str === IS_SPECIALIST_ADDRESS) {
            if (currentAddresses.includes(IS_SPECIALIST_ADDRESS)) {
                return clearAddress(IS_SPECIALIST_ADDRESS);
            }
            return setShowSpecialistAddressModal(true);
        }
        if (currentAddresses.indexOf(IS_REMOTE) !== -1) {
            setCurrentAddresses(
                currentAddresses.filter((elem) => elem !== str)
            );
            return updateField({
                name: IS_REMOTE,
                value: false,
            });
        }
        setCurrentAddresses(currentAddresses.concat(str));
        updateField({
            name: str,
            value: true,
        });
    };

    return (
        <BaseContainer
            saveMyAddress={saveMyAddress}
            setShowMyAddressModal={setShowMyAddressModal}
            showMyAddressModal={showMyAddressModal}
            entities={entities}
            saveSpecialistAddress={saveSpecialistAddress}
            setShowSpecialistAddressModal={setShowSpecialistAddressModal}
            showSpecialistAddressModal={showSpecialistAddressModal}
            currentAddresses={currentAddresses}
            handleChange={handleChange}
            clearAddress={clearAddress}
            myLocation={myLocation}
            specialistLocation={specialistLocation}
        />
    );
};

export default FilterToCreateOrder;

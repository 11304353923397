import AccordionView from "../AccordionView";
import React from "react";
import { TranslationObject } from "../../../models/translationModal";
import ChooseAddress from "../../../components/ChooseAddress";
import { getItemEditOrder } from "../../../store/order/selectors";
import { MY_ADDRESS, SPECIALIST_ADDRESS } from "../../../store/order/constant";
import { useActions } from "../../../hooks/actions";
import { locationProps } from "../../../models/orderModel";

interface Props {
    entities?: TranslationObject;
    isMyAddress?: boolean;
}

const SelectAddressEditOrder: React.FC<Props> = ({ entities, isMyAddress }) => {
    const { updateEditOrderField } = useActions();
    const title = isMyAddress
        ? entities?.order_select_address_title?.value
        : entities?.order_select_meeting_address_title?.value;

    const description = isMyAddress
        ? entities?.order_select_address_text?.value
        : entities?.order_select_meeting_address_text?.value;

    const location = getItemEditOrder(
        isMyAddress ? MY_ADDRESS : SPECIALIST_ADDRESS
    );

    const changeLocation = (obj: locationProps) => {
        updateEditOrderField({
            name: isMyAddress ? MY_ADDRESS : SPECIALIST_ADDRESS,
            value: isMyAddress
                ? {
                    latitude: obj?.latitude || location?.latitude,
                    longitude: obj?.longitude || location?.longitude,
                    address: obj?.address || location?.address,
                }
                : {
                    latitude: obj?.latitude || location?.latitude,
                    longitude: obj?.longitude || location?.longitude,
                    address: obj?.address || location?.address,
                    radius: Object.prototype.hasOwnProperty.call(
                        obj,
                        "radius"
                    )
                        ? obj?.radius
                        : location?.radius,
                },
        });
    };

    return (
        <AccordionView textButton={title || ""}>
            <div className="accordion-form__item-content">
                <p className="accordion-form__item-text">{description}</p>

                <div className="accordion-form__item-wrap">
                    <ChooseAddress
                        withRadius={!isMyAddress}
                        changeLocation={changeLocation}
                        entities={entities}
                        location={location}
                    />
                </div>
            </div>
        </AccordionView>
    );
};

export default SelectAddressEditOrder;

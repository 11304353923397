import React, { useEffect, useState } from "react";
import Pagination from "../../../components/Pagination";
import { TranslationObject } from "../../../models/translationModal";
import { OrderItem } from "../../../models/ordersModel";
import { keysFilter } from "../../../store/order/selectors";
import { formatQueryString, getPages } from "../../../untils";
import { endpoints } from "../../../store/order/order.api";
import CardSpecialist from "../../../components/CardSpecialist";
import { useUpdateOrderMutation } from "../../../store/orders/orders.api";
import Icon from "../../../components/Icon"

interface Props {
    entities?: TranslationObject;
    order: OrderItem;
    isPrivate: boolean;
    isSpecialist: boolean;
}

const SelectSpecialists: React.FC<Props> = ({
    entities,
    order,
    isPrivate,
    isSpecialist,
}) => {
    const [getSpecialist, { data: allSpecialists }] =
        endpoints.getOrderSpecialists.useLazyQuery();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pages, setPages] = useState<number[]>([]);
    const changeCurrentPage = (value: number) => {
        setCurrentPage(value);
    };
    const specialistsIds = order?.specialist_ids;
    const [update] = useUpdateOrderMutation();
    const specialists =
        allSpecialists?.items?.filter(
            (elem) => !specialistsIds.find((item: number) => item === elem.id)
        ) || [];

    useEffect(() => {
        loadList();
    }, [order, currentPage]);

    useEffect(() => {
        if (allSpecialists) {
            const count = Math.ceil(allSpecialists.total / 15);
            if (count > 1) {
                const pages = getPages(count);
                setPages(pages);
            }
        }
    }, [allSpecialists]);

    const loadList = () => {
        let data = {
            page: currentPage,
        };
        keysFilter.forEach((elem) => {
            if (order && order[elem as keyof OrderItem]) {
                data = {
                    ...data,
                    [elem]: order[elem as keyof OrderItem],
                };
            }
        });
        getSpecialist({
            params: formatQueryString(data),
        });
    };

    const changeSpecialistIds = (id: number) => {
        update({
            id: order?.id,
            body: {
                ...order,
                specialist_ids:
                    specialistsIds?.indexOf(id) !== -1
                        ? specialistsIds?.filter((elem: number) => elem !== id)
                        : specialistsIds.concat(id),
            },
        });
    };

    return (
        <div className="inner-container">
            <div className="closed-order-add-spec__content">
                <h3 className="title title--h3">
                    {entities?.order_add_specialists_to_order?.value}
                </h3>
                <div className="find-pro">
                    <div className="find-pro__content">
                        {specialists?.length < 1 && <div className="content-missing">
                            <div className="content-missing__wrapper">
                                <div className="content-missing__img">
                                    <Icon
                                        type="disappointed"
                                        width={32}
                                        height={32}
                                    />
                                </div>
                                <p className="content-missing__text">
                                    {
                                        entities
                                            ?.order_specialists_not_found
                                            ?.value
                                    }
                                </p>
                            </div>
                        </div>}
                        <ul className="find-pro__profiles-list">
                            {specialists?.length > 0 && specialists?.map((specialist) => (
                                <CardSpecialist
                                    key={specialist?.id}
                                    isSpecialist={isSpecialist}
                                    specialist={specialist}
                                    entities={entities}
                                    changeSpecialistIds={changeSpecialistIds}
                                    isAdd={specialistsIds?.find(
                                        (elem: number) =>
                                            elem === specialist?.id
                                    )}
                                    linkTo={""}
                                    isSquare
                                    isEdit={false}
                                    isPrivate={isPrivate}
                                    isUnresponsiveSpecialist={false}
                                    isOrderPage={false}
                                />
                            ))}
                        </ul>
                        {pages?.length > 0 && (
                            <Pagination
                                entities={entities}
                                pages={pages}
                                changeCurrentPage={changeCurrentPage}
                                currentPage={currentPage}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectSpecialists;

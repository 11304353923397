import { getCookie } from "../untils/cookies";
import {
    bodyRegister,
    bodyRequest,
    confirmationsRequest,
    sendPhoneConfirmationBody,
} from "../models/authModel";
import { RootState } from "./index";
import { editOrderState } from "./order/editOrder.slice";

export const getTokenCookie = () => {
    const tkn = getCookie("tkn");
    if (tkn) {
        return JSON.parse(tkn)?.access_token;
    }
    return null;
};

export const getToken = (str?: string | null) => str ?? getTokenCookie();

interface Request {
    url: string;
    method?: string;
    body?:
        | bodyRegister
        | bodyRequest
        | confirmationsRequest
        | { token: number | string }
        | sendPhoneConfirmationBody
        | FormData
        | Record<string, string | number | boolean | null>
        | editOrderState
        | Record<string, any>;
    tkn?: string | null;
    currentLanguage?: string;
    options?: any;
    withoutAuthorization?: boolean;
    params?: Record<string, string | number>;
    withoutLanguage?: boolean;
}

export const request = ({ url, method, body, options, params }: Request) => {
    // eslint-disable-next-line
    let headers: Record<string, string> = {
        Accept: "application/json",
    };

    if (options) {
        Object.keys(options).forEach((option) => {
            headers[option] = options[option];
        });
    }

    return {
        url: url,
        method: method || "GET",
        body,
        headers,
        params,
    };
};

export const changeHeaders = (
    headers: Headers,
    { getState }: { getState: () => unknown }
) => {
    const language = (getState() as RootState).directories.language;
    const token = (getState() as RootState).user.tkn;

    headers.set("Accept-Location", 'web');

    if (language) {
        headers.set("X-Client-Language", language);
    }
    if (token) {
        headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
};

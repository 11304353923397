import { useAppDispatch } from "../../hooks/useAppDispatch";
import { OrderItem } from "../../models/ordersModel";
import { store } from "../../store";
import { util } from "../../store/orders/orders.api";
import { checkOrderMatchesFilters } from "../../store/orders/selectors";
import { CHANGE_ORDER_STATUS } from "./AppSocketProvider";

export const useUpdateOrdersList = () => {
    const dispatch = useAppDispatch();

    return (
        orderId: number,
        params: Record<keyof OrderItem, OrderItem[keyof OrderItem]>,
        action?: string
    ) => {
        const state = store.getState();
        const filters = state.filterOrders
        const endpoints = util.selectInvalidatedBy(state, [
            { type: "Orders", id: "getOrders" },
        ]);


        endpoints.forEach((endpoint) => {
            const { originalArgs } = endpoint;

            if (action === CHANGE_ORDER_STATUS) {
                const orderInList = checkOrderMatchesFilters(
                    {
                        filters,
                        order: params,
                        type: params.status
                    }
                )

                return dispatch(util.updateQueryData("getOrders", originalArgs, (list) => {
                    list.items?.forEach((item) => {
                        if (item.id === orderId) {
                            list.items = list.items?.filter(item => item.id !== orderId)
                        } else if (orderInList) {
                            list.items = [params, ...list.items]
                        }
                    });
                }))
            }

            dispatch(
                util.updateQueryData("getOrders", originalArgs, (list) => {
                    list.items.forEach((item) => {
                        if (item.id === orderId) {
                            Object.assign(item, params);
                        }
                    });
                })
            );
        });
    };
};

import React from "react";
import { TranslationObject } from "../../../models/translationModal";
import Modal from "../../Modal";
import _ from "lodash";
import cn from "classnames";

interface InDevelopmentNotificationProps {
    visible: boolean;
    setVisible: (visible: boolean) => unknown;
    entities?: TranslationObject;
    isSpecialist?: boolean;
}

export const InDevelopmentNotification: React.FC<
    InDevelopmentNotificationProps
> = ({ visible, setVisible, entities, isSpecialist = false }) => {
    const modificators = cn("modal-in-development", {
        specialist: isSpecialist,
    }).split(" ");

    return (
        <>
            <Modal
                visible={visible}
                onClose={() => setVisible(false)}
                title={entities?.common_in_progress?.value}
                description={entities?.common_under_development?.value}
                modificators={modificators}
            >
                <div className="modal__content-buttons">
                    <button
                        className="btn modal__content-button"
                        type="button"
                        onClick={() => setVisible(false)}
                    >
                        Ok
                    </button>
                </div>
            </Modal>
        </>
    );
};

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
    SERVICE_CATEGORY_ID,
    SERVICE_TYPE_ID,
    SERVICE_SUB_TYPE_IDS,
    IS_MY_ADDRESS,
    IS_SPECIALIST_ADDRESS,
    IS_REMOTE,
    IS_INTERESTING,
} from "../order/constant";

export type filterOrdersState = {
    [IS_REMOTE]: boolean;
    [IS_INTERESTING]: boolean;
    search: string;
    [SERVICE_CATEGORY_ID]: number | null;
    [SERVICE_TYPE_ID]: number | null;
    [SERVICE_SUB_TYPE_IDS]: number[];
    [IS_MY_ADDRESS]: boolean;
    [IS_SPECIALIST_ADDRESS]: boolean;
    status: string;
    page: number;
    started_at: string | null;
    ended_at: string | null;
};

const initialState: filterOrdersState = {
    search: "",
    [SERVICE_CATEGORY_ID]: null,
    [SERVICE_TYPE_ID]: null,
    [SERVICE_SUB_TYPE_IDS]: [],
    [IS_REMOTE]: false,
    [IS_INTERESTING]: false,
    [IS_MY_ADDRESS]: false,
    [IS_SPECIALIST_ADDRESS]: false,
    status: "all",
    page: 1,
    started_at: null,
    ended_at: null,
};

export const filterOrdersSlice = createSlice({
    name: "registration",
    initialState,
    reducers: {
        setOrdersFilter<filterOrdersState, K extends keyof filterOrdersState>(
            state: filterOrdersState,
            action: PayloadAction<{ name: K; value: filterOrdersState[K] }>
        ) {
            state = Object.assign(
                state,
                action.payload.name !== "page"
                    ? {
                          page: 1,
                          [action.payload.name]: action.payload.value,
                      }
                    : { [action.payload.name]: action.payload.value }
            ) as filterOrdersState;
        },
        clearOrderFilter<filterOrdersState, K extends keyof filterOrdersState>(
            state: filterOrdersState,
            action: PayloadAction<K>
        ) {
            state = Object.assign(state, {
                [action.payload]: null,
            }) as filterOrdersState;
        },
        clearOrdersFilters(state) {
            state = Object.assign(state, initialState);
        },
    },
});

export const filterOrdersActions = filterOrdersSlice.actions;
export const filterOrdersReducer = filterOrdersSlice.reducer;

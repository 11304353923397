import AccordionView from "../AccordionView";
import SelectItemToInput from "../../../components/SelectItemToInput";
import React, { useEffect } from "react";
import { TranslationObject } from "../../../models/translationModal";
import { endpoints } from "../../../store/directories/directories.api";
import {
    CUSTOM_SERVICE_SUB_TYPE,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_TYPE_ID,
} from "../../../store/order/constant";
import { useActions } from "../../../hooks/actions";
import { getItemEditOrder } from "../../../store/order/selectors";

interface Props {
    entities?: TranslationObject;
}

const SubTypeEditOrder: React.FC<Props> = ({ entities }) => {
    const { updateEditOrderField } = useActions();
    const typeId = getItemEditOrder(SERVICE_TYPE_ID);
    const currentSubTypes = getItemEditOrder(SERVICE_SUB_TYPE_IDS);
    const customValue = getItemEditOrder(CUSTOM_SERVICE_SUB_TYPE);
    const [getSubTypes, { data: subtypes }] =
        endpoints.getServiceSubTypes.useLazyQuery();

    useEffect(() => {
        if (typeof typeId === "number") {
            getSubTypes({
                id: typeId,
            });
        }
    }, [typeId]);

    const items =
        subtypes?.filter((elem) => currentSubTypes?.indexOf(elem.id) === -1) ||
        [];

    const handleClick = (id: number) => {
        updateEditOrderField({
            name: SERVICE_SUB_TYPE_IDS,
            value:
                currentSubTypes?.indexOf(id) !== -1
                    ? currentSubTypes?.filter((elem: number) => elem !== id)
                    : currentSubTypes.concat(id),
        });
        updateEditOrderField({
            name: CUSTOM_SERVICE_SUB_TYPE,
            value: "",
        });
        updateEditOrderField({
            name: "filter_pages",
            value: [],
        });
    };

    const onChange = (value: string) => {
        updateEditOrderField({
            name: CUSTOM_SERVICE_SUB_TYPE,
            value,
        });
    };

    return (
        <AccordionView
            textButton={
                entities?.order_select_service_sub_type_title?.value || ""
            }
        >
            <div className="accordion-form__item-content">
                <p className="accordion-form__item-text">
                    {entities?.order_select_service_type_text?.value}
                </p>
                <div className="accordion-form__item-wrap">
                    <div className="new-order-start">
                        <SelectItemToInput
                            list={subtypes || []}
                            currentItems={
                                currentSubTypes && currentSubTypes?.length > 0
                                    ? currentSubTypes
                                    : customValue
                            }
                            maxLength={255}
                            deleteItem={handleClick}
                            items={items}
                            onSelect={handleClick}
                            isBig
                            withCustomValue
                            onChange={onChange}
                            isMultiple
                        />
                    </div>
                </div>
            </div>
        </AccordionView>
    );
};

export default SubTypeEditOrder;

import React, { useEffect } from "react";
import Form from "../../components/Form/Form";
import { useNavigate } from "react-router-dom";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { OrderStepsProps } from "./index";
import WishesOrder from "../../components/WishesOrder";
import {
    getItemCreateOrder,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import {
    DESCRIPTION,
    PRICE,
    SERVICE_CATEGORY_NAME,
    SERVICE_TYPE_NAME,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_SUB_TYPE_NAME,
    SERVICE_TYPE_SLUG,
    SERVICE_SUB_TYPE_SLUG,
    SERVICE_CATEGORY_SLUG,
} from "../../store/order/constant";
import { createOrderState } from "../../store/order/createOrder.slice";
import { useActions } from "../../hooks/actions";
import Advertising from "../../components/Advertising";
import CancelOrderButton from "./Specialists/CancelOrderButton";
import { scrollTop } from "../../untils";
import Breadcrumbs from "../../components/Breadcrumbs";

const Wishes: React.FC<OrderStepsProps> = ({
    entities,
    isSuccess,
    onSuccess,
    onSubmit,
    profile,
}) => {
    const { updateCreateOrderField } = useActions();
    const navigate = useNavigate();
    const currentLanguage = getCurrentLanguage();
    const price = getItemCreateOrder(PRICE);
    const description = getItemCreateOrder(DESCRIPTION);
    const serviceSubTypesIdsLength = getItemCreateOrder(SERVICE_SUB_TYPE_IDS)?.length;

    const categoryPath = getItemCreateOrder(SERVICE_CATEGORY_SLUG);
    const typePath = getItemCreateOrder(SERVICE_TYPE_SLUG);
    const subtypePath = getItemCreateOrder(SERVICE_SUB_TYPE_SLUG);

    const _back = () => {
        navigate(`/${currentLanguage}/customer/create-order/repeat`);
        scrollTop()
    };

    const _onSuccess = () => {
        onSuccess("finishing");
    };

    const _onSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        onSubmit(e, "finishing");
    };

    useEffect(() => {
        if (isSuccess) {
            _onSuccess();
        }
    }, [isSuccess]);

    const handleInputChangePrice = (value: string) => {
        updateField({ name: PRICE, value });
    };

    const handleInputChangeDescription = (value: string) => {
        updateField({ name: DESCRIPTION, value });
    };

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) =>
        updateItemCreateOrder(
            {
                name: name,
                value: value,
            },
            updateCreateOrderField
        );

    const crumbs = [
        {
            name: getItemCreateOrder(SERVICE_CATEGORY_NAME) || entities?.service_task?.value,
            link: `create-order/${categoryPath}`
        },
        {
            name: getItemCreateOrder(SERVICE_TYPE_NAME) || entities?.type_of_service_2?.value,
            link: `create-order/${categoryPath}/${typePath || 'type'}`
        },
        {
            name: getItemCreateOrder(SERVICE_SUB_TYPE_NAME) || serviceSubTypesIdsLength && `${entities?.subtype_of_service_2?.value} (${serviceSubTypesIdsLength})` || entities?.subtype_of_service_2?.value,
            link: `create-order/${categoryPath}/${typePath || 'type'}/${subtypePath || 'subtype'}`
        },
        {
            name: entities?.choosing_a_specialist?.value,
            link: 'create-order/meeting-point'
        },
        {
            name: entities?.date_and_time?.value,
            link: 'create-order/when-to-start'
        },
        {
            name: entities?.periodicity?.value,
            link: 'create-order/repeat'
        },
        {
            active: true,
            name: entities?.additionally?.value
        }
    ]

    return (
        <div className="inner-container">
            <div className="new-order__wrapper">
                <Breadcrumbs crumbs={crumbs}/>
                <div className="new-order__header">
                    <h2 className="title title--f46">
                        {entities?.order_additional_data_title?.value}
                    </h2>
                    <CancelOrderButton entities={entities} profile={profile} />
                </div>
                <div className="new-order__form form">
                    <Form onSubmit={_onSubmit} isSuccess={isSuccess}>
                        {(invalidFields, error) => (
                            <>
                                <WishesOrder
                                    entities={entities}
                                    handleInputChangePrice={handleInputChangePrice}
                                    error={error}
                                    price={price}
                                    invalidFields={invalidFields}
                                    handleInputChangeDescription={handleInputChangeDescription}
                                    description={description}
                                />
                                <div className="form__row form__controls">
                                    <CancelOrderButton
                                        profile={profile}
                                        entities={entities}
                                    />
                                    <button
                                        className="btn form__back-link btn--transparent"
                                        type="button"
                                        onClick={_back}
                                    >
                                        {entities?.common_back_button?.value}
                                    </button>
                                    <button
                                        className="btn btn--transparent btn--bg-blue"
                                        type="submit"
                                    >
                                        {entities?.login_continue_button?.value}
                                    </button>
                                </div>
                            </>
                        )}
                    </Form>
                </div>
            </div>
            <ul className="advertising-list create-order">
                <Advertising countCards={5} entities={entities} isHorizontal={true} />
            </ul>
        </div>
    );
};

export default Wishes;

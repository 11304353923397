import classNames from "classnames";
import Icon from "../Icon";
import React from "react";

interface Props {
    score?: number;
    withBackground?: boolean;
    className?: string;
    width?: number;
    height?: number;
    classNameSvg?: string;
    classNameSpan?: string;
}

const Score: React.FC<Props> = ({
    score,
    withBackground,
    className,
    width,
    height,
    classNameSvg,
    classNameSpan,
}) => {
    if (!score && score !== 0) {
        return null;
    }

    return (
        <div
            className={classNames(
                className ? "rating " + className : "rating",
                {
                    "rating--green": !withBackground && score >= 7,
                    "rating--yellow":
                        !withBackground && score >= 4 && score < 7,
                    "rating--red": !withBackground && score < 4 && score >= 0,
                    "rating--max": withBackground && score >= 7,
                    "rating--middle": withBackground && score >= 4 && score < 7,
                    "rating--min": withBackground && score < 4 && score >= 0,
                }
            )}
        >
            <Icon
                type="star"
                className={classNames({
                    rating__star: !classNameSvg,
                    [classNameSvg]: !!classNameSvg,
                })}
                width={width ? width : 10}
                height={height ? height : 10}
            />
            <span
                className={classNames({
                    [classNameSpan]: !!classNameSpan,
                })}
            >
                {score}
            </span>
        </div>
    );
};

export default Score;

import React from "react";
import Select from "react-select";

import { CSSObjectWithLabel, Props } from "react-select";

interface SelectProps extends Props {
    id?: string;
    name?: string;
    className?: string;
}

const SelectInput: React.FC<SelectProps> = ({
    options,
    id,
    name,
    isDisabled,
    className,
    value,
    onChange,
}) => {
    return (
        <Select
            id={id}
            options={options}
            name={name}
            onChange={onChange}
            className={className ?? "custom-select"}
            classNamePrefix={"custom-select"}
            value={value}
            styles={{
                input: (styles: CSSObjectWithLabel) => ({
                    ...styles,
                }),
                menuList: () => ({}),
                option: (styles: CSSObjectWithLabel) => {
                    return {
                        ...styles,
                        display: "flex",
                        flexDirection: "row",
                    };
                },
                control: () => ({}),
                menu: (styles: CSSObjectWithLabel) => ({
                    ...styles,
                    display: "flex",
                    flexDirection: "column",
                }),
                dropdownIndicator: () => ({}),
                clearIndicator: () => ({}),
                singleValue: (styles: CSSObjectWithLabel) => ({
                    ...styles,
                }),
            }}
            isDisabled={isDisabled}
        />
    );
};

export default SelectInput;

import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import TextArea from "../../../../components/Form/TextArea";
import { useSetNewComplaintMutation } from "../../../../store/user/user.api";
import { ISetNewComplaintRequest } from "../../../../models/userModels";
import { TranslationObject } from "../../../../models/translationModal";

interface ComplainSpecialistModalProps {
    visible: boolean;
    onClose: () => unknown;
    orderId?: number | string;
    userId?: number | string | null;
    userRole: "specialist" | "customer";
    entities?: TranslationObject;
}

export const ComplainSpecialistModal: React.FC<ComplainSpecialistModalProps> = (
    props
) => {
    const { visible, onClose, userId, orderId, userRole, entities } =
        props || {};

    const [inputValue, setInputValue] = useState<string>("");

    const [setNewComplaint] = useSetNewComplaintMutation();

    const handleClickButtonSaveModal = () => {
        if (!orderId || !userId || !inputValue?.length) return;

        const requestData: ISetNewComplaintRequest = {
            order_id: +orderId,
            text: inputValue,
        };

        if (userRole === "customer") {
            requestData.specialist_id = +userId;
        }

        setNewComplaint(requestData);

        onClose();
    };

    return (
        <>
            <Modal
                visible={visible}
                title={`Пожаловаться на ${
                    userRole === "customer" ? "специалиста" : "заказчика"
                }`}
                onClose={onClose}
                modalClasses={"modal-complaint"}
            >
                <>
                    <TextArea
                        type="text"
                        classes={["custom-textarea"]}
                        placeholder={`${entities?.chat_describe_problem?.value}...`}
                        customLabel={entities?.chat_describe_problem?.value}
                        customLabelClasses={"custom-textarea__label"}
                        onChange={(value) => setInputValue(value)}
                    />
                    <div className="modal__content-buttons">
                        <button
                            className="btn modal__content-button"
                            type="button"
                            onClick={onClose}
                        >
                            {entities?.common_cancel_button?.value}
                        </button>
                        <button
                            onClick={handleClickButtonSaveModal}
                            className="btn modal__content-button modal__content-button--background"
                            type="button"
                        >
                            {entities?.login_save_button?.value}
                        </button>
                    </div>
                </>
            </Modal>
        </>
    );
};

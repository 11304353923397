import DateStartAndEnd from "../../../components/DateStartAndEnd";
import { convertDateFormatISO, setItemStore } from "../../../untils";
import React, { useState } from "react";
import AccordionView from "../AccordionView";
import { TranslationObject } from "../../../models/translationModal";
import { useActions } from "../../../hooks/actions";
import { ENDED_AT, STARTED_AT } from "../../../store/order/constant";
import { getItemEditOrder } from "../../../store/order/selectors";

interface Props {
    entities?: TranslationObject;
}

const StartAtEditOrder: React.FC<Props> = ({ entities }) => {
    const startAt = getItemEditOrder(STARTED_AT);
    const endAt = getItemEditOrder(ENDED_AT);
    const { updateEditOrderField } = useActions();

    const handleChange = (value: Date, name: string) => {
        const date = convertDateFormatISO(value);
        updateEditOrderField({
            name,
            value: date,
        });
    };

    return (
        <AccordionView textButton={entities?.order_start_date_title?.value || ""}>
            <div className="accordion-form__item-content">
                <DateStartAndEnd
                    entities={entities}
                    startAt={startAt}
                    endAt={endAt}
                    handleChange={handleChange}
                />
            </div>
        </AccordionView>
    );
};

export default StartAtEditOrder;

import { getItemStore, setItemStore } from "../../untils";
import { FilterBody } from "../../models/directoryModel";
import {
    FILTER_PAGES,
    IS_MY_ADDRESS,
    IS_PRIVATE,
    IS_REMOTE,
    IS_SPECIALIST_ADDRESS,
    LANGUAGE_IDS,
    MY_ADDRESS,
    SERVICE_CATEGORY_ID,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_TYPE_ID,
    SPECIALIST_ADDRESS,
} from "../../store/order/constant";
import { TranslationObject } from "../../models/translationModal";
import { Info } from "luxon";
import { useSelector } from "react-redux";
import { RootState } from "../index";
import { editOrderState, initialState } from "./editOrder.slice";
import { clearCreateOrderState, createOrderState } from "./createOrder.slice";

export const orderKeys = (isEdit: boolean) =>
    Object.keys(isEdit ? initialState : clearCreateOrderState);

export const keysFilter: (keyof editOrderState)[] | (keyof createOrderState)[] =
    [
        SERVICE_TYPE_ID,
        SERVICE_CATEGORY_ID,
        SERVICE_SUB_TYPE_IDS,
        LANGUAGE_IDS,
        FILTER_PAGES,
        "page",
        IS_REMOTE,
        IS_SPECIALIST_ADDRESS,
        IS_MY_ADDRESS,
        MY_ADDRESS,
        SPECIALIST_ADDRESS,
    ];

export const getOrderBody = (data?: editOrderState) => {
    const body: createOrderState = {};
    const items = orderKeys(false) as (keyof createOrderState)[];

    items.forEach((key: keyof createOrderState) => {
        if (
            data
                ? key === FILTER_PAGES && data[FILTER_PAGES]
                : key === FILTER_PAGES && getItemStore(FILTER_PAGES)
        ) {
            const items = data
                ? data[FILTER_PAGES]
                : getItemStore(FILTER_PAGES);

            items.forEach((filter: FilterBody) => {
                if (filter.service_type_filter_value_ids?.length > 0) {
                    const obj =
                        filter?.custom_filter_value &&
                        filter.custom_filter_value?.length > 0
                            ? filter
                            : {
                                  service_type_filter_id:
                                      filter.service_type_filter_id,
                                  service_type_filter_value_ids:
                                      filter?.service_type_filter_value_ids,
                                  custom_filter_value:
                                      filter?.custom_filter_value,
                              };
                    if (body?.filter_pages) {
                        return (body.filter_pages =
                            body.filter_pages?.concat(obj));
                    }
                    body.filter_pages = [obj];
                }
            });
        }

        if (
            key === IS_MY_ADDRESS ||
            key === IS_SPECIALIST_ADDRESS ||
            key === IS_REMOTE ||
            key === IS_PRIVATE
        ) {
            return (body[key as keyof editOrderState] = data
                ? data[key] && Boolean(data[key])
                : getItemStore(key) && Boolean(getItemStore(key)));
        }

        if (key !== FILTER_PAGES) {
            body[key as keyof editOrderState] = data
                ? data[key as keyof editOrderState]
                : getItemStore(key);
        }
    });

    return body;
};

export const clearOrderDataInLocalStorage = (
    isEdit: false,
    exceptions: (keyof createOrderState)[] = []
) => {
    const items: (keyof createOrderState)[] = orderKeys(
        isEdit
    ) as (keyof createOrderState)[];
    items.forEach((elem) => {
        if (exceptions?.indexOf(elem) !== -1) {
            return;
        }
        if (getItemStore(elem)) {
            localStorage.removeItem(elem);
        }
    });
};

export const changeOrderType = (isPrivate?: boolean) => {
    const is_private = getItemStore(IS_PRIVATE);
    if (!is_private && isPrivate) {
        clearOrderDataInLocalStorage(false, []);
        setItemStore(IS_PRIVATE, true);
        setItemStore("status", "draft");
    }
    if (!isPrivate && is_private) {
        clearOrderDataInLocalStorage(false, []);
        setItemStore(IS_PRIVATE, false);
        setItemStore("status", "draft");
    }
};

export const getRanges = (entities?: TranslationObject) => [
    { name: "once", label: entities?.order_periodicity_once?.value },
    {
        name: "every_day",
        label: entities?.order_periodicity_every_day?.value,
    },
    {
        name: "every_week",
        label: entities?.order_periodicity_every_week?.value,
    },
    {
        name: "every_month",
        label: entities?.order_periodicity_every_month?.value,
    },
];

export const getDays = () => {
    const days = [];
    let i = 1;
    for (i; i < 32; i++) {
        days.push({ value: i, label: i });
    }
    return days;
};

export const getWeekdays = (locale: string) =>
    Info.weekdays("long", {
        locale,
    });

export const getItemEditOrder = <K extends keyof editOrderState>(
    str: K
): editOrderState[K] =>
    useSelector((state: RootState) => state?.editOrder[str]);

export const getItemsEditOrder = () =>
    useSelector((state: RootState) => state?.editOrder) as editOrderState;

export const getItemsCreateOrder = () =>
    useSelector((state: RootState) => state?.createOrder) as createOrderState;

export const getItemCreateOrder = <K extends keyof createOrderState>(
    str: K
): createOrderState[K] =>
    useSelector((state: RootState) => state?.createOrder[str]);

export const updateItemCreateOrder = <K extends keyof createOrderState>(
    {
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    },
    callback: ({ name, value }: { name: K; value: createOrderState[K] }) => void
) => {
    if (
        (typeof value === "string" && value.length < 1) ||
        (typeof value !== "boolean" && !value) ||
        typeof value === null ||
        (Array.isArray(value) && value?.length < 1)
    ) {
        localStorage.removeItem(name);
    } else {
        setItemStore(name, value);
    }
    callback({
        name,
        value,
    });
};

export const getMaxCountSearchAi = () => useSelector((state: RootState) => state.createOrder.maxCountSearchAi);

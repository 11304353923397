import React from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { chooseDeclension, getUrlTransliteName, setItemStore } from "../../untils";
import { IS_INDIVIDUAL } from "../../store/order/constant";
import {
    IAdvertisementSpecialist,
    IFlag,
} from "../../models/advertisementModel";
import _ from "lodash";
import { TranslationObject } from "../../models/translationModal";

interface AdvertisingCardProps {
    id: number;
    advertisementSpecialists: IAdvertisementSpecialist;
    entities?: TranslationObject;
}

const AdvertisingCard: React.FC<AdvertisingCardProps> = ({
    id,
    advertisementSpecialists,
    entities,
}) => {
    const {
        photo,
        reviews_avg_score,
        service_names,
        flags,
        deals_count = 0,
        specialist_name = '',
    } = advertisementSpecialists || {};

    const processedName = specialist_name ? getUrlTransliteName(specialist_name) : 'noname';

    const navigate = useNavigate();

    const currentLanguage = getCurrentLanguage();

    const { previewFlags, hideFlags } = useSpecialistFlags(flags);

    const specialistDetailsCountStr = chooseDeclension(
        deals_count,
        entities?.specialist_details_deals?.value,
        currentLanguage === "ru"
    );

    const handleClick = () => {
        navigate(`/${currentLanguage}/specialist/${processedName}-${id}`, {
            state: window?.location?.pathname,
        });
        setItemStore(IS_INDIVIDUAL, true);
    };

    return (
        <>
            <li
                onClick={handleClick}
                key={id}
                className="specialists__item specialists__item--normal-raiting"
            >
                <div>
                    <div className="specialists__item-wrapper">
                        <img
                            src={photo?.original_url}
                            width="255"
                            height="192"
                            alt={photo?.file_name || "avatar"}
                        />
                        {reviews_avg_score > 0 && (
                            <div className="specialists__raiting">
                                <p>{reviews_avg_score}</p>
                            </div>
                        )}
                    </div>
                    <ul className="specialists__item-skills-list no-scrollbar">
                        {service_names?.map((service) => (
                            <li
                                className="specialists__item-skills-item"
                                key={_.uniqueId("service")}
                            >
                                <p>{service}</p>
                            </li>
                        ))}
                    </ul>
                    <div className="specialists__item-content-wrapper">
                        <div className="specialists__item-country-wrapper">
                            <ul className="specialists__item-country-list">
                                {previewFlags?.map((flag) => (
                                    <li
                                        className="specialists__item-country-item"
                                        key={flag?.id}
                                    >
                                        <img
                                            src={flag?.original_url || ""}
                                            alt={flag?.file_name || "flag"}
                                            width="15"
                                            height="11"
                                        />
                                    </li>
                                ))}
                            </ul>
                            {hideFlags !== 0 && (
                                <span className="specialists__item-content-count">
                                    +{hideFlags}
                                </span>
                            )}
                        </div>
                        <div className="specialists__amountOfDeals">
                            <p>
                                {deals_count +
                                    "\u00A0" +
                                    specialistDetailsCountStr}
                            </p>
                        </div>
                    </div>
                </div>
            </li>
        </>
    );
};

const useSpecialistFlags = (
    flags: IFlag[],
    maxPreview = 3
): { previewFlags: IFlag[]; hideFlags: number } => {
    const previewFlags = flags?.slice(0, maxPreview);
    const hideFlags = flags?.slice(maxPreview)?.length || 0;

    return {
        previewFlags,
        hideFlags,
    };
};

export default AdvertisingCard;

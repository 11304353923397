import { useAppDispatch } from "../../hooks/useAppDispatch";
import { OrderItem } from "../../models/ordersModel";
import { util } from "../../store/orders/orders.api";
import { IRespondedSpecialist } from "./AppSocketProvider.types";
import { useInvalidateOrder } from './useInvalidateOrder'

export const useUpdateOrder = () => {
    const dispatch = useAppDispatch();
    const incalidateOrder = useInvalidateOrder()

    return (
        orderId: number,
        params: Record<keyof OrderItem, OrderItem[keyof OrderItem]>,
        respondedSpecialist: IRespondedSpecialist,
        userRole: string | null
    ) => {
        dispatch(
            util.updateQueryData(
                "getOrder",
                { id: String(orderId) },
                (order) => {
                    if (order?.id === orderId) {
                        if (userRole === "customer") {
                            order?.responded_specialists?.forEach((el) => {
                                if (
                                    el?.id === respondedSpecialist.id &&
                                    el?.chat?.unviewed_messages_count
                                ) {
                                    el.chat.unviewed_messages_count =
                                        respondedSpecialist?.unviewed_chat_messages_count;
                                } else if (el?.id === respondedSpecialist?.id && !el?.chat) {
                                    incalidateOrder()
                                }
                            })
                        } else if (userRole === "specialist") {
                            const hasUnviewedMessagesCount = typeof order?.chat?.unviewed_messages_count === 'number'

                            if (hasUnviewedMessagesCount) {
                                order.chat.unviewed_messages_count =
                                    params?.unviewed_chat_messages_count ?? 0;
                            }
                        }
                    }
                }
            )
        );
    };
};

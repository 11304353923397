import React, { useState } from "react";
import { TranslationObject } from "../../../models/translationModal";
import { OrderItem } from "../../../models/ordersModel";
import Icon from "../../Icon";
import { Link } from "react-router-dom";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import Score from "../../Score";
import classNames from "classnames";
import RenderButtonReview from "../../Buttons/ReviewOrderButton";
import ReviewModal from "../../Modals/ReviewModal";
import ResponseOrderButton from "../../Buttons/ResponseOrderButton";
import { useSuspendedAccess } from "../../../hooks/useSuspendedAccess";
import { getUrlTransliteName } from "../../../untils";

interface Props {
    entities?: TranslationObject;
    order: OrderItem;
    isSpecialist: boolean;
    isDraft: boolean;
    isDone: boolean;
    isActive: boolean;
    isDeal: boolean;
    isResponded: boolean;
    role: string;
    specialistId?: number | false;
}

const Controls: React.FC<Props> = ({
    entities,
    order,
    isActive,
    isDone,
    isDeal,
    isSpecialist,
    role,
    isResponded,
    specialistId
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const score = order?.review?.score;
    const text = order?.review?.text;
    const order_id = order?.id;
    const id = order?.review?.id;
    const currentLanguage = getCurrentLanguage();
    const review = order?.review;
    const showPopover =
        (!isSpecialist && score && text) || (isSpecialist && text);
    const showRespondedSpecialistIds = isActive && !isSpecialist;
    const showCreateReviewCustomer = isSpecialist && !review && isDone;
    const { isSuspendedUser } = useSuspendedAccess();
    let showChats = isDeal || isActive;
    const processedName = order?.executor?.name ? getUrlTransliteName(order?.executor?.name) : 'noname';

    if (isSpecialist && isActive && !isResponded) {
        showChats = false;
    }
    const showReviewInfo = () => {
        if (isDeal || isDone) {
            if (!isSpecialist) {
                return true;
            }
            if (isSpecialist && review && isDone) {
                return true;
            }
            return false;
        }
        return false;
    };

    const addReview = () => {
        setShowModal(true);
    };

    const openModal = () => {
        if (isSuspendedUser) return;
        setShowModal(true);
    };

    return (
        <div
            className="cards-orders__item-info-additional"
            onClick={(e) => e.stopPropagation()}
        >
            <ReviewModal
                key={`modal_review_order_${order_id}`}
                visible={showModal}
                onClose={() => setShowModal(false)}
                order_id={order_id}
                id={id}
                isSpecialist={isSpecialist}
                score={score}
                text={text}
                entities={entities}
            />
            <div className="response-card-order">
                {showRespondedSpecialistIds && (
                    <Link
                        to={`/${currentLanguage}/${role}/lk/order/${order?.id}`}
                        className="response-card-order__response"
                    >
                        <Icon type="specialists" width={20} height={20} />
                        <span className="response-card-order__response-amount">
                            {order?.responded_specialist_ids?.length || 0}
                        </span>
                        <p className="response-card-order__text">
                            {entities?.order_responding_specialists?.value}
                        </p>
                    </Link>
                )}
                {showChats && (
                    <div className="response-card-order__new-message">
                        <Icon type="message" width={20} height={20} />
                        <span className="response-card-order__new-message-amount">
                            {order?.unviewed_chat_messages_count || 0}
                        </span>
                        <p className="response-card-order__text">
                            {entities?.chat_new_messages_text?.value}
                        </p>
                    </div>
                )}
                {showChats && !isSpecialist && (
                    <div className="response-card-order__suggested-deals">
                        <Icon type="deal" width={20} height={20} />
                        <span className="response-card-order__suggested-deals-amount">
                            {order?.offers_count || 0}
                        </span>
                        <p className="response-card-order__text">
                            {entities?.chat_suggested_deals_text?.value}
                        </p>
                    </div>
                )}
            </div>
            {showCreateReviewCustomer && (
                <button
                    className="btn--transparent cards-orders__btn btn btn--rounded"
                    type="button"
                    onClick={addReview}
                >
                    {entities?.order_write_review?.value}
                </button>
            )}
            <ResponseOrderButton
                entities={entities}
                isSpecialist={isSpecialist}
                isActive={isActive}
                order={order}
                specialistId={specialistId}
            />
            {showReviewInfo() && (
                <div className="author-order">
                    {isSpecialist ? (
                        <div className="author-order__title-name">
                            {order?.customer?.name} {order?.customer?.family_name}
                        </div>
                    ) : (
                        <Link
                            className="author-order__title-name"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            state={window?.location?.pathname}
                            to={processedName ? `/${currentLanguage}/specialist/${processedName}-${order?.executor?.id}` : `/${currentLanguage}/not-found`}
                        >
                            {order?.executor?.name}{" "}
                            {order?.executor?.family_name}
                        </Link>
                    )}

                    {isDone && (
                        <div className="author-order__bottom-wrapper">
                            {order?.review && (
                                <>
                                    {!isSpecialist && (
                                        <Score score={score} withBackground />
                                    )}
                                    <div
                                        className={classNames({
                                            "popover popover--white":
                                                showPopover,
                                        })}
                                    >
                                        <div className="popover__tip">
                                            <Score
                                                score={score}
                                                className="rating--end"
                                            />
                                            <p>{text}</p>
                                        </div>
                                        <RenderButtonReview
                                            entities={entities}
                                            review={review}
                                            isSpecialist={isSpecialist}
                                            score={score}
                                            text={text}
                                            onClick={openModal}
                                            className="link link--underline link--fw-medium"
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Controls;

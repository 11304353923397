import React, { useEffect } from "react";
import SelectItemToInput from "../../../components/SelectItemToInput";
import AccordionView from "../AccordionView";
import { TranslationObject } from "../../../models/translationModal";
import { endpoints } from "../../../store/directories/directories.api";
import {
    CUSTOM_SERVICE_TYPE,
    SERVICE_CATEGORY_ID,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_TYPE_ID,
} from "../../../store/order/constant";
import { useActions } from "../../../hooks/actions";
import { getItemEditOrder } from "../../../store/order/selectors";

interface Props {
    entities?: TranslationObject;
}

const TypeEditOrder: React.FC<Props> = ({ entities }) => {
    const { updateEditOrderField } = useActions();
    const categoryId = getItemEditOrder(SERVICE_CATEGORY_ID);
    const activeType = getItemEditOrder(SERVICE_TYPE_ID);
    const customValue = getItemEditOrder(CUSTOM_SERVICE_TYPE);
    const [getTypes, { data: types }] =
        endpoints.getServiceTypes.useLazyQuery();

    useEffect(() => {
        if (categoryId) {
            getTypes({
                id: categoryId,
            });
        }
    }, [categoryId]);

    const items = types?.filter((elem) => elem.id !== activeType) || [];

    const handleClick = (id: number) => {
        updateEditOrderField({
            name: SERVICE_TYPE_ID,
            value: Number(activeType) === Number(id) ? null : id,
        });
        clearOtherFields();
    };

    const onChange = (value: string) => {
        updateEditOrderField({
            name: CUSTOM_SERVICE_TYPE,
            value,
        });
    };

    const clearOtherFields = () => {
        updateEditOrderField({
            name: SERVICE_SUB_TYPE_IDS,
            value: [],
        });
        updateEditOrderField({
            name: "filter_pages",
            value: [],
        });
    };

    const deleteValue = () => {
        updateEditOrderField({
            name: SERVICE_TYPE_ID,
            value: null,
        });
        clearOtherFields();
    };

    return (
        <AccordionView
            textButton={entities?.order_select_service_type_title_edit?.value || ""}
        >
            <div className="accordion-form__item-content">
                <p className="accordion-form__item-text">
                    {entities?.order_select_service_type_text?.value}
                </p>
                <div className="accordion-form__item-wrap">
                    <div className="new-order-start">
                        <SelectItemToInput
                            list={types || []}
                            currentItem={activeType ? activeType : customValue}
                            deleteItem={deleteValue}
                            items={items}
                            onSelect={handleClick}
                            isBig
                            maxLength={255}
                            withCustomValue={false}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </div>
        </AccordionView>
    );
};
export default TypeEditOrder;

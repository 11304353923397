import React, { useEffect, useMemo } from "react";
import List from "./List";
import ChatsSidebar from "../../components/ChatsSidebar";
import SubMenu from "../../components/SubMenu";
import SearchOrders from "./Search";
import Filters from "./Filters";
import FiltersControl from "./FiltersControl";
import classNames from "classnames";
import cn from "classnames";
import { TranslationObject } from "../../models/translationModal";
import { ProfilePayload } from "../../models/userModels";
import Reviews from "./Reviews";
import { useActions } from "../../hooks/actions";
import { useSuspendedAccess } from "../../hooks/useSuspendedAccess";
import { checkIsDesktop } from "../../store/directories/selectors";
import { useDispatch } from "react-redux";
import { util as userUtil } from "../../store/user/user.api";
import Advertising from "../../components/Advertising";

interface Props {
    entities?: TranslationObject;
    profile?: ProfilePayload;
}

const Orders: React.FC<Props> = ({ entities, profile }) => {
    const isSpecialist = profile?.user_role === "specialist";

    const { clearOrdersFilters, setOrdersFilter } = useActions();

    const clearFilters = () => clearOrdersFilters();

    const { isSuspendedUser } = useSuspendedAccess();

    const isDesktop = checkIsDesktop();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userUtil.invalidateTags([{ type: "Profile", id: "PROFILE" }]));
        setTimeout(() => window.scrollTo({ top: 0 }), 5);
    }, [dispatch])

    useMemo(() => {
        setOrdersFilter({
            name: "status",
            value: isSpecialist ? "new" : "active",
        })
    }, [isSpecialist])

    return (
        <main>
            <SubMenu entities={entities} profile={profile} />
            <div className="container">
                <section
                    className={classNames("list-orders my-orders", {
                        "spec-page": isSpecialist,
                    })}
                >
                    <div className="list-orders__wrapper">
                        <div className="list-orders__top-block">
                            <h1 className="title title--h1">
                                {entities?.orders_link_value?.value}
                            </h1>
                            <SearchOrders disabled={isSuspendedUser} />
                            <FiltersControl
                                entities={entities}
                                isSpecialist={isSpecialist}
                                isSuspendedUser={isSuspendedUser}
                            />
                        </div>
                        <section
                            className={cn("filter form filter-orders", {
                                "not-orders": isSuspendedUser,
                            })}
                        >
                            {!isSuspendedUser && (
                                <>
                                    <h2 className="visually-hidden">
                                        {
                                            entities
                                                ?.order_filter_for_order_selection
                                                ?.value
                                        }
                                    </h2>
                                    <form>
                                        <Filters
                                            entities={entities}
                                            profile={profile}
                                        />
                                        {isDesktop && (
                                            <button
                                                className="btn btn--transparent"
                                                type="button"
                                                onClick={clearFilters}
                                            >
                                                {entities?.order_reset_filters
                                                    ?.value || ""}
                                            </button>
                                        )}
                                    </form>
                                </>
                            )}
                        </section>
                        <List
                            entities={entities}
                            isSpecialist={isSpecialist}
                            profile={profile}
                        />
                        <div className="list-orders__aside">
                            <ChatsSidebar
                                profile={profile}
                                entities={entities}
                            />
                            {isSpecialist && <Reviews entities={entities} />}
                        </div>
                        {isSpecialist && (
                            <ul className="advertising-list my-orders">
                                <Advertising
                                    typeAdvertising={"thirdParties"}
                                    entities={entities}
                                />
                            </ul>
                        )}
                    </div>
                </section>
            </div>
        </main>
    );
};

export default Orders;

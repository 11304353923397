import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    changeStatusOrder,
    createReview,
    getOrders,
    responseToOrder,
    setUninterested,
    updateReview,
    copyOrder,
} from "./api";
import {
    ChatsPayload,
    ChatsRoot,
    OrderItem,
    OrdersPayload,
    OrdersRootObject,
} from "../../models/ordersModel";
import { changeHeaders } from "../request";
import { BodyData, EmptyPayload, IdProps, StringParams } from "../models";
import {
    CreateOrderResponse,
    UpdateOrderObject,
    UpdateOrderPayload,
} from "../../models/orderModel";
import { createChat, getOrdersStatusesList, updateOrder } from "../order/api";

export const ordersApi = createApi({
    reducerPath: "orders/api",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
        prepareHeaders: (headers, { getState }) =>
            changeHeaders(headers, { getState }),
    }),
    tagTypes: ["Orders", "Localized", "Order"],
    endpoints: (build) => ({
        getOrders: build.query<OrdersPayload, StringParams>({
            query: ({ params }) => getOrders({ params }),
            providesTags: [
                { type: "Orders", id: "LIST" },
                { type: "Orders", id: "getOrders" },
                { type: "Localized" },
            ],
            transformResponse: (response: OrdersRootObject) =>
                response?.payload,
        }),
        createReview: build.mutation<EmptyPayload, BodyData>({
            query: ({ body }) => createReview({ body }),
            invalidatesTags: [
                { type: "Orders", id: "LIST" },
                { type: "Order" },
            ],
        }),
        updateReview: build.mutation<EmptyPayload, BodyData & IdProps>({
            query: ({ body, id }) => updateReview({ body, id }),
            invalidatesTags: [
                { type: "Orders", id: "LIST" },
                { type: "Order" },
            ],
        }),
        changeStatusOrder: build.mutation<OrderItem, BodyData & IdProps>({
            query: ({ body, id }) => changeStatusOrder({ body, id }),
            invalidatesTags: [{ type: "Orders", id: "LIST" }],
        }),
        responseToOrder: build.mutation({
            query: ({ id }) => responseToOrder({ id }),
            invalidatesTags: [
                { type: "Orders", id: "LIST" },
                { type: "Order" },
            ],
        }),
        setUninterestedToOrder: build.mutation({
            query: ({ body, id }) => setUninterested({ body, id }),
            invalidatesTags: [
                { type: "Orders", id: "LIST" },
            ],
        }),
        updateOrder: build.mutation<
            UpdateOrderPayload,
            CreateOrderResponse & IdProps
        >({
            query: ({ body, id }) => updateOrder({ body, id }),
            transformResponse: (response: UpdateOrderObject) =>
                response?.payload,
            invalidatesTags: [
                { type: "Orders", id: "LIST" },
                { type: "Order" },
            ],
        }),
        getOrder: build.query<any, any>({
            query: ({ id }) => `/orders/${id}`,
            transformResponse: (response: UpdateOrderObject) =>
                response?.payload,
            providesTags: [
                { type: "Order" },
                { type: "Order", id: "getOrder" },
                { type: "Localized" },
            ],
        }),
        getPinnedChats: build.query<ChatsPayload[], { params?: string }>({
            query: ({ params }) =>
                params ? `/chats/pinned?` + params : "/chats/pinned",
            transformResponse: (response: ChatsRoot) => response?.payload,
            providesTags: [
                { type: "Orders", id: "CHAT" },
                { type: "Orders", id: "getPinnedChats" },
                { type: "Localized" },
            ],
        }),
        createChat: build.mutation<
            any,
            { body: { order_id: number; specialist_id?: number } }
        >({
            query: ({ body }) => createChat({ body }),
            transformResponse: (response: UpdateOrderObject) =>
                response?.payload,
            invalidatesTags: [
                { type: "Orders", id: "LIST" },
                { type: "Order" },
            ],
        }),
        getOrdersStatusesList: build.query<any, void>({
            query: () => getOrdersStatusesList(),
            transformResponse: (response: { message: string; payload: any }) =>
                response?.payload,
            providesTags: [
                { type: "Orders" },
                { type: "Orders", id: "LIST" }
            ],
        }),
        copyOrder: build.mutation({
            query: ({ id }) => copyOrder({ id }),
            transformResponse: (response: UpdateOrderObject) =>
                response?.payload,
            invalidatesTags: [
                { type: "Orders", id: "LIST" },
            ],
        }),
    }),
});

export const {
    useUpdateOrderMutation,
    endpoints,
    useCreateReviewMutation,
    useUpdateReviewMutation,
    useChangeStatusOrderMutation,
    useResponseToOrderMutation,
    useSetUninterestedToOrderMutation,
    useCreateChatMutation,
    useCopyOrderMutation,
    util,
} = ordersApi;

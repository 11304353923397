import Icon from "../../Icon";
import React from "react";

interface Props {
    onClick: () => void;
    hiddenCloseButton?: boolean;
}

const DeleteButton: React.FC<Props> = ({ onClick, hiddenCloseButton }) => {
    if (hiddenCloseButton) {
        return null;
    }

    return (
        <button
            className="btn"
            type="button"
            aria-label="удалить"
            onClick={onClick}
        >
            <Icon type="delete-input" width={12} height={12} />
        </button>
    );
};

export default DeleteButton;

import React, { useEffect } from "react";
import { endpoints } from "../../store/order/order.api";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { formatQueryString } from "../../untils";
import Card from "../../components/CardSpecialist";
import { CurrentSpecialistsPayload } from "../../models/userModels";
import { useNavigate } from "react-router-dom";
import { TranslationObject } from "../../models/translationModal";
import { getItemCreateOrder } from "../../store/order/selectors";
import { SERVICE_CATEGORY_SLUG, SERVICE_SUB_TYPE_SLUG, SERVICE_TYPE_SLUG } from "../../store/order/constant";

interface Props {
    entities?: TranslationObject;
    changeSpecialistIds?: (value: number) => void;
    specialistIds: number[];
    isEdit?: boolean;
    isPrivate: boolean;
}

const CurrentSpecialists: React.FC<Props> = ({
    entities,
    specialistIds,
    changeSpecialistIds,
    isEdit = false,
    isPrivate,
}) => {
    const navigate = useNavigate();
    const currentLanguage = getCurrentLanguage();
    const [getSpecialists, { data: specialists }] =
        endpoints.getCurrentSpecialists.useLazyQuery();
    const currentLanguages = getCurrentLanguage();
    const linkTo = `/${currentLanguage}/customer/${isEdit ? "edit-order" : "create-order"
        }/specialist`;

    const categoryPath = getItemCreateOrder(SERVICE_CATEGORY_SLUG);
    const typePath = getItemCreateOrder(SERVICE_TYPE_SLUG);
    const subtypePath = getItemCreateOrder(SERVICE_SUB_TYPE_SLUG);
    
    useEffect(() => {
        if (specialistIds && specialistIds?.length < 1) {
            const path = isEdit ?
                `/${currentLanguages}/customer/edit-order/select-specialists` :
                `/${currentLanguage}/customer/create-order/${categoryPath}/${typePath}/${subtypePath}/list-of-specialists`

            navigate(path);
        }
    }, [specialistIds]);

    useEffect(() => {
        if (specialistIds?.length > 0) {
            getSpecialists({
                params: formatQueryString({
                    specialist_ids: specialistIds,
                }),
            });
        }
    }, [specialistIds]);

    const _back = () => {
        const path = isEdit ?
        `/${currentLanguages}/customer/edit-order/select-specialists` :
        `/${currentLanguage}/customer/create-order/${categoryPath}/${typePath}/${subtypePath}/list-of-specialists`

        navigate(path);
    };

    return (
        <section className="find-pro find-pro-selected">
            <div className="container">
                <div className="find-pro__container">
                    <div className="find-pro__wrapper">
                        <h1 className="title title--f46">
                            {entities?.order_selected_specialists?.value}
                        </h1>
                        <div className="find-pro__content">
                            <ul className="find-pro__profiles-list">
                                {specialistIds?.length > 0 &&
                                    specialists?.map(
                                        (
                                            specialist: CurrentSpecialistsPayload,
                                            key: number
                                        ) => (
                                            <Card
                                                isEdit={isEdit}
                                                linkTo={linkTo}
                                                entities={entities}
                                                isAdd={specialistIds?.includes(
                                                    specialist?.id
                                                )}
                                                isPrivate={isPrivate}
                                                specialist={specialist}
                                                key={key}
                                                changeSpecialistIds={
                                                    changeSpecialistIds
                                                }
                                            />
                                        )
                                    )}
                            </ul>

                            <div className="form__row form__controls">
                                <button
                                    className="btn form__back-link btn--transparent"
                                    type="button"
                                    onClick={_back}
                                >
                                    {
                                        entities?.common_back_button
                                            ?.value
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CurrentSpecialists;

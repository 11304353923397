import memoize from "lodash/memoize";
import isArray from "lodash/isArray";
import isPlainObject from "lodash/isPlainObject";
import isString from "lodash/isString";

import { toArray as setToArray } from "./sets";

export const toArray = memoize((value) => {
    if (value instanceof Set) {
        return setToArray(value);
    }

    if (value instanceof Map) {
        return Array.from(value.values());
    }

    if (isString(value)) {
        return value.split(``);
    }

    if (isPlainObject(value)) {
        return Object.values(value);
    }

    if (isArray(value)) {
        return value;
    }
    return [];
});

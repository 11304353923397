import React, { useEffect } from "react";
import {
    checkIsMobile,
    getCurrentLanguage,
} from "../../../../store/directories/selectors";
import { TranslationObject } from "../../../../models/translationModal";
import {
    clearOrderDataInLocalStorage,
    getItemCreateOrder,
} from "../../../../store/order/selectors";
import { useCancelOrderMutation } from "../../../../store/order/order.api";
import { useActions } from "../../../../hooks/actions";
import { ProfilePayload } from "../../../../models/userModels";
import { getTkn } from "../../../../store/user/selectors";
import { ID } from "../../../../store/order/constant";
import { useNavigate } from "react-router-dom";

interface Props {
    entities?: TranslationObject;
    profile?: ProfilePayload;
}

const CancelOrderButton: React.FC<Props> = ({ entities, profile }) => {
    const { clearCreateOrderFields } = useActions();
    const token = getTkn();
    const id = getItemCreateOrder(ID);
    const currentLanguage = getCurrentLanguage();
    const navigate = useNavigate();

    const [canselOrder, { isSuccess: canselIsSuccess }] =
        useCancelOrderMutation();

    const cancelOrder = () => {
        if (profile?.id && token && id) {
            canselOrder({ id });
        } else {
            onSuccessCansel();
        }
    };

    useEffect(() => {
        if (canselIsSuccess) {
            onSuccessCansel();
        }
    }, [canselIsSuccess]);

    const onSuccessCansel = () => {
        clearOrderDataInLocalStorage(false, []);
        clearCreateOrderFields();
        navigate(`/${currentLanguage}/customer`);
    };

    return (
        <button
            onClick={cancelOrder}
            className="btn btn--transparent new-order__btn"
            type="button"
        >
            {entities?.order_cancel_button?.value}
        </button>
    );
};

export default CancelOrderButton;

import { configureStore } from "@reduxjs/toolkit";
import { directoriesReducer } from "./directories/directories.slice";
import { directoriesApi } from "./directories/directories.api";
import { userReducer } from "./user/user.slice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { feedbackApi } from "./feedback/feedback.api";
import { authApi } from "./auth/auth.api";
import { registrationReducer } from "./registration/registration.slise";
import { registrationApi } from "./registration/registration.api";
import { userApi } from "./user/user.api";
import { orderApi } from "./order/order.api";
import { reviewApi } from "./review/review.api";
import { filterOrdersReducer } from "./orders/filterOrders.slice";
import { ordersApi } from "./orders/orders.api";
import { exceptionsApi } from "./exceptions/exceptions.api";
import { editOrderReducer } from "./order/editOrder.slice";
import { createOrderReducer } from "./order/createOrder.slice";
import { notificationsApi } from "./notifications/notifications.api";
import { chatApi } from "./chat/chat.api";
import { chatReducer } from "./chat/chat.slice";
import { financesApi } from "./finances/finances.api";
import { financesReducer } from "./finances/finances.slice";
import { advertisementApi } from "./advertisement/advertisement.api";
import { feedBacksReducer } from "./feedback/feebback.slice";
import { supportApi } from "./support/support.api";
import { supportReducer } from "./support/support.slice";
import { headerReducer } from "./header/header.slice";

export const store = configureStore({
    reducer: {
        [directoriesApi.reducerPath]: directoriesApi.reducer,
        [feedbackApi.reducerPath]: feedbackApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [orderApi.reducerPath]: orderApi.reducer,
        [reviewApi.reducerPath]: reviewApi.reducer,
        [ordersApi.reducerPath]: ordersApi.reducer,
        [exceptionsApi.reducerPath]: exceptionsApi.reducer,
        [registrationApi.reducerPath]: registrationApi.reducer,
        [notificationsApi.reducerPath]: notificationsApi.reducer,
        [chatApi.reducerPath]: chatApi.reducer,
        [financesApi.reducerPath]: financesApi.reducer,
        [advertisementApi.reducerPath]: advertisementApi.reducer,
        [supportApi.reducerPath]: supportApi.reducer,
        directories: directoriesReducer,
        user: userReducer,
        registration: registrationReducer,
        filterOrders: filterOrdersReducer,
        editOrder: editOrderReducer,
        createOrder: createOrderReducer,
        chat: chatReducer,
        finances: financesReducer,
        feedback: feedBacksReducer,
        support: supportReducer,
        header: headerReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(directoriesApi.middleware)
            .concat(feedbackApi.middleware)
            .concat(authApi.middleware)
            .concat(userApi.middleware)
            .concat(orderApi.middleware)
            .concat(reviewApi.middleware)
            .concat(ordersApi.middleware)
            .concat(exceptionsApi.middleware)
            .concat(registrationApi.middleware)
            .concat(notificationsApi.middleware)
            .concat(financesApi.middleware)
            .concat(chatApi.middleware)
            .concat(advertisementApi.middleware)
            .concat(advertisementApi.middleware),
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import Checkbox from "../Form/Checkbox";
import React from "react";
import {
    IS_MY_ADDRESS,
    IS_REMOTE,
    IS_SPECIALIST_ADDRESS,
} from "../../store/order/constant";
import { TranslationObject } from "../../models/translationModal";
import SelectedAddress from "../../pages/CreateOrder/Specialists/SelectedAddress";
import { LocationData } from "../../models/orderModel";
import { createOrderState } from "../../store/order/createOrder.slice";
import { InvalidFieldsProps } from "../Form/Input";

interface Props {
    entities?: TranslationObject;
    invalidFields?: InvalidFieldsProps;
    currentAddresses: string[];
    error?: any;
    handleChange: (name: keyof createOrderState) => void;
    withAddress?: boolean;
    clearAddress?: (value: string) => void;
    specialistLocation?: LocationData | null;
    myLocation?: LocationData | null;
}

const CheckboxAddress: React.FC<Props> = ({
    entities,
    invalidFields,
    currentAddresses,
    error,
    handleChange,
    withAddress = false,
    specialistLocation,
    myLocation,
    clearAddress,
}) => {
    const checkboxes: {
        name: keyof createOrderState;
        label: string;
    }[] = [
        {
            name: IS_REMOTE,
            label: entities?.common_remotely?.value || "",
        },
        {
            name: IS_MY_ADDRESS,
            label: entities?.common_my_address?.value || "",
        },
        {
            name: IS_SPECIALIST_ADDRESS,
            label: entities?.common_specialist_adress?.value || "",
        },
    ];

    return (
        <div>
            {checkboxes?.map((checkbox) => {
                const isMyAddress = checkbox?.name === IS_MY_ADDRESS;
                const isSpecialistAddress =
                    checkbox?.name === IS_SPECIALIST_ADDRESS;
                const showAddress =
                    (isMyAddress &&
                        !!myLocation?.latitude &&
                        !!myLocation?.longitude) ||
                    (isSpecialistAddress &&
                        !!specialistLocation?.latitude &&
                        !!specialistLocation?.longitude);

                const location =
                    (isMyAddress && myLocation ? myLocation : null) ||
                    (isSpecialistAddress && specialistLocation
                        ? specialistLocation
                        : null);

                return (
                    <div key={checkbox.name}>
                        <Checkbox
                            invalidFields={invalidFields}
                            error={error}
                            value={currentAddresses.includes(checkbox.name)}
                            onChange={() => handleChange(checkbox.name)}
                        >
                            {checkbox.label}
                        </Checkbox>
                        <SelectedAddress
                            showAddress={showAddress && withAddress}
                            clearAddress={clearAddress}
                            location={location}
                            type={checkbox.name}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default CheckboxAddress;

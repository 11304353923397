import { ISetSpecialistNewServiceBody } from "../../../models/userModels";
import { useSetSpecialistNewServiceMutation } from "../../../store/user/user.api";
import {
    IServiceFilter,
    ISubType,
} from "../../../components/NewSpecialistService/SpecialistServiceDescriptionNew";
import _ from "lodash";

interface IServiceData {
    [key: string]: any;
}

const getDataValidation = (data: ISetSpecialistNewServiceBody) => {
    //изменила в связи с багоим по валидации полей 
    const {
        is_remote,
        customer_address,
        specialist_address,
        specialist_service_type_filters,
    } = data || {};

    let valid = true;

    if (typeof is_remote !== "boolean") {
        return 'is_remote';
    }

    if (
        !(
            customer_address === null ||
            (customer_address?.value &&
                customer_address?.latitude &&
                customer_address?.longitude &&
                customer_address?.radius)
        )
    ) {
        return 'customer_address';
    }

    if (
        !(
            specialist_address === null ||
            (specialist_address?.value &&
                specialist_address?.latitude &&
                specialist_address?.longitude)
        )
    ) {
        return 'specialist_address';
    }

    if (specialist_service_type_filters?.length) {
        _.each(specialist_service_type_filters, (filter) => {
            if (!filter?.service_type_filter_value_ids?.length) {
                valid = false;
            }
        });
    }

    return valid;
};

export const useSaveNewSpecialistService = () => {
    const [setSpecialistNewService, { isSuccess, isError, error }] =
        useSetSpecialistNewServiceMutation();

    const prepareDataService = (serviceData: IServiceData) => {
        const {
            is_remote,
            customer_address,
            specialist_address,
            description,
            specialist_service_sub_types,
            specialist_service_type_filters,
            photos,
        } = serviceData || {};

        let data: ISetSpecialistNewServiceBody = {
            is_remote,
            customer_address: customer_address?.value ? customer_address : null,
            specialist_address: specialist_address?.value
                ? specialist_address
                : null,
            description,
            specialist_service_sub_types: specialist_service_sub_types?.map(
                (subType: ISubType) => {
                    if (subType?.subTypeElement?.subTypeId) {
                        return {
                            service_sub_type_id:
                                subType?.subTypeElement?.subTypeId,
                            price: subType?.price ?? "",
                            description: subType?.description ?? "",
                        };
                    }
                    if (subType?.customServiceSubType) {
                        return {
                            custom_service_sub_type:
                                subType?.customServiceSubType,
                            price: subType?.price ?? "",
                            description: subType?.description ?? "",
                        };
                    }
                }
            ),
            specialist_service_type_filters:
                specialist_service_type_filters.map(
                    (filter: IServiceFilter) => {
                        return {
                            service_type_filter_id: filter?.id,
                            service_type_filter_value_ids:
                                filter?.values?.reduce(
                                    (acc: number[], curr) => {
                                        if (curr?.active) {
                                            return [...acc, curr?.valueId];
                                        }
                                        return acc;
                                    },
                                    []
                                ),
                        };
                    }
                ),
        };

        photos.forEach(
            (photo: { file: Blob;[key: string]: unknown }, index: number) => {
                data = { ...data, [`photos[${index + 1}]`]: photo?.file };
            }
        );

        const isValidData = getDataValidation(data);

        return {
            isValidData,
            data,
        };
    };

    const addObjectTOFormData = (
        object: Record<string, any>,
        formData: FormData,
        objectKey: string
    ) => {
        for (const key in object) {
            if (object[key]) {
                formData.set(`${objectKey}[${key}]`, object[key]);
            }
        }
    };

    const saveService = function (
        specialistService: string | number,
        body: ISetSpecialistNewServiceBody
    ) {
        const formDataBody = new FormData();

        for (const key in body) {
            if (
                key === "is_remote" ||
                key === "description" ||
                key?.includes("photos")
            ) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                formDataBody.set(key, body[key]);
            }

            if (key === "specialist_address" || key === "customer_address") {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                addObjectTOFormData(body[key], formDataBody, key);
            }

            if (key === "specialist_service_sub_types") {
                body[key]?.forEach((subType, index) => {
                    _.each(subType, (subTypeValue, subTypeKey) => {
                        formDataBody.set(
                            `${key}[${index + 1}][${subTypeKey}]`,
                            subTypeValue?.toString() || ""
                        );
                    });
                });
            }

            if (key === "specialist_service_type_filters") {
                body[key]?.forEach((subType, index) => {
                    _.each(subType, (subTypeValue, subTypeKey) => {
                        if (subTypeKey === "service_type_filter_id") {
                            formDataBody.set(
                                `${key}[${index + 1}][${subTypeKey}]`,
                                subTypeValue.toString()
                            );
                        }

                        if (subTypeKey === "service_type_filter_value_ids") {
                            _.each(subTypeValue as number[], (el, elIndex) => {
                                formDataBody.set(
                                    `${key}[${index + 1}][${subTypeKey}][${elIndex + 1
                                    }]`,
                                    el.toString()
                                );
                            });
                        }
                    });
                });
            }
        }

        setSpecialistNewService({
            specialistService,
            body: formDataBody,
        });
    };

    return {
        saveService,
        prepareDataService,
        saveIsSuccess: isSuccess,
        saveIsError: isError,
        error,
    };
};

import React, { useEffect, useMemo, useState } from "react";
import { AccountSettings } from "./AccountSettings";
import { TellUsAboutYourself } from "./TellUsAboutYourself";
import { ProfessionalAchievements } from "./ProfessionalAchievements";
import { ServicesYouProvide } from "./ServicesYouProvide";
import { ProfileControls } from "./ProfileControls";
import { TranslationObject } from "../../../../models/translationModal";
import { ProfileHeader } from "../ProfileHeader";
import { ProfilePayload } from "../../../../models/userModels";
import { CompanyData } from "./CompanyData";
import { CompanyEmployees } from "./CompanyEmployees";
import {
    NewServiceStepTypes,
    SpecialistNewService,
} from "../../../Registration/SpecialistNewService/SpecialistNewService";
import { useSearchParams } from "react-router-dom";
import { useSendQuestionAiMutation } from '../../../../store/order/order.api';
import { IProfileTag, QuestionAiTypeObject, IAiTag } from "../../../../models/orderModel";
import {
    useSetSpecialistServiceCategoryMutation,
    useSetSpecialistServiceTypeMutation
} from "../../../../store/user/user.api";

type TisShowLoadingAI = 'UnLoad' | 'Loading' | 'Loaded';
type servicePayload = any;

interface ProfileSpecialistContentProps {
    entities?: TranslationObject;
    currentLanguage: string;
    isSpecialistOnUrl: boolean;
    isCustomerOnUrl: boolean;
    profile?: ProfilePayload;
    userSpecialistRole?: { role: string; translate: string };
}

export const ProfileSpecialistContent: React.FC<
    ProfileSpecialistContentProps
> = ({ entities, profile, currentLanguage, userSpecialistRole }) => {
    const {
        name: userName,
        family_name: userFamilyName,
        about_me: userAboutMe,
        avatar: userAvatar,
        services: userServices,
        verification_status: userVerification,
        company: userCompany,
        published_at: userPublished,
        is_suspended: userSuspended,
    } = profile || {};
    const [
        setSpecialistServiceCategory,
        { isSuccess: isSuccessCategory, data: setSpecialistServiceCategoryResponse },
    ] = useSetSpecialistServiceCategoryMutation();
    const [
        setSpecialistServiceType,
        { isSuccess: isSuccessType, data: setSpecialistServiceTypeResponse },
    ] = useSetSpecialistServiceTypeMutation();
    const [sendQuestionAi, { data }] = useSendQuestionAiMutation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [tagsAi, setTagsAi] = useState<IAiTag[]>([]);
    const [currentData, setCurrentData] = useState<QuestionAiTypeObject>();
    // const [tags, setTags] = useState<IProfileTag[]>([]);
    const [tag, setTag] = useState<IProfileTag | null>(null);
    const [isShowLoadingAI, setIsShowLoadingAI] = useState<TisShowLoadingAI>('UnLoad');
    const [isAddService, setIsAddService] = useState<NewServiceStepTypes>('');
    const [servicePayload, setServicePayload] = useState<servicePayload>({});
    const [isEditAbout, setIsEditAbout] = useState(false);
    const isSpecialist = profile?.user_role === "specialist";

    const serviceEditPayload = useMemo(() => {
        const paramsEditServiceId = Number(searchParams.get("id")) || null;
        if (!paramsEditServiceId) return;
        return userServices?.find(
            (service) => service?.id === paramsEditServiceId
        );
    }, [userServices, searchParams]);

    const handleChangeAddService = (serviceId?: number | string) => {
        const searchParams: Record<string, string> = {
            type: "service",
            step: serviceId ? "description" : "category",
        };
        if (serviceId) {
            searchParams.id = serviceId?.toString();
        }
        setSearchParams(new URLSearchParams(searchParams));
        setIsAddService('');
    };

    const changeStep = (step: NewServiceStepTypes, hide?: boolean) => {
        const params = hide
            ? ""
            : {
                  type: "service",
                  step: step,
              };
        setSearchParams(new URLSearchParams(params));
    };

    // Method clear pre service
    const onEventClearPreService = () => {
        setIsAddService('');
    }

    // Событие удаления AboutMe
    const onEventDeleteAboutMe = () => {
        // setTags([]);
        setTagsAi([]);
        setTag(null);
        setCurrentData();
    };

    const onSetTag = (tag: IProfileTag, tagAi?: IAiTag) => {
        const categoryId = data?.length ? tagAi?.data.category : ''
        setTag(null);
        setCurrentData(tagAi?.data);

        if (tag.type === 'category') {
            changeStep(tag.type);
            setIsAddService(tag.type);

        } else if (tag.type === 'type') {
            setSpecialistServiceCategory({ body: { service_category_id: categoryId, }, });
            setIsAddService(tag.type);

        } else if (tag.type === 'subtype') {
            setTag(tag);
            setSpecialistServiceCategory({ body: { service_category_id: categoryId, }, });
            setIsAddService('subType');
        }
    };

    // Получаем список тегов из Ответа Одного
    const getCreateNewTagsList = (tag: QuestionAiTypeObject) => {
        const newTags: IProfileTag[] = [];

        if (tag.category) {
            newTags.push({
                id: tag.category,
                name: tag.categoryName,
                type: 'category'
            });

            if (tag.type) {
                newTags.push({
                    id: tag.type,
                    name: tag.typeName,
                    type: 'type'
                });
            }

            if (tag.subtype && tag.subtype?.length) {
                tag.subtype.forEach(type => newTags.push({
                    id: type.id,
                    name: type.name,
                    type: 'subtype'
                }))
            } else if (tag.subtype && tag.subtype?.id) {
                newTags.push({
                    id: tag.subtype?.id,
                    name: tag.subtype?.name,
                    type: 'subtype'
                });
            }
        }

        return newTags;
    }

    // Установка списка с тегами
    const setNewTags = (tags: QuestionAiTypeObject[]) => {
        const newTags = tags.reduce((total: IAiTag[], tag: QuestionAiTypeObject) => {

            if (tag.category) {
                total.push({
                    data: tag,
                    tags: getCreateNewTagsList(tag)
                });
            }

            return total;
        }, []);

        setTagsAi(newTags);
    };

    const handleIsEditAbout = () => {
        setIsShowLoadingAI('Loading');
        setIsEditAbout(true);
    }

    const sendQAiAwait = async (message: string | undefined, isShowLoading = false) => {
        if (message) {
            (isShowLoading && isShowLoadingAI !== "Loading") && setIsShowLoadingAI('Loading');
            await sendQuestionAi({ message, show_all: 1 });
            setTimeout(() => {
                setIsShowLoadingAI('Loaded');
            }, 1750);
        } else {
            setIsShowLoadingAI('UnLoad');
        }
    }

    useEffect(() => {
        sendQAiAwait(userAboutMe);
    }, []);

    useEffect(() => {
        if (isEditAbout) {
            sendQAiAwait(userAboutMe);
            setIsEditAbout(false);
        }
    }, [userAboutMe]);

    useEffect(() => {
        if (data?.length) {
            setNewTags(data);
            // setIsShowLoadingAI('Loaded');
        }
    }, [data]);

    useEffect(() => {
        const payload = setSpecialistServiceCategoryResponse?.payload;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (isSuccessCategory && payload?.id) {
            setServicePayload(payload);

            if (isAddService === 'type') {
                changeStep(isAddService);
            } else if (isAddService === 'subType') {
                setSpecialistServiceType({
                    specialistService: payload.id,
                    body: { service_type_id: data?.length ? currentData?.type : undefined },
                });
            }
        }
    }, [isSuccessCategory, setSpecialistServiceCategoryResponse]);

    useEffect(() => {
        const payload = setSpecialistServiceTypeResponse?.payload;
        if (
            isSuccessType &&
            (payload?.service_type?.id || payload?.custom_service_type)
        ) {
            setServicePayload(payload);
            changeStep(isAddService);
        }
    }, [isSuccessType, setSpecialistServiceTypeResponse]);

    if (searchParams.get("type") === "service") {
        return (
            <SpecialistNewService
                entities={entities}
                step={searchParams.get("step") as NewServiceStepTypes}
                changeStep={changeStep}
                eventClearPreService={onEventClearPreService}
                serviceEditPayload={isAddService ? servicePayload : serviceEditPayload}
                tags={isAddService ? currentData : undefined}
                tag={isAddService ? tag : undefined}
            />
        );
    }

    return (
        <>
            <main>
                <section className="specialist-profile">
                    <div className="container">
                        <h1 className="specialist-profile__title title title--f46">
                            {entities?.profile_link_value?.value}
                        </h1>
                        <ProfileHeader
                            name={userName}
                            familyName={userFamilyName}
                            visibleChangeAvatar={false}
                            photo={userAvatar?.original_url}
                            setPhoto={() => null}
                            userSpecialistRole={userSpecialistRole}
                            entities={entities}
                        />
                        <div className="specialist-profile__content">
                            <AccountSettings entities={entities} />
                            {(userSpecialistRole?.role === "employee" ||
                                userSpecialistRole?.role === "manager") && (
                                <CompanyData
                                    userCompany={userCompany}
                                    entities={entities}
                                />
                            )}
                            {userSpecialistRole?.role === "manager" && (
                                <CompanyEmployees entities={entities} />
                            )}
                            <TellUsAboutYourself
                                currentLanguage={currentLanguage}
                                userAboutMe={userAboutMe}
                                userVerification={userVerification}
                                userPublished={!!userPublished}
                                userSuspended={userSuspended}
                                entities={entities}
                                tagsAi={tagsAi}
                                isShowLoadingAI={isShowLoadingAI}
                                eventDeleteAboutMe={onEventDeleteAboutMe}
                                handleSetTag={onSetTag}
                                handleIsEditAbout={handleIsEditAbout}
                            />
                            <ProfessionalAchievements
                                profile={profile}
                                entities={entities}
                                userVerification={userVerification}
                                userPublished={!!userPublished}
                                userSuspended={userSuspended}
                            />
                            <ServicesYouProvide
                                userService={userServices}
                                handleChangeAddService={handleChangeAddService}
                                entities={entities}
                            />
                        </div>
                        <div className="specialist-profile__content">
                            <ProfileControls
                                entities={entities}
                                profile={profile}
                                currentLanguage={currentLanguage}
                            />
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    CUSTOM_SERVICE_SUB_TYPE,
    CUSTOM_SERVICE_TYPE,
    DESCRIPTION,
    ENDED_AT,
    ID,
    IS_MY_ADDRESS,
    IS_PRIVATE,
    IS_PUBLISHED,
    IS_REMOTE,
    IS_SPECIALIST_ADDRESS,
    LANGUAGE_IDS,
    MY_ADDRESS,
    PRICE,
    REPEAT_DAY,
    REPEAT_RANGE,
    SERVICE_CATEGORY_ID,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_TYPE_ID,
    SPECIALIST_ADDRESS,
    SPECIALIST_IDS,
    STARTED_AT,
    STATUS,
    TITLE,
} from "./constant";
import { LocationData } from "../../models/orderModel";

export interface editOrderState {
    [SERVICE_CATEGORY_ID]?: number | null;
    [CUSTOM_SERVICE_TYPE]?: string;
    [SERVICE_TYPE_ID]?: number | null;
    [CUSTOM_SERVICE_SUB_TYPE]?: string;
    [SERVICE_SUB_TYPE_IDS]?: number[];
    filter_pages?: {
        service_type_filter_id: number;
        service_type_filter_value_ids: number[];
        custom_filter_value?: string;
    }[];
    [IS_REMOTE]?: boolean;
    [IS_MY_ADDRESS]?: boolean;
    [IS_SPECIALIST_ADDRESS]?: boolean;
    [MY_ADDRESS]?: LocationData | null;
    [SPECIALIST_ADDRESS]?: LocationData | null;
    [STARTED_AT]?: string;
    [ENDED_AT]?: string;
    [REPEAT_RANGE]?: string;
    [REPEAT_DAY]?: number | null;
    [PRICE]?: string | null;
    [DESCRIPTION]?: string;
    [ID]?: number | null;
    [TITLE]?: string;
    [STATUS]?: string;
    [IS_PRIVATE]?: boolean;
    [IS_PUBLISHED]?: boolean;
    [LANGUAGE_IDS]?: number[];
    [SPECIALIST_IDS]?: number[];
    page?: number;
    execution_at?: string | null;
    is_individual?: boolean;
    activeStep?: string;
    userId?: number;
    hasErrorInAddress?: boolean;
}

export const initialState: editOrderState = {
    [SERVICE_CATEGORY_ID]: null,
    [CUSTOM_SERVICE_TYPE]: "",
    [SERVICE_TYPE_ID]: null,
    [CUSTOM_SERVICE_SUB_TYPE]: "",
    [SERVICE_SUB_TYPE_IDS]: [],
    filter_pages: [],
    [IS_REMOTE]: false,
    [IS_MY_ADDRESS]: false,
    [IS_SPECIALIST_ADDRESS]: false,
    [MY_ADDRESS]: null,
    [SPECIALIST_ADDRESS]: null,
    [STARTED_AT]: "",
    [ENDED_AT]: "",
    [REPEAT_RANGE]: "",
    [REPEAT_DAY]: null,
    [PRICE]: null,
    [DESCRIPTION]: "",
    [ID]: null,
    [TITLE]: "",
    [STATUS]: "draft",
    [IS_PRIVATE]: false,
    [IS_PUBLISHED]: false,
    [LANGUAGE_IDS]: [],
    [SPECIALIST_IDS]: [],
    execution_at: null,
};

export const editOrderSlice = createSlice({
    name: "editOrder",
    initialState,
    reducers: {
        updateEditOrderField<editOrderState, K extends keyof editOrderState>(
            state: editOrderState,
            action: PayloadAction<{ name: K; value: editOrderState[K] }>
        ) {
            state[action.payload.name] = action.payload.value;
        },
        clearEditOrderFields(state) {
            const data = { ...initialState };
            state = Object.assign(state, data);
        },
    },
});

export const editOrderActions = editOrderSlice.actions;
export const editOrderReducer = editOrderSlice.reducer;

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TranslationObject } from "../../../../models/translationModal";
import {
    chatMessagesSelector,
    chatMessagesNotAuthSelector,
    isSupportCountMsgMaxSelector
} from "../../../../store/support/support.selectors";
import {
    util as chatUtil,
    endpoints as chatEndpoints,
} from "../../../../store/support/support.api";
import { getItemStore, setItemStore } from "../../../../untils";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useActions } from "../../../../hooks/actions";
import Echo from "laravel-echo";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import io from "socket.io-client";
import "moment/locale/ru";
import moment from "moment";
import { useGetDirectoriesQuery } from "../../../../store/directories/directories.api";
import { ISupportMessage } from "../../../../models/chatModels";
import { getCurrentLanguage } from "../../../../store/directories/selectors";

interface UseGetChatMessagesData {
    chatId?: string | number;
    token: string;
    userId?: string | number;
    role?: string;
    chatRef: MutableRefObject<HTMLDivElement | null>;
    entities?: TranslationObject;
}

// TODO: тяжело читаемый хук, нужно рефакторить
export const useGetSupportMessages = ({
    chatId,
    userId,
    token,
    role,
    chatRef,
    entities,
}: UseGetChatMessagesData) => {
    const { data: directoriesData } = useGetDirectoriesQuery();

    const {
        setSupportMessages,
        addSupportMessage,
        addSupportMessages,
        resetSupportState,
        addSupportMessagesNotAuth,
    } = useActions();

    const currentLanguage = getCurrentLanguage();

    const [anonymousUserId, setAnonymousUserId] = useState(null);

    const supportMessages = useSelector(chatMessagesSelector);

    const chatMessagesNotAuth = useSelector(chatMessagesNotAuthSelector);

    const isSupportCountMsgMax = useSelector(isSupportCountMsgMaxSelector);

    const nextCursor = useRef<string | null>(null);

    const isCursorFetching = useRef<boolean>(false);

    const newCursorMessages = useRef<boolean>(false);

    const chatScrollHeight = useRef<number | null>(null);

    const dispatch = useDispatch();

    const [
        getChatMessages,
        { data: chatMessagesData, isSuccess: isSuccessChatMessages },
    ] = chatEndpoints.getChatMessages.useLazyQuery();

    const scrollToBottomChat = () => {
        if (!chatRef.current) return;

        chatRef.current.scrollTo({
            top: chatRef.current.scrollHeight,
            behavior: "smooth",
        });
    };

    const getFormaterMessages = (messages, isReverse = false) => {
        const result = _.reduceRight(
            messages,
            (acc: any[], curr: ISupportMessage) => {
                const dataMessageDate = moment
                    .utc(curr?.created_at)
                    .local()
                    .locale("ru")
                    .format("D MMMM");

                if (curr.payload) {
                    curr = updateTextVariables(curr, entities);
                }

                if (acc.find((el) => el.date === dataMessageDate)) {
                    return acc.map((el) => {
                        if (el.date === dataMessageDate) {
                            return {
                                ...el,
                                messages: [...el.messages, curr],
                            };
                        }
                        return el;
                    });
                } else {
                    return [
                        ...acc,
                        { date: dataMessageDate, messages: [curr] },
                    ];
                }
            },
            []
        );

        if (isReverse) {
            return result.map(gr => ({ ...gr, messages: gr.messages.reverse() }))
        }

        return result;
    };

    const updateSupMsg = (ms, isSaveLocal = false) => {
        const messages = ms.reduce((total: any[], item: ISupportMessage) => {

            if (item.user_message) {
                total.push({
                    ...item,
                    is_your: true,
                    text: item.user_message,
                });
            }

            total.push({
                ...item,
                is_your: false,
                text: item.bot_response,
            });

            return total;
        }, []);

        if (messages.length) {
            const formatChatMessages = getFormaterMessages(messages, true);
            setSupportMessages(formatChatMessages);
            addSupportMessagesNotAuth(ms);

            if (isSaveLocal) {
                setItemStore('cm', chatMessagesNotAuth);
            }
        }
    };

    useEffect(() => {
        return () => {
            nextCursor.current = null;
            newCursorMessages.current = false;
            chatScrollHeight.current = null;
            resetSupportState();
        };
    }, []);

    useEffect(() => {
        if (!userId) {
            updateSupMsg(chatMessagesNotAuth, true);
        }
    }, [chatMessagesNotAuth]);

    useEffect(() => {
        if (chatId && userId) {
            const requestData = {
                chatId, params: {
                    per_page: 15, page: 1,
                },
            };
            getChatMessages(requestData);
        }
    }, [chatId, currentLanguage]);

    useEffect(() => {
        if (userId) {
            const messages = (chatMessagesData?.data || []).map(msg => ({
                ...msg,
                is_your: !(msg?.bot_response || msg?.admin_id),
                text: msg.message
            }));
            if (messages?.length) {
                const formatChatMessages = getFormaterMessages(messages);
                if (nextCursor.current && isCursorFetching.current) {
                    addSupportMessages(formatChatMessages);
                    isCursorFetching.current = false;
                    newCursorMessages.current = true;
                } else {
                    setSupportMessages(formatChatMessages);
                }
            }

            nextCursor.current = chatMessagesData?.next_cursor ?? null;
        }
    }, [chatMessagesData, isSuccessChatMessages]);

    const generateUniqueId = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };

    useEffect(() => {

        if (!userId && !chatId) {
            let storedUserId = localStorage.getItem('anonymous');

            if (!storedUserId) {
                storedUserId = generateUniqueId();
                localStorage.setItem('anonymous', storedUserId);
            }

            setAnonymousUserId(storedUserId);

            const client = new Echo({
                broadcaster: `socket.io`,
                host: directoriesData?.echo_server?.host,
                transports: [`websocket`],
                client: io
            });

            client
                .channel(`chats.anonymous.${storedUserId}`)
                .listen(
                    `.chat_message_was_sent`,
                    function (socketMessage: ISupportMessage) {
                        if (socketMessage) {
                            const socketMessageDate = moment
                                .utc(socketMessage?.created_at)
                                .local()
                                .locale("ru")
                                .format("D MMMM");

                            addSupportMessage({
                                date: socketMessageDate,
                                messages: [{
                                    ...socketMessage,
                                    is_your: !(socketMessage?.bot_response || socketMessage?.admin_id),
                                    text: socketMessage?.message
                                }],
                            });

                            setTimeout(() => {
                                scrollToBottomChat();
                            }, 500);
                        }
                    }
                );

        }

        if (
            chatId &&
            userId &&
            token &&
            role &&
            directoriesData?.echo_server?.host
        ) {
            const client = new Echo({
                broadcaster: `socket.io`,
                host: directoriesData?.echo_server?.host,
                transports: [`websocket`],
                client: io,
                auth: {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                },
            });

            client
                .private(`chats.${chatId}.${role}.${userId}`)
                .listen(
                    `.chat_message_was_sent`,
                    function (socketMessage: ISupportMessage) {

                        if (socketMessage) {
                            const socketMessageDate = moment
                                .utc(socketMessage?.created_at)
                                .local()
                                .locale("ru")
                                .format("D MMMM");

                            if (socketMessage.payload) {
                                socketMessage = updateTextVariables(
                                    socketMessage,
                                    entities
                                );
                            }

                            if (
                                socketMessage.code ===
                                "specialist_offers_to_make_a_deal" ||
                                socketMessage.code === "order_offer_reject" ||
                                socketMessage.code ===
                                "customer_stopped_search_specialist"
                            ) {
                                dispatch(chatUtil.invalidateTags(["Support"]));
                            }

                            addSupportMessage({
                                date: socketMessageDate,
                                messages: [{
                                    ...socketMessage,
                                    is_your: !(socketMessage?.bot_response || socketMessage?.admin_id),
                                    text: socketMessage?.message
                                }],
                            });

                            setTimeout(() => {
                                scrollToBottomChat();
                            }, 500);
                        }
                    }
                );

            return () => {
                client.leave(`chats.${chatId}.${role}.${userId}`);
            };
        } else {
            const localeMessagesAi = getItemStore('cm');
            if (localeMessagesAi?.length) {
                updateSupMsg(localeMessagesAi, false);
            }
        }
    }, [chatId, userId, token, role, directoriesData?.echo_server?.host]);

    useEffect(() => {
        if (
            chatRef.current &&
            supportMessages?.length &&
            !chatScrollHeight.current
        ) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
            chatScrollHeight.current = chatRef.current.scrollHeight;
        } else if (
            chatRef.current &&
            supportMessages?.length &&
            chatScrollHeight.current &&
            newCursorMessages.current
        ) {
            chatRef.current.scrollTop =
                chatRef.current.scrollHeight - chatScrollHeight.current;
            chatScrollHeight.current = chatRef.current.scrollHeight;
            newCursorMessages.current = false;
        }
    }, [chatRef, supportMessages, chatScrollHeight, newCursorMessages]);

    useEffect(() => {
        if ((supportMessages.length && newCursorMessages.current) || (supportMessages.length && !userId)) {
            scrollToBottomChat();
        }
    }, [supportMessages]);

    useEffect(() => {
        if (isSupportCountMsgMax && chatMessagesNotAuth.length === 3) {
            updateSupMsg([...chatMessagesNotAuth, {
                bot_system: true,
                bot_response: '',
            }], true);
        }
    }, [isSupportCountMsgMax, chatMessagesNotAuth])

    const supporthandleClickNextCursor = () => {
        if (nextCursor.current && chatId) {
            const requestData = {
                chatId,
                params: {
                    per_page: 15,
                    cursor: nextCursor.current,
                },
            };

            getChatMessages(requestData);

            isCursorFetching.current = true;
        }
    };

    return {
        supportMessages,
        supporthandleClickNextCursor,
    };
};

function updateTextVariables(message: ISupportMessage, entities?: TranslationObject) {
    let text = message.text || message.message;
    const { email, phone, phone_customer, price, title, avatar, full_name } =
        message.payload || {};

    if (title) {
        text =
            `<p class='title'>${entities?.specialist_contacts?.value}</p>` +
            text;
    }

    if (price) {
        text = text.replace(
            ":price",
            `<span class='highlight'>${price.toString()}</span>`
        );
    }

    if (email) {
        text = text.replace(email, `<span class='highlight'>${email}</span>`);
    }

    if (avatar) {
        text += `<img class='avatar' src='${avatar.original_url}' alt='avatar'/>`;
    }

    if (full_name) {
        text += `<p class='full_name'>${full_name}</p>`;
    }

    if (phone_customer) {
        text = text.replace(phone_customer, `<span class='highlight'>${phone_customer}</span>`);
        text += `<p class='phone'>${phone_customer}</p>`;
    } else if (phone) {
        text = text.replace(phone, `<span class='highlight'>${phone}</span>`);
        text += `<p class='phone'>${phone}</p>`;
    }

    return {
        ...message,
        text,
    };
}

import React, { useEffect, useState } from "react";
import CheckList from "../../../components/CheckList";
import { endpoints } from "../../../store/directories/directories.api";
import { getFilter } from "../../../store/orders/selectors";
import { useActions } from "../../../hooks/actions";
import Icon from "../../../components/Icon";
import {
    SERVICE_SUB_TYPE_IDS,
    SERVICE_TYPE_ID,
} from "../../../store/order/constant";
import { TranslationObject } from "../../../models/translationModal";
import { ProfilePayload } from "../../../models/userModels";
import cn from "classnames";
import { SubTypesPayload } from "../../../models/directoryModel";

interface Props {
    entities?: TranslationObject;
    profile?: ProfilePayload;
    setActiveMobileFilter: () => void;
    activeMobileFilter: string;
}

const SubTypesFilter: React.FC<Props> = ({
    entities,
    profile,
    activeMobileFilter,
    setActiveMobileFilter,
}) => {
    const { setOrdersFilter } = useActions();
    const typeId = getFilter(SERVICE_TYPE_ID);
    const ids = getFilter(SERVICE_SUB_TYPE_IDS);
    const [getSubTypes, { data: subTypes, isSuccess }] =
        endpoints.getServiceSubTypes.useLazyQuery();
    const title = entities?.order_sub_type?.value;
    const tilteChecked = entities?.order_sub_type_checked?.value;
    const [checkedSubTypes, setCheckedSubTypes] = useState<SubTypesPayload[]>([]);

    const isSpecialist = profile?.user_role === "specialist";
    const profileSubTypes =
        (isSpecialist &&
            profile?.services
                ?.map((service) => service?.service_sub_types)
                ?.reduce((items: number[], cur) => {
                    const ids: number[] =
                        cur.map((elem) => elem?.service_sub_type?.id) || [];
                    return [...items, ...ids];
                }, [])) ||
        [];

    const specialistSubTypes =
        subTypes?.filter((elem) => profileSubTypes?.indexOf(elem?.id) !== -1) ||
        [];

    useEffect(() => {
        if (typeId) {
            getSubTypes({
                id: typeId,
            });
        }
    }, [typeId]);

    useEffect(() => {
        const subs: SubTypesPayload[] = isSpecialist ? specialistSubTypes : subTypes || []

        if (subs && subs?.length) {
            setCheckedSubTypes(subs.filter(item => {
                return ids.includes(item.id)
            }))
        } else {
            setCheckedSubTypes([]);
        }
    }, [ids]);

    const onChange = (i: number) => {
        setOrdersFilter({
            name: SERVICE_SUB_TYPE_IDS,
            value: ids.includes(i)
                ? ids.filter((elem) => elem !== i)
                : ids.concat(i),
        });
    };

    if (!typeId || (subTypes && subTypes?.length < 1)) {
        return null;
    }

    return (
        <>
            {!!checkedSubTypes?.length && <div className="filter__block">
                <div className="filter__subtitle">
                    {tilteChecked}
                </div>
                <div className="filter__wrap-block">
                    <CheckList
                        entities={entities}
                        isSuccess={isSuccess}
                        onChange={onChange}
                        items={checkedSubTypes}
                        ids={ids || []}
                        isDefShowList
                        isMultiple
                    />
                </div>
            </div>}
            <div
                className={cn("filter__block", {
                    "is-open": activeMobileFilter === "sub_types" && typeId,
                })}
            >
                <button
                    className="filter__subtitle"
                    onClick={setActiveMobileFilter}
                    type="button"
                >
                    {title}
                    <Icon type="arrow-left-pagination" width={9} height={18} />
                </button>
                <div className="filter__wrap-block">
                    <CheckList
                        entities={entities}
                        isSuccess={isSuccess}
                        onChange={onChange}
                        items={isSpecialist ? specialistSubTypes : subTypes || []}
                        ids={ids || []}
                        isMultiple
                    />
                </div>
            </div>
        </>
    );
};

export default SubTypesFilter;

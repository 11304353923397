import React from "react";
import { ComponentProps } from "../../models/models";
import { ProfilePayload } from "../../models/userModels";
import { useAddCardModal, useBankCards, useSpecialistRole } from "./hooks";
import { useWindowSize } from "../../hooks/useWindowSize";
import {
    AddNewCard,
    MainCard,
    OtherCards,
    PaymentHistory,
    SectionBlock,
} from "./elements";
import { IBankCard } from "../../models/financesModel";
import { MobileCards } from "./elements/MobileCards";
import { BankCardsModal } from "../../components/Modals/BankCardsModal";

interface MyFinancesProps extends ComponentProps {
    profile?: ProfilePayload;
}

export const MyFinances: React.FC<MyFinancesProps> = (props) => {
    const { profile, entities } = props || {};
    const isSpecialist = profile?.user_role === "specialist";

    const { specialistRole } = useSpecialistRole(profile);

    const { width } = useWindowSize();

    const { mainCard, othersCards, deleteBankCard } = useBankCards();

    const {
        showAddCardModal,
        hideAddCardModal,
        openAddCardModal,
        newCardHandler,
    } = useAddCardModal(entities);

    return (
        <>
            <BankCardsModal
                visible={showAddCardModal}
                closeModal={hideAddCardModal}
                confirmModal={() => null}
                typeModalProps={"newCard"}
                newCardHandler={newCardHandler}
                backHandler={hideAddCardModal}
                entities={entities}
            />
            <main>
                <div className="container">
                    <section className="my-finances spec-page">
                        <div className="my-finances__wrapper">
                            <h1 className="title title--h1">
                                {entities?.finances_title?.value}
                            </h1>
                            {width > 767 && (
                                <>
                                    <SectionBlock
                                        title={
                                            entities?.finances_main_card
                                                ?.value || ""
                                        }
                                        show={!!mainCard}
                                    >
                                        <div className="main-card__wrapper">
                                            <MainCard
                                                mainCard={mainCard as IBankCard}
                                            />
                                            {!othersCards?.length && (
                                                <AddNewCard
                                                    openAddCardModal={
                                                        openAddCardModal
                                                    }
                                                    entities={entities}
                                                />
                                            )}
                                        </div>
                                    </SectionBlock>
                                    <SectionBlock
                                        title={
                                            entities?.finances_additional_cards
                                                ?.value ?? ""
                                        }
                                        show={!!othersCards.length}
                                    >
                                        <OtherCards
                                            otherCards={othersCards}
                                            openAddCardModal={openAddCardModal}
                                            deleteBankCard={deleteBankCard}
                                        />
                                    </SectionBlock>
                                    <SectionBlock
                                        title={
                                            entities?.finances_my_cards
                                                ?.value ?? ""
                                        }
                                        show={!mainCard && !othersCards?.length}
                                    >
                                        <AddNewCard
                                            openAddCardModal={openAddCardModal}
                                        />
                                    </SectionBlock>
                                    <SectionBlock
                                        title={
                                            entities?.finances_payments_history
                                                ?.value ?? ""
                                        }
                                    >
                                        <PaymentHistory
                                            specialistRole={specialistRole}
                                            entities={entities}
                                        />
                                    </SectionBlock>
                                </>
                            )}
                            {width <= 767 && (
                                <>
                                    <MobileCards
                                        mainCard={mainCard as IBankCard}
                                        otherCards={othersCards}
                                        openAddCardModal={openAddCardModal}
                                        deleteBankCard={deleteBankCard}
                                    />
                                    <SectionBlock
                                        title={
                                            entities?.finances_payments_history
                                                ?.value || ""
                                        }
                                    >
                                        <PaymentHistory
                                            specialistRole={specialistRole}
                                            entities={entities}
                                        />
                                    </SectionBlock>
                                </>
                            )}
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
};

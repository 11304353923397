import Modal from "../Modal";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ComponentProps } from "../../models/models";

interface Props extends ComponentProps {
    showModal: boolean;
    setShowModal: any;
}

const AuthSelectModal: React.FC<Props> = ({
    entities,
    currentLanguage,
    showModal,
    setShowModal,
}) => {
    const navigate = useNavigate();

    const goTo = (str: string, type: string) =>
        navigate(`/${currentLanguage}/${str}/${type}`);

    return (
        <Modal
            visible={showModal}
            onClose={() => setShowModal(false)}
            title={entities?.login_modal_title?.value}
            description={entities?.login_modal_description?.value}
        >
            <div className="modal__content-buttons">
                <button
                    className="btn modal__content-button"
                    type="button"
                    style={{ marginRight: "16px" }}
                    onClick={() => {
                        goTo("customer", "auth");
                        setShowModal(false);
                    }}
                >
                    {entities?.customer_link_value?.value}
                </button>
                <button
                    className="btn modal__content-button btn--green"
                    type="button"
                    onClick={() => {
                        goTo("specialist", "auth");
                        setShowModal(false);
                    }}
                >
                    {entities?.specialist_link_value?.value}
                </button>
            </div>
        </Modal>
    );
};

export default AuthSelectModal;

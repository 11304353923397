import { DateTime } from "luxon";
import React from "react";
import { TranslationObject } from "../../../models/translationModal";
import { Review } from "../../../models/ordersModel";

interface Props {
    entities?: TranslationObject;
    review: Review;
    isSpecialist: boolean;
    score?: number;
    text?: string;
    onClick: () => void;
    className: string;
    isOrder?: boolean
}

export const RenderButtonReview: React.FC<Props> = ({
    entities,
    review,
    isSpecialist,
    score,
    text,
    onClick,
    className,
    isOrder
}) => {
    const timeIsOver =
        !!review?.editable_until &&
        DateTime?.fromSQL(review?.editable_until) < DateTime.local();

    const handleClick = () => {
        if (timeIsOver) {
            return;
        }
        onClick();
    };


    if (timeIsOver) {
        if (isOrder) {
            return null
        }
        return (
            <div className={className}>{entities?.order_review?.value}</div>
        );
    }

    if (score && !text && !isSpecialist) {
        return (
            <div className={className} onClick={handleClick}>
                {entities?.order_write_review?.value}
            </div>
        );
    }

    return (
        <div className={className} onClick={handleClick}>
            {entities?.order_edit_review?.value}
        </div>
    );
};

export default RenderButtonReview;

import React, { useState, useEffect, ReactElement } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { routes } from "./routes";
import Layout from "./components/Layout";
import {
    getArrayToObject,
    getCurrentLanguage,
    getLanguages,
    isCustomerUrl,
    isSpecialistUrl,
} from "./store/directories/selectors";
import {
    endpoints,
    useGetDirectoriesQuery,
} from "./store/directories/directories.api";
import { endpoints as getEndpoints } from "./store/user/user.api";
import Loader from "./components/Loader";
import ProtectRoute from "./components/ProtectRoute";
import { getTkn } from "./store/user/selectors";
import { NotificationProvider } from "./hooks/useNotification";
import { AppSocketProvider } from "./providers";
import { HelmetProvider } from 'react-helmet-async'
import { useActions } from "./hooks/actions";
import { setCookie } from "./untils/cookies";

function App() {
    const currentLanguage = getCurrentLanguage();
    const { data, isLoading: isLoadingTranslations } =
        endpoints.getTranslations.useQuery();
    const token = getTkn();
    const [update, { data: profile, isLoading: isLoadingProfile }] =
        getEndpoints.getProfile.useLazyQuery();
    const location = useLocation();
    const [isCustomerOnUrl, setIsCustomerOnUrl] = useState(isCustomerUrl());
    const [isSpecialistOnUrl, setIsSpecialistOnUrl] = useState(
        isSpecialistUrl()
    );
    const { data: directories, isLoading: isLoadingDirectories } =
        useGetDirectoriesQuery();
    const entities = getArrayToObject(data);
    const isLoading = isLoadingTranslations || isLoadingDirectories;
    const { updateLanguage } = useActions();

    useEffect(() => {
        if (token) {
            update();
        }
    }, [token]);

    const items = routes({
        entities,
        currentLanguage,
        isCustomerOnUrl: isCustomerOnUrl,
        isSpecialistOnUrl: isSpecialistOnUrl,
        profile,
        data: directories,
    });

    useEffect(() => {
        setIsCustomerOnUrl(isCustomerUrl());
        setIsSpecialistOnUrl(isSpecialistUrl());

        const urlLanguage = location.pathname.split('/')?.[1] || '';

        if (urlLanguage !== currentLanguage) {
            const languages = getLanguages();
            const findedLanguage = languages.find(item => item.value === urlLanguage);

            if (findedLanguage) {
                updateLanguage(urlLanguage);
                setCookie('language', urlLanguage);
            } 
        }

    }, [location.pathname]);

    return isLoading ? (
        <Loader />
    ) : (
        <AppSocketProvider
            settings={{
                userId: profile?.id ?? null,
                userRole: profile?.user_role ?? null,
                host: directories?.echo_server?.host ?? null,
                token: token ?? null,
            }}
        >
            <HelmetProvider>
                <Layout
                    entities={entities}
                    isCustomerOnUrl={isCustomerOnUrl}
                    isSpecialistOnUrl={isSpecialistOnUrl}
                    profile={profile}
                >
                    <NotificationProvider entities={entities}>
                        <Routes>
                            {items?.map(
                                (router: {
                                    path: string;
                                    element: ReactElement;
                                    needAuth?: boolean;
                                }) => {
                                    if (router?.needAuth) {
                                        return (
                                            <Route
                                                path={router.path}
                                                key={router.path}
                                                element={
                                                    <ProtectRoute
                                                        isLoading={isLoadingProfile}
                                                        profile={profile}
                                                        path={router.path}
                                                        key={router.path}
                                                        element={router.element}
                                                    />
                                                }
                                            />
                                        );
                                    } else {
                                        return (
                                            <Route
                                                path={router.path}
                                                key={router.path}
                                                element={router.element}
                                            />
                                        );
                                    }
                                }
                            )}
                        </Routes>
                    </NotificationProvider>
                </Layout>
            </HelmetProvider>
        </AppSocketProvider>
    );
}

export default App;

import React from "react";
import { useNotification } from "../../hooks/useNotification";
import { TranslationObject } from "../../models/translationModal";
import { ProfilePayload } from "../../models/userModels";
import { usePublishSpecialistProfileMutation } from "../../store/user/user.api";

interface Props {
    entities?: TranslationObject;
    profile?: ProfilePayload
}

const PublishSpecialistProfile: React.FC<Props> = ({
    entities, profile
}) => {
    const { about_me, services, has_password, published_at, user_role } = profile || {}

    const text = profile?.is_draft ? entities?.common_draft_account_not_published?.value : (entities?.common_account_not_published?.value || `Внимание! Ваш профиль ещё не опубликован. Чтобы использовать все возможности FindPro - активируйте профиль, нажав кнопку "Опубликовать". `)
    const textButton = profile?.is_draft ? entities?.profile_specialist_questionnaire_send_verification?.value : (entities?.specialist_details_submit?.value ||
        "")
    const readyPublished = !!(about_me && services?.length && services.every(service => {
        return service.is_remote || service.specialist_address || service.customer_address;
    }));

    const { setNotification } = useNotification();

    const [
        publishSpecialistProfile,
    ] = usePublishSpecialistProfileMutation();

    const show =
        has_password &&
        about_me &&
        services?.length &&
        services.every(service =>
            service.is_remote ||
            service.specialist_address ||
            service.customer_address
        ) &&
        !published_at &&
        user_role === "specialist";

    if (!show) {
        return null
    }

    const handleClickPublishQuestionnaire = () => {
        publishSpecialistProfile()
            .unwrap()
            .catch(() =>
                setNotification({
                    type: "warn",
                    description:
                        entities
                            ?.specialist_details_profile_has_not_been_published
                            ?.value ?? "",
                })
            );
    };

    return (<div className="cookies unverified-notification">
        <div className="cookies__container" >
            <p
                className="cookies__text"
                dangerouslySetInnerHTML={{
                    __html: text
                }
                }
            />
            <button
                className="btn btn--transparent specialist-profile__block-btn  btn--bg-green"
                type="button"
                disabled={!readyPublished}
                onClick={handleClickPublishQuestionnaire}
            >
                {textButton}
            </button>
        </div>
    </div>)
}

export default PublishSpecialistProfile
import React, { useMemo, useState } from "react";
import { BankCardsModal } from "../../../../../components/Modals/BankCardsModal";
import { ISelectCard } from "../../../../../components/Modals/BankCardsModal/BankCardsModal";
import { useLoading } from "../../../../../hooks/useLoading/useLoading";
import { IOffer } from "../../../../../models/ordersModel";
import { TranslationObject } from "../../../../../models/translationModal";
import { useMakeOfferOrderMutation } from "../../../../../store/chat/chat.api";

interface SpecialistButtonsProps {
    orderId?: string;
    price?: number;
    entities?: TranslationObject;
    offer?: IOffer | null;
}

export const SpecialistButtons: React.FC<SpecialistButtonsProps> = ({
    orderId = "",
    price,
    entities,
    offer,
}) => {
    const [visibleBankCardsModal, setVisibleBankCardsModal] =
        useState<boolean>(false);

    const [typeModalProps, setTypeModalProps] = useState('cards')

    const [makeOfferOrder, { isSuccess, isError }] =
        useMakeOfferOrderMutation();

    useMemo(() => {
        if (isSuccess) {
            setTypeModalProps('isSuccessPaymentMethod')
        }
    }, [isSuccess])

    useMemo(() => {
        if (isError) {
            setTypeModalProps('isError')
        }
    }, [isError])

    const { setLoading } = useLoading();

    const bankCardModalConfirm = (selectCard: ISelectCard, orderId: string) => {
        if (selectCard?.value && orderId) {
            setLoading(true);
            const requestData = {
                orderId: orderId,
                body: {
                    card_id: selectCard?.value,
                },
            };

            makeOfferOrder(requestData)
                .unwrap()
                .finally(() => setLoading(false));
        }
    };

    if (offer && offer.status != 'decline' && !visibleBankCardsModal) {
        document.body.style.overflow = "auto";
        document.documentElement.style.overflow = "auto";
        return null;
    }
    const closeModal = () => {
        setVisibleBankCardsModal(false)
        setTypeModalProps('cards')
    }

    return (
        <>
            <BankCardsModal
                visible={visibleBankCardsModal}
                closeModal={closeModal}
                confirmModal={(selectCard) => {
                    bankCardModalConfirm(selectCard, orderId);
                }}
                price={price}
                entities={entities}
                typeModalProps={typeModalProps}
            />
            <div className="form__row chat__controls">
                <div className="popover popover--grey">
                    <button
                        className="btn chat__btn specialist"
                        type="button"
                        onClick={() => setVisibleBankCardsModal(true)}
                    >
                        {entities?.order_offer_a_deal?.value}
                    </button>
                    <span className="popover__tip">
                        {entities?.order_offer_disclosure_request?.value}
                    </span>
                </div>
            </div>
        </>
    );
};

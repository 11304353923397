import React from "react";
// import { useWindowSize } from "../../hooks/useWindowSize/useWindowSize";
import cn from "classnames";
import { motion } from "framer-motion";
import SelectLanguages from "../SelectLanguages";
import Icon from "../Icon";
import {
    checkIsTablet,
    checkIsMobile,
} from "../../store/directories/selectors";

interface HeaderBurgerContainerProps {
    children: React.ReactNode;
    isOpen: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HeaderBurgerContainer: React.FC<HeaderBurgerContainerProps> = ({
    children,
    isOpen,
    setOpen,
}) => {
    // const { width } = useWindowSize();

    // const isTablet = width < 1023 && width > 480;

    // const isMobile = width < 480;
    const isMobile = checkIsMobile();
    const isTablet = checkIsTablet();

    if (!isTablet && !isMobile) {
        return <>{children}</>;
    }

    const variants = {
        open: {
            opacity: 1,
            minHeight: isTablet ? "80px" : "auto",
            display: isTablet ? "grid" : "block",
            gridTemplateColumns: isTablet ? "1fr min-content" : "none",
            gap: isTablet ? 10 : "none",
            alignItems: isTablet ? "center" : "none",
            overflow: "visible",
            transition: {
                minHeight: {
                    duration: 0.2,
                },
                opacity: {
                    duration: 0.15,
                    delay: 0.05,
                },
            },
        },
        closed: {
            opacity: 0,
            minHeight: 0,
            overflow: "hidden",
            transition: {
                minHeight: {
                    duration: 0.2,
                },
                opacity: {
                    duration: 0.15,
                },
            },
            transitionEnd: {
                display: "none",
            },
        },
    };

    return (
        <motion.div
            initial={false}
            animate={isOpen ? "open" : "closed"}
            variants={variants}
            className={cn("burger-container", {
                tablet: isTablet,
                mobile: isMobile,
            })}
        >
            {isMobile && <MobileHeader setOpen={setOpen} />}
            {children}
        </motion.div>
    );
};

interface MobileHeaderProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ setOpen }) => {
    return (
        <div className="mobile-header">
            <button
                className="close-btn"
                type="button"
                onClick={() => setOpen(false)}
                name="close"
            >
                <Icon
                    type="close-input-current-color"
                    width={16}
                    height={16}
                    colorVariant={"darkBLue"}
                />
            </button>
        </div>
    );
};

import React, { useState } from "react";
import Icon from "../Icon";
import { ChangeIcon } from "./ChangeIcon";
import { useDeleteAvatarMutation } from "../../store/user/user.api";
import { getTkn } from "../../store/user/selectors";
import { TranslationObject } from "../../models/translationModal";

export type UserType = "customer" | "specialist";

export const clearAvatarInput = () => {
    const avatar = document.getElementById(
        "avatar"
    ) as HTMLInputElement;
    if (avatar) avatar["value"] = "";
}

const AvatarInput: React.FC<{
    setPhoto: (value: string | null) => void;
    photo?: string | null;
    classes?: string[];
    userType?: UserType;
    visibleChangeAvatar?: boolean;
    entities?: TranslationObject;
    setAvaterIsDeleted?: (value: boolean) => void;
    avaterIsDeleted: boolean
}> = ({
    setPhoto,
    setAvaterIsDeleted,
    avaterIsDeleted,
    photo,
    classes,
    userType = "customer",
    visibleChangeAvatar = true,
    entities,
}) => {
        const token = getTkn();
        const [photoUpload, setPhotoUpload] = useState<boolean>(false);

        const addPhoto = (event: React.ChangeEvent) => {
            if (!visibleChangeAvatar) return;
            const target = event.target as HTMLInputElement;
            const items = target.files as FileList;
            const files = Array.from(items);
            if (!files.length) {
                return;
            }

            if (setAvaterIsDeleted) {
                setAvaterIsDeleted(false)
            }

            files.forEach((file) => {
                if (!file?.type?.match("image")) {
                    return;
                }
                const reader = new FileReader();

                reader.onload = (ev) => {
                    const trg = ev.target as FileReader;
                    setPhoto(trg?.result);
                };

                reader.readAsDataURL(file);
            });
            setPhotoUpload(true);
        };

        const deletePhoto = (e: React.MouseEvent) => {
            if (!visibleChangeAvatar) return;

            setPhotoUpload(false);

            if (setAvaterIsDeleted) {
                setAvaterIsDeleted(true)
            }

            if (photo) {
                e.preventDefault();
                clearAvatarInput()
                return setPhoto(null);
            }
        };

        return (
            <div className={`upload-foto ${classes?.join(" ")}`}>
                {photo && !avaterIsDeleted ? (
                    <div className="avatar avatar--md avatar--rounded">
                        <img src={photo} width="120" height="120" alt="Avatar" />
                    </div>
                ) : (
                    <Icon
                        type="camera_with_ellipse"
                        className="upload-foto__icon"
                        width={120}
                        height={120}
                    />
                )}
                {visibleChangeAvatar && (
                    <div className="upload-foto__btn">
                        <div className="custom-input upload-foto__btn-input upload-foto__btn-input--file">
                            <label>
                                <span className="custom-input__label">
                                    {entities?.common_upload_photo?.value}
                                </span>
                                <input
                                    onChange={addPhoto}
                                    type="file"
                                    id="avatar"
                                    name={photoUpload ? "avatar" : ""}
                                    onClick={deletePhoto}
                                />
                            </label>
                        </div>
                        <ChangeIcon userType={userType} photo={photo} />
                    </div>
                )}
            </div>
        );
    };

export default AvatarInput;

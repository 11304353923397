import React, { useRef, useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer/index";
import { TranslationObject } from "../../models/translationModal";
import { ProfilePayload } from "../../models/userModels";
import { getCurrentLanguage } from "../../store/directories/selectors";
import Loader from "../Loader";
import { useLoading } from "../../hooks/useLoading/useLoading";
import { UnverifiedNotification } from "../UnverifiedNotification";
import { getTkn } from "../../store/user/selectors";
import { SuspendedNotification } from "../SuspendedNotification";
import PublishSpecialistProfile from "../PublishSpecialistProfile";
import Chat from "../Chat";

const Layout = ({
    children,
    entities,
    isSpecialistOnUrl,
    isCustomerOnUrl,
    profile,
}: {
    children: React.ReactNode;
    entities: TranslationObject;
    isSpecialistOnUrl?: boolean;
    isCustomerOnUrl?: boolean;
    profile?: ProfilePayload;
}) => {
    const currentLanguage = getCurrentLanguage();

    const token = getTkn();

    const isSpecialist = profile?.user_role === "specialist";

    const { loading } = useLoading();

    const { verification_status, published_at } = profile || {};

    const showUnverifiedNotification =
        isSpecialist &&
        token &&
        verification_status === "unverified" &&
        published_at;
    const isFirefox = window.navigator.userAgent.indexOf('Firefox') !== -1;
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const wrapper = wrapperRef?.current;

        if (wrapper) {
            wrapper.style.height = '';
            setTimeout(() => {
                const height = wrapper.getBoundingClientRect()?.height;
                wrapper.style.height = `${height}px`;
            }, 1);
        }
    }, [wrapperRef, location?.href]);
    const [isAuth, setIsAuth] = useState(!!profile?.id);

    useEffect(() => {
        setIsAuth(!!profile?.id)
    }, [profile, profile?.id])

    return (
        <>
            <Header
                profile={profile}
                currentLanguage={currentLanguage}
                entities={entities}
                isFirefox={isFirefox}
            />
            {isFirefox ? (
                <div className='wrapper-firefox' ref={wrapperRef}>
                    {children}
                    <Chat isSpecialist={isSpecialist} entities={entities} isAuth={isAuth} />
                    <Footer
                        entities={entities}
                        profile={profile}
                        isCustomerOnUrl={isCustomerOnUrl}
                        isSpecialistOnUrl={isSpecialistOnUrl}
                    />
                    {(showUnverifiedNotification && !profile?.is_draft && token) && (
                        <UnverifiedNotification entities={entities} isFirefox={isFirefox} />
                    )}
                    {token && <PublishSpecialistProfile entities={entities} profile={profile} />}

                    {loading && <Loader type="fullScreen" />}
                    {token && <SuspendedNotification entities={entities} />}
                </div>
            ) : (
                <>
                    {children}
                    <Chat isSpecialist={isSpecialist} entities={entities}  isAuth={isAuth} />
                    <Footer
                        entities={entities}
                        profile={profile}
                        isCustomerOnUrl={isCustomerOnUrl}
                        isSpecialistOnUrl={isSpecialistOnUrl}
                    />
                    {(showUnverifiedNotification && !profile?.is_draft && token) && (
                        <UnverifiedNotification entities={entities} isFirefox={isFirefox} />
                    )}
                    {token && <PublishSpecialistProfile entities={entities} profile={profile} />}

                    {loading && <Loader type="fullScreen" />}
                    {token && <SuspendedNotification entities={entities} />}
                </>
            )}
        </>
    );
};

export default Layout;

import React from "react";
import _ from "lodash";
import { getDatesOrder } from "../../../../store/orders/selectors";
import { OrderItem } from "../../../../models/ordersModel";
import { TranslationObject } from "../../../../models/translationModal";

interface Props {
    order: OrderItem;
    entities?: TranslationObject;
    isDeal: boolean;
    isDone: boolean;
    isDraft: boolean;
    isArchive: boolean;
    isActive: boolean;
}

const DateType: React.FC<Props> = ({
    order,
    entities,
    isDeal,
    isDone,
    isDraft,
    isArchive,
    isActive,
}) => {
    const dates =
        getDatesOrder({
            order,
            entities,
            isDeal,
            isDone,
            isDraft,
            isArchive,
            isActive,
        }) || [];

    if (dates?.length < 1) {
        return null;
    }

    return (
        <>
            {dates.map(
                (date) =>
                    date?.visible && (
                        <li
                            className="order-information__item"
                            key={_.uniqueId("date_order")}
                        >
                            <p className="order-information__item-heading">
                                {date?.title}
                            </p>
                            <p className="order-information__item-text">
                                {date?.value}
                            </p>
                        </li>
                    )
            )}
        </>
    );
};

export default DateType;

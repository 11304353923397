import React from "react";
import Icon from "../../../../components/Icon";
import { Link } from "react-router-dom";
import { TranslationObject } from "../../../../models/translationModal";

interface AccountSettingsProps {
    entities?: TranslationObject;
    disabledLink?: boolean;
    linkHandler?: () => unknown;
}

export const AccountSettings: React.FC<AccountSettingsProps> = ({
    entities,
    disabledLink = false,
    linkHandler,
}) => {
    return (
        <>
            <div className="specialist-profile__block specialist-profile__block--flex">
                <div className="specialist-profile__col">
                    <h3 className="specialist-profile__subtitle title title--h3">
                        {entities?.specialist_details_account_settings_title?.value}
                    </h3>
                    <p className="text color-grey">
                        {
                            entities
                                ?.specialist_details_account_settings_placeholder
                                ?.value
                        }
                    </p>
                </div>
                <Link className={"btn btn--green specialist-profile__btn"}
                    to={disabledLink ? "" : "account"}
                >
                    {entities?.order_edit?.value}
                </Link>

                {/* <Link to={disabledLink ? "" : "account"}>
                    <div className="profile-block">
                        <div className="profile-block__row">
                            <div className="profile-block__col">
                                <p className="text profile-block__desc">
                                    {
                                        entities
                                            ?.specialist_details_account_settings_placeholder
                                            ?.value
                                    }
                                </p>
                            </div>
                            <div className="profile-block__controls profile-block__controls--centered">
                                <button
                                    className="profile-block__btn btn btn--no-bg"
                                    onClick={linkHandler}
                                    type="button"
                                    aria-label={
                                        entities?.order_edit?.value ?? "edit"
                                    }
                                >
                                    <Icon
                                        type="edit-current-color"
                                        width={20}
                                        height={20}
                                        colorVariant={"jaguar"}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </Link> */}
            </div>
        </>
    );
};

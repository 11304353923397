import React, { useEffect, useRef, useState } from "react";
import Modal from "../../Modal";
import { Cards, NewCard, StatusModal } from "./elements";
import { loadStripe, PaymentMethod, Stripe } from "@stripe/stripe-js";
import { useAttachNewBankCardMutation } from "../../../store/finances/finances.api";
import { TranslationObject } from "../../../models/translationModal";
import { useLoading } from "../../../hooks/useLoading/useLoading";
import { Elements } from "@stripe/react-stripe-js";
import { getDirectories } from "../../../store/directories/selectors";
import { useNotification } from "../../../hooks/useNotification/useNotification";

export type TypeModal =
    | "cards"
    | "newCard"
    | "isSuccess"
    | "isError"
    | "isSuccessPaymentMethod";

export interface ISelectCard {
    value: string;
    label: string;
}

interface BankCardsModalProps {
    visible: boolean;
    closeModal: () => unknown;
    confirmModal: (selectCard: ISelectCard) => unknown;
    typeModalProps?: TypeModal;
    newCardHandler?: (card: PaymentMethod) => unknown;
    backHandler?: () => unknown;
    price?: number | null;
    entities?: TranslationObject;
}

export const BankCardsModal: React.FC<BankCardsModalProps> = (props) => {
    const {
        visible,
        closeModal,
        confirmModal,
        typeModalProps = "cards",
        newCardHandler,
        backHandler,
        price,
        entities,
    } = props || {};

    const [typeModal, setTypeModal] = useState<TypeModal>(typeModalProps);

    const [
        attachNewBankCard,
        { isSuccess: isSuccessAttachNewBankCard, isLoading: isLoadingAttach },
    ] = useAttachNewBankCardMutation();

    const { setLoading } = useLoading();

    const { stripe } = useGetStripe();

    const { setNotification } = useNotification();

    useEffect(() => {
        setTypeModal(typeModalProps);
    }, [typeModalProps]);

    useEffect(() => {
        if (isSuccessAttachNewBankCard) {
            setTypeModal("cards");
        }
    }, [isSuccessAttachNewBankCard]);

    useEffect(() => {
        if (isLoadingAttach) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isLoadingAttach]);

    const modalTitle =
        typeModal === "isError" ||
        typeModal === "isSuccess" ||
        typeModal === "isSuccessPaymentMethod"
            ? ""
            : typeModal === "newCard"
            ? entities?.finances_new_card?.value
            : entities?.advertisement_payment?.value;

    const buttonBackHandler = () => {
        if (backHandler) {
            backHandler();
        } else {
            setTypeModal("cards");
        }
    };

    const buttonNewCardHandler = () => setTypeModal("newCard");

    const confirmModalHandler = (selectCard: ISelectCard | null) => {
        if (confirmModal && selectCard) {
            confirmModal(selectCard);
            return;
        }
    };

    const buttonAddNewCardHandler = (card: PaymentMethod) => {
        if (newCardHandler) {
            newCardHandler(card);
        } else {
            attachNewBankCard({
                card_id: card?.id,
            })
                .unwrap()
                .catch((error) => {
                    const errorDescription =
                        entities?.add_credit_card_error?.value ??
                        error?.data?.description ??
                        "";
                    setNotification({
                        type: "warn",
                        description: errorDescription,
                    });
                });
        }
    };

    return (
        <Modal
            visible={visible}
            onClose={closeModal}
            title={modalTitle}
            modificators={["modal-new-card"]}
        >
            <>
                {typeModal === "isSuccess" && (
                    <StatusModal
                        iconType={"happy"}
                        title={
                            entities?.advertisement_success_payment?.value || ""
                        }
                        description={
                            entities?.advertisement_thanks_for_using_service
                                ?.value || ""
                        }
                        buttonConfirmName={entities?.common_ok?.value || ""}
                        buttonConfirmHandler={closeModal}
                    />
                )}
                {typeModal === "isSuccessPaymentMethod" && (
                    <StatusModal
                        iconType={"happy"}
                        title={
                            entities?.finances_payment_method_selected?.value ??
                            ""
                        }
                        description={
                            entities?.charge_commission_after_customer_confirms_transaction?.value?.replace(
                                ":price",
                                price?.toString() ?? ""
                            ) ?? ""
                        }
                        buttonConfirmName={entities?.common_ok?.value || ""}
                        buttonConfirmHandler={closeModal}
                    />
                )}
                {typeModal === "isError" && (
                    <StatusModal
                        iconType={"sad"}
                        title={
                            entities?.advertisement_error_payment?.value || ""
                        }
                        description={
                            entities?.advertisement_try_again_later?.value || ""
                        }
                        buttonConfirmName={
                            entities?.advertisement_understand?.value || ""
                        }
                        buttonConfirmHandler={closeModal}
                    />
                )}
                {typeModal === "cards" && (
                    <Cards
                        buttonNewCardHandler={buttonNewCardHandler}
                        confirmModalHandler={confirmModalHandler}
                        price={price}
                        entities={entities}
                    />
                )}
                {typeModal === "newCard" && (
                    <Elements stripe={stripe}>
                        <NewCard
                            buttonBackHandler={buttonBackHandler}
                            buttonAddNewCardHandler={buttonAddNewCardHandler}
                            entities={entities}
                        />
                    </Elements>
                )}
            </>
        </Modal>
    );
};

const useGetStripe = () => {
    const { data } = getDirectories();

    const stripeApiKey = data?.stripe?.site_api_key;

    const stripe = useRef<Promise<Stripe | null> | null>(null);

    useEffect(() => {
        if (stripeApiKey) {
            stripe.current = loadStripe(stripeApiKey);
        }
    }, [stripeApiKey]);

    return {
        stripe: stripe.current,
        stripeApiKey,
    };
};

import React, { memo, useRef } from "react";
import { Link } from "react-router-dom";
import "moment/locale/ru";
import moment from "moment";
import cn from "classnames";
import { getCurrentLanguage } from "../../../../store/directories/selectors";
import { useActions } from "../../../../hooks/actions";
import { getToken } from "../../../../store/request";
import { useGetChatMessages, useGetSupportMessages } from "../../hooks";
import { useViewChatMessageMutation } from "../../../../store/chat/chat.api";
// import { useSendMessageToChatMutation } from "../../../../store/support/support.api";
import { TranslationObject } from "../../../../models/translationModal";

interface ChatWindowProps {
    userId?: number | string;
    chatId?: number | string;
    role?: string;
    entities?: TranslationObject;
    isSupport?: boolean;
}

export const ChatWindow: React.FC<ChatWindowProps> = memo(
    ({ userId, chatId, role, entities, isSupport }) => {
        const currentLanguage = getCurrentLanguage();
        const [viewChatMessage] = useViewChatMessageMutation();
        const chatRef = useRef<HTMLDivElement | null>(null);
        const { showModalAuth } = useActions();
        const token = getToken();

        const { supportMessages, supporthandleClickNextCursor } = useGetSupportMessages({
            chatId,
            userId,
            token,
            role,
            chatRef,
            entities,
        });

        const { chatMessages, handleClickNextCursor } = useGetChatMessages({
            chatId,
            userId,
            token,
            role,
            chatRef,
            entities,
        });

        const getMess = isSupport ? supportMessages : chatMessages;

        const handleClickViewMessage =
            (chatId?: string | number, messageId?: string | number) => () => {
                if (chatId && messageId) {
                    const requestData = {
                        messageId,
                        chatId,
                    };

                    viewChatMessage(requestData);
                }
            };

        const onScrollList = (event: React.UIEvent<HTMLDivElement>) => {
            if (event.currentTarget.scrollTop === 0) {
                if (isSupport) {
                    supporthandleClickNextCursor();
                } else {
                    handleClickNextCursor();
                }
            }
        };

        const onAuthModalOpen = (event) => {
            showModalAuth(true);
            event.preventDefault();
        }

        return (
            <>
                <div
                    className="chat__window"
                    ref={chatRef}
                    onScroll={(event) => onScrollList(event)}
                    style={{ transition: "ease 0.5s" }}
                >
                    {!getMess?.length && role === "specialist" && (
                        <div className="chat__info">
                            <p className="chat__text">
                                {moment().local().format("DD MMMM")}
                            </p>
                            <p className="chat__text highlight">
                                {entities?.you_dont_have_messages?.value}
                            </p>
                        </div>
                    )}
                    {getMess?.map(({ date, messages }) => (
                        <React.Fragment key={date}>
                            <div className="chat__info">
                                <p className="chat__text">{date}</p>
                            </div>
                            <div className="chat__inner">
                                {messages.map((message) => (
                                    <React.Fragment key={message.id}>
                                        {(message.is_system && (
                                            <div className="chat__info">
                                                <p className="chat__text">
                                                    {moment
                                                        .utc(
                                                            message?.created_at
                                                        )
                                                        .local()
                                                        .format(
                                                            "DD MMMM HH:mm"
                                                        )}
                                                </p>
                                                <div
                                                    className={"chat__text highlight"}
                                                    onClick={handleClickViewMessage(chatId, message?.id)}
                                                    dangerouslySetInnerHTML={{ __html: message?.text, }}
                                                />
                                            </div>
                                        )) || (
                                                <div
                                                    className={cn("chat__msg", {
                                                        ["chat__msg chat__msg--green chat__msg--left"]:
                                                            !message?.is_your,
                                                        ["chat__msg--right"]:
                                                            message?.is_your,
                                                    })}
                                                >
                                                    <p
                                                        className="text"
                                                        onClick={handleClickViewMessage(
                                                            chatId,
                                                            message?.id
                                                        )}
                                                    >
                                                        {message?.bot_system ? (
                                                            <span>
                                                                {entities?.chat_ai_limit_message?.value}
                                                                <a href="#auth" onClick={onAuthModalOpen}>
                                                                    {entities?.chat_ai_auth_title?.value}
                                                                </a>/<Link to={`/${currentLanguage}/customer/registration`}>
                                                                    {entities?.chat_ai_reg_title?.value}
                                                                </Link>
                                                            </span>
                                                        ) : (message?.text)}
                                                    </p>
                                                    <span className="color-grey">
                                                        {moment
                                                            .utc(
                                                                message?.created_at
                                                            )
                                                            .local()
                                                            .format("HH:mm")}
                                                    </span>
                                                </div>
                                            )}
                                    </React.Fragment>
                                ))}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </>
        );
    }
);

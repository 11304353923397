import React from "react";
import Specialists from "../../CreateOrder/Specialists";
import { OrderStepsProps } from "../../CreateOrder";
import { getCurrentLanguage } from "../../../store/directories/selectors";

interface Props extends OrderStepsProps {
    clearSpecialistIds: () => void;
    specialistIds: number[];
    changeSpecialistIds: (value: number) => void;
    isEdit: boolean;
    save: (value?: string, id?: number) => void;
    isPrivate: boolean;
}

const SpecialistsEditOrder: React.FC<Props> = ({
    entities,
    specialistIds,
    clearSpecialistIds,
    changeSpecialistIds,
    onSubmit,
    onSuccess,
    save,
    isPrivate,
}) => {
    const currentLanguage = getCurrentLanguage();
    return (
        <Specialists
            currentLanguage={currentLanguage}
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            isEdit
            isPrivate={isPrivate}
            updateOrder={save}
            clearSpecialistIds={clearSpecialistIds}
            specialistIds={specialistIds}
            entities={entities}
            changeSpecialistIds={changeSpecialistIds}
        />
    );
};

export default SpecialistsEditOrder;

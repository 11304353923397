import { request } from "../request";
import { BodyData, IdProps } from "../models";

export const getOrders = ({ params }: { params: string }) =>
    request({
        url: `orders/list?` + params,
    });

export const createReview = ({ body }: BodyData) =>
    request({
        url: `reviews`,
        method: "POST",
        body,
    });

export const updateReview = ({ body, id }: BodyData & IdProps) =>
    request({
        url: `reviews/${id}`,
        method: "PUT",
        body,
    });

export const changeStatusOrder = ({ body, id }: BodyData & IdProps) =>
    request({
        url: `orders/${id}/change_status`,
        method: "POST",
        body,
    });

export const responseToOrder = ({ id }: IdProps) =>
    request({
        url: `orders/${id}/store_response`,
        method: "POST",
    });

export const setUninterested = ({ body, id }: BodyData & IdProps) =>
    request({
        url: `orders/${id}/set_interesting`,
        method: "POST",
        body
    });

export const copyOrder = ({ id }: IdProps) =>
    request({
        url: `orders/${id}/copy`,
        method: "POST"
    });

import React, { useEffect, useRef, useState } from "react";
import Icon from "../Icon";
import classNames from "classnames";

type Props = {
  children: React.ReactNode;
  length: number;
  isCustomerPage?: boolean;
  isSpecialistPage?: boolean;
  isHorizontal?: boolean;
}

const Slider: React.FC<Props> = ({ children, length, isCustomerPage, isSpecialistPage, isHorizontal = false }) => {
  const TABLET_WIDTH = 768;

  const [current, setCurrent] = useState(0);
  const [showNav, setShowNav] = useState(false);
  const slider_container_ref = useRef<HTMLDivElement | null>(null);
  const slider_ref = useRef<HTMLDivElement | null>(null);

  const [lastPoint, setLastPoint] = useState(0); 
  const [startMoveX, setStartMoveX] = useState(null); 
  const [locked, setLocked] = useState(false); 
  const [shiftSlider, setShiftSlider] = useState(0);
  const [point, setPoint] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);

  const handleResize = () => {
    const containerWidth = slider_container_ref?.current?.clientWidth || 0;
    const sliderWidth = slider_ref?.current?.clientWidth || 0;

    findLastShiftPoint(containerWidth, sliderWidth);
    setScreenWidth(window.innerWidth);

    if (!getIsMobileDevice()) {
      setShowNav(!!containerWidth && !!sliderWidth && (window.innerWidth > TABLET_WIDTH) && (containerWidth < sliderWidth));
    } else {
      setShowNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  useEffect(() => {
    findLastShiftPoint()
  }, [screenWidth])

  useEffect(() => {
    handleResize();
  }, [length])

  const getWidthElemnt = () => {
    const slider = slider_ref?.current;
    const child = slider?.children[current];
    const childWidth = child?.clientWidth || 0;
    const childMarginLeft = child ? +window.getComputedStyle(child).marginLeft.replace('px', '') : 0;
    const childMarginRight = child ? +window.getComputedStyle(child).marginRight.replace('px', '') : 0;

    return  childWidth + childMarginLeft + childMarginRight;
  } 

  const nextSlide = () => {
    if (slider_ref?.current && current < length - 1) {
      slider_ref.current.style.transform = `translateX(-${getWidthElemnt() * (current + 1)}px)`;

      setCurrent(current + 1);
    }
  };

  const prevSlide = () => {
    if (slider_ref?.current && current <= length - 1 && current !== 0) {
      slider_ref.current.style.transform = `translateX(-${getWidthElemnt() * (current - 1)}px)`;
      setCurrent(current - 1);
    }
  };

  const getIsMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  const findLastShiftPoint = (container = 0, slider = 0) => {
    const containerWidth = container || (slider_container_ref?.current?.clientWidth || 0);
    const sliderWidth = slider || (slider_ref?.current?.clientWidth || 0);
    const lastShiftPoint = containerWidth - sliderWidth;

    if (shiftSlider !== 0) {
      setShiftSlider(Math.abs(shiftSlider) <= Math.abs(lastShiftPoint) ? shiftSlider : lastPoint);
    }

    setLastPoint(lastShiftPoint);
  };

  const unify = (event) => {
    return event.changedTouches ? event.changedTouches[0] : event
  };

  const lock = (event) => {
    if (lastPoint !== 0 && (screenWidth <= TABLET_WIDTH || getIsMobileDevice())) {
      setStartMoveX(unify(event)?.clientX);
      setLocked(true);
      setPoint(shiftSlider);
    }
  }

  const drag = (event) => {
    if(locked && startMoveX) {
      setShiftSlider(point + Math.round(unify(event).clientX - startMoveX))
    }
  }

  const move = () => {
    if(locked) {
      setLocked(false);
      setStartMoveX(null);

      if (slider_ref.current) {
        if (shiftSlider > 0) {
          setShiftSlider(0);
        } else if (Math.abs(shiftSlider) > Math.abs(lastPoint)) {
          setShiftSlider(lastPoint)
        }
      }
    }
  };

  const stopMoving = () => {
    if (locked) {
      setLocked(false);
      setShiftSlider(point);
    }
  }

  return (
    <div className="slider-block-container" ref={slider_container_ref}>
      <button className={classNames("right-arrow", {"hide": !showNav})} onClick={nextSlide}>
        <Icon
            type={"arrow_right"}
            width={20}
            height={60}
            colorVariant="grayLight"
        />
      </button>
      <button className={classNames("left-arrow", {"hide": !showNav})} onClick={prevSlide}>
        <Icon
            type={"arrow_right"}
            width={20}
            height={60}
            colorVariant="grayLight"
        />
      </button>

      <div className={classNames("slider-block slider-block--test", {
          "slider-block--customer": isCustomerPage,
          "slider-block--specialist": isSpecialistPage,
          "slider-block--row": isHorizontal,
          "slider-block--row-column": !isHorizontal && !isSpecialistPage
        })}
        style={{ transform: `translate3d(${shiftSlider}px, 0px, 0px)` } as React.CSSProperties}
        ref={slider_ref}
        onMouseDown={lock}
        onTouchStart={lock}
        onMouseMove={drag}
        onTouchMove={drag}
        onMouseUp={move}
        onTouchEnd={move}
        onMouseLeave={stopMoving}
      >
        { children }
      </div>
    </div>
  )
}

export default Slider;
import Input from "../Form/Input";
import TextArea from "../Form/TextArea";
import React from "react";
import { TranslationObject } from "../../models/translationModal";

interface Props {
    entities?: TranslationObject;
    handleInputChangePrice: (value: string) => void;
    price?: string | null;
    invalidFields: any;
    handleInputChangeDescription: (value: string) => void;
    description?: string;
    error: any;
}

const WishesOrder: React.FC<Props> = ({
    entities,
    handleInputChangePrice,
    price,
    invalidFields,
    handleInputChangeDescription,
    description,
    error,
}) => {
    return (
        <>
            <div className="form__row">
                <fieldset>
                    <legend>
                        {entities?.order_additional_data_price?.value}
                    </legend>
                    <Input
                        onChange={handleInputChangePrice}
                        value={price}
                        type="text"
                        maxLength={255}
                        placeholder=""
                        classes={["custom-input--small"]}
                        invalidFields={invalidFields}
                    />
                </fieldset>
            </div>
            <div className="form__row">
                <fieldset>
                    <legend>
                        {entities?.order_additional_data_wish?.value}
                    </legend>
                    <TextArea
                        type="text"
                        invalidFields={invalidFields}
                        error={error}
                        maxLength={500}
                        placeholder={entities?.order_additional_data_wish_placeholder?.value}
                        defaultValue={description}
                        onChange={handleInputChangeDescription}
                    />
                </fieldset>
            </div>
        </>
    );
};

export default WishesOrder;

import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";

import CookieBlock from "../Cookie";
import SelectLanguages from "../SelectLanguages";
import {
    checkIsTablet,
    isCustomerUrl,
    isSpecialistUrl,
    checkIsMobile,
    checkIsDesktop,
    getCustomPage,
    getArrayToObject
} from "../../store/directories/selectors";
import { getTkn, getUserRole } from "../../store/user/selectors";
import { TranslationObject } from "../../models/translationModal";
import logo from "../../img/icons/logo.svg";
import DropDownMenu from "../DropDownMenu";
import AuthSelectModal from "../Modals/AuthSelectModal";
import { ProfilePayload } from "../../models/userModels";
import Avatar from "../Avatar";
import { getItemStore } from "../../untils";
import { IS_INDIVIDUAL } from "../../store/order/constant";
import { useActions } from "../../hooks/actions";
import { clearOrderDataInLocalStorage } from "../../store/order/selectors";
import { endpoints } from "../../store/user/user.api";
import { HeaderBurgerContainer } from "./HeaderBurgerContainer";
import { useLogout } from "../../hooks/useLogout/useLogout";
import { util as utilUser } from "../../store/user/user.api";
import { isShowModalAuthSelector } from "../../store/registration/selectors"
import { isHeaderSearchSelector, getHeaderSearchPlaceholderSelector } from "../../store/header/header.selectors"
import { useDispatch, useSelector } from "react-redux";
import Search from "../Search";

const Header: React.FC<{
    entities: TranslationObject;
    currentLanguage: string;
    profile?: ProfilePayload;
    isFirefox?: boolean;
}> = ({ currentLanguage, entities, profile, isFirefox }) => {
    const HEADER_ID = 12
    const isShowModalAuth = useSelector(isShowModalAuthSelector);
    const isHeaderSearch = useSelector(isHeaderSearchSelector);
    const searchPlaceholder = useSelector(getHeaderSearchPlaceholderSelector);
    const userRole = getUserRole();
    const [isCustomer, setIsCustomer] = useState<boolean>(false);
    const [isSpecialist, setIsSpecialist] = useState<boolean>(false);
    // const [showModal, setShowModal] = useState<boolean>(false);
    const [openBurger, setOpenBurger] = useState<boolean>(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const isPreview = pathname.includes("preview") && profile?.registered_at;
    const showRegisterButton = isCustomerUrl() || isSpecialistUrl();
    const token = getTkn();
    const { clearCreateOrderFields, changeVersion, showModalAuth } = useActions();
    const { data: bonuses } = endpoints.getUserBonuses.useQuery(null, {
        skip: !profile || profile?.user_role === "specialist",
    });
    const registrationIsEnded = profile?.id && profile?.has_password && token;
    const hasNotPassword = profile?.id && !profile?.has_password;
    const isMobile = checkIsMobile();
    const isTablet = checkIsTablet();
    const isDesktop = checkIsDesktop();
    const logout = useLogout();
    const dispatch = useDispatch();

    const result = getCustomPage({ id: HEADER_ID });
    const headerData = result.data;

    const { balance } = bonuses || {};
    const { setHeaderStateSearch, setHeaderStateSearchPlaceholder } = useActions();

    useEffect(() => {
        window.addEventListener("resize", () => changeVersion(window?.innerWidth));
        clearOrderData();
        window.addEventListener("beforeunload", clearOrderData);
        return () => {
            window.removeEventListener("resize", () => changeVersion(window?.innerWidth));
            window.removeEventListener("beforeunload", clearOrderData)
        }
    }, []);

    const clearOrderData = () => {
        const isIndividual = getItemStore(IS_INDIVIDUAL);
        if (isIndividual) {
            clearOrderDataInLocalStorage(false, []);
            clearCreateOrderFields();
        }
    };

    useEffect(() => {
        if (headerData && headerData?.blocks) {
            const headerBlocks = getArrayToObject(headerData?.blocks || []);
            setHeaderStateSearch(headerBlocks?.header_search_button);
            setHeaderStateSearchPlaceholder(headerBlocks?.header_search_button?.value);
        }
    }, [headerData]);

    useEffect(() => {
        setOpenBurger(false);

        if (isCustomerUrl()) {
            setIsCustomer(true);
            setIsSpecialist(false);
            return;
        }
        if (isSpecialistUrl()) {
            setIsCustomer(false);
            setIsSpecialist(true);
            return;
        }
        setIsCustomer(false);
        setIsSpecialist(false);
    }, [pathname]);

    const handleClick = (type: string) => {
        if (hasNotPassword && token && type === "auth") {
            logout();
            dispatch(utilUser.invalidateTags(["Profile"]));
            return;
        }

        setOpenBurger(false);
        if (isCustomerUrl()) {
            return goTo("customer", type);
        }

        if (isSpecialistUrl()) {
            return goTo("specialist", type);
        }

        // setShowModal(true);
        showModalAuth(true);
    };

    const goTo = (str: string, type: string) =>
        navigate(`/${currentLanguage}/${str}/${type}`);

    useEffect(() => {
        if (isFirefox) {
            document.documentElement.classList.add('browser-firefox');
        }
    }, [isFirefox])

    return (
        <header className="header">
            <div className={cn("container header__container", {"header__container--preview": isPreview})}>
                <span className="header__logo">
                    <Link to={`/${currentLanguage}`}>
                        <img src={logo} alt="FindPRO" />
                    </Link>
                </span>
                {(isMobile || isTablet) && <SelectLanguages className="custom-select select-lang" />}
                <nav className="header__navigation">
                    <button
                        className="header__toggle toggle-menu"
                        onClick={() => setOpenBurger((prevState) => !prevState)}
                    >
                        <span className="toggle-menu__item"></span>
                    </button>
                    <HeaderBurgerContainer
                        isOpen={openBurger}
                        setOpen={setOpenBurger}
                    >
                        <div className="header__overlay">
                            <div className="header__menu-container">
                                <ul className="header__menu">
                                    <li className="header__menu-item">
                                        <Link
                                            className="header__menu-link"
                                            to={
                                                userRole !== "guest"
                                                    ? `${currentLanguage}/${userRole}/about`
                                                    : `${currentLanguage}/about`
                                            }
                                        >
                                            {entities?.about_link_value?.value}
                                        </Link>
                                    </li>
                                    <li className="header__menu-item">
                                        <Link
                                            className={cn("header__menu-link", {
                                                "header__menu-link-active--customer header__menu-link-active":
                                                    isCustomer || isPreview,
                                            })}
                                            to={`${currentLanguage}/customer`}
                                        >
                                            {
                                                entities?.customer_link_value
                                                    ?.value
                                            }
                                        </Link>
                                    </li>
                                    <li className="header__menu-item">
                                        <Link
                                            className={cn("header__menu-link", {
                                                "header__menu-link-active--specialist header__menu-link-active":
                                                    isSpecialist && !isPreview,
                                            })}
                                            to={`${currentLanguage}/specialist`}
                                        >
                                            {
                                                entities?.specialist_link_value
                                                    ?.value
                                            }
                                        </Link>
                                    </li>
                                </ul>

                                {isHeaderSearch && <Search entities={entities} profile={profile} placeholder={searchPlaceholder || ""} />}

                                {registrationIsEnded ? (
                                    <div className="header__user-panel user-panel user-nav">
                                        <div className="user-panel__avatar">
                                            <div className="avatar avatar--xs avatar--rounded">
                                                <Avatar
                                                    avatar={profile?.avatar}
                                                    width={48}
                                                    height={48}
                                                />
                                                <a
                                                    className={cn(
                                                        "user-panel__link",
                                                        {
                                                            "user-panel__link_green":
                                                                profile?.user_role
                                                                    ? profile.user_role ===
                                                                    "specialist"
                                                                    : isSpecialist,
                                                        }
                                                    )}
                                                    href="#"
                                                >
                                                    {profile?.unviewed_notifications_count ||
                                                        0}
                                                </a>
                                            </div>
                                        </div>
                                        <p className="user-panel__name text">
                                            {profile?.name}
                                        </p>
                                        {(profile?.user_role
                                            ? profile.user_role === "customer"
                                            : isCustomer) && (
                                                <p className="user-panel__bonus text color-grey">
                                                    {balance || 0}{" "}
                                                    {
                                                        entities
                                                            ?.profile_bonuses_points
                                                            ?.value
                                                    }
                                                </p>
                                            )}
                                        <DropDownMenu
                                            userRole={profile?.user_role}
                                            currentLanguage={currentLanguage}
                                            entities={entities}
                                            profile={profile}
                                        />
                                    </div>
                                ) : (
                                    <div className="header__buttons">
                                        <button
                                            className={
                                                showRegisterButton
                                                    ? "btn btn btn--transparent btn--nb header__button"
                                                    : "btn btn btn--transparent header__button header__button-reg"
                                            }
                                            type="button"
                                            aria-label={
                                                hasNotPassword && token
                                                    ? entities?.common_logout
                                                        ?.value
                                                    : entities?.login_link_value
                                                        ?.value
                                            }
                                            onClick={() => handleClick("auth")}
                                        >
                                            {hasNotPassword && token
                                                ? entities?.common_logout?.value
                                                : entities?.login_link_value
                                                    ?.value}
                                        </button>
                                        {showRegisterButton && (
                                            <button
                                                className="btn btn btn--transparent header__button header__button-reg"
                                                type="button"
                                                aria-label={
                                                    entities
                                                        ?.registration_link_value
                                                        ?.value
                                                }
                                                onClick={() =>
                                                    handleClick("registration")
                                                }
                                            >
                                                {
                                                    entities
                                                        ?.registration_link_value
                                                        ?.value
                                                }
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </HeaderBurgerContainer>
                </nav>
                {!isMobile && !isTablet && <SelectLanguages className="custom-select select-lang" />}
            </div>
            <CookieBlock entities={entities} isFirefox={isFirefox} />
            <AuthSelectModal
                entities={entities}
                showModal={!!isShowModalAuth}
                setShowModal={showModalAuth}
                currentLanguage={currentLanguage}
            />
        </header>
    );
};
export default Header;

import React from "react";
import Icon from "../../../components/Icon";
import Checkbox from "../../../components/Form/Checkbox";
import { getFilter } from "../../../store/orders/selectors";
import { useActions } from "../../../hooks/actions";
import {
    IS_MY_ADDRESS,
    IS_REMOTE,
    IS_SPECIALIST_ADDRESS,
} from "../../../store/order/constant";
import { TranslationObject } from "../../../models/translationModal";
import { ProfilePayload } from "../../../models/userModels";
import cn from "classnames";

interface Props {
    entities?: TranslationObject;
    profile?: ProfilePayload;
    activeMobileFilter: string;
    setActiveMobileFilter: () => void;
}

const PointFilter: React.FC<Props> = ({
    entities,
    profile,
    activeMobileFilter,
    setActiveMobileFilter,
}) => {
    const { setOrdersFilter } = useActions();
    const isRemote = getFilter(IS_REMOTE);
    const isMyAddress = getFilter(IS_MY_ADDRESS);
    const isSpecialistAddress = getFilter(IS_SPECIALIST_ADDRESS);
    const isSpecialist = profile?.user_role === "specialist";

    const handleChange = (name: string, value: boolean) => {
        setOrdersFilter({
            name,
            value,
        });
    };

    const handleChangeMyAddress = () =>
        handleChange(IS_MY_ADDRESS, !isMyAddress);

    const handleChangeSpecialistAddress = () =>
        handleChange(IS_SPECIALIST_ADDRESS, !isSpecialistAddress);

    return (
        <div
            className={cn("filter__block", {
                "is-open": activeMobileFilter === "meeting_place",
            })}
        >
            <button
                className="filter__subtitle"
                type="button"
                onClick={setActiveMobileFilter}
            >
                {entities?.common_meeting_place?.value}
                <Icon type="arrow-left-pagination" width={9} height={18} />
            </button>
            <div className="filter__wrap-block">
                <Checkbox
                    onChange={() => handleChange(IS_REMOTE, !isRemote)}
                    value={isRemote}
                >
                    {entities?.common_remotely?.value}
                </Checkbox>{" "}
                <Checkbox
                    value={isSpecialist ? isSpecialistAddress : isMyAddress}
                    onChange={() =>
                        isSpecialist
                            ? handleChangeSpecialistAddress()
                            : handleChangeMyAddress()
                    }
                >
                    {entities?.common_my_address?.value}
                </Checkbox>
                <Checkbox
                    value={isSpecialist ? isMyAddress : isSpecialistAddress}
                    onChange={() =>
                        isSpecialist
                            ? handleChangeMyAddress()
                            : handleChangeSpecialistAddress()
                    }
                >
                    {isSpecialist
                        ? entities?.common_customer_adress?.value
                        : entities?.common_specialist_adress?.value}
                </Checkbox>
            </div>
        </div>
    );
};

export default PointFilter;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { changeHeaders } from "../request";
import {
    getAdvertisementEmployees,
    getAdvertisementPaidList,
    getAdvertisementSpecialists,
    getAdvertisementSpecialistsMainPage,
    getBannerStartDate,
    getMailingStartDate,
    getThirdParties,
    getThirdPartiesMain,
    setThirdPartiesClick,
    storeAdvertisementBanner,
    storeAdvertisementMailing,
    storeAdvertisementTop,
} from "./api";
import {
    AdvertisementEmployeesPayload,
    AdvertisementSpecialistsPayload,
    AdvertisementSpecialistBannerPayload,
    AdvertisementStartDatePayload,
    GetAdvertisementPaidListPayload,
    ThirdPartiesPayload,
} from "../../models/advertisementModel";

export const advertisementApi = createApi({
    reducerPath: "advertisement/api",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
        prepareHeaders: (headers, { getState }) =>
            changeHeaders(headers, { getState }),
    }),
    tagTypes: ["Advertisement", "Localized"],
    endpoints: (build) => ({
        getAdvertisementPaidList: build.query<
            GetAdvertisementPaidListPayload,
            void
        >({
            query: () => getAdvertisementPaidList(),
            transformResponse: (response: {
                description: string;
                payload: GetAdvertisementPaidListPayload;
            }) => response.payload,
            providesTags: [{ type: "Advertisement" }],
        }),
        getBannerStartDate: build.query<
            AdvertisementStartDatePayload,
            { days: number }
        >({
            query: (body) => getBannerStartDate(body),
            transformResponse: (response: {
                description: string;
                payload: AdvertisementStartDatePayload;
            }) => response.payload,
            providesTags: [{ type: "Advertisement" }],
        }),
        getMailingStartDate: build.query<AdvertisementStartDatePayload, void>({
            query: () => getMailingStartDate(),
            transformResponse: (response: {
                description: string;
                payload: AdvertisementStartDatePayload;
            }) => response.payload,
            providesTags: [{ type: "Advertisement" }],
        }),
        getAdvertisementEmployees: build.query<
            AdvertisementEmployeesPayload,
            void
        >({
            query: () => getAdvertisementEmployees(),
            transformResponse: (response: {
                description: string;
                payload: AdvertisementEmployeesPayload;
            }) => response.payload,
            providesTags: [{ type: "Advertisement" }],
        }),
        storeAdvertisementBanner: build.mutation<unknown, FormData>({
            query: (body) => storeAdvertisementBanner(body),
            invalidatesTags: [{ type: "Advertisement" }],
        }),
        storeAdvertisementMailing: build.mutation<unknown, FormData>({
            query: (body) => storeAdvertisementMailing(body),
            invalidatesTags: [{ type: "Advertisement" }],
        }),
        storeAdvertisementTop: build.mutation<unknown, FormData>({
            query: (body) => storeAdvertisementTop(body),
            invalidatesTags: [{ type: "Advertisement" }],
        }),
        getAdvertisementSpecialists: build.query<
            AdvertisementSpecialistsPayload,
            void
        >({
            query: () => getAdvertisementSpecialists(),
            transformResponse: (response: {
                description: string;
                payload: AdvertisementSpecialistsPayload;
            }) => response.payload,
            providesTags: [{ type: "Advertisement" }, { type: "Localized" }],
        }),
        getAdvertisementSpecialistsMainPage: build.query<
            AdvertisementSpecialistBannerPayload,
            void
        >({
            query: () => getAdvertisementSpecialistsMainPage(),
            transformResponse: (response: {
                description: string;
                payload: AdvertisementSpecialistBannerPayload;
            }) => response.payload,
            providesTags: [{ type: "Advertisement" }, { type: "Localized" }],
        }),
        getThirdParties: build.query<ThirdPartiesPayload, void>({
            query: () => getThirdParties(),
            transformResponse: (response: {
                description: string;
                payload: ThirdPartiesPayload;
            }) => response.payload,
            providesTags: [{ type: "Advertisement" }],
        }),
        getThirdPartiesMain: build.query<ThirdPartiesPayload, void>({
            query: () => getThirdPartiesMain(),
            transformResponse: (response: {
                description: string;
                payload: ThirdPartiesPayload;
            }) => response.payload,
            providesTags: [{ type: "Advertisement" }],
        }),
        setThirdPartiesClick: build.mutation<unknown, number>({
            query: (id) => setThirdPartiesClick(id),
            invalidatesTags: [{ type: "Advertisement" }],
        }),
    }),
});

export const {
    util,
    endpoints: advertisementEndpoints,
    useStoreAdvertisementBannerMutation,
    useStoreAdvertisementMailingMutation,
    useStoreAdvertisementTopMutation,
    useSetThirdPartiesClickMutation,
} = advertisementApi;

import React, { Dispatch } from "react";
import _ from "lodash";
import { TranslationObject } from "../../../models/translationModal";
import { OrderItem } from "../../../models/ordersModel";

interface Props {
    entities?: TranslationObject;
    isDeal: boolean;
    isDropDown: boolean;
    order: OrderItem;
    userId?: number;
    isSpecialist: boolean;
    closeDropDown?: () => void;
    setShowModal: Dispatch<string>;
}

const CompleteOrderButton: React.FC<Props> = ({
    entities,
    isDeal,
    isDropDown = true,
    closeDropDown,
    setShowModal,
}) => {
    const text = entities?.order_status_competed?.value;

    if (!isDeal) {
        return null;
    }
    const completeOrder = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setShowModal('review');
        if (closeDropDown) {
            closeDropDown();
        }
    };

    return (
        <>
            {isDropDown ? (
                <div
                    className="dotted-menu__item"
                    onClick={completeOrder}
                    key={_.uniqueId("button_dropdown_order")}
                >
                    <div className="dotted-menu__item-link">{text}</div>
                </div>
            ) : (
                <button
                    onClick={completeOrder}
                    className="btn active-order__button"
                    type="button"
                >
                    {text}
                </button>
            )}
        </>
    );
};

export default CompleteOrderButton;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { changeHeaders } from "../request";
import {
    getNotificationsList,
    viewAllNotification,
    viewNotification,
} from "./api";
import {
    NotificationsListPayload,
    NotificationsListRootObject,
} from "../../models/notificationsModel";

export const notificationsApi = createApi({
    reducerPath: "notifications/api",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
        prepareHeaders: (headers, { getState }) =>
            changeHeaders(headers, { getState }),
    }),
    tagTypes: ["Notifications", "Localized"],
    endpoints: (build) => ({
        getNotificationsList: build.query<
            NotificationsListPayload,
            {
                params?: Record<string, number>;
            }
        >({
            query: ({ params }) => getNotificationsList({ params }),
            transformResponse: (response: NotificationsListRootObject) =>
                response.payload,
            providesTags: [{ type: "Notifications" }, { type: "Localized" }],
        }),
        viewNotification: build.mutation<
            NotificationsListPayload,
            string | number
        >({
            query: (notificationId) => viewNotification(notificationId),
            invalidatesTags: [{ type: "Notifications" }],
            transformResponse: (response: NotificationsListRootObject) =>
                response.payload,
        }),
        viewAllNotification: build.mutation<NotificationsListPayload, void>({
            query: () => viewAllNotification(),
            invalidatesTags: [{ type: "Notifications" }],
            transformResponse: (response: NotificationsListRootObject) =>
                response.payload,
        }),
    }),
});

export const {
    endpoints,
    util,
    useViewNotificationMutation,
    useViewAllNotificationMutation,
} = notificationsApi;

import React, { useState } from "react";

import PageLoaders from "../../components/Loaders/PageLoaders";
import Heading from "../../components/Heading";
import { BlocksBaseObject } from "../../models/homePageModel";
// import { Medium } from "../../models/homePageCustomer";
import { ComponentProps } from "../../models/models";
import FeedBackForm from "../../components/FeedBackForm";
import Icon from "../../components/Icon";

import pictureBonusMobilePng from "../../img/content/poster/image6-mobile.png";
import pictureBonusMobilePng2x from "../../img/content/poster/image6-mobile-2x.png";
import pictureBonusMobileWebp from "../../img/content/poster/image6-mobile.webp";
import pictureBonusMobileWebp2x from "../../img/content/poster/image6-mobile-2x.webp";
import pictureBonusTabletPng from "../../img/content/poster/image6-tablet.png";
import pictureBonusTabletPng2x from "../../img/content/poster/image6-tablet-2x.png";
import pictureBonusTabletWebp from "../../img/content/poster/image6-tablet.webp";
import pictureBonusTabletWebp2x from "../../img/content/poster/image6-tablet-2x.webp";
import pictureBonusDesktopPng from "../../img/content/poster/image6-desktop.png";
import pictureBonusDesktopPng2x from "../../img/content/poster/image6-desktop-2x.png";
import pictureBonusDesktopWebp from "../../img/content/poster/image6-desktop.webp";
import pictureBonusDesktopWebp2x from "../../img/content/poster/image6-desktop-2x.webp";

import pictureBonusMobileBg from "../../img/content/poster/bg6-mobile.svg";
import pictureBonusTabletBg from "../../img/content/poster/bg6-tablet.svg";
import pictureBonusDesktopBg from "../../img/content/poster/bg6-desktop.svg";

// import picturePartnerMobilePng from "../../img/content/poster/image7-mobile.png";
// import picturePartnerMobilePng2x from "../../img/content/poster/image7-mobile-2x.png";
// import picturePartnerMobileWebp from "../../img/content/poster/image7-mobile.webp";
// import picturePartnerMobileWebp2x from "../../img/content/poster/image7-mobile-2x.webp";
// import picturePartnerTabletPng from "../../img/content/poster/image7-tablet.png";
// import picturePartnerTabletPng2x from "../../img/content/poster/image7-tablet-2x.png";
// import picturePartnerTabletWebp from "../../img/content/poster/image7-tablet.webp";
// import picturePartnerTabletWebp2x from "../../img/content/poster/image7-tablet-2x.webp";
// import picturePartnerDesktopPng from "../../img/content/poster/image7-desktop.png";
// import picturePartnerDesktopPng2x from "../../img/content/poster/image7-desktop-2x.png";
// import picturePartnerDesktopWebp from "../../img/content/poster/image7-desktop.webp";
// import picturePartnerDesktopWebp2x from "../../img/content/poster/image7-desktop-2x.webp";

const Bonus: React.FC<ComponentProps> = ({ currentLanguage, entities, profile,
                                             isSpecialistOnUrl }) => {
    const [blocks, setBlocks] = useState<BlocksBaseObject | null>(null);
    // const [additionalBlockImage, setAdditionalBlockImage] = useState();
    const isSpecialist = profile?.user_role === "specialist" || isSpecialistOnUrl;
    const bonusPicture = {
        picture: {
            srcsets: [
                {
                    media: '(min-width: 1024px)',
                    type: 'image/webp',
                    srcSet: pictureBonusDesktopWebp + ', ' + pictureBonusDesktopWebp2x + ' 2x'
                },
                {
                    media: '(min-width: 1024px)',
                    type: 'image/png',
                    srcSet: pictureBonusDesktopPng + ', ' + pictureBonusDesktopPng2x + ' 2x'
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/webp',
                    srcSet: pictureBonusTabletWebp + ', ' + pictureBonusTabletWebp2x + ' 2x'
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/png',
                    srcSet: pictureBonusTabletPng + ', ' + pictureBonusTabletPng2x + ' 2x'
                },
                {
                    type: 'image/webp',
                    srcSet: pictureBonusMobileWebp + ', ' + pictureBonusMobileWebp2x + ' 2x'
                }
            ],
            src: pictureBonusMobilePng,
            srcSet: pictureBonusMobilePng2x + ' 2x'
        }
    }

    const bonusBg = {
        bg: {
            srcsets: [
                {
                    media: '(min-width: 1024px)',
                    type: 'image/svg+xml',
                    srcSet: pictureBonusDesktopBg
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/svg+xml',
                    srcSet: pictureBonusTabletBg
                },
            ],
            src: pictureBonusMobileBg
        }
    }

    const bonusButtons = (
        <>
            <a
                className="btn btn--bg-green"
                href={blocks?.main_block_link?.value || "/"}
                target="_blank"
            >
                <Icon
                    type="gift"
                    width={24}
                    height={24}
                />
                {blocks?.banner_button_title?.value}
            </a>
        </>
    );
    const isBonuseButton = (blocks?.main_block_link?.value && blocks?.banner_button_title?.value)

    // const picture = {
    //     srcsets: [
    //         {
    //             media: '(min-width: 1024px)',
    //             type: 'image/webp',
    //             srcSet: picturePartnerDesktopWebp + ', ' + picturePartnerDesktopWebp2x + ' 2x'
    //         },
    //         {
    //             media: '(min-width: 1024px)',
    //             type: 'image/png',
    //             srcSet: picturePartnerDesktopPng + ', ' + picturePartnerDesktopPng2x + ' 2x'
    //         },
    //         {
    //             media: '(min-width: 768px)',
    //             type: 'image/webp',
    //             srcSet: picturePartnerTabletWebp + ', ' + picturePartnerTabletWebp2x + ' 2x'
    //         },
    //         {
    //             media: '(min-width: 768px)',
    //             type: 'image/png',
    //             srcSet: picturePartnerTabletPng + ', ' + picturePartnerTabletPng2x + ' 2x'
    //         },
    //         {
    //             type: 'image/webp',
    //             srcSet: picturePartnerMobileWebp + ', ' + picturePartnerMobileWebp2x + ' 2x'
    //         }
    //     ],
    //     src: picturePartnerMobilePng,
    //     srcSet: picturePartnerMobilePng2x + ' 2x'
    // };

    return (
        <PageLoaders
            isCustomPage
            updateData={setBlocks}
            type="custom_pages"
            code="bonuses_and_partners"
            currentLanguage={currentLanguage}
        >
            <main>
                <div className="page page--bonus">
                    <div className="container">
                        <Heading
                            isSpecialist={isSpecialist}
                            img={blocks?.main_block_image?.value}
                            title={blocks?.main_block_title?.value}
                            text={
                                blocks?.main_block_description?.value ||
                                blocks?.main_block_description_1?.value
                            }
                            text2={blocks?.main_block_description_2?.value}
                            picture={bonusPicture.picture}
                            bg={bonusBg.bg}
                            buttons={isBonuseButton && bonusButtons}
                            className="promo promo-bonus page__promo"
                        />
                        <section className="promo promo-partner page__promo page__promo--bonus">
                            <div className="promo__content-wrapper">
                                {/* <div className="partner-block__img-container">
                                    <picture>
                                        {blocks?.additional_block_image?.media?.map(
                                            (img: Medium) => (
                                                <img
                                                    key={img?.id + 2}
                                                    src={img?.original_url}
                                                />
                                            )
                                        )}
                                    </picture>
                                </div> */}
                                <div className="promo__content-text">
                                    <h2 className="title title--h1">
                                        {blocks?.additional_block_title?.value}
                                    </h2>
                                    {blocks?.additional_block_description
                                        ?.type === "text" ? (
                                        <p
                                            className="promo__text"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    blocks
                                                        ?.additional_block_description
                                                        ?.value || "",
                                            }}
                                        />
                                    ) : (
                                        <p className="promo__text">
                                            {
                                                blocks
                                                    ?.additional_block_description
                                                    ?.value
                                            }
                                        </p>
                                    )}
                                </div>
                                {blocks?.additional_block_image?.value && (
                                    <div className="promo__background-image-wrapper">
                                        <picture className="promo__picture">
                                            <img
                                                src={blocks?.additional_block_image?.value}
                                                width="600"
                                                height="600"
                                                alt="Promo picture"
                                            />
                                        </picture>
                                    </div>
                                )}
                                {/* {picture && (
                                    <div className="promo__background-image-wrapper">
                                        <picture className="promo__picture">
                                            {picture.srcsets?.map((item, key) => (
                                                <source
                                                    key={key}
                                                    media={item.media}
                                                    type={item.type}
                                                    srcSet={item.srcSet}
                                                />
                                            ))}
                                            <img
                                                src={picture.src}
                                                srcSet={picture.srcSet}
                                                width="600"
                                                height="600"
                                                alt=""
                                            />
                                        </picture>
                                    </div>
                                )} */}
                            </div>
                        </section>
                        <div
                            className="feedback-form is-initialized"
                            data-validate=""
                        >
                            <div className="feedback-form__title-wrapper">
                                <h2 className="title title--h2">
                                    {blocks?.feedback_title?.value}
                                </h2>
                                {blocks?.feedback_text?.type === "text" ? (
                                    <p
                                        className="feedback-form__text"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                blocks?.feedback_text?.value ||
                                                "",
                                        }}
                                    />
                                ) : (
                                    <p className="feedback-form__text">
                                        {blocks?.feedback_text?.value}
                                    </p>
                                )}
                            </div>
                            {entities && currentLanguage && (
                                <FeedBackForm
                                    entities={entities}
                                    profile={profile}
                                    currentLanguage={currentLanguage}
                                    type="bonuses_and_partners"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </PageLoaders>
    );
};
export default Bonus;

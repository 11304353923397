import React from "react";

interface CountsProps {
    satisfied_customers_count: number | null;
    satisfied_customers_title: string | null;
    specialists_count: number | null;
    specialists_title: string | null;
    deals_count: number | null;
    deals_title: string | null;
}

const CountsBlock: React.FC<CountsProps> = ({
    satisfied_customers_count,
    satisfied_customers_title,
    specialists_count,
    specialists_title,
    deals_count,
    deals_title,
}) => {
    if (
        (satisfied_customers_count && Number(satisfied_customers_count) < 1) ||
        !satisfied_customers_count ||
        (specialists_count && Number(specialists_count) < 1) ||
        !specialists_count ||
        (deals_count && Number(deals_count) < 1) ||
        !deals_count
    ) {
        return null;
    }

    return (
        <ul className="order-stats__list">
            <li className="order-stats__item">
                <p>{satisfied_customers_count}</p>
                <span>{satisfied_customers_title}</span>
            </li>
            <li className="order-stats__item order-stats__item--green">
                <p>{specialists_count}</p>
                <span>{specialists_title}</span>
            </li>
            <li className="order-stats__item order-stats__item--grey">
                <p>{deals_count}</p>
                <span>{deals_title}</span>
            </li>
        </ul>
    );
};

export default CountsBlock;

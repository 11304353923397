import React, { useEffect, useState } from "react";
import Modal from "../../../../components/Modal";
import ChooseAddress from "../../../../components/ChooseAddress";
import { getLocationObj, setItemStore } from "../../../../untils";
import { locationProps } from "../../../../models/orderModel";
import { TranslationObject } from "../../../../models/translationModal";
import cn from "classnames";
import { SPECIALIST_ADDRESS, MY_ADDRESS } from "../../../../store/order/constant";
import {
    getItemCreateOrder,
    getItemEditOrder,
} from "../../../../store/order/selectors";
import { endpoints } from "../../../../store/user/user.api";

const SpecialistAddressModal: React.FC<{
    entities?: TranslationObject;
    showSpecialistAddressModal: boolean;
    close: () => void;
    save: (location: locationProps) => void;
    isSpecialist?: boolean;
    isEdit?: boolean;
    errorRequired?: boolean;
    isOpenOrder?: boolean;
}> = ({
    entities,
    showSpecialistAddressModal,
    close,
    save,
    isSpecialist = false,
    isEdit,
    errorRequired = false,
    isOpenOrder
}) => {
    const [location, setLocation] = useState<locationProps | null>(null);
    const specialistCoordinates = isEdit
        ? getItemEditOrder(SPECIALIST_ADDRESS)
        : getItemCreateOrder(SPECIALIST_ADDRESS);
    const { data: profile } = endpoints.getProfile.useQuery();
    const myCoordinates = isEdit
        ? getItemEditOrder(MY_ADDRESS)
        : getItemCreateOrder(MY_ADDRESS);

    useEffect(() => {
        if (specialistCoordinates) {
            return setLocation(getLocationObj(specialistCoordinates));
        }
    }, [specialistCoordinates]);

    useEffect(() => {
        if (!showSpecialistAddressModal && !isOpenOrder) {
            setLocation(null);
        }
    }, [showSpecialistAddressModal]);

    useEffect(() => {
        const { address, latitude, longitude } = profile || {};
        if (isOpenOrder && (myCoordinates || (address && latitude && longitude))) {
            return setLocation({
                latitude: myCoordinates?.latitude || latitude,
                longitude: myCoordinates?.longitude || longitude,
                address: myCoordinates?.address || address,
            });
        }
    }, [isEdit, profile]);

    const changeLocation = (obj: locationProps) => {
        if (obj?.latitude && obj?.longitude) {
            setLocation({
                latitude: obj?.latitude,
                longitude: obj?.longitude,
                radius: obj?.radius,
                address: obj?.address,
            });
            if (!isEdit) {
                setItemStore("specialist_address", {
                    latitude: obj?.latitude,
                    longitude: obj?.longitude,
                    radius: obj?.radius,
                    address: obj?.address,
                });
            }
        }
    };

    const disabledButtonSave =
        !location?.latitude || !location?.longitude || !location?.address;

    return (
        <Modal
            visible={showSpecialistAddressModal}
            key="SpecialistAddressModal"
            modificators={[
                !isSpecialist ? "customer" : "",
                "modal-specialist address-modal",
            ]}
            onClose={() => close()}
            title={
                isSpecialist
                    ? entities?.common_customer_adress?.value
                    : entities?.order_select_meeting_address_title?.value
            }
        >
            <form className="form">
                <ChooseAddress
                    inputClasses={["custom-input--suffix form__field"]}
                    changeLocation={changeLocation}
                    withRadius={true}
                    entities={entities}
                    location={location}
                    isSpecialist={isSpecialist}
                    isRequired={true}
                    errorRequired={errorRequired}
                />
                <div className="form__row form__controls form__row--end">
                    <button
                        className="btn form__control form__back-link btn--transparent"
                        type="button"
                        onClick={close}
                    >
                        {entities?.common_cancel_button?.value}
                    </button>
                    <button
                        className={cn("btn form__control", {
                            "btn--bg-green": isSpecialist,
                        })}
                        type="button"
                        onClick={() => !disabledButtonSave && save(location)}
                    >
                        {entities?.common_save_button?.value}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default SpecialistAddressModal;

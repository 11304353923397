import React, { useEffect, useRef, useState } from "react";
import SubMenu from "../../components/SubMenu";
import { getTkn } from "../../store/user/selectors";
import Form from "../../components/Form/Form";
import LanguagesChecklist from "../../components/LanguagesChecklist";
import EditPassword from "../../components/EditPassword";
import { ComponentProps } from "../../models/models";
import RegisterForm from "../../components/Form/RegisterForm";
import classNames from "classnames";
import AvatarInput, { clearAvatarInput } from "../../components/AvatarInput";
import {
    endpoints,
    useDeleteAvatarMutation,
    useUpdateMutation,
    useUpdateProfileMutation,
    util,
} from "../../store/user/user.api";
import { useActions } from "../../hooks/actions";
import Confirmation from "../../components/Confirmation";
import { ProfilePayload } from "../../models/userModels";
import ProfileName from "../../components/Form/ProfileName";
import ProfileContacts from "../../components/Form/ProfileContacts";
import ProfileButtons from "../../components/Form/ProfileButtons";
import ProfileAddress from "../../components/Form/ProfileAddress";
import ProfileButtonsForm from "../../components/Form/ProfileButtonsForm";
import ProfilePhone from "../../components/Form/ProfilePhone";
import { ProfileHeader } from "../ProfileSpecialist/elements/ProfileHeader";
import { Link } from "react-router-dom";
import { endpoints as endpointsDirectories } from "../../store/directories/directories.api";
import { useDispatch } from "react-redux";
import WarnModal from "../../components/Modals/WarnModal";
import { useNavigateProfileSpecialist } from "../ProfileSpecialist/hooks";
import { useHistoryBackHandler } from "../../hooks/useHistoryBackHandler";

interface Pages {
    password: Element;
}

interface Props extends ComponentProps {
    profile?: ProfilePayload;
    historyBackHandler?: () => unknown;
}

const Profile: React.FC<Props> = ({
    entities,
    currentLanguage,
    isSpecialistOnUrl,
    isCustomerOnUrl,
    profile,
    historyBackHandler,
}) => {
    const {
        family_name: userFamilyName,
        avatar: userAvatar,
        user_role: userRole,
        latitude,
        longitude,
    } = profile || {};
    const isSpecialist = userRole === "specialist";
    const token = getTkn();
    const dispatch = useDispatch();
    const { updateUser, updateUserField } = useActions();
    const [name, setName] = useState<string>(profile?.name || "");
    const [familyName, setFamilyName] = useState<string>("");
    const [languageIds, setLanguageIds] = useState<number[]>([]);
    const [phone, setPhone] = useState<string>("");
    const [phoneForCustomer, setPhoneForCustomer] = useState<string>("");
    const [showEmail, setShowEmail] = useState<boolean>(false);
    const [address, setAddress] = useState<string>("");
    const [type, setType] = useState<string | null>(null);
    const [email, setEmail] = useState("");
    const [isChangeEmail, setIsChangeEmail] = useState(false);
    const [photo, setPhoto] = useState<string | null>(null);
    const [photoIsChanged, setPhotoIsChanged] = useState<boolean>(false);
    const [update, { data, isSuccess, error: errorProfile }] =
        useUpdateProfileMutation();
    const [typeConfirm, setTypeConfirm] = useState<string | null>(null);
    const [showModal, setShowModal] = useState<string | null>(null);
    const [avaterIsDeleted, setAvaterIsDeleted] = useState<boolean>(false);
    const [deleteAvatar] = useDeleteAvatarMutation();

    const titleModal =
        showModal === "successSaving"
            ? entities?.common_saved?.value
            : entities?.common_cancel?.value;
    const descriptionModal =
        showModal === "successSaving"
            ? entities?.common_information_has_been_saved?.value
            : entities?.common_action_cancelled?.value;

    const isNotDisabledCansel =
        !photoIsChanged &&
        profile?.name === name &&
        profile?.family_name === familyName &&
        profile?.language_ids?.some(
            (elem: number) => languageIds?.indexOf(elem) !== -1
        ) &&
        profile?.language_ids?.length && languageIds?.length === profile?.language_ids?.length &&
        address === profile?.address &&
        email === profile?.email &&
        phoneForCustomer === profile?.phone_customer;

    const phoneIsConfirmed = phone === profile?.phone;

    const [
        updateConfirm,
        { data: response, isSuccess: isSuccessConfirm, error: errorConfirm },
    ] = useUpdateMutation();

    const { data: bonusesData } = endpoints.getUserBonuses.useQuery(null, {
        skip: isSpecialist,
    });
    const [error, setError] = useState<unknown>(null);

    const updateAddressHandler = useRef(() => null);

    const { data: directories } =
        endpointsDirectories.getDirectories.useQuery();

    const linkBonusProgram = directories?.custom_pages?.find(
        (elem) => elem.code === "bonuses_and_partners"
    )?.path;

    const bonusTextLink = entities?.order_create_review_bonus_program?.value;

    useHistoryBackHandler(historyBackHandler);

    useEffect(() => {
        if (errorProfile || (typeConfirm && errorConfirm)) {
            setError(errorProfile || (typeConfirm && errorConfirm));
        }
    }, [errorProfile, errorConfirm, typeConfirm]);

    useEffect(() => {
        if (!type) {
            setError(null);
        }
    }, [type]);

    const emailIsConfirmed = !isChangeEmail;

    useEffect(() => {
        if (isSuccessConfirm) {
            setError(null);
            setType(typeConfirm?.includes("email") ? "email" : "phone");
        }
    }, [isSuccessConfirm]);

    useEffect(() => {
        if (profile?.id && type === null) {
            updateFields();
        }
    }, [profile]);

    const updateFields = () => {
        setAvaterIsDeleted(false);
        clearAvatarInput();
        setPhotoIsChanged(false);
        setName(profile?.name);
        setFamilyName(profile?.family_name);
        setEmail(profile?.email || "");
        setLanguageIds(profile?.language_ids ?? []);
        setPhone(profile?.phone || "");
        setPhoneForCustomer(profile?.phone_customer || profile?.phone || "");
        setPhoto(userAvatar?.original_url ?? null);
        setAddress(profile?.address ?? null);
        if (profile?.email?.length > 1) {
            setShowEmail(true);
        }
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        const body = new FormData(e.target);
        update({ body });
        updateAddressHandler.current();
    };

    const onSuccess = () => {
        if (avaterIsDeleted) {
            deleteAvatar();
        }
        if (data?.id) {
            updateUser(data);
        }
        setShowModal("successSaving");
    };

    const handleChangeCheckbox = (id: number) => {
        languageIds?.includes(id)
            ? setLanguageIds(languageIds?.filter((i) => i !== id))
            : setLanguageIds(languageIds?.concat(id));
    };

    const confirm = (e: any, type: string) => {
        e.preventDefault();
        if (type) {
            setTypeConfirm(type);
            _updateConfirm(type);
        }
    };

    const _updateConfirm = (type: string) => {
        const key = type === "change_email" ? "email" : "phone";
        updateConfirm({
            str: type,
            body: {
                [key]: type === "change_email" ? email : phone,
            },
        });
    };

    const _returnConfirm = () => {
        setType(null);
        setTypeConfirm(null);
        setIsChangeEmail(false);
        setEmail(profile?.email);
    };

    const onSuccessConfirm = (type: string) => {
        setType(null);
        setIsChangeEmail(false);
        updateUserField({
            name: type,
            value: type === "email" ? email : phone,
        });
        dispatch(util.invalidateTags(["Profile"]));
    };

    const renderConfirm = (str: string) => {
        const isEmail = str === "email";
        return (
            <RegisterForm
                isCustomerOnUrl={isCustomerOnUrl ?? false}
                isSpecialistOnUrl={isSpecialistOnUrl ?? false}
            >
                <Confirmation
                    entities={entities}
                    resendError={error}
                    email={isEmail ? email : null}
                    phone={!isEmail ? phone : null}
                    changeStep={() =>
                        onSuccessConfirm(isEmail ? "email" : "phone")
                    }
                    currentLanguage={currentLanguage}
                    handleReturn={_returnConfirm}
                    data={response}
                    resendCode={() =>
                        _updateConfirm(
                            isEmail ? "change_email" : "send_phone_confirmation"
                        )
                    }
                />{" "}
            </RegisterForm>
        );
    };

    useEffect(() => {
        if (isSuccess) {
            onSuccess();
        }
    }, [isSuccess]);

    const changePage = {
        password: (
            <RegisterForm
                isCustomerOnUrl={isCustomerOnUrl ?? false}
                isSpecialistOnUrl={isSpecialistOnUrl ?? false}
            >
                <EditPassword
                    changeStep={() => {
                        setError(null);
                        setType(null);
                    }}
                    token={token}
                    isChange
                    entities={entities}
                    isCustomerOnUrl={isCustomerOnUrl}
                    isSpecialistOnUrl={isSpecialistOnUrl}
                    currentLanguage={currentLanguage}
                />
            </RegisterForm>
        ),
        email: renderConfirm("email"),
        phone: renderConfirm("phone"),
    };

    const profileNameBlockLegend = entities?.profile_personal_data?.value ?? "";
    const profilePhoneBlockLegend = entities?.profile_personal_phone?.value ?? "";

    const profileNameBlockNameInput = {
        nameInputValue: name,
        inputNameError: error,
        nameInputPlaceholder: entities?.common_name?.value ?? "",
        nameInputChange: (inputValue: string) => setName(inputValue),
    };

    const profileNameBlockFamilyNameInput = {
        familyNameInputValue: familyName,
        familyNameInputPlaceholder: entities?.common_family_name?.value ?? "",
        familyNameInputChange: (inputValue: string) =>
            setFamilyName(inputValue),
    };

    const profileContactsBlockLegend =
        entities?.profile_registration_data?.value ?? "";

    const profileContactsBlockPhoneInput = {
        phone: phone ?? "",
        phoneIsConfirmed,
        buttonPhoneConfirmedName: entities?.common_confirm_link?.value ?? "",
        changePhone: (inputValue: string) => setPhone(inputValue),
        clickPhoneConfirmed: async (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            await confirm(event, "send_phone_confirmation");
        },
    };

    const profilePhoneBlockPhoneInput = {
        phone: phoneForCustomer ?? "",
        changePhone: (inputValue: string) => setPhoneForCustomer(inputValue),
    };

    const profileContactsBlockEmailInput = {
        email,
        showEmail,
        emailIsConfirmed,
        buttonEmailConfirmedName:
            entities?.common_confirm_link?.value ?? "" + " ",
        changeEmail: (value: string) => { 
            setEmail(value);
            setIsChangeEmail(profile?.email !== value);
        },
        clickEmailConfirmed: async (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            await confirm(event, "change_email");
        },
    };

    const cancelChanges = () => {
        updateFields();
        setShowModal("successCansel");
    };

    const handleChangePhoto = (photo: string | null) => {
        setPhotoIsChanged(true);
        setPhoto(photo);
    };
    const navigateTo = useNavigateProfileSpecialist(currentLanguage);

    return (
        <>
            <main>
                <WarnModal
                    title={titleModal || ""}
                    onClose={() => {
                        setShowModal(null);
                        isSpecialistOnUrl && navigateTo("");
                    }}
                    visible={!!showModal}
                    description={descriptionModal || ""}
                    isSpecialist={isSpecialistOnUrl}
                />
                <SubMenu entities={entities} profile={profile} />
                {type ? (
                    changePage[type as keyof Pages]
                ) : (
                    <section
                        className={classNames({
                            profile: !isSpecialist,
                            "specialist-profile": isSpecialist,
                        })}
                    >
                        <h1
                            className={classNames({
                                ["visually-hidden"]: !isSpecialist,
                                ["specialist-profile__title title title--f46"]:
                                    isSpecialist,
                            })}
                        >
                            {
                                entities
                                    ?.specialist_details_account_settings_title
                                    ?.value
                            }
                        </h1>
                        <div className="container">
                            <Form
                                entities={entities}
                                onSubmit={onSubmit}
                                error={error}
                                className="form form--big-fieldset"
                            >
                                {(invalidFields, error) => (
                                    <>
                                        {(isSpecialist && profile && (
                                            <ProfileHeader
                                                name={profile?.name}
                                                familyName={userFamilyName}
                                                visibleChangeAvatar={true}
                                                entities={entities}
                                                photo={photo}
                                                setPhoto={handleChangePhoto}
                                                setAvaterIsDeleted={
                                                    setAvaterIsDeleted
                                                }
                                                avaterIsDeleted={
                                                    avaterIsDeleted
                                                }
                                            />
                                        )) || (
                                                <div className="profile__heading">
                                                    <AvatarInput
                                                        setAvaterIsDeleted={
                                                            setAvaterIsDeleted
                                                        }
                                                        avaterIsDeleted={
                                                            avaterIsDeleted
                                                        }
                                                        setPhoto={handleChangePhoto}
                                                        photo={photo}
                                                        entities={entities}
                                                    />
                                                    <h2 className="title title--h2">
                                                        {profile?.name}
                                                    </h2>
                                                    <div className="bonus-balance">
                                                        <p className="bonus-balance__heading">
                                                            {
                                                                entities
                                                                    ?.profile_bonuses
                                                                    ?.value
                                                            }{" "}
                                                            {bonusesData?.balance ||
                                                                0}{" "}
                                                            {
                                                                entities
                                                                    ?.profile_bonuses_points
                                                                    ?.value
                                                            }
                                                        </p>
                                                        {!!bonusesData?.write_off_amount &&
                                                            bonusesData?.latest_write_off && (
                                                                <>
                                                                    <p className="bonus-balance__data">
                                                                        {
                                                                            bonusesData?.latest_write_off
                                                                        }
                                                                    </p>
                                                                    <p className="bonus-balance__text">
                                                                        {entities?.profile_bonuses_was_transferred?.value?.replace(
                                                                            ":bonus",
                                                                            bonusesData.write_off_amount.toString()
                                                                        )}
                                                                    </p>
                                                                </>
                                                            )}
                                                        <Link
                                                            className="modal-review-pro__link"
                                                            to={`/${currentLanguage}/${linkBonusProgram}`}
                                                        >
                                                            {bonusTextLink}
                                                        </Link>
                                                    </div>
                                                </div>
                                            )}
                                        <div className="profile__wrapper">
                                            <ProfileName
                                                error={error}
                                                invalidFields={invalidFields}
                                                legend={profileNameBlockLegend}
                                                nameInput={{
                                                    ...profileNameBlockNameInput,
                                                }}
                                                familyNameInput={
                                                    profileNameBlockFamilyNameInput
                                                }
                                            />
                                            {(isSpecialist && profile && (
                                            <ProfilePhone
                                                legend={profilePhoneBlockLegend}
                                                invalidFields={invalidFields}
                                                error={error}
                                                phoneInput={{
                                                    ...profilePhoneBlockPhoneInput,
                                                }}
                                                fieldName="phone_customer"
                                            />))}
                                            <ProfileContacts
                                                legend={
                                                    profileContactsBlockLegend
                                                }
                                                invalidFields={invalidFields}
                                                error={error}
                                                phoneInput={{
                                                    ...profileContactsBlockPhoneInput,
                                                }}
                                                emailInput={{
                                                    ...profileContactsBlockEmailInput,
                                                }}
                                            />
                                            <ProfileButtons
                                                entities={entities}
                                                showEmail={showEmail}
                                                setShowEmail={() =>
                                                    setShowEmail(true)
                                                }
                                                setType={() =>
                                                    setType("password")
                                                }
                                                currentLanguage={
                                                    currentLanguage
                                                }
                                                isSpecialist={isSpecialist}
                                                contextUser={
                                                    isSpecialist
                                                        ? "specialist"
                                                        : "customer"
                                                }
                                            />
                                            <div className="profile__fieldset profile__languages">
                                                <LanguagesChecklist
                                                    languageIds={languageIds}
                                                    entities={entities}
                                                    onChange={
                                                        handleChangeCheckbox
                                                    }
                                                    invalidFields={
                                                        invalidFields
                                                    }
                                                    error={error}
                                                    classes="form__row--no-margin"
                                                />
                                            </div>
                                            <ProfileAddress
                                                entities={entities}
                                                address={address}
                                                latitude={latitude}
                                                longitude={longitude}
                                                setAddress={setAddress}
                                                isSpecialist={isSpecialistOnUrl}
                                                updateAddressHandler={
                                                    updateAddressHandler
                                                }
                                            />
                                            <ProfileButtonsForm
                                                entities={entities}
                                                updateFields={cancelChanges}
                                                isNotDisabledCansel={
                                                    isNotDisabledCansel
                                                }
                                                isSpecialistOnUrl={
                                                    isSpecialistOnUrl
                                                }
                                                isCustomerOnUrl={
                                                    isCustomerOnUrl
                                                }
                                                historyBackHandler={
                                                    historyBackHandler
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                            </Form>
                        </div>
                    </section>
                )}
            </main>
        </>
    );
};

export default Profile;

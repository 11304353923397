import React from "react";
import cn from "classnames";
import DateInput from "../../../components/DateInput";
import Input from "../../../components/Form/Input";
import { convertDateFormatISO, convertDateToJSDate } from "../../../untils";
import Icon from "../../../components/Icon";
import { TranslationObject } from "../../../models/translationModal";
import { getFilter } from "../../../store/orders/selectors";
import { useActions } from "../../../hooks/actions";
import { ProfilePayload } from "../../../models/userModels";

interface Props {
    entities?: TranslationObject;
    profile?: ProfilePayload;
    setActiveMobileFilter: () => void;
    activeMobileFilter: string;
}

const DateFilter: React.FC<Props> = ({
    entities,
    setActiveMobileFilter,
    activeMobileFilter,
}) => {
    const { setOrdersFilter } = useActions();
    const start = getFilter("started_at");
    const ended = getFilter("ended_at");
    const started_at = start && convertDateToJSDate(start);
    const ended_at = ended && convertDateToJSDate(ended);

    const handleChange = (value: Date, str: string) => {
        const date = convertDateFormatISO(value);
        return setOrdersFilter({
            name: str,
            value: date,
        });
    };

    return (
        <div
            className={cn("filter__block", {
                "is-open": activeMobileFilter === "date",
            })}
        >
            <button
                className="filter__subtitle"
                onClick={setActiveMobileFilter}
                type="button"
            >
                {entities?.order_date?.value}
                <Icon type="arrow-left-pagination" width={9} height={18} />
            </button>
            <div className="filter__wrap-block filter-orders__date">
                <DateInput
                    dateFormat="dd.MM.yyyy"
                    input={
                        <Input
                            placeholder=""
                            icon={{
                                type: "calendar",
                                width: 18,
                                height: 18,
                                className: "date-field__icon",
                            }}
                            classes={["date-field custom-input--small"]}
                            span={{
                                text: entities?.order_distance_from?.value,
                                className: "date-field__label",
                            }}
                        />
                    }
                    maxDate={ended_at || null}
                    value={started_at ? started_at : null}
                    onChange={(value: Date) =>
                        handleChange(value, "started_at")
                    }
                />
                <DateInput
                    dateFormat="dd.MM.yyyy"
                    minDate={started_at || null}
                    input={
                        <Input
                            placeholder=""
                            icon={{
                                type: "calendar",
                                width: 18,
                                height: 18,
                                className: "date-field__icon",
                            }}
                            classes={["date-field custom-input--small"]}
                            span={{
                                text: entities?.order_distance_to?.value,
                                className: "date-field__label",
                            }}
                        />
                    }
                    value={ended_at ? ended_at : null}
                    onChange={(value: Date) => handleChange(value, "ended_at")}
                />
            </div>
        </div>
    );
};

export default DateFilter;

import React, { useEffect } from "react";
import { useNotification } from "../../hooks/useNotification";
import { ComponentProps } from "../../models/models";
import { useRegisterAsPrivatePersonMutation } from "../../store/registration/registration.api";

interface StepCompanyOrPrivatePersonProps extends ComponentProps {
    changeStep: (step: string) => unknown;
}

export const StepCompanyOrPrivatePerson: React.FC<
    StepCompanyOrPrivatePersonProps
> = ({ changeStep, entities }) => {
    const { setNotification } = useNotification();

    const [
        registerAsPrivatePerson,
        { isSuccess: isSuccessRegisterPrivatePerson },
    ] = useRegisterAsPrivatePersonMutation();

    useEffect(() => {
        if (isSuccessRegisterPrivatePerson) changeStep("four");
    }, [isSuccessRegisterPrivatePerson]);

    function handleClickPrivatePerson() {
        registerAsPrivatePerson()
            .unwrap()
            .catch(() =>
                setNotification({
                    type: "warn",
                    description: '"Error. Failed to save data."',
                })
            );
    }

    return (
        <>
            <div className="registration-form__content">
                <div className="registration-form__title-wrapper">
                    <h1 className="title title--h1">
                        {entities?.register_company_representative_or_individual
                            ?.value || ""}
                    </h1>
                </div>
                <div className="form">
                    <form>
                        <div className="form__row form__controls form__controls--full-width form__controls--not-fixed-sm">
                            <button
                                onClick={handleClickPrivatePerson}
                                className="btn btn--transparent btn--bg-blue"
                                type="button"
                            >
                                {entities?.register_individual?.value || ""}
                            </button>
                            <button
                                onClick={() => changeStep("three")}
                                className="btn btn--transparent"
                                type="button"
                            >
                                {entities?.register_representative?.value || ""}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

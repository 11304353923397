import React, { useState } from "react";
import Modal from "../../Modal";
import { Image } from "../../../models/userModels";
import Icon from "../../Icon";
import { generatePath, NavigateFunction, useNavigate } from "react-router-dom";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import { TranslationObject } from "../../../models/translationModal";

interface PhotoGalleryServicesModalProps {
    visible: boolean;
    setVisible: (visible: boolean) => unknown;
    photos: Image[];
    confirmModal: (selectPhoto: Image) => unknown;
    isMyPhotos?: boolean;
    entities?: TranslationObject;
}

export const PhotoGalleryServicesModal: React.FC<
    PhotoGalleryServicesModalProps
> = ({
    visible,
    setVisible,
    photos,
    confirmModal,
    isMyPhotos = true,
    entities,
}) => {
    const [selectPhoto, setSelectPhoto] = useState<Image | null>(null);

    const navigate = useNavigate();

    const currentLanguage = getCurrentLanguage();

    const linkToProfile = generatePath(
        "/:currentLanguage/specialist/lk/profile/",
        {
            currentLanguage,
        }
    );

    const handleClickPhoto = (photo: Image) => {
        if (selectPhoto?.id === photo.id) {
            return setSelectPhoto(null);
        }

        return setSelectPhoto(photo);
    };

    const handleClickConfirmModal = () => {
        if (selectPhoto) {
            confirmModal(selectPhoto);
            setVisible(false);
            setSelectPhoto(null);
        }
    };

    if (!photos.length) {
        return (
            <ModalNotPhotos
                visible={visible}
                setVisible={setVisible}
                isMyPhotos={isMyPhotos}
                navigate={navigate}
                linkToProfile={linkToProfile}
                entities={entities}
            />
        );
    }

    return (
        <>
            <Modal
                visible={visible}
                onClose={() => setVisible(false)}
                modificators={["modal-photoGalleryServices"]}
            >
                <div className="inner-container">
                    <h2 className="title">
                        {entities?.common_select_photo?.value}
                    </h2>
                    <ul className="photos">
                        {photos.map((photo) => (
                            <li
                                className={"photo"}
                                onClick={() => handleClickPhoto(photo)}
                                key={photo.id}
                            >
                                {selectPhoto?.id === photo.id && (
                                    <div className="overlay">
                                        <Icon
                                            type="valid-current-color"
                                            width={32}
                                            height={32}
                                            colorVariant={"light"}
                                        />
                                    </div>
                                )}
                                <img
                                    src={photo.original_url ?? ""}
                                    width={140}
                                    height={140}
                                    alt={photo?.file_name || 'Photo from gallery'}
                                />
                            </li>
                        ))}
                    </ul>
                    <div className="form__row form__controls form__controls--right">
                        <button
                            className="btn form__back-link btn--transparent"
                            type="button"
                            onClick={() => setVisible(false)}
                        >
                            {entities?.common_cancel_button?.value}
                        </button>
                        <button
                            className="btn btn--transparent btn--bg-green"
                            type="button"
                            onClick={handleClickConfirmModal}
                            disabled={!selectPhoto}
                        >
                            {entities?.common_add_photo?.value}
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

interface ModalNotPhotosProps {
    visible: boolean;
    setVisible: (visible: boolean) => unknown;
    isMyPhotos: boolean;
    navigate: NavigateFunction;
    linkToProfile: string;
    entities?: TranslationObject;
}

const ModalNotPhotos: React.FC<ModalNotPhotosProps> = ({
    visible,
    setVisible,
    isMyPhotos,
    navigate,
    linkToProfile,
    entities,
}) => {
    return (
        <Modal
            visible={visible}
            onClose={() => setVisible(false)}
            modificators={["modal-photoGalleryServices"]}
            title={entities?.advertisement_you_dont_have_photos?.value}
            description={entities?.advertisement_first_attach_photo?.value}
        >
            <>
                <div className="modal__content-buttons">
                    {(isMyPhotos && (
                        <>
                            <button
                                className="btn form__back-link btn--transparent"
                                type="button"
                                onClick={() => setVisible(false)}
                            >
                                {entities?.common_cancel_button?.value}
                            </button>
                            <button
                                className="btn btn--transparent btn--bg-green"
                                type="button"
                                onClick={() => navigate(linkToProfile)}
                            >
                                {entities?.common_go_to_profile?.value}
                            </button>
                        </>
                    )) || (
                        <button
                            className="btn btn--transparent btn--bg-green btn-ok"
                            type="button"
                            onClick={() => setVisible(false)}
                        >
                            OK
                        </button>
                    )}
                </div>
            </>
        </Modal>
    );
};

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { CustomPage, TextPage } from '../../models/directoryModel';

const PAGES_CODE = new Map<string, number>([
  ["nobis", 1],
  ["about", 2],
  ["advertisers", 3],
  ["bonuses-and-partners", 4],
  ["customer", 5],
  ["customer/service", 6],
  ["/", 7],
  ["specialist", 8],
  ["specialist/prices", 9],
  ["specialist/service", 10],
  ["write-us", 11],
  ["services", 13],
  ["user-agreement", 1],
  ["privacy-policy", 2],
  ["cookies-policy", 3],
]);

const HeaderMeta: React.FC<
{
  currentLanguage: string;
  customPages: CustomPage[];
  textPages: TextPage[];
}
> = ({ currentLanguage, customPages, textPages }) => {
  const { pathname } = useLocation();
  const path = pathname.replace(`${currentLanguage}`, '').replace('//', '');
  const pathCode = PAGES_CODE.get(path) || null;
  const isTextPage = 'user_agreement, privacy_policy, cookies_policy'.includes(path);
  const page = (isTextPage ? textPages : customPages).find(item => +item.id === pathCode);

  return (
    <Helmet>
      <title>{page?.meta_title?.[currentLanguage] || 'FindPro'}</title>
    </Helmet>
  )
};

export default HeaderMeta;
import React, { useState } from "react";
import RadioInput from "../Form/RadioInput";
import Modal from "../Modal";
import { useNavigate } from "react-router-dom";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { TranslationObject } from "../../models/translationModal";
import { Category } from "../../models/userModels";
import {
    IS_INDIVIDUAL,
    IS_PRIVATE,
    SERVICE_CATEGORY_ID,
    SPECIALIST_IDS,
    SERVICE_TYPE_ID,
    SERVICE_SUB_TYPE_IDS,
    SPECIALIST_ADDRESS,
    SERVICE_CATEGORY_SLUG,
    SERVICE_TYPE_SLUG,
    SERVICE_SUB_TYPE_SLUG
} from "../../store/order/constant";
import { createOrderState } from "../../store/order/createOrder.slice";
import { clearOrderDataInLocalStorage } from "../../store/order/selectors";
import { LocationData } from "../../models/orderModel";

interface Props {
    entities?: TranslationObject;
    visible: boolean;
    close: () => void;
    id?: number;
    categories?: Category[];
    updateField: <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) => void;
    profileId?: number;
    cat?: number;
    cat_slug?: string;
    specialistAddress?: LocationData,
    isIndividual?: boolean,
    isCatSelect?: boolean
}

const ModalSelectCategory: React.FC<Props> = ({
    entities,
    visible,
    close,
    id,
    categories,
    updateField,
    profileId,
    cat,
    cat_slug,
    specialistAddress,
    isIndividual = true,
    isCatSelect = false
}) => {
    const navigate = useNavigate();
    const currentLanguage = getCurrentLanguage();
    const [type, setType] = useState<Category | null>(null);

    const handleClick = async () => {
        clearOrderDataInLocalStorage(false, []);
        if (!type) return;

        const activeStep = cat
            ? `category-${cat_slug}/type-${type.slug}/subtype`
            : isCatSelect
            ? `category-${type.slug}/type`
            : `category-${cat_slug}/type`;

        const items: {
            name: keyof createOrderState;
            value: createOrderState[keyof createOrderState];
        }[] = [
            { name: IS_PRIVATE, value: true },
            { name: "userId", value: profileId || null },
            {
                name: cat ? SERVICE_TYPE_ID : SERVICE_CATEGORY_ID,
                value: cat || isCatSelect ? type.id : cat,
            },
            {
                name: cat ? SERVICE_TYPE_SLUG : SERVICE_CATEGORY_SLUG,
                value: cat || isCatSelect ? `type-${type.slug}` : `category-${cat_slug}`,
            },
            { name: "activeStep", value: activeStep },
            { name: IS_INDIVIDUAL, value: isIndividual },
        ];

        if (id) {
            items.push({
                name: SPECIALIST_IDS,
                value: [id],
            })
        }

        if (cat) {
            items.push(
                { name: SERVICE_CATEGORY_ID, value: cat },
                { name: SERVICE_CATEGORY_SLUG, value: cat_slug },
                { name: SERVICE_SUB_TYPE_IDS, value: [] },
                { name: SERVICE_SUB_TYPE_SLUG, value: null }
            )
        }

        if (specialistAddress) {
            items.push({
                name: SPECIALIST_ADDRESS,
                value: specialistAddress
            }, {
                name: 'isAdvertising',
                value: true
            })
        }

        items.forEach((elem) =>
            updateField({
                name: elem.name,
                value: elem.value,
            })
        );

        close();

        if (cat) {
            return navigate(`/${currentLanguage}/customer/create-order/category-${cat_slug}/type-${type.slug}/subtype`);
        }
        navigate(`/${currentLanguage}/customer/create-order`);
    };
    return (
        <Modal
            title={cat ? entities?.order_select_one_type?.value : entities?.order_category_offer?.value}
            visible={visible}
            onClose={close}
        >
            <>
                {categories?.map((item: Category, key: number) => (
                    <RadioInput
                        key={key}
                        label={item?.name}
                        checked={item?.id == type?.id}
                        onClick={() => setType(item)}
                    />
                ))}
                <div className="form__row form__controls form__controls--right">
                    <button
                        className="btn form__control form__back-link btn--transparent"
                        type="button"
                        onClick={close}
                    >
                        {entities?.common_cancel_button?.value}
                    </button>
                    <button
                        className="btn form__control btn--transparent btn--bg-green"
                        onClick={handleClick}
                        type="button"
                    >
                        {entities?.common_save_button?.value}
                    </button>
                </div>
            </>
        </Modal>
    );
};

export default ModalSelectCategory;

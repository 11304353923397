import React from "react";
import Modal from "../Modal";
import { TranslationObject } from "../../models/translationModal";
import { Link } from "react-router-dom";
interface Props {
    showModal: string;
    onClose: () => void;
    linkToOrder: string;
    entities?: TranslationObject;
}

const CopyOrderModal: React.FC<Props> = ({
    showModal,
    onClose,
    linkToOrder,
    entities,
}) => {
    return (
        <Modal
            visible={showModal === `draft`}
            key={`modal_copy_order`}
            onClose={() => onClose()}
            title={entities?.order_create_copy_title?.value}
            description={entities?.order_copied?.value}
        >
            <Link
                className="btn modal__content-button"
                to={linkToOrder}
            >
				{entities?.order_go_to_copy?.value}
			</Link>
        </Modal>
    );
};

export default CopyOrderModal;

import React from "react";
import { Link } from "react-router-dom";
import { TranslationObject } from "../../../../models/translationModal";
import { ProfilePayload } from "../../../../models/userModels";
import { usePublishSpecialistProfileMutation } from "../../../../store/user/user.api";
import { useLoading } from "../../../../hooks/useLoading/useLoading";
import DeleteButton from "../../../Profile/DeleteButton";
import LogoutButton from "../../../Profile/LogoutButton";
import { useNotification } from "../../../../hooks/useNotification";
import { transliterate } from 'transliteration';

interface ProfileControlsProps {
    entities?: TranslationObject;
    profile?: ProfilePayload;
    currentLanguage: string;
}

export const ProfileControls: React.FC<ProfileControlsProps> = ({
    entities,
    profile,
    currentLanguage
}) => {
    const { id, name, published_at, about_me, services } = profile || {};

    const readyPublished = !!(about_me && services?.length && services.every(service => {
        return service.is_remote || service.specialist_address || service.customer_address;
    }));

    const [publishSpecialistProfile] = usePublishSpecialistProfileMutation();

    const { setLoading } = useLoading();

    const { setNotification } = useNotification();

    const processedName = name ? transliterate(name.trim().replace(/\s+/g, '-')).toLowerCase() : 'noname';

    const linkToSpecialist = `/${currentLanguage}/specialist/${processedName}-${id}`;

    const handleClickPublishQuestionnaire = () => {
        setLoading(true);
        publishSpecialistProfile()
            .unwrap()
            .then(() =>
                setNotification({
                    type: "confirm",
                    description:
                        entities?.specialist_details_profile_has_been_published
                            ?.value ?? "",
                })
            )
            .catch(() =>
                setNotification({
                    type: "warn",
                    description:
                        entities
                            ?.specialist_details_profile_has_not_been_published
                            ?.value ?? "",
                })
            )
            .finally(() => setLoading(false));
    };

    return (
        <>
            <div className="specialist-profile__controls">
                <DeleteButton
                    isSpecialist={true}
                    currentLanguage={currentLanguage}
                    entities={entities}
                />
                <LogoutButton
                    isSpecialist={true}
                    currentLanguage={currentLanguage}
                    entities={entities}
                />
                {!published_at && (
                    <button
                        className="btn btn--bg-green"
                        type="button"
                        disabled={!readyPublished}
                        onClick={handleClickPublishQuestionnaire}
                    >
                        {
                            !profile?.is_draft
                                ? entities?.specialist_details_submit?.value || ""
                                : entities?.profile_specialist_questionnaire_send_verification?.value || ""
                        }
                    </button>
                )}
                <Link className="btn specialist-profile__btn-text" to={linkToSpecialist} state={window.location.pathname}>
                    {entities?.specialist_details_preview?.value}
                </Link>
                {/* <Link to={linkToUser}>
                    <p className="profile-mini-card__title">
                        {user?.name} {user?.family_name}
                    </p>
                </Link> */}
                {/* <button
                    className="btn specialist-profile__btn-text"
                    type="button"
                    // onClick={handleClickPublishQuestionnaire}
                >
                    Посмотреть, как видят Вашу страницу заказчики
                </button> */}
            </div>
        </>
    );
};

import React, { useState } from "react";
import ChangeDateModal from "../../../../components/Modals/ChangeDateModal";
import { TranslationObject } from "../../../../models/translationModal";
import {
    useUpdateOrderMutation,
    util,
} from "../../../../store/orders/orders.api";
import { useDispatch } from "react-redux";
import { convertDateToDDMMYYYY } from "../../../../untils";
import classNames from "classnames";

interface Props {
    entities?: TranslationObject;
    date: string;
    id: number;
    isSpecialist: boolean;
    isArchive: boolean;
}

const ExecutionDate: React.FC<Props> = ({
    entities,
    date,
    id,
    isSpecialist,
    isArchive,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [updateOrder] = useUpdateOrderMutation();
    const dispatch = useDispatch();
    const stringData = date ? convertDateToDDMMYYYY(date) : "";

    const save = (value: string) => {
        updateOrder({
            body: {
                execution_at: value,
            },
            id,
        });
        dispatch(util.invalidateTags(["Order"]));
        setShowModal(false);
    };

    const handleClick = () => {
        if (isSpecialist || isArchive) {
            return;
        }
        setShowModal(true);
    };

    return (
        <>
            <ChangeDateModal
                entities={entities}
                visible={showModal}
                date={date}
                save={save}
                onClose={() => setShowModal(false)}
            />
            <li className="order-information__item" onClick={handleClick}>
                <p className="order-information__item-heading">
                    {entities?.order_execution_date?.value}
                </p>
                <p className="order-information__item-text">
                    <time
                        dateTime="2022-03-14 19:00"
                        className={classNames({
                            "link link--underline": !isSpecialist && !isArchive,
                        })}
                    >
                        {stringData}
                    </time>
                </p>
            </li>
        </>
    );
};

export default ExecutionDate;

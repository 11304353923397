import { useAppDispatch } from "../../hooks/useAppDispatch";
import { util } from "../../store/orders/orders.api";

export const useUpdateOrdersStatusesList = () => {
    const dispatch = useAppDispatch();

    return (statusesList: Record<string, number | null>) => {
        dispatch(
            util.updateQueryData(
                "getOrdersStatusesList",
                undefined,
                (currentList) => {
                    for (const key in statusesList) {
                        const statusValue = statusesList[key];
                        currentList[key] = statusValue ?? null;
                    }
                }
            )
        );
    };
};

import React, { useState } from "react";
import { TranslationObject } from "../../models/translationModal";
import Modal from "../Modal";
import DateStartAndEnd from "../DateStartAndEnd";
import { createOrderState } from "../../store/order/createOrder.slice";
import { convertDateFormatISO } from "../../untils";

interface Props {
  entities?: TranslationObject;
  visible: boolean;
  handleChange: (startAt: string, endAt: string) => void;
  changeVisible: (value: boolean) => void;
}

const OrderDateModal: React.FC<Props> = ({ entities, visible, changeVisible, handleChange }) => {
  const [startAt, setStartAt] = useState<string | null>();
  const [endAt, setEndAt] = useState<string | null>();
  
  const changeDate = (value: Date | string, name: keyof createOrderState) => {
    if (name === "started_at") {
      setStartAt(convertDateFormatISO(value));
    } else {
      setEndAt(convertDateFormatISO(value));
    }
  }

  const closeModal = () => {
    setStartAt(null);
    setEndAt(null);
    changeVisible(false);
  }

  const confirm = () => {
    if (startAt) {
      handleChange(startAt, endAt || "");
      closeModal();
    }
  }

  return (
    <Modal
      title="Дата и время"
      visible={visible}
      onClose={closeModal}
      description="Дата исполнения заказа истекла. Укажите новую дату."
    >
        <div className="form modal-order-date--publish">
          <DateStartAndEnd 
            entities={entities} 
            handleChange={changeDate}
            startAt={startAt}
            endAt={endAt}
            allowSelectionOldDates={false}
          />

          <div className="modal__content-buttons">
              <button
                  className="btn btn--transparent"
                  type="button"
                  onClick={closeModal}
              >
                  {entities?.common_cancel_button?.value}
              </button>
              <button
                  className="btn modal__content-button btn--bg-green"
                  type="button"
                  disabled={!startAt}
                  onClick={confirm}
              >
                  {entities?.common_confirm_link?.value}
              </button>
          </div>
        </div>
    </Modal>
  )
}

export default OrderDateModal;
import React, { useEffect, useRef, useState } from "react";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { PaymentMethod } from "@stripe/stripe-js";
import { TranslationObject } from "../../../../../models/translationModal";
import { useLoading } from "../../../../../hooks/useLoading/useLoading";
import { useNotification } from "../../../../../hooks/useNotification";

interface NewCardProps {
    buttonBackHandler: () => unknown;
    buttonAddNewCardHandler: (card: PaymentMethod) => unknown;
    entities?: TranslationObject;
}

export const NewCard: React.FC<NewCardProps> = (props) => {
    const { buttonBackHandler, buttonAddNewCardHandler, entities } =
        props || {};

    const stripe = useStripe();

    const elements = useElements();

    const formRef = useRef<HTMLFormElement>(null);

    const { setNotification } = useNotification();

    const [newPaymentCard, setNewPaymentCard] =
        useState<PaymentMethod | null>();

    const { loading, setLoading } = useLoading();

    useEffect(() => {
        if (newPaymentCard) {
            buttonAddNewCardHandler(newPaymentCard);
        }
    }, [newPaymentCard]);

    const handleSubmit = async () => {
        if (
            stripe !== null &&
            elements !== null &&
            elements.getElement(CardNumberElement) !== null &&
            elements.getElement(CardExpiryElement) !== null &&
            elements.getElement(CardCvcElement) !== null
        ) {
            const cardNumber = elements.getElement(CardNumberElement);

            if (cardNumber) {
                setLoading(true);
                const { error, paymentMethod } =
                    (await stripe?.createPaymentMethod({
                        type: "card",
                        card: cardNumber,
                    })) || {};

                if (!error && paymentMethod?.id) {
                    setNewPaymentCard(paymentMethod);
                } else {
                    const errorDescription =
                        entities?.add_credit_card_error?.value ??
                        error?.message ??
                        "";
                    setNotification({
                        type: "warn",
                        description: errorDescription,
                    });
                    setLoading(false);
                }
            }
        }
    };

    return (
        <>
            <div className="credit-card">
                <div className="credit-card__wrapper">
                    <div className="form">
                        <form
                            onSubmit={(event) => {
                                event.preventDefault();
                                handleSubmit();
                            }}
                            ref={formRef}
                        >
                            <div className="form__row">
                                <CardNumberElement
                                    className="card-number"
                                    options={{
                                        placeholder:
                                            entities?.finances_card_number
                                                ?.value,
                                    }}
                                />
                            </div>
                            <div className="form__row">
                                <CardExpiryElement
                                    className="card-expiry"
                                    options={{
                                        placeholder:
                                            entities
                                                ?.add_credit_card_expiration_field
                                                ?.value || "",
                                    }}
                                />
                                <CardCvcElement
                                    className="card-cvc"
                                    options={{
                                        placeholder: "CVC/CVV",
                                    }}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <p
                className="text"
                dangerouslySetInnerHTML={{
                    __html: entities?.add_credit_card_description?.value ?? "",
                }}
            />
            <div className="modal__content-buttons">
                <button
                    className="btn modal__btn btn--transparent"
                    type="button"
                    onClick={buttonBackHandler}
                >
                    {entities?.common_return_button?.value}
                </button>
                <button
                    className="btn modal__btn btn--bg-green btn--transparent"
                    type="button"
                    onClick={() => formRef?.current?.requestSubmit()}
                    disabled={loading}
                >
                    {entities?.common_continue_button?.value}
                </button>
            </div>
        </>
    );
};

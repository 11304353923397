import React, { useEffect } from "react";
import { ProfilePayload } from "../../models/userModels";
import { TranslationObject } from "../../models/translationModal";
import SubMenu from "../../components/SubMenu";
import {
    getItemEditOrder,
    getItemsEditOrder,
    getOrderBody,
} from "../../store/order/selectors";
import { ID, IS_PRIVATE, SPECIALIST_IDS } from "../../store/order/constant";
import { useUpdateOrderMutation } from "../../store/orders/orders.api";
import { Routes, useNavigate, Route } from "react-router-dom";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { useActions } from "../../hooks/actions";
import BaseContainer from "./BaseContainer";
import SpecialistsEditOrder from "./Specialists";
import CurrentSpecialists from "../CreateOrder/CurrentSpecialists";
import Specialist from "../CreateOrder/Specialist";

interface Props {
    profile?: ProfilePayload;
    entities?: TranslationObject;
}

const EditOrder: React.FC<Props> = ({ entities, profile }) => {
    const { clearEditOrderFields, updateEditOrderField } = useActions();
    const [updateOrder, { isSuccess, isLoading }] = useUpdateOrderMutation();
    const specialistIds = getItemEditOrder(SPECIALIST_IDS);
    const id = getItemEditOrder(ID);
    const isPrivate = getItemEditOrder(IS_PRIVATE) || false;
    const data = getItemsEditOrder();
    const navigate = useNavigate();
    const currentLanguage = getCurrentLanguage();
    const defaultLink = `/${currentLanguage}/customer/edit-order`;

    useEffect(() => {
        if (!id) {
            navigate(`/${currentLanguage}/customer/lk/orders`);
        }
    }, [id]);

    useEffect(() => {
        return () => clearEditOrderFields();
    }, []);

    const onSubmit = (e?: React.FormEvent<HTMLInputElement>) => {
        if (e) {
            e.preventDefault();
        }
        if (id) {
            save("active", id);
        }
    };

    const save = (status?: string, id?: number) => {
        let body = getOrderBody(data);
        if (status) {
            body = { ...body, status: status };
        }
        if (id) {
            updateOrder({
                body,
                id,
            });
            navigate(`/${currentLanguage}/customer/lk/order/${id}`);
        }
        clearEditOrderFields();
    };

    const clearSpecialistIds = () => {
        updateEditOrderField({
            name: SPECIALIST_IDS,
            value: [],
        });
        navigate(defaultLink);
    };

    const changeSpecialistIds = (id: number) => {
        updateEditOrderField({
            name: SPECIALIST_IDS,
            value:
                specialistIds?.find(i => i === id)
                    ? specialistIds?.filter((elem) => elem !== id)
                    : specialistIds?.concat(id),
        });
    };

    const onSuccess = () => {
        navigate(`/${currentLanguage}/customer/lk/order/${id}`);
    };

    return (
        <main>
            <SubMenu entities={entities} profile={profile} />
            <section className="order-editing">
                <Routes>
                    <Route
                        path="/"
                        element={
                            <BaseContainer
                                isLoading={isLoading}
                                save={save}
                                onSubmit={onSubmit}
                                isPrivate={isPrivate}
                                isSuccess={isSuccess}
                                entities={entities}
                            />
                        }
                    />
                    <Route
                        path="select-specialists"
                        element={
                            <SpecialistsEditOrder
                                onSubmit={onSubmit}
                                isSuccess={isSuccess}
                                entities={entities}
                                save={save}
                                isPrivate={isPrivate}
                                specialistIds={specialistIds || []}
                                clearSpecialistIds={clearSpecialistIds}
                                changeSpecialistIds={changeSpecialistIds}
                                onSuccess={onSuccess}
                            />
                        }
                    />
                    <Route
                        path="selected_specialists"
                        element={
                            <CurrentSpecialists
                                isEdit
                                specialistIds={specialistIds || []}
                                entities={entities}
                                changeSpecialistIds={changeSpecialistIds}
                                isPrivate={isPrivate}
                            />
                        }
                    />
                    <Route
                        path="specialist/:id"
                        element={
                            <Specialist
                                isEdit
                                isPrivate={isPrivate}
                                isSuccess={isSuccess}
                                entities={entities}
                                onSubmit={onSubmit}
                                onSuccess={onSuccess}
                                clearSpecialistIds={clearSpecialistIds}
                                specialistIds={specialistIds || []}
                                changeSpecialistIds={changeSpecialistIds}
                            />
                        }
                    />
                </Routes>
            </section>
        </main>
    );
};

export default EditOrder;

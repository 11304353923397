import React, { Dispatch } from "react";
import CardSpecialist from "../../../components/CardSpecialist";
import { TranslationObject } from "../../../models/translationModal";
import { OrderSpecialistItem } from "../../../models/orderModel";
import { OrderItem } from "../../../models/ordersModel";
import { getCurrentLanguage } from "../../../store/directories/selectors";

interface Props {
    entities?: TranslationObject;
    specialists: OrderSpecialistItem[];
    isSpecialist: boolean;
    isPrivate: boolean;
    order: OrderItem;
    isActive: boolean;
    setShowSpecialistList: Dispatch<boolean>;
}

const List: React.FC<Props> = ({
    entities,
    specialists,
    isSpecialist,
    isPrivate,
    order,
    isActive,
    setShowSpecialistList,
}) => {
    if (specialists && specialists?.length < 1) {
        return null;
    }
    const currentLanguage = getCurrentLanguage();
    const showAddSpecialistButton = isActive && isPrivate && !isSpecialist;

    return (
        <div className="active-order__response-specialists">
            <h3 className="title title--h3">
                {isPrivate
                    ? entities?.order_selected_specialists?.value
                    : entities?.order_responding_specialists?.value}
            </h3>
            <div className="active-order__response-content">
                <ul className="active-order__response-profiles-list">
                    {specialists?.map((specialist) => (
                        <CardSpecialist
                            isEdit={false}
                            specialist={specialist}
                            entities={entities}
                            key={specialist?.id}
                            isSquare
                            linkTo={""}
                            isPrivate={isPrivate}
                            isUnresponsiveSpecialist={
                                !order?.responded_specialist_ids?.find(
                                    (elem) => elem === specialist?.id
                                )
                            }
                            isAdd={
                                isPrivate &&
                                !!specialists.find(
                                    (item) => item.id === specialist.id
                                )
                            }
                            isSpecialist={isSpecialist}
                            isOrderPage={true}
                            orderId={order?.id}
                        />)
                    )}
                </ul>
            </div>
            {showAddSpecialistButton && (
                <button
                    className="btn reviews__btn btn--white btn--wide btn--rounded"
                    onClick={() => setShowSpecialistList(true)}
                >
                    {entities?.order_add_specialists?.value}
                </button>
            )}
        </div>
    );
};

export default List;

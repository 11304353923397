import { Link } from "react-router-dom";
import React, { useState } from "react";
import { ComponentProps } from "../../models/models";
import { ProfilePayload } from "../../models/userModels";
import Modal from "../Modal";
import cn from "classnames";
import classNames from "classnames";
import { showPagesListIsSuspended } from "../SuspendedNotification/SuspendedNotification";
import { useLogout } from "../../hooks/useLogout/useLogout";

type ItemMenu = {
    name?: string;
    to: string;
    onClick?: () => void;
};

interface SubMenu {
    customer: ItemMenu[];
    specialist: ItemMenu[];
}

interface Props extends ComponentProps {
    userRole: keyof SubMenu;
    profile?: ProfilePayload;
}

const DropDownMenu: React.FC<Props> = ({
    currentLanguage,
    entities,
    userRole,
    profile,
}) => {
    const [visible, setVisible] = useState<boolean>(false);
    const isSpecialist = userRole === "specialist";
    const isSuspendedSpecialist = isSpecialist && profile?.is_suspended;

    const logout = useLogout();

    const handleLogout = () => {
        logout();
        setVisible(false);
    };

    const generalMenu = [
        {
            name: entities?.orders_link_value?.value,
            to: `/${currentLanguage}/${userRole}/lk/orders`,
        },
    ];

    const submenu: SubMenu = {
        customer: [
            ...generalMenu,
            {
                name: entities?.notifications_link_value?.value,
                to: `/${currentLanguage}/${userRole}/lk/notifications`,
            },
            {
                name: entities?.profile_title?.value,
                to: `/${currentLanguage}/${userRole}/lk/profile`,
            }
            //{
            //    name: entities?.my_reviews_link_value?.value,
            //     to: `/${currentLanguage}/${userRole}/lk/reviews/${profile?.id}`,
            //},
        ],
        specialist: [
            ...generalMenu,
            {
                name: entities?.notifications_link_value?.value,
                to: `/${currentLanguage}/${userRole}/lk/notifications`,
            },
            {
                name: entities?.my_reviews_link_value?.value,
                to: `/${currentLanguage}/${userRole}/lk/reviews/${profile?.id}`,
            },
            {
                name: entities?.finances_title?.value,
                to: `/${currentLanguage}/${userRole}/lk/finances`,
            },
            {
                name: entities?.advertisement_title?.value,
                to: `/${currentLanguage}/${userRole}/lk/advertisement`,
            },
            {
                name: entities?.profile_title?.value,
                to: `/${currentLanguage}/${userRole}/lk/profile`,
            }
        ],
    };

    return (
        <>
            <Modal
                title={entities?.profile_logout_text?.value}
                visible={visible}
                onClose={() => setVisible(false)}
            >
                <div className="modal__content-buttons">
                    <button
                        className="btn btn--transparent"
                        type="button"
                        onClick={() => setVisible(false)}
                    >
                        {entities?.common_cancel_button?.value}
                    </button>
                    <button
                        className={classNames("btn modal__content-button", {
                            "modal__content-button--background": isSpecialist,
                            "modal__content-button--background-customer":
                                !isSpecialist,
                        })}
                        type="button"
                        onClick={handleLogout}
                    >
                        {entities?.profile_logout_button_ok?.value}
                    </button>
                </div>
            </Modal>
            <ul className="user-nav__list list-reset">
                {submenu[userRole as keyof SubMenu]?.map(
                    (item: ItemMenu, key: number) => {
                        const disabled =
                            isSuspendedSpecialist &&
                            !showPagesListIsSuspended.some((page) =>
                                item?.to?.includes(page)
                            );
                        return (
                            <li className="user-nav__item" key={key}>
                                <Link
                                    className={cn("user-nav__btn", {
                                        green: userRole === "specialist",
                                        disabled: disabled,
                                    })}
                                    onClick={
                                        disabled ? item?.onClick : undefined
                                    }
                                    to={item?.to}
                                >
                                    {item?.name}
                                </Link>
                            </li>
                        );
                    }
                )}
                <li className="user-nav__item" onClick={() => setVisible(true)}>
                    <div
                        className={cn("user-nav__btn", {
                            green: userRole === "specialist",
                        })}
                    >
                        {entities?.logout_link_value?.value}
                    </div>
                </li>
            </ul>
        </>
    );
};

export default DropDownMenu;

import { useNotification } from "./../../../../hooks/useNotification/useNotification";
import { useLoading } from "./../../../../hooks/useLoading/useLoading";
import {
    financesEndpoints,
    useDeleteBankCardMutation,
} from "../../../../store/finances/finances.api";
import { useEffect, useState } from "react";
import { IBankCard } from "../../../../models/financesModel";
import { IUseBankCards } from "./useBankCards.types";

export const useBankCards = (): IUseBankCards => {
    const [mainCard, setMainCard] = useState<IBankCard | null>(null);
    const [othersCards, setOtherCards] = useState<IBankCard[]>([]);

    const {
        data: bankCards,
        isSuccess: isSuccessBankCards,
        isLoading: isLoadingBankCards,
        isFetching: isFetchingBankCards,
    } = financesEndpoints.getBankCards.useQuery();

    const [
        deleteBankCardMutation,
        {
            isLoading: isLoadingDeleteBankCard,
            isSuccess: isSuccessDeleteBankCard,
        },
    ] = useDeleteBankCardMutation();

    const { setLoading } = useLoading();

    const { setNotification } = useNotification();

    useEffect(() => {
        if (isSuccessBankCards && bankCards?.length) {
            const mainCard = bankCards?.find((card) => card?.is_primary);
            const otherCards = bankCards?.filter((card) => !card?.is_primary);

            if (mainCard) {
                setMainCard(mainCard);
            }

            if (otherCards) {
                setOtherCards(otherCards);
            }
        }
    }, [
        bankCards,
        isSuccessBankCards,
        isFetchingBankCards,
        isSuccessDeleteBankCard,
    ]);

    useEffect(() => {
        const isLoading =
            isLoadingDeleteBankCard ||
            isLoadingBankCards ||
            isFetchingBankCards;
        if (isLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isLoadingBankCards, isLoadingDeleteBankCard, isFetchingBankCards]);

    const deleteBankCard = (cardId: string) => {
        if (!cardId || isLoadingDeleteBankCard || isLoadingBankCards) return;

        const requestData = {
            card_id: cardId,
        };

        deleteBankCardMutation(requestData)
            .unwrap()
            .then(() =>
                setNotification({
                    type: "confirm",
                    description: "Карта успешно удалена!",
                })
            )
            .catch(() =>
                setNotification({
                    type: "warn",
                    description: " Не удалось удалить карту.",
                })
            );
    };

    return {
        mainCard,
        othersCards,
        deleteBankCard,
    };
};

import React from "react";
import { BankCard } from "../BankCard";
import { AddNewCard } from "../AddNewCard";
import { IBankCard } from "../../../../models/financesModel";

interface MobileCardsProps {
    mainCard: IBankCard;
    otherCards: IBankCard[];
    openAddCardModal: () => unknown;
    deleteBankCard: (cardId: string) => unknown;
}

export const MobileCards: React.FC<MobileCardsProps> = ({
    mainCard,
    otherCards,
    openAddCardModal,
    deleteBankCard,
}) => {
    return (
        <div className="mobile-cards">
            <div>
                <BankCard card={mainCard} />
                <p className="mobile-cards__main-card__description">
                    Основная карта
                </p>
            </div>

            {otherCards?.map((card) => (
                <BankCard
                    key={card?.id}
                    card={card}
                    deleteBankCard={deleteBankCard}
                />
            ))}
            <AddNewCard openAddCardModal={openAddCardModal} />
        </div>
    );
};

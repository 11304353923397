import SpecialistInfo from "../../components/SpecialistInfo";
import SpecialistPanel from "../../components/SpecialistPanel";
import React, { useEffect } from "react";
import { OrderStepsProps } from "./index";
import { getItemCreateOrder } from "../../store/order/selectors";
import { SERVICE_CATEGORY_SLUG, SERVICE_SUB_TYPE_SLUG, SERVICE_TYPE_SLUG } from "../../store/order/constant";

interface Props extends OrderStepsProps {
    changeSpecialistIds?: (value: number) => void;
    specialistIds: number[];
    clearSpecialistIds: () => void;
    isEdit: boolean;
    isPrivate: boolean;
}

const Specialist: React.FC<Props> = ({
    entities,
    currentLanguage,
    onSubmit,
    isSuccess,
    clearSpecialistIds,
    specialistIds,
    changeSpecialistIds,
    onSuccess,
    isEdit,
    isPrivate,
}) => {
    const categoryPath = getItemCreateOrder(SERVICE_CATEGORY_SLUG);
    const typePath = getItemCreateOrder(SERVICE_TYPE_SLUG);
    const subtypePath = getItemCreateOrder(SERVICE_SUB_TYPE_SLUG);

    const _onSuccess = () => {
        const path = isEdit ? 
            'select-specialists' :
            `${categoryPath}/${typePath}/${subtypePath}/list-of-specialists`

        onSuccess(path);
    };

    useEffect(() => {
        if (isSuccess) {
            _onSuccess();
        }
    }, [isSuccess]);

    const _onSubmit = (e?: React.FormEvent<HTMLInputElement>) => {
        const path = isEdit ? 
            'select-specialists' :
            `${categoryPath}/${typePath}/${subtypePath}/list-of-specialists`
        return onSubmit(e, path);
    };
    return (
        <>
            <SpecialistInfo
                entities={entities}
                currentLanguage={currentLanguage}
                changeSpecialistIds={changeSpecialistIds}
                isOrder={true}
                specialistIds={specialistIds}
                isEdit={isEdit}
            />
            <SpecialistPanel
                isPrivate={isPrivate}
                isEdit={isEdit}
                classes={["output--white"]}
                entities={entities}
                onSubmit={_onSubmit}
                isSuccess={isSuccess}
                onSuccess={_onSuccess}
                clearSpecialistIds={clearSpecialistIds}
                specialistIds={specialistIds}
            />
        </>
    );
};

export default Specialist;

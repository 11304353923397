import React, { useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import {
    getArrayToObject,
    getCustomPage
} from "../../store/directories/selectors";
import { useSelector } from "react-redux";
import classNames from 'classnames';
import { ProfilePayload } from "../../models/userModels";
import { useActions } from "../../hooks/actions";
import { supportChatIdSelector } from "../../store/support/support.selectors";
import SubMenu from '../../components/SubMenu';
import { TranslationObject } from '../../models/translationModal';
import { ChatContainer, ChatForm, ChatWindow } from '../OrderChat/elements';
import ChatsSidebar from '../../components/ChatsSidebar';

import {
    endpoints as supportEndpoints,
    useCreateNewChatMutation,
    // useSendMessageToChatMutation,
} from "../../store/support/support.api";

const Support: React.FC<{
    entities: TranslationObject;
    profile?: ProfilePayload;
}> = ({ entities, profile }) => {
    const HEADER_ID = 12
    const isSupport = true;
    const { id: userId, user_role: role } = profile || {};
    const isAuth = !!userId;
    const { setSupportId, setHeaderStateSearch } = useActions();
    const [getChat, { data: chat }] = supportEndpoints.getChatInfo.useLazyQuery();
    const [createNewChat, { data: newChat }] = useCreateNewChatMutation();
    const chatId = useSelector(supportChatIdSelector);
    const result = getCustomPage({ id: HEADER_ID });
    const headerData = result.data;
    const chID = newChat?.id || chatId;
    // const { role } = useParams();
    const navigate = useNavigate();
    const back = () => navigate(-1);

    useEffect(() => {
        if (newChat?.id) {
            setSupportId(newChat?.id || 0);
        }
    }, [newChat]);

    useEffect(() => {
        if (chatId) {
            getChat(chatId);
        } else {
            createNewChat();
        }
    }, [chatId]);

    useEffect(() => {
        if (headerData && headerData?.blocks) {
            const headerBlocks = getArrayToObject(headerData?.blocks || []);
            setHeaderStateSearch(headerBlocks?.header_search_button);
        }
    }, [headerData]);

    return (
        <>
            <main>
                <div
                    className={classNames('page', {
                        'specialist-chat': role === 'specialist',
                        'customer-chat': role === 'customer',
                    })}
                >
                    <SubMenu entities={entities} />
                    <div className='container'>
                        <div className='order-information page__intro'>
                            <div className='order-information__header'>
                                <span></span>
                                <div className='order-information__buttons'>
                                    <button
                                        className='btn order-information__button order-information__button--dotts'
                                        onClick={back}
                                    >{entities?.order_details?.value}</button>
                                </div>
                                <h2 className='title title--h2'>{entities?.support_title?.value}</h2>
                            </div>
                        </div>
                    </div>

                    <ChatContainer entities={entities}>
                        <div className='chat__header'></div>
                        <ChatsSidebar
                            classNames={'chat__pinned'}
                            showIfNotChats={true}
                            // id={+orderId}
                            entities={entities}
                            // profile={profile}
                        />
                        <ChatWindow
                            userId={userId}
                            chatId={chID}
                            role={role}
                            entities={entities}
                            isSupport={isSupport}
                        />
                        <ChatForm
                            role={role}
                            // orderId={orderId}
                            chatId={chatId}
                            entities={entities}
                            isSupport={isSupport}
                            isAuth={isAuth}
                        />
                    </ChatContainer>
                </div>
            </main>
        </>
    )
}

export default Support
import React, { ReactNode, useState } from "react";
import Control from "../Control";

export interface Errors {
    name: string[];
    family_name: string[];
}

export type InvalidFieldsProps = Set<string>;

interface InputProps {
    type?: string;
    invalidFields?: InvalidFieldsProps;
    label?: string;
    id?: string;
    error?: any;
    name?: string;
    defaultValue?: string;
    untaggedRequired?: any;
    required?: boolean;
    disabled?: boolean;
    children?: ReactNode;
    controlMessage?: string;
    classes?: string[];
    placeholder: string;
    onChange?: any;
    value?: string | number | null;
    button?: ReactNode;
    icon?: {
        type: string;
        width: number;
        height: number;
        className?: string;
    } | null;
    span?: {
        text?: string | number;
        className?: string;
    };
    min?: number;
    max?: number;
    maxLength?: number;
}

const Input: React.FC<InputProps> = ({
    type,
    invalidFields,
    label,
    id,
    error,
    name,
    defaultValue,
    untaggedRequired,
    required,
    disabled,
    children,
    controlMessage,
    onChange,
    classes,
    value,
    button,
    icon,
    span,
    maxLength = 255,
    ...otherProps
}) => {
    const [activeValue, setActiveValue] = useState("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e.target.value);
        } else {
            setActiveValue(e.target.value);
        }
    };

    return (
        <Control
            id={id}
            label={label}
            error={error}
            name={name}
            classes={classes}
            required={required}
            invalidFields={invalidFields}
            controlMessage={controlMessage}
            button={button}
            icon={icon}
            span={span}
        >
            <input
                name={name}
                id={id}
                type={type}
                value={value ?? activeValue}
                className="custom-input"
                defaultValue={defaultValue}
                required={required || untaggedRequired}
                disabled={disabled}
                autoComplete={name}
                onChange={handleChange}
                maxLength={maxLength}
                {...otherProps}
            />
            {children}
        </Control>
    );
};

export default Input;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCookie } from "../../untils/cookies";

export interface DirectoriesState {
    language: string;
    agreeWithCookie: boolean;
    isUserChangedLanguage: boolean;
    isMobile: boolean;
    isDesktop: boolean;
    isTablet: boolean;
}

const initialState: DirectoriesState = {
    language: getCookie("language") || "el",
    agreeWithCookie: false,
    isUserChangedLanguage: false,
    isMobile: window.innerWidth < 768,
    isDesktop: window.innerWidth > 1199,
    isTablet: window.innerWidth <= 1199 && window.innerWidth >= 768,
};

export const directoriesSlice = createSlice({
    name: "directories",
    initialState,
    reducers: {
        updateLanguage(state, action: PayloadAction<string>) {
            state.language = action.payload;
        },
        updateAgreeWithCookie(state, action: PayloadAction<boolean>) {
            state.agreeWithCookie = action.payload;
        },
        updateIsUserChangedLanguage(state, action: PayloadAction<boolean>) {
            state.isUserChangedLanguage = action.payload;
        },
        changeVersion(state, action: PayloadAction<boolean>) {
            state.isMobile = action.payload < 768;
            state.isDesktop = action.payload > 1199;
            state.isTablet =
                action.payload <= 1199 && action.payload >= 768;
        },
    },
});

export const directoriesActions = directoriesSlice.actions;
export const directoriesReducer = directoriesSlice.reducer;

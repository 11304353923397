import React, { useState } from "react";
import { endpoints } from "../../store/directories/directories.api";
import SubTypeItem from "./SubTypeItem";
import Icon from "../../components/Icon";
import classNames from "classnames";
import { Category, TypesPayload } from "../../models/directoryModel";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { TranslationObject } from "../../models/translationModal";
import CreateOrderModal from "../../components/Modals/CreateOrderModal/CreateOrderModal";
import LogoutModalButton from "../../components/LogoutModalButton/LogoutModalButton";

const TypeItem: React.FC<{
    category: Category;
    type: TypesPayload;
    entities?: TranslationObject;
    isSpecialist?: boolean;
}> = ({ type, entities, isSpecialist, category }) => {
    const currentLanguage = getCurrentLanguage();
    const { data: subtypes } = endpoints.getServiceSubTypes.useQuery({
        id: type?.id,
        currentLanguage,
    });
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div
            className={classNames(
                "accordion__element accordion__content-parent",
                {
                    "is-active": isOpen,
                }
            )}
        >
            {
                (!subtypes || subtypes?.length > 1) ? (
                    <button
                        className="accordion__element-parent-button"
                        type="button"
                        onClick={subtypes && subtypes?.length ? () => setIsOpen(!isOpen) : undefined}
                    >
                        <Icon
                            type={isOpen ? "arrow-down" : "arrow-right"}
                            className={classNames("accordion__element-button-icon", {
                                visibility: subtypes && subtypes?.length < 1,
                                "is-open": isOpen,
                            })}
                            width={isOpen ? 14 : 9}
                            height={isOpen ? 9 : 14}
                        />
                            <span
                                className={classNames(
                                    "accordion__element-parent-button-title",
                                    {
                                        "is-open": isOpen,
                                    }
                                )}
                            >
                                {type?.name}
                            </span>
                            <span className="accordion__element-parent-button-count">
                                ({type?.specialists_count})
                            </span>
                    </button>
                ) : (
                    isSpecialist ? (
                        <LogoutModalButton 
                            isSpecialist={isSpecialist}
                            entities={entities}
                            classes={["accordion__element-parent-button"]}
                        >
                            <>
                                <span className="accordion__element-parent-button-title">
                                    {type?.name}
                                </span>
                                <span className="accordion__element-parent-button-count">
                                    ({type?.specialists_count})
                                </span>
                            </>
                        </LogoutModalButton>
                    ) : (
                        <CreateOrderModal 
                            entities={entities}
                            category={category}
                            type={type}
                            classes="accordion__element-parent-button"
                        >
                            <span className="accordion__element-parent-button-title">
                                {type?.name}
                            </span>
                            <span className="accordion__element-parent-button-count">
                                ({type?.specialists_count})
                            </span>
                        </CreateOrderModal>
                    )
                )
            }
            {isOpen && 
                <SubTypeItem 
                    category={category} 
                    type={type} 
                    subtypes={subtypes || []} 
                    entities={entities} 
                    isSpecialist={isSpecialist} 
                />
            }
        </div>
    );
};

export default TypeItem;

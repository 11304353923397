import React, { ReactNode } from "react";
import cn from "classnames";
import { toArray } from "../../../lib/converters";
import { InvalidFieldsProps } from "../Input";

interface Props {
    name?: string;
    label: string;
    value?: string | number;
    onClick?: any;
    checked?: boolean;
    children?: ReactNode;
    classes?: string;

    invalidFields?: InvalidFieldsProps;
    error?: any;
    required?: boolean;
}

const RadioInput: React.FC<Props> = ({
    name,
    label,
    value,
    onClick,
    checked,
    children,
    classes,
    invalidFields,
    error,
    required,
}) => {
    const flattenName =
        name && String(name)?.split(`]`)?.join(``)?.split(`[`)?.join(`.`);

    const textError =
        flattenName &&
        !!invalidFields &&
        invalidFields?.has(flattenName) &&
        error?.errors[flattenName] &&
        toArray(error?.errors[flattenName])?.join(`, `);

    return (
        <div
            className={cn("custom-toggle custom-toggle--radio", {
                "is-invalid":
                    !!invalidFields &&
                    flattenName &&
                    invalidFields?.has(flattenName),
                [classes]: classes,
            })}
        >
            <label>
                <input
                    checked={checked}
                    type="radio"
                    id={name}
                    name={name}
                    required={required}
                    value={value}
                    onChange={onClick}
                />
                <span className="custom-toggle__icon"></span>
                <span className="custom-toggle__label">{label}</span>
            </label>
            {children}
            {!!invalidFields &&
                flattenName &&
                invalidFields.has(flattenName) && (
                    <div className="custom-toggle__error">{textError}</div>
                )}
        </div>
    );
};

export default RadioInput;

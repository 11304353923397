import React, { useEffect, useState } from "react";
import SubMenu from "../../components/SubMenu";
import HeaderOrder from "./Header";
import { TranslationObject } from "../../models/translationModal";
import {
    endpoints,
    useCopyOrderMutation,
    useUpdateOrderMutation,
} from "../../store/orders/orders.api";
import { useNavigate, useParams } from "react-router-dom";
import { ProfilePayload } from "../../models/userModels";
import BaseComponent from "./BaseComponent";
import SelectSpecialists from "./SelectSpecialists";
import { util } from "../../store/orders/orders.api";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { useUserRole } from "../../hooks/useUserRole";
import Loader from "../../components/Loader";

interface Props {
    entities: TranslationObject;
    profile?: ProfilePayload;
}

const Order: React.FC<Props> = ({ entities, profile }) => {
    const [showSpecialistList, setShowSpecialistList] =
        useState<boolean>(false);
    const [selectedOrder, setSelectedOrder] = useState<number | null>(null);
    const { id } = useParams();
    const [updateStatus] = useUpdateOrderMutation();
    const [copyOrder] = useCopyOrderMutation();
    const {
        data: order,
        error,
        isLoading,
    } = endpoints.getOrder.useQuery({
        id,
    });
    const dispatch = useDispatch();
    const isDone = order?.status === "done";
    const isActive = order?.status === "active";
    const isDraft = order?.status === "draft";
    const isDeal = order?.status === "deal";
    const isArchive = order?.status === "archive";
    const status = order?.status;
    const isPrivate = order?.is_private;
    const hasExecutor = !!order?.executor;
    const statusProps = {
        isDone,
        isActive,
        isDraft,
        isDeal,
        isArchive,
    };
    const isSpecialist = profile?.user_role === "specialist";
    const navigate = useNavigate();
    const currentLanguage = getCurrentLanguage();
    const { role } = useUserRole();

    useEffect(() => {
        if (error) {
            navigate(
                `/${currentLanguage}/${profile?.user_role || role}/notfound`
            );
            return;
        }
        window.scrollTo({ top: 0 })
    }, [error]);

    const changeStatus = (body: {status: string, started_at?: string, ended_at?: string}) => {
        updateStatus({
            body: body,
            id: Number(id),
        });

        dispatch(util.invalidateTags(["Order"]));
    };

    const _copyOrder = async () => {
        const copiedOrder = await copyOrder({id});
        setSelectedOrder?.(copiedOrder?.data?.id);
    }

    if (isLoading) {
        return <Loader />;
    }

    return (
        <main>
            <SubMenu entities={entities} profile={profile} />
            <div className="page">
                <section
                    className={classNames("active-order", {
                        "active-order-draft": isDraft,
                        "closed-order-done": isDone,
                        "closed-order": isDeal,
                        "active-order-2": isActive,
                    })}
                >
                    <div className="container">
                        <HeaderOrder
                            changeStatus={_copyOrder}
                            entities={entities}
                            date={order?.execution_at}
                            {...statusProps}
                            isSpecialist={isSpecialist}
                            status={status}
                            title={order?.title}
                            order={order}
                            hasExecutor={hasExecutor}
                            userId={profile?.id}
                            showSpecialistList={showSpecialistList}
                            handleClickDetailOrderButton={(value) =>
                                setShowSpecialistList(value)
                            }
                            selectedOrder={selectedOrder}
                        />
                        {!showSpecialistList && (
                            <>
                                <BaseComponent
                                    {...statusProps}
                                    changeStatus={changeStatus}
                                    isPrivate={isPrivate}
                                    entities={entities}
                                    order={order}
                                    isSpecialist={isSpecialist}
                                    profile={profile}
                                    setShowSpecialistList={
                                        setShowSpecialistList
                                    }
                                />
                            </>
                        )}
                        {showSpecialistList && (
                            <SelectSpecialists
                                entities={entities}
                                order={order}
                                isPrivate={isPrivate}
                                isSpecialist={isSpecialist}
                            />
                        )}
                    </div>
                </section>
            </div>
        </main>
    );
};
export default Order;

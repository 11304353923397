import { RootState } from "../index";
import { createSelector } from "@reduxjs/toolkit";

export const showAddCardModalSelector = (state: RootState) =>
    state.finances.showAddCardModal;

export const selectEmployeeSelector = (state: RootState) =>
    state.finances.selectEmployee;

export const currentPageSelector = (state: RootState) =>
    state.finances.currentPage;

export const dateFilterFromSelector = (state: RootState) =>
    state.finances.dateFilterFrom;

export const dateFilterToSelector = (state: RootState) =>
    state.finances.dateFilterTo;

export const specialistRoleSelector = (state: RootState) =>
    state.finances.specialistRole;

export const datesFilterSelector = createSelector(
    [dateFilterFromSelector, dateFilterToSelector],
    (dateFrom, dateTo) => {
        return {
            dateFrom,
            dateTo,
        };
    }
);

export const paymentsSelector = createSelector(
    [
        selectEmployeeSelector,
        currentPageSelector,
        dateFilterFromSelector,
        dateFilterToSelector,
    ],
    (selectEmployee, currentPage, dateFrom, dateTo) => {
        return {
            selectEmployee,
            currentPage,
            dateFrom,
            dateTo,
        };
    }
);

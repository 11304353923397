import React from "react";
import Icon from "../Icon";
import cn from "classnames";
import { TranslationObject } from "../../models/translationModal";

const Pagination: React.FC<{
    pages: number[];
    changeCurrentPage: (value: number) => void;
    currentPage: number;
    entities?: TranslationObject
}> = ({ pages, changeCurrentPage, currentPage, entities }) => {
    const showNextPage = () => {
        const page = currentPage + 1;
        if (pages?.length >= page) {
            changeCurrentPage(page);
            window.scrollTo(0, 0);
        }
    };

    const showPrevPage = () => {
        const page = currentPage - 1;
        if (page > 0) {
            changeCurrentPage(page);
            window.scrollTo(0, 0);
        }
    };

    return (
        <ul className="pagination">
            {currentPage > 1 && (
                <li className="pagination__item pagination__item--prev">
                    <div
                        onClick={showPrevPage}
                        className="pagination__link pagination__link--prev"
                    >
                        {entities?.common_back_button?.value || ''}
                        <Icon
                            type="arrow-right-pagination"
                            width={9}
                            height={18}
                        />
                    </div>
                </li>
            )}

            {pages.map((page, key) => (
                <li
                    className="pagination__item"
                    onClick={() => changeCurrentPage(page)}
                    key={key}
                >
                    <a
                        className={cn("pagination__link", {
                            ["is-active"]: page === currentPage,
                        })}
                        href="#"
                        aria-label="Первая страница"
                    >
                        {page}
                    </a>
                </li>
            ))}
            {pages?.length > 0 && pages?.length > currentPage && (
                <li className="pagination__item pagination__item--next">
                    <div
                        className="pagination__link pagination__link--next"
                        onClick={showNextPage}
                    >
                        {entities?.common_more?.value || ''}
                        <Icon
                            type="arrow-right-pagination"
                            width={9}
                            height={18}
                        />
                    </div>
                </li>
            )}
        </ul>
    );
};

export default Pagination;

import React from "react";
import { Category, SubTypesPayload, TypesPayload } from "../../models/directoryModel";
import LogoutModalButton from "../../components/LogoutModalButton/LogoutModalButton";
import CreateOrderModal from "../../components/Modals/CreateOrderModal/CreateOrderModal";
import { TranslationObject } from "../../models/translationModal";

const SubTypeItem: React.FC<{
    category: Category;
    type: TypesPayload;
    subtypes: SubTypesPayload[];
    entities?: TranslationObject;
    isSpecialist?: boolean;
}> = ({ subtypes, entities, category, type, isSpecialist = false }) => {
    return (
        <div className="accordion">
            {isSpecialist && subtypes?.map((subtype, key) => (
                <div
                    key={key + "subtype"}
                    className="accordion__element accordion__element-parent"
                >
                    <LogoutModalButton 
                        isSpecialist={isSpecialist}
                        entities={entities}
                        classes={["accordion__element-parent-button"]}
                    >
                        <>
                            <span className="accordion__element-parent-button-title">
                                {subtype?.name}
                            </span>
                            <span className="accordion__element-parent-button-count">
                                ({subtype?.specialists_count})
                            </span>
                        </>
                    </LogoutModalButton>
                </div>
            ))}
            {!isSpecialist && subtypes?.map((subtype, key) => (
                <div
                    key={key + "subtype"}
                    className="accordion__element accordion__element-parent"
                >
                    <CreateOrderModal 
                        entities={entities}
                        category={category}
                        type={type}
                        subtype={subtype}
                        classes="accordion__element-parent-button"
                    >
                        <span className="accordion__element-parent-button-title">
                            {subtype?.name}
                        </span>
                        <span className="accordion__element-parent-button-count">
                            ({subtype?.specialists_count})
                        </span>
                    </CreateOrderModal>
                </div>
            ))}
        </div>
    );
};

export default SubTypeItem;

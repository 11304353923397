import React, { useEffect, useState } from "react";
import Input, { InvalidFieldsProps } from "../../../../components/Form/Input";
import { ICompany } from "../../../../models/userModels";

interface CompanyDataProps {
    company?: ICompany | null;
    invalidFields?: InvalidFieldsProps;
    error?: any;
}

export const CompanyData: React.FC<CompanyDataProps> = ({
    company,
    invalidFields,
    error,
}) => {
    const {
        registration_number,
        country,
        address,
        name,
        tax_number,
        vat_number,
    } = company || {};

    const [companyCountry, setCompanyCountry] = useState<string>("");

    const [companyAddress, setCompanyAddress] = useState<string>("");

    const [companyName, setCompanyName] = useState<string>("");

    useEffect(() => {
        if (country) {
            setCompanyCountry(country);
        }
        if (address) {
            setCompanyAddress(address);
        }
        if (name) {
            setCompanyName(name);
        }
    }, [country, address, name]);

    return (
        <>
            <div className="profile__fieldset">
                <fieldset>
                    <legend>Данные компании</legend>
                    <div className="form__row">
                        <Input
                            disabled={true}
                            type="number"
                            name="registration_number"
                            value={registration_number}
                            id="registration_number"
                            invalidFields={invalidFields}
                            error={error}
                            placeholder={"Регистрационный номер"}
                        />
                    </div>
                    <div className="form__row">
                        <Input
                            type="text"
                            name="country"
                            value={companyCountry}
                            onChange={setCompanyCountry}
                            id="country"
                            invalidFields={invalidFields}
                            error={error}
                            placeholder={"Страна регистрации компании"}
                        />
                    </div>
                    <div className="form__row">
                        <Input
                            type="text"
                            name="address"
                            value={companyAddress}
                            onChange={setCompanyAddress}
                            id="address"
                            invalidFields={invalidFields}
                            error={error}
                            placeholder={"Юридический адрес компании"}
                        />
                    </div>
                    <div className="form__row">
                        <Input
                            type="text"
                            name="name"
                            value={companyName}
                            onChange={setCompanyName}
                            id="name"
                            invalidFields={invalidFields}
                            error={error}
                            placeholder={"Название компании"}
                        />
                    </div>
                    <div className="form__row">
                        <Input
                            disabled={true}
                            type="number"
                            name="tax_number"
                            value={tax_number}
                            onChange={() => null}
                            id="tax_number"
                            invalidFields={invalidFields}
                            error={error}
                            placeholder={"Налоговый номер"}
                        />
                    </div>
                    <div className="form__row">
                        <Input
                            disabled={true}
                            type="number"
                            name="vat_number"
                            value={vat_number}
                            id="vat_number"
                            invalidFields={invalidFields}
                            error={error}
                            placeholder={"VAT-номер"}
                        />
                    </div>
                </fieldset>
            </div>
        </>
    );
};

import { advertisementEndpoints } from "../../../../store/advertisement/advertisement.api";
import { useEffect, useState } from "react";
import {
    ICompanyEmployee,
    IMyAdvertisement,
} from "./useAdvertisementData.types";

export const useAdvertisementData = (profileId?: number) => {
    const {
        data: advertisementPaidListData,
        isSuccess: isSuccessAdvertisementPaidList,
    } = advertisementEndpoints.getAdvertisementPaidList.useQuery();

    const [myBanner, setMyBanner] = useState<IMyAdvertisement>({
        type: "banner",
        status: null,
        startsAt: null,
        endsAt: null,
    });

    const [myTop, setMyTop] = useState<IMyAdvertisement>({
        type: "top",
        status: null,
        startsAt: null,
        endsAt: null,
    });

    const [myEmail, setMyEmail] = useState<IMyAdvertisement>({
        type: "email",
        status: null,
        startsAt: null,
        endsAt: null,
    });

    const [companyEmployees, setCompanyEmployees] = useState<
        ICompanyEmployee[]
    >([]);

    useEffect(() => {
        if (
            isSuccessAdvertisementPaidList &&
            advertisementPaidListData?.length &&
            profileId
        ) {
            const newCompanyEmployees = advertisementPaidListData?.reduce(
                (acc: ICompanyEmployee[], item) => {
                    if (item?.specialist?.id !== profileId) {
                        if (acc.find((el) => el.id === item?.specialist?.id)) {
                            return acc.map((el) => {
                                if (el.id === item?.specialist?.id) {
                                    return {
                                        ...el,
                                        [item?.type]: {
                                            status: item?.status,
                                            startsAt: item?.starts_at || null,
                                            endsAt: item?.ends_at || null,
                                        },
                                    };
                                }
                                return el;
                            });
                        } else {
                            return [
                                ...acc,
                                {
                                    id: item?.specialist?.id,
                                    name: item?.specialist?.name,
                                    familyName: item?.specialist?.family_name,
                                    [item?.type]: {
                                        status: item?.status,
                                        startsAt: item?.starts_at || null,
                                        endsAt: item?.ends_at || null,
                                    },
                                },
                            ];
                        }
                    } else if (
                        item?.specialist?.id === profileId &&
                        item?.type === "banner"
                    ) {
                        setMyBanner({
                            type: "banner",
                            status: item?.status || null,
                            startsAt: item?.starts_at || null,
                            endsAt: item?.ends_at || null,
                        });
                    } else if (
                        item?.specialist?.id === profileId &&
                        item?.type === "top"
                    ) {
                        setMyTop({
                            type: "top",
                            status: item?.status || null,
                            startsAt: item?.starts_at || null,
                            endsAt: item?.ends_at || null,
                        });
                    } else if (
                        item?.specialist?.id === profileId &&
                        item?.type === "email"
                    ) {
                        setMyEmail({
                            type: "email",
                            status: item?.status || null,
                            startsAt: item?.starts_at || null,
                            endsAt: item?.ends_at || null,
                        });
                    }

                    return acc;
                },
                []
            );
            setCompanyEmployees(newCompanyEmployees);
        }
    }, [isSuccessAdvertisementPaidList, advertisementPaidListData, profileId]);

    return {
        myBanner,
        myTop,
        myEmail,
        companyEmployees,
    };
};

import React, { useEffect } from "react";
import { useActions } from "../../hooks/actions";
import {
    getArrayToObject,
    getCustomPage,
    getDirectories
} from "../../store/directories/selectors";
import { endpoints } from "../../store/directories/directories.api";
import CategoryItem from "./CategoryItem";
import { TranslationObject } from "../../models/translationModal";
import { scrollTop } from "../../untils";
import { ProfilePayload } from "../../models/userModels";
import HeaderMeta from "../../components/HeaderMeta/HeaderMeta";

const ServiceCatalog: React.FC<{
    entities?: TranslationObject;
    profile?: ProfilePayload;
    currentLanguage: string;
}> = ({ entities, profile, currentLanguage }) => {
    const { setHeaderStateSearch } = useActions();
    const { data: categories } = endpoints.getServiceCategories.useQuery();
    const HEADER_ID = 12;
    const isSpecialist = profile?.user_role === "specialist";
    const { data: pages } = getDirectories();
    const result = getCustomPage({ id: HEADER_ID });
    const headerData = result.data;

    useEffect(() => {
        scrollTop();
    }, []);

    useEffect(() => {
        if (headerData && headerData?.blocks) {
            const headerBlocks = getArrayToObject(headerData?.blocks || []);
            setHeaderStateSearch(headerBlocks?.header_search_button);
        }
    }, [headerData]);

    return (
        <main>
            <HeaderMeta 
                currentLanguage={currentLanguage}
                customPages={pages?.custom_pages || []}
                textPages={pages?.text_pages || []}
            />
            <section className="catalog-page">
                <div className="container">
                    <h1 className="title title--h1">
                        {entities?.service_catalog_link_value?.value}
                    </h1>
                    <div className="catalog-page__accordion">
                        <div className="accordion">
                            {categories?.map((category, key) => (
                                <CategoryItem 
                                    key={key}
                                    category={category}
                                    entities={entities}
                                    isSpecialist={isSpecialist}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default ServiceCatalog;

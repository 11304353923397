import React, { useState } from "react";
import PageLoaders from "../../components/Loaders/PageLoaders";
import { ComponentProps } from "../../models/models";
import Heading from "../../components/Heading";
import { reduce } from "lodash";
import { AboutPagePayload, Block } from "../../models/aboutPageModel";
import Question from "./Question";
import backgroundGradientSpecialist from "../../img/content/main-page/background-gradient-specialist.svg";
import Advertising from "../../components/Advertising";
import { getTkn } from "../../store/user/selectors";

const Service: React.FC<ComponentProps> = ({
    currentLanguage,
    isCustomerOnUrl,
    entities,
    profile
}) => {
    const [page, setPage] = useState<AboutPagePayload | null>(null);
    const tkn = getTkn();
    const isCustomer = profile?.user_role === "customer" && tkn;
    const isSpecialist = !isCustomer && !isCustomerOnUrl;

    const blocks = reduce(
        page?.blocks,
        (blocks: any, block: Block) => {
            blocks[block?.code] = block;
            return blocks;
        },
        {}
    );

    const code =
        isCustomer || isCustomerOnUrl
            ? "customer_service_page"
            : "specialist_service_page";

    return (
        <PageLoaders
            isCustomPage
            updateData={setPage}
            type="custom_pages"
            code={code}
            isNeedPage
            currentLanguage={currentLanguage}
        >
            <main>
                <div className="page about-service-page">
                    <div className="page__container container">
                        <Heading
                            className="promo-about page__promo"
                            img={blocks?.main_block_image?.media}
                            titleType={blocks?.main_block_title?.type}
                            title={blocks?.main_block_title?.value}
                            text={blocks?.main_block_text?.value}
                            background={
                                !isCustomer &&
                                !isCustomerOnUrl &&
                                backgroundGradientSpecialist
                            }
                            isSpecialist={isSpecialist}
                        />
                        <section className="accordion">
                            <div className="page__grid">
                                <div className="questions-about">
                                    <div
                                        className="title title--f46"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                blocks?.faq_block_title
                                                    ?.value || ""
                                        }}
                                    />

                                    {blocks?.faq_question_block_table?.value?.map(
                                        (
                                            elem: Record<string, string>,
                                            key: number
                                        ) => (
                                            <Question
                                                key={key}
                                                isCustomer={isCustomerOnUrl}
                                                answer={elem?.answer}
                                                question={elem?.question}
                                            />
                                        )
                                    )}
                                </div>
                                <div className="specialists__wrapper no-scrollbar specialists__wrapper--service">
                                    <h2 className="visually-hidden">
                                        {
                                            entities?.finances_all_specialists
                                                ?.value
                                        }
                                    </h2>
                                    <Advertising
                                        typeAdvertising={
                                            isCustomerOnUrl
                                                ? "specialists"
                                                : "thirdParties"
                                        }
                                        isCustomerPage={true}
                                        entities={entities}
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </main>
        </PageLoaders>
    );
};

export default Service;

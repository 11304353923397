import React, { useEffect, useState } from "react";
import Form from "../../components/Form/Form";
import { OrderStepsProps } from "./index";
import { useNavigate } from "react-router-dom";
import {
    REPEAT_DAY,
    REPEAT_RANGE,
    SERVICE_CATEGORY_NAME,
    SERVICE_TYPE_NAME,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_SUB_TYPE_NAME
} from "../../store/order/constant";
import {
    getDays,
    getItemCreateOrder,
    getWeekdays,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import RepeatOrderСomponent from "../../components/RepeatOrder";
import { createOrderState } from "../../store/order/createOrder.slice";
import { useActions } from "../../hooks/actions";
import Advertising from "../../components/Advertising";
import CancelOrderButton from "./Specialists/CancelOrderButton";
import { scrollTop } from "../../untils";
import Breadcrumbs from "../../components/Breadcrumbs";

const RepeatOrder: React.FC<OrderStepsProps> = ({
    entities,
    currentLanguage,
    profile,
    isSuccess,
    onSuccess,
    onSubmit,
    error,
}) => {
    const navigate = useNavigate();
    const repeatRange = getItemCreateOrder(REPEAT_RANGE);
    const repeat_day = getItemCreateOrder(REPEAT_DAY);
    const [repeatDay, setRepeatDay] = useState<{
        value: number;
        label: number | string;
    } | null>(null);
    const isDisable =
        !repeatRange ||
        (repeatRange === "repeat_week_day" && !repeatDay) ||
        (repeatRange === "repeat_month_day" && !repeatDay);

    const days = getDays();
    const weekItems = getWeekdays(currentLanguage);
    const weekdays = weekItems?.map((day, key) => ({
        value: key + 1,
        label: day,
    }));
    const serviceSubTypesIdsLength = getItemCreateOrder(SERVICE_SUB_TYPE_IDS)?.length;

    const { updateCreateOrderField } = useActions();
    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) =>
        updateItemCreateOrder(
            {
                name: name,
                value: value,
            },
            updateCreateOrderField
        );

    useEffect(() => {
        if (repeatRange === "repeat_week_day") {
            setRepeatDay(
                weekdays?.find((elem) => elem?.value == repeat_day) ||
                    weekdays[0]
            );
        }
        if (repeatRange === "repeat_month_day") {
            setRepeatDay(
                days.find((elem) => elem?.value == repeat_day) || days[0]
            );
        }
    }, []);

    const handleChange = (value: string) => {
        updateField({ name: REPEAT_DAY, value: null });
        if (value === "repeat_week_day") {
            setRepeatDay(weekdays[0]);
            updateField({ name: REPEAT_DAY, value: weekdays[0]?.value });
        }
        if (value === "repeat_month_day") {
            setRepeatDay(days[0]);
            updateField({ name: REPEAT_DAY, value: days[0]?.value });
        }
        updateField({ name: REPEAT_RANGE, value });
    };

    const _back = () => {
        navigate(`/${currentLanguage}/customer/create-order/when-to-start`);
        scrollTop()
    };

    const handleChangeRangeDay = (value: any) => {
        setRepeatDay(value);
        updateField({ name: REPEAT_DAY, value: value?.value });
    };

    const _onSuccess = () => {
        onSuccess("wishes");
    };

    const _onSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        onSubmit(e, "wishes");
    };

    useEffect(() => {
        if (isSuccess) {
            _onSuccess();
        }
    }, [isSuccess]);

    const crumbs = [
        {
            name: getItemCreateOrder(SERVICE_CATEGORY_NAME) || entities?.service_task?.value,
            link: 'create-order'
        },
        {
            name: getItemCreateOrder(SERVICE_TYPE_NAME) || entities?.type_of_service_2?.value,
            link: 'create-order/type'
        },
        {
            name: getItemCreateOrder(SERVICE_SUB_TYPE_NAME) || serviceSubTypesIdsLength && `${entities?.subtype_of_service_2?.value} (${serviceSubTypesIdsLength})` || entities?.subtype_of_service_2?.value,
            link: 'create-order/subtype'
        },
        {
            name: entities?.choosing_a_specialist?.value,
            link: 'create-order/meeting-point'
        },
        {
            name: entities?.date_and_time?.value,
            link: 'create-order/when-to-start'
        },
        {
            active: true,
            name: entities?.periodicity?.value
        }
    ]

    return (
        <div className="inner-container">
            <div className="new-order__wrapper">
                <Breadcrumbs crumbs={crumbs}/>
                <div className="new-order__header">
                    <h2 className="title title--f46">
                        {entities?.order_periodicity?.value}
                    </h2>
                    <CancelOrderButton profile={profile} entities={entities} />
                </div>
                <div className="new-order__form form">
                    <Form onSubmit={_onSubmit} isSuccess={isSuccess}>
                        {() => (
                            <>
                                <div className="how-often">
                                    <RepeatOrderСomponent
                                        entities={entities}
                                        handleChangeRangeDay={
                                            handleChangeRangeDay
                                        }
                                        handleChange={handleChange}
                                        repeatDay={repeatDay}
                                        repeatRange={repeatRange}
                                    />
                                </div>
                                <div className="form__row form__controls">
                                    <CancelOrderButton
                                        profile={profile}
                                        entities={entities}
                                    />
                                    <button
                                        className="btn form__back-link btn--transparent"
                                        type="button"
                                        onClick={_back}
                                    >
                                        {entities?.common_back_button?.value}
                                    </button>
                                    <button
                                        className="btn btn--transparent btn--bg-blue"
                                        type="submit"
                                        disabled={isDisable}
                                    >
                                        {entities?.login_continue_button?.value}
                                    </button>
                                </div>
                            </>
                        )}
                    </Form>
                </div>
            </div>
            <ul className="advertising-list create-order">
                <Advertising countCards={5} entities={entities} isHorizontal={true} />
            </ul>
        </div>
    );
};
export default RepeatOrder;

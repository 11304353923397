import React, { useState, useMemo, useEffect } from "react";
import classNames from "classnames";

import { TranslationObject } from "../../../models/translationModal";
import { getFilter } from "../../../store/orders/selectors";
import { useActions } from "../../../hooks/actions";
import { endpoints } from "../../../store/orders/orders.api";
import { getCurrentLanguage } from "../../../store/directories/selectors";

interface Props {
    entities?: TranslationObject;
    isSpecialist: boolean;
    isSuspendedUser: boolean;
}

const FiltersControl: React.FC<Props> = ({
    entities,
    isSpecialist,
    isSuspendedUser,
}) => {
    const { setOrdersFilter } = useActions();
    const name = "status";
    const status = getFilter(name);
    const currentLanguage = getCurrentLanguage();

    const [getOrdersStatusesList, { data: ordersStatusesList }] =
        endpoints.getOrdersStatusesList.useLazyQuery();

    useEffect(() => {
        getOrdersStatusesList();
    }, [])

    const initialControls = [
        { name: entities?.order_list_status_all?.value || "", code: "all" },
        {
            name: entities?.order_list_status_active?.value || "",
            code: "active",
        },
        { name: entities?.order_list_status_deals?.value || "", code: "deal" },
        {
            name: entities?.order_list_status_completed?.value || "",
            code: "done",
        },
        {
            name: entities?.order_list_status_archive?.value || "",
            code: "archive",
        },
        { name: entities?.order_list_status_draft?.value || "", code: "draft" },
    ];

    const [controls, setControls] =
        useState<{ name: string; code: string }[]>(initialControls);

    useMemo(() => {
        if (isSuspendedUser) {
            setControls((prevState) => prevState.slice(0, 1));
            return;
        }

        if (isSpecialist) {
            const items = initialControls.filter(
                (elem) => elem.code !== "draft" && elem.code !== "archive"
            );

            items.splice(1, 0, {
                name: entities?.order_list_status_new?.value || "New",
                code: "new",
            });

            setControls(items);
        } else {
            setControls(initialControls);
        }
    }, [isSpecialist, isSuspendedUser, currentLanguage, entities]);

    const handleClick = (value: string) => {
        return setOrdersFilter({
            name,
            value,
        });
    };

    const isShowCounter = (code: string) => {
        return ["active", "deal", "new"].includes(code);
    }

    return (
        <div className="list-orders__receipt">
            <div className="receipt">
                <ul className="receipt__list">
                    {controls?.map((elem, key) => (
                        <li
                            key={elem?.code + key}
                            className="receipt__item"
                            onClick={() => handleClick(elem?.code)}
                        >
                            <div
                                key={elem?.code}
                                className={classNames("btn receipt__link", {
                                    "receipt__link--current":
                                        status === elem?.code,
                                    "receipt__link--amount":
                                        isShowCounter(elem?.code),
                                })}
                            >
                                {elem?.name}
                            </div>
                            {isShowCounter(elem?.code) && (
                                <span className="receipt__amount receipt__amount--current">
                                    {ordersStatusesList?.[elem?.code] || 0}
                                </span>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default React.memo(FiltersControl)

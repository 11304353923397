import uniq from "lodash/uniq";
import flatten from "lodash/flatten";

import { toArray } from "../lib/converters";
import SiteAPI from "./SiteAPI";

class Validation {
    status: number;
    message: string;
    errors: any;
    flatErrors: any;
    fields: any;

    constructor(response: any) {
        this.status = 422;
        this.message = response.data.description;
        this.errors = response.data.payload.messages;
        this.flatErrors = uniq(flatten(toArray(this.errors)));
        this.fields = new Set(
            flatten(
                Object.keys(this.errors || {}).map((name) => [
                    name,
                    name?.split(`.`)[0],
                ])
            )
        );
        Object.setPrototypeOf(this, SiteAPI.prototype);
    }
}

export default Validation;

import React from "react";
import { TranslationObject } from "../../../models/translationModal";
import { useNavigateProfileSpecialist } from "../../../pages/ProfileSpecialist/hooks";
import { getCurrentLanguage } from "../../../store/directories/selectors";

interface ProfileButtonsFormProps {
    entities?: TranslationObject;
    updateFields: () => unknown;
    isNotDisabledCansel: boolean;
    isSpecialistOnUrl?: boolean;
    isCustomerOnUrl?: boolean;
    historyBackHandler?: () => unknown;
}

export const ProfileButtonsForm: React.FC<ProfileButtonsFormProps> = (
    props
) => {
    const {
        updateFields,
        entities,
        isNotDisabledCansel,
        isCustomerOnUrl,
        isSpecialistOnUrl,
        historyBackHandler,
    } = props || {};

    const currentLanguage = getCurrentLanguage()
    const navigateTo = useNavigateProfileSpecialist(currentLanguage);

    return (
        <>
            <div className="profile__buttons-form">
                <button
                    className="btn profile__button"
                    type="button"
                    disabled={isCustomerOnUrl && isNotDisabledCansel}
                    onClick={() => {
                        if (isNotDisabledCansel && isSpecialistOnUrl) {
                            return historyBackHandler
                                ? historyBackHandler()
                                : navigateTo("");
                        }
                        updateFields();
                    }}
                >
                    {entities?.common_cancel_button?.value}
                </button>
                <button
                    className="btn profile__button profile__button--color"
                    type="submit"
                    disabled={isNotDisabledCansel}
                >
                    {entities?.common_save_button?.value}
                </button>
            </div>
        </>
    );
};

import Avatar from "../../Avatar";
import { getRoleCompanyUser } from "../../../store/user/selectors";
import LanguagesSpecialist from "../Languages";
import React, { useEffect, useState } from "react";
import { TranslationObject } from "../../../models/translationModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ServiceType } from "../../../models/orderModel";
import { SpecialistPayload } from "../../../models/userModels";
import { SpecialistPageTranslatePayload } from "../../../models/reviewModel";
import {
    checkIsMobile,
    checkIsTablet,
} from "../../../store/directories/selectors";

interface Props {
    user?: SpecialistPayload;
    entities?: TranslationObject;
    defaultLink?: string;
    details: any;
    handleClickTranslatePage?: () => unknown;
    pageTranslate?: SpecialistPageTranslatePayload;
    isShowBackLink?: boolean;
}

const HeaderSpecialist: React.FC<Props> = ({
    user,
    entities,
    defaultLink,
    details,
    handleClickTranslatePage,
    pageTranslate,
    isShowBackLink = true
}) => {
    const name = user?.name;
    const avatar = user?.avatar;
    const textTranslateButton = entities?.common_translate_page?.value;
    const shortListLanguages = Array.isArray(user?.languages)
        ? user?.languages?.slice(0, 3)
        : [];
    const isRemote = details?.services?.some(
        (elem: ServiceType) => elem?.is_remote
    );
    const isSpecialistAddress = details?.services?.some(
        (elem: ServiceType) =>
            elem?.specialist_address?.latitude &&
            elem?.specialist_address?.longitude
    );
    const isCustomerAddress = details?.services?.some(
        (elem: ServiceType) =>
            elem?.customer_address?.latitude &&
            elem?.customer_address?.longitude
    );

    const navigate = useNavigate();
    const titleLanguages =
        entities?.common_communication_languages?.value + ": ";
    const titleMeeting = entities?.common_meeting_place?.value;
    const isMobile = checkIsMobile();
    const isTablet = checkIsTablet();
    const [link, setLink] = useState('')
    const location = useLocation()

    useEffect(() => {
        if (defaultLink) {
            setLink(defaultLink)
        }
    }, [defaultLink])

    useEffect(() => {
        if (location.state) {
            setLink(location.state)
        }
    }, [location.state])

    let avatarSize = 133;

    if (isMobile) {
        avatarSize = 64;
    }
    if (isTablet) {
        avatarSize = 100;
    }

    return (
        <div className="specialist-card grey-card specialist-page__profile-card">
            <div className="specialist-card__header">
                <div className="specialist-card__title">
                    <h2 className="title title--h2">{name}</h2>
                </div>
                <div className="specialist-card__avatar avatar">
                    <Avatar
                        avatar={avatar}
                        width={avatarSize}
                        height={avatarSize}
                    />
                </div>
                <div className="specialist-card__controls">
                    <button
                        className="btn specialist-card__btn btn--transparent"
                        type="button"
                        onClick={handleClickTranslatePage}
                    >
                        {textTranslateButton}
                    </button>
                    {
                        isShowBackLink &&
                            <Link
                                to={link}
                                className="btn specialist-card__btn btn--transparent"
                                type="button"
                            >
                                {entities?.common_back_button?.value}
                            </Link>
                    }
                </div>
            </div>
            <div className="specialist-card__info">
                {!!user?.company &&
                    <div className="specialist-card__column prof-services">
                        <h3 className="title title--h4">
                            {getRoleCompanyUser({
                                specialist: user,
                                entities,
                            })}
                        </h3>
                        {user?.company?.insurance_company_name && (
                            <ul className="prof-services__list">
                                <li className="prof-services__item">
                                    {
                                        entities?.specialist_details_has_insurance
                                            ?.value
                                    }
                                </li>
                                <li className="prof-services__item">
                                    {pageTranslate?.insurance_company_name ||
                                        user?.company?.insurance_company_name}{" "}
                                    {pageTranslate?.insurance_max_payment_amount ||
                                        user?.company
                                            ?.insurance_max_payment_amount ||
                                        ""}
                                </li>
                            </ul>
                        )}
                    </div>
                }
                {(isRemote || isSpecialistAddress || isCustomerAddress) &&
                    <div className="specialist-card__column prof-services">
                        <h3 className="title title--h4">{titleMeeting}</h3>
                        <ul className="prof-services__list">
                            {isRemote && (
                                <li className="prof-services__item">
                                    {entities?.common_remotely?.value}
                                </li>
                            )}
                            {isSpecialistAddress && (
                                <li className="prof-services__item">
                                    {entities?.common_specialist_adress?.value}
                                </li>
                            )}
                            {isCustomerAddress && (
                                <li className="prof-services__item">
                                    {entities?.common_customer_adress?.value}
                                </li>
                            )}
                        </ul>
                    </div>
                }
                {(!!shortListLanguages?.length) &&
                    <div className="languages languages--big specialist-card__column">
                        <h3 className="title title--h4">{titleLanguages}</h3>
                        <div className="languages__wrapper">
                            <LanguagesSpecialist
                                shortListLanguages={shortListLanguages || []}
                                languages={user?.languages || []}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default HeaderSpecialist;

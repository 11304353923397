import React, { useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import ExecutionDate from "./ExecutionDate";
import { TranslationObject } from "../../../models/translationModal";
import Status from "../../../components/Status";
import { convertDateToDDMMYYYY } from "../../../untils";
import { OrderItem } from "../../../models/ordersModel";
import { getRepeatString } from "../../../store/orders/selectors";
import EditOrderButton from "../../../components/Buttons/EditOrderButton";
// import CopyOrderButton from "../../../components/Buttons/CopyOrderButton";
import CopyOrderButton2 from "../../../components/Buttons/CopyOrderButton2";
// import SendToDraftButton from "../../../components/Buttons/SendToDraftOrderButton";
// import DetailOrderButton from "./DetailOrderButton";
// import BackOrderButton from "./BackOrderButton";
import DateType from "./DateType";
import cn from "classnames";
import useClickOutside from "../../../hooks/useClickOutside";
import Icon from "../../../components/Icon";
import CloseOrderButton from "../../../components/Buttons/CloseOrderButton";
import Modal from "../../../components/Modal";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import { useUserRole } from "../../../hooks/useUserRole";

interface Props {
    entities: TranslationObject;
    date: string;
    status: string;
    isSpecialist: boolean;
    isDeal: boolean;
    isDraft: boolean;
    isActive: boolean;
    isDone: boolean;
    isArchive: boolean;
    title: string;
    order: OrderItem;
    userId?: number;
    hasExecutor: boolean;
    changeStatus: (value: string) => void;
    classes?: string;
    handleClickDetailOrderButton: (value: boolean) => void;
    showSpecialistList: boolean;
    isChatPage?: boolean;
    selectedOrder?: number | null;
}

const HeaderOrder: React.FC<Props> = ({
    entities,
    isDeal,
    isDone,
    isDraft,
    isArchive,
    isActive,
    isSpecialist,
    status,
    title,
    order,
    userId,
    hasExecutor,
    changeStatus,
    classes,
    handleClickDetailOrderButton,
    showSpecialistList,
    isChatPage,
    selectedOrder,
}) => {
    const statuses = {
        isDeal,
        isDone,
        isDraft,
        isArchive,
        isActive,
    };

    const started_at =
        order?.started_at && convertDateToDDMMYYYY(order?.started_at);
    const ended_at =
        order?.ended_at && `- ${convertDateToDDMMYYYY(order?.ended_at)}`;

    const hasSpecialists = order?.responded_specialist_ids?.length > 0;
    const isCustomerChat = !isSpecialist && isChatPage
    const isCustomerNotChat = !isSpecialist && !isChatPage
    const isShowExitChatButton = showSpecialistList || isCustomerChat

    const [showModal, setShowModal] = useState<string>('');
    const [isOpen, setIsOpen] = useState(false);
    const $collapse = useRef<HTMLDivElement>(null);
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(true);
    };
    useClickOutside($collapse, () => setIsOpen(false));

    const { role } = useUserRole(isSpecialist ? "specialist" : "customer");
    const currentLanguage = getCurrentLanguage();
    const navigate = useNavigate();
    const back = () => navigate(-1);
    const linkToOrder = `/${currentLanguage}/${role}/lk/order/${selectedOrder}`;

    return (
        <div
            className={cn("order-information", {
                [`${classes}`]: !!classes,
            })}
        >
            <div className="order-information__header">
                <Status
                    entities={entities}
                    status={status}
                    isSpecialist={isSpecialist}
                    isResponded={!!order?.response?.created_at}
                    isDeal={isDeal}
                    isDraft={isDraft}
                    isActive={isActive}
                    isDone={isDone}
                    isArchive={isArchive}
                    hasExecutor={hasExecutor}
                    className="order-information__status"
                />
                <div className="order-information__buttons">
                    {isCustomerNotChat ? (<div
                        className="btn order-information__button order-information__button--dotts"
                        onClick={(e) => handleClick(e)}
                    >
                        <div
                            className={cn("dotted-menu", {
                                "is-active": isOpen,
                            })}
                            ref={$collapse}
                        >
                            <Icon
                                type="dotts"
                                width={24}
                                height={24}
                            />
                            <ul className="dotted-menu__list">
                                <CloseOrderButton
                                    entities={entities}
                                    isDraft={isDraft}
                                    isDropDown
                                    isPrimary={false}
                                    isActive={isActive}
                                    changeStatus={changeStatus}
                                />
                                <CopyOrderButton2
                                    changeStatus={changeStatus}
                                    isActive={isActive}
                                    hasSpecialists={hasSpecialists}
                                    isDropDown
                                    entities={entities}
                                    isSpecialist={isSpecialist}
                                    setShowModal={setShowModal}
                                    order_id={order?.id}
                                />
                                <EditOrderButton
                                    entities={entities}
                                    isDraft={isDraft}
                                    isDropDown={false}
                                    order={order}
                                    isSpecialist={isSpecialist}
                                />
                            </ul>
                        </div>
                    </div>) : isShowExitChatButton ? (<button
                        onClick={() => handleClickDetailOrderButton(false)}
                        className="btn order-information__button order-information__button--dotts"
                    >{entities?.order_details?.value}</button>) : (<button
                        className="btn order-information__button order-information__button--dotts"
                        onClick={back}
                    >{entities?.common_back_button?.value}</button>)}
                </div>
                <h2 className="title title--h2">{title}</h2>
            </div>
            <ul className="order-information__list">
                <li className="order-information__item">
                    <p className="order-information__item-heading">
                        {entities?.order_number?.value}
                    </p>
                    <p className="order-information__item-text">
                        {order?.number}
                    </p>
                </li>
                <DateType order={order} entities={entities} {...statuses} />
                <li className="order-information__item">
                    <p className="order-information__item-heading">
                        {entities?.order_deadline?.value}
                    </p>
                    <p className="order-information__item-text">
                        {started_at} {ended_at}
                    </p>
                </li>
                <ExecutionDate
                    entities={entities}
                    date={order?.execution_at}
                    id={order?.id}
                    isSpecialist={isSpecialist}
                    isArchive={isArchive}
                />
                <li className="order-information__item">
                    <p className="order-information__item-heading">
                        {entities?.order_periodic?.value}
                    </p>
                    <p className="order-information__item-text">
                        {getRepeatString({
                            entities,
                            range: order?.repeat_range,
                            day: Number(order?.repeat_day),
                        })}
                    </p>
                </li>
            </ul>
            <Modal
                onClose={() => setShowModal('')}
                visible={showModal === 'draft'}
                description={entities?.order_copied?.value}
            >
                <Link
                    className="btn modal__content-button"
                    to={linkToOrder}
                    onClick={() => setShowModal('')}
                >
                    {entities?.order_go_to_copy?.value}
                </Link>
            </Modal>
        </div>
    );
};

export default HeaderOrder;

import React, { useState } from "react";
import { TranslationObject } from "../../../models/translationModal";
import { useActions } from "../../../hooks/actions";
import { useNavigate } from "react-router-dom";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import { getItemEditOrder } from "../../../store/order/selectors";
import OrderClosingConfirmation from "../../../components/OrderCard/OrderClosingConfirmation";

interface Props {
    entities?: TranslationObject;
    save: (value: string, id: number) => void;
    isPrivate: boolean;
    isLoading?: boolean;
}

const ButtonsEditOrder: React.FC<Props> = ({
    entities,
    save,
    isPrivate,
    isLoading,
}) => {
    const { clearEditOrderFields } = useActions();
    const currentLanguage = getCurrentLanguage();
    const id = getItemEditOrder("id");
    const navigate = useNavigate();
    const [showConfirmationClose, setShowConfirmationClose] = useState<boolean>(false)

    const undoEditing = () => {
        const saveId = id;
        clearEditOrderFields();
        navigate(`${currentLanguage}/customer/lk/order/${saveId}`);
    };


    const changeStatus = () => {
        save("archive", id)
        setShowConfirmationClose(false)
    }

    return (
        <div className="accordion-form__btns">
            <button
                className="btn btn btn--white btn--rounded"
                onClick={undoEditing}
                disabled={isLoading}
            >
                {entities?.common_cancel_button?.value}
            </button>
            {!isPrivate && (
                <button
                    disabled={isLoading}
                    className="btn btn btn--white btn--rounded"
                    onClick={() => id && save("draft", id)}
                >
                    {entities?.order_save_with_draft_status?.value}
                </button>
            )}
            <button
                className="btn btn btn--white btn--rounded"
                disabled={isLoading}
                onClick={() => setShowConfirmationClose(true)}
                type="button"
            >
                {entities?.order_close?.value}
            </button>
            <OrderClosingConfirmation
                visible={showConfirmationClose}
                onClose={() => setShowConfirmationClose(false)}
                entities={entities}
                changeStatus={changeStatus}
            />
            {!isPrivate && (
                <button className="btn btn btn--rounded btn--nb" type="submit">
                    {entities?.order_save_and_published?.value}
                </button>
            )}
            {isPrivate && (
                <button
                    className="btn btn btn--rounded btn--nb"
                    type="button"
                    onClick={() =>
                        navigate(
                            `/${currentLanguage}/customer/edit-order/select-specialists`
                        )
                    }
                >
                    {entities?.order_edit_select_specialists?.value}
                </button>
            )}
        </div>
    );
};

export default ButtonsEditOrder;

import React, { useEffect, useState } from "react";
import BaseContainerFilterMeetingPointOrder from "./BaseContainer";
import { TranslationObject } from "../../../../models/translationModal";
import {
    getItemCreateOrder,
    getItemEditOrder,
} from "../../../../store/order/selectors";
import {
    IS_MY_ADDRESS,
    IS_REMOTE,
    IS_SPECIALIST_ADDRESS,
    MY_ADDRESS,
    SPECIALIST_ADDRESS,
} from "../../../../store/order/constant";
import { useActions } from "../../../../hooks/actions";
import { locationProps } from "../../../../models/orderModel";

interface Props {
    entities?: TranslationObject;
}

type TypeAddress = Record<string, string>;

const FilterToEditOrder: React.FC<Props> = ({ entities }) => {
    const { updateEditOrderField } = useActions();
    const [showMyAddressModal, setShowMyAddressModal] = useState(false);
    const [showSpecialistAddressModal, setShowSpecialistAddressModal] =
        useState(false);
    const [currentAddresses, setCurrentAddresses] = useState<string[]>([]);
    const isMyAddress = getItemEditOrder(IS_MY_ADDRESS);
    const isSpecialistAddress = getItemEditOrder(IS_SPECIALIST_ADDRESS);
    const isRemote = getItemEditOrder(IS_REMOTE);
    const myLocation = getItemEditOrder(MY_ADDRESS);
    const specialistLocation = getItemEditOrder(SPECIALIST_ADDRESS);

    useEffect(() => {
        updateCurrentAddresses();
    }, [isMyAddress, isSpecialistAddress, isRemote]);

    const typeAddress: TypeAddress = {
        [IS_MY_ADDRESS]: MY_ADDRESS,
        [IS_SPECIALIST_ADDRESS]: SPECIALIST_ADDRESS,
    };

    const updateCurrentAddresses = () => {
        const items: string[] = [];
        if (isRemote) {
            items.push(IS_REMOTE);
        }
        if (isSpecialistAddress) {
            items.push(IS_SPECIALIST_ADDRESS);
        }

        if (isMyAddress) {
            items.push(IS_MY_ADDRESS);
        }
        setCurrentAddresses(items);
    };

    const clearAddress = (name: string) => {
        updateEditOrderField({
            name: typeAddress[name],
            value: null,
        });
        updateEditOrderField({
            name: name,
            value: false,
        });
    };

    const handleChange = (name: string) => {
        let value;
        if (name === IS_REMOTE) {
            value = !isRemote;
            updateEditOrderField({
                name,
                value,
            });
        }
        if (name === IS_SPECIALIST_ADDRESS) {
            if (!isSpecialistAddress) {
                setShowSpecialistAddressModal(true);
            } else {
                clearAddress(name);
            }
        }
        if (name === IS_MY_ADDRESS) {
            if (!isMyAddress) {
                setShowMyAddressModal(true);
            } else {
                clearAddress(name);
            }
        }
    };

    const changeAddress = (location: locationProps, name?: string) => {
        if (!location) {
            return;
        }
        updateEditOrderField({
            name: name,
            value: {
                latitude: location.latitude,
                longitude: location.longitude,
                radius: location.radius,
                address: location.address,
            },
        });
        const type =
            name === MY_ADDRESS ? IS_MY_ADDRESS : IS_SPECIALIST_ADDRESS;
        updateEditOrderField({
            name: type,
            value: true,
        });
        if (name === MY_ADDRESS) {
            setShowMyAddressModal(false);
        } else {
            setShowSpecialistAddressModal(false);
        }
    };

    return (
        <BaseContainerFilterMeetingPointOrder
            saveMyAddress={(location) => changeAddress(location, MY_ADDRESS)}
            setShowMyAddressModal={setShowMyAddressModal}
            showMyAddressModal={showMyAddressModal}
            entities={entities}
            saveSpecialistAddress={(location) => changeAddress(location, SPECIALIST_ADDRESS)}
            setShowSpecialistAddressModal={setShowSpecialistAddressModal}
            showSpecialistAddressModal={showSpecialistAddressModal}
            currentAddresses={currentAddresses}
            handleChange={handleChange}
            clearAddress={clearAddress}
            myLocation={myLocation}
            specialistLocation={specialistLocation}
            isEdit={true}
        />
    );
};

export default FilterToEditOrder;

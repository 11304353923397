import React from "react";
import {
    useAcceptAndContinueSearchMutation,
    useAcceptAndStopSearchMutation,
    useDeclineOfferOrderMutation,
} from "../../../../../store/chat/chat.api";
import { IOffer } from "../../../../../models/ordersModel";
import { TranslationObject } from "../../../../../models/translationModal";
import { useNotification } from "../../../../../hooks/useNotification/useNotification";

interface CustomerButtonsProps {
    offer: IOffer;
    entities?: TranslationObject;
}

export const CustomerButtons: React.FC<CustomerButtonsProps> = ({
    offer,
    entities,
}) => {
    const { status, id } = offer || {};

    const { setNotification } = useNotification();

    const [acceptAndContinueSearch] = useAcceptAndContinueSearchMutation();

    const [acceptAndStopSearch] = useAcceptAndStopSearchMutation();

    const [declineOfferOrder] = useDeclineOfferOrderMutation();

    const actionHandlers = {
        AcceptAndContinue: acceptAndContinueSearch,
        AcceptAndStop: acceptAndStopSearch,
        DeclineOffer: declineOfferOrder,
    };

    const handleClickButton: (params: {
        offerId: number;
        action: "AcceptAndContinue" | "AcceptAndStop" | "DeclineOffer";
    }) => void = ({ offerId, action }) => {
        actionHandlers[action](offerId)
            .unwrap()
            .catch((err) =>
                setNotification({
                    type: "warn",
                    title: err?.data?.payload?.message ?? "",
                    description:
                        err?.data?.payload?.detailed_message ??
                        err?.data?.description ??
                        "",
                })
            );
    };

    if (status !== null) {
        return null;
    }

    return (
        <>
            <div className="form__row chat__controls">
                <div className="popover popover--grey">
                    <button
                        className="btn chat__btn customer"
                        onClick={() =>
                            handleClickButton({
                                offerId: id,
                                action: "AcceptAndContinue",
                            })
                        }
                    >
                        {
                            entities?.order_offer_accept_and_continue_search
                                ?.value
                        }
                    </button>
                    <span className="popover__tip">
                        {
                            entities
                                ?.order_offer_accept_and_continue_search_info
                                ?.value
                        }
                    </span>
                </div>
                <div className="popover popover--grey">
                    <button
                        className="btn chat__btn customer"
                        type="button"
                        onClick={() =>
                            handleClickButton({
                                offerId: id,
                                action: "AcceptAndStop",
                            })
                        }
                    >
                        {entities?.order_offer_accept_and_stop_search?.value}
                    </button>
                    <span className="popover__tip">
                        {
                            entities?.order_offer_accept_and_stop_search_info
                                ?.value
                        }
                    </span>
                </div>
                <div className="popover popover--grey">
                    <button
                        className="btn chat__btn customer"
                        type="button"
                        onClick={() =>
                            handleClickButton({
                                offerId: id,
                                action: "DeclineOffer",
                            })
                        }
                    >
                        {entities?.common_decline?.value}
                    </button>
                    <span className="popover__tip">
                        {entities?.order_offer_reject_info?.value}
                    </span>
                </div>
            </div>
        </>
    );
};

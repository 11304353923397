import React from "react";
import Icon from "../../../../Icon";

interface StatusModalProps {
    iconType: "happy" | "sad";
    title: string;
    description: string;
    buttonConfirmName: string;
    buttonConfirmHandler?: () => unknown;
}

export const StatusModal: React.FC<StatusModalProps> = (props) => {
    const {
        iconType,
        title,
        description,
        buttonConfirmHandler,
        buttonConfirmName,
    } = props || {};
    return (
        <div className="modal__status">
            <div className="modal__status-icon__block">
                <Icon type={`${iconType}-smile`} width={32} height={32} />
            </div>
            <h4 className="modal__status-title">{title}</h4>
            <p className="modal__status-description">{description}</p>
            <button
                className="btn btn--transparent btn--bg-green"
                onClick={buttonConfirmHandler}
            >
                {buttonConfirmName}
            </button>
        </div>
    );
};

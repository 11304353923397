import React, { useEffect, useState } from "react";
import OrderCard from "../../../components/OrderCard";
import Pagination from "../../../components/Pagination";
import { useActions } from "../../../hooks/actions";
import {
    getFilter,
    getFilters,
    getParamsToFilterOrders,
} from "../../../store/orders/selectors";
import { endpoints } from "../../../store/orders/orders.api";
import { formatQueryString, getPages } from "../../../untils";
import { OrderItem } from "../../../models/ordersModel";
import { TranslationObject } from "../../../models/translationModal";
import { ProfilePayload } from "../../../models/userModels";
import _ from "lodash";
import { useUserRole } from "../../../hooks/useUserRole";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import CopyOrderModal from "../../../components/Modals/CopyOrderModal";

interface Props {
    entities?: TranslationObject;
    isSpecialist: boolean;
    profile?: ProfilePayload;
}

const ListOrders: React.FC<Props> = ({ entities, isSpecialist, profile }) => {
    const { setOrdersFilter } = useActions();
    const page = getFilter("page");
    const filters = getFilters();
    const [getOrders, { data: orders, isLoading, error }] =
        endpoints.getOrders.useLazyQuery();
    const [pages, setPages] = useState<number[]>([]);
    const [showModal, setShowModal] = useState<string>('');
    const [selectedOrder, setSelectedOrder] = useState<number | null>(null);

    const { role } = useUserRole(isSpecialist ? "specialist" : "customer");
    const currentLanguage = getCurrentLanguage();
    const linkToOrder = `/${currentLanguage}/${role}/lk/order`;

    useEffect(() => {
        if (orders) {
            const count = Math.ceil(orders?.total / 10);
            if (count > 1) {
                const pages = getPages(count);
                setPages(pages);
            } else {
                setPages([]);
            }
        }
    }, [orders]);
    const changeCurrentPage = (value: number) => {
        return setOrdersFilter({
            name: "page",
            value,
        });
    };

    useEffect(() => {
        const params = getParamsToFilterOrders(filters);
        getOrders({
            params: formatQueryString(params),
        });
    }, [filters]);

    if (isLoading) {
        return null;
    }

    if (error) {
        return (
            <div className="not-reviews__block">
                <p>{entities?.common_orders_list_not_available?.value} {error?.data?.description}</p>
            </div>
        );
    }

    if (!orders?.items?.length) {
        return (
            <div className="not-reviews__block">
                <p>{entities?.order_has_no_orders?.value}</p>
            </div>
        );
    }
    return (
        <section className="cards-orders">
            <ul className="cards-orders__list">
                {orders?.items?.map((order: OrderItem) => (
                    <OrderCard
                        profile={profile}
                        order={order}
                        key={order?.id || _.uniqueId("order")}
                        entities={entities}
                        isSpecialist={isSpecialist}
                        showModal={showModal}
                        role={role}
                        currentLanguage={currentLanguage}
                        setShowModal={setShowModal}
                        setSelectedOrder={setSelectedOrder}
                    />
                ))}
            </ul>
            <Pagination
                pages={pages}
                entities={entities}
                changeCurrentPage={changeCurrentPage}
                currentPage={page}
            />

            {
                selectedOrder && <CopyOrderModal
                    onClose={() => setShowModal('')}
                    showModal={showModal}
                    entities={entities}
                    linkToOrder={`${linkToOrder}/${selectedOrder}`}
                />
            }
        </section>
    );
};

export default ListOrders;

import React, { useState } from "react";
import PageLoaders from "../../components/Loaders/PageLoaders";
import { TextPagePayload } from "../../models/textPageModel";

const TextPage: React.FC<{
    code: string;
    currentLanguage: string;
}> = ({ code, currentLanguage }) => {
    const [data, setData] = useState<TextPagePayload | null>(null);
    return (
        <PageLoaders
            type="text_pages"
            code={code}
            updateData={setData}
            currentLanguage={currentLanguage}
        >
            <main>
                <section className="cascade-page">
                    <div className="container">
                        <div className="cascade">
                            <h1>{data?.title}</h1>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: data?.text || "",
                                }}
                            />
                        </div>
                    </div>
                </section>
            </main>
        </PageLoaders>
    );
};

export default TextPage;

import React from "react";
import Advertising from "../../components/Advertising";

import { Link, useNavigate } from "react-router-dom";
import { Medium } from "../../models/homePageSpecialist";
import { BlocksBaseObject } from "../../models/homePageModel";
import CountsBlock from "./CountsBlock";
import {
    changeOrderType,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { TranslationObject } from "../../models/translationModal";
import { getItemStore } from "../../untils";
import { IS_PRIVATE } from "../../store/order/constant";
import { useActions } from "../../hooks/actions";
import { createOrderState } from "../../store/order/createOrder.slice";
import { ProfilePayload } from "../../models/userModels";
import LogoutModalButton from "../../components/LogoutModalButton/LogoutModalButton";

const BodyCustomer: React.FC<{
    blocks: BlocksBaseObject;
    entities?: TranslationObject;
    profile?: ProfilePayload
}> = ({ blocks, entities, profile }) => {
    const currentLanguage = getCurrentLanguage();
    const { updateCreateOrderField, clearCreateOrderFields } = useActions();
    const navigate = useNavigate();
    const isSpecialist = profile?.user_role === 'specialist'

    const updateOrderField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) => {
        updateItemCreateOrder(
            {
                name,
                value,
            },
            updateCreateOrderField
        );
    };

    const _changeOrderType = async (isPrivate?: boolean) => {
        if (isSpecialist) {
            return
        }

        const is_private = getItemStore(IS_PRIVATE);

        if (is_private !== isPrivate) {
            await localStorage.clear();
            clearCreateOrderFields();
        }
        updateOrderField({
            name: IS_PRIVATE,
            value: isPrivate || false,
        });
        changeOrderType(isPrivate);
        navigate(`/${currentLanguage}/customer/create-order`);
    };

    const linkToRegistration = `/${currentLanguage}/customer/registration`;

    return (
        <div className="page__grid">
            <section className="selection-spec section">
                <h2 className="visually-hidden">
                    {blocks?.order_creation_block_title?.value}
                </h2>
                <ul className="selection-spec__list">
                    <li className="selection-spec__item selection-spec__item--mail-icon">
                        <h3 className="title title--h2">
                            {blocks?.order_creation_block_title?.value}
                        </h3>
                        {blocks?.order_creation_block_text?.type === "text" ? (
                            <p
                                className="selection-spec__text"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        blocks?.order_creation_block_text
                                            ?.value || "",
                                }}
                            />
                        ) : (
                            <p className="selection-spec__text">
                                {blocks?.order_creation_block_text?.value}
                            </p>
                        )}

                        <LogoutModalButton
                            handleClick={_changeOrderType}
                            isSpecialist={isSpecialist}
                            entities={entities}
                            classes={["btn", "selection-spec__item-button"]}
                            text={blocks?.order_creation_block_create_order_button?.value}
                        />
                    </li>
                    <li className="selection-spec__item selection-spec__item--search-icon">
                        <h3 className="title title--h2">
                            {blocks?.private_order_creation_block_title?.value}
                        </h3>

                        {blocks?.private_order_creation_block_text?.type ===
                            "text" ? (
                            <p
                                className="selection-spec__text"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        blocks
                                            ?.private_order_creation_block_text
                                            ?.value || "",
                                }}
                            ></p>
                        ) : (
                            <p className="selection-spec__text">
                                {
                                    blocks?.private_order_creation_block_text
                                        ?.value
                                }
                            </p>
                        )}
                        <LogoutModalButton
                            handleClick={() => _changeOrderType(true)}
                            isSpecialist={isSpecialist}
                            entities={entities}
                            classes={["btn", "selection-spec__item-button"]}
                            text={blocks?.create_private_order_block_create_order_button?.value}
                        />
                    </li>
                </ul>
            </section>

            <section className="specialists">
                <div className="specialists__wrapper no-scrollbar">
                    <h2 className="visually-hidden">
                        {entities?.finances_all_specialists?.value}
                    </h2>
                    {/* <Advertising
                        typeAdvertising={"specialists"}
                        entities={entities}
                    /> */}
                    <Advertising
                        typeAdvertising={"specialistsCustomerPage"}
                        entities={entities}
                        isCustomerPage={true}
                    />
                </div>
            </section>

            <section className="account-options section">
                <div className="account-options__wrapper">
                    <h2 className="title title--h2">
                        {blocks?.features_title?.value}
                    </h2>
                    <ol className="account-options__list">
                        {blocks?.features_list?.value?.map(
                            (title: string, key: number) => (
                                <li className="account-options__item" key={key}>
                                    {title}
                                </li>
                            )
                        )}
                    </ol>
                    <Link
                        to={linkToRegistration}
                        className="btn account-options__button"
                    >
                        {blocks?.features_register_button_text?.value}
                    </Link>
                </div>
                <div className="account-options__image">
                    {blocks?.features_image?.media.map((img: Medium) => (
                        <img
                            key={img?.id + 1}
                            src={img?.original_url}
                            alt={blocks?.features_image?.title}
                        />
                    ))}
                </div>
                <div className="account-options__image-mobile">
                    {blocks?.features_image?.media?.map((img: Medium) => (
                        <img key={img?.id + 2} src={img?.original_url} alt={blocks?.features_image?.title}/>
                    ))}
                </div>
            </section>
            <section className="section order-stats desktop-only">
                <CountsBlock
                    satisfied_customers_count={
                        blocks?.satisfied_customers_count?.value
                    }
                    satisfied_customers_title={
                        blocks?.satisfied_customers_title?.value
                    }
                    specialists_count={blocks?.specialists_count?.value}
                    specialists_title={blocks?.specialists_title?.value}
                    deals_count={blocks?.deals_count?.value}
                    deals_title={blocks?.deals_title?.value}
                />
            </section>
        </div>
    );
};

export default BodyCustomer;

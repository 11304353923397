import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { changeHeaders } from "../request";
import {
    // acceptAndContinueSearch,
    // acceptAndStopSearch,
    createNewChat,
    // declineOfferOrder,
    getChatInfo,
    getChatMessages,
    // makeOfferOrder,
    // pinChat,
    sendMessageToChat,
    sendMessageToChatNotAuth,
    // unpinChat,
    // viewChatMessage,
} from "./api";
import {
    // createNewChatRequestData,
    createNewChatResponse,
    // GetChatMessagesPayload,
    GetSupportMessagesPayload,
    GetChatMessagesRequest,
    // GetChatMessagesResponse,
    GetSupportMessagesResponse,
    IChatPayload,
    ISupportMessage,
    // makeOfferOrderRequestData,
    SendMessageToChatRequest,
    SendMessageToChatNotAuthRequest,
    // SendMessageToChatAiRequest,
    SendMessageToChatResponse,
} from "../../models/chatModels";

export const supportApi = createApi({
    reducerPath: "support_chat/api",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
        prepareHeaders: (headers, { getState }) =>
            changeHeaders(headers, { getState }),
    }),
    tagTypes: ["Support"],
    endpoints: (build) => ({
        createNewChat: build.mutation<IChatPayload, void>({
            query: () => createNewChat(),
            transformResponse: (response: createNewChatResponse) =>
                response.payload,
        }),
        getChatInfo: build.query<IChatPayload, string | number>({
            query: (chatId) => getChatInfo(chatId),
            providesTags: [{ type: "Support" }],
            transformResponse: (response: createNewChatResponse) =>
                response.payload,
        }),
        sendSupportMessageToChat: build.mutation<ISupportMessage, SendMessageToChatRequest>({
            query: (requestData) => sendMessageToChat(requestData),
            transformResponse: (response: SendMessageToChatResponse) =>
                response.payload,
        }),
        sendSupportMessageToChatNotAuth: build.mutation<ISupportMessage, SendMessageToChatNotAuthRequest>({
            query: (requestData) => sendMessageToChatNotAuth(requestData),
            transformResponse: (response: SendMessageToChatResponse) =>
                response.payload,
        }),
        getChatMessages: build.query<GetSupportMessagesPayload, GetChatMessagesRequest>({
            query: (requestData) => getChatMessages(requestData),
            transformResponse: (response: GetSupportMessagesResponse) =>
                response.payload,
        }),
    }),
});

export const {
    endpoints,
    util,
    useCreateNewChatMutation,
    useSendSupportMessageToChatMutation,
    useSendSupportMessageToChatNotAuthMutation,
} = supportApi;

import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import { useDeleteProfileMutation } from "../../store/user/user.api";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../store/directories/selectors";
import { ComponentProps } from "../../models/models";
import { getTkn } from "../../store/user/selectors";
import { deleteCookie, getCookie } from "../../untils/cookies";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useActions } from "../../hooks/actions";

interface Props extends ComponentProps {
    isSpecialist?: boolean;
}

const DeleteButton: React.FC<Props> = ({
    entities,
    currentLanguage,
    isSpecialist,
}) => {
    const [deleteUser, { data, isSuccess }] = useDeleteProfileMutation();
    const [visible, setVisible] = useState<boolean>(false);
    const navigate = useNavigate();
    const { updateUserField } = useActions();
    const path = getPath();
    const token = getTkn();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isSuccess && data?.description === "OK") {
            onSuccess();
        }
    }, [isSuccess]);

    const onSuccess = async () => {
        if (getCookie("tkn")) {
            deleteCookie("tkn");
            dispatch(
                updateUserField({
                    name: "tkn",
                    value: null,
                })
            );
        }
        navigate(`/${currentLanguage}/${path[1]}/`);
    };

    const deleteProfile = () => {
        if (token) {
            deleteUser();
        }
    };

    return (
        <>
            <Modal
                title={entities?.profile_delete_account?.value}
                description={entities?.profile_destroy_text?.value}
                visible={visible}
                onClose={() => setVisible(false)}
            >
                <div className="modal__content-buttons">
                    <button
                        className="btn btn--transparent"
                        type="button"
                        onClick={() => setVisible(false)}
                    >
                        {entities?.common_cancel_button?.value}
                    </button>
                    <button
                        className={classNames("btn modal__content-button ", {
                            "modal__content-button--background": isSpecialist,
                            "modal__content-button--background-customer":
                                !isSpecialist,
                        })}
                        type="button"
                        onClick={deleteProfile}
                    >
                        {entities?.profile_destroy_button_ok?.value}
                    </button>
                </div>
            </Modal>
            <button
                className="btn profile__button"
                type="button"
                onClick={() => setVisible(true)}
            >
                {entities?.profile_delete_account?.value}
            </button>
        </>
    );
};

export default DeleteButton;

import React, { useEffect, useState } from "react";
import { getItemStore } from "../../../untils";
import Icon from "../../Icon";
import { toArray } from "../../../lib/converters";
import { InvalidFieldsProps } from "../Input";
import { feedBackState } from "../../../store/feedback/feebback.slice";

const MediaInput: React.FC<{
    text: string | null;
    name: string;
    isNeedSaveFile?: boolean;
    resetFiles?: boolean;
    invalidFields?: InvalidFieldsProps;
    error?: any;
    maxFiles?: number;
    updateField?: (name: keyof feedBackState, value: any) => void
    removeFieldFiles?: (name: keyof feedBackState, value: feedBackState[keyof feedBackState]) => void
}> = ({ invalidFields, error, text, name, isNeedSaveFile, maxFiles, updateField, removeFieldFiles, resetFiles = false }) => {
    const [files, setFiles] = useState<any>([]);
    const [isLimit, setIsLimit] = useState(false)
    const types = [
        "jpg",
        "gif",
        "bmp",
        "webp",
        "png",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "pdf",
        "ppt",
        "pptx",
        "jpeg",
        "vnd.openxmlformats-officedocument.wordprocessingml.document",
        "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "vnd.ms-powerpoint",
    ];

    useEffect(() => {
        const savedFile = getItemStore("image");
        if (savedFile) {
            setFiles(files.concat(savedFile));
        }
    }, []);
    useEffect(() => {
        if (maxFiles) setIsLimit(files.length >= maxFiles)
    }, [files]);

    useEffect(() => {
        if (resetFiles) {
            files.forEach(fl => removeFieldFiles?.("files", fl))
            setTimeout(() => setFiles([]), 1);
        }
    }, [resetFiles]);

    const readerFile = async(file, name) => {
        return new Promise((resolve) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
                resolve({
                    file: fileReader.result,
                    name: name,
                });
            };
            fileReader.readAsDataURL(file);
        });
    }

    const addFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const elems: FileList | null = e.target.files;
        if (elems) {
            const keys = Object.keys(elems);
            const items: any[] = [];

            keys.forEach(async (elem: any) => {
                if (isLimit) return
                if (elems[elem]) {
                    const type = elems[elem]?.type?.split("/");
                    const isRightType = types?.find((elem) => elem === type[1]);
                    const dbl = files?.find((el: any) => el.name === elems[elem].name);

                    if (isRightType && !dbl) {
                        items.push(elems[elem]);
                    }

                    if (isRightType) {
                        const result = await readerFile(items[elem], items[elem].name)
                        updateField?.("files", result);
                    }
                }
            });

            maxFiles ? setFiles([...files, ...items].slice(0, maxFiles)) : setFiles([...files, ...items])
        }
    };

    const deleteImage = (file: any) => {
        removeFieldFiles?.("files", file);
        setFiles(files.filter((item: any) => item.name !== file.name));

        const item: HTMLElement | null = document.getElementById(name);

        if (item) {
            (item as HTMLInputElement).value = "";
        }
    };

    return (
        <div className="feedback-form__file-field">
            {files?.map((file: any, key: number) => {
                const name = `files.${key}`;
                const showError =
                    !!invalidFields &&
                    invalidFields.has(name) &&
                    error?.errors?.[name];
                const textError =
                    error?.errors[name] &&
                    toArray(error?.errors[name]).join(`, `);

                return (
                    <>
                        <div key={key} className="feedback-form__file-name">
                            <p className="feedback-form__file-text">
                                {file.name}
                            </p>
                            <button
                                onClick={() => deleteImage(file)}
                                className="feedback-form__file-delete"
                                type="button"
                                aria-label="Удалить файл"
                            >
                                <Icon type="delete" />
                            </button>
                        </div>
                        {showError && (
                            <div className="feedback-form__file-error">
                                {textError}
                            </div>
                        )}
                    </>
                );
            })}
            <div className="custom-input feedback-form__input feedback-form__input--file">
                <label>
                    <span className="custom-input__label">{text}</span>
                    <input
                        type="file"
                        multiple={true}
                        id={name}
                        onChange={(e) => addFile(e)}
                        disabled={isLimit}
                    />
                </label>
            </div>
        </div>
    );
};
export default MediaInput;

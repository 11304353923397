import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import Heading from "../../components/Heading/index";
import BodySpecialist from "./BodySpecialist";
import BodyCustomer from "./BodyCustomer";
import BaseBody from "./BaseBody";
import {
    isCustomerUrl,
    isSpecialistUrl,
} from "../../store/directories/selectors";
import { getTkn, getUserRole } from "../../store/user/selectors";
import PageLoaders from "../../components/Loaders/PageLoaders";
import { BlocksBaseObject } from "../../models/homePageModel";
import { ComponentProps } from "../../models/models";
import SubMenu from "../../components/SubMenu";
import { changeOrderType } from "../../store/order/selectors";
import Icon from "../../components/Icon";
import pictureMobilePng from "../../img/content/poster/image1-mobile.png";
import pictureMobilePng2x from "../../img/content/poster/image1-mobile-2x.png";
import pictureMobileWebp from "../../img/content/poster/image1-mobile.webp";
import pictureMobileWebp2x from "../../img/content/poster/image1-mobile-2x.webp";
import pictureTabletPng from "../../img/content/poster/image1-tablet.png";
import pictureTabletPng2x from "../../img/content/poster/image1-tablet-2x.png";
import pictureTabletWebp from "../../img/content/poster/image1-tablet.webp";
import pictureTabletWebp2x from "../../img/content/poster/image1-tablet-2x.webp";
import pictureDesktopPng from "../../img/content/poster/image1-desktop.png";
import pictureDesktopPng2x from "../../img/content/poster/image1-desktop-2x.png";
import pictureDesktopWebp from "../../img/content/poster/image1-desktop.webp";
import pictureDesktopWebp2x from "../../img/content/poster/image1-desktop-2x.webp";

import pictureMobileBg from "../../img/content/poster/bg1-mobile.svg";
import pictureTabletBg from "../../img/content/poster/bg1-tablet.svg";
import pictureDesktopBg from "../../img/content/poster/bg1-desktop.svg";

import picture2MobilePng from "../../img/content/poster/image2-mobile.png";
import picture2MobilePng2x from "../../img/content/poster/image2-mobile-2x.png";
import picture2MobileWebp from "../../img/content/poster/image2-mobile.webp";
import picture2MobileWebp2x from "../../img/content/poster/image2-mobile-2x.webp";
import picture2TabletPng from "../../img/content/poster/image2-tablet.png";
import picture2TabletPng2x from "../../img/content/poster/image2-tablet-2x.png";
import picture2TabletWebp from "../../img/content/poster/image2-tablet.webp";
import picture2TabletWebp2x from "../../img/content/poster/image2-tablet-2x.webp";
import picture2DesktopPng from "../../img/content/poster/image2-desktop.png";
import picture2DesktopPng2x from "../../img/content/poster/image2-desktop-2x.png";
import picture2DesktopWebp from "../../img/content/poster/image2-desktop.webp";
import picture2DesktopWebp2x from "../../img/content/poster/image2-desktop-2x.webp";

import pictureCustomerMobilePng from "../../img/content/poster/image4-mobile.png";
import pictureCustomerMobilePng2x from "../../img/content/poster/image4-mobile-2x.png";
import pictureCustomerMobileWebp from "../../img/content/poster/image4-mobile.webp";
import pictureCustomerMobileWebp2x from "../../img/content/poster/image4-mobile-2x.webp";
import pictureCustomerTabletPng from "../../img/content/poster/image4-tablet.png";
import pictureCustomerTabletPng2x from "../../img/content/poster/image4-tablet-2x.png";
import pictureCustomerTabletWebp from "../../img/content/poster/image4-tablet.webp";
import pictureCustomerTabletWebp2x from "../../img/content/poster/image4-tablet-2x.webp";
import pictureCustomerDesktopPng from "../../img/content/poster/image4-desktop.png";
import pictureCustomerDesktopPng2x from "../../img/content/poster/image4-desktop-2x.png";
import pictureCustomerDesktopWebp from "../../img/content/poster/image4-desktop.webp";
import pictureCustomerDesktopWebp2x from "../../img/content/poster/image4-desktop-2x.webp";

import pictureCustomerMobileBg from "../../img/content/poster/bg4-mobile.svg";
import pictureCustomerTabletBg from "../../img/content/poster/bg4-tablet.svg";
import pictureCustomerDesktopBg from "../../img/content/poster/bg4-desktop.svg";

import pictureSpecialistMobilePng from "../../img/content/poster/image5-mobile.png";
import pictureSpecialistMobilePng2x from "../../img/content/poster/image5-mobile-2x.png";
import pictureSpecialistMobileWebp from "../../img/content/poster/image5-mobile.webp";
import pictureSpecialistMobileWebp2x from "../../img/content/poster/image5-mobile-2x.webp";
import pictureSpecialistTabletPng from "../../img/content/poster/image5-tablet.png";
import pictureSpecialistTabletPng2x from "../../img/content/poster/image5-tablet-2x.png";
import pictureSpecialistTabletWebp from "../../img/content/poster/image5-tablet.webp";
import pictureSpecialistTabletWebp2x from "../../img/content/poster/image5-tablet-2x.webp";
import pictureSpecialistDesktopPng from "../../img/content/poster/image5-desktop.png";
import pictureSpecialistDesktopPng2x from "../../img/content/poster/image5-desktop-2x.png";
import pictureSpecialistDesktopWebp from "../../img/content/poster/image5-desktop.webp";
import pictureSpecialistDesktopWebp2x from "../../img/content/poster/image5-desktop-2x.webp";

import pictureSpecialistMobileBg from "../../img/content/poster/bg5-mobile.svg";
import pictureSpecialistTabletBg from "../../img/content/poster/bg5-tablet.svg";
import pictureSpecialistDesktopBg from "../../img/content/poster/bg5-desktop.svg";

import pictureGuestMobilePng from "../../img/content/poster/image1-mobile.png";
import pictureGuestMobilePng2x from "../../img/content/poster/image1-mobile-2x.png";
import pictureGuestMobileWebp from "../../img/content/poster/image1-mobile.webp";
import pictureGuestMobileWebp2x from "../../img/content/poster/image1-mobile-2x.webp";
import pictureGuestTabletPng from "../../img/content/poster/image1-tablet.png";
import pictureGuestTabletPng2x from "../../img/content/poster/image1-tablet-2x.png";
import pictureGuestTabletWebp from "../../img/content/poster/image1-tablet.webp";
import pictureGuestTabletWebp2x from "../../img/content/poster/image1-tablet-2x.webp";
import pictureGuestDesktopPng from "../../img/content/poster/image1-desktop.png";
import pictureGuestDesktopPng2x from "../../img/content/poster/image1-desktop-2x.png";
import pictureGuestDesktopWebp from "../../img/content/poster/image1-desktop.webp";
import pictureGuestDesktopWebp2x from "../../img/content/poster/image1-desktop-2x.webp";

import pictureGuestMobileBg from "../../img/content/poster/bg1-mobile.svg";
import pictureGuestTabletBg from "../../img/content/poster/bg1-tablet.svg";
import pictureGuestDesktopBg from "../../img/content/poster/bg1-desktop.svg";
import Search from "../../components/Search";

const HomePage: React.FC<ComponentProps> = ({
    currentLanguage,
    entities,
    profile,
}) => {
    const [blocks, setBlocks] = useState<BlocksBaseObject | null>(null);
    const tkn = getTkn();
    const userRole = getUserRole();
    const navigate = useNavigate();
    const location = useLocation();
    const isCustomer = isCustomerUrl();
    const isSpecialist = isSpecialistUrl();
    const isGuest = !isSpecialist && !isCustomer;
    const isSpecialistRole = profile?.user_role === 'specialist';
    const isSearchButton = blocks?.main_block_search_button ? true : false;

    const goCustomerHomePage = () => navigate(`/${currentLanguage}/customer`);
    const goSpecialistHomePage = () => navigate(`/${currentLanguage}/specialist`);
    const goSpecialistLkOrdersPage = () => navigate(`/${currentLanguage}/specialist/lk/orders`);

    const createOrder = () => {
        if (isSpecialistRole || isGuest) {
            return goCustomerHomePage()
        }
        changeOrderType();
        navigate(`/${currentLanguage}/customer/create-order`);
    };

    const createPrivateOrder = () => {
        if (tkn && profile?.user_role === "specialist") {
            return goSpecialistLkOrdersPage()
        } else {
            return goSpecialistHomePage()
        }
    };

    const buttons = (
        <>
            {(blocks?.main_block_create_order_button && !isSearchButton) && (
                <button
                    className="btn promo__order-button"
                    type="button"
                    aria-label={blocks?.main_block_create_order_button?.value}
                    onClick={createOrder}
                >
                    {blocks?.main_block_create_order_button?.value}
                </button>
            )}

            {(blocks?.main_block_get_order_button && !isSearchButton) && (
                <button
                    className="btn promo__order-button promo__order-button--green"
                    type="button"
                    aria-label={blocks?.main_block_get_order_button?.value}
                    onClick={createPrivateOrder}
                >
                    {blocks?.main_block_get_order_button?.value}
                </button>
            )}

            {isSearchButton && <Search colored={true} entities={entities} profile={profile} placeholder={blocks?.main_block_search_button?.value || ""} />}
        </>

        // <>
        //     {blocks?.main_block_create_order_button && (
        //         <button
        //             className="btn promo__order-button"
        //             type="button"
        //             aria-label={blocks?.main_block_create_order_button?.value}
        //             onClick={createOrder}
        //         >
        //             {blocks?.main_block_create_order_button?.value}
        //         </button>
        //     )}

        //     {blocks?.main_block_get_order_button && (
        //         <button
        //             className="btn promo__order-button promo__order-button--green"
        //             type="button"
        //             aria-label={blocks?.main_block_get_order_button?.value}
        //             onClick={createPrivateOrder}
        //         >
        //             {blocks?.main_block_get_order_button?.value}
        //         </button>
        //     )}

        //     {blocks?.main_block_search_button && <Search colored={true} entities={entities} />}
        // </>
    );

    useEffect(() => {
        if (!isSpecialist && userRole === "customer") {
            navigate(`/${currentLanguage}/customer`);
        }

        if (!isCustomer && userRole === "specialist") {
            navigate(`/${currentLanguage}/specialist`);
        }
    }, [location.pathname]);

    const headerClassName = () => {
        if (isCustomer) {
            return "promo-customer page__promo";
        }
        if (isSpecialist) {
            return "page__promo promo promo-specialists";
        }
        return "promo page__promo ";
    };

    const getPath = () => {
        if (isCustomer) {
            return "customer_main_page";
        }

        if (isSpecialist) {
            return "specialist_main_page";
        }

        return "main_page";
    };

    const scrollToFooter = () => {
        const element = document.getElementById("footer");
        // const root = document.getElementById('root');

        !!element && window.scrollTo({
            behavior: "smooth",
            top: element.offsetTop || 0
        });
    }

    const heading = {
        picture: {
            srcsets: [
                {
                    media: '(min-width: 1024px)',
                    type: 'image/webp',
                    srcSet: pictureDesktopWebp + ', ' + pictureDesktopWebp2x + ' 2x'
                },
                {
                    media: '(min-width: 1024px)',
                    type: 'image/png',
                    srcSet: pictureDesktopPng + ', ' + pictureDesktopPng2x + ' 2x'
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/webp',
                    srcSet: pictureTabletWebp + ', ' + pictureTabletWebp2x + ' 2x'
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/png',
                    srcSet: pictureTabletPng + ', ' + pictureTabletPng2x + ' 2x'
                },
                {
                    type: 'image/webp',
                    srcSet: pictureMobileWebp + ', ' + pictureMobileWebp2x + ' 2x'
                }
            ],
            src: pictureMobilePng,
            srcSet: pictureMobilePng2x + ' 2x'
        }
    }

    const headingBg = {
        bg: {
            srcsets: [
                {
                    media: '(min-width: 1024px)',
                    type: 'image/svg+xml',
                    srcSet: pictureDesktopBg
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/svg+xml',
                    srcSet: pictureTabletBg
                },
            ],
            src: pictureMobileBg
        }
    }

    const customerPicture = {
        picture: {
            srcsets: [
                {
                    media: '(min-width: 1024px)',
                    type: 'image/webp',
                    srcSet: pictureCustomerDesktopWebp + ', ' + pictureCustomerDesktopWebp2x + ' 2x'
                },
                {
                    media: '(min-width: 1024px)',
                    type: 'image/png',
                    srcSet: pictureCustomerDesktopPng + ', ' + pictureCustomerDesktopPng2x + ' 2x'
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/webp',
                    srcSet: pictureCustomerTabletWebp + ', ' + pictureCustomerTabletWebp2x + ' 2x'
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/png',
                    srcSet: pictureCustomerTabletPng + ', ' + pictureCustomerTabletPng2x + ' 2x'
                },
                {
                    type: 'image/webp',
                    srcSet: pictureCustomerMobileWebp + ', ' + pictureCustomerMobileWebp2x + ' 2x'
                }
            ],
            src: pictureCustomerMobilePng,
            srcSet: pictureCustomerMobilePng2x + ' 2x'
        }
    }

    const customerBg = {
        bg: {
            srcsets: [
                {
                    media: '(min-width: 1024px)',
                    type: 'image/svg+xml',
                    srcSet: pictureCustomerDesktopBg
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/svg+xml',
                    srcSet: pictureCustomerTabletBg
                },
            ],
            src: pictureCustomerMobileBg
        }
    }

    const customerButtons = (
        <>
            <a
                className="btn"
                href={`/${currentLanguage}/customer/registration`}
            >
                {entities?.registration_link_value?.value}
            </a>

            <button
                className="link"
                type="button"
                aria-label={entities?.heading_link_get_link_app?.value}
                onClick={scrollToFooter}
            >
                <Icon
                    className="link__icon"
                    type="download"
                    width={24}
                    height={24}
                />
                {entities?.heading_link_get_link_app?.value}
            </button>
        </>
    );

    const specialistPicture = {
        picture: {
            srcsets: [
                {
                    media: '(min-width: 1024px)',
                    type: 'image/webp',
                    srcSet: pictureSpecialistDesktopWebp + ', ' + pictureSpecialistDesktopWebp2x + ' 2x'
                },
                {
                    media: '(min-width: 1024px)',
                    type: 'image/png',
                    srcSet: pictureSpecialistDesktopPng + ', ' + pictureSpecialistDesktopPng2x + ' 2x'
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/webp',
                    srcSet: pictureSpecialistTabletWebp + ', ' + pictureSpecialistTabletWebp2x + ' 2x'
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/png',
                    srcSet: pictureSpecialistTabletPng + ', ' + pictureSpecialistTabletPng2x + ' 2x'
                },
                {
                    type: 'image/webp',
                    srcSet: pictureSpecialistMobileWebp + ', ' + pictureSpecialistMobileWebp2x + ' 2x'
                }
            ],
            src: pictureSpecialistMobilePng,
            srcSet: pictureSpecialistMobilePng2x + ' 2x'
        }
    }

    const specialistBg = {
        bg: {
            srcsets: [
                {
                    media: '(min-width: 1024px)',
                    type: 'image/svg+xml',
                    srcSet: pictureSpecialistDesktopBg
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/svg+xml',
                    srcSet: pictureSpecialistTabletBg
                },
            ],
            src: pictureSpecialistMobileBg
        }
    }

    const specialistButtons = (
        <>
            <a
                className="btn btn--bg-green"
                href={`/${currentLanguage}/specialist/registration`}
            >
                {entities?.registration_link_value?.value}
            </a>

            <button
                className="link"
                type="button"
                aria-label={entities?.heading_link_get_link_app?.value}
                onClick={scrollToFooter}
            >
                <Icon
                    className="link__icon"
                    type="download"
                    width={24}
                    height={24}
                    colorVariant={"greenLight"}
                />
                {entities?.heading_link_get_link_app?.value}
            </button>
        </>
    );

    const guestPicture = {
        picture: {
            srcsets: [
                {
                    media: '(min-width: 1024px)',
                    type: 'image/webp',
                    srcSet: pictureGuestDesktopWebp + ', ' + pictureGuestDesktopWebp2x + ' 2x'
                },
                {
                    media: '(min-width: 1024px)',
                    type: 'image/png',
                    srcSet: pictureGuestDesktopPng + ', ' + pictureGuestDesktopPng2x + ' 2x'
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/webp',
                    srcSet: pictureGuestTabletWebp + ', ' + pictureGuestTabletWebp2x + ' 2x'
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/png',
                    srcSet: pictureGuestTabletPng + ', ' + pictureGuestTabletPng2x + ' 2x'
                },
                {
                    type: 'image/webp',
                    srcSet: pictureGuestMobileWebp + ', ' + pictureGuestMobileWebp2x + ' 2x'
                }
            ],
            src: pictureGuestMobilePng,
            srcSet: pictureGuestMobilePng2x + ' 2x'
        }
    }

    const guestBg = {
        bg: {
            srcsets: [
                {
                    media: '(min-width: 1024px)',
                    type: 'image/svg+xml',
                    srcSet: pictureGuestDesktopBg
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/svg+xml',
                    srcSet: pictureGuestTabletBg
                },
            ],
            src: pictureGuestMobileBg
        }
    }

    const searchElement = (
        <div className="promo__search">
            <Search
                colored={true}
                entities={entities}
                profile={profile}
                placeholder={blocks?.main_block_search_button?.value || ""}
            />
        </div>
    )

    return (
        <PageLoaders
            type="custom_pages"
            code={getPath()}
            isCustomPage
            updateData={setBlocks}
            currentLanguage={currentLanguage}
        >
            <main>
                <SubMenu entities={entities} profile={profile} />
                <div
                    className={classNames("page", {
                        "page--main": isGuest,
                        "page--customer": isCustomer,
                        "page--specialists": isSpecialist,
                    })}
                >
                    <div className="page__container container">
                        {/* <Heading
                            isSpecialist={isSpecialist}
                            className={headerClassName()}
                            img={blocks?.main_block_image?.media}
                            title={blocks?.main_block_title?.value}
                            text={blocks?.main_block_text?.value}
                            buttons={
                                blocks?.main_block_create_order_button &&
                                blocks?.main_block_get_order_button &&
                                buttons
                            }
                            picture={heading.picture}
                        /> */}

                        {isCustomer && (
                            <Heading
                                isSpecialist={isSpecialist}
                                className={headerClassName()}
                                img={blocks?.main_block_image?.value}
                                title={blocks?.main_block_title?.value}
                                text={blocks?.main_block_text?.value}
                                buttons={customerButtons}
                                picture={customerPicture.picture}
                                bg={customerBg.bg}
                                search={blocks?.main_block_search_button && searchElement}
                            />
                        )}
                        {isSpecialist && (
                            <Heading
                                isSpecialist={isSpecialist}
                                className={headerClassName()}
                                img={blocks?.main_block_image?.value}
                                title={blocks?.main_block_title?.value}
                                text={blocks?.main_block_text?.value}
                                buttons={specialistButtons}
                                picture={specialistPicture.picture}
                                bg={specialistBg.bg}
                            />
                        )}
                        {isGuest && (
                            <Heading
                                isSpecialist={isSpecialist}
                                className={headerClassName()}
                                img={blocks?.main_block_image?.value}
                                title={blocks?.main_block_title?.value}
                                text={blocks?.main_block_text?.value}
                                buttons={buttons}
                                picture={guestPicture.picture}
                                bg={guestBg.bg}
                            />
                        )}
                        {!isCustomer && !isSpecialist && !isGuest && (
                            <Heading
                                isSpecialist={isSpecialist}
                                className={headerClassName()}
                                img={blocks?.main_block_image?.value}
                                title={blocks?.main_block_title?.value}
                                text={blocks?.main_block_text?.value}
                                buttons={buttons}
                                picture={heading.picture}
                                bg={headingBg.bg}
                            />
                        )}

                        {isSpecialist && blocks && (
                            <BodySpecialist blocks={blocks} entities={entities} />
                        )}
                        {isCustomer && blocks && (
                            <BodyCustomer blocks={blocks} entities={entities} profile={profile} />
                        )}
                        {!isSpecialist && blocks && !isCustomer && (
                            <BaseBody blocks={blocks} entities={entities} />
                        )}
                    </div>
                </div>
            </main>
        </PageLoaders>
    );
};
export default HomePage;

import React, { useEffect, useState } from "react";
import { ComponentProps } from "../../models/models";
import Form from "../../components/Form/Form";
import Input from "../../components/Form/Input";
import RadioInput from "../../components/Form/RadioInput";
import { useSetRegistrationCompanyMutation, endpoints } from "../../store/registration/registration.api";
import {
    checkReCaptcha,
    getDirectories,
} from "../../store/directories/selectors";

interface StepCompanyDataProps extends ComponentProps {
    changeStep: (step: string) => unknown;
}

export const StepCompanyData: React.FC<StepCompanyDataProps> = ({
    changeStep,
    entities,
    profile
}) => {
    const { data: directories } = getDirectories();
    const site_key = directories?.recaptcha?.site_key;
    const [showInsurance, setShowInsurance] = useState(false);
    const [isDraft, setIsDarft] = useState(false);
    const [setRegistrationCompany, { isLoading, isSuccess, error }] =
        useSetRegistrationCompanyMutation();
    const [isChecked, setIsChecked] = useState(false);
    const [getRegistrationCompany, { data: dataFields }] = endpoints.getRegistrationCompany.useLazyQuery();
    const [fieldsValue, setFieldsValue] = useState({});
    const fields = {
        top: [
            {
                id: 'registration_number',
                placeholder: 'register_company_registration_number'
            }
        ],
        main: [
            {
                id: 'country',
                placeholder: 'register_company_country'
            }, {
                id: 'address',
                placeholder: 'register_company_legal_address'
            }, {
                id: 'name',
                placeholder: 'register_company_name'
            }, {
                id: 'tax_number',
                placeholder: 'register_company_tax_number'
            }, {
                id: 'vat_number',
                placeholder: 'register_company_vat_number'
            }
        ],
        bottom: [
            {
                id: 'insurance_company_name',
                placeholder: 'register_company_insurance_name'
            }, {
                id: 'insurance_max_payment_amount',
                placeholder: 'register_company_insurance_benefit'
            }
        ]
    }

    const getFields = (arr, invalidFields, error) => (
        arr.map(({ id, placeholder }) => {
            return <div className="form__row">
                <Input
                    type={"text"}
                    id={id}
                    name={id}
                    placeholder={entities && entities[placeholder]?.value || ''}
                    invalidFields={invalidFields}
                    error={error}
                    value={fieldsValue[id]}
                    disabled={profile?.is_draft ? false : !!profile?.[id]}
                    onChange={(val) => setFieldsValue({ ...fieldsValue, [id]: val })}
                    classes={profile?.is_draft ? [''] : dataFields && dataFields[id] ? ['is-disabled'] : ['']}
                />
            </div>
        })
    )

    const submitForm = (event: any) => {
        event.preventDefault();

        if (isLoading) return;

        if (isChecked) {
            const data = new FormData(event.target);
            const sendFeedback = (str: string, data: FormData) => {
                data.append("g-recaptcha-response", str);
                setRegistrationCompany(data);
            };

            if (site_key) {
                return checkReCaptcha(site_key, "store_company", (token) =>
                    sendFeedback(token, data)
                );
            }
        } else {
            fieldsValue?.["registration_number"] &&
                getRegistrationCompany(fieldsValue["registration_number"]);
            setIsChecked(!isChecked)
        }
    };

    const backForm = () => {
        if (isChecked) {
            setIsChecked(!isChecked)
            setFieldsValue({})
            getRegistrationCompany('')
        } else {
            changeStep('two')
        }
    }

    useEffect(() => {
        if (isSuccess) {
            changeStep('four');
        }
    }, [isSuccess]);

    useEffect(() => {
        if (dataFields) {
            const obj = {}

            Object.keys(dataFields).forEach((key) => {
                obj[key] = dataFields[key]
            })

            setFieldsValue({ ...fieldsValue, ...obj });
            setShowInsurance(!!(dataFields?.insurance_company_name && dataFields?.insurance_max_payment_amount))
        }
    }, [dataFields])

    useEffect(() => {
        if (isDraft) return;

        if (isChecked && fieldsValue?.['registration_number']) getRegistrationCompany(fieldsValue['registration_number'])
    }, [fieldsValue['registration_number']])

    useEffect(() => {
        const isDraftSpecialist = !!profile?.is_draft;

        setIsDarft(isDraftSpecialist);
        setIsChecked(isDraftSpecialist);
        setShowInsurance(!!(profile?.company?.insurance_company_name || profile?.company?.insurance_max_payment_amount));
        
        isDraftSpecialist && setFieldsValue({
            registration_number: profile?.company?.registration_number || "",
            country: profile?.company?.country || "",
            address: profile?.company?.address || "",
            name: profile?.company?.name || "",
            tax_number: profile?.company?.tax_number || "",
            vat_number: profile?.company?.vat_number || "",
            insurance_company_name: profile?.company?.insurance_company_name || "",
            insurance_max_payment_amount: profile?.company?.insurance_max_payment_amount || "",
        })

    }, [profile]);

    return (
        <div className="registration-form__content">
            <div className="registration-form__title-wrapper">
                <h1 className="title title--h1">
                    {entities?.register_company_title?.value || ""}
                </h1>
                <p className="registration-form__text registration-form__text--gray">{entities?.register_company_description?.value || ""}</p>
            </div>
            <div className="form">
                <Form onSubmit={submitForm} error={error}>
                    {(invalidFields, error) => (
                        <>
                            {getFields(fields.top, invalidFields, error)}
                            {isChecked && (<>
                                {getFields(fields.main, invalidFields, error)}
                                <div className="form__row">
                                    <fieldset>
                                        <legend>
                                            {entities
                                                ?.register_company_has_insurance
                                                ?.value || ""}
                                        </legend>
                                        <RadioInput
                                            label={
                                                entities?.common_yes?.value || ""
                                            }
                                            name={"insurance"}
                                            onClick={() => setShowInsurance(true)}
                                            checked={showInsurance === true}
                                            classes={"custom-toggle--green"}
                                            invalidFields={invalidFields}
                                            error={error}
                                        />
                                        <RadioInput
                                            label={entities?.common_no?.value || ""}
                                            name={"insurance"}
                                            onClick={() => setShowInsurance(false)}
                                            checked={showInsurance === false}
                                            classes={"custom-toggle--green"}
                                            invalidFields={invalidFields}
                                            error={error}
                                        />
                                    </fieldset>
                                </div>
                                {showInsurance && (
                                    <>
                                        <div className="form__row">
                                            <fieldset className="form__field">
                                                <legend>
                                                    {entities
                                                        ?.register_company_insurance_data
                                                        ?.value || ""}
                                                </legend>
                                            </fieldset>
                                        </div>
                                        {getFields(fields.bottom, invalidFields, error)}
                                    </>
                                )}
                            </>)}
                            <div className="form__row form__controls form__controls--full-width form__controls--not-fixed-sm">
                                <button
                                    onClick={() => backForm()}
                                    className="btn btn--transparent"
                                    type="button"
                                >
                                    {entities?.common_return_button?.value || ""}
                                </button>
                                <button
                                    className="btn btn--transparent btn--bg-blue"
                                    type="submit"
                                    disabled={!fieldsValue['registration_number']}
                                >
                                    {entities?.common_continue_button?.value || ""}
                                </button>
                            </div>
                        </>
                    )}
                </Form>
            </div>
        </div>
    );
};

import { request } from "../request";
import {
    AttachNewBankCardRequestData,
    GetPaymentsParams,
} from "../../models/financesModel";

export const getBankCards = () =>
    request({
        url: "/cards",
        method: "GET",
    });

export const attachNewBankCard = (body: AttachNewBankCardRequestData) =>
    request({
        url: "/cards/attach",
        method: "POST",
        body,
    });

export const deleteBankCard = (body: any) =>
    request({
        url: "/cards/delete",
        method: "DELETE",
        body,
    });

export const getPayments = (params: GetPaymentsParams) =>
    request({
        url: "/payments",
        method: "GET",
        params: { ...params },
    });

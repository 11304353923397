import React, { useEffect, useState } from "react";
import Modal from "../../Modal";
import classNames from "classnames";
import Icon from "../../Icon";
import TextArea from "../../Form/TextArea";
import Form from "../../Form/Form";
import {
    useCreateReviewMutation,
    useUpdateOrderMutation,
    useUpdateReviewMutation,
} from "../../../store/orders/orders.api";
import { TranslationObject } from "../../../models/translationModal";
import { Link } from "react-router-dom";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import { endpoints } from "../../../store/directories/directories.api";
import { checkStringForForbiddenWords } from "../../../untils/reg";

interface Props {
    visible: boolean;
    onClose: () => void;
    id?: number;
    isSpecialist: boolean;
    score?: number;
    text?: string;
    order_id: number;
    entities?: TranslationObject;
}

const ReviewModal: React.FC<Props> = ({
    visible,
    onClose,
    id,
    isSpecialist,
    score,
    text,
    order_id,
    entities,
}) => {
    const { data: directories } = endpoints.getDirectories.useQuery();
    const currentLanguage = getCurrentLanguage();
    const [value, setValue] = useState<string>(text || "");
    const isEdit = id;
    const [activeScore, setActiveScore] = useState<number | null>(
        score || null
    );
    const [showLexiconError, setShowLexiconError] = useState<boolean>(false);

    const [showLexiconRegularError, setShowLexiconRegularError] =
        useState<boolean>(false);

    const [
        createReview,
        {
            isSuccess: isSuccessCreate,
            isError: isErrorCreateReview,
            error: createReviewError,
        },
    ] = useCreateReviewMutation();
    const [
        updateReview,
        {
            isSuccess: isSuccessUpdate,
            isError: isErrorUpdateReview,
            error: updateReviewError,
        },
    ] = useUpdateReviewMutation();
    const [updateOrder] = useUpdateOrderMutation();
    const linkBonusProgram = directories?.custom_pages?.find(
        (elem) => elem.code === "bonuses_and_partners"
    )?.path;
    const bonusText = entities?.order_create_review_bonuses_body_text?.value;
    const bonusTextLink = entities?.order_create_review_bonus_program?.value;
    const reviewScoreTitle = entities?.order_create_review_score_title?.value;

    useEffect(() => {
        if (isSuccessCreate || isSuccessUpdate) {
            onClose();
        }
    }, [isSuccessCreate, isSuccessUpdate]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (isErrorUpdateReview && updateReviewError?.status === 422) {
            setShowLexiconError(true);
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (isErrorCreateReview && createReviewError?.status === 422) {
            setShowLexiconError(true);
        }
    }, [
        isErrorCreateReview,
        isErrorUpdateReview,
        createReviewError,
        updateReviewError,
    ]);

    useEffect(() => {
        if (!value && showLexiconRegularError && visible) {
            setShowLexiconRegularError(false);
        }
    }, [value, showLexiconRegularError, visible]);

    const onSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();

        let body: Record<string, number | string> = {
            text: value,
            order_id,
        };

        if (!isSpecialist && activeScore) {
            body = {
                ...body,
                score: activeScore,
            };
        }
        if (!isEdit) {
            createReview({
                body,
            });
            updateOrder({
                body: {
                    status: "done",
                },
                id: order_id,
            });
        } else {
            if (id) {
                updateReview({
                    id,
                    body,
                });
            }
        }
    };

    const handleClickScore = (item: number) => {
        setActiveScore(item);
    };

    const handleChangeInputText = (value: string) => {
        setValue(value);

        if (visible) {
            const errorLexicon = checkStringForForbiddenWords(value)

            if (value && !showLexiconRegularError && errorLexicon) {
                setShowLexiconRegularError(true);
            } else if (showLexiconRegularError && !errorLexicon) {
                setShowLexiconRegularError(false);
            }

            if (showLexiconError) {
                setShowLexiconError(false);
            }
        }
    };

    return (
        <Modal
            modificators={["modal-review-pro"]}
            visible={visible}
            key={`modal_review_order_${order_id}`}
            onClose={() => onClose()}
            title={
                !isSpecialist
                    ? entities?.order_create_review_title?.value
                    : entities?.order_review_about_customer_title?.value
            }
        >
            <Form
                onSubmit={onSubmit}
                isSuccess={
                    (isEdit && isSuccessUpdate) || (!isEdit && isSuccessCreate)
                }
            >
                {(invalidFields, error) => (
                    <>
                        {!isSpecialist && (
                            <>
                                <p className="modal__content-text">
                                    {bonusText}
                                </p>
                                <Link
                                    className="modal-review-pro__link"
                                    to={`/${currentLanguage}/${linkBonusProgram}`}
                                >
                                    {bonusTextLink}
                                </Link>
                                <div className="modal-review-pro__indicator-star indicator-star">
                                    <h2 className="indicator-star__title">
                                        {reviewScoreTitle}
                                    </h2>
                                    <ul className="indicator-star__list">
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                                            (score, key) => (
                                                <li
                                                    className="indicator-star__item"
                                                    key={key}
                                                >
                                                    <button
                                                        className={classNames(
                                                            "indicator-star__item-button",
                                                            {
                                                                "is-active":
                                                                    activeScore &&
                                                                    key + 1 <=
                                                                    activeScore,
                                                            }
                                                        )}
                                                        type="button"
                                                        onClick={() =>
                                                            handleClickScore(
                                                                score
                                                            )
                                                        }
                                                    >
                                                        <Icon
                                                            type="star"
                                                            className="indicator-star__item-button-icon"
                                                            width={50}
                                                            height={50}
                                                        />
                                                        <span className="indicator-star__item-button-index">
                                                            {score ===
                                                                activeScore &&
                                                                score}
                                                        </span>
                                                    </button>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            </>
                        )}
                        {(showLexiconError || showLexiconRegularError) && (
                            <div className="notification-lexicon__error static">
                                {
                                    entities?.message_violates_terms_of_system
                                        ?.value
                                }
                            </div>
                        )}
                        <TextArea
                            type="text"
                            classes={["custom-textarea"]}
                            defaultValue={value}
                            onChange={handleChangeInputText}
                            invalidFields={invalidFields}
                            error={error}
                            placeholder={
                                isSpecialist
                                    ? entities
                                        ?.order_review_about_customer_placeholder
                                        ?.value
                                    : entities?.order_create_review_text?.value
                            }
                        />
                        <div className="modal__content-buttons">
                            <button
                                className="btn modal__content-button"
                                type="button"
                                onClick={onClose}
                            >
                                {entities?.common_cancel_button?.value}
                            </button>
                            <button
                                disabled={!isSpecialist && !activeScore}
                                onClick={onSubmit}
                                className={classNames(
                                    "btn modal__content-button modal__content-button--background",
                                    { "btn--bg-green": isSpecialist }
                                )}
                                type="button"
                            >
                                {entities?.login_save_button?.value}
                            </button>
                        </div>
                    </>
                )}
            </Form>
        </Modal>
    );
};

export default ReviewModal;

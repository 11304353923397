import React, { useState } from "react";
import Modal from "../../Modal";
import { TranslationObject } from "../../../models/translationModal";
import TextArea from "../../Form/TextArea";
import cn from "classnames";

interface NotifyAdministratorModalProps {
    entities?: TranslationObject;
    show: boolean;
    onClose: () => unknown;
    onSubmitModal: (comment: string) => unknown;
    isSpecialistOnUrl?: boolean;
}

export const NotifyAdministratorModal: React.FC<
    NotifyAdministratorModalProps
> = (props) => {
    const { show, onClose, entities, onSubmitModal, isSpecialistOnUrl } = props;
    const [comment, setComment] = useState<string>("");

    return (
        <Modal
            visible={show}
            onClose={onClose}
            title={entities?.suspension_title?.value}
            modalClasses={"modal-complaint modal-notify__administrator"}
        >
            <>
                <TextArea
                    type="textarea"
                    classes={
                        isSpecialistOnUrl ? ["custom-input__specialist"] : []
                    }
                    defaultValue={comment}
                    onChange={setComment}
                    maxLength={512}
                    lengthError={comment?.length > 512}
                    entities={entities}
                />
                <div className="modal__content-buttons">
                    <button
                        className="btn modal__content-button"
                        type="button"
                        onClick={onClose}
                    >
                        {entities?.common_cancel_button?.value}
                    </button>
                    <button
                        disabled={!comment || comment?.length > 512}
                        className={cn(
                            "btn modal__content-button modal__content-button--background",
                            {
                                "btn--green": isSpecialistOnUrl,
                            }
                        )}
                        type="button"
                        onClick={() => onSubmitModal(comment)}
                    >
                        {entities?.login_save_button?.value}
                    </button>
                </div>
            </>
        </Modal>
    );
};

import React, { useEffect } from "react";
import { useLoading } from "../../../../hooks/useLoading/useLoading";
import { TranslationObject } from "../../../../models/translationModal";
import {
    endpoints,
    useDeleteEmployeeCompanyMutation,
    useSetAssignEmployeeManagerMutation,
    useSetConfirmEmployeeMutation,
} from "../../../../store/user/user.api";
import { CompanyEmployee } from "./CompanyEmployee";

export const CompanyEmployees: React.FC<{ entities?: TranslationObject }> = ({
    entities,
}) => {
    const { data: employees = [] } = endpoints.getCompanyEmployees.useQuery();

    const [setConfirmEmployee, { isLoading: isLoadingConfirmEmployee }] =
        useSetConfirmEmployeeMutation();

    const [setAssignEmployeeManager, { isLoading: isLoadingAssignManager }] =
        useSetAssignEmployeeManagerMutation();

    const [deleteEmployeeCompany, { isLoading: isLoadingDeleteEmployee }] =
        useDeleteEmployeeCompanyMutation();

    const { setLoading } = useLoading();

    useEffect(() => {
        const loading =
            isLoadingConfirmEmployee ||
            isLoadingAssignManager ||
            isLoadingDeleteEmployee;
        setLoading(loading);
    }, [
        isLoadingConfirmEmployee,
        isLoadingAssignManager,
        isLoadingDeleteEmployee,
    ]);

    const assignEmployeeManager = (employeeId: number | string) => {
        if (isLoadingAssignManager) return;

        if (employeeId) {
            setAssignEmployeeManager({ employeeId });
        }
    };

    const deleteEmployee = (employeeId: number | string) => {
        if (isLoadingDeleteEmployee) return;

        if (employeeId) {
            deleteEmployeeCompany({ employeeId });
        }
    };

    const acceptOrRejectEmployee = (
        employeeId: string | number,
        value: boolean
    ) => {
        if (isLoadingConfirmEmployee) return;
        const requestData = {
            employeeId,
            body: {
                is_confirmed: value,
            },
        };

        setConfirmEmployee(requestData);
    };

    if (!employees.length) {
        return null;
    }

    return (
        <>
            <div className="specialist-profile__block">
                <h3 className="specialist-profile__subtitle title title--h3">
                    {entities?.order_company_employees?.value}
                </h3>
                <div className="profile-block grey-card">
                    {employees.map((employee) => (
                        <CompanyEmployee
                            key={employee?.id}
                            employee={{
                                id: employee?.id,
                                name: employee?.name || "",
                                familyName: employee?.family_name || "",
                                registerDate: employee?.registered_at || "",
                                isManager: !!employee?.is_company_manager,
                                isConfirmedManager:
                                    employee?.is_confirmed_by_manager,
                                services: employee?.services?.map(
                                    (service) => service?.service_type?.name
                                ),
                                verified:
                                    employee?.verification_status ===
                                    "verified",
                            }}
                            acceptOrRejectEmployee={acceptOrRejectEmployee}
                            assignEmployeeManager={assignEmployeeManager}
                            deleteEmployee={deleteEmployee}
                            entities={entities}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

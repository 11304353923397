import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    cancelOrder,
    checkAddress,
    createOrder,
    getCurrentSpecialists,
    getGeoCodingAutoComplete,
    getLocationByAddress,
    getOrderSpecialists,
    getUnsuitableSpecialists,
    sendQuestionAi,
    sendQuestionSearchHelper,
} from "./api";
import {
    CanselOrderRootObject,
    CreateOrderResponse,
    LocationByAddressPayload,
    LocationByAddressRootObject,
    OrderSpecialistsPayload,
    OrderSpecialistsRootObject,
    UpdateOrderObject,
    UpdateOrderPayload,
    SendQuestionAiResponse,
    SendQuestionAiPayload,
    QuestionAiTypeObject,
    SendQuestionSearchHelperResponse,
    SendQuestionSearchHelperPayload,
    QuestionSearchHelperTypeObject,
} from "../../models/orderModel";
import {
    CurrentSpecialistsPayload,
    CurrentSpecialistsRootObject,
    GeoCodingAutoCompleteRootObject,
} from "../../models/userModels";
import { changeHeaders } from "../request";
import { EmptyPayload, IdProps, StringParams } from "../models";

export const orderApi = createApi({
    reducerPath: "orderApi/api",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
        prepareHeaders: (headers, { getState }) =>
            changeHeaders(headers, { getState }),
    }),
    tagTypes: ["Localized"],
    endpoints: (build) => ({
        createOrder: build.mutation<UpdateOrderPayload, CreateOrderResponse>({
            query: ({ body }) => createOrder({ body }),
            transformResponse: (response: UpdateOrderObject) =>
                response?.payload,
        }),
        getLocationByAddress: build.mutation<
            LocationByAddressPayload,
            Record<string, string>
        >({
            query: ({ address }) => getLocationByAddress({ address }),
            transformResponse: (response: LocationByAddressRootObject) =>
                response?.payload,
        }),
        cancelOrder: build.mutation<CanselOrderRootObject, IdProps>({
            query: ({ id }) => cancelOrder({ id }),
        }),
        getOrderSpecialists: build.query<OrderSpecialistsPayload, StringParams>(
            {
                query: ({ params }) => getOrderSpecialists({ params }),
                providesTags: [{ type: "Localized" }],
                transformResponse: (response: OrderSpecialistsRootObject) =>
                    response?.payload,
            }
        ),
        getCurrentSpecialists: build.query<
            CurrentSpecialistsPayload[],
            StringParams
        >({
            query: ({ params }) => getCurrentSpecialists({ params }),
            providesTags: [{ type: "Localized" }],
            transformResponse: (response: CurrentSpecialistsRootObject) =>
                response?.payload,
        }),
        getUnsuitableSpecialists: build.query<number[], StringParams>({
            query: ({ params }) => getUnsuitableSpecialists({ params }),
            transformResponse: (response: CurrentSpecialistsRootObject) =>
                response?.payload,
        }),
        checkAddress: build.query<EmptyPayload, StringParams>({
            query: ({ params, id }) => checkAddress({ params, id }),
        }),
        getGeoCodingAutoComplete: build.query<
            {
                suggestion: string;
                types: any;
            }[],
            { params: { address: string } }
        >({
            query: ({ params }) => getGeoCodingAutoComplete({ params }),
            transformResponse: (response: GeoCodingAutoCompleteRootObject) =>
                response?.payload,
        }),
        sendQuestionAi: build.mutation<QuestionAiTypeObject[], SendQuestionAiResponse>({
            query: ({ message, show_all, use_cache }) => sendQuestionAi({ message, show_all, use_cache }),
            transformResponse: (response: SendQuestionAiPayload) =>
                response?.payload,
        }),
        sendQuestionSearchHelper: build.mutation<QuestionSearchHelperTypeObject[], SendQuestionSearchHelperResponse>({
            query: ({ message }) => sendQuestionSearchHelper({ message }),
            transformResponse: (response: SendQuestionSearchHelperPayload) =>
                response?.payload,
        }),
    }),
});

export const {
    endpoints,
    useCreateOrderMutation,
    useGetLocationByAddressMutation,
    useCancelOrderMutation,
    useSendQuestionAiMutation,
    useSendQuestionSearchHelperMutation,
    util,
} = orderApi;

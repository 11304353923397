import classNames from "classnames";
import React, { useEffect, useState } from "react";
import ValidationError from "./ValidationError";
import DeleteButton from "./DeleteButton";
import CustomValueInput from "./CustomValueInput";
import { Category, SubTypesPayload } from "../../models/directoryModel";
import _, { entries } from "lodash";
import Input from "../Form/Input";
import { TranslationObject } from "../../models/translationModal";

interface Props {
    entities?: TranslationObject;
    currentItem?: number | null | string;
    currentItems?: number[] | string | null;
    isMultiple?: boolean;
    error?: any;
    hiddenCloseButton?: boolean;
    deleteItem: (value: number, name?: string) => void;
    validationError?: any;
    items: Category[] | SubTypesPayload[];
    onSelect: (value: number, slug: string, name?: string) => void;
    withCustomValue?: boolean;
    onChange?: any;
    isBig?: boolean;
    isMedium?: boolean;
    list: Category[] | SubTypesPayload[];
    maxLength?: number;
}

const SelectItemToInput: React.FC<Props> = ({
    entities,
    currentItem,
    currentItems,
    isMultiple,
    error,
    hiddenCloseButton,
    deleteItem,
    validationError,
    items,
    onSelect,
    withCustomValue,
    onChange,
    isBig,
    isMedium,
    list,
    maxLength,
}) => {
    const hasCurrent = !isMultiple
        ? currentItem && typeof currentItem === "number"
        : currentItems && Array.isArray(currentItems);
    const renderLabel = (current: number | string) =>
        list?.find((item) => Number(item.id) === Number(current))?.name;
    const [search, setSearch] = useState<string>("");

    useEffect(() => {
        setSearch("");
    }, [currentItem, currentItems])

    const visibleCustomInput = () => {
        if (withCustomValue) {
            if (isMultiple) {
                return !(
                    Array.isArray(currentItems) && currentItems.length > 0
                );
            }
            return typeof currentItem === "string";
        }
        return false;
    };

    const handleOnChange = (value: string) => {
        setSearch(value);
        onChange(value);
    };

    return (
        <>
            <div
                className={classNames("form__row ", {
                    "form__row--big-margin": isBig,
                    "form__row--medium-margin": isMedium,
                })}
            >
                <div
                    className={classNames(
                        "new-order-start__field new-order-start__field--selected",
                        {
                            "is-invalid": error && !hasCurrent,
                        }
                    )}
                >
                    {hasCurrent && (
                        <>
                            {isMultiple &&
                                Array.isArray(currentItems) &&
                                currentItems?.map((current) => (
                                    <span
                                        className="new-order-start__toggle is-active"
                                        key={_.uniqueId("address")}
                                    >
                                        {renderLabel(current)}
                                        <DeleteButton
                                            onClick={() => deleteItem(current)}
                                            hiddenCloseButton={
                                                hiddenCloseButton
                                            }
                                        />
                                    </span>
                                ))}
                            {!isMultiple && currentItem && (
                                <span className="new-order-start__toggle is-active">
                                    {renderLabel(currentItem)}
                                    <DeleteButton
                                        onClick={() =>
                                            typeof currentItem === "number" &&
                                            deleteItem(currentItem)
                                        }
                                        hiddenCloseButton={hiddenCloseButton}
                                    />
                                </span>
                            )}
                        </>
                    )}
                    <CustomValueInput
                        visible={visibleCustomInput()}
                        disabled={
                            isMultiple
                                ? Array.isArray(currentItems) &&
                                  currentItems.length > 0
                                : typeof currentItem === "number"
                        }
                        maxLength={maxLength}
                        value={
                            isMultiple
                                ? typeof currentItems === "string"
                                    ? currentItems
                                    : ""
                                : typeof currentItem === "string"
                                ? currentItem
                                : ""
                        }
                        onChange={handleOnChange}
                    />
                    {
                        !visibleCustomInput() &&
                            <Input
                                value={search}
                                onChange={handleOnChange}
                                classes={["new-order-start__search-field"]}
                                type="search"
                                placeholder=""
                            />
                    }
                </div>
                <ValidationError
                    validationError={validationError}
                    currentItem={currentItem}
                    isMultiple={isMultiple}
                    currentItems={currentItems}
                />
            </div>
            <div className="form__row">
                {items.map((elem) => (
                    <button
                        key={elem?.id}
                        className="new-order-start__toggle"
                        type="button"
                        onClick={() => onSelect(elem?.id, elem?.slug, elem?.name)}
                    >
                        {elem?.name}
                    </button>
                ))}
            </div>
        </>
    );
};

export default SelectItemToInput;

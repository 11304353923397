class SiteAPI {
    message: number;
    data: string;

    constructor(message: number, payload: any) {
        this.message = message;
        this.data = payload;
        Object.setPrototypeOf(this, Error.prototype);
    }
}

export default SiteAPI;

import React, { useEffect, useState } from "react";
import { OptionProps, Props } from "react-select";

import Select from "../Form/Select";
import {
    getActiveLanguage,
    getAgreeWithCookie,
    getLanguages,
    getPath,
    getUpdateIsUserChangedLanguage,
} from "../../store/directories/selectors";
import { getCookie, setCookie } from "../../untils/cookies";
import { useActions } from "../../hooks/actions";
import trim from "lodash/trim";
import { generatePath, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { util as directoriesUtil } from "../../store/directories/directories.api";
import { util as financesUtil } from "../../store/finances/finances.api";
import { util as userUtil } from "../../store/user/user.api";
import { util as ordersUtil } from "../../store/orders/orders.api";
import { util as registrationUtil } from "../../store/registration/registration.api";
import { util as notificationsUtil } from "../../store/notifications/notifications.api";
import { util as orderUtil } from "../../store/order/order.api";
import { util as advertisementUtil } from "../../store/advertisement/advertisement.api";

interface SelectLanguageProps extends Props<OptionProps> {
    className: string;
}

const SelectLanguage: React.FC<SelectLanguageProps> = ({ className }) => {
    const { updateLanguage, updateIsUserChangedLanguage } = useActions();
    const dispatch = useDispatch();
    const options = getLanguages();
    const language = "language";
    const navigate = useNavigate();
    const cookieLanguage = getCookie(language);
    const activeLanguage = getActiveLanguage();
    const agreeWithCookie = getAgreeWithCookie();
    const isUserChangedLanguage = getUpdateIsUserChangedLanguage();

    const defaultLanguage = {
        value: "",
        label: "",
    };

    const [option, setOption] = useState(options[0] || defaultLanguage);

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        checkLanguage();
        changeUrl();
    }, [activeLanguage]);

    const load = () => {
        checkLanguage();
    };

    const changeUrl = () => {
        const pathname = document.location.pathname;
        const path = trim(pathname, "/").split("/");
        const defaultLanguage = path[0]?.length > 0 ? path[0] : "el";
        const search = document.location.search


        if (path[0] !== activeLanguage) {

            let url = `/:currentLanguage/${path
                .slice(1, path?.length)
                .join("/")}`

            if (search) {
                url = url + search
            }

            navigate(
                generatePath(url, {
                    currentLanguage:
                        !isUserChangedLanguage && !cookieLanguage
                            ? defaultLanguage
                            : activeLanguage,
                })
            );
        }
    };

    const checkLanguage = () => {
        if (cookieLanguage || activeLanguage) {
            const languageInUrl = getPath()[0];

            const currentLanguage =
                options.find((option) => option.value === cookieLanguage) ||
                    isUserChangedLanguage
                    ? options.find((option) => option.value === activeLanguage)
                    : options.find(
                        (option) => option.value === languageInUrl
                    ) || options[0];
            if (
                activeLanguage !== languageInUrl &&
                !cookieLanguage &&
                !isUserChangedLanguage
            ) {
                updateLanguage(languageInUrl);
            }
            if (currentLanguage) {
                onChangeLanguage(currentLanguage);
            }
            return;
        }
        onChangeLanguage(options[0]);
    };

    const onChangeLanguage = (option: any) => {
        const languageInUrl = getPath()[0];
        setOption(option);
        updateIsUserChangedLanguage(true);
        if (
            activeLanguage !== option.value ||
            cookieLanguage !== option.value ||
            languageInUrl !== option.value
        ) {
            updateLanguage(option.value);
            dispatch(directoriesUtil.invalidateTags(["Localized"]));
            dispatch(userUtil.invalidateTags(["Localized"]));
            dispatch(ordersUtil.invalidateTags(["Localized"]));
            dispatch(registrationUtil.invalidateTags(["Localized"]));
            dispatch(orderUtil.invalidateTags(["Localized"]));
            dispatch(notificationsUtil.invalidateTags(["Localized"]));
            dispatch(financesUtil.invalidateTags(["Localized"]));
            dispatch(advertisementUtil.invalidateTags(["Localized"]));
        }
        if (agreeWithCookie) {
            setCookie(language, option.value);
        }
    };

    return (
        <Select
            value={option}
            options={options || []}
            isDisabled={false}
            className={className}
            onChange={onChangeLanguage}
        />
    );
};
export default SelectLanguage;

import { useAppDispatch } from "../../hooks/useAppDispatch";
import { OrderItem } from "../../models/ordersModel";
import { store } from "../../store";
import { util } from "../../store/orders/orders.api";
import { IRespondedSpecialist } from './AppSocketProvider.types'

export const useUpdatePinnedChats = () => {
    const dispatch = useAppDispatch();

    return (
        orderId: number,
        params: Record<keyof OrderItem, OrderItem[keyof OrderItem]>,
        responded_specialist: IRespondedSpecialist,
        isSpecialist: boolean
    ) => {
        const state = store.getState();
        const endpoints = util.selectInvalidatedBy(state, [
            { type: "Orders", id: "getPinnedChats" },
        ]);

        endpoints.forEach((endpoint) => {
            const { originalArgs } = endpoint;

            dispatch(
                util.updateQueryData(
                    "getPinnedChats",
                    originalArgs,
                    (chats) => {
                        chats.forEach((chat) => {
                            if (isSpecialist && chat.order.id === orderId) {
                                return chat.unviewed_messages_count =
                                    params.unviewed_chat_messages_count || 0
                            }

                            if (chat.order.id === orderId && chat?.specialist?.id === responded_specialist?.id) {
                                chat.unviewed_messages_count =
                                    params.unviewed_chat_messages_count || 0
                            }
                        });
                    }
                )
            );
        });
    };
};

import React, { useState } from "react";
import _ from "lodash";
import { TranslationObject } from "../../../models/translationModal";
import OrderClosingConfirmation from "../../OrderCard/OrderClosingConfirmation";
import classNames from "classnames";

interface Props {
    entities?: TranslationObject;
    isDropDown: boolean;
    isDraft?: boolean;
    isActive: boolean;
    changeStatus: (value: string) => void;
    isPrimary: boolean;
}

const CloseOrderButton: React.FC<Props> = ({
    entities,
    isDraft,
    isDropDown = true,
    isActive,
    changeStatus,
    isPrimary,
}) => {
    const text = isDraft ? entities?.order_archive_delete?.value : entities?.order_close?.value;
    const [showConfirmationClose, setShowConfirmationClose] = useState(false);

    if (!isActive && !isDraft) {
        return null;
    }

    return (
        <>
            {isDropDown ? (
                <div
                    className="dotted-menu__item"
                    onClick={() => setShowConfirmationClose(true)}
                    key={_.uniqueId("button_dropdown_order")}
                >
                    <div className="dotted-menu__item-link">{text}</div>
                </div>
            ) : (
                <button
                    onClick={() => setShowConfirmationClose(true)}
                    className={classNames("btn active-order__button", {
                        "active-order__button--draft": !isPrimary,
                    })}
                    type="button"
                >
                    {text}
                </button>
            )}
            <OrderClosingConfirmation
                visible={showConfirmationClose}
                onClose={() => setShowConfirmationClose(false)}
                entities={entities}
                isDraft={isDraft}
                changeStatus={changeStatus}
            />
        </>
    );
};

export default CloseOrderButton;

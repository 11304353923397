import RadioInput from "../Form/RadioInput";
import SelectInput from "../Form/Select";
import React from "react";
import { getDays, getRanges, getWeekdays } from "../../store/order/selectors";
import { TranslationObject } from "../../models/translationModal";
import { getCurrentLanguage } from "../../store/directories/selectors";

interface Props {
    entities?: TranslationObject;
    handleChange: (value: string) => void;
    repeatRange?: string;
    repeatDay: {
        value: number;
        label: number | string;
    } | null;
    handleChangeRangeDay: any;
}

const RepeatOrder: React.FC<Props> = ({
    entities,
    handleChange,
    repeatRange,
    repeatDay,
    handleChangeRangeDay,
}) => {
    const currentLanguage = getCurrentLanguage();
    const ranges = getRanges(entities);
    const days = getDays();
    const weekItems = getWeekdays(currentLanguage);
    const weekdays = weekItems?.map((day, key) => ({
        value: key + 1,
        label: day,
    }));

    return (
        <>
            {ranges?.map((range) => (
                <RadioInput
                    key={range?.name}
                    name={range?.name}
                    onClick={() => handleChange(range?.name)}
                    label={range?.label || ""}
                    checked={repeatRange ? repeatRange === range?.name : false}
                />
            ))}
            <RadioInput
                onClick={() => handleChange("repeat_week_day")}
                label={entities?.order_periodicity_every?.value || ""}
                checked={
                    repeatRange ? repeatRange === "repeat_week_day" : false
                }
            >
                <SelectInput
                    options={weekdays}
                    value={
                        repeatRange === "repeat_week_day"
                            ? repeatDay
                            : weekdays[0]
                    }
                    isDisabled={repeatRange !== "repeat_week_day"}
                    onChange={handleChangeRangeDay}
                    className="custom-select select-day select-week-day"
                />
            </RadioInput>
            <RadioInput
                onClick={() => handleChange("repeat_month_day")}
                label={entities?.order_periodicity_every_month?.value || ""}
                checked={repeatRange === "repeat_month_day" ?? false}
            >
                <>
                    <SelectInput
                        value={
                            repeatRange === "repeat_month_day"
                                ? repeatDay
                                : days[0]
                        }
                        isDisabled={repeatRange !== "repeat_month_day"}
                        options={days}
                        className="custom-select select-day"
                        onChange={handleChangeRangeDay}
                    />
                    <span className="custom-toggle__label">
                        {entities?.order_periodicity_day?.value}
                    </span>
                </>
            </RadioInput>
        </>
    );
};

export default RepeatOrder;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IChatMessage } from "../../models/chatModels";

interface IChatSliceInitialState {
    chatMessages: IChatMessage[];
    isActiveChat: boolean;
    isUpdateChat: boolean;
    isCreateNewChat: boolean;
}

const chatSliceInitialState: IChatSliceInitialState = {
    chatMessages: [],
    isActiveChat: false,
    isUpdateChat: false,
    isCreateNewChat: false,
};

export const chatSlice = createSlice({
    name: "chat",
    initialState: chatSliceInitialState,
    reducers: {
        resetChatState: () => chatSliceInitialState,
        setChatMessages: (state, action: PayloadAction<IChatMessage[]>) => {
            state.chatMessages = action.payload;
        },
        addChatMessages: (state, action: PayloadAction<IChatMessage[]>) => {
            state.chatMessages = [...action.payload, ...state.chatMessages];
        },
        addChatMessage: (state, action: PayloadAction<IChatMessage>) => {
            if (
                state.chatMessages.find((el) => el.date === action.payload.date)
            ) {
                state.chatMessages.forEach((messagesElement) => {
                    if (messagesElement.date === action.payload.date) {
                        messagesElement.messages.push(
                            ...action.payload.messages
                        );
                    }
                });
            } else {
                state.chatMessages = [...state.chatMessages, action.payload];
            }
        },
        setIsActiveChat: (state, action: PayloadAction<boolean>) => {
            state.isActiveChat = action.payload;
        },
        setIsUpdateChat: (state, action: PayloadAction<boolean>) => {
            state.isUpdateChat = action.payload;
        },
        setIsCreateNewChat: (state, action: PayloadAction<boolean>) => {
            state.isCreateNewChat = action.payload;
        },
    },
});

export const chatReducer = chatSlice.reducer;
export const chatActions = chatSlice.actions;

import {
    endpoints,
    useSendNotifyAdminMutation,
} from "../../store/user/user.api";
import { useEffect, useState } from "react";
import { IStatusChangeInfo } from "../../models/userModels";
import moment from "moment";
import { TranslationObject } from "../../models/translationModal";

export const useSuspendedAccess = (
    entities?: TranslationObject
): {
    isSuspendedUser: boolean;
    pendingIsSuspended: boolean;
    suspendedChangeInfo: IStatusChangeInfo;
    suspendedText: string | null;
    showButtonEliminareComments: boolean;
    sendNotifyAdmin: (body: { comment: string }) => unknown;
} => {
    const [sendNotifyAdmin] = useSendNotifyAdminMutation();

    const [isSuspendedUser, setIsSuspendedUser] = useState<boolean>(false);

    const [suspendedChangeInfo, setSuspendedChangeInfo] =
        useState<IStatusChangeInfo>({
            change_status_at: null,
            comment: null,
            status_change_reason: null,
            suspension_days: null,
        });

    const [suspendedText, setSuspendedText] = useState<string | null>(null);

    const [showButtonEliminareComments, setShowButtonEliminareComments] =
        useState<boolean>(false);

    const {
        data: profile,
        isSuccess,
        isLoading: pendingIsSuspended,
    } = endpoints.getProfile.useQuery();

    const { is_suspended, status_change_info } = profile || {};

    useEffect(() => {
        if (isSuccess) {
            setIsSuspendedUser(!!is_suspended);

            if (status_change_info) {
                setSuspendedChangeInfo(status_change_info);
            }
        }
    }, [is_suspended, status_change_info, isSuccess]);

    useEffect(() => {
        const { change_status_at, suspension_days, status_change_reason } =
            suspendedChangeInfo || {};
        // entities?.common_account_is_suspended?.value?.
        if (change_status_at && suspension_days) {
            type datesType = {
                ["{:suspended_at}"]: string;
                ["{:activate_at}"]: string;
            };
            const dates: datesType = {
                ["{:suspended_at}"]:
                    moment(change_status_at).format("DD MMMM YYYY HH:mm"),
                ["{:activate_at}"]: moment(change_status_at)
                    .add(suspension_days || 1, "days")
                    .format("DD MMMM YYYY HH:mm"),
            };

            const text =
                entities?.common_account_is_suspended?.value?.replace(
                    /\{:suspended_at\}|\{:activate_at\}/gi,
                    (matched) => dates[matched as keyof datesType]
                ) || "";
            setSuspendedText(text);
            setShowButtonEliminareComments(false);
        } else if (status_change_reason) {
            const text =
                entities?.common_account_is_suspended_until_contravention_corrected?.value?.replace(
                    /\{ :status_change_reason \}/gi,
                    `<span class='text-reason__suspended'>${status_change_reason}</span>`
                ) || "";
            setSuspendedText(text);
            setShowButtonEliminareComments(true);
        }
    }, [entities, suspendedChangeInfo]);

    return {
        isSuspendedUser,
        pendingIsSuspended,
        suspendedChangeInfo,
        suspendedText,
        showButtonEliminareComments,
        sendNotifyAdmin,
    };
};

import React, { useEffect } from "react";
import Icon from "../Icon";
import { Image } from "../../models/userModels";
import { TranslationObject } from "../../models/translationModal";

const PhotoSlider: React.FC<{
    photos: Image[];
    entities?: TranslationObject;
}> = ({ photos, entities }) => {
    const container = document.querySelector(".slider__wrapper") as HTMLElement;
    const item = document.querySelector(".slider__slide") as HTMLElement;
    const goLeft = document.querySelector(".go_left") as HTMLElement;
    const goRight = document.querySelector(".go_right") as HTMLElement;
    let translate = 0;

    useEffect(() => {
        if (goLeft && item) {
            goLeft.addEventListener("click", () => scroll(false));
        }
        if (goRight && item) {
            goRight.addEventListener("click", () => scroll(true));
        }
        return () => {
            if (goLeft && goRight) {
                goLeft.removeEventListener("click", () => scroll(false));
                goRight.removeEventListener("click", () => scroll(true));
            }
        };
    }, [goLeft, goRight]);

    const scroll = (isRight: boolean) => {
        const itemWidth = item?.offsetWidth;
        translate = isRight ? translate + itemWidth : translate - itemWidth;
        if (container) {
            container.scrollTo({
                left: translate,
                behavior: "smooth",
            });
        }
    };

    return (
        <div className="slider">
            <h3 className="slider__title title title--h4">
                {entities?.specialist_details_service_photo?.value}
            </h3>
            <div className="slider__wrapper no-scrollbar">
                {translate > 1 && (
                    <button
                        className="slider__btn btn btn--nb go_left"
                        type="button"
                        aria-label="toggler"
                    >
                        <Icon type="arrow-right" width={9} height={18} />
                    </button>
                )}
                {photos?.map((elem, key) => (
                    <div className="slider__slide" key={key}>
                        <picture>
                            <img
                                src={elem?.original_url}
                                width="190"
                                height="190"
                                alt="slide-1"
                            />
                        </picture>
                    </div>
                ))}
                {photos?.length > 4 && (
                    <button
                        className="slider__btn btn btn--nb go_right"
                        type="button"
                        aria-label="toggler"
                    >
                        <Icon type="arrow-right" width={9} height={18} />
                    </button>
                )}
            </div>
        </div>
    );
};

export default PhotoSlider;

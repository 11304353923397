import { useSelector } from "react-redux";
import { RootState } from "../index";
import { Block } from "../../models/directoryModel";
import trim from "lodash/trim";
import { reduce } from "lodash";
import { endpoints } from "./directories.api";
import { getUserRole } from "../user/selectors";

export const getLanguages = () => {
    return [
        { label: "EL", value: "el" },
        { label: "EN", value: "en" },
        { label: "РУС", value: "ru" },
    ];
};

export const getCurrentLanguage = () =>
    useSelector((state: RootState) => state.directories.language);

export const getPageId = (data: any, code: string, name: string) =>
    data?.[name]?.find((page: any) => page?.code === code)?.id;

export const isCustomerUrl = () =>
    window.location.pathname.includes("customer");

export const isSpecialistUrl = () => getPath()[1] === "specialist";

export const getPath = () => trim(window?.location?.pathname, "/").split("/");

export const getArrayToObject = (data: any) => {
    if (data && !Array.isArray(data)) {
        return {};
    }
    return reduce(
        data,
        (blocks: any, block: Block) => {
            blocks[block?.code] = block;
            return blocks;
        },
        {}
    );
};

export const getTranslations = () => {
    return endpoints.getTranslations.useQuery();
};

export const getCustomPage = ({ id }: { id: number }) => {
    return endpoints.getCustomPage.useQuery(
        {
            id: id,
            url: "custom_pages",
        },
        { skip: !id }
    );
};

export const getTextPage = ({ id }: { id: number }) => {
    return endpoints.getTextPage.useQuery(
        {
            id: id,
            url: "text_pages",
        },
        { skip: !id }
    );
};

export const getDirectories = () => endpoints.getDirectories.useQuery();

export const getLanguagesOptions = () => endpoints.getLanguages.useQuery();

declare global {
    interface Window {
        grecaptcha: any;
    }
}

export const checkReCaptcha = (
    siteKey: string,
    action: string,
    callback: (token: string) => void
) => {
    // В случае, если капча отключена в .env, отправляем на сервер рандомный токен
    if (!window.grecaptcha) {
        callback(Math.random().toString(36).slice(2, 7));
    }

    window?.grecaptcha?.ready(function () {
        window?.grecaptcha?.execute(siteKey, { action }).then((res: string) => {
            if (res) {
                callback(res);
            }
        });
    });
};

export const getUrl = (url: string | null) => {
    const currentLanguage = getCurrentLanguage();
    const userRole = getUserRole();
    if (userRole === "guest")
        return url ? `/${currentLanguage}/${url}` : `/${currentLanguage}/`;

    return url
        ? `/${currentLanguage}/${userRole}/${url}`
        : `/${currentLanguage}/${userRole}/`;
};

export const getActiveLanguage = () =>
    useSelector((state: RootState) => state?.directories?.language);

export const getAgreeWithCookie = () =>
    useSelector((state: RootState) => state?.directories?.agreeWithCookie);

export const getUpdateIsUserChangedLanguage = () =>
    useSelector(
        (state: RootState) => state?.directories?.isUserChangedLanguage
    );

export const checkIsMobile = () =>
    useSelector((state: RootState) => state?.directories?.isMobile);

export const checkIsTablet = () =>
    useSelector((state: RootState) => state?.directories?.isTablet);

export const checkIsDesktop = () =>
    useSelector((state: RootState) => state?.directories?.isDesktop);

export const isSafariBrowser = () =>
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


import { request } from "../request";
import { IdProps, StringParams } from "../models";
import { editOrderState } from "./editOrder.slice";
import { createOrderState } from "./createOrder.slice";

export const createOrder = ({ body }: { body: createOrderState }) =>
    request({
        url: `orders`,
        method: "POST",
        body,
    });

export const updateOrder = ({
    body,
    id,
}: {
    body: editOrderState;
    id: number;
}) =>
    request({
        url: `orders/${id}`,
        method: "PUT",
        body,
    });

export const getLocationByAddress = ({ address }: Record<string, string>) =>
    request({
        url: `geocoding/by_address?address=${address}`,
    });

export const cancelOrder = ({ id }: IdProps) =>
    request({
        url: `orders/${id}/destroy`,
        method: "POST",
    });

export const getOrderSpecialists = ({ params }: StringParams) => {
    return request({
        url: `specialists/search_for_order?` + params,
        method: "GET",
    });
};

export const getCurrentSpecialists = ({ params }: StringParams) => {
    return request({
        url: `specialists/selected_list?` + params,
        method: "GET",
    });
};

export const getUnsuitableSpecialists = ({ params }: StringParams) => {
    return request({
        url: `specialists/unsuitable_list?` + params,
        method: "GET",
    });
};

export const checkAddress = ({
    params,
    id,
}: {
    params: StringParams;
    id: IdProps;
}) => {
    return request({
        url: `specialists/${id}/address_check?` + params,
        method: "GET",
    });
};

export const getGeoCodingAutoComplete = ({
    params,
}: {
    params: { address: string };
}) => {
    return request({
        url: `geocoding/autocomplete?` + params,
        method: "GET",
    });
};
export const createChat = ({
    body,
}: {
    body: { order_id: number; specialist_id?: number };
}) =>
    request({
        url: `chats`,
        body,
        method: "POST",
    });

export const getOrdersStatusesList = () =>
    request({
        url: "/orders/statuses_list",
    });

export const sendQuestionAi = ({ message, show_all, use_cache }: { message: string, show_all?: number, use_cache?: number }) =>
    request({
        url: `/search-helper/send`,
        body: {
            message,
            ...(show_all ? { show_all } : {}),
            ...(use_cache !== undefined ? { use_cache } : {})
        },
        method: "POST",
    });

export const sendQuestionSearchHelper = ({ message }: { message: string }) => 
    request({
        url: `/search-helper/search`,
        body: {
            message,
        },
        method: "POST",
    });


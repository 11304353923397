import React from "react";
import { BankCard } from "../BankCard";
import { AddNewCard } from "../AddNewCard";
import { IBankCard } from "../../../../models/financesModel";

interface OtherCardsProps {
    otherCards: IBankCard[];
    openAddCardModal: () => unknown;
    deleteBankCard: (cardId: string) => unknown;
}

export const OtherCards: React.FC<OtherCardsProps> = ({
    otherCards,
    openAddCardModal,
    deleteBankCard,
}) => {
    return (
        <div className="other-cards">
            {otherCards?.map((card) => (
                <BankCard
                    key={card?.id}
                    card={card}
                    deleteBankCard={deleteBankCard}
                />
            ))}
            <AddNewCard openAddCardModal={openAddCardModal} />
        </div>
    );
};

import { ProfilePayload } from "../../../../models/userModels";
import { useSelector } from "react-redux";
import { specialistRoleSelector } from "../../../../store/finances/finances.selectors";
import { useActions } from "../../../../hooks/actions";
import { useEffect } from "react";

export const useSpecialistRole = (profile?: ProfilePayload) => {
    const { is_company_manager, company, is_confirmed_by_manager } =
        profile || {};

    const specialistRole = useSelector(specialistRoleSelector);

    const { setSpecialistRole } = useActions();

    useEffect(() => {
        if (is_company_manager && is_confirmed_by_manager && company?.id) {
            setSpecialistRole("manager");
        } else if (
            company?.id &&
            !is_company_manager &&
            !is_confirmed_by_manager
        ) {
            setSpecialistRole("employee");
        } else {
            setSpecialistRole("private person");
        }
    }, [is_company_manager, company, is_confirmed_by_manager]);

    return {
        specialistRole,
    };
};

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { endpoints } from "../../store/orders/orders.api";
import { TranslationObject } from "../../models/translationModal";
import ChatCard from "./ChatCard";
import { ProfilePayload } from "../../models/userModels";
import cn from "classnames";
import Loader from "../Loader";
import { checkIsDesktop } from "../../store/directories/selectors";
import Icon from "../Icon";

interface Props {
    id?: number;
    entities?: TranslationObject;
    profile?: ProfilePayload;
    classNames?: string;
    showIfNotChats?: boolean;
    isSpecialist?: boolean;
}

const ChatsSidebar: React.FC<Props> = ({
    entities,
    id,
    profile,
    classNames,
    showIfNotChats = false,
    isSpecialist = false,
}) => {
    const params = id ? `order_id=${id}` : undefined;
    const {
        data: chats,
        refetch,
        isLoading,
        isFetching,
    } = endpoints.getPinnedChats.useQuery({
        params,
    });
    const showLoader = isLoading || isFetching;
    const [showChats, setShowChats] = useState<boolean>(false);
    const isDesktop = checkIsDesktop();

    useEffect(() => {
        if (profile?.id && !id) {
            refetch();
        }
    }, [profile, params, id]);

    if (chats && chats?.length < 1 && !showIfNotChats) {
        return null;
    }

    const handleClickChats = () => {
        if (!isDesktop) {
            setShowChats(!showChats);
        }
    };

    return (
        <div
            className={cn("pinned-chats", {
                hide: showIfNotChats && !chats?.length,
                [`${classNames}`]: !!classNames,
                "row-2": !isSpecialist,
                "is-open": showChats,
            })}
        >
            <button
                className="pinned-chats__button"
                type="button"
                onClick={handleClickChats}
            >
                <span className="pinned-chats__title">
                    {entities?.chat_pinned_chats?.value}
                </span>
            </button>
            {showLoader ? (
                <Loader />
            ) : (
                <div className={cn("pinned-chats__wrapper")}>
                    <ul className="pinned-chats__list">
                        <div className="pinned-chats__mobile-button">
                            <button
                                className="pinned-chats__mobile-title"
                                type="button"
                                onClick={() => setShowChats(false)}
                            >
                                {entities?.chat_pinned_chats?.value}
                                <Icon type="arrow-right-pagination" />
                            </button>
                        </div>
                        {chats?.map((chat) => (
                            <ChatCard
                                key={_.uniqueId("chat")}
                                profile={profile}
                                chat={chat}
                                entities={entities}
                            />
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
export default ChatsSidebar;

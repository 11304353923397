import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    confirmations,
    login,
    sendPhoneConfirmation,
    storePassword,
    storeProfile,
} from "./api";
import {
    bodyLoginEmailRequest,
    bodyLoginPhoneRequest,
    bodyRegister,
    confirmationObject,
    confirmationsRequest,
} from "../../models/authModel";
import { ProfileRootObject } from "../../models/userModels";
import { changeHeaders } from "../request";

export const authApi = createApi({
    reducerPath: "auth/api",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
        prepareHeaders: (headers, { getState }) =>
            changeHeaders(headers, { getState }),
    }),
    endpoints: (build) => ({
        loginByPhone: build.mutation<confirmationObject, bodyLoginPhoneRequest>(
            {
                query: ({ body, type }) => login({ body, type }),
            }
        ),
        loginByEmail: build.mutation<confirmationObject, bodyLoginEmailRequest>(
            {
                query: ({ body, type = "by_email" }) => login({ body, type }),
            }
        ),
        confirmations: build.mutation<confirmationObject, confirmationsRequest>(
            {
                query: ({ id, token, user_id }) => confirmations({ id, token, user_id }),
            }
        ),
        sendPhoneConfirmation: build.mutation<confirmationObject, bodyRegister>(
            {
                query: ({ body }) => sendPhoneConfirmation({ body }),
            }
        ),
        storePassword: build.mutation<any, any>({
            query: ({ body }) => storePassword({ body }),
        }),
        storeProfile: build.mutation<
            confirmationObject & ProfileRootObject,
            bodyRegister
        >({
            query: ({ body }) => storeProfile({ body }),
        }),
    }),
});

export const {
    endpoints,
    useLoginByPhoneMutation,
    useLoginByEmailMutation,
    useConfirmationsMutation,
    useSendPhoneConfirmationMutation,
    useStorePasswordMutation,
    useStoreProfileMutation,
} = authApi;

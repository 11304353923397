import { useNavigate } from "react-router-dom";

export const useNavigateProfileSpecialist = (currentLanguage?: string) => {
    const navigate = useNavigate();

    return (route?: string, replaceHistory?: boolean) => {
        if (!currentLanguage) return;

        if (route) {
            navigate(`/${currentLanguage}/specialist/lk/profile/${route}`, {
                replace: !!replaceHistory,
            });
        } else {
            navigate(`/${currentLanguage}/specialist/lk/profile`, {
                replace: !!replaceHistory,
            });
        }
    };
};

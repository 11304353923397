import React from "react";
import Icon from "../../../../components/Icon";
import { ICompany } from "../../../../models/userModels";
import { useNavigateProfileSpecialist } from "../../hooks";
import { getCurrentLanguage } from "../../../../store/directories/selectors";
import { TranslationObject } from "../../../../models/translationModal";

interface CompanyDataProps {
    userCompany?: ICompany | null;
    entities?: TranslationObject;
}

export const CompanyData: React.FC<CompanyDataProps> = ({
    userCompany,
    entities,
}) => {
    const {
        name,
        registration_number,
        country,
        address,
        tax_number,
        vat_number,
        insurance_company_name,
        insurance_max_payment_amount,
    } = userCompany || {};

    const currentLanguage = getCurrentLanguage();

    const navigateTo = useNavigateProfileSpecialist(currentLanguage);

    return (
        <>
            <div className="specialist-profile__block">
                <h3 className="specialist-profile__subtitle title title--h3">
                    {entities?.register_company_title?.value || ""}
                </h3>
                <div className="profile-block grey-card">
                    <div className="profile-block__row">
                        <div className="profile-block__col">
                            <h3 className="profile-block__title title">
                                {name}
                            </h3>
                            <div className="profile-block__details">
                                <p className="text">
                                    {entities
                                        ?.register_company_registration_number
                                        ?.value || ""}
                                </p>
                                <span>{registration_number}</span>
                            </div>
                            <div className="profile-block__details">
                                <p className="text">
                                    {entities?.register_company_country
                                        ?.value || ""}
                                </p>
                                <span>{country}</span>
                            </div>
                            <div className="profile-block__details">
                                <p className="text">
                                    {entities?.register_company_legal_address
                                        ?.value || ""}
                                </p>
                                <span>{address}</span>
                            </div>
                            <div className="profile-block__details">
                                <p className="text">
                                    {entities?.register_company_tax_number
                                        ?.value || ""}
                                </p>
                                <span>{tax_number}</span>
                            </div>
                            <div className="profile-block__details">
                                <p className="text">
                                    {
                                        entities?.register_company_vat_number
                                            ?.value
                                    }
                                </p>
                                <span>{vat_number}</span>
                            </div>
                        </div>
                        {insurance_company_name &&
                            insurance_max_payment_amount && (
                                <div className="profile-block__col">
                                    <h3 className="profile-block__title title">
                                        {entities
                                            ?.register_company_insurance_data
                                            ?.value || ""}
                                    </h3>
                                    <div className="profile-block__details">
                                        <p className="text">Articles</p>
                                        <span>{insurance_company_name}</span>
                                    </div>
                                    <div className="profile-block__details">
                                        <p className="text">
                                            {entities
                                                ?.register_company_insurance_benefit
                                                ?.value || ""}
                                        </p>
                                        <span>
                                            {insurance_max_payment_amount}
                                        </span>
                                    </div>
                                </div>
                            )}
                        <div className="profile-block__controls">
                            <button
                                onClick={() => navigateTo("company-edit")}
                                className="profile-block__btn btn btn--no-bg"
                                type="button"
                                aria-label={entities?.order_edit?.value}
                            >
                                <Icon
                                    type="edit-current-color"
                                    width={20}
                                    height={20}
                                    colorVariant={"jaguar"}
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

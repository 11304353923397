import React, { ChangeEvent, ReactNode, useEffect, useState } from "react";
import ValidationError from "../../../errors/Validation";
import WarnModal from "../../Modals/WarnModal";
import { TranslationObject } from "../../../models/translationModal";

interface From {
    onSubmit: any;
    onSuccess?: any;
    onError?: () => void;
    error?: any | null;
    isSuccess?: boolean;
    children: (invalidFields: any, error: any) => ReactNode;
    entities?: TranslationObject;
    id?: string;
    className?: string;
}

const Form: React.FC<From> = ({
    onSubmit,
    onSuccess,
    onError,
    error,
    isSuccess,
    children,
    entities,
    id,
    className,
}) => {
    const [dataError, setDataError] = useState<any>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalIsClosed, setModalIsClosed] = useState<boolean>(false);
    const [message, setMessage] = useState("");

    const submit = (e: ChangeEvent<HTMLFormElement>) => {
        setDataError(null);
        return onSubmit(e);
    };

    useEffect(() => {
        if (isSuccess && !!onSuccess) {
            onSuccess();
        }
        if (error) {
            if (error?.status === 422) {
                const data = new ValidationError(error);
                setDataError(data);
            }

            if (error?.status !== 422 && error?.data?.description) {
                setMessage(error?.data?.description);
                setShowModal(true);
            }

            if (onError) {
                return onError();
            }
        }
    }, [error, isSuccess]);

    useEffect(() => {
        setModalIsClosed(showModal);
    }, [showModal]);

    return (
        <>
            {modalIsClosed && (
                <WarnModal
                    title={entities?.error_while_sending_request?.value || ""}
                    onClose={() => setShowModal(false)}
                    visible={showModal}
                    description={message || ""}
                />
            )}
            <form id={id} onSubmit={submit} className={className}>
                {children(dataError?.fields, dataError)}
            </form>
        </>
    );
};

export default Form;

import classNames from "classnames";
import React from "react";
import { getPath } from "../../store/directories/selectors";
import { FiltersPayload } from "../../models/directoryModel";
import { getItemCreateOrder } from "../../store/order/selectors";
import {
    IS_MY_ADDRESS,
    IS_SPECIALIST_ADDRESS,
} from "../../store/order/constant";

interface Props {
    isPrivate?: boolean;
    activeFilter: number;
    filters: any;
    hasFilters: boolean;
    hasMyAddress?: boolean;
    hasSpecialistAddress?: boolean;
    isIndividualOrder: boolean;
}

const ProgressBar: React.FC<Props> = ({
    isPrivate,
    activeFilter,
    filters,
    hasFilters,
    isIndividualOrder,
}) => {
    const hasMyAddress = getItemCreateOrder(IS_MY_ADDRESS);
    const hasSpecialistAddress = getItemCreateOrder(IS_SPECIALIST_ADDRESS);
    const initialSteps = [
        "category",
        "type",
        "subtype",
        "meeting-point",
        "when-to-start",
        "repeat",
        "wishes",
        "finishing",
    ];
    const path = getPath();
    const isManySteps = !isPrivate || (isPrivate && isIndividualOrder);
    const renderItem = (isActive: boolean, key?: string) => (
        <div
            key={key}
            className={classNames("progress-bar__item", {
                "is-succes": isActive,
            })}
        />
    );

    return (
        <div className="progress-bar">
            {initialSteps
                .slice(0, 3)
                .map((elem, key) =>
                    renderItem(
                        (elem === 'category' && path[path.length - 1] === 'create-order') ||
                            path[path.length - 1] === elem ||
                            path[path.length - 1].startsWith(`${elem}-`),
                        key + elem
                    )
                )}
            {isPrivate && renderItem(path.includes("list-of-specialists"))}
            {hasFilters &&
                isManySteps &&
                filters?.map((elem: FiltersPayload, key: number) =>
                    renderItem(
                        path[3] === "filters" && activeFilter == key,
                        key + "filters"
                    )
                )}
            {isManySteps && renderItem(path[3] === "meeting-point")}
            {hasMyAddress &&
                isManySteps &&
                renderItem(path[3] === "select-my-address")}
            {hasSpecialistAddress &&
                isManySteps &&
                renderItem(path[3] === "select-specialist-address")}
            {initialSteps
                .slice(4, initialSteps?.length)
                .map((elem, key) => renderItem(path[3] === elem, key + "elem"))}
        </div>
    );
};

export default ProgressBar;

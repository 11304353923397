import React, { useState } from "react";
import { TranslationObject } from "../../../models/translationModal";
import { useResponseToOrderMutation, useSetUninterestedToOrderMutation } from "../../../store/orders/orders.api";
import { OrderItem } from "../../../models/ordersModel";
import Modal from "../../../components/Modal";
import classNames from "classnames";

interface Props {
    isSpecialist: boolean;
    isActive: boolean;
    order: OrderItem;
    entities?: TranslationObject;
    specialistId?: number | false;
}

const ResponseOrderButton: React.FC<Props> = ({
    isSpecialist,
    isActive,
    order,
    entities,
    specialistId
}) => {
    const [responseToOrder] = useResponseToOrderMutation();
    const [setUninterested] = useSetUninterestedToOrderMutation();
    const [visible, setVisible] = useState<boolean>(false);
    const isNotInteresting = order?.interesting_orders?.find(({ specialist_id }) => specialist_id === specialistId)

    if (!isSpecialist || !isActive || order?.response?.created_at) {
        return null;
    }

    if (isNotInteresting) {
        return null;
    }

    const _responseToOrder = () => {
        responseToOrder({ id: order?.id });
    };

    const _setUninterested = () => {
        setUninterested({ id: order?.id, body: { is_interesting: "1" } });
        setVisible(false)
    };

    return (
        <>
            <Modal
                title={entities?.order_modal_no_interested_text?.value}
                visible={visible}
                onClose={() => setVisible(false)}
            >
                <div className="modal__content-buttons">
                    <button
                        className="btn btn--transparent"
                        type="button"
                        onClick={_setUninterested}
                    >
                        {entities?.order_modal_no_interested_yes?.value}
                    </button>
                    <button
                        className={classNames("btn modal__content-button", {
                            "modal__content-button--background": isSpecialist,
                            "modal__content-button--background-customer":
                                !isSpecialist,
                        })}
                        type="button"
                        onClick={() => setVisible(false)}
                    >
                        {entities?.order_modal_no_interested_no?.value}
                    </button>
                </div>
            </Modal>
            <div className="cards-orders__buttons">
                <button
                    className="btn--bg-green cards-orders__btn btn btn--rounded"
                    type="button"
                    onClick={_responseToOrder}
                >
                    {entities?.order_make_respond?.value}
                </button>
                <button
                    className="btn--transparent cards-orders__btn btn btn--rounded"
                    type="button"
                    onClick={() => setVisible(true)}
                >
                    {entities?.order_modal_no_interested_button?.value}
                </button>
            </div>
        </>
    );
};
export default ResponseOrderButton;

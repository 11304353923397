import React, { useMemo, useState } from "react";
import { DateTime } from "luxon";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { TranslationObject } from "../../models/translationModal";
import { ReviewItem } from "../../models/reviewModel";
import classNames from "classnames";
import Score from "../Score";
import Avatar from "../Avatar";
import {
    useGetTranslateReviewMutation,
    useMakeReviewPayMutation,
    useReadReviewMutation,
    endpoints
} from "../../store/review/review.api";
import Icon from "../Icon";
import { BankCardsModal, } from "../Modals/BankCardsModal";
import { useLoading } from "../../hooks/useLoading/useLoading"
import { ISelectCard } from "../Modals/BankCardsModal/BankCardsModal";

interface Props {
    entities?: TranslationObject;
    review: ReviewItem;
    viewForSpecialist?: boolean;
    isSpecialist?: boolean;
    reviewFee?: number
}

const ReviewCard: React.FC<Props> = ({
    entities,
    review,
    viewForSpecialist,
    isSpecialist = false,
    reviewFee
}) => {
    const currentLanguage = getCurrentLanguage();
    const [readReview] = useReadReviewMutation();
    const [
        translateReview,
        { data: translateReviewData, isSuccess, isError, isLoading },
    ] = useGetTranslateReviewMutation();

    const onClick = () => {
        if (review?.id && isSpecialist) {
            readReview({
                id: review?.id,
            });
        }
    };

    const handleClickButtonTranslateReview = () => {
        if (review?.id) {
            translateReview(review.id);
        }
    };

    return (
        <>
            <li className="reviews__item" onClick={onClick}>
                <article
                    className={classNames(
                        "profile-card profile-card--block review-card",
                        {
                            "review-card--new":
                                viewForSpecialist && !review?.read_at,
                        }
                    )}
                >
                    <div className="review-card__header">
                        <div className="avatar avatar--sm avatar--rounded">
                            <Avatar
                                width={64}
                                height={64}
                                avatar={review?.reviewer?.avatar}
                            />
                        </div>
                        <h3 className="review-card__title title title--h4">
                            {review?.reviewer?.name}
                        </h3>
                        <time className="review-card__date color-grey">
                            {review?.created_at &&
                                DateTime.fromSQL(review?.created_at)
                                    .setLocale(currentLanguage)
                                    .toFormat("d MMMM yyyy")}
                        </time>
                        {!(isLoading || isError) && (
                            <div className="review-card__controls">
                                <button
                                    className="btn review-card__btn btn--white"
                                    type="button"
                                    onClick={handleClickButtonTranslateReview}
                                >
                                    {entities?.common_translate?.value}
                                </button>
                                <SharedButton
                                    reviewFee={reviewFee}
                                    id={review?.id}
                                    entities={entities}
                                    isSpecialist={isSpecialist}
                                />
                            </div>
                        )}
                        <Score
                            score={review?.score}
                            withBackground
                            className="review-card__rating"
                        />
                    </div>
                    <h4 className="review-card__subtitle review-card__text review-card__title title title--h4">
                        {review?.order_name}
                    </h4>
                    <p className="review-card__text text">
                        {translateReviewData?.text || review?.text}
                    </p>
                </article>
            </li>
        </>
    );
};

interface SharedButtonProps {
    entities?: TranslationObject;
    isSpecialist?: boolean;
    id: number
    reviewFee?: number
}

const SharedButton: React.FC<SharedButtonProps> = ({
    entities,
    id,
    reviewFee
}) => {
    const [visibleBankCardsModal, setVisibleBankCardsModal] =
        useState<boolean>(false);
    const language = getCurrentLanguage()
    const [countTry, setCountTry] = useState<number>(0)

    const [getShare, { data, isSuccess: isSuccessShare }] = endpoints.getShare.useLazyQuery()

    const [typeModalProps, setTypeModalProps] = useState('cards')

    const { setLoading } = useLoading();

    const [makeReviewPay, { isSuccess, isError }] =
        useMakeReviewPayMutation();

    useMemo(() => {
        if (data && isSuccessShare && countTry) {
            window.open(data?.facebook)
        }
    }, [isSuccessShare, data, countTry])

    useMemo(() => {
        if (isSuccess) {
            setVisibleBankCardsModal(false)
            setCountTry(countTry + 1)
            getShare({ reviewId: id, language })
        }
    }, [isSuccess])

    useMemo(() => {
        if (isError) {
            setTypeModalProps('isError')
        }
    }, [isError])

    const bankCardModalConfirm = (selectCard: ISelectCard) => {
        if (selectCard?.value) {
            setLoading(true);
            const requestData = {
                reviewId: id,
                body: {
                    card_id: selectCard?.value,
                },
            };

            makeReviewPay(requestData)
                .unwrap()
                .finally(() => setLoading(false));
        }
    };

    const closeModal = () =>
        setVisibleBankCardsModal(false)


    return (
        <>
            <BankCardsModal
                visible={visibleBankCardsModal}
                closeModal={closeModal}
                confirmModal={(selectCard) => {
                    bankCardModalConfirm(selectCard);
                }}
                price={reviewFee}
                entities={entities}
                typeModalProps={typeModalProps}
            />
            <button
                className="review-card__btn review-card__share-btn btn btn--no-bg"
                onClick={() => setVisibleBankCardsModal(true)}
                type="button"
                aria-label="share"
            >
                <Icon
                    type="share"
                    width={20}
                    height={20}
                    className="review-card__icon-share"
                />
            </button>
        </>
    );
};

export default ReviewCard;

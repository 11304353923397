import React from "react";

interface Props {
    onChange: (value: string) => void;
    value?: string;
    visible?: boolean;
    disabled: boolean;
    maxLength?: number;
}

const CustomValueInput: React.FC<Props> = ({
    onChange,
    disabled,
    value,
    visible,
    maxLength,
}) => {
    if (!visible) {
        return null;
    }

    const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        onChange(target.value);
    };

    return (
        <input
            type="search"
            disabled={disabled}
            onChange={_onChange}
            value={value}
            maxLength={maxLength}
        />
    );
};

export default CustomValueInput;

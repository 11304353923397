import { request } from "../request";
import { paramPage } from "../../models/directoryModel";
import { IdProps } from "../models";

export const getPage = ({ id, url }: paramPage) =>
    request({
        url: `${url}/${id}`,
    });

export const getTranslations = () => request({ url: "translations" });

export const getServiceCategories = () =>
    request({
        url: `services/service_categories`,
    });

export const getServiceTypes = ({ id }: IdProps) =>
    request({
        url: `services/service_categories/${id}/service_types`,
    });

export const getServiceSubTypes = ({ id }: IdProps) =>
    request({
        url: `services/service_types/${id}/service_subtypes`,
    });

export const getFilters = ({ id }: IdProps) =>
    request({
        url: `services/service_types/${id}/filters`,
    });

import React from "react";
import Modal from "../../../components/Modal";
import { TranslationObject } from "../../../models/translationModal";
import { generatePath, useNavigate } from "react-router-dom";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import {
    getItemCreateOrder,
    updateItemCreateOrder,
} from "../../../store/order/selectors";
import { createOrderState } from "../../../store/order/createOrder.slice";
import { useActions } from "../../../hooks/actions";
import { getUrlTransliteName } from "../../../untils";

interface Props {
    title: string;
    onClose: () => void;
    visible: boolean;
    description: string;
    entities?: TranslationObject;
    id?: number;
    name?: string;
}

const ErrorAddressModal: React.FC<Props> = ({
    onClose,
    visible,
    title,
    description,
    entities,
    id,
    name
}) => {
    const { updateCreateOrderField } = useActions();
    const navigate = useNavigate();
    const currentLanguage = getCurrentLanguage();

    const backToSpecialist = async () => {
        if (!id || !name) {
            return;
        }

        const nameSpec = getUrlTransliteName(name);
        const url = `/:currentLanguage/specialist/:id`;
        navigate(
            generatePath(url, {
                currentLanguage,
                id: `${nameSpec}-${id}`,
            })
        );
    };

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) => {
        updateItemCreateOrder(
            {
                name,
                value,
            },
            updateCreateOrderField
        );
    };

    const cancel = async () => {
        updateField({ name: "hasErrorInAddress", value: false });
        await onClose();
        const url = `/:currentLanguage/customer`;
        navigate(
            generatePath(url, {
                currentLanguage,
            })
        );
    };

    return (
        <Modal
            onClose={onClose}
            visible={visible}
            title={title}
            description={description}
        >
            <div className="modal__content-buttons">
                <button
                    style={{ marginRight: "16px", color: "#17171b" }}
                    className="btn modal__content-button btn--transparent"
                    type="button"
                    onClick={cancel}
                >
                    {entities?.common_cancel_button?.value}
                </button>
                <button
                    className="btn modal__content-button "
                    type="button"
                    onClick={backToSpecialist}
                >
                    {entities?.order_back_to_specialist_button?.value}
                </button>
            </div>
        </Modal>
    );
};

export default ErrorAddressModal;

import React, { useState } from "react";
import Icon from "../Icon";
import { IS_MY_ADDRESS, IS_REMOTE } from "../../store/order/constant";
import classNames from "classnames";
import { TranslationObject } from "../../models/translationModal";
import { Service, ServiceSubType } from "../../models/userModels";
import PhotoSlider from "./PhotoSlider";

const ServiceTypeCard: React.FC<{
    serviceType: Service;
    entities?: TranslationObject;
    serviceTypeTranslate?: Service | null;
}> = ({ serviceType, entities, serviceTypeTranslate }) => {
    const [isOpen, setIsOpen] = useState(false);
    const points = {
        [IS_REMOTE]: `${entities?.common_remotely?.value}, `,
        [IS_MY_ADDRESS]: `${entities?.common_specialist_adress?.value}`,
        is_customer_address: `${entities?.common_customer_adress?.value}, `,
    };

    return (
        <div
            className={classNames(
                "specialist-page__dropdown grey-card dropdown",
                {
                    "is-open": isOpen,
                }
            )}
        >
            <div className="dropdown__header">
                <h2 className="dropdown__title title title--f32">
                    {serviceType?.service_type?.name ||
                        serviceType?.custom_service_type}
                </h2>
                <button
                    className="dropdown__btn btn btn--transparent btn--nb"
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <Icon
                        type={isOpen ? "arrow_up" : "arrow_down"}
                        width={20}
                        height={20}
                    />
                </button>
            </div>
            <div className="dropdown__content">
                <p className="dropdown__item dropdown__sub subtitle subtitle--sm"></p>
                <h3 className="dropdown__item title title--h4">
                    {entities?.common_meeting_place?.value}
                </h3>
                <p className="dropdown__item text">
                    {serviceType?.is_remote ? points["is_remote"] : null}
                    {serviceType?.is_customer_address
                        ? points["is_customer_address"]
                        : null}
                    {serviceType?.is_my_address
                        ? points["is_my_address"]
                        : null}
                </p>
                <p className="dropdown__item text color-grey">
                    {serviceTypeTranslate?.description ||
                        serviceType?.description}
                </p>
                <div className="dropdown__item details">
                    {serviceType?.service_sub_types?.map(
                        (serviceSubType: ServiceSubType, key: number) => (
                            <dl key={key + serviceSubType?.id}>
                                <dt>
                                    {serviceSubType?.service_sub_type?.name ??
                                        serviceSubType?.custom_service_sub_type}
                                    <p className="details__text text color-grey">
                                        {serviceTypeTranslate?.service_sub_types?.find(
                                            (el) =>
                                                el?.id === serviceSubType?.id
                                        )?.description ||
                                            serviceSubType?.description}
                                    </p>
                                </dt>
                                <dd>
                                    {serviceTypeTranslate?.service_sub_types?.find(
                                        (el) => el?.id === serviceSubType?.id
                                    )?.price || serviceSubType?.price}
                                </dd>
                            </dl>
                        )
                    )}
                </div>
                {serviceType?.photos?.length > 0 && (
                    <PhotoSlider
                        photos={serviceType?.photos}
                        entities={entities}
                    />
                )}
            </div>
        </div>
    );
};

export default ServiceTypeCard;

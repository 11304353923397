import { request } from "../request";

export const getAdvertisementPaidList = () =>
    request({
        url: "/unicorns/specialists/paid",
        method: "GET",
    });

export const getBannerStartDate = ({ days }) =>
    request({
        url: "/unicorns/specialists/banner_start_date",
        method: "GET",
        params: {
            days,
        },
    });

export const getMailingStartDate = () =>
    request({
        url: "/unicorns/specialists/mailing_start_date",
        method: "GET",
    });

export const getAdvertisementEmployees = () =>
    request({
        url: "/unicorns/specialists/employees",
        method: "GET",
    });

export const getAdvertisementSpecialists = () =>
    request({
        url: "/unicorns/specialists",
        method: "GET",
    });

export const getAdvertisementSpecialistsMainPage = () =>
    request({
        url: "/unicorns/main_page",
        method: "GET",
    });

export const getThirdPartiesMain = () =>
    request({
        url: "/unicorns/third_parties",
        method: "GET",
    });

export const getThirdParties = () =>
    request({
        url: "/unicorns/third_parties/spec",
        method: "GET",
    });

export const setThirdPartiesClick = (id: number) =>
    request({
        url: `/unicorns/third_parties/${id}/click`,
        method: "PUT",
    });

export const storeAdvertisementBanner = (body: FormData) =>
    request({
        url: "/unicorns/specialists/store_banner",
        method: "POST",
        body,
    });

export const storeAdvertisementMailing = (body: FormData) =>
    request({
        url: "/unicorns/specialists/store_mailing",
        method: "POST",
        body,
    });

export const storeAdvertisementTop = (body: FormData) =>
    request({
        url: "/unicorns/specialists/store_top",
        method: "POST",
        body,
    });

import { ISettings, ISocketClient } from "./AppSocketProvider.types";
import Echo from "laravel-echo";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import io from "socket.io-client";

export const useCreateSocketClient = (
    socketClient: ISocketClient,
    settings: ISettings
) => {
    const { host, token, userId, userRole } = settings;

    return () => {
        socketClient.client = new Echo({
            broadcaster: `socket.io`,
            host: host,
            transports: [`websocket`],
            client: io,
            auth: {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        });

        socketClient.channel = `events.${userRole}.${userId}`;

        return socketClient as { client: Echo; channel: string };
    };
};

import React from "react";
import { TranslationObject } from "../../models/translationModal";
import Modal from "../Modal";

interface Props {
  entities?: TranslationObject;
  visible: boolean;
  handleChange: () => void;
  changeVisible: (value: boolean) => void;
}

const ConfirmSelectAllSubtypesModal: React.FC<Props> = ({ entities, visible, changeVisible, handleChange }) => {
  const closeModal = () => {
    changeVisible(false);
  }

  const confirm = () => {
      handleChange();
      closeModal();
  }

  return (
    <Modal
      title={entities?.services_confirm_select_all_popup_title?.value}
      visible={visible}
      onClose={closeModal}
      description={entities?.services_confirm_select_all_popup_text?.value}
    >
        <div className="modal__content-buttons">
            <button
                className="btn btn--transparent"
                type="button"
                onClick={closeModal}
            >
                {entities?.common_no?.value}
            </button>
            <button
                className="btn modal__content-button btn--bg-green"
                type="button"
                onClick={confirm}
            >
                {entities?.common_yes?.value}
            </button>
        </div>
    </Modal>
  )
}

export default ConfirmSelectAllSubtypesModal;
import { ColorVariantType } from "./index";

export const getColorIcon = (colorVariant?: ColorVariantType) => {
    switch (colorVariant) {
        case "light":
            return "#FFFFFF";
        case "grayLight":
            return "#9ea4a7";
        case "darkBLue":
            return "#23232A";
        case "greenLight":
            return "#22d695";
        case "blue":
            return "#434BEE";
        case "orange":
            return "#EC930E";
        case "jaguar":
            return "#23232A";
        default:
            return;
    }
};

import classNames from "classnames";
import React, { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useCreateChatMutation } from "../../../store/orders/orders.api";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import { OrderSpecialistItem } from "../../../models/orderModel";
import { CurrentSpecialistsPayload } from "../../../models/userModels";
import { TranslationObject } from "../../../models/translationModal";
import { OrderItem } from "../../../models/ordersModel";

interface Props {
    specialist?: OrderSpecialistItem | CurrentSpecialistsPayload;
    orderId: number;
    entities?: TranslationObject;
    isSpecialist?: boolean;
    isPrivate: boolean;
    isOrderPage: boolean;
    isUnresponsiveSpecialist: boolean;
    order?: OrderItem;
    isDeal?: boolean;
    isDone?: boolean;
    role?: string;
    isActive?: boolean;
    isSpecialistCardBtn?: boolean;
}

const ChatButton: React.FC<Props> = ({
    specialist,
    orderId,
    entities,
    isSpecialist = false,
    isPrivate,
    isOrderPage,
    role,
    order,
    isDeal = false,
    isDone = false,
    isUnresponsiveSpecialist,
    isActive,
    isSpecialistCardBtn = true,
}) => {
    const hasChat = specialist?.chat?.id || order?.chat?.id;
    const unViewedMessages =
        specialist?.chat?.unviewed_chat_messages_count ||
        specialist?.chat?.unviewed_messages_count ||
        order?.chat?.unviewed_messages_count;
    const [createChat, { isSuccess, data }] = useCreateChatMutation();
    const navigate = useNavigate();
    const currentLanguage = getCurrentLanguage();
    const showFromCustomer = isSpecialistCardBtn
        ? (!isPrivate && isOrderPage) ||
        (isPrivate && !isUnresponsiveSpecialist && isOrderPage)
        : (isDone && hasChat) || isDeal;
    const isRespondedSpecialist = order?.response?.created_at;
    const showFromSpecialist =
        (isRespondedSpecialist && isActive) || isDeal || isDone;
    const showChatButton = isSpecialist ? showFromSpecialist : showFromCustomer;
    const showUnViewedMessages = hasChat && unViewedMessages >= 0;

    useEffect(() => {
        if (isSuccess && data?.id) {
            goChat();
        }
    }, [isSuccess, data]);

    const goChat = () => {
        const url = `/:currentLanguage/:role/lk/order/:id/chat/:chatId`;
        const chatId = specialist?.chat?.id || order?.chat?.id || data?.id;
        if (role && currentLanguage && orderId && chatId) {
            navigate(
                generatePath(url, {
                    currentLanguage,
                    chatId,
                    id: String(orderId),
                    role: role,
                })
            );
        }
    };

    const handleClickChat = () => {
        if (hasChat && role && currentLanguage && orderId) {
            goChat();
        } else {
            createChat({
                body: isSpecialist
                    ? {
                        order_id: orderId,
                    }
                    : {
                        order_id: orderId,
                        specialist_id: specialist?.id,
                    },
            });
        }
    };

    if (!showChatButton) {
        return null;
    }

    return (
        <button
            className={classNames("profile-card__button btn btn--rounded", {
                "profile-card__button--chat": hasChat,
                "profile-card__button--chat--specialist":
                    isSpecialist && hasChat,
                "profile-card__button--chat--specialist--start":
                    isSpecialist && !hasChat,
                "profile-card__button--chat--customer": !isSpecialistCardBtn,
            })}
            type="button"
            onClick={handleClickChat}
        >
            <span className="profile-card__button-text">
                {!hasChat
                    ? entities?.chat_create?.value
                    : entities?.order_chat?.value}
            </span>
            {showUnViewedMessages && (
                <span className="pinned-chats__msg-count">
                    {unViewedMessages}
                </span>
            )}
        </button>
    );
};
export default ChatButton;

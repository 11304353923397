import React, { useState } from "react";
import Icon from "../../components/Icon";
import classNames from "classnames";

interface Props {
    question: string;
    answer: string;
    isCustomer?: boolean;
}

const Question: React.FC<Props> = ({ question, answer, isCustomer }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="accordion__element accordion__element-main">
            <button
                className="accordion__element-button"
                type="button"
                onClick={() => setIsOpen(!isOpen)}
            >
                <Icon
                    type={isOpen ? "arrow-down" : "arrow-right"}
                    width={isOpen ? 24 : 16}
                    height={isOpen ? 16 : 24}
                    className={classNames("accordion__element-button-icon", {
                        customer: isOpen && isCustomer,
                        specialist: isOpen && !isCustomer,
                    })}
                />
                <div
                    className="accordion__element-button-title"
                    dangerouslySetInnerHTML={{
                        __html: question || "",
                    }}
                />
            </button>
            {isOpen && (
                <div
                    className="accordion__element-button-description"
                    dangerouslySetInnerHTML={{
                        __html: answer || "",
                    }}
                />
            )}
        </div>
    );
};

export default Question;

import React from "react";
import { TranslationObject } from "../../../../models/translationModal";

interface ChatProps {
    children?: React.ReactNode;
    entities?: TranslationObject;
}

export const ChatContainer: React.FC<ChatProps> = ({ children, entities }) => {
    return (
        <>
            <section className="chat">
                <h1 className="visually-hidden">
                    {entities?.chat_page?.value}
                </h1>
                <div className="container">
                    <div className="chat__wrapper">{children}</div>
                </div>
            </section>
        </>
    );
};

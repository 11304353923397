import React, { useEffect, useState } from "react";
import Icon from "../../../components/Icon";
import { getDirectories } from "../../../store/directories/selectors";
import { endpoints } from "../../../store/exceptions/exceptions.api";
import { LocationData } from "../../../models/orderModel";
import { toCollectAddress } from "../../../untils";

const SelectedAddress: React.FC<{
    location: LocationData | null;
    clearAddress?: (value: string) => void;
    showAddress: boolean;
    type: string;
}> = ({ location, clearAddress, showAddress, type }) => {
    const _clearAddress = () => {
        if (clearAddress) {
            clearAddress(type);
        }
    };

    if (!showAddress) {
        return null;
    }

    return (
        <p className="toggle-place__address">
            {location?.address}
            {location?.radius && (
                <span>{`в радиусе ${location?.radius} км`}</span>
            )}
            <button className="btn toggle-place__btn-delete" type="button">
                <div onClick={_clearAddress}>
                    <Icon type="close" width={28} height={28} />
                </div>
            </button>
        </p>
    );
};

export default SelectedAddress;

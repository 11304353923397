import React, { useEffect, useState } from "react";
import Input from "../../components/Form/Input";
import { getItemStore } from "../../untils";
import Checkbox from "../../components/Form/Checkbox";
import { FilterBody, FiltersPayload, FilterValues } from "../../models/directoryModel";
import { useActions } from "../../hooks/actions";
import { FILTER_PAGES, FILTER_MAX_ELEMENTS_SHOW } from "../../store/order/constant";
import { FilterProps } from "../../models/orderModel";
import {
    getItemCreateOrder,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import { createOrderState } from "../../store/order/createOrder.slice";
import { TranslationObject } from "../../models/translationModal";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

interface Props {
    filter: FiltersPayload;
    update?: () => void;
    hiddenInput?: boolean;
    isEdit: boolean;
    entities?: TranslationObject;
}

const MultipleFilter: React.FC<Props> = ({
    filter,
    update,
    hiddenInput,
    isEdit,
    entities,
}) => {
    const { updateEditOrderField, updateCreateOrderField } = useActions();
    const [ids, setIds] = useState<number[] | null>(null);
    const [customFilterValue, setCustomFilterValue] = useState<string | number>("");
    const [checkListLangs, setCheckListLangs] = useState<FilterValues[]>([]);
    const filterPages = getItemCreateOrder(FILTER_PAGES) || [];
    const isDisabledInput = !ids || (Array.isArray(ids) && ids?.length < 1);
    const [showLongList, setShowLongList] = useState<boolean>(false);

    useEffect(() => {
        if (filterPages) {
            const currentFilter = filterPages.find(
                (elem: FilterProps) => elem.service_type_filter_id === filter.id
            );
            if (currentFilter) {
                setIds(currentFilter?.service_type_filter_value_ids);
                if (currentFilter?.custom_filter_value) {
                    setCustomFilterValue(currentFilter?.custom_filter_value);
                }
            }
        }
    }, []);

    useEffect(() => {
        if (!isEdit) {
            const store = getItemStore(FILTER_PAGES);
            if (store) {
                setIds(
                    store
                        ?.find(
                            (elem: FilterBody) =>
                                elem["service_type_filter_id"] == filter?.id
                        )
                        ?.service_type_filter_value_ids?.map(
                            (elem: FilterBody) => Number(elem)
                        ) || []
                );
                setCustomFilterValue(
                    store?.find(
                        (elem: any) =>
                            elem["service_type_filter_id"] == filter?.id
                    )?.custom_filter_value || ""
                );
            }
        }
    }, []);

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) =>
        updateItemCreateOrder(
            {
                name: name,
                value: value,
            },
            updateCreateOrderField
        );

    const handleClick = (id: number) => {
        if (ids?.find((elem) => Number(elem) === Number(id))) {
            const items = ids.filter((i) => i !== id);
            if (items.length < 1) {
                setCustomFilterValue("");
            }
            return setIds(items);
        }
        setIds(ids?.concat(id) || [id]);
    };

    useEffect(() => {
        if (ids || customFilterValue) {
            isEdit ? updateFiltersEditOrder() : updateLocalStage();
        }
    }, [ids, customFilterValue]);

    const updateFiltersEditOrder = () => {
        updateEditOrderField({
            name: FILTER_PAGES,
            value: [
                ...filterPages.filter(
                    (elem: FilterProps) =>
                        elem?.service_type_filter_id !== filter.id
                ),
                getFilter(),
            ],
        });
    };

    const getFilter = () => {
        if (ids) {
            let obj: FilterProps = {
                service_type_filter_id: filter?.id,
                service_type_filter_value_ids: ids,
            };
            if (filter?.has_custom_value && customFilterValue) {
                obj = {
                    ...obj,
                    custom_filter_value: customFilterValue,
                };
            }
            return obj;
        }
    };

    const updateLocalStage = () => {
        const newItems = filterPages?.map((item: any) => {
            const has_service_type_filter_id =
                Object.prototype.hasOwnProperty.call(
                    item,
                    "service_type_filter_id"
                );

            if (
                has_service_type_filter_id &&
                item["service_type_filter_id"] == filter?.id
            ) {
                return getFilter();
            }

            return item;
        });

        updateField({ name: FILTER_PAGES, value: newItems });

        if (update) {
            update();
        }
    };

    const handleChange = (value: string | number) => {
        setCustomFilterValue(value);
    };

    useEffect(() => {
        if (filter?.values && Array.isArray(filter?.values) && ids && Array.isArray(ids)) {
            setCheckListLangs(filter?.values.filter((lang: FilterValues) => {
                return ids.includes(lang.id)
            }))
        }

    }, [filter?.values, ids])

    return (
        <>
            {(!!checkListLangs.length && filter?.values?.length > FILTER_MAX_ELEMENTS_SHOW) && <div className="form__row">
                {checkListLangs.map((value: any, key: number) => (
                    <Checkbox
                        onChange={() => handleClick(value?.id)}
                        key={key}
                        value={ids?.includes(Number(value.id))}
                    >
                        {value?.name}
                    </Checkbox>
                ))}
            </div>}
            <SimpleBar autoHide={false} forceVisible={true} style={{ maxHeight: 500 }}>
                <div className="form__row">
                    {(showLongList
                        ? filter?.values
                        : filter?.values?.slice(0, FILTER_MAX_ELEMENTS_SHOW)
                    )?.map((value: any, key: number) => (
                        <Checkbox
                            onChange={() => handleClick(value?.id)}
                            key={key}
                            value={ids?.includes(Number(value.id))}
                        >
                            {value?.name}
                        </Checkbox>
                    ))}
                </div>
            </SimpleBar>
            {filter?.values?.length > FILTER_MAX_ELEMENTS_SHOW && (
                <button
                    className="btn btn--view-all"
                    type="button"
                    onClick={() => setShowLongList(!showLongList)}
                >
                    {showLongList
                        ? entities?.order_hide?.value
                        : entities?.order_show_all?.value}
                </button>
            )}
                
            {filter?.has_custom_value && !hiddenInput && (
                <fieldset>
                    <legend>
                        {entities?.order_filter_custom_answer_details?.value}
                    </legend>
                    <Input
                        onChange={handleChange}
                        type="text"
                        placeholder=""
                        disabled={isDisabledInput}
                        classes={[
                            "custom-input--text-right custom-input--small",
                        ]}
                        value={customFilterValue}
                    />
                </fieldset>
            )}
        </>
    );
};

export default MultipleFilter;

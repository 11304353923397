import React, { useRef, useState, Dispatch } from "react";
import { TranslationObject } from "../../../models/translationModal";
import classNames from "classnames";
import useClickOutside from "../../../hooks/useClickOutside";
import { useCopyOrderMutation, useUpdateOrderMutation } from "../../../store/orders/orders.api";
import { OrderItem } from "../../../models/ordersModel";
// import SendToDraftOrderButton from "../../Buttons/SendToDraftOrderButton";
import PublishOrderButton from "../../Buttons/PublishOrderButton";
// import CopyOrderButton from "../../Buttons/CopyOrderButton";
import CompleteOrderButton from "../../Buttons/CompleteOrderButton";
import CloseOrderButton from "../../Buttons/CloseOrderButton";
import EditOrderButton from "../../Buttons/EditOrderButton";
import ReviewModal from "../../Modals/ReviewModal";
import CopyOrderButton2 from "../../Buttons/CopyOrderButton2";
import OrderDateModal from "../../Modals/OrderDateModal";

interface Props {
    entities?: TranslationObject;
    isDeal: boolean;
    isActive: boolean;
    isDraft: boolean;
    hasSpecialists: boolean;
    id: number;
    order: OrderItem;
    userId?: number;
    isSpecialist: boolean;
    showModal: string;
    setShowModal?: Dispatch<string>;
    setSelectedOrder?: Dispatch<number>;
}

const DropDownMenu: React.FC<Props> = ({
    entities,
    isDeal,
    isActive,
    isDraft,
    hasSpecialists,
    id,
    order,
    userId,
    isSpecialist,
    showModal,
    setShowModal,
    setSelectedOrder
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);

    const $collapse = useRef<HTMLDivElement>(null);
    const [updateStatus] = useUpdateOrderMutation();
    const [copyOrder] = useCopyOrderMutation();
    useClickOutside($collapse, () => setIsOpen(false));

    const _changeStatus = (str: string, startAt?: string, endAt?: string) => {
        const body = {
            status: str,
            ...(startAt ? { started_at: startAt } : {}),
            ...(startAt ? { ended_at:  endAt || "" } : {})
        };

        updateStatus({
            body: body,
            id,
        });
        setIsOpen(false);
    };

    const _copyOrder = async () => {
        const copiedOrder = await copyOrder({id});
        setSelectedOrder?.(copiedOrder?.data?.id);
    }

    const publishOrder = () => {
        const orderDate = new Date(order?.started_at);
        const currentDate = new Date();

        if (orderDate < currentDate && orderDate.toDateString() !== currentDate.toDateString()) {
            setVisible(true);
        } else {
            _changeStatus("active");
        }
    }

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(true);
    };

    return (
        <>
            <div
                className="cards-orders__item-dotted"
                onClick={(e) => handleClick(e)}
            >
                <div
                    className={classNames("dotted-menu", {
                        "is-active": isOpen,
                    })}
                    ref={$collapse}
                >
                    <span>...</span>
                    <ul className="dotted-menu__list">
                        {/* <SendToDraftOrderButton
                            changeStatus={_changeStatus}
                            isActive={isActive}
                            hasSpecialists={hasSpecialists}
                            isDropDown
                            entities={entities}
                            isSpecialist={isSpecialist}
                        /> */}
                        <PublishOrderButton
                            isDraft={isDraft}
                            isDropDown
                            entities={entities}
                            publishOrder={publishOrder}
                        />
                        {/* <CopyOrderButton
                            isDraft={isDraft}
                            isDropDown
                            entities={entities}
                            order={order}
                            userId={userId}
                            isSpecialist={isSpecialist}
                        /> */}
                        <CopyOrderButton2
                            changeStatus={_copyOrder}
                            isActive={isActive}
                            order_id={order.id}
                            hasSpecialists={hasSpecialists}
                            isDropDown
                            entities={entities}
                            isSpecialist={isSpecialist}
                            setShowModal={setShowModal}
                            setSelectedOrder={setSelectedOrder}
                        />
                        <CompleteOrderButton
                            entities={entities}
                            order={order}
                            setShowModal={setShowModal}
                            isDropDown
                            isDeal={isDeal}
                            isSpecialist={isSpecialist}
                            closeDropDown={() => setIsOpen(false)}
                        />
                        <CloseOrderButton
                            entities={entities}
                            isDraft={isDraft}
                            isDropDown
                            isPrimary={false}
                            isActive={isActive}
                            changeStatus={_changeStatus}
                        />
                        <EditOrderButton
                            isDraft={isDraft}
                            isDropDown
                            entities={entities}
                            order={order}
                            isSpecialist={isSpecialist}
                        />
                    </ul>
                </div>
            </div>

            <ReviewModal
                key={"modal_review" + order?.id}
                visible={showModal === 'review'}
                onClose={() => setShowModal?.('')}
                order_id={order?.id}
                id={order?.review?.id}
                isSpecialist={isSpecialist}
                score={order?.review?.score}
                text={order?.review?.text}
                entities={entities}
            />

            <OrderDateModal
                entities={entities}
                visible={visible}
                changeVisible={setVisible}
                handleChange={(startAt, endAt) => _changeStatus("active", startAt, endAt)}
            />
        </>
    );
};

export default DropDownMenu;

import classNames from "classnames";
import React, { useState } from "react";
import Icon from "../../components/Icon";
import Types from "./Types";
import { Category } from "../../models/directoryModel";
import { TranslationObject } from "../../models/translationModal";

const CategoryItem: React.FC<{
    entities?: TranslationObject;
    isSpecialist?: boolean;
    category: Category;
}> = ({ category, entities, isSpecialist }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div
            className={classNames("accordion__element accordion__element-main")}
        >
            <button
                className="accordion__element-button"
                type="button"
                onClick={() => setIsOpen(!isOpen)}
            >
                <Icon
                    type={isOpen ? "arrow-down" : "arrow-right"}
                    className={classNames("accordion__element-button-icon", {
                        "is-open": isOpen,
                    })}
                    width={isOpen ? 33 : 20}
                    height={isOpen ? 20 : 33}
                />
                <span className="accordion__element-button-title">
                    {category?.name}
                </span>
                <span className="accordion__element-button-count">
                    ({category?.specialists_count})
                </span>
            </button>
            {isOpen && <Types id={category?.id} entities={entities} isSpecialist={isSpecialist} category={category}/>}
        </div>
    );
};

export default CategoryItem;

import { useSelector } from "react-redux";
import { RootState } from "../index";
import { filterOrdersState } from "./filterOrders.slice";
import { convertDateToDDMMYYYY } from "../../untils";
import { OrderItem } from "../../models/ordersModel";
import { TranslationObject } from "../../models/translationModal";
import { getRanges, getWeekdays } from "../order/selectors";
import { getCurrentLanguage } from "../directories/selectors";

export const getFilter = <K extends keyof filterOrdersState>(
    str: K
): filterOrdersState[K] =>
    useSelector((state: RootState) => state?.filterOrders[str]);

export const getFilters = () =>
    useSelector((state: RootState) => state?.filterOrders);

type K = keyof filterOrdersState;

export const getParamsToFilterOrders = (filters: filterOrdersState) => {
    let params = {
        per_page: 10,
    };
    const keys: K[] = (Object.keys(filters) as K[]) || [];
    keys.forEach((elem: K) => {
        if (elem !== "search" && filters[elem]) {
            const items = filters[elem];
            const isArray = Array.isArray(items);
            if (isArray && items?.length < 1) {
                return;
            }
            params = {
                ...params,
                [elem]: filters[elem],
            };
        } else {
            if (filters[elem]) {
                params = {
                    ...params,
                    [elem]: filters[elem],
                };
            }
        }
    });
    return params;
};

export const getDatesOrder = ({
    order,
    entities,
    isActive,
    isDraft,
    isDone,
    isDeal,
    isArchive,
}: {
    order: OrderItem;
    entities?: TranslationObject;
    isActive: boolean;
    isDraft: boolean;
    isDone: boolean;
    isDeal: boolean;
    isArchive: boolean;
}) => {
    const created_at =
        order?.created_at && convertDateToDDMMYYYY(order?.created_at);
    const dealing_at =
        order?.dealing_at && convertDateToDDMMYYYY(order?.dealing_at);
    const completed_at =
        order?.completed_at && convertDateToDDMMYYYY(order?.completed_at);
    const archived_at =
        order?.archived_at && convertDateToDDMMYYYY(order?.archived_at);

    return [
        {
            visible: (isActive && created_at) || (isDraft && created_at),
            title: entities?.order_created?.value,
            value: created_at,
        },
        {
            visible: isDeal && dealing_at,
            title: entities?.order_dealing_at?.value,
            value: dealing_at,
        },
        {
            visible: isDone && completed_at,
            title: entities?.order_completed_at?.value,
            value: completed_at,
        },
        {
            visible: isArchive && archived_at,
            title: entities?.order_archived_at?.value,
            value: archived_at,
        },
    ];
};

export const getRepeatString = ({
    entities,
    range,
    day,
}: {
    entities?: TranslationObject;
    range: string;
    day?: number;
}) => {
    let str;
    const ranges = getRanges(entities);
    const currentLanguage = getCurrentLanguage();
    const currentRange = ranges?.find((elem) => elem.name === range);

    if (range) {
        str = currentRange?.label;
    }
    if (range === "repeat_week_day") {
        const weekdays = getWeekdays(currentLanguage);
        const weekday = weekdays?.find((elem, key) => key + 1 === Number(day));
        str = `${entities?.order_periodicity_every?.value} ${weekday}`;
    }

    if (range === "repeat_month_day") {
        str = `${entities?.order_periodicity_every?.value} ${day} ${entities?.order_periodicity_day?.value}`;
    }

    return str;
};

export const checkOrderMatchesFilters = (
    { type, filters, order }:
        { type: OrderItem['status'], filters: filterOrdersState, order: OrderItem }
) => {
    const otherList = filters?.status !== type
    const orderWithOtherCategory = filters?.service_category_id
        && filters?.service_category_id !== order.service_category_id
    const orderWithOtherType = filters?.service_type_id
        && filters?.service_type_id !== order.service_type_id
    const orderWithOtherSubType = filters?.service_sub_type_ids?.length > 0
        && !filters?.service_sub_type_ids?.find(elem => order?.service_sub_type_ids?.find(item => elem === item))
    const orderWithOtherMeetPoint = filters?.is_remote
        && filters?.is_remote
        && !order.is_remote
        || filters?.is_my_address
        && filters?.is_my_address
        && !order.is_my_address
        || filters?.is_specialist_address
        && filters?.is_specialist_address
        && !order.is_specialist_address

    const orderWithOtherStartAt = filters?.started_at
        && new Date(filters?.started_at) > new Date(order.started_at)
    const orderWithOtherEndedAt = filters?.ended_at
        && (order.ended_at && new Date(filters?.ended_at) > new Date(order.ended_at)
            || !order.ended_at && new Date(filters?.ended_at) > new Date(order.started_at))

    if (otherList
        || orderWithOtherCategory
        || orderWithOtherType
        || orderWithOtherSubType
        || orderWithOtherMeetPoint
        || orderWithOtherStartAt
        || orderWithOtherEndedAt) {
        return false
    }

    return true
}

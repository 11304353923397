import { request } from "../request";
import {
    bodyRegister,
    bodyRequest,
    confirmationsRequest,
} from "../../models/authModel";

export const login = ({ body, type }: bodyRequest) =>
    request({
        url: `auth/login/${type}`,
        method: "POST",
        body,
        withoutAuthorization: true,
    });

export const confirmations = ({
    id,
    token,
    user_id = null
}: confirmationsRequest) =>
    request({
        url: `confirmations/${id}/execute`,
        method: "POST",
        body: { 
            token: token,
            ...((user_id) ? {id: user_id} : {})
        },
    });

export const sendPhoneConfirmation = ({
    body,
}: bodyRegister) =>
    request({
        url: `auth/register/send_phone_confirmation`,
        body,
        method: "POST",
        withoutAuthorization: true,
    });

export const storePassword = ({
    body,
}: bodyRegister) => {
    return request({
        url: `auth/register/store_password`,
        method: "POST",
        body,
    });
};

export const storeProfile = ({ body }: bodyRegister) =>
    request({
        url: `auth/register/store_profile`,
        method: "POST",
        body,
    });

import React, { Dispatch } from "react";
import _ from "lodash";
import { TranslationObject } from "../../../models/translationModal";
import { isSpecialistUrl } from "../../../store/directories/selectors";
import cn from "classnames";

interface Props {
    entities?: TranslationObject;
    isActive: boolean;
    hasSpecialists: boolean;
    changeStatus: (value: string) => void;
    isDropDown: boolean;
    isSpecialist: boolean;
    order_id: number;
    setShowModal?: Dispatch<string>;
    setSelectedOrder?: Dispatch<number>;
}

const CopyOrderButton2: React.FC<Props> = ({
    entities,
    isActive,
    hasSpecialists,
    changeStatus,
    isDropDown = true,
    isSpecialist,
    order_id,
    setShowModal,
    setSelectedOrder
}) => {
    const text = entities?.order_make_copy?.value;
    // if (!isActive || hasSpecialists) {
    //     return null;
    // }

    const handleClick = () => {
        changeStatus('draft')
        setShowModal?.('draft')
    }

    return isDropDown ? (
        <div
            className="dotted-menu__item"
            onClick={() => handleClick()}
            key={_.uniqueId("button_dropdown_order")}
        >
            <div className="dotted-menu__item-link">{text}</div>
        </div>
    ) : (
        <button
            onClick={() => handleClick()}
            className={cn("btn order-information__button order-information__button--list", {
                "btn--green": isSpecialist || isSpecialistUrl(),
            })}
            type="button"
        >
            {text}
        </button>
    );
};

export default CopyOrderButton2;

import React from "react";
import Icon from "../../Icon";
import { ChatsPayload } from "../../../models/ordersModel";
import { Link } from "react-router-dom";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import { ProfilePayload } from "../../../models/userModels";
import { TranslationObject } from "../../../models/translationModal";
import classNames from "classnames";

interface Props {
    chat: ChatsPayload;
    profile?: ProfilePayload;
    entities?: TranslationObject;
}

const ChatCard: React.FC<Props> = ({ chat, profile, entities }) => {
    const currentLanguage = getCurrentLanguage();
    const to = `/${currentLanguage}/${profile?.user_role}/lk/order/${chat?.order?.id}/chat/${chat?.id}`;
    const isSpecialist = profile?.user_role === "specialist";

    return (
        <Link to={to}>
            <li className="pinned-chats__item">
                <div className="pinned-chats__top-wrapper">
                    <p className="pinned-chats__item-heading">{chat?.order?.title}</p>
                    <button
                        className="pinned-chats__item-button"
                        type="button"
                        aria-label={entities?.chat_pin_chat?.value}
                    >
                        <Icon type="fixed" width={16} height={16} />
                    </button>
                </div>
                <div className="pinned-chats__bottom-wrapper">
                    <p className="pinned-chats__item-name">{isSpecialist ? chat?.customer?.name : chat?.specialist?.name}</p>
                    <span className={classNames("pinned-chats__msg-count", { 'user-panel__link_green': isSpecialist })}>
                        {chat?.unviewed_messages_count}
                    </span>
                </div>
            </li>
        </Link>
    );
};

export default ChatCard;

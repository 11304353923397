import React from "react";
import classNames from "classnames";
import { toArray } from "../../../lib/converters";
import Icon from "../../Icon";

interface ControlProps {
    invalidFields: any;
    label?: string;
    id?: string;
    children: React.ReactNode;
    error: any;
    name?: string | null;
    required?: boolean;
    controlMessage?: string;
    classes?: string[];
    button?: React.ReactNode;
    icon?: {
        type: string;
        width: number;
        height: number;
        className?: string;
    } | null;
    span?: {
        text?: string | number;
        className?: string;
    };
}

const Control: React.FC<ControlProps> = ({
    invalidFields,
    children,
    error,
    name,
    classes,
    button,
    icon,
    span,
}) => {
    const flattenName = name?.split(`]`)?.join(``)?.split(`[`)?.join(`.`);

    const textError =
        flattenName &&
        invalidFields &&
        invalidFields?.has(flattenName) &&
        error?.errors[flattenName] &&
        toArray(error?.errors[flattenName])?.join(`, `);

    return (
        <div
            className={classNames(`custom-input ` + classes?.join(" "), {
                "is-invalid":
                    flattenName &&
                    !!invalidFields &&
                    invalidFields?.has(flattenName) &&
                    error?.errors[flattenName] &&
                    toArray(error?.errors[flattenName])?.join(`, `),
            })}
        >
            <label>{children}</label>
            {span && <span className={span?.className}>{span?.text}</span>}
            {!!icon && (
                <Icon
                    type={icon?.type}
                    width={icon?.width}
                    height={icon?.height}
                    className={icon?.className}
                />
            )}
            {button}
            <div className="custom-input__error">{textError}</div>
        </div>
    );
};

export default Control;

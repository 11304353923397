import React from "react";
import AccordionView from "../AccordionView";
import CheckList from "../../../components/CheckList";
import { TranslationObject } from "../../../models/translationModal";
import Input from "../../../components/Form/Input";

interface FilterProps {
    service_type_filter_id: number;
    service_type_filter_value_ids: number[];
    custom_filter_value?: string;
}

interface Props {
    filter?: FilterProps;
    ids: number[];
    id: number | null;
    isSuccess: boolean;
    isMultiple: boolean;
    items: { name: string; id: number }[];
    entities?: TranslationObject;
    onChange: (obj: FilterProps) => void;
    name: string;
    filterId: number;
    hasCustomValue: boolean;
}

const FilterItem: React.FC<Props> = ({
    filter,
    ids,
    id,
    isSuccess,
    isMultiple,
    items,
    entities,
    onChange,
    name,
    filterId,
    hasCustomValue,
}) => {
    const isDisabledInput =
        !filter?.service_type_filter_value_ids ||
        filter?.service_type_filter_value_ids?.length < 1;

    const _onChange = (value_id: number) => {
        let obj;
        if (filter && isMultiple) {
            if (
                filter?.service_type_filter_value_ids?.indexOf(value_id) !== -1
            ) {
                const items = filter?.service_type_filter_value_ids?.filter(
                    (elem: number) => elem !== value_id
                );

                obj = {
                    ...filter,
                    service_type_filter_value_ids: items,
                };
                if (filter?.custom_filter_value && items?.length < 1) {
                    obj = {
                        ...obj,
                        custom_filter_value: "",
                    };
                }
            } else {
                obj = {
                    ...filter,
                    service_type_filter_value_ids:
                        filter.service_type_filter_value_ids.concat(value_id),
                };
            }
        } else if (filter) {
            obj = {
                ...filter,
                service_type_filter_value_ids: [value_id],
            };
        } else {
            obj = {
                service_type_filter_id: filterId,
                service_type_filter_value_ids: [value_id],
            };
        }

        onChange(obj);
    };

    const handleChange = (value: string) => {
        const obj = {
            ...(filter as FilterProps),
            custom_filter_value: value,
        };

        onChange(obj);
    };

    return (
        <AccordionView textButton={name || ""}>
            <div className="accordion-form__item-content">
                <CheckList
                    items={items}
                    ids={ids}
                    id={id}
                    isSuccess={isSuccess}
                    isMultiple={isMultiple}
                    entities={entities}
                    onChange={(idItem) => _onChange(idItem)}
                />
            </div>
            {hasCustomValue && (
                <fieldset>
                    <legend>
                        {entities?.order_filter_custom_answer_details?.value}
                    </legend>
                    <Input
                        onChange={handleChange}
                        type="text"
                        placeholder=""
                        disabled={isDisabledInput}
                        classes={[
                            "custom-input--text-right custom-input--small",
                        ]}
                        value={filter?.custom_filter_value}
                    />
                </fieldset>
            )}
        </AccordionView>
    );
};
export default FilterItem;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FeedBackObject } from "../../models/feedBackModel";
import { createFeedback } from "./api";
import { changeHeaders } from "../request";
import { BodyFormData } from "../models";

export const feedbackApi = createApi({
    reducerPath: "feedback/api",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
        prepareHeaders: (headers, { getState }) =>
            changeHeaders(headers, { getState }),
    }),
    endpoints: (build) => ({
        createFeedback: build.mutation<FeedBackObject, BodyFormData>({
            query: ({ body }) => createFeedback({ body }),
        }),
    }),
});

export const { endpoints, useCreateFeedbackMutation } = feedbackApi;

import React, { useEffect } from "react";
import { endpoints } from "../../../store/directories/directories.api";
import { getItemEditOrder } from "../../../store/order/selectors";
import { useActions } from "../../../hooks/actions";
import { FiltersPayload } from "../../../models/directoryModel";
import { TranslationObject } from "../../../models/translationModal";
import {
    FILTER_PAGES,
    SERVICE_CATEGORY_ID,
    SERVICE_TYPE_ID,
} from "../../../store/order/constant";
import FilterItem from "./FilterItem";

interface FilterProps {
    service_type_filter_id: number;
    service_type_filter_value_ids: number[];
    custom_filter_value?: string;
}

interface Props {
    entities?: TranslationObject;
}

const FiltersEditOrder: React.FC<Props> = ({ entities }) => {
    const name = FILTER_PAGES;
    const { updateEditOrderField } = useActions();
    const activeFilters = getItemEditOrder(FILTER_PAGES);
    const typeId = getItemEditOrder(SERVICE_TYPE_ID);
    const categoryId = getItemEditOrder(SERVICE_CATEGORY_ID);
    const [getFilters, { data: filters, isSuccess }] =
        endpoints.getFilters.useLazyQuery();

    useEffect(() => {
        if (typeId) {
            getFilters({
                id: typeId,
            });
        }
    }, [typeId]);

    const onChange = (obj: FilterProps, filterId: number) => {
        const value = activeFilters?.length
            ? [
                  ...activeFilters.filter(
                      (elem) => elem.service_type_filter_id !== filterId
                  ),
                  obj,
              ]
            : [obj];

        updateEditOrderField({
            name,
            value: value,
        });
    };

    if (!categoryId || !typeId) {
        return null;
    }

    return (
        <>
            {filters?.map((filter: FiltersPayload) => {
                const isMultiple = filter?.type !== "single";
                const ids =
                    isMultiple &&
                    activeFilters?.find(
                        (elem) => elem.service_type_filter_id === filter.id
                    )?.service_type_filter_value_ids;
                const id =
                    !isMultiple &&
                    activeFilters?.find(
                        (elem) => elem.service_type_filter_id === filter.id
                    )?.service_type_filter_value_ids[0];
                const activeFilter = activeFilters?.find(
                    (elem) => elem.service_type_filter_id === filter.id
                );

                return (
                    <FilterItem
                        filterId={filter?.id}
                        key={filter?.id}
                        items={
                            filter?.values.map((elem) => ({
                                name: elem.name,
                                id: elem.id,
                            })) || []
                        }
                        hasCustomValue={filter?.has_custom_value}
                        ids={ids || []}
                        id={id ? id : null}
                        isSuccess={isSuccess}
                        isMultiple={isMultiple}
                        entities={entities}
                        name={filter?.name}
                        filter={activeFilter}
                        onChange={(obj: FilterProps) =>
                            onChange(obj, filter?.id)
                        }
                    />
                );
            })}
        </>
    );
};

export default FiltersEditOrder;

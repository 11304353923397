import React, { useEffect, useState } from "react";
import { getItemStore } from "../../../untils";
import { endpoints } from "../../../store/directories/directories.api";
import { SubTypesPayload } from "../../../models/directoryModel";
import { useNavigate } from "react-router-dom";
import {
    SERVICE_TYPE_ID,
    SERVICE_SUB_TYPE_IDS,
    CUSTOM_SERVICE_SUB_TYPE,
} from "../../../store/order/constant";
import {
    getItemCreateOrder,
    getItemEditOrder,
} from "../../../store/order/selectors";

const SubTypeItem: React.FC<{
    currentLanguage: string;
    isEdit: boolean;
}> = ({ currentLanguage, isEdit }) => {
    const navigate = useNavigate();
    const id = isEdit
        ? getItemEditOrder(SERVICE_TYPE_ID)
        : getItemCreateOrder(SERVICE_TYPE_ID);
    const ids = isEdit
        ? getItemEditOrder(SERVICE_SUB_TYPE_IDS)
        : getItemCreateOrder(SERVICE_SUB_TYPE_IDS);
    const customValue = isEdit
        ? getItemEditOrder(CUSTOM_SERVICE_SUB_TYPE)
        : getItemCreateOrder(CUSTOM_SERVICE_SUB_TYPE);
    const [types, setTypes] = useState<SubTypesPayload[]>([]);

    const [getServiceSubTypes, { data }] =
        endpoints.getServiceSubTypes.useLazyQuery();

    useEffect(() => {
        if (id) {
            getServiceSubTypes({
                id,
            });
        }
    }, [id]);

    useEffect(() => {
        const items =
            Array.isArray(ids) &&
            data?.filter((elem) => ids.indexOf(elem.id) !== -1);
        setTypes(items || []);
    }, [data]);

    const link = `/${currentLanguage}/customer/${
        isEdit ? "edit-order" : "create-order/subtype"
    }`;

    return types?.length > 0 ? (
        <>
            {types?.map((type: SubTypesPayload, key: number) => (
                <div className="form__row" key={key}>
                    <div className="link" onClick={() => navigate(link)}>
                        {type?.name}
                    </div>
                </div>
            ))}
        </>
    ) : (
        <div className="form__row">
            <div className="link" onClick={() => navigate(link)}>
                {customValue}
            </div>
        </div>
    );
};

export default SubTypeItem;

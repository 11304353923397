import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type feedBackState = {
    name: string
    email: string
    text: string
    questionType: string
    personalDataAgreement: boolean
    needAuth: boolean
    files: any[]
};

const initialState: feedBackState = {
    name: "",
    email: "",
    text: "",
    personalDataAgreement: false,
    needAuth: false,
    questionType: '',
    files: []
};

export const feedBackSlice = createSlice({
    name: "registration",
    initialState,
    reducers: {
        setFeedBackField<feedBackState, K extends keyof feedBackState>(
            state: feedBackState,
            action: PayloadAction<{ name: K; value: feedBackState[K] }>
        ) {
            if (action.payload.name !== 'files') {
                state[action.payload.name] = action.payload.value;
            } else {
                const file = state.files.find(item => item.name === action.payload.value.name);
                if (!file) {
                    state.files = [...state.files, action.payload.value];
                }
            }
        },
        removeFeedBackFile<feedBackState, K extends keyof feedBackState>(
            state: feedBackState,
            action: PayloadAction<{ name: K; value: any }>
        ) {
            state.files = [...state.files.filter(item => item.name !== action.payload.value.name)];
        },
        clearFeedBackField(state) {
            state = Object.assign(state, initialState);
        },
        clearFeedBackFiles(state) {
            state.files = [];
        }
    }
});

export const feedBackActions = feedBackSlice.actions;
export const feedBacksReducer = feedBackSlice.reducer;

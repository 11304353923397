import React, { useState } from "react";
import Score from "../../../components/Score";
import { OrderItem, Review } from "../../../models/ordersModel";
import { TranslationObject } from "../../../models/translationModal";
import ReviewModal from "../../../components/Modals/ReviewModal";
import ReviewOrderButton from "../../../components/Buttons/ReviewOrderButton";
import { DateTime } from "luxon";
import { getCurrentLanguage } from "../../../store/directories/selectors";

interface Props {
    isDone: boolean;
    review: Review;
    isSpecialist: boolean;
    entities?: TranslationObject;
    score?: number;
    text?: string;
    order: OrderItem;
}

const ReviewOrder: React.FC<Props> = ({
    isDone,
    review,
    isSpecialist,
    entities,
    text,
    score,
    order,
}) => {
    if (!isDone || !review) {
        return null;
    }
    const [showModal, setShowModal] = useState<boolean>(false);
    const currentLanguage = getCurrentLanguage();

    const handleClick = () => {
        setShowModal(true);
    };

    const timeIsOver =
        !!review?.editable_until &&
        DateTime?.fromSQL(review?.editable_until) < DateTime.local();

    return (
        <div className="review-block">
            <ReviewModal
                visible={showModal}
                onClose={() => setShowModal(false)}
                order_id={order?.id}
                id={order?.review?.id}
                isSpecialist={isSpecialist}
                score={score}
                text={text}
                entities={entities}
            />
            <Score
                classNameSvg="review-block__indicator-icon"
                classNameSpan="review-block__indicator-index"
                score={review?.score}
                width={24}
                height={24}
                className="review-block__indicator"
            />
            <p className="review-block__content">{review?.text}</p>
            <span className="review-block__data">
                {review?.created_at &&
                    DateTime.fromSQL(review?.created_at)
                        ?.setLocale(currentLanguage)
                        ?.toFormat("dd MMMM yyyy")}
            </span>
            <ReviewOrderButton
                review={review}
                isSpecialist={isSpecialist}
                onClick={handleClick}
                entities={entities}
                text={text}
                score={score}
                isOrder
                className="review-block__edit-review link-underline"
            />
            {!timeIsOver && <span className="review-block__time">
                {entities?.order_24_hours_available?.value}
            </span>}
        </div>
    );
};

export default ReviewOrder;

import React, { useMemo } from "react";
import Select, { SingleValue } from "react-select";
import { endpoints } from "../../../../store/user/user.api";
import { useActions } from "../../../../hooks/actions";
import { useSelector } from "react-redux";
import { selectEmployeeSelector } from "../../../../store/finances/finances.selectors";
import { TranslationObject } from "../../../../models/translationModal";

interface SpecialistsSelectProps {
    entities?: TranslationObject;
}

export const SpecialistsSelect: React.FC<SpecialistsSelectProps> = ({
    entities,
}) => {
    const { data: companyEmployees } = endpoints.getCompanyEmployees.useQuery();

    const selectEmployee = useSelector(selectEmployeeSelector);

    const { setSelectEmployee, setCurrentPage } = useActions();

    const employeesOptions = useMemo(() => {
        if (companyEmployees?.length) {
            return companyEmployees.map((employee) => {
                return {
                    value: employee?.id.toString(),
                    label: `${employee?.name} ${employee?.family_name}`,
                };
            });
        } else {
            return [];
        }
    }, [companyEmployees]);

    const handleChangeSelectEmployee = (
        value: SingleValue<{ value: string; label: string }>
    ) => {
        setSelectEmployee(value);
        setCurrentPage(1);
    };

    return (
        <div className="specialist-select__block">
            <p className="specialist-select__block-title">
                {entities?.finances_select_specialist?.value}
            </p>
            <Select
                className="specialist-select"
                classNamePrefix="specialist-select__prefix"
                placeholder={entities?.finances_all_specialists?.value}
                options={employeesOptions}
                value={selectEmployee}
                isClearable={true}
                onChange={handleChangeSelectEmployee}
                components={{
                    IndicatorSeparator: () => null,
                }}
            />
        </div>
    );
};

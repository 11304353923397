import CheckList from "../../../components/CheckList";
import { endpoints } from "../../../store/directories/directories.api";
import { useActions } from "../../../hooks/actions";
import Icon from "../../../components/Icon";
import { getFilter } from "../../../store/orders/selectors";
import {
    SERVICE_CATEGORY_ID,
    SERVICE_TYPE_ID,
} from "../../../store/order/constant";
import { TranslationObject } from "../../../models/translationModal";
import { ProfilePayload } from "../../../models/userModels";
import cn from "classnames";

interface Props {
    entities?: TranslationObject;
    profile?: ProfilePayload;
    setActiveMobileFilter: () => void;
    activeMobileFilter: string;
}

const CategoryFilter: React.FC<Props> = ({
    entities,
    profile,
    setActiveMobileFilter,
    activeMobileFilter,
}) => {
    const { setOrdersFilter, clearOrderFilter } = useActions();
    const name = SERVICE_CATEGORY_ID;
    const id = getFilter(SERVICE_CATEGORY_ID);
    const { data: categories, isSuccess } =
        endpoints.getServiceCategories.useQuery();
    const isSpecialist = profile?.user_role === "specialist";
    const profileCategories =
        (isSpecialist &&
            profile?.services?.map(
                (service) => service?.service_category?.id
            )) ||
        [];

    const specialistCategories =
        categories?.filter(
            (elem) => profileCategories?.indexOf(elem.id) !== -1
        ) || [];

    const onChange = (i: number) => {
        if (id === i) {
            return clearOrderFilter(name);
        }
        clearOrderFilter(SERVICE_TYPE_ID);
        setOrdersFilter({ name, value: [] });
        setOrdersFilter({
            name,
            value: i,
        });
    };

    return (
        <div
            className={cn("filter__block", {
                "is-open": activeMobileFilter === "category",
            })}
        >
            <button
                className="filter__subtitle"
                type="button"
                onClick={setActiveMobileFilter}
            >
                {entities?.order_service_category?.value}
                <Icon type="arrow-left-pagination" width={9} height={18} />
            </button>
            <div className="filter__wrap-block">
                <CheckList
                    entities={entities}
                    isSuccess={isSuccess}
                    onChange={onChange}
                    items={
                        isSpecialist ? specialistCategories : categories || []
                    }
                    id={id ? id : null}
                />
            </div>
        </div>
    );
};

export default CategoryFilter;

import React, { MouseEventHandler } from "react";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import { useNavigate } from "react-router-dom";
import { TranslationObject } from "../../../models/translationModal";

interface Props {
    isEdit: boolean;
    clearSpecialistIds: MouseEventHandler<HTMLButtonElement>;
    hasSpecialist: boolean;
    entities?: TranslationObject;
    specialistIds: number[];
    _updateOrder: () => void;
}

const ButtonsSpecialistPanel: React.FC<Props> = ({
    isEdit,
    clearSpecialistIds,
    hasSpecialist,
    entities,
    specialistIds,
    _updateOrder,
}) => {
    const currentLanguage = getCurrentLanguage();
    const navigate = useNavigate();

    const back = () => {
        if (!isEdit) {
            return;
        }
        navigate(`/${currentLanguage}/customer/edit-order`);
    };

    const textButtonSaveToDraft = entities?.order_save_with_draft_status?.value;
    const textButtonSubmit = isEdit
        ? entities?.order_save_and_published?.value
        : entities?.order_continue?.value;
    const textButtonBackOrClear = isEdit
        ? entities?.common_back_button?.value
        : entities?.order_reset?.value;
    const currentSpecialistWithWrongParameters = false;
    const isDisableSubmitButton =
        (isEdit && currentSpecialistWithWrongParameters) ||
        specialistIds.length < 1;
    const isDisableSaveToDraftButton =
        (isEdit && currentSpecialistWithWrongParameters) ||
        specialistIds.length < 1;

    return (
        <div className="output__btns">
            <button
                className="btn btn--rounded output__btn-cancel"
                onClick={isEdit ? back : clearSpecialistIds}
                type="button"
            >
                {textButtonBackOrClear}
            </button>
            {isEdit && hasSpecialist && (
                <button
                    className="btn btn--rounded output__btn-cancel"
                    type="button"
                    disabled={isDisableSaveToDraftButton}
                    onClick={_updateOrder}
                >
                    {textButtonSaveToDraft}
                </button>
            )}
            {hasSpecialist && (
                <button
                    disabled={isDisableSubmitButton}
                    className="btn btn--white btn--rounded output__btn-confirm"
                    type="submit"
                >
                    <span>{textButtonSubmit}</span>
                </button>
            )}
        </div>
    );
};

export default ButtonsSpecialistPanel;

import React from "react";
import HeaderOrder from "../../../Order/Header";
import { OrderItem } from "../../../../models/ordersModel";
import { ProfilePayload } from "../../../../models/userModels";
import { TranslationObject } from "../../../../models/translationModal";
import { generatePath, useNavigate, useParams } from "react-router-dom";

interface OrderInformationProps {
    entities: TranslationObject;
    order: OrderItem;
    profile?: ProfilePayload;
    isChatPage?: boolean;
}

export const OrderInformation: React.FC<OrderInformationProps> = ({
    entities,
    order,
    profile,
    isChatPage
}) => {
    const navigate = useNavigate();

    const { currentLanguage, role, id } = useParams();

    const isSpecialist = profile?.user_role === "specialist";

    const isDone = order?.status === "done";

    const isActive = order?.status === "active";

    const isDraft = order?.status === "draft";

    const isDeal = order?.status === "deal";

    const isArchive = order?.status === "archive";

    const status = order?.status;

    const isPrivate = order?.is_private;

    const hasExecutor = !!order?.executor;

    const statusProps = {
        isDone,
        isActive,
        isDraft,
        isDeal,
        isArchive,
    };

    const handleClickDetailOrderButton = () => {
        if (!currentLanguage || !role || !id) return;
        navigate(
            generatePath(`/:currentLanguage/:role/lk/order/:id`, {
                currentLanguage,
                role,
                id,
            })
        );
    };

    return (
        <>
            <div className="container">
                <HeaderOrder
                    showSpecialistList={true}
                    isChatPage={isChatPage}
                    handleClickDetailOrderButton={handleClickDetailOrderButton}
                    changeStatus={() => null}
                    entities={entities}
                    date={order?.execution_at}
                    {...statusProps}
                    isSpecialist={isSpecialist}
                    status={status}
                    title={order?.title}
                    order={order}
                    hasExecutor={hasExecutor}
                    userId={profile?.id}
                    classes={"page__intro"}
                />
            </div>
        </>
    );
};

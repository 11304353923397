import React from "react";
import _ from "lodash";
import OrderCard from "../../../components/OrderCard";
import { OrderItem } from "../../../models/ordersModel";
import { ProfilePayload } from "../../../models/userModels";
import { TranslationObject } from "../../../models/translationModal";

interface Props {
    list: OrderItem[];
    entities?: TranslationObject;
    isSpecialist: boolean;
    profile?: ProfilePayload;
}

const ChildrenList: React.FC<Props> = ({
    list,
    entities,
    isSpecialist,
    profile,
}) => {
    if (list?.length < 1) {
        return null;
    }

    return (
        <div className="active-order__cocluded-deals">
            <div className="concluded-deals">
                <h3 className="title title--h3">
                    {entities?.order_child?.value}
                </h3>
                <ul className="concluded-deals__list">
                    {list?.map((order) => (
                        <OrderCard
                            order={order}
                            key={_.uniqueId("child_order")}
                            entities={entities}
                            isSpecialist={isSpecialist}
                            profile={profile}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ChildrenList;

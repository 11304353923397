import React, { useState } from "react";
import Icon from "../../../../components/Icon";
import { TranslationObject } from "../../../../models/translationModal";

interface IEmployee {
    id: number;
    name: string;
    familyName: string;
    registerDate: string;
    isConfirmedManager: boolean | null;
    isManager: boolean;
    services: string[];
    verified: boolean;
}

interface CompanyEmployeeProps {
    employee: IEmployee;
    acceptOrRejectEmployee: (
        employeeId: number | string,
        value: boolean
    ) => unknown;
    assignEmployeeManager: (employeeId: number | string) => unknown;
    deleteEmployee: (employeeId: number | string) => unknown;
    entities?: TranslationObject;
}

const getEmployeeRegister = (registerDate: string) => {
    if (!registerDate) return "";
    const date = new Date(registerDate);

    let day = date?.getDate().toString();
    let month = date?.getMonth().toString();
    const year = date?.getFullYear();

    if (day?.length === 1) {
        day = "0" + day;
    }

    if (month?.length === 1) {
        month = "0" + month;
    }

    return `${day}.${month}.${year}`;
};

export const CompanyEmployee: React.FC<CompanyEmployeeProps> = (props) => {
    const {
        employee,
        acceptOrRejectEmployee,
        assignEmployeeManager,
        deleteEmployee,
        entities,
    } = props || {};
    const {
        id,
        name,
        familyName,
        registerDate,
        isConfirmedManager,
        isManager,
        services,
        verified,
    } = employee || {};

    const employeeRegister = getEmployeeRegister(registerDate);

    const [showServices, setShowServices] = useState<boolean>(false);

    return (
        <div className="profile-block__row" key={id}>
            <div className="profile-block__col">
                <h3 className="profile-block__person title">
                    {`${name} ${familyName}`}
                </h3>
                {showServices &&
                    services?.map((service) => (
                        <>{service && <p className="text">{service}</p>}</>
                    ))}
                <button
                    className="profile-block__link link link--underline link--green"
                    onClick={() => setShowServices((state) => !state)}
                >
                    {showServices && services?.length
                        ? entities?.specialist_details_hide_services?.value
                        : entities?.specialist_details_specialist_services
                              ?.value}
                </button>
            </div>
            {(isConfirmedManager === null && (
                <div className="profile-block__buttons">
                    <button
                        className="btn specialist-profile__btn btn--transparent"
                        type="button"
                        onClick={() => acceptOrRejectEmployee(id, true)}
                    >
                        {
                            entities?.specialist_details_confirm_registration
                                ?.value
                        }
                    </button>
                    <button
                        className="btn specialist-profile__btn btn--transparent"
                        type="button"
                        onClick={() => acceptOrRejectEmployee(id, false)}
                    >
                        {
                            entities?.specialist_details_decline_registration
                                ?.value
                        }
                    </button>
                </div>
            )) || (
                <>
                    <div className="profile-block__col">
                        {employeeRegister && (
                            <p className="text">
                                {entities?.registration_link_value?.value}
                                <span>{employeeRegister}</span>
                            </p>
                        )}
                    </div>
                    <div className="profile-block__col">
                        <div className="custom-toggle custom-toggle--checkbox custom-toggle--green">
                            <label>
                                <input
                                    onChange={() =>
                                        !isManager && assignEmployeeManager(id)
                                    }
                                    type="checkbox"
                                    id="check-3"
                                    name="check"
                                    checked={isManager}
                                />
                                <span className="custom-toggle__icon" />
                                <span className="custom-toggle__label">
                                    {
                                        entities?.specialist_details_manager
                                            ?.value
                                    }
                                </span>
                            </label>
                        </div>
                    </div>
                    {verified && (
                        <div className="profile-block__check">
                            <Icon type={"valid"} width={20} height={14} />
                        </div>
                    )}
                    <div className="profile-block__controls">
                        <button
                            className="profile-block__btn btn btn--no-bg"
                            type="button"
                            aria-label={
                                entities?.profile_destroy_button_ok?.value
                            }
                            onClick={() => deleteEmployee(id)}
                        >
                            <Icon
                                type="close-input-current-color"
                                width={20}
                                height={20}
                                colorVariant={"grayLight"}
                            />
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

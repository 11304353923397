import React, { Dispatch, useEffect, useState } from "react";
import DetailInfo from "../DetailInfo";
import UserCard from "../UserCard";
import ChatsSidebar from "../../../components/ChatsSidebar";
import List from "../List";
import ChildrenList from "../ChildsList";
import Review from "../Review";
import { TranslationObject } from "../../../models/translationModal";
import { OrderItem } from "../../../models/ordersModel";
import CompleteOrderButton from "../../../components/Buttons/CompleteOrderButton";
import PublishButton from "../../../components/Buttons/PublishOrderButton";
import CloseOrderButton from "../../../components/Buttons/CloseOrderButton";
import { ProfilePayload } from "../../../models/userModels";
import { OrderSpecialistItem } from "../../../models/orderModel";
import ResponseOrderButton from "../../../components/Buttons/ResponseOrderButton";
import classNames from "classnames";
import ChatButton from "../../../components/Buttons/ChatButton";
import CreateReviewCustomerButton from "../../../components/Buttons/CreateReviewCustomerButton";
import Advertising from "../../../components/Advertising";
import ReviewModal from "../../../components/Modals/ReviewModal";
import OrderDateModal from "../../../components/Modals/OrderDateModal";

interface Props {
    entities?: TranslationObject;
    order: OrderItem;
    isDone: boolean;
    isActive: boolean;
    isDraft: boolean;
    isDeal: boolean;
    isArchive: boolean;
    isSpecialist: boolean;
    isPrivate: boolean;
    changeStatus: (body: {status: string, started_at?: string, ended_at?: string}) => void;
    profile?: ProfilePayload;
    setShowSpecialistList: Dispatch<boolean>;
}

const BaseComponent: React.FC<Props> = ({
    entities,
    order,
    isDone,
    isActive,
    isDeal,
    isSpecialist,
    isPrivate,
    isDraft,
    changeStatus,
    profile,
    setShowSpecialistList,
}) => {
    const [specialists, setSpecialists] = useState<OrderSpecialistItem[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const showAddSpecialistButton = isActive && isPrivate && !isSpecialist;
    const showList = isActive && !isSpecialist;
    const role = profile?.user_role;

    useEffect(() => {
        if (order) {
            if (!isPrivate) {
                setSpecialists(order?.responded_specialists || []);
            } else {
                const items: OrderSpecialistItem[] = [];
                order?.responded_specialists?.forEach((elem) =>
                    items.push(elem)
                );
                order?.specialists?.forEach(
                    (elem) =>
                        !order?.responded_specialists?.find(
                            (item) => item?.id === elem?.id
                        ) && items.push(elem)
                );
                setSpecialists(items || []);
            }
        }
    }, [order]);

    const handelChangeStatus = (str: string, startAt?: string, endAt?: string) => {
        changeStatus({
            status: str,
            ...(startAt ? { started_at: startAt } : {}),
            ...(startAt ? { ended_at:  endAt || "" } : {})
        })
    }

    const publishOrder = () => {
        const orderDate = new Date(order?.started_at);
        const currentDate = new Date();

        if (orderDate < currentDate && orderDate.toDateString() !== currentDate.toDateString()) {
            setVisible(true);
        } else {
            changeStatus({ status: "active" });
        }
    }

    return (
        <div className="inner-container">
            <DetailInfo
                entities={entities}
                order={order}
                isSpecialist={isSpecialist}
            />
            <div
                className={classNames({
                    "active-order__buttons": !isDone,
                })}
            >
                <ChatButton
                    entities={entities}
                    order={order}
                    orderId={order?.id}
                    isSpecialist={isSpecialist}
                    role={role}
                    isActive={isActive}
                    isDeal={isDeal}
                    isDone={isDone}
                    specialist={order?.executor}
                    isPrivate={isPrivate}
                    isOrderPage={true}
                    isUnresponsiveSpecialist={true}
                    isSpecialistCardBtn={false}
                />
                {!isSpecialist ? (
                    <>
                        <PublishButton
                            isDropDown={false}
                            isDraft={isDraft}
                            publishOrder={publishOrder}
                            entities={entities}
                        />
                        <CompleteOrderButton
                            isDeal={isDeal}
                            isDropDown={false}
                            order={order}
                            isSpecialist={isSpecialist}
                            entities={entities}
                            showModal={showModal}
                            setShowModal={setShowModal}
                        />
                        {showModal && (
                            <ReviewModal
                                key={"modal_review" + order?.id}
                                visible={showModal}
                                onClose={() => setShowModal(false)}
                                order_id={order?.id}
                                id={order?.review?.id}
                                isSpecialist={isSpecialist}
                                score={order?.review?.score}
                                text={order?.review?.text}
                                entities={entities}
                            />
                        )}
                        {/* <CloseOrderButton
                            isActive={isActive}
                            changeStatus={changeStatus}
                            isDraft={isDraft}
                            isDropDown={false}
                            entities={entities}
                            isPrimary={!isDraft}
                        /> */}
                        {showAddSpecialistButton && (
                            <button
                                className="btn active-order__add-specialist-button"
                                onClick={() => setShowSpecialistList(true)}
                            >
                                {entities?.order_add_specialists?.value}
                            </button>
                        )}
                    </>
                ) : (
                    <>
                        <ResponseOrderButton
                            entities={entities}
                            isSpecialist={isSpecialist}
                            order={order}
                            isActive={isActive}
                        />
                        {isDone && !order?.review && (
                            <CreateReviewCustomerButton
                                entities={entities}
                                isSpecialist={isSpecialist}
                                order_id={order?.id}
                            />
                        )}
                    </>
                )}
            </div>
            <UserCard
                user={isSpecialist ? order?.customer : order?.executor}
                isDeal={isDeal}
                isDone={isDone}
                entities={entities}
                isSpecialist={isSpecialist}
            />
            <Review
                review={order?.review}
                isDone={isDone}
                entities={entities}
                isSpecialist={isSpecialist}
                score={order?.review?.score}
                text={order?.review?.text}
                order={order}
            />
            <ChatsSidebar
                id={order?.id}
                entities={entities}
                profile={profile}
                isSpecialist={isSpecialist}
            />
            {showList && (
                <List
                    order={order}
                    entities={entities}
                    specialists={specialists}
                    isPrivate={isPrivate}
                    isSpecialist={isSpecialist}
                    isActive={isActive}
                    setShowSpecialistList={setShowSpecialistList}
                />
            )}
            <ChildrenList
                list={order?.children || []}
                entities={entities}
                isSpecialist={isSpecialist}
            />
            {isSpecialist && (
                <div className="advertising-container_active">
                    <h3 className="advertising-container__title">
                        {entities?.main_page_useful_ads?.value}
                    </h3>
                    <ul className="advertising-list no-scrollbar">
                        <Advertising
                            typeAdvertising={"thirdParties"}
                            entities={entities}
                        />
                    </ul>
                </div>
            )}
            {!isSpecialist && (
                <OrderDateModal
                    entities={entities}
                    visible={visible}
                    changeVisible={setVisible}
                    handleChange={(startAt, endAt) => handelChangeStatus("active", startAt, endAt)}
                />
            )}
        </div>
    );
};
export default BaseComponent;

import React from "react";
import { TranslationObject } from "../../models/translationModal";
import Form from "../Form/Form";
import TextArea from "../Form/TextArea";
import Modal from "../Modal";

interface AboutYouModalProps {
    visibleModal: boolean;
    closeModal: () => unknown;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => unknown;
    aboutMeInputValue: string;
    onChangeInput: (value: string) => unknown;
    maxLengthInput: number;
    maxLengthError: boolean;
    onClickCancel: () => unknown;
    entities?: TranslationObject;
}

export const AboutYouModal: React.FC<AboutYouModalProps> = (props) => {
    const {
        visibleModal,
        closeModal,
        onSubmit,
        aboutMeInputValue,
        onChangeInput,
        maxLengthInput,
        maxLengthError,
        onClickCancel,
        entities,
    } = props || {};

    return (
        <>
            <Modal
                visible={visibleModal}
                onClose={closeModal}
                title={entities?.specialist_details_about_me?.value}
                modificators={["modal-specialist"]}
            >
                <Form onSubmit={onSubmit} className={"aboutme-form"}>
                    {(invalidFields, error) => (
                        <>
                            <TextArea
                                type="text"
                                invalidFields={invalidFields}
                                error={error}
                                defaultValue={aboutMeInputValue}
                                onChange={onChangeInput}
                                placeholder={
                                    entities
                                        ?.profile_specialist_questionnaire_about_work_warn
                                        ?.value
                                }
                                autoSize={true}
                                maxLength={maxLengthInput}
                                lengthError={maxLengthError}
                                entities={entities}
                            />
                            <div className="form__row form__controls form__controls--right">
                                <button
                                    onClick={onClickCancel}
                                    className="btn form__back-link btn--transparent"
                                    type="button"
                                >
                                    {entities?.common_cancel_button?.value}
                                </button>
                                <button
                                    className="btn btn--transparent btn--bg-green"
                                    type="submit"
                                    disabled={maxLengthError}
                                >
                                    {entities?.common_save_button?.value}
                                </button>
                            </div>
                        </>
                    )}
                </Form>
            </Modal>
        </>
    );
};

import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";

import Icon from "../../../../components/Icon";
import { useUpdateUserAboutMeMutation } from "../../../../store/user/user.api";
import { AboutYouModal } from "../../../../components/Modals/AboutYouModal";
import { TranslationObject } from "../../../../models/translationModal";
import ConfirmDeleteModal from "../../../../components/Modals/ConfirmDeleteModal/ConfirmDeleteModal";
import { IProfileTag, IAiTag } from "../../../../models/orderModel";

interface TellUsAboutYourselfProps {
    userAboutMe?: string;
    currentLanguage: string;
    userVerification?: "verified" | "unverified";
    entities?: TranslationObject;
    userPublished?: boolean;
    userSuspended?: boolean;
    tagsAi?: IAiTag[];
    isShowLoadingAI?: TisShowLoadingAI;
    handleSetTag?: (tag: IProfileTag, tagAi?: IAiTag) => unknown;
    handleIsEditAbout: () => unknown;
    eventDeleteAboutMe?: () => unknown;
}

type TisShowLoadingAI = 'UnLoad' | 'Loading' | 'Loaded';

export const TellUsAboutYourself: React.FC<TellUsAboutYourselfProps> = ({
    userAboutMe,
    userVerification,
    userPublished,
    userSuspended,
    entities,
    tagsAi,
    isShowLoadingAI,
    handleSetTag,
    eventDeleteAboutMe,
    handleIsEditAbout,
}) => {
    const [updateUserAboutMe] = useUpdateUserAboutMeMutation();
    const [visibleModal, setVisibleModal] = useState<boolean>(false);
    const [aboutMeInputValue, setAboutMeInputValue] = useState<string>("");
    const [isLoadingTags, setIsLoadingTags] = useState<boolean>(false);

    const tagsMemo = useMemo(() => {
        if (tagsAi && tagsAi?.length) {
            return tagsAi.reduce((total: IProfileTag[], tagAi: IAiTag) => {
                tagAi.tags.forEach(tag => {
                    if (!tag?.name) return;

                    const findTag = total.find(tf => tf.id === tag.id);

                    if (!findTag) {
                        total.push(tag);
                    }
                });

                return total;
            }, []);
        }

        return [];
    }, [tagsAi]);

    useEffect(() => {
        if (userAboutMe) {
            setAboutMeInputValue(userAboutMe);
        }
    }, [userAboutMe]);

    useEffect(() => {
        if (tagsAi) {
            if (tagsAi?.length) {
                setIsLoadingTags(true);
            } else {
                setIsLoadingTags(false);
            }
        }
    }, [tagsAi]);

    const maxLengthAboutMe = 2000;
    const aboutMeLengthError = aboutMeInputValue?.length > maxLengthAboutMe;
    const showAboutMeModal = () => {
        if (
            userSuspended ||
            !userPublished ||
            ['verified', 'unverified'].includes(userVerification)
        ) {
            setVisibleModal(true);
        }
    };

    const getFindTagAiByTag = (findTagAi: IProfileTag) => {
        if (tagsAi && tagsAi?.length) {
            return tagsAi.reduce((total: IAiTag, tagAi: IAiTag) => {
                const findTag = tagAi.tags.find(tag => tag.id === findTagAi.id);

                if (findTag) {
                    total = tagAi;
                }
                return total;
            });
        }

        return;
    };

    const onSubmitModalAboutMe = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (aboutMeLengthError) return;

        updateUserAboutMe({ body: { about_me: aboutMeInputValue } });
        setVisibleModal(false);
        handleIsEditAbout();
    };

    const closeAboutMeModal = () => {
        setVisibleModal(false);

        setAboutMeInputValue(userAboutMe ?? "");
    };

    const deleteUserAboutMe = () => {
        updateUserAboutMe({ body: { about_me: "" } });
        setAboutMeInputValue("");

        // Событие удаления AboutMe
        if (eventDeleteAboutMe) {
            eventDeleteAboutMe();
        }
    };

    const onMoveServices = (tag: IProfileTag) => {
        const findTagAi = getFindTagAiByTag(tag);
        if (handleSetTag) {
            handleSetTag(tag, findTagAi);
        }
    }

    return (
        <>
            <div
                className={cn("specialist-profile__block", {
                    ["specialist-profile__block--flex"]:
                        !aboutMeInputValue?.length,
                })}
            >
                <div className="specialist-profile__col">
                    <h3 className="specialist-profile__subtitle title title--h3">
                        {entities?.specialist_details_about_me?.value || ""}
                        <span className="asterisk">*</span>
                    </h3>
                    <p className="text color-grey">
                        {entities?.profile_specialist_questionnaire_about_work
                            ?.value || ""}
                    </p>
                </div>
                {(aboutMeInputValue?.length && (
                    <div className="profile-block grey-card">
                        <div className="profile-block__row">
                            <div className="profile-block__col">
                                <p className="text profile-block__text">
                                    {aboutMeInputValue ?? ""}
                                </p>
                            </div>
                            <div className="profile-block__controls">
                                <button
                                    onClick={showAboutMeModal}
                                    className="profile-block__btn btn btn--no-bg"
                                    type="button"
                                    aria-label={entities?.order_edit?.value}
                                >
                                    <Icon
                                        type="edit-current-color"
                                        width={20}
                                        height={20}
                                        colorVariant={"jaguar"}
                                    />
                                </button>

                                <ConfirmDeleteModal
                                    entities={entities}
                                    classes={["profile-block__btn", "btn", "btn--no-bg"]}
                                    handleClick={deleteUserAboutMe}
                                    ariaLabel={entities?.profile_destroy_button_ok?.value}
                                >
                                    <Icon
                                        type="close-input-current-color"
                                        width={20}
                                        height={20}
                                        colorVariant={"grayLight"}
                                    />
                                </ConfirmDeleteModal>
                            </div>
                        </div>
                    </div>
                    
                )) || (
                    <button
                        onClick={() => setVisibleModal(true)}
                        className="btn btn--green specialist-profile__btn"
                        type="button"
                    >
                        {entities?.specialist_details_add?.value || ""}
                    </button>
                )}
                <div className="specialist-profile__row">
                    {/* {(isLoadingTags && isShowLoadingAI === 'Loaded') ? (tagsMemo || []).map((tag, index) => (
                        <button
                            className={cn("new-order-start__toggle", `new-order-start__toggle--${tag.type}`)}
                            type="button"
                            onClick={() => onMoveServices(tag)}
                        >
                            {tag.type === 'category' && index ?
                                (<>
                                    <br />
                                    {tag.name}
                                </>) : 
                                (tag.name)
                            }
                        </button>
                    )) : ('')} */}
                    {(isLoadingTags && isShowLoadingAI === 'Loaded') ?
                        (tagsMemo || []).map((tag, index) => (
                            <>
                                {tag.type === 'category' && index ? (<br />) : ''}
                                <button
                                    className={cn("new-order-start__toggle", `new-order-start__toggle--${tag.type}`)}
                                    type="button"
                                    onClick={() => onMoveServices(tag)}
                                >
                                    {tag.name}
                                </button>
                            </>
                        )) : ('')
                    }
                    {(isShowLoadingAI === 'Loading') &&
                        <span
                            className="new-order-start__toggle new-order-start__toggle--preloader"
                        >
                            {entities?.specialist_details_about_me_ai_loading?.value}
                        </span>
                    }
                </div>
            </div>
            <AboutYouModal
                visibleModal={visibleModal}
                closeModal={closeAboutMeModal}
                onSubmit={onSubmitModalAboutMe}
                onClickCancel={closeAboutMeModal}
                maxLengthError={aboutMeLengthError}
                maxLengthInput={maxLengthAboutMe}
                aboutMeInputValue={aboutMeInputValue}
                onChangeInput={(value) => setAboutMeInputValue(value)}
                entities={entities}
            />
        </>
    );
};

import { RootState } from "../index";

export const chatMessagesSelector = (state: RootState) =>
    state.chat.chatMessages;

export const isActiveChatSelector = (state: RootState) =>
    state.chat.isActiveChat;

export const isUpdateChatSelector = (state: RootState) =>
    state.chat.isUpdateChat;

export const isCreateNewChatSelector = (state: RootState) =>
    state.chat.isCreateNewChat;

import React, { useState } from "react";
import { reduce, map } from "lodash";

import Heading from "../../components/Heading";
import Icon from "../../components/Icon";
import Card from "./Card";
import { AboutPagePayload, Block } from "../../models/aboutPageModel";
import PageLoaders from "../../components/Loaders/PageLoaders";
import "../../img/icons/fire.svg";
import { TranslationObject } from "../../models/translationModal";
import { ProfilePayload } from "../../models/userModels";

import pictureAboutMobilePng from "../../img/content/poster/image8-mobile.png";
import pictureAboutMobilePng2x from "../../img/content/poster/image8-mobile-2x.png";
import pictureAboutMobileWebp from "../../img/content/poster/image8-mobile.webp";
import pictureAboutMobileWebp2x from "../../img/content/poster/image8-mobile-2x.webp";
import pictureAboutTabletPng from "../../img/content/poster/image8-tablet.png";
import pictureAboutTabletPng2x from "../../img/content/poster/image8-tablet-2x.png";
import pictureAboutTabletWebp from "../../img/content/poster/image8-tablet.webp";
import pictureAboutTabletWebp2x from "../../img/content/poster/image8-tablet-2x.webp";
import pictureAboutDesktopPng from "../../img/content/poster/image8-desktop.png";
import pictureAboutDesktopPng2x from "../../img/content/poster/image8-desktop-2x.png";
import pictureAboutDesktopWebp from "../../img/content/poster/image8-desktop.webp";
import pictureAboutDesktopWebp2x from "../../img/content/poster/image8-desktop-2x.webp";

import pictureAboutMobileBg from "../../img/content/poster/bg8-mobile.svg";
import pictureAboutTabletBg from "../../img/content/poster/bg8-tablet.svg";
import pictureAboutDesktopBg from "../../img/content/poster/bg8-desktop.svg";

const About: React.FC<{
    currentLanguage: string;
    entities?: TranslationObject;
    isSpecialistOnUrl: boolean;
    profile?: ProfilePayload
}> = ({ currentLanguage, isSpecialistOnUrl, profile, entities }) => {
    const [page, setPage] = useState<AboutPagePayload | null>(null);
    const isSpecialist = profile?.user_role === "specialist" || isSpecialistOnUrl;

    const blocks = reduce(
        page?.blocks,
        (blocks: any, block: Block) => {
            blocks[block?.code] = block;
            return blocks;
        },
        {}
    );

    const verticalCards = reduce(
        page?.blocks?.filter((block) => block?.code?.includes("vertical_card")),
        (items: any, item: Block) => {
            const name = `vertical_card_${item?.code[item?.code?.length - 1]}`;
            items[name] = items[name] || [];
            if (items[name]) {
                items[name][item.code] = item;
            }
            return items;
        },
        {}
    );

    const aboutPicture = {
        picture: {
            srcsets: [
                {
                    media: '(min-width: 1024px)',
                    type: 'image/webp',
                    srcSet: pictureAboutDesktopWebp + ', ' + pictureAboutDesktopWebp2x + ' 2x'
                },
                {
                    media: '(min-width: 1024px)',
                    type: 'image/png',
                    srcSet: pictureAboutDesktopPng + ', ' + pictureAboutDesktopPng2x + ' 2x'
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/webp',
                    srcSet: pictureAboutTabletWebp + ', ' + pictureAboutTabletWebp2x + ' 2x'
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/png',
                    srcSet: pictureAboutTabletPng + ', ' + pictureAboutTabletPng2x + ' 2x'
                },
                {
                    type: 'image/webp',
                    srcSet: pictureAboutMobileWebp + ', ' + pictureAboutMobileWebp2x + ' 2x'
                }
            ],
            src: pictureAboutMobilePng,
            srcSet: pictureAboutMobilePng2x + ' 2x'
        }
    }

    const aboutBg = {
        bg: {
            srcsets: [
                {
                    media: '(min-width: 1024px)',
                    type: 'image/svg+xml',
                    srcSet: pictureAboutDesktopBg
                },
                {
                    media: '(min-width: 768px)',
                    type: 'image/svg+xml',
                    srcSet: pictureAboutTabletBg
                },
            ],
            src: pictureAboutMobileBg
        }
    }

    return (
        <PageLoaders
            type="custom_pages"
            code="about"
            isCustomPage
            isNeedPage
            updateData={setPage}
            currentLanguage={currentLanguage}
        >
            <main>
                <div className="page--about">
                    <div className="page__container container">
                        <Heading
                            isSpecialist={isSpecialist}
                            className="page__promo promo-about"
                            img={blocks?.main_block_image?.value}
                            title={blocks?.main_block_title?.value}
                            text={blocks?.main_block_description?.value}
                            picture={aboutPicture.picture}
                            bg={aboutBg.bg}
                        />
                    </div>
                    <section className="provisions">
                        <div className="container">
                            <h2 className="visually-hidden">
                                {entities?.common_regulations?.value}
                            </h2>
                            <ul className="provisions__list">
                                <li className="provisions__item provisions__item--gray">
                                    <div className="provisions__item-top">
                                        <h3 className="provisions__item-top-title">
                                            {
                                                blocks?.horizontal_card_header_1
                                                    ?.value
                                            }
                                        </h3>
                                        <div className="provisions__item-top-icon">
                                            <Icon type="fire" />
                                        </div>
                                    </div>
                                    <p
                                        className="provisions__item-text"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                blocks?.horizontal_card_text_1
                                                    ?.value || "",
                                        }}
                                    />
                                </li>
                                <li className="provisions__item provisions__item--light-green">
                                    <div className="provisions__item-top">
                                        <h3 className="provisions__item-top-title">
                                            {
                                                blocks?.horizontal_card_header_2
                                                    ?.value
                                            }
                                        </h3>
                                        <div className="provisions__item-top-icon">
                                            <Icon type="checks" />
                                        </div>
                                    </div>
                                    <p
                                        className="provisions__item-text"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                blocks?.horizontal_card_text_2
                                                    ?.value || "",
                                        }}
                                    />
                                </li>
                                <li className="provisions__item provisions__item--quartz">
                                    <div className="provisions__item-top">
                                        <h3 className="provisions__item-top-title">
                                            {
                                                blocks?.horizontal_card_header_3
                                                    ?.value
                                            }
                                        </h3>
                                        <div className="provisions__item-top-icon provisions__item-top-icon--quartz">
                                            <Icon type="target" />
                                        </div>
                                    </div>
                                    <p
                                        className="provisions__item-text"
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                blocks?.horizontal_card_text_3
                                                    ?.value || "",
                                        }}
                                    />
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section className="about-info">
                        <div className="container">
                            <ul className="about-info__list">
                                {map(verticalCards)?.map(
                                    (card: any, key: number) => (
                                        <Card card={card} key={key} />
                                    )
                                )}
                            </ul>
                        </div>
                    </section>
                </div>
            </main>
        </PageLoaders>
    );
};

export default About;

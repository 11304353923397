import React, { useEffect } from "react";
import { FilterBody } from "../../../models/directoryModel";
import { getItemStore, setItemStore } from "../../../untils";
import { endpoints } from "../../../store/directories/directories.api";
import SingleFilter from "../SingleFilter";
import MultipleFilter from "../MultipleFilter";
import { FILTER_PAGES, SERVICE_TYPE_ID } from "../../../store/order/constant";
import Icon from "../../../components/Icon";
import {
    getItemCreateOrder,
    getItemEditOrder,
    updateItemCreateOrder,
} from "../../../store/order/selectors";
import { TranslationObject } from "../../../models/translationModal";
import { useActions } from "../../../hooks/actions";
import classNames from "classnames";

const Filters: React.FC<{
    update: () => void;
    isEdit: boolean;
    entities?: TranslationObject;
    activeMobileFilter: string;
    handleClearActiveMobileFilter: () => void;
}> = ({
    update,
    isEdit,
    entities,
    activeMobileFilter,
    handleClearActiveMobileFilter,
}) => {
    const { updateCreateOrderField } = useActions();
    const typeId = isEdit
        ? getItemEditOrder(SERVICE_TYPE_ID)
        : getItemCreateOrder(SERVICE_TYPE_ID);
    const [getFilters, { data: filters }] = endpoints.getFilters.useLazyQuery();

    useEffect(() => {
        if (typeId) {
            getFilters({
                id: typeId,
            });
        }
    }, [typeId]);

    useEffect(() => {
        const items: FilterBody[] = [];
        const currentFilters = getItemStore(FILTER_PAGES);

        if (currentFilters) {
            currentFilters?.forEach(
                (elem: any) =>
                    filters?.find(
                        (item: any) => item.id === elem.service_type_filter_id
                    ) && items.push(elem)
            );
            if (Array.isArray(filters)) {
                filters?.forEach(
                    (filter: any) =>
                        !items?.find(
                            (elem: any) =>
                                elem.service_type_filter_id == filter.id
                        ) && items.push(sampleFilter(filter?.id))
                );
            }
        } else {
            Array.isArray(filters) &&
                filters?.forEach((filter: any) =>
                    items.push(sampleFilter(filter?.id))
                );
        }
        if (items.length > 0) {
            updateItemCreateOrder(
                {
                    name: FILTER_PAGES,
                    value: items,
                },
                updateCreateOrderField
            );
        }
    }, [filters]);

    const sampleFilter = (id: number) => {
        return {
            service_type_filter_id: id,
            service_type_filter_value_ids: [],
        };
    };

    const renderFilter = (type: string, filter: any) => {
        switch (type) {
            case "single":
                return (
                    <SingleFilter
                        entities={entities}
                        filter={filter}
                        update={update}
                        hiddenInput
                        isEdit={isEdit}
                    />
                );
            case "multiple":
                return (
                    <MultipleFilter
                        entities={entities}
                        filter={filter}
                        update={update}
                        hiddenInput
                        isEdit={isEdit}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            {filters?.map((filter, key) => (
                <div
                    className={classNames("filter__block", {
                        "is-open": activeMobileFilter === String(filter?.id),
                    })}
                    key={key}
                >
                    <button
                        className="filter__subtitle"
                        type="button"
                        onClick={handleClearActiveMobileFilter}
                    >
                        {filter.name}
                        <Icon type="arrow-left-pagination" />
                    </button>
                    <div className="filter__wrap-block">
                        <div className="form__row">
                            {renderFilter(filter?.type, filter)}
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default Filters;

import React from "react";
import { Medium } from "../../models/homePageCustomer";
import Advertising from "../../components/Advertising";
import { BlocksBaseObject } from "../../models/homePageModel";
import CountsBlock from "./CountsBlock";
import { Link } from "react-router-dom";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { TranslationObject } from "../../models/translationModal";

const BodySpecialist: React.FC<{
    blocks: BlocksBaseObject;
    entities?: TranslationObject;
}> = ({ blocks, entities }) => {
    const currentLanguage = getCurrentLanguage();
    return (
        <div className="page__grid">
            <section className="price-deals section">
                <div className="price-deals__wrapper">
                    <div className="price-deals__img">
                        {blocks?.cost_image?.media?.map((img: Medium) => (
                            <img key={img?.id} src={img?.original_url} alt="Price deals"/>
                        ))}
                    </div>
                    <div className="price-deals__info">
                        <h2 className="price-deals__info-title">
                            {blocks?.cost_title?.value}
                        </h2>

                        {blocks?.cost_text?.type === "text" ? (
                            <p
                                className="selection-spec__text"
                                dangerouslySetInnerHTML={{
                                    __html: blocks?.cost_text?.value || "",
                                }}
                            ></p>
                        ) : (
                            <p className="price-deals__info-text">
                                {blocks?.cost_text?.value}
                            </p>
                        )}
                        <h3 className="price-deals__info-text-title">
                            {blocks?.cost_subtitle?.value}
                        </h3>
                        <ul className="price-deals__info-list">
                            {blocks?.cost_subtitle_list?.value?.map(
                                (el: string, key: number) => (
                                    <li
                                        key={key}
                                        dangerouslySetInnerHTML={{
                                            __html: el || "",
                                        }}
                                        className="price-deals__info-item"
                                    />
                                )
                            )}
                        </ul>
                        <a
                            className="price-deals__info-all"
                            href={blocks?.cost_link?.value?.url}
                        >
                            {blocks?.cost_link?.value?.title}
                        </a>
                    </div>
                </div>
            </section>
            <section className="account-options account-options-specialists section">
                <div className="account-options__wrapper">
                    <h2 className="title title--h2">
                        {blocks?.features_title?.value}
                    </h2>
                    <ol className="account-options__list green">
                        {blocks?.features_list?.value?.map(
                            (el: string, key: number) => (
                                <li key={key} className="account-options__item">
                                    {el}
                                </li>
                            )
                        )}
                    </ol>
                    <Link
                        className="btn account-options__button btn--green"
                        to={`/${currentLanguage}/specialist/registration`}
                    >
                        {blocks?.features_register_button_text?.value}
                    </Link>
                </div>
                <div className="account-options__image">
                    {blocks?.features_image?.media.map((img: Medium) => (
                        <img
                            key={img?.id + 1}
                            src={img?.original_url}
                            alt={blocks?.features_image?.title}
                        />
                    ))}
                </div>
                <div className="account-options__image-mobile">
                    {blocks?.features_image?.media.map((img: Medium) => (
                        <img
                            key={img?.id + 2}
                            src={img?.original_url}
                            alt={blocks?.features_image?.title}
                        />
                    ))}
                </div>
            </section>
            <section className="section order-stats order-stats-specialists">
                <CountsBlock
                    satisfied_customers_count={
                        blocks?.satisfied_customers_count?.value
                    }
                    satisfied_customers_title={
                        blocks?.satisfied_customers_title?.value
                    }
                    specialists_count={blocks?.specialists_count?.value}
                    specialists_title={blocks?.specialists_title?.value}
                    deals_count={blocks?.deals_count?.value}
                    deals_title={blocks?.deals_title?.value}
                />
            </section>
            <section className="service-partner">
                <div className="service-partner__wrapper">
                    <h2 className="visually-hidden">Наши партнёры</h2>
                    <ul className="service-partner__list no-scrollbar">
                        <Advertising
                            typeAdvertising={"thirdParties"}
                            entities={entities}
                        />
                    </ul>
                </div>
            </section>
        </div>
    );
};

export default BodySpecialist;

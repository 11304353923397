import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IChatSupportMessage, ISupportMessage } from "../../models/chatModels";

interface IChatSliceInitialState {
    chatMessages: IChatSupportMessage[];
    isActiveChat: boolean;
    chatId: number;
    msgNotAuth: ISupportMessage[];
    maxCountMessage: number;
}

const chatSliceInitialState: IChatSliceInitialState = {
    chatMessages: [],
    isActiveChat: false,
    chatId: 0,
    msgNotAuth: [],
    maxCountMessage: 3,
};

export const supportSlice = createSlice({
    name: "support",
    initialState: chatSliceInitialState,
    reducers: {
        resetSupportState: () => chatSliceInitialState,
        setSupportMessages: (state, action: PayloadAction<IChatSupportMessage[]>) => {
            state.chatMessages = action.payload;
        },
        setSupportId: (state, action: PayloadAction<number>) => {
            state.chatId = action.payload
        },
        addSupportMessages: (state, action: PayloadAction<IChatSupportMessage[]>) => {
            state.chatMessages = [...action.payload, ...state.chatMessages];
        },
        addSupportMessage: (state, action: PayloadAction<IChatSupportMessage>) => {
            if (
                state.chatMessages.find((el) => el.date === action.payload.date)
            ) {
                state.chatMessages.forEach((messagesElement) => {
                    if (messagesElement.date === action.payload.date) {
                        messagesElement.messages.push(
                            ...action.payload.messages
                        );
                    }
                });
            } else {
                state.chatMessages = [...state.chatMessages, action.payload];
            }
        },
        setSupportIsActiveChat: (state, action: PayloadAction<boolean>) => {
            state.isActiveChat = action.payload;
        },
        addSupportMessagesNotAuth: (state, action: PayloadAction<ISupportMessage[]>) => {
            state.msgNotAuth = action.payload;
        },
        addSupportMessageNotAuth: (state, action: PayloadAction<ISupportMessage>) => {
            state.msgNotAuth.push(action.payload);
        },
    },
});

export const supportReducer = supportSlice.reducer;
export const supportActions = supportSlice.actions;

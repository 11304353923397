import React from "react";
import FilterToCreateOrder from "./FilterToCreateOrder";
import FilterToEditOrder from "./FilterToEditOrder";
import { TranslationObject } from "../../../../models/translationModal";

interface Props {
    entities?: TranslationObject;
    isEdit: boolean;
}

const MeetingPointFilter: React.FC<Props> = ({ entities, isEdit = false }) => {
    if (isEdit) {
        return <FilterToEditOrder entities={entities} />;
    }

    return <FilterToCreateOrder entities={entities} />;
};

export default MeetingPointFilter;

import React from "react";
import { formatQueryString } from "../../../../untils";
import { getTkn } from "../../../../store/user/selectors";
import { useSelector } from "react-redux";
import { selectEmployeeSelector } from "../../../../store/finances/finances.selectors";
import { DateFilterType } from "../../../../store/finances/finances.types";
import { TranslationObject } from "../../../../models/translationModal";
import moment from "moment";
import { INotification } from "../../../../hooks/useNotification/useNotification";

type errors = { dateTo: boolean, dateFrom: boolean }


interface MenuNavProps {
    dateFrom: DateFilterType;
    dateTo: DateFilterType;
    setLoading: (value: boolean) => unknown;
    setNotification: (notification: INotification) => unknown;
    entities?: TranslationObject;
    checkValidationsErrors: ({ dateTo, dateFrom }: errors) => void
}


export const MenuNav: React.FC<MenuNavProps> = ({
    dateFrom,
    dateTo,
    setLoading,
    setNotification,
    entities,
    checkValidationsErrors
}) => {
    const token = getTkn();

    const { value: specialistId } = useSelector(selectEmployeeSelector) || {};

    return (
        <>
            <nav className="user-card__nav user-nav">
                <button className="btn-reset menu-toggler" type="button">
                    <span className="menu-toggler__item" />
                    <span className="menu-toggler__item" />
                    <span className="menu-toggler__item" />
                </button>
                <ul className="user-nav__list list-reset">
                    <li className="user-nav__item">
                        <button
                            className="btn-reset user-nav__btn"
                            type="button"
                            onClick={() =>
                                handleClickButtonDownloadDocument(
                                    "statement",
                                    token,
                                    setNotification,
                                    setLoading,
                                    specialistId,
                                    dateFrom,
                                    dateTo,
                                    checkValidationsErrors
                                )
                            }
                        >
                            {entities?.finances_download_statement?.value}
                        </button>
                    </li>
                    <li className="user-nav__item">
                        <button
                            onClick={() =>
                                handleClickButtonDownloadDocument(
                                    "report",
                                    token,
                                    setNotification,
                                    setLoading,
                                    specialistId,
                                    dateFrom,
                                    dateTo,
                                    checkValidationsErrors
                                )
                            }
                            className="btn-reset user-nav__btn"
                            type="button"
                        >
                            {entities?.finances_download_report?.value}
                        </button>
                    </li>
                </ul>
            </nav>
        </>
    );
};

function handleClickButtonDownloadDocument(
    type: "statement" | "report",
    token: string | null,
    setNotification: (notification: INotification) => unknown,
    setLoading: (value: boolean) => unknown,
    specialistId?: string | number,
    dateFrom?: DateFilterType,
    dateTo?: DateFilterType,
    checkValidationsErrors: ({ dateTo, dateFrom }: errors) => void
) {
    setLoading(true);

    if (!(dateTo && dateFrom)) {
        const params = { 'dateTo': !!dateTo, 'dateFrom': !!dateFrom }

        return checkValidationsErrors(params)
    }

    const params = formatQueryString({
        from: dateFrom ? moment(dateFrom).format("DD.MM.YYYY") : null,
        to: dateTo ? moment(dateTo).format("DD.MM.YYYY") : null,
        specialist_id: specialistId || null,
    });

    fetch(`/api/v1/payments/${type}?${params}`, {
        method: "GET",
        headers: new Headers({
            Authorization: "Bearer " + token,
        }),
    })
        .then((response) => {
            if (response?.ok) {
                return response.blob();
            }
            throw new Error("Error download file");
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${type}.xlsx`;
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            a.remove();
        })
        .catch((err) =>
            setNotification({ type: "warn", description: err?.message })
        )
        .finally(() => setLoading(false));
}

import React, { ReactNode } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import el from "date-fns/locale/el";
import en from "date-fns/locale/en-CA";
import moment from "moment/moment";
import { getCurrentLanguage } from "../../store/directories/selectors";

registerLocale("ru", ru);
registerLocale("el", el);
registerLocale("en", en);

const DateInput: React.FC<{
    input: ReactNode;
    maxDate?: Date | null;
    minDate?: Date | null;
    onChange: (date: Date | string) => void;
    value?: Date | null;
    dateFormat: string;
    allowSelectionOldDates?: boolean;
}> = ({
    input,
    onChange,
    value,
    dateFormat,
    allowSelectionOldDates = true,
    maxDate,
    minDate,
}) => {
    const currentLanguage = getCurrentLanguage();
    let minDateValue = allowSelectionOldDates ? null : moment().toDate();

    if (minDate) {
        minDateValue = minDate;
    }

    return (
        <DatePicker
            selected={value}
            onChange={(date: Date) => onChange(date)}
            customInput={input}
            locale={currentLanguage}
            dateFormat={dateFormat}
            maxDate={maxDate ? maxDate : null}
            minDate={minDateValue}
            onChangeRaw={(event) => typeof event === "string" && onChange("")}
        />
    );
};

export default DateInput;

import React, { useEffect, useState } from "react";
import { endpoints } from "../../store/directories/directories.api";
import { endpoints as userEndpoints } from "../../store/user/user.api";
import Form from "../../components/Form/Form";
import Icon from "../../components/Icon";
import Breadcrumbs from "../../components/Breadcrumbs";
import classNames from "classnames";
import { toArray } from "../../lib/converters";
import { OrderStepsProps } from "./index";
import { useNavigate, useParams } from "react-router-dom";
import {
    CUSTOM_SERVICE_SUB_TYPE,
    CUSTOM_SERVICE_TYPE,
    FILTER_PAGES,
    SERVICE_CATEGORY_ID,
    SERVICE_CATEGORY_NAME,
    SERVICE_CATEGORY_SLUG,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_TYPE_ID,
    SERVICE_TYPE_NAME,
    SERVICE_TYPE_SLUG,
    SPECIALIST_IDS,
} from "../../store/order/constant";
import { useActions } from "../../hooks/actions";
import {
    getItemCreateOrder,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import {
    clearCreateOrderState,
    createOrderState,
} from "../../store/order/createOrder.slice";
import { TypesPayload } from "../../models/directoryModel";
import Advertising from "../../components/Advertising";
import CancelOrderButton from "./Specialists/CancelOrderButton";
import { scrollTop } from "../../untils";
import Input from "../../components/Form/Input";

interface Props extends OrderStepsProps {
    setHasFilters: (value: boolean) => void;
    currentLanguage: string;
    isIndividualOrder: boolean;
}

const SelectServiceTypes: React.FC<Props> = ({
    currentLanguage,
    entities,
    onSubmit,
    error,
    profile,
    onSuccess,
    isSuccess,
    setHasFilters,
    isIndividualOrder,
}) => {
    const { category, type: pathType } = useParams();
    const [nextStep, setNextStep] = useState<string>(`${category}/${pathType || 'type'}/subtype`);
    const navigate = useNavigate();
    const { updateCreateOrderField } = useActions();
    const activeCategory = getItemCreateOrder(SERVICE_CATEGORY_ID);
    const type = getItemCreateOrder(SERVICE_TYPE_ID);
    const custom = getItemCreateOrder(CUSTOM_SERVICE_TYPE);
    const activeType = type || custom;
    const [notSelectedTypes, setNotSelectedTypes] = useState<TypesPayload[]>([]);
    const { data: serviceCategories } = endpoints.getServiceCategories.useQuery();
    const [getServiceTypes, { data: serviceTypes }] =
        endpoints.getServiceTypes.useLazyQuery();
    const specialistIds = getItemCreateOrder(SPECIALIST_IDS);
    const [getSpecialist, { data: specialist }] =
        userEndpoints.getSpecialistDetail.useLazyQuery();
    const [search, setSearch] = useState<string>(custom || "");

    useEffect(() => {
        if (specialistIds?.length && isIndividualOrder) {
            getSpecialist({ id: specialistIds[0] });
        }
    }, [specialistIds]);

    useEffect(() => {
        if (!serviceCategories?.length) return;

        const slugCat = category?.replace(/^category-/gi, '') || ''; 
        const selectedCat = serviceCategories?.find(item => item.slug === slugCat || item.id === activeCategory);

        if (selectedCat?.id) {
            updateOrderField<typeof SERVICE_CATEGORY_ID>(SERVICE_CATEGORY_ID, selectedCat.id);
            updateOrderField<typeof SERVICE_CATEGORY_SLUG>(SERVICE_CATEGORY_SLUG, selectedCat.slug ? `category-${selectedCat.slug}` : 'category');
        }

        updateOrderField<typeof SERVICE_CATEGORY_NAME>(SERVICE_CATEGORY_NAME, selectedCat?.name || null);
    }, [serviceCategories])

    useEffect(() => {
        if (!serviceTypes?.length) return;

        const slugType = pathType?.replace(/^type-/gi, '') || ''; 
        const selectedType= serviceTypes?.find(item => item.slug === slugType || item.id === activeType);

        if (selectedType?.id) {
            updateOrderField<typeof SERVICE_TYPE_ID>(SERVICE_TYPE_ID, selectedType.id);
            updateOrderField<typeof SERVICE_TYPE_SLUG>(SERVICE_TYPE_SLUG, selectedType.slug ? `type-${selectedType.slug}` : 'type');

            history.pushState(null, "", `type-${selectedType.slug}`);
            setNextStep(`${category}/type-${selectedType.slug}/subtype`);
            return;
        }

        updateOrderField<typeof SERVICE_TYPE_NAME>(SERVICE_TYPE_NAME, selectedType?.name || null);
        setNextStep(`${category}/type/subtype`);
    }, [serviceTypes])

    useEffect(() => {
        if (activeCategory) {
            getServiceTypes({
                id: activeCategory,
            });
        }
    }, [activeCategory]);

    useEffect(() => {
        if (serviceTypes) {
            let items = serviceTypes?.filter(
                (serviceType: TypesPayload) => activeType !== serviceType?.id
            );
            if (isIndividualOrder && specialist) {
                const services = specialist?.services?.filter(
                    (elem) => elem.service_category.id === activeCategory
                );
                items = items?.filter((elem) =>
                    services?.find((item) => item?.service_type?.id === elem.id)
                );
            }
            setNotSelectedTypes(items || []);
        }
    }, [serviceTypes, specialist, type]);

    const currentType =
        serviceTypes?.find((elem: TypesPayload) => elem.id == activeType)?.name || null

    const updateOrderField = <K extends keyof createOrderState>(name: K, value: createOrderState[K]) => {
        updateField({
            name,
            value,
        });
    }

    const handleClick = (id: number, nameType: string, slug: string) => {
        [
            SERVICE_TYPE_ID,
            CUSTOM_SERVICE_TYPE,
            CUSTOM_SERVICE_SUB_TYPE,
            SERVICE_SUB_TYPE_IDS,
            FILTER_PAGES,
        ].forEach((key) =>
            updateField({
                name: key as keyof createOrderState,
                value: clearCreateOrderState[key as keyof createOrderState],
            })
        );
        if (activeType === id) {
            setHasFilters(false);
        }
        updateOrderField<typeof SERVICE_TYPE_ID>(SERVICE_TYPE_ID, activeType === id ? null : id);
        updateOrderField<typeof SERVICE_TYPE_NAME>(SERVICE_TYPE_NAME, nameType || null);
        updateOrderField<typeof SERVICE_TYPE_SLUG>(SERVICE_TYPE_SLUG, slug ? `type-${slug}` : 'type');

        setSearch('');
        const items =
            activeType === id
                ? serviceTypes || []
                : serviceTypes?.filter(
                    (elem: TypesPayload) => elem?.id !== id
                ) || [];
        setNotSelectedTypes(items);

        history.pushState(null, "", slug ? `type-${slug}` : 'type');
        setNextStep(`${category}/${slug ? `type-${slug}` : 'type'}/subtype`);
    };

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) => {
        updateItemCreateOrder(
            {
                name,
                value,
            },
            updateCreateOrderField
        );
    };

    useEffect(() => {
        if (isSuccess) {
            _onSuccess();
        }
    }, [isSuccess]);

    const _onSuccess = () => {
        if (isSuccess) {
            onSuccess(nextStep);
        }
    };

    const back = () => {
        navigate(`/${currentLanguage}/customer/create-order/${category}`);
        scrollTop();
    };

    const handleOnChange = (value: string) => {
        setSearch(value);

        const items = serviceTypes?.filter(
            (serviceType: TypesPayload) => activeType !== serviceType?.id
        ) || [];

        setNotSelectedTypes(
            items?.filter((item) =>
                item.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
            ) || items
        );

        if (value !== "") {
            updateOrderField<typeof SERVICE_TYPE_ID>(SERVICE_TYPE_ID, null);
            updateOrderField<typeof SERVICE_TYPE_NAME>(SERVICE_TYPE_NAME, null);
            updateOrderField<typeof SERVICE_TYPE_SLUG>(SERVICE_TYPE_SLUG, 'type');
            updateOrderField<typeof CUSTOM_SERVICE_TYPE>(CUSTOM_SERVICE_TYPE, value);

            history.pushState(null, "", `type`);
            setNextStep(`${category}/type/subtype`);
        }
    };

    const crumbs = [
        {
            name: getItemCreateOrder(SERVICE_CATEGORY_NAME) || entities?.service_task?.value,
            link: `create-order/${category}`
        },
        {
            active: true,
            name: entities?.type_of_service_2?.value
        }
    ]

    return (
        <>
            <h1 className="visually-hidden">
                {entities?.order_select_one_category?.value}
            </h1>
            <div className="inner-container">
                <div className="new-order__wrapper">
                    <Breadcrumbs crumbs={crumbs} />
                    <div className="new-order__header">
                        <h2 className="title title--f46">
                            {entities?.order_select_one_category?.value}
                        </h2>
                        <CancelOrderButton le={profile} entities={entities} />
                        <p className="subtitle">
                            {entities?.order_little_more_details?.value}
                        </p>
                    </div>
                    <div className="new-order__form form">
                        <Form
                            isSuccess={isSuccess}
                            onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
                                onSubmit(e, nextStep);
                                scrollTop();
                            }}
                            entities={entities}
                        >
                            {(invalidFields, error) => {
                                const validationError =
                                    invalidFields &&
                                    error &&
                                    error?.errors[SERVICE_CATEGORY_ID] &&
                                    toArray( error?.errors[SERVICE_CATEGORY_ID]).join(`, `);
                                return (
                                    <>
                                        <div className="new-order-start">
                                            <div className="form__row form__row--big-margin">
                                                <div
                                                    className={classNames(
                                                        "new-order-start__field new-order-start__field--selected",
                                                        { "is-invalid": error && !activeType }
                                                    )}
                                                >
                                                    {(!!currentType) && (
                                                        <span className="new-order-start__toggle is-active">
                                                            {currentType}
                                                            <button
                                                                className="btn"
                                                                type="button"
                                                                aria-label="удалить"
                                                                onClick={() => handleClick(activeType)}
                                                            >
                                                                <Icon
                                                                    type="delete-input"
                                                                    width={12}
                                                                    height={12}
                                                                />
                                                            </button>
                                                        </span>
                                                    )}
                                                    <Input
                                                        value={search}
                                                        onChange={handleOnChange}
                                                        classes={["new-order-start__search-field"]}
                                                        type="search"
                                                        placeholder=""
                                                    />
                                                </div>
                                                {validationError &&
                                                    !activeType && (
                                                        <div className="new-order-start__field-error">
                                                            {validationError}
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="form__row">
                                                {notSelectedTypes?.map(
                                                    (
                                                        elem: TypesPayload,
                                                        key: number
                                                    ) => (
                                                        <button
                                                            key={elem?.id}
                                                            className="new-order-start__toggle"
                                                            type="button"
                                                            onClick={() => handleClick(elem?.id, elem?.name, elem?.slug)}
                                                        >{elem?.name}</button>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        <div className="form__row form__controls">
                                            <CancelOrderButton
                                                profile={profile}
                                                entities={entities}
                                            />
                                            <button
                                                className="btn form__back-link btn--transparent"
                                                type="button"
                                                onClick={back}
                                            >{entities?.common_back_button?.value}</button>
                                            <button
                                                className="btn btn--transparent btn--bg-blue"
                                                type="submit"
                                            >{entities?.login_continue_button?.value}</button>
                                        </div>
                                    </>
                                );
                            }}
                        </Form>
                    </div>
                </div>
                <ul className="advertising-list create-order">
                    <Advertising countCards={5} entities={entities} isHorizontal={true} />
                </ul>
            </div>
        </>
    );
};

export default SelectServiceTypes;

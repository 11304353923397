import React from "react";
import SelectLanguages from "../SelectLanguages";
import { Link, useLocation } from "react-router-dom";
import { endpoints } from "../../store/directories/directories.api";
import { TextPage } from "../../models/directoryModel";
import { getCurrentLanguage, getPath } from "../../store/directories/selectors";
import TextLink from "./TextLink";

import elFlag from "../../img/content/header/select-lang/el.png";
import ruFlag from "../../img/content/header/select-lang/RUS.png";
import enFlag from "../../img/content/header/select-lang/EN.png";
import logo from "../../img/icons/logo.svg";
import appStore from "../../img/icons/app_store.svg";
import googlePlay from "../../img/icons/google_play.svg";
import huaweiLogo from "../../img/icons/huawei-logo.svg";
import instagramIcon from '../../img/icons/instagram.svg';
import fbIcon from "../../img/icons/fb.svg";
import linkedinIcon from "../../img/icons/linkedin.svg";
import { ProfilePayload } from "../../models/userModels";
import { TranslationObject } from "../../models/translationModal";
import { useState } from "react";
import { InDevelopmentNotification } from "../Modals/InDevelopmentNotification/InDevelopmentNotification";
import { getTkn } from "../../store/user/selectors";
import Modal from "../Modal";
import cn from "classnames";

const Index: React.FC<{
    isSpecialistOnUrl?: boolean;
    isCustomerOnUrl?: boolean;
    profile?: ProfilePayload;
    entities?: TranslationObject;
}> = ({ isSpecialistOnUrl, isCustomerOnUrl, profile, entities }) => {
    const currentLanguage = getCurrentLanguage();
    const path = getPath();
    const { pathname } = useLocation();
    const isPreview = pathname.includes("preview");
    const { data } = endpoints.getDirectories.useQuery();
    const textPages = data?.text_pages;
    const settings = data?.settings;
    const today = new Date();
    const tkn = getTkn();
    const isCustomer = profile?.user_role === "customer" && tkn;
    const isSpecialist = !isCustomer && isSpecialistOnUrl;

    const getUrl = (code: string) => {
        return data?.custom_pages?.find((page) => page?.code === code)?.path;
    };

    const [showInDevelopmentNotification, setShowInDevelopmentNotification] = useState<boolean>(false);

    const [showModal, setShowModal] = useState(false);
    const [urlsToRedirect, setUrlsToRedirect] = useState<{ specialist: string, customer: string }>({ "specialist": "", "customer": "" })
    const appStoreData = {
        "Google Play": {
            "specialist": "https://play.google.com/store/apps/details?id=com.cy.findpro.specialist",
            "customer": "https://play.google.com/store/apps/details?id=com.cy.findpro.customer",
        },
        "App Store": {
            "specialist": "https://apps.apple.com/us/app/findpro-specialist/id6447989628",
            "customer": "https://apps.apple.com/us/app/findpro-customer/id6447989425",
        },
        "AppGallery": {
            "specialist": "https://appgallery.huawei.com/app/C108510371",
            "customer": "https://appgallery.huawei.com/app/C108510375",
        },
    }

    const routesAppStore = (app_store: "Google Play" | "App Store" | "AppGallery") => {
        setUrlsToRedirect(appStoreData[app_store]);
        setShowModal(true);
    };

    const redirectToAppStore = (role: "specialist" | "customer") => {
        window.open(urlsToRedirect[role], '_blank', 'fullscreen=yes');
        setShowModal(false)
    };
    const renderIcon = () => {
        switch (currentLanguage) {
            case "el":
                return (
                    <img
                        className="footer__select-img"
                        src={elFlag}
                        alt="el flag"
                    />
                );
            case "en":
                return (
                    <img
                        className="footer__select-img"
                        src={enFlag}
                        alt="en flag"
                    />
                );
            case "ru":
                return (
                    <img
                        className="footer__select-img"
                        src={ruFlag}
                        alt="ru flag"
                    />
                );
            default:
                null;
        }
    };

    const links = [
        {
            to: `/${currentLanguage}/services`,
            visible: true,
            text: entities?.service_catalog_link_value?.value,
        },
        {
            to: `/${currentLanguage}/customer/${getUrl("advertisers")}`,
            // visible:
            //     (profile?.id && profile?.user_role === "customer") ||
            //     isCustomerOnUrl,
            visible: true,
            text: entities?.advertisers_link_value?.value,
        },
        {
            to:
                profile?.id || isCustomerOnUrl || isSpecialistOnUrl
                    ? `/${currentLanguage}/${profile?.id ? profile?.user_role : path[1]
                    }/${getUrl("bonuses_and_partners")}`
                    : `/${currentLanguage}/${getUrl("bonuses_and_partners")}`,
            visible: true,
            text: entities?.bonuses_partners_link_value?.value,
        },
        {
            to: `/${currentLanguage}/${getUrl(
                isCustomerOnUrl
                    ? "customer_service_page"
                    : "specialist_service_page"
            )}`,
            // visible: customerOrSpecialist,
            visible: true,
            text: entities?.about_service_link_value?.value,
        },
        {
            to: profile?.id ? `/${currentLanguage}/${profile?.id ? profile?.user_role : path[1]
                }/${getUrl("write_us")}` : `/${currentLanguage}/${getUrl("write_us")}`,
            visible: true,
            text: entities?.contact_us_link_value?.value,
        },
        {
            to: `/${currentLanguage}/${getUrl("specialist_prices")}`,
            visible:
                (profile?.id && profile?.user_role === "specialist") ||
                isSpecialistOnUrl,
            text: entities?.specialist_prices_link_value?.value || "",
        },
    ];

    return (
        <>
            <Modal
                visible={showModal}
                onClose={() => setShowModal(false)}
                title={entities?.login_modal_title_redirect_to_app?.value}
                description={entities?.login_modal_description_redirect_to_app?.value}
            >
                <div className="modal__content-buttons">
                    <button
                        className="btn modal__content-button"
                        type="button"
                        style={{ marginRight: "16px" }}
                        onClick={() => redirectToAppStore("customer")}
                    >
                        {entities?.customer_link_value?.value}
                    </button>
                    <button
                        className="btn modal__content-button btn--green"
                        type="button"
                        onClick={() => redirectToAppStore("specialist")}
                    >
                        {entities?.specialist_link_value?.value}
                    </button>
                </div>
            </Modal>

            <InDevelopmentNotification
                entities={entities}
                visible={showInDevelopmentNotification}
                setVisible={(visible) =>
                    setShowInDevelopmentNotification(visible)
                }
                isSpecialist={isSpecialist}
            />
            <footer className="footer" id="footer">
                <div className="container">
                    <div className={cn("footer__wrapper", {"footer__wrapper--preview": isPreview})}>
                        <div className="footer__wrapper-logo-marketplace">
                            <span>
                                <Link
                                    className="footer__logo"
                                    to={`/${currentLanguage}/`}
                                >
                                    <img src={logo} alt="FindPRO" />
                                </Link>
                            </span>
                            <div className="footer__marketplace">
                                <span
                                    className="footer__marketplace__link"
                                    onClick={() => routesAppStore("App Store")}
                                >
                                    <img
                                        src={appStore}
                                        className="footer__marketplace__icon"
                                        aria-hidden="true"
                                        alt="App Store"
                                    />
                                    <p className="footer__marketplace__text">
                                        {entities?.common_upload_to?.value}{" "}
                                        <br /> <span>App Store</span>
                                    </p>
                                </span>
                                <span
                                    className="footer__marketplace__link"
                                    onClick={() => routesAppStore("Google Play")}
                                >
                                    <img
                                        src={googlePlay}
                                        className="footer__marketplace__icon"
                                        aria-hidden="true"
                                        alt="Google Play"
                                    />
                                    <p className="footer__marketplace__text">
                                        {entities?.common_available_in?.value}{" "}
                                        <br /> <span>Google Play</span>
                                    </p>
                                </span>
                                <span
                                    className="footer__marketplace__link"
                                    onClick={() => routesAppStore("AppGallery")}
                                >
                                    <img
                                        src={huaweiLogo}
                                        className="footer__marketplace__icon"
                                        aria-hidden="true"
                                        alt="Huawei"
                                    />
                                    <p className="footer__marketplace__text">
                                        {entities?.common_available_in?.value}{" "}
                                        <br /> <span>AppGallery</span>
                                    </p>
                                </span>
                            </div>
                        </div>
                        <div className="footer__wrapper-nav-legal">
                            <nav className="footer__nav">
                                <ul className="footer__nav__list">
                                    {links.map(
                                        (link, key) =>
                                            link?.visible && (
                                                <li
                                                    className="footer__nav-item"
                                                    key={key}
                                                >
                                                    <Link
                                                        to={link?.to}
                                                        className="footer__nav-link"
                                                    >
                                                        {link?.text}
                                                    </Link>
                                                </li>
                                            )
                                    )}
                                </ul>
                            </nav>
                            <div className="footer__legal-ground">
                                {textPages?.map((page: TextPage) => (
                                    <TextLink id={page?.id} key={page?.id} />
                                ))}
                            </div>
                        </div>
                        <div className="footer__wrapper-social-select">
                            <div className="footer__select">
                                {renderIcon()}
                                <SelectLanguages
                                    className={"custom-select select-lang"}
                                />
                            </div>
                            <ul className="footer__social">
                                <li className="footer__social-item">
                                    <a
                                        className="footer__social-link"
                                        href={settings?.twitter_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src={instagramIcon} alt="Instagram"/>
                                    </a>
                                </li>
                                <li className="footer__social-item">
                                    <a
                                        className="footer__social-link"
                                        href={settings?.facebook_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src={fbIcon} alt="Facebook"/>
                                    </a>
                                </li>
                                <li className="footer__social-item">
                                    <a
                                        className="footer__social-link"
                                        href={settings?.linkedin_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src={linkedinIcon} alt="LinekdIn"/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <div className="footer-copyright__owner">
                            <p className="footer-copyright__text">
                                © FindPro, {today.getFullYear()}
                            </p>
                        </div>
                        <div className="footer-copyright__developer">
                            <p className="footer-copyright__developer-text">
                                Developed by ITgro
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Index;

import React, { useEffect } from "react";
import { endpoints } from "../../../store/directories/directories.api";
import { getItemStore } from "../../../untils";
import { TypesPayload } from "../../../models/directoryModel";
import { useNavigate } from "react-router-dom";
import {
    SERVICE_CATEGORY_ID,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_TYPE_ID,
} from "../../../store/order/constant";
import {
    getItemCreateOrder,
    getItemEditOrder,
} from "../../../store/order/selectors";

const TypeItem: React.FC<{
    currentLanguage: string;
    isEdit: boolean;
}> = ({ currentLanguage, isEdit }) => {
    const navigate = useNavigate();
    const id = isEdit
        ? getItemEditOrder(SERVICE_CATEGORY_ID)
        : getItemCreateOrder(SERVICE_CATEGORY_ID);
    const typeId = isEdit
        ? getItemEditOrder(SERVICE_TYPE_ID)
        : getItemCreateOrder(SERVICE_TYPE_ID);

    const [getServiceTypes, { data }] =
        endpoints.getServiceTypes.useLazyQuery();

    useEffect(() => {
        if (id) {
            getServiceTypes({
                id,
            });
        }
    }, [id]);

    const type =
        data?.find((elem: TypesPayload) => elem.id == typeId)?.name || "";

    const link = `/${currentLanguage}/customer/${
        isEdit ? "edit-order" : "create-order/type"
    }`;

    return (
        <div className="form__row">
            <div className="link" onClick={() => navigate(link)}>
                {type}
            </div>
        </div>
    );
};

export default TypeItem;

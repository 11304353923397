import React, { useState, useEffect, FormEvent, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { useLogout } from "../../hooks/useLogout/useLogout";
import cn from 'classnames';
import Modal from '../Modal';
import TextArea from '../Form/TextArea';
import { TranslationObject } from '../../models/translationModal';
import { useSendQuestionAiMutation, useSendQuestionSearchHelperMutation } from '../../store/order/order.api';
import {
    QuestionAiTypeObject,
} from "../../models/orderModel";

import { createOrderState } from "../../store/order/createOrder.slice";
import { ProfilePayload } from "../../models/userModels";

// Selectors
import { getCurrentLanguage } from "../../store/directories/selectors";
import { clearOrderDataInLocalStorage, getMaxCountSearchAi, updateItemCreateOrder, } from "../../store/order/selectors";
import { IProfileTag } from "../../models/orderModel";

import { debounce } from "lodash";

import {
    IS_PRIVATE,
    SERVICE_CATEGORY_ID,
    SERVICE_CATEGORY_NAME,
    SERVICE_TYPE_ID,
    SERVICE_TYPE_NAME,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_SUB_TYPE_NAME,
    SERVICE_CATEGORY_SLUG,
    SERVICE_TYPE_SLUG,
    SERVICE_SUB_TYPE_SLUG,
} from "../../store/order/constant";

import { useActions } from "../../hooks/actions";
import { setItemStore, getItemStore } from '../../untils';
import { getIsAuth } from '../../store/user/selectors';

type TQuestionAiLoadState = '' | 'Loading' | 'Loaded' | 'Back';
type TRenderTag = {
    id: string | number;
    name: string;
    type: string;
    slug: string;
    categoryId?: string | number | null;
    categoryName?: string | null;
    categorySlug?: string;
    typeId?: string | number | null;
    typeName?: string | null;
    typeSlug?: string;
}


const Search: React.FC<{
    colored?: boolean,
    entities?: TranslationObject,
    profile?: ProfilePayload,
    placeholder?: string
}> = ({ colored, entities, profile, placeholder }) => {
    const logout = useLogout();
    const [sendQuestionAi, { data }] = useSendQuestionAiMutation();
    const [sendQuestionSearchHelper, { data: searchHelperData, isUninitialized, isSuccess }] = useSendQuestionSearchHelperMutation();
    const [showModal, setShowModal] = useState(false);
    const [showModalOrder, setShowModalOrder] = useState(false);
    const [dataQuestionAi, setDataQuestionAi] = useState<QuestionAiTypeObject[] | QuestionAiTypeObject>([]);
    const [questionAiLoading, setQuestionAiLoading] =  useState<TQuestionAiLoadState>(''); // Loading, '', Loaded
    const [showSelectModal, setShowSelectModal] = useState(false);
    const [typeTag, setTypeTag] = useState<string>('');
    const [selectedTag, setSelectedTag] = useState<TRenderTag | null>(null);
    const [privateOrder, setPrivateOrder] = useState<string | null>(null);
    const [question, setQuestion] = useState('');
    const [isShowSearchButton, setIsShowSearchButton] = useState(false);
    const [isShowAi, setIsShowAi] = useState<boolean>(false);
    const [isSearchedBack, setIsSearchedBack] = useState<boolean>(false);
    const [aiSearchCount, setAiSearchCount] = useState(+getItemStore('csai') || 0);
    const [renderedTags, setRenderedTags] = useState<TRenderTag[]>([]);
    const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
    const maxCountSearchAi = getMaxCountSearchAi() || 3;
    const currentLanguage = getCurrentLanguage();
    const isSpecialist = profile?.user_role === "specialist";
    const isSearchLimitExceeded = !!(getItemStore('csai') >= maxCountSearchAi);
    const isNotAuthUser = !getIsAuth();
    const navigate = useNavigate();

    // Filed store on create order
    const { updateCreateOrderField, clearCreateOrderFields } = useActions();

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) => {
        updateItemCreateOrder(
            { name, value },
            updateCreateOrderField
        );
    };

    // Считываем поле и записываем вопрос
    const onChange = (val: string) => {
        setQuestion(val.trim());
    };

    // // Считываем поле и записываем вопрос
    const onInput = (val: string) => {
        setQuestion(val.trim());
        debounceFn(val);
    };

    function handleDebounceFn(message) {
        setIsShowAi(false);
        message && sendQuestionSearchHelper({ message: message });
    }

    const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);

    // Событие отправки сообщения Чату
    const onSubmit = async (e: FormEvent<HTMLFormElement> | KeyboardEvent) => {
        e.preventDefault();

        if (!question) return;

        if (isSearchedBack && isSuccess && !questionAiLoading) {
            if (isNotAuthUser && isSearchLimitExceeded) {
                setDataQuestionAi([]);
                setIsShowAi(true);
                setQuestionAiLoading('Loaded');
                return;
            }

            setIsSearchedBack(false);
            setQuestionAiLoading('Loading');
            await sendQuestionAi({ message: question, use_cache: 0 });
            setIsShowAi(true);
            setQuestion("");
            setTimeout(() => {
                setQuestionAiLoading('Loaded');
                setIsShowSearchButton(true);
            }, 750);
        } else {
            sendQuestionSearchHelper({ message: question });
        }
    };

    const generateTagsByData = (array) => {
        const tags: TRenderTag[] = [];

        if (array && array?.length) {
            if (Array.isArray(array)) {
                array.forEach((item) => {
                    const categoryId: number | string = item?.category || '';
                    const categoryName: string = item?.categoryName || '';
                    const typeId: number | string = item?.type || '';
                    const typeName: string = item?.typeName || '';
                    const types = item?.types || [];
                    const subtype =  item?.subtype || [];
                    const category = {
                        categoryId, 
                        categoryName,
                        categorySlug: item?.categorySlug || ''
                    };
                    const type = {
                        typeId, 
                        typeName,
                        typeSlug: item?.typeSlug || ''
                    };

                    (categoryId && categoryName) && tags.push({ 
                            id: categoryId, 
                            name: categoryName,
                            slug: item?.categorySlug,
                            type: 'category'
                        });

                    if (types?.length) {
                        types.forEach((typeTag) => {
                            tags.push({ 
                                id: typeTag.type,
                                name: typeTag.typeName,
                                slug: typeTag.typeSlug,
                                type: "type",
                                ...category
                             });

                            if (typeTag?.subtypes?.length) {
                                typeTag?.subtypes.forEach((subTag) =>
                                    tags.push({
                                        ...category,
                                        typeId: typeTag.type,
                                        typeName: typeTag.typeName,
                                        typeSlug: typeTag.typeSlug,
                                        id: subTag.id,
                                        name: subTag.name,
                                        slug: subTag.subtypeSlug,
                                        type: 'subtype'
                                    })
                                );
                            }
                        });
                    } else if (typeId && typeName) {
                        tags.push({
                            id: typeId,
                            name: typeName,
                            slug: item?.typeSlug,
                            type: "type",
                            ...category
                        });
                    }

                    if (Array.isArray(subtype) && subtype?.length) {
                        subtype.forEach(stag => tags.push({
                            ...category,
                            ...type,
                            id: stag.id,
                            name: stag.name,
                            type: 'subtype',
                            slug: stag.subtypeSlug
                        }));
                    } else if (subtype?.id) {
                        const subtypes = Object.values(subtype)?.reduce((acc: Array<any>, item) => {
                            return typeof item === 'object' ? [...acc, item] : acc
                        }, []);
        
                        if (subtypes?.length) {
                            subtypes.forEach(item => tags.push({
                                ...category,
                                ...type,
                                id: item.id,
                                name: item.name,
                                type: 'subtype',
                                slug: item.subtypeSlug
                            }))
                        }
        
                        tags.push({
                            ...category,
                            ...type,
                            id: subtype.id,
                            name: subtype.name,
                            type: 'subtype',
                            slug: subtype.subtypeSlug
                        });
                    }
                })
            }
        }

        return tags;
    }

    const onCloseModal = () => {
        setShowModal(false);
        setIsShowSearchButton(false);
        setIsShowAi(false);
        setIsSearchedBack(false);
        setQuestionAiLoading('');
        setItemStore('csai', aiSearchCount);

        if (timerId) {
            clearTimeout(timerId);
            setTimerId(null);
        }
    }

    // считаем количество раз поиска неавторизованного пользователя, для ограничения
    const countSearchRequest = () => {
        if (isNotAuthUser && (questionAiLoading === 'Loaded' || questionAiLoading === 'Loading')) {
            setAiSearchCount(aiSearchCount + 1);
        }
    }

    const onMoveTag = (tag: TRenderTag) => {
        setTypeTag(tag.type);
        setSelectedTag(tag);
        onCloseModal();
        setShowSelectModal(true);
    }

    const onCreateOrder = () => {
        if (!isSpecialist) {
            navigate(`/${currentLanguage}/customer/create-order`);
        }

        onClosedAllModal();

        if (isSpecialist) {
            setShowModalOrder(true);
        }
    }

    const onClosedAllModal = () => {
        clearCreateOrderFields();
        clearOrderDataInLocalStorage(false, []);
        onCloseModal();
        setShowModalOrder(false);
        setShowSelectModal(false);
        setQuestionAiLoading('');
        setDataQuestionAi([]);
        setTypeTag('');
    }

    // Генерируем путь
    const resultItems = () => {
        const items = [{ name: IS_PRIVATE, value: privateOrder == 'yes' }]
        let step = 'create-order';

        if (selectedTag) {
            const categoryId = typeTag === 'category' ? selectedTag?.id : selectedTag?.categoryId;
            const categoryName = typeTag === 'category' ? selectedTag?.name : selectedTag?.categoryName;
            const categorySlug = typeTag === 'category' ? selectedTag?.slug : selectedTag?.categorySlug;
            const typeId = typeTag === 'type' ? selectedTag?.id : selectedTag?.typeId;
            const typeName = typeTag === 'type' ? selectedTag?.name : selectedTag?.typeName;
            const typeSlug = typeTag === 'type' ? selectedTag?.slug : selectedTag?.typeSlug;

            if (categoryId) {
                items.push(
                    { name: SERVICE_CATEGORY_ID, value: Number(categoryId) },
                    { name: SERVICE_CATEGORY_SLUG, value: categorySlug },
                    { name: 'activeStep', value: `category-${categorySlug}` }
                );
    
                if (categoryName) {
                    items.push({ name: SERVICE_CATEGORY_NAME, value: categoryName });
                }
                step = `create-order/category-${categorySlug}`;
            }

            if (typeId) {
                items.push(
                    { name: SERVICE_TYPE_ID, value: Number(typeId) },
                    { name: SERVICE_TYPE_SLUG, value: typeSlug },
                    { name: 'activeStep', value: `category-${categorySlug}/type-${typeSlug}` }
                );
    
                if (typeName) {
                    items.push({ name: SERVICE_TYPE_NAME, value: typeName });
                }

                step = `create-order/category-${categorySlug}/type-${typeSlug}`;
            }

            if (typeTag === 'subtype') {
                items.push(
                    {name: SERVICE_SUB_TYPE_IDS, value: selectedTag?.id ? [selectedTag?.id] : [] },
                    {name: SERVICE_SUB_TYPE_NAME, value: selectedTag?.name },
                    {name: SERVICE_SUB_TYPE_SLUG, value: selectedTag?.slug },
                    { name: 'activeStep', value: `category-${categorySlug}/type-${typeSlug}/subtype-${selectedTag?.slug}` }
                );
                step = `create-order/category-${categorySlug}/type-${typeSlug}/subtype-${selectedTag?.slug}`;
            }
        }

        return { items, step };
    }

    // Создаем заказ и переходим
    const createOrder = () => {
        const { items, step } = resultItems();
        clearCreateOrderFields();
        clearOrderDataInLocalStorage(false, []);
        setShowSelectModal(false);
        onCloseModal();

        items.forEach((elem) => {
            updateField({
                name: elem.name,
                value: elem.value,
            });
        });

        if (profile?.id) {
            updateField({
                name: "userId",
                value: profile?.id,
            });
        }

        setTimeout(() => navigate(`/${currentLanguage}/customer/${step}`), 10);
    };

    // Методо выхода
    const handleLogout = () => {
		logout();
        onCloseModal();
        setShowModalOrder(false);
        setShowSelectModal(false);
        setQuestionAiLoading('');
        setDataQuestionAi([]);

		setTimeout(() => createOrder(), 10);
	};

    const onOpenModal = () => {
        setShowModal(true);
    }

    // Ожидаем действия от пользователя, Приватный или Не закрыты Заказ
    useEffect(() => {
        if (privateOrder) {
            if (!isSpecialist) {
                createOrder();
            } else {
                onCloseModal();
                setShowSelectModal(false);
                setShowModalOrder(true);
            }
            setPrivateOrder('');
            setQuestionAiLoading('');
            setDataQuestionAi([]);
        }
    }, [privateOrder]);

    useEffect(() => {
        if (data) {
            setDataQuestionAi(data || []);
            countSearchRequest();
        }
    }, [data]);

    useEffect(() => {
        if (!isUninitialized) {
            setIsSearchedBack(true);

            if (isSearchLimitExceeded && questionAiLoading !== '') {
                setQuestionAiLoading('Back');
            }

            if (questionAiLoading === 'Loaded') {
                setQuestion('');
            }

            if (searchHelperData) {
                setDataQuestionAi(searchHelperData);

                if (!isShowSearchButton) {
                    if (searchHelperData?.length && !timerId) {
                        const idTimer = setTimeout(() => {
                            if (questionAiLoading !== 'Back') {
                                setIsShowSearchButton(true);
                            }
                        }, 5000);

                        setTimerId(idTimer);
                    } else {
                        setIsShowSearchButton(true);
                    }
                }
            }
        }
    }, searchHelperData)

    useEffect(() => {
        setRenderedTags(generateTagsByData(dataQuestionAi));
    }, [dataQuestionAi])

    return (
        <>
            <button
                className={cn(
                    'trigger-gpt',
                    { 'trigger-gpt--banner': colored },
                    { 'colored': colored },
                )}
                onClick={onOpenModal}
            >
                <span className='trigger-gpt__inner'>
                    <span className='trigger-gpt__text'>{placeholder || entities?.what_kind_of_help?.value}</span>
                    <svg className='trigger-gpt__icon' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.7663 20.5889 16.7962 15.6188C18.1506 13.9623 18.8165 11.8487 18.6562 9.715 18.4959 7.5813 17.5216 5.5908 15.9349 4.1553 14.3482 2.7198 12.2704 1.9491 10.1314 2.0026 7.9923 2.0561 5.9557 2.9297 4.4427 4.4427 2.9297 5.9557 2.0561 7.9923 2.0026 10.1314 1.9491 12.2704 2.7198 14.3482 4.1553 15.9349 5.5908 17.5216 7.5813 18.4959 9.715 18.6562 11.8487 18.8165 13.9623 18.1506 15.6188 16.7962L20.5889 21.7663C20.7459 21.9179 20.9563 22.0019 21.1746 22 21.3929 21.9981 21.6017 21.9105 21.7561 21.7561 21.9105 21.6017 21.9981 21.3929 22 21.1746 22.0019 20.9563 21.9179 20.7459 21.7663 20.5889ZM10.3531 17.0143C9.0357 17.0143 7.7478 16.6237 6.6524 15.8917 5.5569 15.1598 4.7032 14.1194 4.199 12.9023 3.6948 11.6851 3.5629 10.3458 3.8199 9.0536 4.077 7.7615 4.7114 6.5746 5.643 5.643 6.5746 4.7114 7.7615 4.077 9.0536 3.8199 10.3458 3.5629 11.6851 3.6948 12.9023 4.199 14.1194 4.7032 15.1598 5.5569 15.8917 6.6524 16.6237 7.7478 17.0143 9.0357 17.0143 10.3531 17.0124 12.1192 16.3099 13.8123 15.0611 15.0611 13.8123 16.3099 12.1192 17.0124 10.3531 17.0143Z' fill={colored ? '#9EA4A7' : '#23232A'} />
                    </svg>
                </span>
            </button>
            <Modal
                visible={showModalOrder}
                onClose={() => onClosedAllModal()}
                title={entities?.common_offer_order?.value}
				description={entities?.profile_logout_specialist?.value}
            >
                <div className="modal__content-buttons">
					<button
						className="btn btn--transparent"
						type="button"
						onClick={() => onClosedAllModal()}
					>
						{entities?.common_cancel_button?.value}
					</button>
					<button
						className="btn modal__content-button btn--bg-green"
						type="button"
						onClick={handleLogout}
					>
						{entities?.profile_logout_button_ok?.value}
					</button>
				</div>
            </Modal>
            <Modal
                visible={showModal}
                onClose={() => onClosedAllModal()}
                title={entities?.write_your_needs?.value}
                description={entities?.search_ai_description?.value}
            >
                <form className='trigger-gpt__form' onSubmit={onSubmit}>
                    <TextArea
                        classes={['trigger-gpt__textarea']}
                        type='text'
                        maxLength={undefined}
                        placeholder={entities?.common_ai_looking?.value}
                        onChange={onChange}
                        onEnter={onSubmit}
                        onInput={onInput}
                    />
                    <div
                        className={cn('specialist-profile__row', {
                            'specialist-profile__row--search': renderedTags.length
                        })}
                    >
                        {renderedTags.map(tag => (
                            <button
                                className={cn("new-order-start__toggle", `new-order-start__toggle--${tag.type}`)}
                                type="button"
                                onClick={() => onMoveTag(tag)}
                            >
                                {tag.name}
                            </button>
                        ))}
                        {questionAiLoading === 'Loading' && (!isShowAi || renderedTags.length === 0) &&
                            <span
                                className="new-order-start__toggle new-order-start__toggle--preloader"
                            >
                                {entities?.common_ai_search_loading?.value}
                            </span>
                        }
                        {questionAiLoading === 'Loaded' && (isShowAi && renderedTags.length === 0) && !isSearchLimitExceeded &&
                            <span
                                className="new-order-start__toggle new-order-start__toggle--preloader"
                            >
                                {entities?.common_ai_search_loaded_not_find?.value}
                            </span>
                        }
                        {(questionAiLoading === 'Loaded' && isSearchLimitExceeded && isNotAuthUser) &&
                            <span
                                className="new-order-start__toggle new-order-start__toggle--preloader"
                            >
                                {entities?.common_ai_search_exceeded_limit?.value}
                            </span>
                        }
                    </div>
                    <div className="specialist-profile__row specialist-profile__row--search">
                        {isShowAi && renderedTags.length && !isSearchedBack? 
                            (
                                <span
                                    className="new-order-start__toggle new-order-start__toggle--preloader"
                                >
                                    {entities?.common_ai_search_loaded_find?.value}
                                </span>
                            ) : ''
                        }
                    </div>
                    {isShowSearchButton && <>
                        {questionAiLoading === '' ? 
                            (<button
                                className='btn btn--blue trigger-gpt__btn'
                                type='submit'
                            >
                                {entities?.button_ai_text?.value}
                            </button>) : ('')
                        }
                        {(questionAiLoading === 'Loaded' || questionAiLoading === 'Back') &&
                            <button
                                className='btn btn--blue trigger-gpt__btn'
                                type='button'
                                onClick={onCreateOrder}
                            >
                                {entities?.common_offer_order_ai?.value}
                            </button>
                        }
                    </>}
                </form>
            </Modal>
            <Modal
                title={entities?.create_order_link_value?.value}
                description={entities?.order_place?.value}
                visible={showSelectModal}
                onClose={() => onClosedAllModal()}
            >
                <div className="modal__content-buttons">
                    <button
                        className="btn"
                        type="button"
                        onClick={() => setPrivateOrder('yes')}
                    >
                        {entities?.order_place_specialist_select?.value}
                    </button>
                    <button
                        className="btn modal__content-button btn--bg-green"
                        type="button"
                        onClick={() => setPrivateOrder('no')}
                    >
                        {entities?.order_place_specialist_send?.value}
                    </button>
                </div>
            </Modal>
        </>
    )
};

export default Search

import React from "react";
import { Link } from "react-router-dom";

import { directoriesApi } from "../../store/directories/directories.api";
import { getCurrentLanguage, getUrl } from "../../store/directories/selectors";

const TextLink: React.FC<{ id: number }> = ({ id }) => {
    const currentLanguage = getCurrentLanguage();
    const { data } = directoriesApi.endpoints.getDirectories.useQuery();
    const page = data?.text_pages?.find((page) => page.id == id);
    const url = page && getUrl(page?.path);

    return (
        <Link
            key={page?.id}
            className="footer__legal-ground-link"
            to={url || "/"}
        >
            {page?.title[currentLanguage] || ""}
        </Link>
    );
};

export default TextLink;

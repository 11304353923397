import React, { useEffect, useState } from "react";
import RadioInput from "../../../../components/Form/RadioInput";
import Input, { InvalidFieldsProps } from "../../../../components/Form/Input";
import { ICompany } from "../../../../models/userModels";
import { TranslationObject } from "../../../../models/translationModal";

interface RegistrationDataProps {
    company?: ICompany | null;
    invalidFields?: InvalidFieldsProps;
    error?: any;
    entities?: TranslationObject;
}

export const RegistrationData: React.FC<RegistrationDataProps> = ({
    company,
    invalidFields,
    error,
    entities,
}) => {
    const { insurance_company_name, insurance_max_payment_amount } =
        company || {};

    const [visible, setVisible] = useState<boolean>(false);

    const [insuranceName, setInsuranceName] = useState<string>("");
    const [insurancePayment, setInsurancePayment] = useState<string>("");

    useEffect(() => {
        if (insurance_company_name && insurance_max_payment_amount) {
            setVisible(true);
        }

        if (insurance_company_name) {
            setInsuranceName(insurance_company_name);
        }

        if (insurance_max_payment_amount) {
            setInsurancePayment(insurance_max_payment_amount);
        }
    }, [insurance_company_name, insurance_max_payment_amount]);

    return (
        <>
            <div className="profile__fieldset profile__contacts">
                <fieldset>
                    <legend>
                        {entities?.profile_registration_data?.value}
                    </legend>
                    <div className="form__block">
                        <h3 className="title title--h4">
                            {entities?.register_company_has_insurance?.value}
                        </h3>
                        <RadioInput
                            checked={visible}
                            label={entities?.common_yes?.value || ""}
                            onClick={() => setVisible(true)}
                        />
                        <RadioInput
                            checked={!visible}
                            label={entities?.common_no?.value || ""}
                            onClick={() => setVisible(false)}
                        />
                    </div>
                    {visible && (
                        <>
                            <div className="form__row">
                                <Input
                                    type="text"
                                    name="insurance_company_name"
                                    value={insuranceName}
                                    onChange={setInsuranceName}
                                    id="insurance_company_name"
                                    invalidFields={invalidFields}
                                    error={error}
                                    placeholder={
                                        entities
                                            ?.register_company_insurance_name
                                            ?.value || ""
                                    }
                                />
                            </div>
                            <div className="form__row">
                                <Input
                                    type="text"
                                    name="insurance_max_payment_amount"
                                    value={insurancePayment}
                                    onChange={setInsurancePayment}
                                    id="insurance_max_payment_amount"
                                    invalidFields={invalidFields}
                                    error={error}
                                    placeholder={
                                        entities
                                            ?.register_company_insurance_benefit
                                            ?.value || ""
                                    }
                                />
                            </div>
                        </>
                    )}
                </fieldset>
            </div>
        </>
    );
};

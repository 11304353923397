import React from "react";
import { endpoints } from "../../../store/directories/directories.api";
import { getItemStore } from "../../../untils";
import { Category } from "../../../models/directoryModel";
import { useNavigate } from "react-router-dom";
import { SERVICE_CATEGORY_ID } from "../../../store/order/constant";
import {
    getItemCreateOrder,
    getItemEditOrder,
} from "../../../store/order/selectors";

const CategoryItem: React.FC<{
    currentLanguage: string;
    isEdit: boolean;
}> = ({ currentLanguage, isEdit }) => {
    const navigate = useNavigate();
    const id = isEdit
        ? getItemEditOrder(SERVICE_CATEGORY_ID)
        : getItemCreateOrder(SERVICE_CATEGORY_ID);
    const { data } = endpoints.getServiceCategories.useQuery();
    const category = data?.find((elem: Category) => elem.id == id)?.name || "";
    const link = `/${currentLanguage}/customer/${
        isEdit ? "edit-order" : "create-order"
    }`;

    return (
        <div className="form__row">
            <div className="link" onClick={() => navigate(link)}>
                {category}
            </div>
        </div>
    );
};

export default CategoryItem;

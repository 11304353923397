import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
    getReviews,
    getReviewsByCustomerId,
    getReviewsBySpecialistId,
    getSpecialistPageTranslate,
    getTranslateReview,
    makeReviewPay,
    share,
    readReview,
} from "./api";
import {
    makeReviewPayData,
    ReviewItem,
    ReviewPayload,
    ReviewRootObject,
    makeReviewShareData,
    SharePayload,
    SpecialistPageTranslatePayload,
    ShareRootObject
} from "../../models/reviewModel";
import { changeHeaders } from "../request";
import { IdProps } from "../models";

export const reviewApi = createApi({
    reducerPath: "review/api",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
        prepareHeaders: (headers, { getState }) =>
            changeHeaders(headers, { getState }),
    }),
    tagTypes: ["Reviews"],
    endpoints: (build) => ({
        getReviewsBySpecialistId: build.query<
            ReviewPayload,
            {
                params?: Record<string, number>;
                id: number;
            }
        >({
            query: ({ params, id }) => getReviewsBySpecialistId({ params, id }),
            transformResponse: (response: ReviewRootObject) => response.payload,
        }),
        getReviews: build.query<
            ReviewPayload,
            {
                params?: Record<string, number>;
            }
        >({
            query: ({ params }) => getReviews({ params }),
            transformResponse: (response: ReviewRootObject) => response.payload,
            providesTags: [{ type: "Reviews" }],
        }),
        readReview: build.mutation<ReviewItem, IdProps>({
            query: ({ id }) => readReview({ id }),
            invalidatesTags: [{ type: "Reviews" }],
        }),
        getReviewsByCustomerId: build.query<
            ReviewPayload,
            {
                params?: Record<string, number>;
                id: number;
            }
        >({
            query: ({ params, id }) => getReviewsByCustomerId({ params, id }),
            transformResponse: (response: ReviewRootObject) => response.payload,
        }),
        getTranslateReview: build.mutation<ReviewItem, number>({
            query: (id) => getTranslateReview(id),
            transformResponse: (response: {
                description: string;
                payload: ReviewItem;
            }) => response.payload,
        }),
        getSpecialistPageTranslate: build.mutation<
            SpecialistPageTranslatePayload,
            number
        >({
            query: (id) => getSpecialistPageTranslate(id),
            transformResponse: (response: {
                description: string;
                payload: SpecialistPageTranslatePayload;
            }) => response.payload,
        }),
        makeReviewPay: build.mutation<
            any,
            makeReviewPayData
        >({
            query: (requestData) => makeReviewPay(requestData),
        }),
        getShare: build.query<
            SharePayload,
            makeReviewShareData
        >({
            query: ({ reviewId, language }) => share({ reviewId, language }),
            transformResponse: (response: ShareRootObject) => response.payload,
        }),
    }),
});

export const {
    endpoints,
    useReadReviewMutation,
    useGetTranslateReviewMutation,
    useGetSpecialistPageTranslateMutation,
    useMakeReviewPayMutation
} = reviewApi;

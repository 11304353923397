import { useNavigate } from "react-router-dom";
import { getCurrentLanguage, getPath } from "../../store/directories/selectors";
import { deleteCookie } from "../../untils/cookies";
import { useActions } from "../actions";
import { endpoints as getEndpoints, util } from "../../store/user/user.api";
import { useAppDispatch } from "../useAppDispatch";

export const useLogout = () => {
    const { clearTkn, clearRegistrationState } = useActions();
    const dispatch = useAppDispatch()

    const navigate = useNavigate();

    const path = getPath();

    const currentLanguage = getCurrentLanguage();

    //чистим при любых условиях чтобы не было бага

    const clearProfile = () => dispatch(
        util.updateQueryData(
            "getProfile",
            undefined,
            (user) => user = null
        )
    );

    return async (isNavigate = true) => {
        clearProfile()
        deleteCookie("tkn");
        clearTkn();
        await clearRegistrationState();
        isNavigate && navigate(`/${currentLanguage}/${path[1]}/`);
    };
};

import React from "react";
import { TranslationObject } from "../../models/translationModal";
import classNames from "classnames";

interface Props {
    entities?: TranslationObject;
    status: string;
    isSpecialist: boolean;
    isDeal: boolean;
    isDraft: boolean;
    isActive: boolean;
    isDone: boolean;
    isArchive: boolean;
    isResponded?: boolean;
    className?: string;
    hasExecutor?: boolean;
}

const StatusOrder: React.FC<Props> = ({
    entities,
    status,
    isSpecialist,
    isDeal,
    isDraft,
    isActive,
    isDone,
    isArchive,
    isResponded,
    className = "cards-orders__item-status",
    hasExecutor = false,
}) => {
    const controls: Record<string, string> = {
        active:
            isSpecialist && !isResponded
                ? entities?.common_new?.value || ""
                : entities?.order_status_active?.value || "",
        deal:
            hasExecutor && !isSpecialist
                ? entities?.order_status_specialist_selected?.value || ""
                : entities?.order_status_deal?.value || "",
        done: entities?.order_status_competed?.value || "",
        archive: entities?.order_list_status_archive?.value || "",
        draft: entities?.order_list_status_draft?.value || "",
    };

    const title = controls[status];

    return (
        <span
            className={classNames(className, {
                [className + "--blue"]:
                    (isDeal && !hasExecutor) || (isDeal && isSpecialist),
                [className + "--fulfil"]: isDone,
                [className + "--active"]:
                    (isActive && !isSpecialist) ||
                    (isActive && isSpecialist && isResponded),
                [className + "--red"]: isActive && isSpecialist && !isResponded,
                [className + "--draft"]: isDraft || isArchive,
                [className + "--spec-check"]:
                    isDeal && hasExecutor && !isSpecialist,
            })}
        >
            {title}
        </span>
    );
};

export default StatusOrder;

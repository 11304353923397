import React, { useEffect, useState } from "react";
import AccordionView from "../AccordionView";
import { TranslationObject } from "../../../models/translationModal";
import CheckboxAddress from "../../../components/CheckboxAddress";
import { useActions } from "../../../hooks/actions";
import { InvalidFieldsProps } from "../../../components/Form/Input";
import { getItemEditOrder } from "../../../store/order/selectors";
import {
    IS_MY_ADDRESS,
    IS_REMOTE,
    IS_SPECIALIST_ADDRESS,
} from "../../../store/order/constant";

interface Props {
    entities?: TranslationObject;
    invalidFields?: InvalidFieldsProps;
    error?: any;
}

const MeetingPointEditOrder: React.FC<Props> = ({
    entities,
    invalidFields,
    error,
}) => {
    const [currentAddresses, setCurrentAddresses] = useState<string[]>([]);
    const is_remote = getItemEditOrder(IS_REMOTE);
    const is_my_address = getItemEditOrder(IS_MY_ADDRESS);
    const is_specialist_address = getItemEditOrder(IS_SPECIALIST_ADDRESS);

    const { updateEditOrderField } = useActions();

    useEffect(() => {
        const items = [];
        if (is_remote) {
            items.push(IS_REMOTE);
        }
        if (is_my_address) {
            items.push(IS_MY_ADDRESS);
        }
        if (is_specialist_address) {
            items.push(IS_SPECIALIST_ADDRESS);
        }
        setCurrentAddresses(items);
    }, [is_remote, is_my_address, is_specialist_address]);

    const handleChange = (str: string) => {
        const isChecked = currentAddresses?.indexOf(str) !== -1;
        updateEditOrderField({
            name: str,
            value: !isChecked,
        });
    };

    return (
        <AccordionView
            textButton={
                entities?.order_select_location_title?.value + "?" || ""
            }
        >
            <div className="accordion-form__item-content">
                <CheckboxAddress
                    entities={entities}
                    invalidFields={invalidFields}
                    currentAddresses={currentAddresses}
                    error={error}
                    handleChange={handleChange}
                />
            </div>
        </AccordionView>
    );
};

export default MeetingPointEditOrder;

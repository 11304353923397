import { RefObject, useEffect } from "react";

export default function useClickOutside(
    ref: RefObject<HTMLDivElement> | null,
    callback: (event: Event) => void
) {
    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (
                ref &&
                ref.current &&
                !ref.current.contains(event.target as Node)
            ) {
                callback(event);
            }
        }

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [ref]);
}

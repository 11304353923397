import React from "react";
import ReactDOM from 'react-dom';
import { useLocation, useSearchParams } from "react-router-dom";
import { TranslationObject } from "../../models/translationModal";

interface UnverifiedNotificationProps {
    entities: TranslationObject;
    isFirefox: boolean;
}

const unviewPages = ["registration"];
const invalidSearchParams = [
    {
        name: "type",
        value: "service",
    },
];

export const UnverifiedNotification: React.FC<UnverifiedNotificationProps> = ({
    entities,
    isFirefox
}) => {
    const container = document.getElementById('root') || document.body;
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const currentPageUnview = ((pathname: string, unviewPages: string[]) => {
        return unviewPages.some((el) => pathname.includes(el));
    })(pathname, unviewPages);
    const isExistNotValidSearchParams = invalidSearchParams.find(
        (param) => searchParams.get(param.name) === param.value
    );

    if (currentPageUnview || isExistNotValidSearchParams) return null;

    const jsxCookies = () =>
        <div className="cookies unverified-notification">
            <div className="cookies__container">
                <p
                    className="cookies__text"
                    dangerouslySetInnerHTML={{
                        __html: entities?.common_account_not_verified?.value,
                    }}
                />
            </div>
        </div>

    return isFirefox ? ReactDOM.createPortal(jsxCookies(), container) : jsxCookies();
};

import React from "react";
import { Language } from "../../../models/userModels";
import { TranslationObject } from "../../../models/translationModal";

interface Props {
    specialistLanguages: Language[];
    entities?: TranslationObject;
}

const LanguagesSpecialist: React.FC<Props> = ({
    specialistLanguages,
    entities,
}) => {
    const textLanguages = entities?.common_communication_languages?.value;
    return (
        <div className="languages">
            <p className="languages__title">{textLanguages}:</p>
            <div className="languages__wrapper">
                <ul className="languages__list">
                    {specialistLanguages
                        ?.slice(0, 3)
                        ?.map((language: Language, key: number) => (
                            <li className="languages__item popover" key={key}>
                                <span className="popover__tip">
                                    {language?.name}
                                </span>
                                <img
                                    src={language?.flag?.original_url}
                                    height="11"
                                    alt={language?.name || 'language'}
                                />
                            </li>
                        ))}
                </ul>
                {specialistLanguages?.length > 3 && (
                    <span className="languages__add">
                        +{specialistLanguages?.length - 3}
                    </span>
                )}
            </div>
        </div>
    );
};

export default LanguagesSpecialist;

import React from "react";
import _ from "lodash";
import { TranslationObject } from "../../../models/translationModal";
import { orderKeys } from "../../../store/order/selectors";
import { IFilterPage, OrderItem } from "../../../models/ordersModel";
import { useActions } from "../../../hooks/actions";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import { useNavigate } from "react-router-dom";
import { isSpecialistUrl } from "../../../store/directories/selectors";
import cn from "classnames";
import {
    ENDED_AT,
    FILTER_PAGES,
    STARTED_AT,
} from "../../../store/order/constant";

interface Props {
    entities?: TranslationObject;
    isDropDown: boolean;
    isDraft?: boolean;
    order: OrderItem;
    isSpecialist: boolean;
}

const EditOrderButton: React.FC<Props> = ({
    entities,
    isDraft,
    isDropDown = true,
    order,
    isSpecialist
}) => {
    const text = entities?.order_edit?.value;
    const currentLanguage = getCurrentLanguage();
    const navigate = useNavigate();
    const { updateEditOrderField } = useActions();

    const editOrder = () => {
        const item = orderKeys(true);
        item?.forEach((key) => {
            if (order[key as keyof OrderItem]) {
                if (key === STARTED_AT || key === ENDED_AT) {
                    return;
                }

                if (key === FILTER_PAGES) {
                    const filters = [
                        ...order[key as keyof OrderItem].map(
                            (elem: IFilterPage) => ({
                                service_type_filter_id:
                                    elem?.service_type_filter_id,
                                service_type_filter_value_ids:
                                    elem?.service_type_filter_value_ids,
                                custom_filter_value:
                                    elem?.custom_filter_value || "",
                            })
                        ),
                    ];
                    return updateEditOrderField({
                        name: key,
                        value: filters,
                    });
                }
                updateEditOrderField({
                    name: key,
                    value: order[key as keyof OrderItem],
                });
            }
        });
        navigate(`/${currentLanguage}/customer/edit-order`);
    };

    if (!isDraft) {
        return null;
    }

    return isDropDown ? (
        <div
            className="dotted-menu__item"
            onClick={editOrder}
            key={_.uniqueId("button_dropdown_order")}
        >
            <div className="dotted-menu__item-link">{text}</div>
        </div>
    ) : (
        <button
            onClick={editOrder}
            className={cn("btn order-information__button order-information__button--list", {
                "btn--green": isSpecialist || isSpecialistUrl(),
            })}
            type="button"
        >
            {text}
        </button>
    );
};

export default EditOrderButton;

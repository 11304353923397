import { useNotification } from "./../../../../hooks/useNotification/useNotification";
import { useActions } from "../../../../hooks/actions";
import { useSelector } from "react-redux";
import { showAddCardModalSelector } from "../../../../store/finances/finances.selectors";
import { IUseAddCardModal } from "./useAddCardModal.types";
import { PaymentMethod } from "@stripe/stripe-js";
import { useAttachNewBankCardMutation } from "../../../../store/finances/finances.api";
import { TranslationObject } from "../../../../models/translationModal";

export const useAddCardModal = (
    entities?: TranslationObject
): IUseAddCardModal => {
    const [attachNewBankCard] = useAttachNewBankCardMutation();

    const { setShowAddCardModal } = useActions();

    const { setNotification } = useNotification();

    const showAddCardModal = useSelector(showAddCardModalSelector);

    const openAddCardModal = () => {
        setShowAddCardModal(true);
    };

    const hideAddCardModal = () => {
        setShowAddCardModal(false);
    };

    const newCardHandler = (card: PaymentMethod) => {
        const newCardIdStripe = card.id;

        if (newCardIdStripe) {
            attachNewBankCard({
                card_id: newCardIdStripe,
            })
                .unwrap()
                .then(() =>
                    setNotification({
                        type: "confirm",
                        description: "Bank card successfully added",
                    })
                )
                .catch((error) => {
                    const errorDescription =
                        entities?.add_credit_card_error?.value ??
                        error?.data?.description ??
                        "";
                    setNotification({
                        type: "warn",
                        description: errorDescription,
                    });
                });
        }
        hideAddCardModal();
    };

    return {
        showAddCardModal,
        openAddCardModal,
        hideAddCardModal,
        newCardHandler,
    };
};

import React, { useState } from "react";
import ReviewModal from "../../Modals/ReviewModal";
import _ from "lodash";
import { TranslationObject } from "../../../models/translationModal";

interface Props {
    order_id: number;
    isSpecialist: boolean;
    entities?: TranslationObject;
}

const CreateReviewCustomerButton: React.FC<Props> = ({
    order_id,
    isSpecialist,
    entities,
}) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <ReviewModal
                key={_.uniqueId("modal_review")}
                visible={showModal}
                onClose={() => setShowModal(false)}
                order_id={order_id}
                isSpecialist={isSpecialist}
                entities={entities}
            />
            <button
                className="btn--transparent cards-orders__btn btn btn--rounded"
                type="button"
                onClick={() => setShowModal(true)}
            >
                {entities?.order_write_review?.value}
            </button>
        </>
    );
};

export default CreateReviewCustomerButton;

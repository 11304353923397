import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    CompanyEmployeesPayload,
    CustomerPayload,
    CustomerRootObject,
    ISetNewComplaintRequest,
    ProfilePayload,
    ProfileRootObject,
    SpecialistDetailPayload,
    SpecialistDetailRootObject,
    SpecialistPayload,
    SpecialistRootObject,
    UserBonusesPayload,
} from "../../models/userModels";

import {
    changePassword,
    createUserAchievement,
    deleteAvatar,
    deleteEmployeeCompany,
    deleteProfile,
    deleteSpecialistService,
    deleteSpecialistServicePhoto,
    deleteUserAchievement,
    deleteUserAchievementDocument,
    getCompanyEmployees,
    getCustomer,
    getSpecialist,
    getSpecialistDetail,
    getUserBonuses,
    publishSpecialistProfile,
    sendNotifyAdmin,
    setAssignEmployeeManager,
    setConfirmEmployee,
    setManagerCompany,
    setNewComplaint,
    setSpecialistNewService,
    setSpecialistServiceCategory,
    setSpecialistServiceSubType,
    setSpecialistServiceType,
    update,
    updateProfile,
    updateUserAboutMe,
    updateUserAchievement,
    updateUserAddress,
} from "./api";
import {
    AuthWithPhoneObject,
    bodyChangePassword,
    confirmationObject,
} from "../../models/authModel";
import { changeHeaders } from "../request";
import { BodyData, BodyFormData, EmptyPayload, IdProps } from "../models";

export const userApi = createApi({
    reducerPath: "user/api",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
        prepareHeaders: (headers, { getState }) =>
            changeHeaders(headers, { getState }),
    }),
    tagTypes: ["Profile", "Localized"],
    endpoints: (build) => ({
        getProfile: build.query<ProfilePayload, void>({
            query: () => "profile",
            providesTags: [
                { type: "Profile", id: "PROFILE" },
                { type: "Profile", id: "FIELDS" },
                { type: "Localized" },
            ],
            transformResponse: (response: ProfileRootObject) =>
                response.payload,
        }),
        changePassword: build.mutation<confirmationObject, bodyChangePassword>({
            query: ({ body }) => changePassword({ body }),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        deleteProfile: build.mutation<EmptyPayload, void>({
            query: () => deleteProfile(),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        updateProfile: build.mutation<ProfilePayload, BodyFormData>({
            query: ({ body }) => updateProfile({ body }),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        update: build.mutation<
            AuthWithPhoneObject,
            {
                str: string;
                body: Record<string, string>;
            }
        >({
            query: ({ str, body }) => update({ str, body }),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        deleteAvatar: build.mutation<EmptyPayload, void>({
            query: () => deleteAvatar(),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        getCustomer: build.query<CustomerPayload, IdProps>({
            query: ({ id }) => getCustomer({ id }),
            providesTags: [{ type: "Localized" }],
            transformResponse: (response: CustomerRootObject) =>
                response.payload,
        }),
        getSpecialist: build.query<SpecialistPayload, IdProps>({
            query: ({ id }) => getSpecialist({ id }),
            providesTags: [{ type: "Localized" }],
            transformResponse: (response: SpecialistRootObject) =>
                response.payload,
        }),
        getSpecialistDetail: build.query<SpecialistDetailPayload, IdProps>({
            query: ({ id }) => getSpecialistDetail({ id }),
            providesTags: [{ type: "Localized" }],
            transformResponse: (response: SpecialistDetailRootObject) =>
                response.payload,
        }),
        updateUserAddress: build.mutation<SpecialistDetailPayload, BodyData>({
            query: ({ body }) => updateUserAddress({ body }),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        updateUserAboutMe: build.mutation<ProfilePayload, BodyData>({
            query: ({ body }) => updateUserAboutMe({ body }),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        //TODO: -add types
        createUserAchievement: build.mutation<EmptyPayload, any>({
            query: ({ body, currentLanguage, token }) =>
                createUserAchievement({ body, currentLanguage, token }),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        //TODO: -add types
        updateUserAchievement: build.mutation<EmptyPayload, any>({
            query: ({ achievementId, body, currentLanguage, token }) =>
                updateUserAchievement({
                    achievementId,
                    body,
                    currentLanguage,
                    token,
                }),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        //TODO: -add types
        deleteUserAchievement: build.mutation<EmptyPayload, any>({
            query: ({ achievementId, currentLanguage, token }) =>
                deleteUserAchievement({
                    achievementId,
                    currentLanguage,
                    token,
                }),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        //TODO: -add types
        deleteUserAchievementDocument: build.mutation<EmptyPayload, any>({
            query: ({ achievementId, documentId }) =>
                deleteUserAchievementDocument({
                    achievementId,
                    documentId,
                }),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        //TODO: -add types
        setSpecialistServiceCategory: build.mutation<EmptyPayload, any>({
            query: ({ body }) =>
                setSpecialistServiceCategory({
                    body,
                }),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        //TODO: -add types
        setSpecialistServiceType: build.mutation<EmptyPayload, any>({
            query: ({ specialistService, body, currentLanguage, token }) =>
                setSpecialistServiceType({
                    specialistService,
                    body,
                    currentLanguage,
                    token,
                }),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        //TODO: -add types
        setSpecialistServiceSubType: build.mutation<EmptyPayload, any>({
            query: ({ specialistService, body, currentLanguage, token }) =>
                setSpecialistServiceSubType({
                    specialistService,
                    body,
                    currentLanguage,
                    token,
                }),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        setSpecialistNewService: build.mutation<
            EmptyPayload,
            {
                specialistService: number | string;
                body: FormData;
            }
        >({
            query: ({ specialistService, body }) =>
                setSpecialistNewService({
                    specialistService,
                    body,
                }),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        deleteSpecialistService: build.mutation<EmptyPayload, string | number>({
            query: (specialistServiceId: string | number) =>
                deleteSpecialistService(specialistServiceId),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        getCompanyEmployees: build.query<Array<CompanyEmployeesPayload>, void>({
            query: getCompanyEmployees,
            providesTags: [
                { type: "Profile", id: "FIELDS" },
                { type: "Localized" },
            ],
            transformResponse: (response: {
                description: string;
                payload: Array<CompanyEmployeesPayload>;
            }) => response.payload,
        }),
        setConfirmEmployee: build.mutation<
            EmptyPayload,
            {
                employeeId: number | string;
                body: { is_confirmed: boolean };
            }
        >({
            query: ({ employeeId, body }) =>
                setConfirmEmployee({
                    employeeId,
                    body,
                }),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        setAssignEmployeeManager: build.mutation<
            EmptyPayload,
            {
                employeeId: number | string;
            }
        >({
            query: ({ employeeId }) =>
                setAssignEmployeeManager({
                    employeeId,
                }),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        deleteEmployeeCompany: build.mutation<
            EmptyPayload,
            {
                employeeId: number | string;
            }
        >({
            query: ({ employeeId }) =>
                deleteEmployeeCompany({
                    employeeId,
                }),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        setManagerCompany: build.mutation<
            EmptyPayload,
            Record<string, unknown>
        >({
            query: (body) => setManagerCompany(body),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        setNewComplaint: build.mutation<EmptyPayload, ISetNewComplaintRequest>({
            query: (body) => setNewComplaint(body),
        }),
        getUserBonuses: build.query<UserBonusesPayload, unknown>({
            query: getUserBonuses,
            providesTags: [
                { type: "Profile", id: "FIELDS" },
                { type: "Localized" },
            ],
            transformResponse: (response: {
                description: string;
                payload: UserBonusesPayload;
            }) => response.payload,
        }),
        sendNotifyAdmin: build.mutation<unknown, { comment: string }>({
            query: (body) => sendNotifyAdmin(body),
        }),
        deleteSpecialistServicePhoto: build.mutation<
            unknown,
            { serviceId: number; photoId: number }
        >({
            query: (params) => deleteSpecialistServicePhoto(params),
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
        publishSpecialistProfile: build.mutation<unknown, void>({
            query: publishSpecialistProfile,
            invalidatesTags: [{ type: "Profile", id: "FIELDS" }],
        }),
    }),
});

export const {
    endpoints,
    useLazyGetProfileQuery,
    useChangePasswordMutation,
    useDeleteProfileMutation,
    useUpdateProfileMutation,
    useUpdateMutation,
    useDeleteAvatarMutation,
    useUpdateUserAddressMutation,
    useUpdateUserAboutMeMutation,
    useCreateUserAchievementMutation,
    useDeleteUserAchievementMutation,
    useUpdateUserAchievementMutation,
    useDeleteUserAchievementDocumentMutation,
    util,
    useSetSpecialistServiceCategoryMutation,
    useSetSpecialistServiceTypeMutation,
    useSetSpecialistServiceSubTypeMutation,
    useSetSpecialistNewServiceMutation,
    useSetConfirmEmployeeMutation,
    useSetAssignEmployeeManagerMutation,
    useDeleteEmployeeCompanyMutation,
    useDeleteSpecialistServiceMutation,
    useSetManagerCompanyMutation,
    useSetNewComplaintMutation,
    useSendNotifyAdminMutation,
    useDeleteSpecialistServicePhotoMutation,
    usePublishSpecialistProfileMutation,
} = userApi;

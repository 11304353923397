import React, { useEffect, useState } from "react";
import { useActions } from "../../hooks/actions";
import { Link, useNavigate } from "react-router-dom";
import Form from "../../components/Form/Form";
import Input from "../../components/Form/Input";
import { getRegisterInfo } from "../../store/registration/selectors";
import {
    checkReCaptcha,
    getDirectories,
    getUrl,
    isSpecialistUrl,
} from "../../store/directories/selectors";
import { useStoreProfileMutation } from "../../store/auth/auth.api";
import Confirmation from "../../components/Confirmation";
import LanguagesChecklist from "../../components/LanguagesChecklist";
import { ComponentProps } from "../../models/models";
import AvatarInput from "../../components/AvatarInput";
import { ProfilePayload } from "../../models/userModels";

interface Props extends ComponentProps {
    token?: string;
    changeStep: any;
    profile?: ProfilePayload;
    nextStep?: string;
    prevStep?: string;
}

const StepTwoRegister: React.FC<Props> = ({
    entities,
    changeStep,
    currentLanguage,
    profile,
    nextStep,
    prevStep,
}) => {
    const { updateField } = useActions();
    const { name, family_name, email, language_ids } = getRegisterInfo();
    const { updateLanguageIds } = useActions();
    const { data: directories } = getDirectories();
    const [photo, setPhoto] = useState<any>(null);
    const [update, { error, isSuccess, data }] = useStoreProfileMutation();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [bodyProfile, setBodyProfile] = useState<FormData | null>(null);
    const site_key = directories?.recaptcha?.site_key;
    const bonus = directories?.settings?.email_verification_bonus_amount || 0;
    const navigate = useNavigate();
    const isSpecialist = isSpecialistUrl();

    useEffect(() => {
        const isDraftSpecialist = profile?.is_draft;
        if (profile?.id && profile?.has_password && !isDraftSpecialist) {
            const url = `/${currentLanguage}/${profile?.user_role}/lk/profile`;

            navigate(url);
        }

        if (isDraftSpecialist) {
            updateField({ name: 'name', value: profile?.name || "" });
            updateField({ name: 'family_name', value: profile?.family_name || "" });
            updateField({ name: 'language_ids', value: profile?.language_ids || [] });
            setPhoto(profile?.avatar?.original_url);
        }
    }, [profile]);

    const url = (str: string) => getUrl(str);

    const onSuccess = () => {
        if (isSuccess && !email) {
            if (nextStep) {
                return changeStep(nextStep);
            }
            return changeStep("three");
        }
        setShowConfirm(true);
    };

    useEffect(() => {
        if (isSuccess) {
            onSuccess();
        }
    }, [isSuccess]);

    const registration_email_bonus =
        entities?.registration_email_bonus?.value.replace(
            ":bonus",
            String(bonus)
        );

    const sendStoreProfile = (str: string, body: any) => {
        body.append("g-recaptcha-response", str);
        update({
            body,
        });
    };

    const onSubmit = (e?: any, saveBody?: FormData | null) => {
        let body = new FormData();

        if (e) {
            e.preventDefault();
            body = new FormData(e.target);
        } else {
            if (saveBody) {
                body = saveBody;
            }
        }

        if (!photo) {
            if (Object.prototype.hasOwnProperty.call(body, "avatar")) {
                body.delete("avatar");
            }
        }
        setBodyProfile(body);
        if (site_key) {
            checkReCaptcha(site_key, "store_profile", (str) =>
                sendStoreProfile(str, body)
            );
        }
    };

    const changeField = (name: string, value: string) => {
        updateField({ name, value });
    };

    return showConfirm ? (
        <Confirmation
            entities={entities}
            email={email}
            changeStep={changeStep}
            data={data}
            currentLanguage={currentLanguage}
            handleReturn={() => setShowConfirm(false)}
            resendCode={(e: HTMLFormElement | null) => onSubmit(e, bodyProfile)}
            successStep={nextStep || "three"}
        />
    ) : (
        <div className="registration-form__content">
            <div className="registration-form__title-wrapper">
                <h1 className="title title--h1">
                    {entities?.registration_user_profile_title?.value}
                </h1>
            </div>
            <div className="form">
                <Form
                    entities={entities}
                    onSubmit={onSubmit}
                    error={error}
                    isSuccess={isSuccess}
                >
                    {(invalidFields, error) => (
                        <>
                            <AvatarInput
                                setPhoto={setPhoto}
                                photo={photo}
                                classes={["form__upload-foto"]}
                                userType={
                                    isSpecialist ? "specialist" : "customer"
                                }
                                entities={entities}
                            />
                            <div className="form__row">
                                <Input
                                    maxLength={70}
                                    type="text"
                                    onChange={(value: string) =>
                                        changeField("name", value)
                                    }
                                    id="name"
                                    value={name}
                                    classes={["form__field"]}
                                    name="name"
                                    placeholder={
                                        entities?.common_name?.value ?? ""
                                    }
                                    invalidFields={invalidFields}
                                    error={error}
                                />
                            </div>
                            <div className="form__row">
                                <Input
                                    type="text"
                                    onChange={(value: string) =>
                                        changeField("family_name", value)
                                    }
                                    id="family_name"
                                    value={family_name}
                                    classes={["form__field"]}
                                    name="family_name"
                                    maxLength={70}
                                    placeholder={
                                        entities?.common_family_name?.value ??
                                        ""
                                    }
                                    invalidFields={invalidFields}
                                    error={error}
                                />
                            </div>

                            <div className="form__row">
                                <Input
                                    type="email"
                                    onChange={(value: string) =>
                                        changeField("email", value)
                                    }
                                    id="email"
                                    value={email}
                                    classes={["form__field"]}
                                    name="email"
                                    placeholder={
                                        entities?.bonuses_and_partners_email
                                            ?.value ?? ""
                                    }
                                    invalidFields={invalidFields}
                                    error={error}
                                />
                            </div>
                            {!isSpecialist && (
                                <div className="form__row">
                                    <p className="form__note">
                                        {registration_email_bonus + " "}
                                        <Link
                                            to={url("bonuses_and_partners")}
                                            target="_blank"
                                            className="link link--underline link--hover-underline"
                                        >
                                            {
                                                entities
                                                    ?.registration_bonus_link
                                                    ?.value
                                            }
                                        </Link>
                                    </p>
                                </div>
                            )}
                            <div className="form__row form__row--h515">
                                <LanguagesChecklist
                                    languageIds={language_ids}
                                    entities={entities}
                                    onChange={(id: number) =>
                                        updateLanguageIds(id)
                                    }
                                    invalidFields={invalidFields}
                                    error={error}
                                />
                            </div>
                            <div className="form__row form__controls form__controls--full-width form__controls--not-fixed-sm">
                                <div
                                    className="btn btn btn--transparent registration-form__button"
                                    onClick={() =>
                                        changeStep(prevStep || "one")
                                    }
                                >
                                    {entities?.login_return_button?.value}
                                </div>
                                <button
                                    className="btn btn--transparent btn--bg-blue"
                                    type="submit"
                                >
                                    {entities?.login_continue_button?.value}
                                </button>
                            </div>
                        </>
                    )}
                </Form>
            </div>
        </div>
    );
};
export default StepTwoRegister;

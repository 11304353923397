import React, { useEffect, useState } from "react";
import AvatarInput from "../../../components/AvatarInput";
import { TranslationObject } from "../../../models/translationModal";
import { Link } from "react-router-dom";
import { getCurrentLanguage } from "../../../store/directories/selectors";

interface ProfileHeaderProps {
    name: string;
    familyName: string;
    visibleChangeAvatar?: boolean;
    userSpecialistRole?: { role: string; translate: string };
    entities?: TranslationObject;
    setPhoto: (value: string | null) => void;
    photo?: string | null;
    setAvaterIsDeleted: (value: boolean) => void;
    avaterIsDeleted: boolean
}

export const ProfileHeader: React.FC<ProfileHeaderProps> = ({
    name = "",
    familyName = "",
    visibleChangeAvatar = true,
    userSpecialistRole,
    entities,
    setPhoto,
    photo,
    setAvaterIsDeleted,
    avaterIsDeleted
}) => {
    const currentLanguage = getCurrentLanguage();

    const linkToOrders = `/${currentLanguage}/specialist/lk/orders`;

    return (
        <>
            <div className="specialist-profile__header grey-card specialist-profile__header-row">
                <div className="specialist-profile__header grey-card">
                    <div className="specialist-profile__avatar avatar-block">
                        <div className="avatar avatar--md">
                            <AvatarInput
                                setPhoto={setPhoto}
                                photo={photo}
                                userType={"specialist"}
                                visibleChangeAvatar={visibleChangeAvatar}
                                entities={entities}
                                setAvaterIsDeleted={setAvaterIsDeleted}
                                avaterIsDeleted={avaterIsDeleted}
                            />
                        </div>
                    </div>

                    <div className="specialist-profile__name">
                        <h2 className="title title--h2">{`${name} ${familyName}`}</h2>
                        <p className="text">{userSpecialistRole?.translate}</p>
                    </div>
                </div>
                <Link className="order-information__buttons" to={linkToOrders}>
                    <button
                        className="btn order-information__button"
                        type="button"
                    >
                        {entities?.orders_link_value?.value || ""}
                    </button>
                </Link>
            </div>
        </>
    );
};

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import SingleFilter from "./SingleFilter";
import MultipleFilter from "./MultipleFilter";
import { FilterBody, FiltersPayload } from "../../models/directoryModel";
import { OrderStepsProps } from "./index";
import Form from "../../components/Form/Form";
import { useNavigate, useParams } from "react-router-dom";
import { getCurrentLanguage } from "../../store/directories/selectors";
import {
    getItemCreateOrder,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import {
    FILTER_PAGES,
    SERVICE_CATEGORY_NAME,
    SERVICE_TYPE_NAME,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_SUB_TYPE_NAME,
    SERVICE_CATEGORY_SLUG,
    SERVICE_TYPE_SLUG,
    SERVICE_SUB_TYPE_SLUG
} from "../../store/order/constant";
import { useActions } from "../../hooks/actions";
import Advertising from "../../components/Advertising";
import { FilterProps } from "../../models/orderModel";
import CancelOrderButton from "./Specialists/CancelOrderButton";
import Breadcrumbs from "../../components/Breadcrumbs";

interface Props extends OrderStepsProps {
    filters: FiltersPayload[];
    setActiveFilter: Dispatch<SetStateAction<number>>;
    activeFilter: number;
}

const Filters: React.FC<Props> = ({
    entities,
    cancelOrder,
    onSubmit,
    error,
    filters,
    setActiveFilter,
    activeFilter,
    onSuccess,
    isSuccess,
    profile,
}) => {
    const currentLanguage = getCurrentLanguage();
    const navigate = useNavigate();
    const { updateCreateOrderField } = useActions();
    const [newData, setNewData] = useState(false);
    const currentFilters = getItemCreateOrder(FILTER_PAGES);
    const filterPages = getItemCreateOrder(FILTER_PAGES) || [];
    const serviceSubTypesIdsLength = getItemCreateOrder(SERVICE_SUB_TYPE_IDS)?.length;

    const categoryPath = getItemCreateOrder(SERVICE_CATEGORY_SLUG);
    const typePath = getItemCreateOrder(SERVICE_TYPE_SLUG);
    const subtypePath = getItemCreateOrder(SERVICE_SUB_TYPE_SLUG);

    useEffect(() => {
        if (activeFilter) {
            setNewData(true);
        }
    }, [activeFilter]);

    useEffect(() => {
        const items: FilterBody[] = [];
        if (currentFilters) {
            currentFilters?.forEach(
                (elem: FilterBody) =>
                    filters?.find(
                        (item: FiltersPayload) =>
                            item.id === elem.service_type_filter_id
                    ) && items.push(elem)
            );
            filters?.forEach(
                (filter: FiltersPayload) =>
                    !items?.find(
                        (elem: FilterBody) =>
                            elem.service_type_filter_id === filter.id
                    ) &&
                    items.push(
                        sampleFilter(filter?.has_custom_value, filter?.id)
                    )
            );
        } else {
            filters?.forEach((filter: FiltersPayload) =>
                items.push(sampleFilter(filter?.has_custom_value, filter?.id))
            );
        }

        if (items.length > 0) {
            updateItemCreateOrder(
                {
                    name: FILTER_PAGES,
                    value: items,
                },
                updateCreateOrderField
            );
        }
    }, [filters]);

    const sampleFilter = (withCustomValue = false, id: number) => {
        return withCustomValue
            ? {
                  service_type_filter_id: id,
                  service_type_filter_value_ids: [],
                  custom_filter_value: "",
              }
            : {
                  service_type_filter_id: id,
                  service_type_filter_value_ids: [],
              };
    };

    const _back = () => {
        if (activeFilter > 0) {
            return setActiveFilter(activeFilter - 1);
        }

        navigate(`/${currentLanguage}/customer/create-order/${categoryPath}/${typePath}/${subtypePath}`);
    };

    const _onSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        if (activeFilter == filters.length - 1) {
            setNewData(false);
            return onSubmit(e, "meeting-point");
        }
        setActiveFilter(activeFilter + 1);
        onSubmit(e, "filters");
        setNewData(false);
    };

    const _onSuccess = () => {
        const lastIndex = filters.length - 1;
        if (Number(activeFilter) === Number(lastIndex)) {
            setActiveFilter(0);
            return onSuccess("meeting-point");
        }
        onSuccess("filters");
    };

    useEffect(() => {
        if (isSuccess && !newData) {
            _onSuccess();
        }
    }, [isSuccess, newData]);

    const crumbs = [
        {
            name: getItemCreateOrder(SERVICE_CATEGORY_NAME) || entities?.service_task?.value,
            link: `create-order/${categoryPath}`
        },
        {
            name: getItemCreateOrder(SERVICE_TYPE_NAME) || entities?.type_of_service_2?.value,
            link: `create-order/${categoryPath}/${typePath || 'type'}`
        },
        {
            name: getItemCreateOrder(SERVICE_SUB_TYPE_NAME) || serviceSubTypesIdsLength && `${entities?.subtype_of_service_2?.value} (${serviceSubTypesIdsLength})` || entities?.subtype_of_service_2?.value,
            link: `create-order/${categoryPath}/${typePath || 'type'}/${subtypePath || 'subtype'}`
        },
        {
            active: true,
            name: entities?.service_filter?.value
        }
    ]

    const renderFilter = (type: string, filter: FiltersPayload) => {
        switch (type) {
            case "single":
                return (
                    <SingleFilter
                        entities={entities}
                        filter={filter}
                        key={filter.id}
                        isEdit={false}
                    />
                );
            case "multiple":
                return (
                    <MultipleFilter
                        entities={entities}
                        filter={filter}
                        key={filter.id}
                        isEdit={false}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            {filters?.map((filter: FiltersPayload, key: number) => {
                const currentFilter = filterPages?.find(
                    (elem: FilterProps) =>
                        elem?.service_type_filter_id === filter?.id
                );
                const isDisabled =
                    (currentFilter?.service_type_filter_value_ids &&
                        currentFilter?.service_type_filter_value_ids?.length <
                            1) ||
                    false;

                if (key == activeFilter) {
                    return (
                        <div className="inner-container" key={filter?.id}>
                            <div className="new-order__wrapper">
                                <Breadcrumbs
                                    crumbs={crumbs}
                                />
                                <div className="new-order__header">
                                    <h2 className="title title--f46">
                                        {filter?.name}
                                    </h2>
                                    <CancelOrderButton
                                        profile={profile}
                                        entities={entities}
                                    />
                                </div>
                                <div className="new-order__form form">
                                    <Form
                                        onSubmit={(
                                            e: React.FormEvent<HTMLInputElement>
                                        ) => _onSubmit(e)}
                                        entities={entities}
                                        isSuccess={isSuccess}
                                    >
                                        {() => (
                                            <>
                                                {renderFilter(
                                                    filter?.type,
                                                    filter
                                                )}
                                                <div className="form__row form__controls">
                                                    <CancelOrderButton
                                                        profile={profile}
                                                        entities={entities}
                                                    />
                                                    <button
                                                        className="btn form__back-link btn--transparent"
                                                        type="button"
                                                        onClick={_back}
                                                    >
                                                        {
                                                            entities
                                                                ?.common_back_button
                                                                ?.value
                                                        }
                                                    </button>
                                                    <button
                                                        className="btn btn--transparent btn--bg-blue"
                                                        type="submit"
                                                        disabled={isDisabled}
                                                    >
                                                        {
                                                            entities
                                                                ?.login_continue_button
                                                                ?.value
                                                        }
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </Form>
                                </div>
                            </div>
                            <ul className="advertising-list create-order">
                                <Advertising
                                    countCards={5}
                                    entities={entities}
                                    isHorizontal={true}
                                />
                            </ul>
                        </div>
                    );
                } else {
                    return null;
                }
            })}
        </>
    );
};

export default Filters;

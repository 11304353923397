import React from "react";
import cn from "classnames";

interface LoaderProps {
    type?: "fullScreen" | "container";
}

const Loader: React.FC<LoaderProps> = ({ type }) => {
    return (
        <main>
            <div
                className={cn("loader", {
                    fullScreen: type === "fullScreen",
                })}
            >
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                </div>
            </div>
        </main>
    );
};

export default Loader;

import React from "react";
import Input from "../../../components/Form/Input";
import { useActions } from "../../../hooks/actions";
import { getFilter } from "../../../store/orders/selectors";

interface SearchOrdersProps {
    disabled?: boolean;
}

const SearchOrders: React.FC<SearchOrdersProps> = ({ disabled = false }) => {
    const name = "search";
    const search = getFilter(name);
    const { setOrdersFilter } = useActions();

    const onChange = (value: string) => {
        setOrdersFilter({
            name,
            value,
        });
    };

    return (
        <div className="list-orders__search-input">
            <Input
                type={name}
                value={search}
                onChange={onChange}
                placeholder=""
                icon={{ type: name, width: 24, height: 24 }}
                disabled={disabled}
            />
        </div>
    );
};

export default SearchOrders;

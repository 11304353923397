import { useSelector } from "react-redux";
import { RootState } from "../index";
import { OrderSpecialistItem } from "../../models/orderModel";
import {
    CurrentSpecialistsPayload,
    SpecialistPayload,
} from "../../models/userModels";
import { TranslationObject } from "../../models/translationModal";

export const getUserRole = () =>
    useSelector((state: RootState) => state.user.user_role);

export const getTkn = () => useSelector((state: RootState) => state.user.tkn);

export const getIsAuth = () => useSelector((state: RootState) => !!state.user.tkn);

export const getUserEmail = () =>
    useSelector((state: RootState) => state.user?.email);

export const getUrlAfterAuthorization = () =>
    useSelector((state: RootState) =>
        state.user?.urlAfterAuthorization &&
        state.user?.urlAfterAuthorization?.length > 0
            ? state.user?.urlAfterAuthorization
            : null
    );

export const getProfile = () => useSelector((state: RootState) => state.user);

export const getRoleCompanyUser = ({
    specialist,
    entities,
}: {
    specialist?: SpecialistPayload;
    entities?: TranslationObject;
}) => {
    const company = specialist?.company;
    const companyManager = specialist?.is_company_manager;

    if (company && companyManager) {
        return entities?.specialist_details_company_manager?.value ?? "";
    }

    if (company && !companyManager) {
        return entities?.specialist_details_company_employee?.value ?? "";
    }

    return entities?.specialist_details_individual_person?.value ?? "";
};

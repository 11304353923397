import React, { useState } from "react";
import { TranslationObject } from "../../models/translationModal";
import { useSuspendedAccess } from "../../hooks/useSuspendedAccess";
import { NotifyAdministratorModal } from "../Modals/NotifyAdministratorModal";
import cn from "classnames";
import { isSpecialistUrl } from "../../store/directories/selectors";
import { useLocation, useSearchParams } from "react-router-dom";

interface SuspendedNotificationProps {
    entities?: TranslationObject;
}

export const showPagesListIsSuspended: string[] = [
    "profile",
    "orders",
    "notifications",
    "reviews",
];

const invalidSearchParams = [
    {
        name: "type",
        value: "service",
    },
];

export const SuspendedNotification: React.FC<SuspendedNotificationProps> = ({
    entities,
}) => {
    const {
        isSuspendedUser,
        suspendedText,
        showButtonEliminareComments,
        sendNotifyAdmin,
    } = useSuspendedAccess(entities);

    const [searchParams] = useSearchParams();

    const { pathname } = useLocation();

    const validShowPage = showPagesListIsSuspended.some((page) =>
        pathname.includes(page)
    );

    const isExistNotValidSearchParams = invalidSearchParams.find(
        (param) => searchParams.get(param.name) === param.value
    );

    const isSpecialist = isSpecialistUrl();

    const [showNotifyModal, setShowNotifyModal] = useState<boolean>(false);

    const submitNotifyAdministratorModal = (comment: string) => {
        if (comment) {
            sendNotifyAdmin({ comment });
            setShowNotifyModal(false);
        }
    };

    if (!isSuspendedUser || !validShowPage || isExistNotValidSearchParams) {
        return null;
    }

    return (
        <>
            <NotifyAdministratorModal
                entities={entities}
                show={showNotifyModal}
                onClose={() => setShowNotifyModal(false)}
                onSubmitModal={submitNotifyAdministratorModal}
                isSpecialistOnUrl={isSpecialist}
            />
            <div className="cookies suspended-notification">
                <div className="cookies__container">
                    {suspendedText && (
                        <p
                            className="cookies__text"
                            dangerouslySetInnerHTML={{ __html: suspendedText }}
                        />
                    )}
                    {showButtonEliminareComments && (
                        <button
                            className={cn(
                                "btn btn--rounded eliminare-comments__btn",
                                {
                                    "btn--green": isSpecialist,
                                }
                            )}
                            type="button"
                            onClick={() => setShowNotifyModal(true)}
                        >
                            {entities?.suspension_issues_fixed?.value}
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

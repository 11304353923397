import React, { useEffect, useState } from "react";
import { SpecialistServiceSubTypes } from "../../../components/NewSpecialistService/SpecialistServiceSubTypes";
import { SpecialistServiceTypes } from "../../../components/NewSpecialistService/SpecialistServiceTypes";
import { SpecialistServices } from "../../../components/NewSpecialistService/SpecialistServices";
import { SpecialistServiceDescriptionNew } from "../../../components/NewSpecialistService/SpecialistServiceDescriptionNew";
import { TranslationObject } from "../../../models/translationModal";
import { QuestionAiTypeObject, IProfileTag } from "../../../models/orderModel";

export type NewServiceStepTypes =
    | "category"
    | "type"
    | "subType"
    | "description";

type StepListType = Record<NewServiceStepTypes, React.ReactNode>;

export type ChangeStepType = (
    step: NewServiceStepTypes,
    hide?: boolean
) => unknown;

type servicePayload = any;

interface SpecialistNewServiceProps {
    entities?: TranslationObject;
    step: NewServiceStepTypes;
    changeStep: ChangeStepType;
    eventClearPreService?: () => unknown;
    serviceEditPayload?: servicePayload;
    tags?: QuestionAiTypeObject;
    tag?: IProfileTag;
}

export const SpecialistNewService: React.FC<SpecialistNewServiceProps> = (
    props
) => {
    const {
        step = "category",
        changeStep,
        eventClearPreService,
        serviceEditPayload,
        entities,
        tags,
        tag,
    } = props || {};

    const [servicePayload, setServicePayload] = useState<servicePayload>({});

    useEffect(() => {
        if (serviceEditPayload) {
            setServicePayload(serviceEditPayload);
        }
    }, [serviceEditPayload]);

    const stepList: StepListType = {
        category: (
            <SpecialistServices
                entities={entities}
                setServicePayload={setServicePayload}
                changeStep={changeStep}
                eventClearPreService={eventClearPreService}
                categoryId={(tags && tags?.category) ? tags.category : ''}
                categoryName={(tags && tags?.categoryName) ? tags.categoryName : ''}
            />
        ),
        type: (
            <SpecialistServiceTypes
                entities={entities}
                setServicePayload={setServicePayload}
                changeStep={changeStep}
                eventClearPreService={eventClearPreService}
                activeSpecialistServices={servicePayload?.id || null}
                activeCategory={servicePayload?.service_category?.id || null}
                activeCategoryName={servicePayload?.service_category?.name || null}
                type={(tags && tags?.type) ? tags.type : ''}
                typeName={(tags && tags?.typeName) ? tags.typeName : ''}
            />
        ),
        subType: (
            <SpecialistServiceSubTypes
                entities={entities}
                setServicePayload={setServicePayload}
                changeStep={changeStep}
                eventClearPreService={eventClearPreService}
                activeSpecialistServices={servicePayload?.id || null}
                activeCategory={servicePayload?.service_category?.id || null}
                activeType={
                    servicePayload?.custom_service_type ||
                    servicePayload?.service_type?.id ||
                    null
                }
                activeCategoryName={servicePayload?.service_category?.name || null}
                activeTypeName={servicePayload?.service_type?.name || null}
                activeTag={tag}
            />
        ),
        description: (
            <SpecialistServiceDescriptionNew
                entities={entities}
                changeStep={changeStep}
                servicePayload={servicePayload}
            />
        ),
    };

    return (
        <section className="service-page">
            {stepList[step as keyof StepListType]}
        </section>
    );
};

import { useEffect } from "react";

export const useHistoryBackHandler = (callback?: () => unknown) => {
    useEffect(() => {
        if (callback) {
            const historyCurrentPage = callback;
            history.pushState(null, document.title, location.href);
            window.addEventListener("popstate", historyCurrentPage);

            return () =>
                window.removeEventListener("popstate", historyCurrentPage);
        }
    }, []);
};

import React, { ChangeEvent, useEffect, useState } from "react";
import Input from "../../components/Form/Input";
import {
    checkReCaptcha,
    getDirectories,
    getPath,
    getUrl,
} from "../../store/directories/selectors";
import { Link } from "react-router-dom";
import Form from "../../components/Form/Form";
import Checkbox from "../../components/Form/Checkbox";
import { useSendPhoneConfirmationMutation } from "../../store/auth/auth.api";
import Confirmation from "../../components/Confirmation";
import { getRegisterInfo } from "../../store/registration/selectors";
import { useActions } from "../../hooks/actions";
import { ComponentProps } from "../../models/models";
import { endpoints } from "../../store/user/user.api";
import { getTkn } from "../../store/user/selectors";

interface Props extends ComponentProps {
    changeStep: any;
    nextStep?: string;
    isSpecialist?: boolean;
}

const StepOneRegister: React.FC<Props> = ({
    entities,
    changeStep,
    isCustomerOnUrl,
    currentLanguage,
    nextStep = "two",
    isSpecialist = false,
}) => {
    const { phone, user_agreement, privacy_policy_agreement  } =
        getRegisterInfo();
    const { updateField } = useActions();
    const { data: directories } = getDirectories();
    const token = getTkn();
    const site_key = directories?.recaptcha?.site_key;
    const [isSentCode, setIsSentCode] = useState<boolean>(false);
    const path = getPath();
    const [updateProfile] = endpoints.getProfile.useLazyQuery();
    const [update, { data, isSuccess, error }] =
        useSendPhoneConfirmationMutation();

    useEffect(() => {
        if (token) {
            updateProfile();
        }
    }, [token]);

    const sendPhone = (token: string) => {
        update({
            body: {
                user_role: path[1],
                user_agreement,
                privacy_policy_agreement,
                "g-recaptcha-response": token,
                phone
            },
        });
    };

    const handleChangeStep = (str: string | boolean) => {
        typeof str === "string" ? changeStep(nextStep) : setIsSentCode(false);
    };

    const url = (str: string) => getUrl(str);

    const onSuccess = () => {
        if (isSuccess) {
            setIsSentCode(true);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            onSuccess();
        }
    }, [isSuccess]);

    const handleChangePhone = (value: string) => {
        if (String(value).length > 15) return;

        if (phone && phone[0] !== "+") {
            updateField({
                name: "phone",
                value: "+" + value,
            });
        }
        updateField({
            name: "phone",
            value,
        });
    };

    const onSubmit = (e: ChangeEvent<HTMLFormElement>) => {
        if (e) {
            e.preventDefault();
        }
        if (site_key) {
            checkReCaptcha(site_key, "send_phone_confirmation", sendPhone);
        }
    };

    const updateCheckBox = (name: string, value: boolean) => {
        updateField({
            name,
            value,
        });
    };

    return isSentCode ? (
        <Confirmation
            data={data}
            resendCode={onSubmit}
            entities={entities}
            phone={phone}
            currentLanguage={currentLanguage}
            changeStep={handleChangeStep}
            handleReturn={() => setIsSentCode(false)}
        />
    ) : (
        <div className="registration-form__content">
            <div className="registration-form__title-wrapper">
                <h1 className="title title--h1">
                    {isCustomerOnUrl
                        ? entities?.registration_title?.value
                        : entities?.register_specialist_title?.value}
                </h1>
            </div>
            <Form
                entities={entities}
                onSubmit={onSubmit}
                error={error}
                isSuccess={isSuccess}
            >
                {(invalidFields, error) => (
                    <div className="registration-form__form-wrapper">
                        <div className="registration-form__password-input authorization__form-input authorization__form-input--password">
                            <Input
                                type="phone"
                                onChange={handleChangePhone}
                                id="phone"
                                value={phone}
                                classes={[
                                    "registration-form__input-phone",
                                    `${
                                        isSpecialist
                                            ? "custom-input--green"
                                            : ""
                                    }`,
                                ]}
                                name="phone"
                                placeholder="+"
                                required
                                invalidFields={invalidFields}
                                error={error}
                            />
                            <div className="form__policy">
                                <Checkbox
                                    classes={
                                        isSpecialist
                                            ? "custom-toggle--green"
                                            : ""
                                    }
                                    invalidFields={invalidFields}
                                    error={error}
                                    name="user_agreement"
                                    onChange={() =>
                                        updateCheckBox(
                                            "user_agreement",
                                            !user_agreement
                                        )
                                    }
                                    value={user_agreement}
                                >
                                    {entities?.registration_agree_with?.value}
                                    <Link
                                        to={url("user-agreement")}
                                        className="link link--hover-underline "
                                    >
                                        {
                                            entities
                                                ?.registration_user_agreement_link
                                                ?.value
                                        }
                                    </Link>
                                </Checkbox>
                                <Checkbox
                                    classes={
                                        isSpecialist
                                            ? "custom-toggle--green"
                                            : ""
                                    }
                                    invalidFields={invalidFields}
                                    error={error}
                                    name="privacy_policy_agreement"
                                    onChange={() =>
                                        updateCheckBox(
                                            "privacy_policy_agreement",
                                            !privacy_policy_agreement
                                        )
                                    }
                                    value={privacy_policy_agreement}
                                >
                                    {entities?.registration_agree_with?.value}
                                    <Link
                                        to={url("privacy-policy")}
                                        className="link link--hover-underline"
                                    >
                                        {entities
                                            ?.registration_privacy_policy_link
                                            ?.value + " "}
                                    </Link>
                                </Checkbox>
                            </div>
                        </div>

                        <div className="form__row form__controls form__controls--medium-width form__controls--not-fixed-sm">
                            <button
                                className="btn btn--transparent btn--bg-blue"
                                type="submit"
                            >
                                {entities?.login_continue_button?.value}
                            </button>
                        </div>
                    </div>
                )}
            </Form>
        </div>
    );
};

export default StepOneRegister;

import { RootState } from "../index";

export const chatMessagesSelector = (state: RootState) =>
    state.support.chatMessages;

export const isActiveChatSelector = (state: RootState) =>
    state.support.isActiveChat;

export const supportChatIdSelector = (state: RootState) =>
    state.support.chatId;

export const chatMessagesNotAuthSelector = (state: RootState) =>
    state.support.msgNotAuth;

export const isSupportCountMsgMaxSelector = (state: RootState) =>
    state.support.msgNotAuth.length >= state.support.maxCountMessage;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    AddressByCoordinatesResult,
    AddressByCoordinatesRootObject,
} from "../../models/googleModel";
import { LocationData } from "../../models/orderModel";
import { getAddressByCoordinates } from "./api";

export const exceptionsApi = createApi({
    reducerPath: "exceptions/api",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
    }),
    endpoints: (build) => ({
        getAddressByCoordinates: build.query<
            AddressByCoordinatesResult[],
            { location: LocationData; key: string }
        >({
            query: ({ location, key }) =>
                getAddressByCoordinates({
                    location,
                    key,
                }),
            transformResponse: (response: AddressByCoordinatesRootObject) =>
                response?.results,
        }),
    }),
});

export const { endpoints } = exceptionsApi;

import React, { useEffect, useState } from "react";
import { TranslationObject } from "../../../models/translationModal";
import { OrderItem } from "../../../models/ordersModel";
import { Loader } from '@googlemaps/js-api-loader';
import { getDirectories } from "../../../store/directories/selectors";

interface Props {
    order?: OrderItem;
    entities?: TranslationObject;
    isSpecialist: boolean;
}

const DetailInfo: React.FC<Props> = ({ entities, order, isSpecialist }) => {
    const key = process.env.NODE_ENV !== "production"
            ? "AIzaSyDbBAyAIysP6PdbqEnANpJazjoC8vwh1gk"
            : getDirectories()?.data?.google_maps?.site_api_key;
    const [customerAddress, setCustomerAddress] = useState('');
    const description = order?.description;
    const price = order?.price;
    const isRemote = order?.is_remote;
    const isMyAddress = order?.is_my_address;
    const isParentAddress = !!order?.parent_address?.address;
    const isSpecialistAddress = order?.is_specialist_address;
    const isSpecialistParentAddress = !!order?.specialist_parent_address?.address;
    const isDeal = order?.status === "deal";
    const isDone = order?.status === "done";

    useEffect(() => {
        const orderCustomerAddress = (order?.my_address?.address || order?.parent_address?.address) || "";
        const latitude = (order?.my_address?.latitude || order?.parent_address?.latitude) || "";
        const longitude = (order?.my_address?.longitude || order?.parent_address?.longitude) || "";
        let address = entities?.common_customer_adress?.value || '';

        if (isDeal || isDone) {
            address = `${address}${orderCustomerAddress ? (', ' + orderCustomerAddress) : ''}`
        }

        setCustomerAddress(address);

        if (!(isDeal || isDone) && !!key) {
            const loader = new Loader({ apiKey: key });

            if (loader) {
                loader.load().then((google) => {
                    const geocoder = new google.maps.Geocoder();

                    if (orderCustomerAddress && latitude && longitude) {
                        geocoder.geocode({ 
                            location: {
                                lat: latitude,
                                lng: longitude,
                            },
                        }, (results, status) => {
                            if (status !== 'OK' && !results || !results?.length) {
                                setCustomerAddress('');
                                return;
                            }
    
                            let shortAddress = `${entities?.common_customer_adress?.value}, locality, postal_code, country`;
                            const addressComponents = results?.[0]?.address_components || [];
    
                            addressComponents.forEach((item) => {
                                if (item.types.includes("locality")) {
                                    shortAddress = shortAddress.replace("locality,", `${item.long_name},`);
                                }
                                if (item.types.includes("postal_code")) {
                                    shortAddress = shortAddress.replace("postal_code,", `${item.long_name},`);
                                }
                                if (item.types.includes("country")) {
                                    shortAddress = shortAddress.replace("country", `${item.long_name}`);
                                }
                            });
    
                            shortAddress = shortAddress.replace("postal_code,", '');
                            shortAddress = /locality|country/.test(shortAddress) ? address : shortAddress;
    
                            setCustomerAddress(shortAddress);
                        });
                    }
                });
            }
        }
    }, []);

    return (
        <div className="order-information-details">
            <div className="order-information-details__content">
                <ul className="order-information-details__content-list">
                    <li className="order-information-details__content-item">
                        <p className="order-information-details__content-item-heading">
                            {entities?.order_services?.value}
                        </p>
                        <ul className="order-information-details__content-services-list">
                            <li className="order-information-details__content-services-item">
                                {order?.service_category?.name}
                            </li>
                            <li className="order-information-details__content-services-item">
                                {order?.service_type?.name ||
                                    order?.custom_service_type}
                            </li>
                            {order?.service_sub_types &&
                            order?.service_sub_types?.length > 0 ? (
                                order?.service_sub_types?.map((subtype) => (
                                    <li
                                        className="order-information-details__content-services-item"
                                        key={subtype?.id}
                                    >
                                        {subtype?.name}
                                    </li>
                                ))
                            ) : (
                                <li className="order-information-details__content-services-item">
                                    {order?.custom_service_type}
                                </li>
                            )}
                        </ul>
                    </li>
                    <li className="order-information-details__content-item">
                        <p className="order-information-details__content-item-heading">
                            {entities?.order_additional_data_price?.value}
                        </p>
                        <p className="order-information-details__content-services-item">
                            {price}
                        </p>
                    </li>
                    <li className="order-information-details__content-item">
                        <p className="order-information-details__content-item-heading">
                            {entities?.order_comment?.value}
                        </p>
                        <p className="order-information-details__content-services-item">
                            {description}
                        </p>
                    </li>
                    <li className="order-information-details__content-item">
                        <p className="order-information-details__content-item-heading">
                            {entities?.order_select_location_title?.value + "?"}
                        </p>
                        <ul className="order-information-details__content-services-list">
                            {isRemote && (
                                <li className="order-information-details__content-services-item">
                                    {entities?.common_remotely?.value}
                                </li>
                            )}
                            {(isMyAddress || isParentAddress)&& (
                                <li className="order-information-details__content-services-item">
                                    {isSpecialist ? customerAddress :
                                        `${entities?.common_my_address?.value}${(order?.my_address?.address ||
                                            order?.parent_address?.address) ? (', ' + (order?.my_address?.address
                                                || order?.parent_address?.address)) : ''}` }
                                </li>
                            )}
                            {(isSpecialistAddress || isSpecialistParentAddress) && (
                                <li className="order-information-details__content-services-item">
                                    {isSpecialist
                                        ? entities?.common_my_address?.value
                                        : entities?.common_specialist_adress
                                              ?.value}
                                    , {order?.specialist_address?.address || order?.specialist_parent_address?.address || ""}
                                </li>
                            )}
                        </ul>
                    </li>

                    {order?.filter_pages?.map((elem) => (
                        <li
                            className="order-information-details__content-item"
                            key={elem?.id}
                        >
                            <p className="order-information-details__content-item-heading">
                                {elem?.service_type_filter?.name}
                            </p>
                            <ul className="order-information-details__content-services-list">
                                <li className="order-information-details__content-services-item">
                                    {elem?.filter_values
                                        ?.map((item) => item?.name)
                                        ?.join(", ")}
                                    {elem?.custom_filter_value &&
                                        " " +
                                            entities
                                                ?.order_filter_custom_answer_details
                                                ?.value +
                                            ": " +
                                            elem?.custom_filter_value}
                                </li>
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default DetailInfo;

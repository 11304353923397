import Checkbox from "../Form/Checkbox";
import React, { useEffect, useState } from "react";
import { TranslationObject } from "../../models/translationModal";
import {
    getLanguagesOptions,
    isSpecialistUrl,
} from "../../store/directories/selectors";
import { Language } from "../../models/directoryModel";
import classNames from "classnames";
import { InvalidFieldsProps } from "../Form/Input";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { ProfilePayload } from "../../models/userModels";

const LanguagesChecklist: React.FC<{
    search?: string;
    languageIds: number[];
    entities?: TranslationObject;
    onChange: (value: number) => void;
    invalidFields?: InvalidFieldsProps;
    error?: any;
    classes?: string;
    profile?: ProfilePayload;
    fromSelectedLanguages?: boolean;
    isHideChecked?: boolean;
}> = ({
    languageIds,
    entities,
    onChange,
    invalidFields,
    error,
    classes,
    search,
    profile,
    isHideChecked
}) => {
    const { data: languages } = getLanguagesOptions();
    const [listLanguages, setListLanguages] = useState<Language[]>([]); // if hide see 3 first lang
    const [checkListLangs, setCheckListLangs] = useState<Language[]>([]);
    const [showMoreLanguages, setShowMoreLanguages] = useState<boolean>(false);
    const legend = entities?.registration_languages_2?.value
    const legendSelect = entities?.registration_languages_selected?.value
    const isSpecialist = isSpecialistUrl();
    const varsLanguages: Language[] | undefined = profile?.languages ||  languages; // all langs

    useEffect(() => {
        if (varsLanguages && search && search?.length > 0) {
            return setListLanguages(
                varsLanguages.filter((language) =>
                    language?.name
                        ?.toLowerCase()
                        ?.includes(search.toLowerCase())
                )
            );
        }

        if (showMoreLanguages) {
            return setListLanguages(varsLanguages || []);
        }
        setListLanguages(varsLanguages?.slice(0, 3) || []);
    }, [varsLanguages, showMoreLanguages, search]);

    useEffect(() => {
        if (varsLanguages && Array.isArray(varsLanguages) && languageIds && Array.isArray(languageIds)) {
            setCheckListLangs(varsLanguages.filter((lang: Language) => {
                return languageIds.includes(lang.id)
            }))
        }

    }, [varsLanguages, languageIds]);

    return (
        <>
            {(!!checkListLangs.length && !isHideChecked) && <fieldset>
                <legend className="choose-your-language">{legendSelect}</legend>
                <div className={classNames(`form__row ${classes}`)}>
                    {checkListLangs?.map((language, key) => (
                        <Checkbox
                            classes={isSpecialist ? "custom-toggle--green" : ""}
                            key={key}
                            value={languageIds?.includes(language?.id)}
                            invalidFields={invalidFields}
                            onChange={() => onChange(language?.id)}
                            error={error}
                            disabled={listLanguages.length < 2}
                        >
                            {language?.name}
                        </Checkbox>
                    ))}
                </div>
            </fieldset>}
            <fieldset>
                <legend className="choose-your-language">{legend}{legend && <span className="asterisk">*</span>}</legend>
                {languageIds?.map((id, key) => (
                    <input
                        type="hidden"
                        value={id}
                        key={key}
                        name={`language_ids[${key}]`}
                    />
                ))}
                <SimpleBar autoHide={false} forceVisible={true} style={{ maxHeight: 500, height: '100%' }}>
                    <div className={classNames(`form__row ${classes}`)}>
                        {listLanguages?.map((language, key) => (
                            <Checkbox
                                classes={isSpecialist ? "custom-toggle--green" : ""}
                                key={key}
                                value={languageIds?.includes(language?.id)}
                                invalidFields={invalidFields}
                                onChange={() => onChange(language?.id)}
                                error={error}
                                disabled={listLanguages.length < 2}
                            >
                                {language?.name}
                            </Checkbox>
                        ))}
                    </div>
                </SimpleBar>
            </fieldset>

            {varsLanguages && varsLanguages?.length > 3 && <button
                className="link link--underline"
                type="button"
                onClick={() => setShowMoreLanguages(!showMoreLanguages)}
            >
                {!showMoreLanguages
                    ? entities?.registration_other_languages?.value
                    : entities?.registration_hide_languages?.value}
            </button>}
        </>
    );
};
export default LanguagesChecklist;

import MyAddressModal from "../Modals/MyAddressModal";
import SpecialistAddressModal from "../Modals/SpecialistAddressModal";
import CheckboxAddress from "../../../../components/CheckboxAddress";
import React, { Dispatch, SetStateAction } from "react";
import { TranslationObject } from "../../../../models/translationModal";
import { LocationData, locationProps } from "../../../../models/orderModel";
import { createOrderState } from "../../../../store/order/createOrder.slice";

interface Props {
    saveMyAddress: (location: locationProps) => void;
    setShowMyAddressModal: Dispatch<SetStateAction<boolean>>;
    showMyAddressModal: boolean;
    entities?: TranslationObject;
    saveSpecialistAddress: (location: locationProps) => void;
    setShowSpecialistAddressModal: Dispatch<SetStateAction<boolean>>;
    showSpecialistAddressModal: boolean;
    currentAddresses: string[];
    handleChange: (value: keyof createOrderState) => void;
    clearAddress: (value: string) => void;
    myLocation?: LocationData | null;
    specialistLocation?: LocationData | null;
    isEdit?: boolean;
}

const BaseContainerFilterMeetingPointOrder: React.FC<Props> = ({
    saveMyAddress,
    setShowMyAddressModal,
    showMyAddressModal,
    entities,
    saveSpecialistAddress,
    setShowSpecialistAddressModal,
    showSpecialistAddressModal,
    currentAddresses,
    handleChange,
    clearAddress,
    myLocation,
    specialistLocation,
    isEdit
}) => {
    return (
        <>
            <MyAddressModal
                save={saveMyAddress}
                close={() => setShowMyAddressModal(false)}
                entities={entities}
                showMyAddressModal={showMyAddressModal}
                isSpecialist={false}
                isEdit={isEdit}
            />

            <SpecialistAddressModal
                save={saveSpecialistAddress}
                close={() => setShowSpecialistAddressModal(false)}
                entities={entities}
                isSpecialist={false}
                isEdit={isEdit}
                showSpecialistAddressModal={showSpecialistAddressModal}
                isOpenOrder={true}
            />

            <div className="toggle-place">
                <ul className="toggle-place__list">
                    <CheckboxAddress
                        entities={entities}
                        currentAddresses={currentAddresses}
                        handleChange={handleChange}
                        withAddress
                        clearAddress={clearAddress}
                        myLocation={myLocation}
                        specialistLocation={specialistLocation}
                    />
                </ul>
            </div>
        </>
    );
};

export default BaseContainerFilterMeetingPointOrder;

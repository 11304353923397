import { request } from "../request";
import {
    createNewChatRequestData,
    GetChatMessagesRequest,
    makeOfferOrderRequestData,
    SendMessageToChatRequest,
    ViewChatMessageRequest,
} from "../../models/chatModels";

export const createNewChat = (requestData: createNewChatRequestData) =>
    request({
        url: `/chats`,
        method: "POST",
        body: requestData,
    });

export const getChatInfo = (chatId: string | number) =>
    request({
        url: `/chats/${chatId}`,
        method: "GET",
    });

export const pinChat = (chatId: string | number) =>
    request({
        url: `/chats/${chatId}/pin`,
        method: "PUT",
    });

export const unpinChat = (chatId: string | number) =>
    request({
        url: `/chats/${chatId}/unpin`,
        method: "PUT",
    });

export const sendMessageToChat = ({ chatId, body }: SendMessageToChatRequest) =>
    request({
        url: `/chats/${chatId}/messages/send`,
        method: "POST",
        body,
    });

export const getChatMessages = ({ chatId, params }: GetChatMessagesRequest) =>
    request({
        url: `/chats/${chatId}/messages`,
        method: "GET",
        params,
    });

export const viewChatMessage = ({
    messageId,
    chatId,
}: ViewChatMessageRequest) =>
    request({
        url: `/chats/messages/${messageId}/view`,
        method: "PUT",
        body: {
            chat: chatId,
            message: messageId,
        },
    });

export const makeOfferOrder = ({ orderId, body }: makeOfferOrderRequestData) =>
    request({
        url: `orders/${orderId}/make_offer`,
        body,
        method: "POST",
    });

export const acceptAndContinueSearch = (orderOfferId: number) =>
    request({
        url: `order_offers/${orderOfferId}/accept_and_continue_search`,
        method: "POST",
    });

export const acceptAndStopSearch = (orderOfferId: number) =>
    request({
        url: `order_offers/${orderOfferId}/accept_and_stop_search`,
        method: "POST",
    });

export const declineOfferOrder = (orderOfferId: number) =>
    request({
        url: `order_offers/${orderOfferId}/decline`,
        method: "POST",
    });

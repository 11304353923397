import { LocationData } from "../../models/orderModel";
import { request } from "../request";

export const getAddressByCoordinates = ({
    location,
    key,
}: {
    location: LocationData;
    key: string;
}) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
        location.latitude + "," + location.longitude
    }&key=${key}`;
    return request({
        url,
        method: "GET",
    });
};

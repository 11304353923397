import React from "react";
import Input from "../../../../components/Form/Input";
import DateInput from "../../../../components/DateInput";
import { convertDateFormatISO, convertDateToJSDate } from "../../../../untils";
import { useSelector } from "react-redux";
import { datesFilterSelector } from "../../../../store/finances/finances.selectors";
import { useActions } from "../../../../hooks/actions";
import { TranslationObject } from "../../../../models/translationModal";

interface DateInputsProps {
    entities?: TranslationObject;
    errorsValidaions?: string[],
    clearValidationError: (value: string) => void
}

export const DateInputs: React.FC<DateInputsProps> = ({ entities, errorsValidaions, clearValidationError }) => {
    const { setDateFilter, setCurrentPage } = useActions();

    const { dateTo, dateFrom } = useSelector(datesFilterSelector);

    const onChangeDateInput = (
        value: Date,
        typeInput: "dateFilterFrom" | "dateFilterTo"
    ) => {
        clearValidationError(typeInput === 'dateFilterTo' ? 'dateTo' : 'dateFrom')
        const date = convertDateFormatISO(value);
        setDateFilter({ type: typeInput, value: date });
        setCurrentPage(1);
    };

    const dateInputsClassName = "date-field custom-input--small"
    const hasErrorDateTo = errorsValidaions?.indexOf("dateTo") !== -1
    const hasErrorDateFrom = errorsValidaions?.indexOf("dateFrom") !== -1

    return (
        <div className="date-inputs">
            <p className="date-inputs__title">
                {entities?.finances_select_period?.value}
            </p>
            <div className="date-inputs__block">
                <DateInput
                    dateFormat="dd.MM.yyyy"
                    input={
                        <Input
                            placeholder=""
                            icon={{
                                type: "calendar",
                                width: 18,
                                height: 18,
                                className: "date-field__icon",
                            }}
                            classes={hasErrorDateFrom ? [dateInputsClassName, "is-invalid"] : [dateInputsClassName]}
                            span={{
                                text: entities?.order_distance_from?.value,
                                className: "date-field__label",
                            }}
                        />
                    }
                    value={dateFrom ? convertDateToJSDate(dateFrom) : null}
                    onChange={(value: Date) =>
                        onChangeDateInput(value, "dateFilterFrom")
                    }
                />
                <div className="separator"></div>
                <DateInput
                    dateFormat="dd.MM.yyyy"
                    input={
                        <Input
                            placeholder=""
                            icon={{
                                type: "calendar",
                                width: 18,
                                height: 18,
                                className: "date-field__icon",
                            }}
                            classes={hasErrorDateTo ? [dateInputsClassName, "is-invalid"] : [dateInputsClassName]}
                            span={{
                                text: entities?.order_distance_to?.value,
                                className: "date-field__label",
                            }}
                        />
                    }
                    value={dateTo ? convertDateToJSDate(dateTo) : null}
                    onChange={(value: Date) =>
                        onChangeDateInput(value, "dateFilterTo")
                    }
                />
            </div>
        </div>
    );
};

import SelectItemToInput from "../../../components/SelectItemToInput";
import React from "react";
import { endpoints } from "../../../store/directories/directories.api";
import { useActions } from "../../../hooks/actions";
import {
    SERVICE_CATEGORY_ID,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_TYPE_ID,
} from "../../../store/order/constant";
import { TranslationObject } from "../../../models/translationModal";
import AccordionView from "../AccordionView";
import { getItemEditOrder } from "../../../store/order/selectors";

interface Props {
    entities?: TranslationObject;
}

const Category: React.FC<Props> = ({ entities }) => {
    const { updateEditOrderField } = useActions();
    const activeCategory = getItemEditOrder(SERVICE_CATEGORY_ID);
    const { data: categories } = endpoints.getServiceCategories.useQuery();
    const items =
        categories?.filter((elem) => elem.id !== activeCategory) || [];

    const handleClick = (id: number) => {
        updateEditOrderField({
            name: SERVICE_CATEGORY_ID,
            value: Number(activeCategory) === Number(id) ? null : id,
        });
        clearOtherFields();
    };

    const clearOtherFields = () => {
        updateEditOrderField({
            name: SERVICE_TYPE_ID,
            value: null,
        });
        updateEditOrderField({
            name: SERVICE_SUB_TYPE_IDS,
            value: [],
        });
        updateEditOrderField({
            name: "filter_pages",
            value: [],
        });
    };

    return (
        <>
            <AccordionView
                defaultIsOpen
                textButton={entities?.order_select_category_title?.value || ""}
            >
                <div className="accordion-form__item-content">
                    <p className="accordion-form__item-text">
                        {entities?.order_select_service_type_text?.value}
                    </p>
                    <div className="accordion-form__item-wrap">
                        <div className="new-order-start">
                            <SelectItemToInput
                                list={categories || []}
                                currentItem={activeCategory}
                                deleteItem={handleClick}
                                items={items}
                                onSelect={handleClick}
                                isBig
                            />
                        </div>
                    </div>
                </div>
            </AccordionView>
        </>
    );
};
export default Category;

import React, { ChangeEvent, useEffect } from "react";
import { ProfilePayload } from "../../../../models/userModels";
import { ProfileHeader } from "../ProfileHeader";
import { CompanyData } from "./CompanyData";
import Form from "../../../../components/Form/Form";
import { RegistrationData } from "./RegistrationData";
import { useSetManagerCompanyMutation } from "../../../../store/user/user.api";
import { useNavigateProfileSpecialist } from "../../hooks";
import { getCurrentLanguage } from "../../../../store/directories/selectors";
import { TranslationObject } from "../../../../models/translationModal";

interface EditCompanyProps {
    profile?: ProfilePayload;
    userSpecialistRole?: { role: string; translate: string };
    entities?: TranslationObject;
}

export const EditCompany: React.FC<EditCompanyProps> = ({
    profile,
    userSpecialistRole,
    entities,
}) => {
    const {
        name: userName,
        family_name: userFamilyName,
        avatar: userAvatar,
        company: userCompany,
    } = profile || {};

    const { country, address, name } = userCompany || {};

    const currentLanguage = getCurrentLanguage();
    const isSpecialist = profile?.user_role === "specialist";

    const navigateTo = useNavigateProfileSpecialist(currentLanguage);

    const [setManagerCompany, { isSuccess, isLoading }] =
        useSetManagerCompanyMutation();

    useEffect(() => {
        if (isSuccess) {
            navigateTo("");
        }
    }, [isSuccess]);

    const onSubmitForm = (event: ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event?.target);

        const requestBody: Record<string, unknown> = {};

        formData.forEach((value, key) => (requestBody[key] = value));

        setManagerCompany(requestBody);
    };

    return (
        <>
            <main>
                <section className="specialist-profile company-data">
                    <h1 className="specialist-profile__title title title--f46">
                        {entities?.register_company_title?.value || ""}
                    </h1>
                    <div className="container">
                        <Form
                            entities={entities}
                            onSubmit={onSubmitForm}
                            onSuccess={() => null}
                            error={"error"}
                            className="form form--big-fieldset"
                        >
                            {(invalidFields, error) => (
                                <>
                                    <ProfileHeader
                                        photo={userAvatar?.original_url}
                                        setPhoto={() => null}
                                        name={userName}
                                        familyName={userFamilyName}
                                        visibleChangeAvatar={false}
                                        userSpecialistRole={userSpecialistRole}
                                        entities={entities}
                                    />
                                    <div className="profile__wrapper">
                                        <CompanyData
                                            company={userCompany}
                                            invalidFields={invalidFields}
                                            error={error}
                                        />
                                        <RegistrationData
                                            company={userCompany}
                                            entities={entities}
                                        />
                                        <div className="profile__buttons-form">
                                            <button
                                                className="btn profile__button"
                                                type="button"
                                                onClick={() => navigateTo("")}
                                            >
                                                {entities?.common_cancel_button
                                                    ?.value || ""}
                                            </button>
                                            <button
                                                disabled={
                                                    isLoading ||
                                                    !address ||
                                                    !country ||
                                                    !name
                                                }
                                                className=" btn--bg-green btn profile__button "
                                                type="submit"
                                            >
                                                {entities?.common_save_button
                                                    ?.value || ""}
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </section>
            </main>
        </>
    );
};

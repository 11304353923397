import React, { ChangeEvent, useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { endpoints } from "../../../store/directories/directories.api";
import classNames from "classnames";
import Icon from "../../../components/Icon";
import { TranslationObject } from "../../../models/translationModal";
import { SubTypesPayload } from "../../../models/directoryModel";

export interface IModalData {
    id: number | null;
    name: string | null;
    customSubType: string | null;
    description: string | null;
    price: string | null;
}

export interface IEditableType {
    id: number | null;
    customType: string | null;
    description: string | null;
    price: number | string | null;
}

interface SubTypeModalProps {
    showSubTypeModal: boolean;
    activeType: number | string | null;
    closeSubTypeModal: () => void;
    subTypeTitle: string;
    editableType?: IEditableType;
    saveSubTypeModal: (modalData: IModalData) => unknown;
    entities?: TranslationObject;
}

export const SubTypeModal: React.FC<SubTypeModalProps> = ({
    showSubTypeModal,
    activeType,
    closeSubTypeModal,
    subTypeTitle,
    editableType,
    saveSubTypeModal,
    entities,
}) => {
    const [activeSubType, setActiveSubType] = useState<{
        id: number | null;
        name: string | null;
        customSubType: string | null;
    }>({
        id: null,
        name: null,
        customSubType: null,
    });

    const [descriptionSubType, setDescriptionSubType] = useState<string>("");
    const [priceSubType, setPriceSubType] = useState<string>("");
    const [foundItems, setFoundItems] = useState<SubTypesPayload[] | null>(null);

    const { data: serviceSubTypes } = endpoints.getServiceSubTypes.useQuery(
        {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            id: activeType,
        },
        {
            skip: typeof activeType !== "number" || Number.isNaN(activeType),
        }
    );

    useEffect(() => {
        if (editableType) {
            const typeId = editableType?.id;
            const typeName = serviceSubTypes?.find(
                (subType) => subType?.id === editableType?.id
            )?.name;
            const customType = editableType?.customType;
            const descriptionType = editableType?.description;
            const priceType = editableType?.price;

            if (typeId && typeName) {
                setActiveSubType({
                    id: typeId,
                    name: typeName,
                    customSubType: null,
                });
            } else if (customType) {
                setActiveSubType({
                    id: null,
                    name: null,
                    customSubType: customType,
                });
            }

            if (descriptionType) {
                setDescriptionSubType(descriptionType);
            }

            if (priceType) {
                setPriceSubType(String(priceType));
            }
        }

        setFoundItems(foundItems !== null ? foundItems : serviceSubTypes?.length ? serviceSubTypes : null);
    }, [editableType, serviceSubTypes]);

    const handleClickSubTypeItem =
        (subType: { id: number; name: string }) => () => {
            setActiveSubType({ ...subType, customSubType: null });
            setFoundItems(serviceSubTypes?.length ? serviceSubTypes : null)
        };

    const deleteActiveSubType = () => {
        setActiveSubType({ id: null, name: null, customSubType: null });
    };

    const handleChangeCustomSubType = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const value = event.target.value;
        setActiveSubType((state) => {
            return { ...state, customSubType: value };
        });

        const filtered =  serviceSubTypes?.filter((item) => {
            const subStr = value.toLocaleLowerCase();

            return (
                item.name.toLocaleLowerCase().includes(subStr) ||
                item?.keywords?.toLocaleLowerCase()?.includes(subStr)
            );
        });

        setFoundItems(value && filtered?.length ? filtered : (serviceSubTypes || []));
    };

    const handleChangeDescription = (
        event: ChangeEvent<HTMLTextAreaElement>
    ) => {
        const value = event.target.value;
        setDescriptionSubType(value);
    };

    const handleChangePrice = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setPriceSubType(value);
    };

    const closeAndClearModal = () => {
        closeSubTypeModal();
        setActiveSubType({ id: null, name: null, customSubType: null });
        setDescriptionSubType("");
        setPriceSubType("");
    };

    const saveModal = () => {
        const modalData: IModalData = {
            id: activeSubType?.id,
            name: activeSubType?.name,
            customSubType: activeSubType?.customSubType,
            description: descriptionSubType,
            price: priceSubType,
        };

        closeAndClearModal();
        saveSubTypeModal(modalData);
    };

    return (
        <Modal
            visible={showSubTypeModal}
            modificators={["modal-specialist"]}
            onClose={closeAndClearModal}
            title={subTypeTitle}
        >
            <>
                <div className="form new-order-start">
                    <form
                        action="#"
                        onSubmit={(e) => {
                            e.preventDefault();
                            saveModal();
                        }}
                    >
                        <div className="form__row form__row--medium-margin">
                            <div
                                className={classNames(
                                    "new-order-start__field new-order-start__field--selected"
                                )}
                            >
                                {activeSubType?.id && activeSubType?.name && (
                                    <span className="new-order-start__toggle is-active">
                                        {activeSubType?.name}
                                        <button
                                            className="btn"
                                            type="button"
                                            aria-label={
                                                entities
                                                    ?.profile_destroy_button_ok
                                                    ?.value
                                            }
                                            onClick={deleteActiveSubType}
                                        >
                                            <Icon
                                                type="delete-input"
                                                width={12}
                                                height={12}
                                            />
                                        </button>
                                    </span>
                                )}
                                <input
                                    type="search"
                                    onChange={handleChangeCustomSubType}
                                    value={activeSubType.customSubType ?? ""}
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            {foundItems?.map((subType) => (
                                <React.Fragment key={subType?.id}>
                                    {subType?.id !== activeSubType?.id && (
                                        <button
                                            key={subType?.id}
                                            className="new-order-start__toggle"
                                            type="button"
                                            onClick={handleClickSubTypeItem({
                                                id: subType?.id,
                                                name: subType?.name,
                                            })}
                                        >
                                            {subType?.name}
                                        </button>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                        <div className="form__row">
                            <div className="custom-textarea form__field">
                                <label>
                                    <textarea
                                        name="description"
                                        placeholder={
                                            entities
                                                ?.specialist_details_enter_service_description
                                                ?.value
                                        }
                                        value={descriptionSubType}
                                        onChange={handleChangeDescription}
                                    />
                                </label>
                            </div>
                        </div>
                        <h2 className="modal__content-title modal__subtitle title title--h4">
                            {entities?.specialist_details_service_cost?.value}
                        </h2>
                        <div className="form__row">
                            <div className="custom-input form__field form__field--w30">
                                <label>
                                    <input
                                        type="text"
                                        id="price"
                                        name="price"
                                        value={priceSubType}
                                        onChange={handleChangePrice}
                                    />
                                </label>
                                <div className="custom-input__error">
                                    {
                                        entities
                                            ?.specialist_details_service_cost
                                            ?.value
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form__row form__controls form__controls--right">
                            <button
                                onClick={closeAndClearModal}
                                className="btn form__back-link btn--transparent"
                                type="button"
                            >
                                {entities?.common_cancel_button?.value}
                            </button>
                            <button
                                className="btn btn--transparent btn--bg-green"
                                type="submit"
                            >
                                {entities?.login_save_button?.value}
                            </button>
                        </div>
                    </form>
                </div>
                <button
                    className="modal__close-btn"
                    type="button"
                    aria-label={entities?.common_close?.value}
                >
                    <svg width={27} height={27} aria-hidden="true">
                        <use xlinkHref="#icon-close-2" />
                    </svg>
                </button>
            </>
        </Modal>
    );
};

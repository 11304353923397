import React, { Dispatch } from "react";
import { TranslationObject } from "../../models/translationModal";
import { OrderItem } from "../../models/ordersModel";
import DropDownMenu from "./DropDownMenu";
import Status from "../Status";
import Controls from "./Controls";
import Avatar from "../Avatar";
import { useNavigate } from "react-router-dom";
import { ProfilePayload } from "../../models/userModels";
import { getDatesOrder, getRepeatString } from "../../store/orders/selectors";
import _ from "lodash";
import { convertDateToDDMMYYYY } from "../../untils";
import { useSuspendedAccess } from "../../hooks/useSuspendedAccess";

interface Props {
    entities?: TranslationObject;
    order: OrderItem;
    isSpecialist: boolean;
    profile?: ProfilePayload;
    showModal?: string;
    role: string;
    currentLanguage: string;
    setShowModal?: Dispatch<string>;
    setSelectedOrder?: Dispatch<number>;
}

const OrderCard: React.FC<Props> = ({
    entities,
    order,
    isSpecialist,
    profile,
    showModal,
    role,
    currentLanguage,
    setShowModal,
    setSelectedOrder
}) => {
    const isDone = order?.status === "done";
    const isActive = order?.status === "active";
    const isDraft = order?.status === "draft";
    const isDeal = order?.status === "deal";
    const isArchive = order?.status === "archive";
    const hasSpecialists = order?.responded_specialist_ids?.length > 0;
    const navigate = useNavigate();
    const started_at =
        order?.started_at && convertDateToDDMMYYYY(order?.started_at);
    const ended_at =
        order?.ended_at && `- ${convertDateToDDMMYYYY(order?.ended_at)}`;
    const linkToOrder = `/${currentLanguage}/${role}/lk/order/${order.id}`;
    const statusProps = {
        isDone: !!isDone,
        isActive: !!isActive,
        isDraft: !!isDraft,
        isDeal: !!isDeal,
        isArchive: !!isArchive,
    };
    const dates = getDatesOrder({ order, entities, ...statusProps });
    const showCustomer = isSpecialist && order?.customer;
    const specialistId = isSpecialist && profile?.id;

    const { isSuspendedUser } = useSuspendedAccess();

    const handleClick = () => {
        if (isSuspendedUser) return;
        navigate(linkToOrder);
    };

    return (
        <li className="cards-orders__item" onClick={handleClick}>
            <div className="cards-orders__item-top-wrapper">
                <Status
                    entities={entities}
                    status={order?.status}
                    isSpecialist={isSpecialist}
                    isResponded={!!order?.response?.created_at}
                    hasExecutor={!!order?.executor}
                    {...statusProps}
                />
                {!isSpecialist && !isSuspendedUser && (
                    <DropDownMenu
                        isSpecialist={isSpecialist}
                        entities={entities}
                        {...statusProps}
                        hasSpecialists={hasSpecialists}
                        id={order.id}
                        order={order}
                        userId={profile?.id}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        setSelectedOrder={setSelectedOrder}
                    />
                )}
                <div className="cards-orders__item-title">
                    <h3 className="title title--f24">{order?.title}</h3>
                    {isSpecialist && <span>{order?.price}</span>}
                </div>
            </div>
            <div className="cards-orders__item-bottom-wrapper">
                <div className="cards-orders__item-info">
                    {dates?.map(
                        (date) =>
                            date.visible && (
                                <p
                                    className="cards-orders__item-create"
                                    key={_.uniqueId("date_order")}
                                >
                                    {date.title}
                                    <span className="cards-orders__item-create-date">
                                        {date.value}
                                    </span>
                                </p>
                            )
                    )}
                    <p className="cards-orders__item-create">
                        {entities?.order_deadline?.value}
                        <span className="cards-orders__item-create-date">
                            {started_at} {ended_at}
                        </span>
                    </p>

                    <p className="cards-orders__item-repeat">
                        {entities?.order_repeat?.value}
                        <span className="cards-orders__item-repeat-text">
                            {getRepeatString({
                                entities,
                                range: order?.repeat_range,
                                day: Number(order?.repeat_day),
                            })}
                        </span>
                    </p>
                </div>
                <Controls
                    isSpecialist={isSpecialist}
                    entities={entities}
                    order={order}
                    {...statusProps}
                    role={role}
                    isResponded={!!order?.response?.created_at}
                    specialistId={specialistId}
                />
            </div>
            {showCustomer && (
                <div className="cards-orders__user">
                    <div className="avatar avatar--rounded avatar--xxs">
                        <Avatar
                            width={40}
                            height={40}
                            avatar={order?.customer?.avatar}
                        />
                    </div>
                    <p className="text">
                        {order?.customer?.name} {order?.customer?.family_name}
                    </p>
                </div>
            )}
        </li>
    );
};
export default OrderCard;

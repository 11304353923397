import React, { ReactNode } from "react";
import classNames from "classnames";

interface Props {
    children: ReactNode;
    isCustomerOnUrl: boolean;
    isSpecialistOnUrl: boolean;
}

const RegisterForm: React.FC<Props> = ({
    isCustomerOnUrl,
    isSpecialistOnUrl,
    children,
}) => {
    return (
        <div
            className={classNames(`registration-page`, {
                "customer-page": isCustomerOnUrl,
                "registration-page--specialist": isSpecialistOnUrl,
            })}
        >
            <div className="registration-page__wrapper container">
                <div className="registration-form">{children}</div>
            </div>
        </div>
    );
};

export default RegisterForm;

import React, { useEffect, useRef } from "react";

import {
    AppSocketProviderProps,
    IChatMessageCreated,
    IChatMessageViewed,
    INotificationWasCreated,
    ISocketClient,
} from "./AppSocketProvider.types";
import { useActions } from "../../hooks/actions";
import { useLeaveSocketClient } from "./useLeaveSocketClient";
import { useCreateSocketClient } from "./useCreateSocketClient";
import { useUpdateUnviewedNotifications } from "./useUpdateUnviewedNotifications";
import { useUpdateOrdersStatusesList } from "./useUpdateOrdersStatusesList";
import { useUpdateOrdersList } from "./useUpdateOrdersList";
import { useUpdatePinnedChats } from "./useUpdatePinnedChats";
import { useUpdateOrder } from "./useUpdateOrder";
import { OrderItem } from "../../models/ordersModel";
import { useInvalidateOrder } from "./useInvalidateOrder";
import { useAddNewOrderInList } from "./useAddNewOrderInList";

export const CHANGE_ORDER_STATUS = 'CHANGE_ORDER_STATUS'

export const AppSocketProvider: React.FC<AppSocketProviderProps> = ({
    children,
    settings,
}) => {
    const { setIsUpdateChat, setIsCreateNewChat } = useActions();
    const { host, token, userId, userRole } = settings;

    const socketClientRef = useRef<ISocketClient>({
        client: null,
        channel: null,
    });

    const createSocketClient = useCreateSocketClient(
        socketClientRef.current,
        settings
    );

    const leaveSocketClient = useLeaveSocketClient(socketClientRef.current);

    const updateUnviewedNotifications = useUpdateUnviewedNotifications();

    const updateOrdersStatusesList = useUpdateOrdersStatusesList();

    const updateOrdersList = useUpdateOrdersList();

    const updatePinnedChats = useUpdatePinnedChats();

    const updateOrder = useUpdateOrder();

    const addNewOrderInList = useAddNewOrderInList()

    const invalidateOrder = useInvalidateOrder()


    useEffect(() => {
        const isValidSettings = host && token && userId && userRole;
        const isSpecialist = userRole === 'specialist'

        if (!isValidSettings) {
            leaveSocketClient();
            return;
        }

        const { client, channel } = createSocketClient();

        client
            .private(channel)
            .listen(
                `.notification_was_created`,
                function (notificationWasCreated: INotificationWasCreated) {
                    const { unviewed_notifications_count } =
                        notificationWasCreated;
                    updateUnviewedNotifications(unviewed_notifications_count);
                }
            )
            .listen(
                `.chat_message_was_created`,
                function (chatMessageCreated: IChatMessageCreated) {
                    const {
                        active_order_chat_messages_count,
                        order,
                        responded_specialist,
                    } = chatMessageCreated;

                    updateOrdersStatusesList({
                        active: active_order_chat_messages_count,
                    });

                    updateOrdersList(order.id, order);

                    updatePinnedChats(order.id, order, responded_specialist, isSpecialist);

                    updateOrder(
                        order.id,
                        order,
                        responded_specialist,
                        userRole
                    );

                    setIsUpdateChat(true);
                }
            )
            .listen(
                `.chat_message_was_viewed`,
                function (chatMessageViewed: IChatMessageViewed) {
                    const {
                        active_order_chat_messages_count,
                        order,
                        responded_specialist,
                    } = chatMessageViewed;

                    updateOrdersStatusesList({
                        active: active_order_chat_messages_count,
                    });

                    updateOrdersList(order.id, order);

                    updatePinnedChats(order.id, order, responded_specialist, isSpecialist);

                    updateOrder(
                        order.id,
                        order,
                        responded_specialist,
                        userRole
                    );

                    setIsUpdateChat(true);
                }
            )
            .listen(
                `.notification_was_viewed`,
                function (notificationWasCreated: INotificationWasCreated) {
                    const { unviewed_notifications_count } =
                        notificationWasCreated;
                    updateUnviewedNotifications(unviewed_notifications_count);
                }
            ).listen(`.order_response_was_created`, function (order: Record<keyof OrderItem, OrderItem[keyof OrderItem]>) {
                const param: Record<keyof OrderItem, OrderItem[keyof OrderItem]> = { 'responded_specialist_ids': order?.responded_specialist_ids }

                if (userRole === 'customer') {
                    updateOrdersList(order?.id, param);
                    invalidateOrder()
                }
            }).listen(`.new_order_was_created`, function (order: Record<keyof OrderItem, OrderItem[keyof OrderItem]>) {
                addNewOrderInList(order, 'new');
                setTimeout(() => setIsCreateNewChat(true), 1500);
            }).listen(`.order_status_was_changed`, function (order: Record<keyof OrderItem, OrderItem[keyof OrderItem]>) {
                updateOrdersList(order.id, order, CHANGE_ORDER_STATUS)
            })

    }, [host, token, userId, userRole]);

    return <>{children}</>;
};

import React from "react";
import { Link } from "react-router-dom";
import { OrderSpecialistItem } from "../../models/orderModel";
import Icon from "../Icon";
import { TranslationObject } from "../../models/translationModal";
import classNames from "classnames";
import { CurrentSpecialistsPayload } from "../../models/userModels";
import {
    checkIsDesktop,
    getCurrentLanguage,
} from "../../store/directories/selectors";
import { useUserRole } from "../../hooks/useUserRole";
import Score from "../Score";
import Avatar from "../Avatar";
import LanguagesSpecialist from "./Languages";
import SpecialistServices from "./Services";
import ChatButton from "../Buttons/ChatButton";
import { chooseDeclension } from "../../untils";
import cn from "classnames";
import { transliterate } from 'transliteration';

interface Props {
    specialist: OrderSpecialistItem | CurrentSpecialistsPayload;
    entities?: TranslationObject;
    changeSpecialistIds?: (value: number, name?: string) => void;
    isAdd?: boolean;
    linkTo?: string;
    isSpecialist?: boolean;
    pathNameRole?: string;
    isEdit: boolean;
    isActiveSubmit?: boolean;
    isSquare?: boolean;
    isPrivate: boolean;
    isUnresponsiveSpecialist?: boolean;
    isOrderPage?: boolean;
    orderId?: number;
}

const CardSpecialist: React.FC<Props> = ({
    specialist,
    entities,
    changeSpecialistIds,
    isAdd,
    linkTo,
    pathNameRole,
    isSquare = false,
    isPrivate,
    isOrderPage = false,
    isUnresponsiveSpecialist = false,
    orderId,
    isSpecialist,
    isActiveSubmit = true
}) => {
    const currentLanguage = getCurrentLanguage();
    const handleChange = () => {
        if (specialist?.id && changeSpecialistIds)
            changeSpecialistIds(specialist?.id, specialist?.name);
    };
    const { role } = useUserRole(pathNameRole);
    const linkToReview = `/${currentLanguage}/${role}/reviews/${specialist?.id}`;

    const isRussian = currentLanguage === "ru";

    const specialistDetailsReviewsCount =
        entities?.specialist_details_reviews_count?.value;

    const specialistDetailsCount = entities?.specialist_details_deals?.value;

    const reviewsCountStr = chooseDeclension(
        Number(specialist?.reviews_count),
        specialistDetailsReviewsCount,
        isRussian,
    );

    const specialistDetailsCountStr = chooseDeclension(
        Number(specialist?.deals_count),
        specialistDetailsCount,
        isRussian
    );

    const textReview =
        specialist?.reviews_count > 0
            ? reviewsCountStr
            : entities?.without_reviews?.value;

    const documentsIsVerified = specialist?.documents_is_verified;
    const hasReview = specialist?.reviews_count > 0;

    const showCancelButton = isPrivate && isAdd && !isOrderPage;
    const showAddButton = isPrivate && !isOrderPage;
    const showAddOrCancelButton = showCancelButton || showAddButton;
    const showUnresponsiveText =
        isUnresponsiveSpecialist && isPrivate && isOrderPage;
    const processedName = specialist?.name ? transliterate(specialist.name.trim().replace(/\s+/g, '-')).toLowerCase() : 'noname';
    const linkToSpecialist = `/${currentLanguage}/specialist/${processedName}-${specialist?.id}`;
    const isDesktop = checkIsDesktop();

    let avatarSize = 133;

    if (!isDesktop) {
        avatarSize = 64;
    }

    return (
        <li className={cn("profile-card-container", {
            "profile-card-container--sent-offer": !!specialist?.sent_offer,
        })}>
            <div className="profile-card">
                <div className="profile-card__img-wrap">
                    <Avatar
                        avatar={specialist?.avatar}
                        width={avatarSize}
                        height={avatarSize}
                        isSquare={isSquare}
                    />
                </div>
                <Link className="profile-card__title" to={linkToSpecialist} state={window.location.pathname}>
                    {specialist?.name}
                </Link>

                <div className="indicators">
                    <Score
                        score={specialist?.rating}
                        withBackground
                        className="rating"
                        width={10}
                        height={10}
                    />
                    <span className="indicators__deal">
                        {specialist?.deals_count} {specialistDetailsCountStr}
                    </span>
                    {hasReview ? (
                        <Link
                            className="indicators__link-review link link--underline"
                            to={linkToReview}
                        >
                            {specialist?.reviews_count + " " + textReview}
                        </Link>
                    ) : (
                        <span className="indicators__link-review ">{textReview}</span>
                    )}
                </div>
                <LanguagesSpecialist
                    specialistLanguages={specialist?.languages}
                    entities={entities}
                />
                <SpecialistServices
                    specialist={specialist}
                    entities={entities}
                    linkToSpecialist={linkToSpecialist}
                />
                {documentsIsVerified && (
                    <p className="profile-card__note">
                        <Icon type="ok_ellipse" width={16} height={16} />
                        <span>
                            {entities?.specialist_details_documents_verified?.value}
                        </span>
                    </p>
                )}
                {(showAddOrCancelButton && isActiveSubmit) && (
                    <div
                        onClick={handleChange}
                        className={classNames(
                            "btn profile-card__button  btn btn--rounded",
                            {
                                "profile-card__button--cancel btn--transparent":
                                    showCancelButton,
                                "profile-card__button--confirm": showAddButton,
                            }
                        )}
                    >
                        {showCancelButton
                            ? entities?.common_cancel_button?.value
                            : entities?.order_place_specialist_select?.value}
                    </div>
                )}
                {(showAddOrCancelButton && !isActiveSubmit) && (
                    <div className="popover popover--grey popover--specialist">
                        <div
                            onClick={handleChange}
                            className={classNames(
                                "btn profile-card__button  btn btn--rounded",
                                {
                                    "profile-card__button--confirm": showAddButton,
                                    "profile-card__button--disabled": !isActiveSubmit,
                                }
                            )}
                        >
                            {entities?.order_select_specialist?.value}
                        </div>
                        <span className="popover__tip popover__tip--specialist">
                            {entities?.order_fill_filters?.value}
                        </span>
                    </div> 
                )}
                {showUnresponsiveText && (
                    <button className="btn btn--text">
                        {entities?.order_select_specialist?.value}
                    </button>
                )}
                {orderId && (
                    <ChatButton
                        entities={entities}
                        isPrivate={isPrivate}
                        isSpecialist={isSpecialist}
                        specialist={specialist}
                        orderId={orderId}
                        role={role}
                        isOrderPage={isOrderPage}
                        isUnresponsiveSpecialist={isUnresponsiveSpecialist}
                    />
                )}
                {!!specialist?.sent_offer && (
                    <div className="profile-card__offer">
                        <span className="profile-card__offer-icon">
                            <svg
                                width="10"
                                height="8"
                                viewBox="0 0 10 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1 3.67794L4.03267 6L9 1" stroke="#22D695" stroke-width="2" stroke-linecap="round" />
                            </svg>
                        </span>
                        <span className="profile-card__offer-text">{entities?.order_specialist_offered_a_deal?.value}</span>
                    </div>)}
            </div>
        </li>
    );
};

export default CardSpecialist;

import React from "react";
import { Link } from "react-router-dom";
import { getCurrentLanguage } from "../../store/directories/selectors";
import { useUserRole } from "../../hooks/useUserRole";
import cn from "classnames";

const pageNotFoundMessages = {
    ru: {
        message: 'Страница не существует либо вы ввели неправильный адрес.',
        linkText: 'на главную страницу'
    },
    en: {
        message: 'The page does not exist or you have entered an incorrect address.',
        linkText: 'to the main page'
    },
    el: {
        message: 'Η σελίδα δεν υπάρχει ή εισαγάγατε λανθασμένη διεύθυνση.',
        linkText: 'στην κεντρική σελίδα'
    }
};

const NotFoundPage = () => {
    const currentLanguage = getCurrentLanguage();
    const { role } = useUserRole();

    const linkTo =
        currentLanguage && role !== "guest"
            ? `/${currentLanguage}/${role}/`
            : currentLanguage
                ? `/${currentLanguage}/`
                : role !== "guest"
                    ? `/el/${role}/`
                    : "/el/";

    const { message, linkText } = pageNotFoundMessages[currentLanguage] || pageNotFoundMessages.en;

    return (
        <main>
            <section className="notfound-page">
                <div className="notfound-page__inner">
                    <div className="notfound-info">
                        <p className="notfound-info__title">404</p>
                        <p className="notfound-info__description">
                            {message}
                        </p>
                        <Link to={linkTo}>
                            <a
                                className={cn("notfound-info__link", {
                                    ["link-green"]: role === "specialist",
                                })}
                            >
                                {linkText}
                            </a>
                        </Link>
                    </div>
                </div>
            </section>
        </main>
    );
};
export default NotFoundPage;

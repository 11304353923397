import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import Modal from "../../components/Modal";
import ChooseAddress from "../../components/ChooseAddress";
import { useUpdateUserAddressMutation } from "../../store/user/user.api";
import { LocationData, locationProps } from "../../models/orderModel";
import Input from "../../components/Form/Input";
import Icon from "../../components/Icon";
import { TranslationObject } from "../../models/translationModal";
import cn from "classnames";
import ConfirmDeleteModal from "../../components/Modals/ConfirmDeleteModal/ConfirmDeleteModal";

const ChangeAddress: React.FC<{
    address: string;
    entities?: TranslationObject;
    setAddress: Dispatch<SetStateAction<string>>;
    isSpecialist?: boolean;
    updateAddressHandler?: React.MutableRefObject<() => unknown>;
    latitude?: number;
    longitude?: number;
}> = ({
    address,
    setAddress,
    entities,
    isSpecialist = false,
    updateAddressHandler,
    latitude,
    longitude,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [location, setLocation] = useState<LocationData | null>(null);

    useEffect(() => {
        if (address || latitude || longitude) {
            setLocation({
                latitude: latitude || null,
                longitude: longitude || null,
                address: address,
            });
        }
    }, [address, latitude, longitude]);

    const [updateAddress] = useUpdateUserAddressMutation();

    const update = () => {
        if (location?.latitude && location?.longitude) {
            if (updateAddressHandler?.current) {
                updateAddressHandler.current = () =>
                    updateAddress({
                        body: {
                            address: location?.address,
                            latitude: location?.latitude,
                            longitude: location?.longitude,
                        },
                    });
            } else {
                updateAddress({
                    body: {
                        address: location?.address,
                        latitude: location?.latitude,
                        longitude: location?.longitude,
                    },
                });
            }
            if (location?.address) setAddress?.(location.address);
            setShowModal(false);
        }
    };

    const changeLocation = (obj: locationProps | null) => {
        if (obj?.latitude && obj?.longitude) {
            setLocation({
                latitude: obj?.latitude,
                longitude: obj?.longitude,
                address: obj?.address,
            });
        }
    };

    const clearAddress = () => {
        if (updateAddressHandler?.current) {
            updateAddressHandler.current = () =>
                updateAddress({
                    body: {
                        address: null,
                        latitude: null,
                        longitude: null,
                    },
                });
        } else {
            updateAddress({
                body: {
                    address: null,
                    latitude: null,
                    longitude: null,
                },
            });
        }
        setAddress("");
        setLocation(null);
    };

    const buttons = (
        <>
            <button
                className="address-field__button address-field__button--edit"
                type="button"
                onClick={() => setShowModal(true)}
            >
                <Icon type="edit" width={20} height={20} />
            </button>
            <ConfirmDeleteModal
                entities={entities}
                classes={["address-field__button", "address-field__button--cancel"]}
                handleClick={clearAddress}
                ariaLabel={entities?.profile_destroy_button_ok?.value}
            >
                <Icon type="close-input" width={20} height={20} />
            </ConfirmDeleteModal>
        </>
    );

    const modalModificators = [
        "modal-specialist address-modal",
        isSpecialist ? "" : "customer",
    ];

    return (
        <>
            <Modal
                title={entities?.order_select_address_title?.value || ""}
                visible={showModal}
                onClose={() => setShowModal(false)}
                modificators={modalModificators}
                key="ProfileModal"
            >
                <div>
                    <ChooseAddress
                        inputClasses={["custom-input--suffix form__field"]}
                        changeLocation={changeLocation}
                        withRadius={false}
                        entities={entities}
                        location={location}
                        changeAddress={(value: string) => setAddress(value)}
                        isSpecialist={isSpecialist}
                    />
                    <div className="form__row form__controls form__row--end">
                        <button
                            className="btn form__control form__back-link btn--transparent"
                            type="button"
                            onClick={() => setShowModal(false)}
                        >
                            {entities?.common_cancel_button?.value}
                        </button>
                        <button
                            className={cn("btn form__control", {
                                "btn--bg-green": isSpecialist,
                            })}
                            type="button"
                            onClick={update}
                        >
                            {entities?.common_save_button?.value}
                        </button>
                    </div>
                </div>
            </Modal>
            {address ? (
                <Input
                    type="text"
                    name={address?.length > 0 ? "address" : ""}
                    placeholder={""}
                    value={address}
                    disabled={true}
                    classes={["address-field"]}
                    button={buttons}
                />
            ) : (
                <button
                    className="link link--underline"
                    type="button"
                    onClick={() => setShowModal(true)}
                >
                    {entities?.profile_add_address?.value || ""}
                </button>
            )}
        </>
    );
};

export default ChangeAddress;

import React, { ChangeEvent, useEffect, useState } from "react";
import Form from "../../components/Form/Form";
import Input from "../../components/Form/Input";
import classNames from "classnames";
import { useLoginByPhoneMutation } from "../../store/auth/auth.api";
import { getPath, isSpecialistUrl } from "../../store/directories/selectors";
import { useActions } from "../../hooks/actions";
import Confirmation from "../../components/Confirmation";
import { ComponentProps } from "../../models/models";
import { deleteCookie } from "../../untils/cookies";
import { Link } from "react-router-dom";
import { useUserRole } from "../../hooks/useUserRole";

interface Props extends ComponentProps {
    setStep: any;
}

const StepOne: React.FC<Props> = ({ entities, setStep, currentLanguage }) => {
    const [
        updatePhone,
        { error, isLoading, isSuccess, data: dataResponsePhone },
    ] = useLoginByPhoneMutation();
    const isSpecialist = isSpecialistUrl();
    const { updateUserField } = useActions();
    const path = getPath();
    const [value, setValue] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const [name, setName] = useState("phone");

    const onSuccess = () => {
        name === "phone" ? setShowConfirm(true) : setStep("two");
    };

    // useEffect(() => {
    //     deleteCookie("tkn");
    // }, []);

    useEffect(() => {
        const clearValue = value
            ?.split("")
            ?.filter((elem) => elem !== "-")
            ?.join("")
            .replace("+", "")
            .replace(/\s/g, "");

        const name = !isNaN(Number(clearValue)) ? "phone" : "email";
        setName(name);
        updateUserField({
            name,
            value,
        });
    }, [value]);

    const onSubmit = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = new FormData(e.target);

        if (name === "email") {
            return setStep("two");
        }
        currentLanguage && updatePhone({ body: data, type: "by_phone" });
    };

    const onChange = (value: any) => {
        if (!isNaN(value) && value !== "" && String(value)[0] !== "+") {
            return setValue(`+${value}`);
        } else if (isNaN(value) && String(value)[0] === "+") {
            const str = String(value);
            return setValue(str.substring(1, str.length));
        }

        if (!isNaN(value) && String(value)?.length > 15) {
            return;
        }
        setValue(value);
    };

    const resentCode = () => {
        const formData = new FormData();
        formData.append("phone", String(value));
        formData.append("user_role", getPath()[1]);
        updatePhone({ body: formData, type: "by_phone" });
    };

    useEffect(() => {
        if (isSuccess) {
            onSuccess();
        }
    }, [isSuccess]);

    const { role } = useUserRole(isSpecialist ? "specialist" : "customer");
    const linkRegistration = `/${currentLanguage}/${role}/registration`;

    return showConfirm ? (
        <Confirmation
            entities={entities}
            handleReturn={() => setShowConfirm(false)}
            resendCode={resentCode}
            isAuth
            phone={value}
            changeStep={setStep}
            currentLanguage={currentLanguage}
            data={dataResponsePhone}
        />
    ) : (
        <div className="registration-form__content">
            <div className="registration-form__title-wrapper">
                <h1 className="title title--h1">
                    {entities?.login_title?.value}
                </h1>
            </div>
            <div className="form">
                <Form
                    entities={entities}
                    onSubmit={onSubmit}
                    isSuccess={isSuccess}
                    error={error}
                >
                    {(invalidFields, error) => (
                        <>
                            <div className="form__row">
                                <Input
                                    type={name === "phone" ? "phone" : "email"}
                                    name={name === "phone" ? "phone" : "email"}
                                    invalidFields={invalidFields}
                                    error={error}
                                    onChange={onChange}
                                    value={value}
                                    placeholder={
                                        entities?.login_email_or_phone_input
                                            ?.value || ""
                                    }
                                />
                                <input
                                    type="hidden"
                                    name="user_role"
                                    value={path[1]}
                                />
                            </div>
                            <div className="form__row">
                                <span className="form__text">
                                    {entities?.login_no_account?.value}
                                </span>
                                <Link
                                    to={linkRegistration}
                                    className="form__text link link--hover-underline"
                                >
                                    {entities?.login_sign_up?.value}
                                </Link>
                            </div>
                            <div className="form__row form__controls form__controls--medium-width form__controls--not-fixed-sm">
                                <button
                                    disabled={isLoading}
                                    className={classNames(
                                        "btn btn--transparent btn--bg-blue",
                                        {
                                            "btn--green": isSpecialist,
                                        }
                                    )}
                                    type="submit"
                                >
                                    {entities?.login_continue_button?.value}
                                </button>
                            </div>
                        </>
                    )}
                </Form>
            </div>
        </div>
    );
};

export default StepOne;

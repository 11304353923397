import React from "react";
import Input, { InvalidFieldsProps } from "../Input";

interface IPhoneInput {
    phone: string;
    placeholder?: string;
    changePhone: (inputValue: string) => unknown;
    phoneIsConfirmed: boolean;
    buttonPhoneConfirmedName: string;
    clickPhoneConfirmed: (
        event: React.MouseEvent<HTMLButtonElement>
    ) => unknown;
    phoneInputError?: unknown;
    phoneInputInvalidFields?: InvalidFieldsProps;
}

interface IEmailInput {
    showEmail: boolean;
    email: string;
    changeEmail: (value: string) => unknown;
    emailIsConfirmed: boolean;
    buttonEmailConfirmedName: string;
    clickEmailConfirmed: (
        event: React.MouseEvent<HTMLButtonElement>
    ) => unknown;
    emailInputError?: unknown;
    emailInputInvalidFields?: InvalidFieldsProps;
}

interface ProfileContactsProps {
    legend: string;
    phoneInput: IPhoneInput;
    emailInput: IEmailInput;
    error: any;
    invalidFields?: InvalidFieldsProps;
}

export const ProfileContacts: React.FC<ProfileContactsProps> = (props) => {
    const { legend, phoneInput, emailInput, error, invalidFields } =
        props || {};
    const {
        phone,
        placeholder = "",
        changePhone,
        phoneIsConfirmed,
        clickPhoneConfirmed,
        buttonPhoneConfirmedName,
    } = phoneInput || {};
    const {
        showEmail,
        email,
        changeEmail,
        emailIsConfirmed,
        clickEmailConfirmed,
        buttonEmailConfirmedName,
    } = emailInput || {};

    const emaiValidation = /^[a-zA-Z0-9]+(?:[._%+-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
    const showEmailConfirmButton = !emailIsConfirmed && email?.length > 3 && emaiValidation?.test(email)

    return (
        <>
            <div className="profile__fieldset profile__contacts">
                <fieldset>
                    <legend>{legend}{legend && <span className="asterisk">*</span>}</legend>
                    <div className="form__row">
                        <div className="contacts-field is-valid">
                            <Input
                                classes={phoneIsConfirmed ? ["is-valid"] : []}
                                type="tel"
                                value={phone}
                                onChange={changePhone}
                                id="phone"
                                error={error}
                                invalidFields={invalidFields}
                                name="phone"
                                placeholder={placeholder}
                                icon={
                                    phoneIsConfirmed
                                        ? {
                                            type: "valid",
                                            className: "valid-icon",
                                            width: 24,
                                            height: 24,
                                        }
                                        : null
                                }
                                button={
                                    !phoneIsConfirmed && (
                                        <button
                                            className="btn-reset confirm-field__btn"
                                            onClick={clickPhoneConfirmed}
                                        >
                                            {buttonPhoneConfirmedName}
                                        </button>
                                    )
                                }
                            />
                        </div>
                    </div>
                    {showEmail && (
                        <div className="form__row email-field">
                            <div className="contacts-field is-valid">
                                <Input
                                    type="email"
                                    value={email}
                                    onChange={changeEmail}
                                    id="email"
                                    error={error}
                                    invalidFields={invalidFields}
                                    name="email"
                                    placeholder=""
                                    icon={
                                        emailIsConfirmed
                                            ? {
                                                type: "valid",
                                                className: "valid-icon",
                                                width: 24,
                                                height: 24,
                                            }
                                            : null
                                    }
                                    button={
                                        showEmailConfirmButton && (
                                            <button
                                                className="btn-reset confirm-field__btn"
                                                onClick={clickEmailConfirmed}
                                            >
                                                {buttonEmailConfirmedName}
                                            </button>
                                        )
                                    }
                                    classes={
                                        emailIsConfirmed ? ["is-valid"] : []
                                    }
                                />
                            </div>
                        </div>
                    )}
                </fieldset>
            </div>
        </>
    );
};

import React, { useEffect, ReactElement } from "react";
import { useActions } from "../../hooks/actions";
import {
    getArrayToObject,
    getPageId,
    getPath,
    getDirectories,
    getCustomPage,
    getTextPage
} from "../../store/directories/selectors";
import { scrollTop } from "../../untils";
import Loader from "../Loader";
import HeaderMeta from "../HeaderMeta/HeaderMeta";

interface Props {
    code?: string;
    updateData?: any;
    updateEntities?: any;
    isCustomPage?: boolean;
    type: string;
    children: ReactElement<any, any> | null;
    isNeedPage?: boolean;
    currentLanguage: string;
}

const PageLoaders: React.FC<Props> = (props) => {
    const HEADER_ID = 12
    const { setHeaderStateSearch, setHeaderStateSearchPlaceholder } = useActions();
    const { data: pages } = getDirectories();
    const path = getPath();
    const id =
        pages &&
        getPageId(pages, props?.code ?? path[path?.length - 1], props.type);


    const result = getCustomPage({ id: HEADER_ID });
    const headerData = result.data;

    const { isLoading, data } = props.isCustomPage
        ? getCustomPage({ id })
        : getTextPage({ id });

    useEffect(() => {
        if (path) {
            scrollTop()
        }
    }, [path]);

    useEffect(() => {
        if (headerData && headerData?.blocks) {
            const headerBlocks = getArrayToObject(headerData?.blocks || []);
            setHeaderStateSearch(headerBlocks?.header_search_button);
            setHeaderStateSearchPlaceholder(headerBlocks?.header_search_button?.value);
        }

        if (data) {
            if (props.isCustomPage && !props.isNeedPage) {
                const dataBlocks = data?.blocks || [];

                if (data?.blocks) {
                    const blocks = getArrayToObject(dataBlocks);
                    props.updateData(blocks);
                }
            } else {
                props.updateData(data);
            }
        }
    }, [data, props.currentLanguage, headerData]);

    if (isLoading) {
        return <Loader />;
    }

    if (props.children) {
        return <>
            <HeaderMeta 
                currentLanguage={props.currentLanguage}
                customPages={pages?.custom_pages || []}
                textPages={pages?.text_pages || []}
            />
            { props.children }
        </>
    }
    return null;
};

export default PageLoaders;

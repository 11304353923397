import React from "react";
import Input from "../../../components/Form/Input";
import { useActions } from "../../../hooks/actions";
import { TITLE } from "../../../store/order/constant";
import { TranslationObject } from "../../../models/translationModal";
import { getItemEditOrder } from "../../../store/order/selectors";

interface Props {
  entities?: TranslationObject;
}

const OrderName: React.FC<Props> = ({ entities }) => {
  const { updateEditOrderField } = useActions();
  const title = getItemEditOrder(TITLE);

  const handleChange = (value: string) => {
    updateEditOrderField({
        name: TITLE,
        value,
    });
  };

  return (
    <fieldset>
      <legend>
          {
              entities
                  ?.order_finish_order_name
                  ?.value
          }
      </legend>
      <Input
          type="text"
          placeholder=""
          maxLength={255}
          value={title}
          onChange={handleChange}
      />
    </fieldset>
  )
}

export default OrderName
import React, { useEffect, useMemo, useState } from "react";
import Select, { SingleValue } from "react-select";
import { endpoints } from "../../../../store/user/user.api";
import {
	getCurrentLanguage,
	getDirectories,
} from "../../../../store/directories/selectors";
import _ from "lodash";
import Icon from "../../../../components/Icon";
import Score from "../../../../components/Score";
import Input from "../../../../components/Form/Input";
import {
	advertisementEndpoints,
	useStoreAdvertisementBannerMutation,
	useStoreAdvertisementMailingMutation,
	useStoreAdvertisementTopMutation,
} from "../../../../store/advertisement/advertisement.api";
import { BankCardsModal } from "../../../../components/Modals/BankCardsModal";
import {
	ISelectCard,
	TypeModal,
} from "../../../../components/Modals/BankCardsModal/BankCardsModal";
import {
	AdvertisementEmployee,
	IMainLanguage,
	IService,
} from "../../../../models/advertisementModel";
import { TranslationObject } from "../../../../models/translationModal";
import { Image, ProfilePayload } from "../../../../models/userModels";
import { PhotoGalleryServicesModal } from "../../../../components/Modals/PhotoGalleryServicesModal/PhotoGalleryServicesModal";
import { chooseDeclension } from "../../../../untils";
import cn from "classnames";

interface NewAdvertisementProps {
	onClose: () => unknown;
	type: "banner" | "top" | "email";
	profile?: ProfilePayload;
	role?: "manager" | "employee" | "private person";
	entities?: TranslationObject;
}

const days = [5, 10, 20, 30, 60];

export const NewAdvertisement: React.FC<NewAdvertisementProps> = ({
	onClose,
	type = "banner",
	role,
	entities,
	profile,
}) => {
	const { id: profileId } = profile || {};

	const [typeAdvertisement, setTypeAdvertisement] = useState<
		"banner" | "top" | "email"
	>(type);

	const [imageAdvertisement, setImageAdvertisement] = useState<Image | null>(
		null
	);

	const [selectedServicesTypes, setSelectedServicesTypes] = useState<
		{
			value: number;
			label: string;
		}[]
	>([]);

	const [selectedServicesSubTypes, setSelectedServicesSubTypes] = useState<
		{
			id: number;
			name: string;
		}[]
		>([]);

	// TODO: заглушка в разработке
	// const [showInDevelopmentNotification, setShowInDevelopmentNotification] =
	//     useState<boolean>(false);

	const [selectedSpecialist, setSelectedSpecialist] = useState<SingleValue<{
		value: string;
		label: string;
	}> | null>(null);

	const [daysCount, setDaysCount] = useState<number>(5);

	const [emailCount, setEmailCount] = useState<number | null>(null);

	const [showModal, setShowModal] = useState<boolean>(false);

	const [getSpecialistDetail, { data: getSpecialistDetailData }] =
		endpoints.getSpecialistDetail.useLazyQuery();

	const [getBannerStartDate, { data: getBannerStartDateData }] =
		advertisementEndpoints.getBannerStartDate.useLazyQuery();

	const [getMailingStartDate, { data: getMailingStartDateData }] =
		advertisementEndpoints.getMailingStartDate.useLazyQuery();

	const [
		storeAdvertisementBanner,
		{ isSuccess: isSuccessStoreBanner, isError: isErrorStoreBanner },
	] = useStoreAdvertisementBannerMutation();

	const [
		storeAdvertisementMailing,
		{ isSuccess: isSuccessStoreMailing, isError: isErrorStoreMailing },
	] = useStoreAdvertisementMailingMutation();

	const [
		storeAdvertisementTop,
		{ isSuccess: isSuccessStoreTop, isError: isErrorStoreTop },
	] = useStoreAdvertisementTopMutation();

	const { employeesInfo, allEmployeesInfo } =
		useMyAndEmployeesInfo(profileId);

	const { selectSpecialistInfo } = useSelectSpecialistInfo(
		allEmployeesInfo,
		role === "private person",
		profile,
		Number(selectedSpecialist?.value)
	);

	const { data: directories } = getDirectories();

	const typeModalProps = useGetTypeModalProps(
		isSuccessStoreBanner || isSuccessStoreMailing || isSuccessStoreTop,
		isErrorStoreBanner || isErrorStoreMailing || isErrorStoreTop
	);

	const { specialistsBannerCost, specialistsTopCost, specialistMailingCost } =
		useMemo(() => {
			const specialistsBannerCost = Object.values(
				directories?.ads_settings?.specialists_banner_cost || {}
			).map((item, index) => {
				return {
					label: days[index] || "",
					value: item,
				};
			});

			const specialistsTopCost = Object.values(
				directories?.ads_settings?.specialists_top_cost || {}
			).map((item, index) => {
				return {
					label: days[index] || "",
					value: item,
				};
			});

			const specialistMailingCost =
				directories?.ads_settings?.specialists_mailing_cost || null;

			return {
				specialistsBannerCost,
				specialistsTopCost,
				specialistMailingCost,
			};
		}, [directories?.ads_settings]);

	useEffect(() => {
		setSelectedServicesTypes([]);
		setSelectedSpecialist(null);
		setImageAdvertisement(null);
		setDaysCount(5);
		setEmailCount(null);
	}, [typeAdvertisement]);

	useEffect(() => {
		if (typeAdvertisement === "banner") {
			getBannerStartDate({
				days: daysCount,
			});
		}
		if (typeAdvertisement === "email") {
			getMailingStartDate();
		}
	}, [typeAdvertisement, getBannerStartDate, getMailingStartDate, daysCount]);

	useEffect(() => {
		if (selectedSpecialist?.value && typeAdvertisement === "banner") {
			getSpecialistDetail({ id: +selectedSpecialist?.value });
		} else if (profileId) {
			getSpecialistDetail({ id: profileId });
		}
		setSelectedServicesTypes([]);
		setImageAdvertisement(null);
	}, [profileId, selectedSpecialist]);

	const servicesTypes = useMemo(() => {
		if (getSpecialistDetailData?.services?.length) {
			const services = getSpecialistDetailData.services;
			return services?.map((el) => ({
				label: el?.custom_service_type || el?.service_type?.name || "",
				value: el?.id,
				sub_types: el?.service_sub_types
			}));
		}
		return [];
	}, [getSpecialistDetailData]);

	const handleChangeInputServicesType = (servicesType: {
		value: number;
		label: string;
	}) => {
		setSelectedServicesSubTypes([]);
		setSelectedServicesTypes((prevState) => {
			if (prevState.find((el) => el.value === servicesType.value)) {
				return prevState.filter(
					(el) => el.value !== servicesType.value
				);
			} else {
				return selectedServicesTypes?.length >= 3
					? prevState
					: [...prevState, servicesType];
			}
		});
		setImageAdvertisement(null);
	};

	const handleChangeInputServicesSubType = (servicesSubType: {
		name: string;
		id: number;
	}) => {
		setSelectedServicesSubTypes((prevState) => {
			if (prevState.find((el) => el.id === servicesSubType.id)) {
				return prevState.filter(
					(el) => el.id !== servicesSubType.id
				);
			} else {
				return selectedServicesSubTypes?.length >= 3
					? prevState
					: [...prevState, servicesSubType];
			}
		});
	};

	const showBanner = useMemo(() => {
		if (typeAdvertisement === "banner") {
			return {
				date: getBannerStartDateData?.date || "",
				is_overloaded: getBannerStartDateData?.is_overloaded || false,
			};
		} else if (typeAdvertisement === "email") {
			return {
				date: getMailingStartDateData?.date || "",
				is_overloaded: getMailingStartDateData?.is_overloaded || false,
			};
		}
		return {
			date: "",
			is_overloaded: false,
		};
	}, [getBannerStartDateData, getMailingStartDateData, typeAdvertisement]);

	const storeAdvertisement = (selectCard: ISelectCard) => {
		const cardId = selectCard?.value;
		const specialistId = selectedSpecialist?.value || profileId?.toString();
		const days = daysCount.toString();

		if (!cardId || !specialistId) return;

		if (typeAdvertisement === "banner") {
			if (!selectedServicesTypes?.length || !imageAdvertisement?.id)
				return;

			const body = new FormData();

			body.set("card_id", cardId);

			body.set("specialist_id", specialistId);

			body.set("days", days);

			selectedServicesTypes.forEach((el, index) => {
				body.set(
					`specialist_service_ids[${index}]`,
					el?.value?.toString()
				);
			});

			body.set("photo_id", imageAdvertisement.id.toString());

			storeAdvertisementBanner(body);
		} else if (typeAdvertisement === "email") {
			if (
				!emailCount ||
				!selectedServicesTypes?.length ||
				!imageAdvertisement?.id
			)
				return;

			const body = new FormData();

			body.set("card_id", cardId);

			body.set("specialist_id", specialistId);

			body.set("mailings_count", emailCount.toString());

			selectedServicesTypes.forEach((el, index) => {
				body.set(
					`specialist_service_ids[${index}]`,
					el?.value?.toString()
				);
			});

			body.set("photo_id", imageAdvertisement.id.toString());

			storeAdvertisementMailing(body);
		} else if (typeAdvertisement === "top") {
			const body = new FormData();

			body.set("card_id", cardId);

			body.set("specialist_id", specialistId);

			body.set("days", days);
			
			storeAdvertisementTop(body);
		}
	};

	useEffect(() => {
		if (
			(typeModalProps === "isSuccess" || typeModalProps === "isError") &&
			showModal === false
		) {
			onClose();
		}
	}, [typeModalProps, showModal]);

	const price = useGetPriceAdvertisement(
		typeAdvertisement,
		specialistsBannerCost,
		specialistsTopCost,
		specialistMailingCost,
		daysCount,
		emailCount
	);

	const disabledBtnPay = (() => {
		if (!price) {
			return true;
		}
		if (typeAdvertisement === "banner") {
			if (!selectedServicesTypes?.length || !imageAdvertisement?.id) {
				return true;
			}
		} else if (typeAdvertisement === "email") {
			if (
				!emailCount ||
				!selectedServicesTypes?.length ||
				!imageAdvertisement?.id
			) {
				return true;
			}
		}
		return false;
	})();

	const [
		visiblePhotoGalleryServicesModal,
		setVisiblePhotoGalleryServicesModal,
	] = useState<boolean>(false);

	const currentSpecialistPhotos = useGetCurrentSpecialistPhotos(
		selectSpecialistInfo,
		selectedServicesTypes.map((el) => el.value)
	);

	return (
		<>
			<BankCardsModal
				visible={showModal}
				closeModal={() => setShowModal(false)}
				confirmModal={storeAdvertisement}
				typeModalProps={typeModalProps}
				price={price}
				entities={entities}
			/>
			<PhotoGalleryServicesModal
				visible={visiblePhotoGalleryServicesModal}
				setVisible={(visible) =>
					setVisiblePhotoGalleryServicesModal(visible)
				}
				photos={currentSpecialistPhotos}
				confirmModal={(selectPhoto) =>
					setImageAdvertisement(selectPhoto)
				}
				isMyPhotos={selectSpecialistInfo?.id === profileId}
				entities={entities}
			/>
			<main>
				<div className="spec-page">
					<div className="spec-page__container container">
						<section className="spec-page__new-offer new-offer">
							<h1 className="spec-page__heading title title--f46">
								{entities?.advertisement_design?.value}
							</h1>
							<div className="form new-offer-form">
								<form action="/">
									<h2 className="new-offer-form__title title title--f24">
										{
											entities?.advertisement_basic_data
												?.value
										}
									</h2>
									<div className="new-offer-form__row">
										<fieldset>
											<legend>
												{
													entities
														?.advertisement_choose_type
														?.value
												}
											</legend>
											<div className="custom-toggle custom-toggle--radio custom-radio new-offer-form__field">
												<input
													type="radio"
													id="offer-1"
													name="type"
													checked={
														typeAdvertisement ===
														"banner"
													}
													onChange={() =>
														setTypeAdvertisement(
															"banner"
														)
													}
												/>
												<label
													className="custom-radio__label"
													htmlFor="offer-1"
												>
													<span className="custom-toggle__icon" />
													{
														entities
															?.advertisement_on_platform
															?.value
													}
												</label>
											</div>
											<div className="custom-toggle custom-toggle--radio custom-radio new-offer-form__field">
												<input
													type="radio"
													id="offer-2"
													name="type"
													checked={
														typeAdvertisement ===
														"top"
													}
													onChange={() =>
														setTypeAdvertisement(
															"top"
														)
													}
												/>
												<label
													className="custom-radio__label"
													htmlFor="offer-2"
												>
													<span className="custom-toggle__icon" />
													{
														entities
															?.advertisement_rise_to_top
															?.value
													}
												</label>
											</div>
											<div className="custom-toggle custom-toggle--radio custom-radio new-offer-form__field">
												<input
													type="radio"
													id="offer-3"
													name="type"
													checked={
														typeAdvertisement ===
														"email"
													}
													onChange={() =>
														setTypeAdvertisement(
															"email"
														)
													}
												/>
												<label
													className="custom-radio__label"
													htmlFor="offer-3"
												>
													<span className="custom-toggle__icon" />
													{
														entities
															?.advertisement_e_mailings
															?.value
													}
												</label>
											</div>
										</fieldset>
									</div>
									{typeAdvertisement === "email" && (
										<div className="form__row form__row--big-margin">
											<fieldset>
												<legend>
													{
														entities
															?.advertisement_number_of_mailings
															?.value
													}
												</legend>
												<Input
													placeholder={""}
													onChange={setEmailCount}
													value={emailCount}
													type={"number"}
													classes={[
														"input-mailing__mailing",
													]}
												/>
												{specialistMailingCost && (
													<p className="input-mailing__mailing-description">
														{entities?.advertisement_cost_for_one?.value?.replace(
															"20",
															String(
																specialistMailingCost
															)
														)}
													</p>
												)}
											</fieldset>
										</div>
									)}
									{role === "manager" &&
										typeAdvertisement !== "email" && (
											<div className="form__row form__row--big-margin">
												<fieldset>
													<legend>
														{
															entities
																?.finances_select_specialist
																?.value
														}
													</legend>
													<Select
														className="specialist-select"
														classNamePrefix="specialist-select__prefix"
														placeholder={
															entities
																?.finances_select_specialist
																?.value
														}
														options={employeesInfo?.map(
															(item) => ({
																value: item?.id.toString(),
																label: `${item?.name} ${item?.family_name}`,
															})
														)}
														onChange={(value) =>
															setSelectedSpecialist(
																value
															)
														}
														value={
															selectedSpecialist
														}
														isClearable={true}
														components={{
															IndicatorSeparator:
																() => null,
														}}
													/>
												</fieldset>
											</div>
										)}
									{typeAdvertisement !== "email" && (
										<div className="form__row new-offer-form__block">
											<fieldset>
												<legend>
													{
														entities
															?.advertisement_select_number_of_days
															?.value
													}
												</legend>
												<div className="table">
													<table>
														<tbody>
															{(typeAdvertisement ===
																"banner"
																? specialistsBannerCost
																: specialistsTopCost
															)?.map(
																({
																	label,
																	value,
																}) => (
																	<tr
																		key={_.uniqueId()}
																	>
																		<td>
																			<div className="custom-toggle custom-toggle--radio">
																				<label
																					onChange={() =>
																						setDaysCount(
																							+label
																						)
																					}
																				>
																					<input
																						type="radio"
																						name="days"
																						value={
																							value
																						}
																						checked={
																							+daysCount ===
																							+label
																						}
																					/>
																					<span className="custom-toggle__icon" />
																					<span className="custom-toggle__label">
																						{`${label} ${entities?.common_days?.value}`}
																					</span>
																				</label>
																			</div>
																		</td>
																		<td>
																			{
																				value
																			}{" "}
																			€
																		</td>
																	</tr>
																)
															)}
														</tbody>
													</table>
												</div>
											</fieldset>
										</div>
									)}
									{typeAdvertisement !== "top" && (
										<div className="form__row form__row--big-margin">
											<h2 className="new-offer-form__title title title--f24">
												{
													entities
														?.advertisement_data_advertisement_data
														?.value
												}
											</h2>
											<div className="offer-card">
												<div className="offer-card__info">
													<fieldset>
														<legend>
															{
																entities
																	?.advertisement_select_three_services
																	?.value
															}
														</legend>
														{servicesTypes.map(
															({
																label,
																value,
																sub_types
															}) => (
															<>
																<div
																	key={value}
																	className={cn(
																		'custom-toggle custom-toggle--checkbox custom-toggle--green',
																		{ 'is-disabled': selectedServicesSubTypes.length >= 1 && !selectedServicesTypes?.find((el) => el.value === value)}
																	)}
																>
																	<label>
																		<input
																			type="checkbox"
																			onChange={() => handleChangeInputServicesType({ value, label })}
																			checked={!!selectedServicesTypes?.find((el) => el.value === value)} />
																		<span className="custom-toggle__icon" />
																		<span className="custom-toggle__label">{label}</span>
																	</label>
																</div>
																{!!selectedServicesTypes?.find((el) => el.value === value)
																	&& sub_types?.map(({ service_sub_type, custom_service_sub_type, id: sub_types_id }) => (<div className="custom-toggle__sublist">
																	<div
																		key={service_sub_type?.id}
																		className={cn(
																			'custom-toggle custom-toggle--checkbox custom-toggle--green',
																			{ 'is-disabled': (selectedServicesSubTypes.length >= 3 && !selectedServicesSubTypes?.find(({id}) => id === service_sub_type?.id)) || selectedServicesTypes?.length > 1}
																		)}
																	>
																		<label>
																			<input
																				type="checkbox"
																				onChange={() => handleChangeInputServicesSubType(service_sub_type?.name ? service_sub_type : { id: sub_types_id, name: custom_service_sub_type })}
																				checked={!!selectedServicesSubTypes?.find(({id}) => id === (service_sub_type?.id || sub_types_id))}
																			/>
																			<span className="custom-toggle__icon" />
																			<span className="custom-toggle__label">{service_sub_type?.name || custom_service_sub_type}</span>
																		</label>
																	</div>
																</div>))}
															</>
															)
														)}
														{!servicesTypes?.length && (
															<p className="chat__text">
																{
																	entities
																		?.profile_add_services_at_first
																		?.value
																}
															</p>
														)}
													</fieldset>
												</div>
												<div className="offer-card__column">
													<div className="upload-advertorial">
														<div className="upload-advertorial__header">
															{(imageAdvertisement && (
																<>
																	<div className="upload-advertorial__img">
																		<img
																			src={
																				imageAdvertisement.original_url
																			}
																			width="255"
																			height="192"
																			alt="avatar"
																		/>
																		<div className="upload-advertorial__overlay">
																			<button
																				className="btn btn--rounded btn--white"
																				type="button"
																				onClick={() =>
																					setVisiblePhotoGalleryServicesModal(
																						true
																					)
																				}
																			>
																				{
																					entities
																						?.common_change_photo
																						?.value
																				}
																			</button>
																		</div>
																	</div>
																	<Score
																		score={
																			8
																		}
																		withBackground
																		className="rating"
																		width={
																			10
																		}
																		height={
																			10
																		}
																	/>
																</>
															)) || (
																	<div className="upload-advertorial__placeholder">
																		<Icon
																			type={
																				"upload-advertorial"
																			}
																			width={
																				24
																			}
																			height={
																				24
																			}
																		/>
																		<p className="upload-advertorial__caption text">
																			{
																				entities
																					?.advertisement_photo
																					?.value
																			}
																		</p>
																		<button
																			className="btn btn--transparent btn--rounded"
																			type="button"
																			onClick={() =>
																				setVisiblePhotoGalleryServicesModal(
																					true
																				)
																			}
																		>
																			{
																				entities
																					?.specialist_details_add
																					?.value
																			}
																		</button>
																	</div>
																)}
														</div>
														{(!!selectedServicesSubTypes.length && (
															<ul className="upload-advertorial__items">
																{selectedServicesSubTypes.map(({id, name}) => (<li className="upload-advertorial__item" key={id}>{name}</li>))}
															</ul>
														)) || (!!selectedServicesTypes.length && (
															<ul className="upload-advertorial__items">
																{selectedServicesTypes.map(({value, label}) => (<li className="upload-advertorial__item" key={value}>{label}</li>))}
															</ul>
														)) || (
															<p className="upload-advertorial__content">{entities?.advertisement_no_selected?.value}</p>
														)}
														<UploadAdvertorialFooter
															entities={entities}
															languages={
																selectSpecialistInfo?.languages
															}
															dealsCount={
																selectSpecialistInfo?.deals_count
															}
														/>
													</div>
												</div>
											</div>
										</div>
									)}
									{showBanner.is_overloaded &&
										showBanner.date && (
											<div className="alert-box">
												<Icon
													className={
														"alert-box__icon"
													}
													type={
														"shield-current-color"
													}
													width={18}
													height={22}
													colorVariant={"orange"}
												/>
												<strong className="alert-box__title">
													{
														entities
															?.advertisement_all_spots_full
															?.value
													}
												</strong>
												<p className="text">
													{
														entities
															?.advertisement_will_appear_on
															?.value
													}{" "}
													{showBanner.date}
												</p>
											</div>
										)}
									<div className="form__controls">
										<button
											className="btn new-offer-form__btn btn--transparent"
											type="button"
											onClick={onClose}
										>
											{
												entities?.common_cancel_button
													?.value
											}
										</button>
										<button
											className="btn new-offer-form__btn btn--transparent btn--bg-green"
											type="button"
											onClick={() => setShowModal(true)}
											disabled={disabledBtnPay}
										>
											{entities?.advertisement_pay?.value}{" "}
											{price && <span>{price} €</span>}
										</button>
									</div>
								</form>
							</div>
						</section>
					</div>
				</div>
			</main>
		</>
	);
};

interface UploadAdvertorialFooterProps {
	languages?: IMainLanguage[];
	dealsCount?: number;
	entities?: TranslationObject;
}

const UploadAdvertorialFooter: React.FC<UploadAdvertorialFooterProps> = ({
	languages = [],
	dealsCount = 0,
	entities,
}) => {
	const currentLanguage = getCurrentLanguage();

	const countHiddenLanguages = ((languages) => {
		const languagesLength = languages.length;
		return languagesLength > 3 ? languagesLength - 3 : 0;
	})(languages);

	const specialistDetailsCountStr = chooseDeclension(
		dealsCount,
		entities?.specialist_details_deals?.value,
		currentLanguage === "ru"
	);

	return (
		<div className="upload-advertorial__footer">
			<div className="languages__wrapper">
				<ul className="languages__list">
					{languages.slice(0, 3).map(({ id, flag, name }) => (
						<li className="languages__item" key={id}>
							<img
								src={flag?.original_url || ""}
								width={22}
								height={16}
								alt={name || "flag"}
							/>
						</li>
					))}
				</ul>
				{countHiddenLanguages > 0 && (
					<span className="languages__add">
						+{countHiddenLanguages}
					</span>
				)}
			</div>
			<p className="text">
				<span className="upload-advertorial__count">{dealsCount}</span>
				{specialistDetailsCountStr}
			</p>
		</div>
	);
};

const useMyAndEmployeesInfo = (
	profileId?: number
): {
	myInfo: AdvertisementEmployee | null;
	employeesInfo: AdvertisementEmployee[];
	allEmployeesInfo: AdvertisementEmployee[];
} => {
	const { data: getAdvertisementEmployeesData } =
		advertisementEndpoints.getAdvertisementEmployees.useQuery();

	const { myInfo, employeesInfo, allEmployeesInfo } = useMemo(() => {
		if (profileId && getAdvertisementEmployeesData?.length) {
			const myInfo = getAdvertisementEmployeesData.find(
				(el) => el.id === profileId
			);

			const employeesInfo = getAdvertisementEmployeesData.filter(
				(el) => el.id !== profileId
			);

			return {
				myInfo: myInfo || null,
				employeesInfo,
				allEmployeesInfo: getAdvertisementEmployeesData,
			};
		}
		return {
			myInfo: null,
			employeesInfo: [],
			allEmployeesInfo: [],
		};
	}, [profileId, getAdvertisementEmployeesData]);

	return {
		myInfo,
		employeesInfo,
		allEmployeesInfo,
	};
};

const useSelectSpecialistInfo = (
	allEmployeesInfo: AdvertisementEmployee[],
	isPrivatePerson: boolean,
	profile?: ProfilePayload,
	selectSpecialistId?: number
): { selectSpecialistInfo: AdvertisementEmployee | null } => {
	let selectSpecialistInfo;

	if (isPrivatePerson && profile) {
		selectSpecialistInfo = {
			id: profile.id,
			name: profile.name,
			family_name: profile.family_name,
			deals_count: profile.deals_count,
			reviews_avg_score: profile.reviews_avg_score,
			user_role: profile.user_role,
			languages: profile.languages,
			services: profile.services,
		};
	} else {
		selectSpecialistInfo =
			allEmployeesInfo?.find(
				(el) => el.id === (selectSpecialistId || profile?.id)
			) || null;
	}

	return {
		selectSpecialistInfo,
	};
};

const useGetTypeModalProps = (
	isSuccess: boolean,
	isError: boolean
): TypeModal => {
	if (isError) {
		return "isError";
	} else if (isSuccess) {
		return "isSuccess";
	} else {
		return "cards";
	}
};

const useGetPriceAdvertisement = (
	typeAdvertisement: "banner" | "top" | "email",
	specialistsBannerCost: Array<{ label: string | number; value: number }>,
	specialistTopCost: Array<{ label: string | number; value: number }>,
	specialistMailingCost: number | null,
	daysCount: number,
	emailCount: number | null
): number | null => {
	if (typeAdvertisement === "banner" && daysCount) {
		const price = specialistsBannerCost.find(
			(el) => +el.label === +daysCount
		)?.value;

		return price || null;
	}
	if (typeAdvertisement === "top" && daysCount) {
		const price = specialistTopCost.find(
			(el) => +el.label === +daysCount
		)?.value;

		return price || null;
	}
	if (typeAdvertisement === "email" && emailCount && specialistMailingCost) {
		return specialistMailingCost * emailCount || null;
	}
	return null;
};

const useGetCurrentSpecialistPhotos = (
	currentSpecialist?: AdvertisementEmployee | null,
	selectServiceTypeIds?: number[]
): Image[] => {
	const services = currentSpecialist?.services;

	if (services?.length) {
		return services.reduce((acc: Image[], curr: IService) => {
			if (selectServiceTypeIds) {
				if (selectServiceTypeIds.includes(curr.id)) {
					return acc.concat(curr.photos);
				} else {
					return acc;
				}
			}
			return acc.concat(curr.photos);
		}, []);
	}
	return [];
};

import React from "react";
import _ from "lodash";
import { TranslationObject } from "../../../models/translationModal";

interface Props {
    entities?: TranslationObject;
    isDraft: boolean;
    isDropDown: boolean;
    publishOrder: () => void;
}

const PublishButton: React.FC<Props> = ({
    entities,
    isDraft,
    publishOrder,
    isDropDown = true,
}) => {
    const text = entities?.order_publish?.value;
    if (!isDraft) {
        return null;
    }

    return isDropDown ? (
        <>
            <div
                className="dotted-menu__item"
                onClick={publishOrder}
                key={_.uniqueId("button_dropdown_order")}
            >
                <div className="dotted-menu__item-link">{text}</div>
            </div>
        </>
    ) : (   
        <button
            onClick={publishOrder}
            className="btn active-order__button"
            type="button"
        >
            {text}
        </button>
    );
};

export default PublishButton;

import React from "react";
import { Language } from "../../../models/orderModel";
import _ from "lodash";

interface Props {
    shortListLanguages: Language[];
    languages: Language[];
}

const LanguagesSpecialist: React.FC<Props> = ({
    shortListLanguages,
    languages,
}) => {
    return (
        <>
            <ul className="languages__list">
                {shortListLanguages?.map((language: Language) => (
                    <li
                        className="languages__item popover"
                        key={_.uniqueId("language_specialist")}
                    >
                        <span className="popover__tip">{language?.name}</span>
                        <img
                            src={language?.flag?.original_url}
                            width="22"
                            height="16"
                            alt={language?.name || 'language'}
                        />
                    </li>
                ))}
            </ul>
            {languages?.length > 3 && (
                <span className="languages__add">
                    {` +${languages?.length - 3}`}
                </span>
            )}
        </>
    );
};

export default LanguagesSpecialist;

import React, { useState } from "react";
import Input from "../Input";
import Icon from "../../Icon";

interface inputPasswordProps {
    btnClassName: string;
    error: any;
    onChange: any;
    password: string;
    id: string;
    name: string;
    placeholder: string;
    inputClassName: [string];
    invalidFields: any;
    required?: boolean;
}

const InputPassword: React.FC<inputPasswordProps> = ({
    btnClassName,
    error,
    onChange,
    password,
    name,
    placeholder,
    inputClassName,
    invalidFields,
    required,
    id,
}) => {
    const [visible, setVisible] = useState<boolean>(false);
    const button = (
        <button
            className={btnClassName}
            type="button"
            onClick={() => setVisible(!visible)}
        >
            <Icon
                type={!visible ? "open-eyes" : "close-eyes"}
                width={!visible ? 24 : 20}
                height={!visible ? 22 : 18}
            />
        </button>
    );

    return (
        <Input
            type={!visible ? "password" : "text"}
            onChange={onChange}
            id={id}
            value={password}
            classes={inputClassName}
            name={name}
            placeholder={placeholder}
            required={required}
            invalidFields={invalidFields}
            error={error}
            button={button}
        />
    );
};
export default InputPassword;

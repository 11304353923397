import React, { Dispatch } from "react";
import Modal from "../../Modal";
import { TranslationObject } from "../../../models/translationModal";

interface Props {
    visible: boolean;
    isDraft?: boolean;
    onClose: () => unknown;
    entities?: TranslationObject;
    changeStatus: (value: string) => void;
}

const OrderClosingConfirmation: React.FC<Props> = ({
    visible,
    onClose,
    isDraft,
    entities,
    changeStatus,
}) => {
    const handleClick = () => {
        changeStatus("archive");
        onClose();
    };

    const title = (isDraft ? entities?.order_to_archive_delete_title?.value : entities?.order_to_archive_title?.value) || ''
    const text = (isDraft ? entities?.order_to_archive_delete_text?.value : entities?.order_to_archive_text?.value) || ''

    return (
        <Modal
            visible={visible}
            onClose={onClose}
            title={title}
        >
            <>
                <p className="modal__content-text">
                    {text}
                </p>
                <div className="modal__content-buttons">
                    <button
                        className="btn modal__content-button"
                        type="button"
                        onClick={onClose}
                    >
                        {entities?.order_to_archive_button_no?.value}
                    </button>
                    <button
                        className="btn modal__content-button modal__content-button--background"
                        type="button"
                        onClick={handleClick}
                    >
                        {entities?.order_to_archive_button_yes?.value}
                    </button>
                </div>
            </>
        </Modal>
    );
};

export default OrderClosingConfirmation;

import React, { useEffect, useMemo, useState } from "react";
import { INotificationListItem } from "../../models/notificationsModel";
import cn from "classnames";
import { useViewNotificationMutation } from "../../store/notifications/notifications.api";
import Icon from "../../components/Icon";
import { TranslationObject } from "../../models/translationModal";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { getCurrentLanguage } from "../../store/directories/selectors";
interface NotificationProps {
    notification: INotificationListItem;
    isSpecialist?: boolean;
    entities?: TranslationObject;
}

export const Notification: React.FC<NotificationProps> = ({
    notification,
    isSpecialist = false,
    entities,
}) => {
    const { id, text, created_at, type, viewed_at, files, payload, code } =
        notification || {};
    const currentLanguage = getCurrentLanguage();

    const notificationText = useFormatNotificationText(
        code ? entities?.[code]?.value ?? "" : text,
        payload,
        code,
        currentLanguage,
        isSpecialist
    );

    const formatDateCreated = moment(created_at).format("DD.MM.YYYY");

    const [viewed, setViewed] = useState<boolean>(false);

    const [limitText, setLimitText] = useState<boolean>(true);

    const [viewNotification, { isLoading, isSuccess }] =
        useViewNotificationMutation();

    useEffect(() => {
        setViewed(!!viewed_at);
    }, [viewed_at]);

    useEffect(() => {
        if (isSuccess) {
            setViewed(true);
        }
    }, [isSuccess]);

    const viewNotificationHandler = () => {
        if (viewed_at || isLoading || isSuccess) return;
        viewNotification(id);
    };

    return (
        <>
            <li
                className={cn("notice", {
                    ["system-notification"]: type === "system",
                    ["no-viewed"]: !viewed,
                    ["viewed"]: viewed,
                })}
                onClick={viewNotificationHandler}
            >
                <p className="notice__subtitle">
                    {type === "system" &&
                        entities?.system_notification_title?.value}
                    {type === "admin" &&
                        entities?.admin_notification_title?.value}
                </p>
                <p
                    className={cn("notice__text", {
                        ["limit"]: limitText,
                    })}
                >
                    {notificationText.children}
                </p>
                {notificationText.text?.length > 256 && (
                    <a
                        className="link link--underline notice__link-more"
                        onClick={() => setLimitText((state) => !state)}
                    >
                        {limitText
                            ? entities?.common_expand?.value
                            : entities?.common_collapse?.value}
                    </a>
                )}
                {files?.map((file) => (
                    <a
                        key={file?.id}
                        className="link link--underline notice__link-file"
                        href={file?.original_url}
                        download={file?.file_name}
                    >
                        {file?.file_name}
                        <Icon
                            type={"file-current-color"}
                            width={16}
                            height={20}
                            colorVariant={isSpecialist ? "greenLight" : "blue"}
                        />
                    </a>
                ))}
                <time
                    className="notice__date-time"
                    dateTime={formatDateCreated}
                >
                    {formatDateCreated}
                </time>
            </li>
        </>
    );
};

const useFormatNotificationText = (
    text: string,
    payload: Record<string, string> | null,
    code: string | null,
    currentLanguage: string,
    isSpecialist: boolean
) => {
    return useMemo(() => {
        if (text && payload) {
            const isContainsLink = /link/g.test(code || "");

            if (text.includes('συνομιλία') || text.includes('chat') || text.includes('чате') || text.includes('чат')) {
                const wordsToReplace = ['συνομιλία', 'chat', 'чате', 'чат'];
                let replaced = false;

                wordsToReplace.forEach(word => {
                    if (!replaced && text.includes(word)) {
                        text = text.replace(word, `<a class='notice__link' href='/en/customer/lk/order/${payload.order_id}/chat/${payload.chat_id}'>${word}</a>`);
                        replaced = true;
                    }
                });
            }

            if (text.includes('новый заказ') || text.includes('new order') || text.includes('νέα παραγγελία')) {
                const wordsToReplace = ['новый заказ', 'new order', 'νέα παραγγελία'];
                let replaced = false;

                wordsToReplace.forEach(word => {
                    if (!replaced && text.includes(word)) {
                        text = text.replace(word, `<a class='notice__link' href='/en/specialist/lk/order/${payload.order_id}'>${word}</a>`);
                        replaced = true;
                    }
                });
            }

            if (text.includes(':orderName') && !payload.chatId) {
                text = text.replace(':orderName', `<em><strong><a href="/en/customer/lk/order/${payload.order_id}/">${payload.orderName}</a></strong></em>`);
            }

            if (text.includes(':orderName') && payload.chatId) {
                text = text.replace(':orderName', `<em><strong><a href="/en/customer/lk/order/${payload.order_id}/chat/${payload.chatId}">${payload.orderName}</a></strong></em>`);
            }

            for (const key in payload) {
                if (!['slug', 'specialist'].includes(key)) {
                    text = text.replace(
                        `:${key}`,
                        `${isContainsLink ? payload[key] || "" : `<em><strong>${payload[key] || ""}</strong></em>`}`
                    );
                }
            }

            if (text.includes(':specialist') && payload.slug) {
                const splitText = text.split(':specialist');

                return {
                    text: `${splitText[0]}${payload.specialist}${splitText[1]}`,
                    children: <span>
                        <span dangerouslySetInnerHTML={{ __html: splitText[0] }}></span>
                        <Link 
                            to={`/${currentLanguage}/specialist/${payload.slug}`}
                            state={window?.location?.pathname}
                        >
                            <em><strong>{payload.specialist}</strong></em>
                        </Link>
                        <span dangerouslySetInnerHTML={{ __html: splitText[1]}}></span>
                    </span>
                };
            } else {
                text = text.replace(':specialist', `<em><strong>${payload?.specialist}</strong></em>` || '');

                return {
                    text,
                    children: <span dangerouslySetInnerHTML={{ __html: text }} />
                }
            }
        }
        return {
            text,
            children: <span dangerouslySetInnerHTML={{ __html: text }} />
        };
    }, [payload, text]);
};

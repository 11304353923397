import React, { useMemo, useState } from "react";
import Select from "react-select";
import { ISelectCard } from "../../BankCardsModal";
import { financesEndpoints } from "../../../../../store/finances/finances.api";
import { TranslationObject } from "../../../../../models/translationModal";

interface CardsProps {
    buttonNewCardHandler: () => unknown;
    confirmModalHandler: (selectCard: ISelectCard | null) => unknown;
    price?: number | null;
    entities?: TranslationObject;
}

export const Cards: React.FC<CardsProps> = (props) => {
    const { buttonNewCardHandler, confirmModalHandler, price, entities } =
        props || {};

    const [cardsSelect, setCardsSelect] = useState<ISelectCard | null>(null);

    const { data: bankCardsData, isLoading: isLoadingCards } =
        financesEndpoints.getBankCards.useQuery();

    const bankCardOptions = useMemo(() => {
        if (bankCardsData?.length) {
            return bankCardsData?.map((card) => {
                if (card?.is_primary) {
                    setCardsSelect({
                        value: card?.id,
                        label: `XXXX XXXX XXXX ${card?.last_four}`,
                    });
                }
                return {
                    value: card?.id,
                    label: `XXXX XXXX XXXX ${card?.last_four}`,
                };
            });
        } else {
            return [];
        }
    }, [bankCardsData]);

    return (
        <>
            <div className={"select-cards__container"}>
                <label>
                    {entities?.finances_choose_card_for_payment?.value}
                </label>
                <Select
                    className={"select-cards"}
                    classNamePrefix={"select-cards__prefix"}
                    options={bankCardOptions}
                    value={cardsSelect}
                    onChange={(value) => value && setCardsSelect(value)}
                    placeholder={
                        isLoadingCards
                            ? "Loading..."
                            : entities?.common_list_is_empty?.value
                    }
                    isDisabled={isLoadingCards}
                    components={{
                        IndicatorSeparator: () => null,
                    }}
                />
            </div>
            <div className="modal__content-buttons">
                <button
                    className="btn modal__btn btn--transparent"
                    type="button"
                    onClick={buttonNewCardHandler}
                >
                    {entities?.advertisement_add_new_card?.value}
                </button>
                <button
                    className="btn modal__btn btn--bg-green btn--transparent"
                    type="button"
                    disabled={!cardsSelect}
                    onClick={() => confirmModalHandler(cardsSelect)}
                >
                    {entities?.advertisement_pay?.value}{" "}
                    {price && ` ${price} €`}
                </button>
            </div>
        </>
    );
};

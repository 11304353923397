import { financesEndpoints } from "../../../../store/finances/finances.api";
import { useEffect, useState } from "react";
import { IHistoryPayment } from "../../../../models/financesModel";
import { IUsePayment } from "./usePayments.types";
import { getPages } from "../../../../untils";
import { paymentsSelector } from "../../../../store/finances/finances.selectors";
import { useSelector } from "react-redux";
import { useActions } from "../../../../hooks/actions";
import moment from "moment";

export const usePayments = (): IUsePayment => {
    const [
        getPayments,
        {
            data: getPaymentsData,
            isSuccess: isSuccessGetPayments,
            isError: isErrorGetPayments,
        },
    ] = financesEndpoints.getPayments.useLazyQuery();

    const { setCurrentPage } = useActions();

    const { selectEmployee, currentPage, dateTo, dateFrom } =
        useSelector(paymentsSelector);

    const [paymentsHistory, setPaymentsHistory] = useState<IHistoryPayment[]>(
        []
    );

    const [pages, setPages] = useState<number[]>([]);

    useEffect(() => {
        const params = {
            per_page: 15,
            page: currentPage,
            specialist_id: Number(selectEmployee?.value) || undefined,
            from: dateFrom ? moment(dateFrom).format("DD.MM.YYYY") : undefined,
            to: dateTo ? moment(dateTo).format("DD.MM.YYYY") : undefined,
        };
        getPayments(params);
    }, [currentPage, selectEmployee, dateTo, dateFrom]);

    useEffect(() => {
        if (isSuccessGetPayments) {
            if (getPaymentsData?.items) {
                setPaymentsHistory(getPaymentsData?.items);
            }
            if (getPaymentsData?.total) {
                const count = Math.ceil(getPaymentsData.total / 15);
                if (count >= 1) {
                    const pages = getPages(count);
                    setPages(pages);
                }
            }
            if (
                getPaymentsData?.current_page &&
                getPaymentsData?.current_page !== currentPage
            ) {
                setCurrentPage(getPaymentsData.current_page);
            }
        } else if (isErrorGetPayments) {
            setPaymentsHistory([]);
            setPages([]);
            setCurrentPage(1);
        }
    }, [getPaymentsData, isSuccessGetPayments, isErrorGetPayments]);

    return {
        paymentsHistory,
        currentPage,
        pages,
    };
};

import React, { useState } from "react";
import FeedBackForm from "../../components/FeedBackForm";
import { ComponentProps } from "../../models/models";
import { ProfilePayload } from "../../models/userModels";
import { BlocksBaseObject } from "../../models/homePageModel";
import PageLoaders from "../../components/Loaders/PageLoaders";

interface Props extends ComponentProps {
    profile?: ProfilePayload;
}

const ContactAs: React.FC<Props> = ({ entities, currentLanguage, profile }) => {
    const [blocks, setBlocks] = useState<BlocksBaseObject | null>(null);

    const { feedback_title, feedback_text, feedback_type_list } = blocks || {};

    return (
        <PageLoaders
            type="custom_pages"
            code="write_us"
            isCustomPage
            updateData={setBlocks}
            currentLanguage={currentLanguage}
        >
            <main>
                <section className="feedback-page">
                    <div className="container">
                        <div className="feedback-form form">
                            <h1
                                className="title title--h1"
                                dangerouslySetInnerHTML={{
                                    __html: feedback_title?.value,
                                }}
                            ></h1>
                            <p
                                className="feedback-form__text"
                                dangerouslySetInnerHTML={{
                                    __html: feedback_text?.value,
                                }}
                            ></p>
                            <FeedBackForm
                                entities={entities}
                                currentLanguage={currentLanguage}
                                type="write_us"
                                profile={profile}
                                feedbackTypeList={feedback_type_list}
                                feedbackTypeListTitle={
                                    blocks?.feedback_type_title?.value
                                }
                            />
                        </div>
                    </div>
                </section>
            </main>
        </PageLoaders>
    );
};

export default ContactAs;

import React, {
    ReactNode,
    useState,
    ChangeEvent,
    useEffect,
    useRef,
    FormEvent,
} from "react";
import Control from "../Control";
import cn from "classnames";
import { InvalidFieldsProps } from "../Input";
import { TranslationObject } from "../../../models/translationModal";

interface InputProps {
    type: string;
    invalidFields?: InvalidFieldsProps;
    label?: string;
    id?: string;
    error?: any;
    name?: string;
    defaultValue?: string;
    untaggedRequired?: boolean;
    required?: boolean;
    disabled?: boolean;
    children?: ReactNode;
    refInput?: React.MutableRefObject<HTMLTextAreaElement | null>;
    controlMessage?: string;
    classes?: string[];
    placeholder?: string;
    onChange?: (value: string) => void;
    onInput?: (value: string) => void;
    onEnter?: (e: KeyboardEvent) => void;
    autoSize?: boolean;
    lengthError?: boolean;
    customLabel?: string;
    customLabelClasses?: string;
    entities?: TranslationObject;
    maxLength?: number;
}

const Input: React.FC<InputProps> = ({
    invalidFields,
    label,
    id,
    error,
    name,
    untaggedRequired,
    required,
    disabled,
    children,
    refInput,
    controlMessage,
    placeholder,
    classes,
    defaultValue,
    onChange,
    onInput,
    onEnter,
    autoSize = false,
    lengthError = false,
    customLabel,
    customLabelClasses,
    entities,
    maxLength = 500,
    ...otherProps
}) => {
    const [value, setValue] = useState("");

    const localTextAreaRef =
        refInput ?? useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        } else {
            setValue("");
        }
    }, [defaultValue]);

    useEffect(() => {
        const textarea = localTextAreaRef?.current;
        if (textarea) {
            textarea.style.height = "inherit";
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }, [value, localTextAreaRef.current]);

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const target = e.target as HTMLTextAreaElement;
        if (onChange) {
            onChange(target.value);
        }
        setValue(target.value);
    };

    const handleInput = (e: FormEvent<HTMLTextAreaElement>) => {
        const target = e.target as HTMLTextAreaElement;

        onInput?.(target.value);
        setValue(target.value);
    };

    const onEnterPress = (e: KeyboardEvent) => {
        if (e.key === 'Enter' && e.shiftKey === false && onEnter) {
            e.preventDefault()
            onEnter(e);
        }
    }

    return (
        <>
            <Control
                id={id}
                label={label}
                error={error}
                name={name}
                classes={classes}
                required={required}
                invalidFields={invalidFields}
                controlMessage={controlMessage}
            >
                {customLabel && (
                    <span
                        className={cn({
                            [`${customLabelClasses}`]: !!customLabelClasses,
                        })}
                    >
                        {customLabel}
                    </span>
                )}
                <textarea
                    name={name}
                    ref={localTextAreaRef}
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    onInput={handleInput}
                    required={required || untaggedRequired}
                    disabled={disabled}
                    maxLength={maxLength}
                    onKeyDown={onEnterPress}
                    {...otherProps}
                />
                {maxLength && (
                    <span className={"symbol-counter"}>
                        {value?.length}/{maxLength}
                    </span>
                )}
                {children}
            </Control>
            {lengthError && (
                <span className="max-length-error">
                    {entities?.common_character_limit_exceeded?.value || ""}
                </span>
            )}
        </>
    );
};

export default Input;

import React from "react";

interface SectionBlockProps {
    title: string;
    children: React.ReactNode;
    show?: boolean;
}

export const SectionBlock: React.FC<SectionBlockProps> = ({
    title,
    children,
    show = true,
}) => {
    if (!show) {
        return null;
    }

    return (
        <div className="section-block">
            <h2 className="section-block__title">{title}</h2>
            {children}
        </div>
    );
};

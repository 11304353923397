import { getCookie, setCookie } from "../../untils/cookies";

export const setTokenCookie = ({
    access_token,
    expires_in,
}: {
    access_token?: string;
    expires_in?: number;
}) => {
    if (!access_token || !expires_in) {
        return;
    }
    const agreeWithCookie = getCookie("agreeWithCookie");

    if (agreeWithCookie) {
        setCookie(
            "tkn",
            JSON.stringify({
                access_token,
                expires_in,
            })
        );
    }
};

import { useAppDispatch } from "../../hooks/useAppDispatch";
import { OrderItem } from "../../models/ordersModel";
import { store } from "../../store";
import { util } from "../../store/orders/orders.api";
import { checkOrderMatchesFilters } from "../../store/orders/selectors";

export const useAddNewOrderInList = () => {
    const dispatch = useAppDispatch();

    return (
        order: OrderItem, type: OrderItem['status']
    ) => {
        const state = store.getState();
        const filters = state?.filterOrders

        if (!checkOrderMatchesFilters({ filters, order, type })) {
            return
        }

        const endpoints = util.selectInvalidatedBy(state, [
            { type: "Orders", id: "getOrders" },
        ]);

        endpoints.forEach((endpoint) => {
            const { originalArgs } = endpoint;

            dispatch(
                util.updateQueryData('getOrders', originalArgs, (list) => {
                    list.items = [order, ...list.items]
                })
            );
        });
    };
};
import React from "react";
import Modal from "../Modal";
import _ from "lodash";
import cn from "classnames";
import { isSpecialistUrl } from "../../store/directories/selectors";

interface Props {
    title: string;
    onClose: () => void;
    visible: boolean;
    description: string;
    isSpecialist?: boolean;
}

const WarnModal: React.FC<Props> = ({
    title,
    onClose,
    visible,
    description,
    isSpecialist = false,
}) => {


    return (
        <Modal
            key={_.uniqueId("warn-modal")}
            title={title}
            onClose={onClose}
            description={description}
            visible={visible}
            modalClasses="warn-modal"
        >
            <div className="modal__content-buttons">
                <button
                    className={cn("btn modal__content-button", {
                        "btn--green": isSpecialist || isSpecialistUrl(),
                    })}
                    type="button"
                    onClick={onClose}
                >
                    Ok
                </button>
            </div>
        </Modal>
    );
};

export default WarnModal;

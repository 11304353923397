import { request } from "../request";
import {
    // createNewChatRequestData,
    GetChatMessagesRequest,
    // makeOfferOrderRequestData,
    SendMessageToChatRequest,
    SendMessageToChatNotAuthRequest,
    // SendMessageToChatAiRequest,
    // ViewChatMessageRequest,
} from "../../models/chatModels";

export const createNewChat = () =>
    request({
        url: `/support_chat`,
        method: "POST",
    });

export const getChatInfo = (chatId: string | number) =>
    request({
        url: `/support_chat/${chatId}`,
        method: "GET",
    });

export const sendMessageToChat = ({ chatId, body }: SendMessageToChatRequest) =>
    request({
        url: `/support_chat/${chatId}/messages/send`,
        method: "POST",
        body,
    });

export const sendMessageToChatNotAuth = ({ body }: SendMessageToChatNotAuthRequest) =>
    request({
        url: `/support_chat/anonymous-messages/send`,
        method: "POST",
        body,
    });

export const getChatMessages = ({ chatId, params }: GetChatMessagesRequest) =>
    request({
        url: `/support_chat/${chatId}/messages`,
        method: "GET",
        params,
    });

// export const viewChatMessage = ({
//     messageId,
//     chatId,
// }: ViewChatMessageRequest) =>
//     request({
//         url: `/chats/messages/${messageId}/view`,
//         method: "PUT",
//         body: {
//             chat: chatId,
//             message: messageId,
//         },
//     });

// export const makeOfferOrder = ({ orderId, body }: makeOfferOrderRequestData) =>
//     request({
//         url: `orders/${orderId}/make_offer`,
//         body,
//         method: "POST",
//     });

// export const acceptAndContinueSearch = (orderOfferId: number) =>
//     request({
//         url: `order_offers/${orderOfferId}/accept_and_continue_search`,
//         method: "POST",
//     });

// export const acceptAndStopSearch = (orderOfferId: number) =>
//     request({
//         url: `order_offers/${orderOfferId}/accept_and_stop_search`,
//         method: "POST",
//     });

// export const declineOfferOrder = (orderOfferId: number) =>
//     request({
//         url: `order_offers/${orderOfferId}/decline`,
//         method: "POST",
//     });

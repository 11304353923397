import React, { useEffect } from "react";
import CheckList from "../../../components/CheckList";
import { endpoints } from "../../../store/directories/directories.api";
import { getFilter } from "../../../store/orders/selectors";
import { useActions } from "../../../hooks/actions";
import Icon from "../../../components/Icon";
import {
    SERVICE_CATEGORY_ID,
    SERVICE_TYPE_ID,
} from "../../../store/order/constant";
import { TranslationObject } from "../../../models/translationModal";
import { ProfilePayload } from "../../../models/userModels";
import cn from "classnames";

interface Props {
    entities?: TranslationObject;
    profile?: ProfilePayload;
    setActiveMobileFilter: () => void;
    activeMobileFilter: string;
}

const TypeFilter: React.FC<Props> = ({
    entities,
    profile,
    setActiveMobileFilter,
    activeMobileFilter,
}) => {
    const { setOrdersFilter, clearOrderFilter } = useActions();
    const categoryId = getFilter(SERVICE_CATEGORY_ID);
    const name = SERVICE_TYPE_ID;
    const id = getFilter(SERVICE_TYPE_ID);
    const [getTypes, { data: types, isSuccess }] =
        endpoints.getServiceTypes.useLazyQuery();

    const isSpecialist = profile?.user_role === "specialist";
    const profileCategories =
        (isSpecialist &&
            profile?.services?.map((service) => service?.service_type?.id)) ||
        [];

    const specialistTypes =
        types?.filter((elem) => profileCategories?.indexOf(elem.id) !== -1) ||
        [];

    useEffect(() => {
        if (categoryId) {
            getTypes({
                id: categoryId,
            });
        }
    }, [categoryId]);

    const onChange = (i: number) => {
        if (id === i) {
            return clearOrderFilter(name);
        }
        setOrdersFilter({ name, value: [] });
        setOrdersFilter({
            name,
            value: i,
        });
    };

    if (!categoryId || (types && types?.length < 1)) {
        return null;
    }

    return (
        <div
            className={cn("filter__block", {
                "is-open": activeMobileFilter === "type" && categoryId,
            })}
        >
            <button
                className="filter__subtitle"
                onClick={setActiveMobileFilter}
                type="button"
            >
                {entities?.order_service_type?.value}
                <Icon type="arrow-left-pagination" width={9} height={18} />
            </button>
            <div className="filter__wrap-block">
                <CheckList
                    entities={entities}
                    isSuccess={isSuccess}
                    onChange={onChange}
                    items={isSpecialist ? specialistTypes : types || []}
                    id={id}
                />
            </div>
        </div>
    );
};

export default TypeFilter;

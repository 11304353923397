import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { changeHeaders } from "../request";
import { EmptyPayload } from "../models";
import { registerAsPrivatePerson, setRegistrationCompany, getRegistrationCompany } from "./api";
import { CompanyPayload, CompanyRootObject } from "../../models/registrationModels";

export const registrationApi = createApi({
    reducerPath: "registration/api",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/v1/",
        prepareHeaders: (headers, { getState }) =>
            changeHeaders(headers, { getState }),
    }),
    tagTypes: ["Registration", "Localized"],
    endpoints: (build) => ({
        setRegistrationCompany: build.mutation<EmptyPayload, FormData>({
            query: (requestData) => setRegistrationCompany(requestData),
            invalidatesTags: [{ type: "Registration" }, { type: "Localized" }],
        }),
        registerAsPrivatePerson: build.mutation<EmptyPayload, void>({
            query: registerAsPrivatePerson,
            invalidatesTags: [{ type: "Registration" }, { type: "Localized" }],
        }),
        getRegistrationCompany: build.query<CompanyPayload, string>({
            query: (str) => getRegistrationCompany(str),
            providesTags: [{ type: "Registration" }, { type: "Localized" }],
            transformResponse: (response: CompanyRootObject) =>
                response.payload,
        }),
    }),
});

export const {
    endpoints,
    useSetRegistrationCompanyMutation,
    useRegisterAsPrivatePersonMutation,
    util,
} = registrationApi;

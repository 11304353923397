import React, { useEffect, useState } from "react";
import RadioInput from "../../components/Form/RadioInput";
import Input from "../../components/Form/Input";
import { getItemStore } from "../../untils";
import { FiltersPayload } from "../../models/directoryModel";
import { FILTER_PAGES } from "../../store/order/constant";
import { useActions } from "../../hooks/actions";
import { FilterProps } from "../../models/orderModel";
import {
    getItemCreateOrder,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import { createOrderState } from "../../store/order/createOrder.slice";
import { TranslationObject } from "../../models/translationModal";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

interface Props {
    filter: FiltersPayload;
    update?: () => void;
    hiddenInput?: boolean;
    isEdit: boolean;
    entities?: TranslationObject;
}

const SingleFilter: React.FC<Props> = ({
    filter,
    update,
    hiddenInput,
    isEdit,
    entities,
}) => {
    const { updateEditOrderField, updateCreateOrderField } = useActions();
    const filterPages = isEdit
        ? getItemStore(FILTER_PAGES)
        : getItemCreateOrder(FILTER_PAGES);
    const [id, setId] = useState<number | null>(null);
    const [customFilterValue, setCustomFilterValue] = useState<string | number>(
        ""
    );
    const [showLongList, setShowLongList] = useState<boolean>(false);

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) =>
        updateItemCreateOrder(
            {
                name: name,
                value: value,
            },
            updateCreateOrderField
        );

    useEffect(() => {
        if (filterPages) {
            const currentFilter = filterPages.find(
                (elem: FilterProps) => elem.service_type_filter_id === filter.id
            );
            if (filter) {
                setId(currentFilter?.service_type_filter_value_ids);
                if (currentFilter?.custom_filter_value) {
                    setCustomFilterValue(currentFilter?.custom_filter_value);
                }
            }
        }
    }, []);

    useEffect(() => {
        if (!isEdit) {
            const store = getItemStore(FILTER_PAGES);
            if (store) {
                setId(
                    store?.find(
                        (elem: FilterProps) =>
                            elem["service_type_filter_id"] == filter?.id
                    )?.service_type_filter_value_ids[0] || null
                );
                setCustomFilterValue(
                    store?.find(
                        (elem: FilterProps) =>
                            elem["service_type_filter_id"] == filter?.id
                    )?.custom_filter_value || ""
                );
            }
        }
    }, []);

    const handleClick = (id: number) => {
        setId(id);
    };

    const updateFiltersEditOrder = () => {
        updateEditOrderField({
            name: FILTER_PAGES,
            value: [
                ...filterPages.filter(
                    (elem: FilterProps) =>
                        elem?.service_type_filter_id !== filter.id
                ),
                getFilter(),
            ],
        });
    };

    useEffect(() => {
        if (id || customFilterValue) {
            isEdit ? updateFiltersEditOrder() : updateLocalStage();
        }
    }, [id, customFilterValue]);

    const getFilter = () => {
        if (id) {
            let obj: FilterProps = {
                service_type_filter_id: filter?.id,
                service_type_filter_value_ids: [id],
            };
            if (filter?.has_custom_value && customFilterValue) {
                obj = {
                    ...obj,
                    custom_filter_value: customFilterValue,
                };
            }
            return obj;
        }
    };

    const updateLocalStage = () => {
        const newItems = filterPages?.map((item: any) => {
            const has_service_type_filter_id =
                Object.prototype.hasOwnProperty.call(
                    item,
                    "service_type_filter_id"
                );

            if (
                has_service_type_filter_id &&
                item["service_type_filter_id"] == filter?.id
            ) {
                return getFilter();
            }

            return item;
        });

        updateField({
            name: FILTER_PAGES,
            value: newItems,
        });
        if (update) {
            update();
        }
    };

    const handleChange = (value: string | number) => {
        setCustomFilterValue(value);
    };

    return (
        <>
            <SimpleBar autoHide={false} forceVisible={true} style={{ maxHeight: 500 }}>
                <div className="form__row">
                    {(showLongList
                        ? filter?.values
                        : filter?.values?.slice(0, 10)
                    )?.map((value: any, key: number) => (
                        <RadioInput
                            checked={value.id == id}
                            onClick={() => handleClick(value?.id)}
                            key={key}
                            name={value?.service_type_filter_id}
                            label={value?.name}
                            value={value?.service_type_filter_id}
                        />
                    ))}
                </div>
            </SimpleBar>
            {filter?.values?.length > 10 && (
                <button
                    className="btn btn--view-all"
                    type="button"
                    onClick={() => setShowLongList(!showLongList)}
                >
                    {showLongList
                        ? entities?.order_hide?.value
                        : entities?.order_show_all?.value}
                </button>
            )}
            {filter?.has_custom_value && !hiddenInput && (
                <fieldset>
                    <legend>
                        {entities?.order_filter_custom_answer_details?.value}
                    </legend>
                    <Input
                        onChange={handleChange}
                        type="text"
                        disabled={!id}
                        placeholder=""
                        classes={[
                            "custom-input--text-right custom-input--small",
                        ]}
                        value={customFilterValue}
                    />
                </fieldset>
            )}
        </>
    );
};

export default SingleFilter;

import React, { useState } from "react";
import { SpecialistsSelect } from "./SpecialistsSelect";
import { DateInputs } from "./DateInputs";
import { MenuNav } from "./MenuNav";
import { TablePayments } from "./TablePayments";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { usePayments } from "../../hooks";
import {
    DateFilterType,
    SpecialistRoleType,
} from "../../../../store/finances/finances.types";
import { useSelector } from "react-redux";
import {
    datesFilterSelector,
    selectEmployeeSelector,
} from "../../../../store/finances/finances.selectors";
import { useLoading } from "../../../../hooks/useLoading/useLoading";
import { TranslationObject } from "../../../../models/translationModal";
import { useNotification } from "../../../../hooks/useNotification";
import { getTkn } from "../../../../store/user/selectors";
import moment from "moment";
import { formatQueryString } from "../../../../untils";

interface PaymentHistoryProps {
    specialistRole: SpecialistRoleType;
    entities?: TranslationObject;
}

type errors = { dateTo: boolean, dateFrom: boolean }


export const PaymentHistory: React.FC<PaymentHistoryProps> = ({
    specialistRole,
    entities,
}) => {
    const [errorsValidaions, setErrorsValidaions] = useState<string[]>([])

    const { width } = useWindowSize();

    const token = getTkn();

    const { setLoading } = useLoading();

    const { setNotification } = useNotification();

    const { paymentsHistory, pages, currentPage } = usePayments();

    const { dateFrom, dateTo } = useSelector(datesFilterSelector) || {};

    const { value: specialistId } = useSelector(selectEmployeeSelector) || {};

    const checkValidationsErrors = ({ dateTo, dateFrom }: errors) => {
        const unValidatedFields: string[] = []
        setErrorsValidaions(unValidatedFields)

        if (!dateTo) {
            unValidatedFields.push('dateTo')
        }
        if (!dateFrom) {
            unValidatedFields.push('dateFrom')
        }

        setLoading?.(false);
        setErrorsValidaions(unValidatedFields)
    }

    const clearValidationError = (str: string) => {
        setErrorsValidaions(errorsValidaions.filter(elem => elem !== str))
    }

    return (
        <>
            <div className="payment-history">
                <div className="payment-history__filters">
                    {width > 767 && (
                        <>
                            {specialistRole === "manager" && (
                                <SpecialistsSelect entities={entities} />
                            )}
                            <DateInputs entities={entities} errorsValidaions={errorsValidaions} clearValidationError={clearValidationError} />
                            {specialistRole !== "employee" && (
                                <>
                                    <button
                                        className="btn modal__btn btn--bg-green btn--transparent"
                                        type="button"
                                        onClick={() =>
                                            handleClickButtonDownload(
                                                token || "",
                                                specialistId,
                                                dateFrom,
                                                dateTo,
                                                setLoading,
                                                checkValidationsErrors
                                            )
                                        }
                                    >
                                        {
                                            entities?.finances_download_invoice
                                                ?.value
                                        }
                                    </button>
                                    <MenuNav
                                        dateFrom={dateFrom}
                                        dateTo={dateTo}
                                        setLoading={setLoading}
                                        setNotification={setNotification}
                                        entities={entities}
                                        checkValidationsErrors={checkValidationsErrors}
                                    />
                                </>
                            )}
                        </>
                    )}
                    {width <= 767 && (
                        <>
                            {specialistRole === "manager" && (
                                <SpecialistsSelect entities={entities} />
                            )}
                            <DateInputs entities={entities} errorsValidaions={errorsValidaions} clearValidationError={clearValidationError} />
                        </>
                    )}
                </div>
                <TablePayments
                    paymentsHistory={paymentsHistory}
                    pages={pages}
                    currentPage={currentPage}
                    entities={entities}
                />
            </div>
        </>
    );
};


function handleClickButtonDownload(
    token: string,
    specialistId?: string | number,
    dateFrom?: DateFilterType,
    dateTo?: DateFilterType,
    setLoading?: (value: boolean) => unknown,
    checkValidationsErrors: ({ dateTo, dateFrom }: errors) => unknown
) {
    setLoading?.(true)

    if (!(dateTo && dateFrom)) {
        const params: errors = { 'dateTo': !!dateTo, 'dateFrom': !!dateFrom }

        return checkValidationsErrors(params)
    }


    const params = formatQueryString({
        from: dateFrom ? moment(dateFrom).format("DD.MM.YYYY") : null,
        to: dateTo ? moment(dateTo).format("DD.MM.YYYY") : null,
        specialist_id: specialistId || null,
    });

    fetch(`/api/v1/payments/invoice?${params}`, {
        method: "GET",
        headers: new Headers({
            Authorization: "Bearer " + token,
        }),
    })
        .then((response) => {
            if (response?.ok) {
                return response.blob();
            }
            throw new Error("Error download file");
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "invoice.xlsx";
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            a.remove();
        })
        .catch((err) => console.error(err?.message))
        .finally(() => setLoading?.(false));
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getTokenCookie } from "../request";

export interface userState {
    user_role: string;
    tkn: string | null;
    urlAfterAuthorization: string | null;
    email: string;
}

interface Field {
    name: string;
    value: string & null;
}

const initialState: userState = {
    user_role: "guest",
    tkn: getTokenCookie() || null,
    urlAfterAuthorization: null,
    email: "",
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        updateUserField(state, action: PayloadAction<Field>) {
            state[action.payload.name as keyof userState] =
                action.payload.value;
        },
        updateUser(state, action: PayloadAction<userState>) {
            state = Object.assign(state, action.payload);
        },
        clearTkn(state: userState) {
            state = Object.assign(state, { tkn: null });
        },
    },
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;

import React, { useEffect, useState } from "react";
import { ComponentProps } from "../../models/models";
import { ProfilePayload } from "../../models/userModels";
import { NewAdvertisement } from "./elements";
import { useAdvertisementData } from "./hooks";
import cn from "classnames";
import { getSpecialistRole } from "../ProfileSpecialist/utils";
import { TranslationObject } from "../../models/translationModal";
import { useLocation } from "react-router-dom";
import { InDevelopmentNotification } from "../../components/Modals/InDevelopmentNotification/InDevelopmentNotification";

interface AdvertisementProps extends ComponentProps {
    profile?: ProfilePayload;
    entities?: TranslationObject;
}

export const Advertisement: React.FC<AdvertisementProps> = ({
    profile,
    entities,
}) => {
    const [newAdvertisement, setNewAdvertisement] = useState<{
        show: boolean;
        type: "banner" | "top" | "email";
    }>({
        show: false,
        type: "banner",
    });

    const [showInDevelopmentNotification, setShowInDevelopmentNotification] =
        useState<boolean>(false);

    const { myBanner, myTop, myEmail, companyEmployees } = useAdvertisementData(
        profile?.id
    );

    const specialistRole = getSpecialistRole(profile, entities);
    const isSpecialist = profile?.user_role === "specialist";

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    if (newAdvertisement.show) {
        return (
            <NewAdvertisement
                entities={entities}
                onClose={() =>
                    setNewAdvertisement({
                        show: false,
                        type: "banner",
                    })
                }
                type={newAdvertisement.type}
                role={specialistRole?.role}
                profile={profile}
            />
        );
    }

    return (
        <>
            <InDevelopmentNotification
                entities={entities}
                visible={showInDevelopmentNotification}
                setVisible={(visible) =>
                    setShowInDevelopmentNotification(visible)
                }
                isSpecialist={true}
            />
            <main>
                <div className="spec-page">
                    <div className="spec-page__container container">
                        <section className="spec-page__offers specialist-offers">
                            <h1 className="spec-page__heading title title--f46">
                                {entities?.advertising_link_value?.value}
                            </h1>
                            <ul className="specialist-offers__list">
                                <li className="specialist-offers__item">
                                    <article className="offer-card">
                                        <div className="offer-card__info">
                                            {specialistRole?.role ===
                                                "private person" && (
                                                <span
                                                    className={cn("tag", {
                                                        "tag--active":
                                                            myBanner.status ===
                                                            "active",
                                                        "tag--pending":
                                                            myBanner.status ===
                                                            "pending",
                                                    })}
                                                >
                                                    {myBanner.status ===
                                                    "active"
                                                        ? entities
                                                              ?.advertisement_active
                                                              ?.value
                                                        : myBanner.status ===
                                                          "pending"
                                                        ? entities
                                                              ?.advertisement_in_queue
                                                              ?.value
                                                        : entities
                                                              ?.advertisement_no_activated
                                                              ?.value}
                                                </span>
                                            )}
                                            <h2 className="offer-card__title title title--f24">
                                                {
                                                    entities
                                                        ?.advertisement_on_platform
                                                        ?.value
                                                }
                                            </h2>
                                            <p className="text">
                                                {
                                                    entities
                                                        ?.profile_add_services_photo
                                                        ?.value
                                                }
                                            </p>
                                        </div>
                                        <div className="offer-card__column">
                                            {(!myBanner?.status) && (
                                                <button
                                                    className="btn btn--green btn--rounded offer-card__btn"
                                                    type="button"
                                                    onClick={() =>
                                                        setNewAdvertisement({
                                                            show: true,
                                                            type: "banner",
                                                        })
                                                    }
                                                >
                                                    {
                                                        entities
                                                            ?.advertisement_activate
                                                            ?.value
                                                    }
                                                </button>
                                            )}
                                            {myBanner.status ===
                                                "active" && (
                                                <div className="description">
                                                    <dl>
                                                        <div className="description__row">
                                                            <dt>
                                                                {
                                                                    entities
                                                                        ?.advertisement_start
                                                                        ?.value
                                                                }
                                                            </dt>
                                                            <dd>
                                                                {
                                                                    myBanner.startsAt
                                                                }
                                                            </dd>
                                                        </div>
                                                        <div className="description__row">
                                                            <dt>
                                                                {
                                                                    entities
                                                                        ?.advertisement_end
                                                                        ?.value
                                                                }
                                                            </dt>
                                                            <dd>
                                                                {
                                                                    myBanner.endsAt
                                                                }
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                            )}
                                            {myBanner.status ===
                                                "pending" && (
                                                <div className="description">
                                                    <dl>
                                                        <div className="description__row">
                                                            <dt>
                                                                {
                                                                    entities
                                                                        ?.advertisement_queue_up_to
                                                                        ?.value
                                                                }
                                                            </dt>
                                                            <dd>
                                                                {
                                                                    myBanner.startsAt
                                                                }
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                            )}
                                        </div>
                                    </article>
                                </li>
                                <li className="specialist-offers__item">
                                    <article className="offer-card">
                                        <div className="offer-card__info">
                                            {specialistRole?.role ===
                                                "private person" && (
                                                <span
                                                    className={cn("tag", {
                                                        "tag--active":
                                                            myTop.status ===
                                                            "active",
                                                        "tag--pending":
                                                            myTop.status ===
                                                            "pending",
                                                    })}
                                                >
                                                    {myTop.status === "active"
                                                        ? entities
                                                              ?.advertisement_active
                                                              ?.value
                                                        : myTop.status ===
                                                          "pending"
                                                        ? entities
                                                              ?.advertisement_in_queue
                                                              ?.value
                                                        : entities
                                                              ?.advertisement_no_activated
                                                              ?.value}
                                                </span>
                                            )}
                                            <h2 className="offer-card__title title title--f24">
                                                {
                                                    entities
                                                        ?.advertisement_rise_to_top
                                                        ?.value
                                                }
                                            </h2>
                                            <p className="text">
                                                {
                                                    entities
                                                        ?.rise_in_the_list_price_block_description
                                                        ?.value
                                                }
                                            </p>
                                        </div>
                                        <div className="offer-card__column">
                                            {(!myTop?.status) && (
                                                <button
                                                    className="btn btn--green btn--rounded offer-card__btn"
                                                    type="button"
                                                    onClick={() =>
                                                        setNewAdvertisement({
                                                            show: true,
                                                            type: "top",
                                                        })
                                                    }
                                                >
                                                    {
                                                        entities
                                                            ?.advertisement_activate
                                                            ?.value
                                                    }
                                                </button>
                                            )}
                                            {myTop.status ===
                                                "active" && (
                                                <div className="description">
                                                    <dl>
                                                        <div className="description__row">
                                                            <dt>
                                                                {
                                                                    entities
                                                                        ?.advertisement_start
                                                                        ?.value
                                                                }
                                                            </dt>
                                                            <dd>
                                                                {
                                                                    myTop.startsAt
                                                                }
                                                            </dd>
                                                        </div>
                                                        <div className="description__row">
                                                            <dt>
                                                                {
                                                                    entities
                                                                        ?.advertisement_end
                                                                        ?.value
                                                                }
                                                            </dt>
                                                            <dd>
                                                                {
                                                                    myTop.endsAt
                                                                }
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                            )}
                                            {myTop.status ===
                                                "pending" && (
                                                <div className="description">
                                                    <dl>
                                                        <div className="description__row">
                                                            <dt>
                                                                {
                                                                    entities
                                                                        ?.advertisement_queue_up_to
                                                                        ?.value
                                                                }
                                                            </dt>
                                                            <dd>
                                                                {
                                                                    myTop.startsAt
                                                                }
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                            )}
                                        </div>
                                    </article>
                                </li>
                                <li className="specialist-offers__item">
                                    <article className="offer-card">
                                        <div className="offer-card__info">
                                            {specialistRole?.role ===
                                                "private person" && (
                                                <span
                                                    className={cn("tag", {
                                                        "tag--active":
                                                            myEmail.status ===
                                                            "active",
                                                        "tag--pending":
                                                            myEmail.status ===
                                                            "pending",
                                                    })}
                                                >
                                                    {myEmail.status === "active"
                                                        ? entities
                                                              ?.advertisement_active
                                                              ?.value
                                                        : myEmail.status ===
                                                          "pending"
                                                        ? entities
                                                              ?.advertisement_in_queue
                                                              ?.value
                                                        : entities
                                                              ?.advertisement_no_activated
                                                              ?.value}
                                                </span>
                                            )}
                                            <h2 className="offer-card__title title title--f24">
                                                {
                                                    entities
                                                        ?.advertisement_e_mailings
                                                        ?.value
                                                }
                                            </h2>
                                            <p className="text">
                                                {
                                                    entities
                                                        ?.mailing_price_block_description
                                                        ?.value
                                                }
                                            </p>
                                        </div>
                                        <div className="offer-card__column">
                                            {(!myEmail?.status) && (
                                                <button
                                                    className="btn btn--green btn--rounded offer-card__btn"
                                                    type="button"
                                                    onClick={() =>
                                                        setNewAdvertisement({
                                                            show: true,
                                                            type: "email",
                                                        })
                                                    }
                                                >
                                                    {
                                                        entities
                                                            ?.advertisement_activate
                                                            ?.value
                                                    }
                                                </button>
                                            )}
                                            {myEmail.status ===
                                                "active" && (
                                                <div className="description">
                                                    <dl>
                                                        <div className="description__row">
                                                            <dt>
                                                                {
                                                                    entities
                                                                        ?.advertisement_start
                                                                        ?.value
                                                                }
                                                            </dt>
                                                            <dd>
                                                                {
                                                                    myEmail.startsAt
                                                                }
                                                            </dd>
                                                        </div>
                                                        <div className="description__row">
                                                            <dt>
                                                                {
                                                                    entities
                                                                        ?.advertisement_end
                                                                        ?.value
                                                                }
                                                            </dt>
                                                            <dd>
                                                                {
                                                                    myEmail.endsAt
                                                                }
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                            )}
                                            {myEmail.status ===
                                                "pending" && (
                                                <div className="description">
                                                    <dl>
                                                        <div className="description__row">
                                                            <dt>
                                                                {
                                                                    entities
                                                                        ?.advertisement_queue_up_to
                                                                        ?.value
                                                                }
                                                            </dt>
                                                            <dd>
                                                                {
                                                                    myEmail.startsAt
                                                                }
                                                            </dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                            )}
                                        </div>
                                    </article>
                                </li>
                            </ul>
                            {specialistRole?.role === "manager" &&
                                !!companyEmployees.length && (
                                    <div className="specialist-offers__block">
                                        <h3 className="specialist-offers__subtitle title title--f24">
                                            {
                                                entities
                                                    ?.order_company_employees
                                                    ?.value
                                            }
                                        </h3>
                                        <div className="table-large">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            {
                                                                entities
                                                                    ?.advertisement_employer
                                                                    ?.value
                                                            }
                                                        </th>
                                                        <th scope="col">
                                                            {
                                                                entities
                                                                    ?.advertisement_on_platform
                                                                    ?.value
                                                            }
                                                        </th>
                                                        <th scope="col">
                                                            {
                                                                entities
                                                                    ?.advertisement_rise_to_top
                                                                    ?.value
                                                            }
                                                        </th>
                                                        <th scope="col">
                                                            {
                                                                entities
                                                                    ?.advertisement_e_mailings
                                                                    ?.value
                                                            }
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {companyEmployees.map(
                                                        ({
                                                            id,
                                                            name,
                                                            familyName,
                                                            banner,
                                                            top,
                                                            email,
                                                        }) => (
                                                            <tr key={id}>
                                                                <th scope="row">
                                                                    {`${name} ${familyName}`}
                                                                </th>
                                                                {(banner && (
                                                                    <td>
                                                                        <span
                                                                            className={cn(
                                                                                "tag",
                                                                                {
                                                                                    "tag--active":
                                                                                        banner?.status ===
                                                                                        "active",
                                                                                    "tag--pending":
                                                                                        banner?.status ===
                                                                                        "pending",
                                                                                }
                                                                            )}
                                                                        >
                                                                            {banner?.status ===
                                                                            "active"
                                                                                ? "Активна"
                                                                                : banner?.status ===
                                                                                  "pending"
                                                                                ? "В очереди"
                                                                                : ""}
                                                                        </span>
                                                                        <div className="description">
                                                                            {banner.status ===
                                                                                "active" && (
                                                                                <dl>
                                                                                    <div className="description__row">
                                                                                        <dt>
                                                                                            {
                                                                                                entities
                                                                                                    ?.advertisement_start
                                                                                                    ?.value
                                                                                            }
                                                                                        </dt>
                                                                                        <dd>
                                                                                            {
                                                                                                banner?.startsAt
                                                                                            }
                                                                                        </dd>
                                                                                    </div>
                                                                                    <div className="description__row">
                                                                                        <dt>
                                                                                            {
                                                                                                entities
                                                                                                    ?.advertisement_end
                                                                                                    ?.value
                                                                                            }
                                                                                        </dt>
                                                                                        <dd>
                                                                                            {
                                                                                                banner?.endsAt
                                                                                            }
                                                                                        </dd>
                                                                                    </div>
                                                                                </dl>
                                                                            )}
                                                                            {banner.status ===
                                                                                "pending" && (
                                                                                <dl>
                                                                                    <div className="description__row">
                                                                                        <dt>
                                                                                            {
                                                                                                entities
                                                                                                    ?.common_to
                                                                                                    ?.value
                                                                                            }
                                                                                        </dt>
                                                                                        <dd>
                                                                                            {
                                                                                                banner?.startsAt
                                                                                            }
                                                                                        </dd>
                                                                                    </div>
                                                                                </dl>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                )) || (
                                                                    <td className="is-empty" />
                                                                )}
                                                                {(top && (
                                                                    <td>
                                                                        <span
                                                                            className={cn(
                                                                                "tag",
                                                                                {
                                                                                    "tag--active":
                                                                                        top?.status ===
                                                                                        "active",
                                                                                    "tag--pending":
                                                                                        top?.status ===
                                                                                        "pending",
                                                                                }
                                                                            )}
                                                                        >
                                                                            {top?.status ===
                                                                            "active"
                                                                                ? entities
                                                                                      ?.advertisement_active
                                                                                      ?.value
                                                                                : top?.status ===
                                                                                  "pending"
                                                                                ? entities
                                                                                      ?.advertisement_in_queue
                                                                                      ?.value
                                                                                : ""}
                                                                        </span>
                                                                        <div className="description">
                                                                            {top?.status ===
                                                                                "active" && (
                                                                                <dl>
                                                                                    <div className="description__row">
                                                                                        <dt>
                                                                                            {
                                                                                                entities
                                                                                                    ?.advertisement_start
                                                                                                    ?.value
                                                                                            }
                                                                                        </dt>
                                                                                        <dd>
                                                                                            {
                                                                                                top?.startsAt
                                                                                            }
                                                                                        </dd>
                                                                                    </div>
                                                                                    <div className="description__row">
                                                                                        <dt>
                                                                                            {
                                                                                                entities
                                                                                                    ?.advertisement_end
                                                                                                    ?.value
                                                                                            }
                                                                                        </dt>
                                                                                        <dd>
                                                                                            {
                                                                                                top?.endsAt
                                                                                            }
                                                                                        </dd>
                                                                                    </div>
                                                                                </dl>
                                                                            )}
                                                                            {top?.status ===
                                                                                "pending" && (
                                                                                <dl>
                                                                                    <div className="description__row">
                                                                                        <dt>
                                                                                            {
                                                                                                entities
                                                                                                    ?.common_to
                                                                                                    ?.value
                                                                                            }
                                                                                        </dt>
                                                                                        <dd>
                                                                                            {
                                                                                                top?.startsAt
                                                                                            }
                                                                                        </dd>
                                                                                    </div>
                                                                                </dl>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                )) || (
                                                                    <td className="is-empty" />
                                                                )}
                                                                {(email && (
                                                                    <td>
                                                                        <span
                                                                            className={cn(
                                                                                "tag",
                                                                                {
                                                                                    "tag--active":
                                                                                        email?.status ===
                                                                                        "active",
                                                                                    "tag--pending":
                                                                                        email?.status ===
                                                                                        "pending",
                                                                                }
                                                                            )}
                                                                        >
                                                                            {email?.status ===
                                                                            "active"
                                                                                ? entities
                                                                                      ?.advertisement_active
                                                                                      ?.value
                                                                                : email?.status ===
                                                                                  "pending"
                                                                                ? entities
                                                                                      ?.advertisement_in_queue
                                                                                      ?.value
                                                                                : ""}
                                                                        </span>
                                                                        <div className="description">
                                                                            {email?.status ===
                                                                                "active" && (
                                                                                <dl>
                                                                                    <div className="description__row">
                                                                                        <dt>
                                                                                            {
                                                                                                entities
                                                                                                    ?.advertisement_start
                                                                                                    ?.value
                                                                                            }
                                                                                        </dt>
                                                                                        <dd>
                                                                                            {
                                                                                                email?.startsAt
                                                                                            }
                                                                                        </dd>
                                                                                    </div>
                                                                                    <div className="description__row">
                                                                                        <dt>
                                                                                            {
                                                                                                entities
                                                                                                    ?.advertisement_end
                                                                                                    ?.value
                                                                                            }
                                                                                        </dt>
                                                                                        <dd>
                                                                                            {
                                                                                                email?.endsAt
                                                                                            }
                                                                                        </dd>
                                                                                    </div>
                                                                                </dl>
                                                                            )}
                                                                            {email?.status ===
                                                                                "pending" && (
                                                                                <dl>
                                                                                    <div className="description__row">
                                                                                        <dt>
                                                                                            {
                                                                                                entities
                                                                                                    ?.common_to
                                                                                                    ?.value
                                                                                            }
                                                                                        </dt>
                                                                                        <dd>
                                                                                            {
                                                                                                email?.startsAt
                                                                                            }
                                                                                        </dd>
                                                                                    </div>
                                                                                </dl>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                )) || (
                                                                    <td className="is-empty" />
                                                                )}
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                        </section>
                    </div>
                </div>
            </main>
        </>
    );
};

import React, { ChangeEvent, useEffect } from "react";
import {
    checkReCaptcha,
    getDirectories,
} from "../../store/directories/selectors";
import Form from "../../components/Form/Form";
import { useStorePasswordMutation } from "../../store/auth/auth.api";
import { useNavigate } from "react-router-dom";
import InputPassword from "../../components/Form/InputPassword";
import { useActions } from "../../hooks/actions";
import { getRegisterInfo } from "../../store/registration/selectors";
import { useChangePasswordMutation, util } from "../../store/user/user.api";
import { ComponentProps } from "../../models/models";
import { useDispatch } from "react-redux";
import { getUrlAfterAuthorization } from "../../store/user/selectors";
import { ProfilePayload } from "../../models/userModels";

interface Props extends ComponentProps {
    changeStep: any;
    token: string;
    isChange?: boolean;
    isSpecialist?: boolean;
    prevStep?: string;
    profile?: ProfilePayload;
}

const EditPassword: React.FC<Props> = ({
    entities,
    changeStep,
    token,
    isChange,
    currentLanguage,
    isSpecialist = false,
    prevStep,
    profile,
}) => {
    const { password_confirmation, password } = getRegisterInfo();
    const navigate = useNavigate();
    const { updateField } = useActions();
    const { data: directories } = getDirectories();
    const site_key = directories?.recaptcha?.site_key;
    const [add, { isSuccess: isSuccessAdd, error: errorAdd }] =
        useStorePasswordMutation();
    const [change, { isSuccess: isSuccessChange, error: errorChange }] =
        useChangePasswordMutation();
    const isSuccess = isChange ? isSuccessChange : isSuccessAdd;
    const error = errorAdd || errorChange;
    const dispatch = useDispatch();
    const urlAfterRegistration = getUrlAfterAuthorization();

    const onSuccess = async () => {
        if (isChange) {
            return changeStep();
        }
        if (isSuccess) {
            await dispatch(util.invalidateTags(["Profile"]));

            if (isSpecialist) {
                changeStep("six");
                return;
            }
            const nextUrl =
                urlAfterRegistration ||
                `/${currentLanguage}/${profile?.user_role}/lk/${
                    profile?.user_role === "customer" ? "profile" : "orders"
                }`;
            navigate(nextUrl);
        }
    };

    const storePassword = (str: string) => {
        if (isChange) {
            return change({
                body: {
                    password,
                    password_confirmation,
                },
            });
        }
        add({
            body: {
                password,
                password_confirmation,
                "g-recaptcha-response": str,
            },
            currentLanguage,
            token,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            onSuccess();
        }
    }, [isSuccess]);

    const onSubmit = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (site_key) {
            checkReCaptcha(site_key, "store_password", storePassword);
        }
    };

    return (
        <div className="registration-form__content">
            <div className="registration-form__title-wrapper">
                <h1 className="title title--h1">
                    {isChange
                        ? entities?.profile_change_password_text?.value
                        : entities?.registration_set_password?.value}
                </h1>
            </div>
            <div className="form">
                <Form
                    entities={entities}
                    onSubmit={onSubmit}
                    error={error}
                    isSuccess={isSuccess}
                >
                    {(invalidFields, error) => (
                        <>
                            <div className="form__row">
                                <InputPassword
                                    onChange={(value: string) =>
                                        updateField({
                                            name: "password",
                                            value,
                                        })
                                    }
                                    id="password"
                                    password={password}
                                    placeholder={
                                        entities?.login_enter_password?.value ??
                                        ""
                                    }
                                    name="password"
                                    required
                                    inputClassName={["password-field"]}
                                    invalidFields={invalidFields}
                                    error={error}
                                    btnClassName="password-field__btn btn-reset"
                                />
                            </div>
                            <div className="form__row">
                                <InputPassword
                                    onChange={(value: string) =>
                                        updateField({
                                            name: "password_confirmation",
                                            value,
                                        })
                                    }
                                    id="password_confirmation"
                                    password={password_confirmation}
                                    placeholder={
                                        entities?.common_repeat_password
                                            ?.value ?? ""
                                    }
                                    name="password_confirmation"
                                    required
                                    inputClassName={["password-field"]}
                                    invalidFields={invalidFields}
                                    error={error}
                                    btnClassName="password-field__btn btn-reset"
                                />
                            </div>

                            <div className="form__row form__controls form__controls--full-width form__controls--not-fixed-sm">
                                <div
                                    className="btn btn btn--transparent registration-form__button"
                                    onClick={() =>
                                        changeStep(prevStep || "two")
                                    }
                                >
                                    {entities?.login_return_button?.value}
                                </div>
                                <button
                                    className="btn btn--transparent btn--bg-blue"
                                    type="submit"
                                >
                                    {entities?.login_continue_button?.value}
                                </button>
                            </div>
                        </>
                    )}
                </Form>
            </div>
        </div>
    );
};

export default EditPassword;

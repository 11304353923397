import React, { useEffect, useMemo, useState } from "react";
import Form from "../Form/Form";
import { toArray } from "../../lib/converters";
import classNames from "classnames";
import Icon from "../Icon";
import { Category } from "../../models/directoryModel";
import { endpoints } from "../../store/directories/directories.api";
import { map } from "lodash";
import { TranslationObject } from "../../models/translationModal";
import { useSetSpecialistServiceCategoryMutation } from "../../store/user/user.api";
import { ChangeStepType } from "../../pages/Registration/SpecialistNewService/SpecialistNewService";
import { useHistoryBackHandler } from "../../hooks/useHistoryBackHandler";
import Input from "../Form/Input";

interface SpecialistServicesProps {
    entities?: TranslationObject;
    changeStep: ChangeStepType;
    eventClearPreService?: () => unknown;
    setServicePayload?: React.Dispatch<React.SetStateAction<any>>;
    categoryId?: number | string;
    categoryName?: string;
}

export const SpecialistServices: React.FC<SpecialistServicesProps> = (
    props
) => {
    const { entities, setServicePayload, changeStep, categoryId, categoryName, eventClearPreService } = props || {};
    const [activeCategory, setActiveCategory] = useState<number | string | null>(categoryId ? categoryId : null);
    const [nameCategory, setNameCategory] = useState(categoryName || '');
    const { data: serviceCategories } =
        endpoints.getServiceCategories.useQuery();
    const [search, setSearch] = useState<string>("");
    const [items, setItems] = useState<Category[]>([]);
    const [foundCategories, setFoundCategories] = useState<Category[]>([]);

    const [
        setSpecialistServiceCategory,
        { isSuccess, isLoading, data: setSpecialistServiceCategoryResponse },
    ] = useSetSpecialistServiceCategoryMutation();

    useEffect(() => {
        const items = (map(serviceCategories) || []).filter((serviceCategory: Category) => activeCategory !== serviceCategory?.id);

        setItems(items);
        setFoundCategories(items);
    }, [serviceCategories, activeCategory]);

    const currentCategory = useMemo(() => {
        const category = items?.find(
            (category: Category) => category.id === activeCategory
        );

        const categoryName = category?.name;

        if (!categoryName) return;

        return categoryName;
    }, [activeCategory]);

    const isSubmitActive = !!((activeCategory) && (currentCategory || nameCategory) && !isLoading);

    useHistoryBackHandler(() => changeStep("category", true));

    useEffect(() => {
        const payload = setSpecialistServiceCategoryResponse?.payload;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (isSuccess && payload?.id) {
            setServicePayload?.(payload);
            changeStep("type");
        }
    }, [isSuccess, setSpecialistServiceCategoryResponse]);

    useEffect(() => {
        if (categoryId) {
            setActiveCategory(categoryId);
        }
    }, [categoryId]);

    useEffect(() => {
        if (nameCategory) {
            setNameCategory(nameCategory);
        }
    }, [nameCategory])

    const handleClick = (id: number | string) => {
        if (activeCategory === id) {
            setActiveCategory(null);
            setNameCategory('');
            return;
        }

        // Event clear pre service
        if (eventClearPreService) {
            eventClearPreService();
        }

        setSearch('');
        setActiveCategory(id);
    };

    const handleOnChange = (value: string) => {
        setSearch(value);

        setFoundCategories(
            items?.filter((item) =>
                item.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
            ) || items
        )
    };

    const onSubmitForm = (
        event: React.FormEvent<HTMLFormElement>,
        activeCategory: number | null,
        isSubmitActive: boolean
    ) => {
        event.preventDefault();

        if (!isSubmitActive) return;

        const requestData = {
            body: {
                service_category_id: activeCategory,
            },
        };

        setSpecialistServiceCategory(requestData);
    };

    return (
        <>
            <h1 className="visually-hidden">
                {entities?.specialist_details_choose_category_services?.value ||
                    ""}
            </h1>
            <div className="inner-container">
                <div className="new-order__wrapper new-order__wrapper--first">
                    <div className="new-order__header">
                        <h2 className="title title--f46">
                            {entities
                                ?.specialist_details_choose_category_services
                                ?.value || ""}
                        </h2>
                        <p className="subtitle">
                            {entities
                                ?.specialist_details_select_one_service_category
                                ?.value || ""}
                        </p>
                    </div>
                    <div className="specialist new-order__form form">
                        <Form
                            onSubmit={(
                                event: React.FormEvent<HTMLFormElement>
                            ) =>
                                onSubmitForm(
                                    event,
                                    activeCategory,
                                    isSubmitActive
                                )
                            }
                            entities={entities}
                        >
                            {(invalidFields, error) => {
                                const validationError =
                                    invalidFields &&
                                    error &&
                                    error?.errors["service_category_id"] &&
                                    toArray(
                                        error?.errors["service_category_id"]
                                    ).join(`, `);
                                return (
                                    <>
                                        <div className="new-order-start">
                                            <div className="form__row form__row--big-margin">
                                                <div
                                                    className={classNames(
                                                        "new-order-start__field new-order-start__field--selected",
                                                        {
                                                            "is-invalid":
                                                                error &&
                                                                !activeCategory,
                                                        }
                                                    )}
                                                >
                                                    {activeCategory && (
                                                        <span className="new-order-start__toggle is-active">
                                                            {currentCategory || nameCategory}
                                                            <button
                                                                className="btn"
                                                                type="button"
                                                                aria-label={
                                                                    entities
                                                                        ?.profile_destroy_button_ok
                                                                        ?.value
                                                                }
                                                                onClick={() =>
                                                                    handleClick(
                                                                        activeCategory
                                                                    )
                                                                }
                                                            >
                                                                <Icon
                                                                    type="delete-input"
                                                                    width={12}
                                                                    height={12}
                                                                />
                                                            </button>
                                                        </span>
                                                    )}
                                                    <Input
                                                        value={search}
                                                        onChange={handleOnChange}
                                                        classes={["new-order-start__search-field"]}
                                                        type="search"
                                                        placeholder=""
                                                    />
                                                </div>
                                                {validationError &&
                                                    !activeCategory && (
                                                        <div className="new-order-start__field-error">
                                                            {validationError}
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="form__row">
                                                {foundCategories.map((elem: Category) => (
                                                        <button
                                                            key={elem?.id}
                                                            className="new-order-start__toggle"
                                                            type="button"
                                                            onClick={() =>
                                                                handleClick(
                                                                    elem?.id
                                                                )
                                                            }
                                                        >
                                                            {elem?.name}
                                                        </button>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="form__row form__controls">
                                            <button
                                                onClick={() =>
                                                    changeStep("category", true)
                                                }
                                                className="btn form__back-link btn--transparent"
                                                type="button"
                                            >
                                                {entities?.common_back_button
                                                    ?.value || ""}
                                            </button>
                                            <button
                                                disabled={!isSubmitActive}
                                                className="btn btn--transparent btn--bg-green"
                                                type="submit"
                                            >
                                                {entities
                                                    ?.common_continue_button
                                                    ?.value || ""}
                                            </button>
                                        </div>
                                    </>
                                );
                            }}
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};

import React, { useEffect, useState } from "react";
import { endpoints } from "../../store/directories/directories.api";
import Form from "../Form/Form";
import { toArray } from "../../lib/converters";
import classNames from "classnames";
import Icon from "../Icon";
import { TranslationObject } from "../../models/translationModal";
import { useSetSpecialistServiceSubTypeMutation } from "../../store/user/user.api";
import { ChangeStepType } from "../../pages/Registration/SpecialistNewService/SpecialistNewService";
import { useHistoryBackHandler } from "../../hooks/useHistoryBackHandler";
import Breadcrumbs from "../../components/Breadcrumbs";
import { IProfileTag } from "../../models/orderModel";
import { SubTypesPayload } from "../../models/directoryModel";
import ConfirmSelectAllSubtypesModal from "../Modals/ConfirmSelectAllSubtypesModal";

interface ServiceCategory {
    id: number;
    name: string;
    is_active: boolean;
}

interface SpecialistServiceSubTypesProps {
    entities?: TranslationObject;
    changeStep: ChangeStepType;
    eventClearPreService?: () => unknown;
    setServicePayload?: React.Dispatch<React.SetStateAction<any>>;
    activeSpecialistServices: number | null;
    activeCategory: number | null;
    activeCategoryName?: string | null;
    activeType: number | string | null;
    activeTypeName: string | null;
    activeTag?: IProfileTag;
}

export const SpecialistServiceSubTypes: React.FC<
    SpecialistServiceSubTypesProps
> = (props) => {
    const {
        entities,
        changeStep,
        eventClearPreService,
        setServicePayload,
        activeSpecialistServices,
        // activeCategory,
        activeCategoryName,
        activeType,
        activeTypeName,
        activeTag,
    } = props || {};

    const [activeSubTypes, setActiveSubTypes] = useState<number[] | string>([]);
    const [foundItems, setFoundItems] = useState<SubTypesPayload[] | null>(null);
    const [visible, setVisible] = useState<boolean>(false);

    const { data: serviceSubTypes } = endpoints.getServiceSubTypes.useQuery(
        {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            id: activeType,
        },
        {
            skip: typeof activeType !== "number" || Number.isNaN(activeType),
        }
    );

    const [
        setSpecialistServiceSubType,
        { isSuccess, data: setSpecialistServiceSubTypeResponse },
    ] = useSetSpecialistServiceSubTypeMutation();

    useHistoryBackHandler(() => changeStep("type"));

    useEffect(() => {
        setFoundItems(foundItems !== null ? foundItems : serviceSubTypes?.length ? serviceSubTypes : null);
    }, [serviceSubTypes, activeType]);

    useEffect(() => {
        const payload = setSpecialistServiceSubTypeResponse?.payload;
        const { service_sub_types } = payload || {};
        if (isSuccess && service_sub_types?.length) {
            setServicePayload?.(payload);
            changeStep("description");
        }
    }, [isSuccess, setSpecialistServiceSubTypeResponse]);

    useEffect(() => {
        if (activeTag && activeTag?.id) {
            if (Array.isArray(activeSubTypes)) {
                setActiveSubTypes([...activeSubTypes, Number(activeTag?.id)])
            }
        }
    }, [activeTag]);

    // const isSubmitActive =
    //     !!activeCategory &&
    //     !!activeSpecialistServices &&
    //     !!activeType &&
    //     activeSubTypes?.length &&
    //     !isLoading;

    const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!activeSubTypes.length) {
            setVisible(true);
            return false;
        }

        submitForm(activeSubTypes);
    };

    const handleClick = (id: number) => {
        const items =
            Array.isArray(activeSubTypes) && activeSubTypes.includes(id)
                ? activeSubTypes.filter((elem) => elem !== id) || []
                : typeof activeSubTypes === "string"
                ? [id]
                : activeSubTypes?.concat(id) || [];
        setActiveSubTypes(items.length < 1 ? [] : items);
        setFoundItems(serviceSubTypes?.length ? serviceSubTypes : null);

        if (eventClearPreService) {
            eventClearPreService();
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        setActiveSubTypes(target.value);

        const filtered =  serviceSubTypes?.filter((item) => {
            const subStr = target.value.toLocaleLowerCase();

            return (
                item.name.toLocaleLowerCase().includes(subStr) ||
                item?.keywords?.toLocaleLowerCase()?.includes(subStr)
            );
        });

        setFoundItems(target.value && filtered?.length ? filtered : (serviceSubTypes || []));
    };

    const handleSelectAllSubtypes = () => {
        const allSubtypes = serviceSubTypes?.map(item => item.id) || [];
        submitForm(allSubtypes);
        setActiveSubTypes(allSubtypes);
    }

    const submitForm = (subtypes: string | number[]) => {
        const typeActiveSubTypes = Array.isArray(subtypes)
            ? "service_sub_type_ids"
            : "custom_service_sub_type";

        const requestData = {
            specialistService: activeSpecialistServices,
            body: {
                [typeActiveSubTypes]: subtypes,
            },
        };

        setSpecialistServiceSubType(requestData);
    }

    const crumbs = [
        {
            name: activeCategoryName || entities?.service_category?.value,
            link: 'lk/profile?type=service&step=category'
        },
        {
            name: activeTypeName || entities?.type_of_service?.value,
            link: 'lk/profile?type=service&step=type'
        },
        {
            active: true,
            name: entities?.subtype_of_service?.value
        }
    ]

    return (
        <>
            <h1 className="visually-hidden">
                {entities?.specialist_details_select_service_subtypes?.value}
            </h1>
            <div className="inner-container">
                <div className="new-order__wrapper">
                    <Breadcrumbs
                        crumbs={crumbs}
                        isSpecialist={true}
                    />
                    <div className="new-order__header">
                        <h2 className="title title--f46">
                            {
                                entities
                                    ?.specialist_details_select_service_subtypes
                                    ?.value
                            }
                        </h2>
                        <p className="subtitle">
                            {
                                entities
                                    ?.specialist_details_select_one_service_subtypes
                                    ?.value
                            }
                        </p>
                    </div>
                    <div className="specialist new-order__form form">
                        <Form onSubmit={onSubmitForm} entities={entities}>
                            {(invalidFields, error) => {
                                const validationError =
                                    invalidFields &&
                                    error &&
                                    error?.errors["service_category_id"] &&
                                    toArray(
                                        error?.errors["service_category_id"]
                                    ).join(`, `);
                                return (
                                    <>
                                        <div className="new-order-start">
                                            <div className="form__row form__row--big-margin">
                                                <div
                                                    className={classNames(
                                                        "new-order-start__field new-order-start__field--selected",
                                                        {
                                                            "is-invalid":
                                                                error &&
                                                                !activeType,
                                                        }
                                                    )}
                                                >
                                                    {Array.isArray(
                                                        activeSubTypes
                                                    ) &&
                                                        activeSubTypes.map(
                                                            (subType) => (
                                                                <span
                                                                    className="new-order-start__toggle is-active"
                                                                    key={
                                                                        subType
                                                                    }
                                                                >
                                                                    {
                                                                        serviceSubTypes?.find(
                                                                            (
                                                                                elem
                                                                            ) =>
                                                                                elem.id ==
                                                                                subType
                                                                        )?.name
                                                                    }
                                                                    <button
                                                                        className="btn"
                                                                        type="button"
                                                                        aria-label="удалить"
                                                                        onClick={() =>
                                                                            handleClick(
                                                                                subType
                                                                            )
                                                                        }
                                                                    >
                                                                        <Icon
                                                                            type="delete-input"
                                                                            width={
                                                                                12
                                                                            }
                                                                            height={
                                                                                12
                                                                            }
                                                                        />
                                                                    </button>
                                                                </span>
                                                            )
                                                        )}
                                                    <input
                                                        type="search"
                                                        onChange={handleChange}
                                                        value={
                                                            typeof activeSubTypes ===
                                                            "string"
                                                                ? activeSubTypes
                                                                : ""
                                                        }
                                                    />
                                                </div>
                                                {validationError &&
                                                    !activeSubTypes && (
                                                        <div className="new-order-start__field-error">
                                                            {validationError}
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="form__row">
                                                {foundItems
                                                    ?.filter((serviceType) =>
                                                        Array.isArray(
                                                            activeSubTypes
                                                        )
                                                            ? activeSubTypes.indexOf(
                                                                  serviceType.id
                                                              ) === -1
                                                            : serviceType
                                                    )?.map(
                                                        (
                                                            elem: ServiceCategory
                                                        ) => (
                                                            <button
                                                                key={elem?.id}
                                                                className="new-order-start__toggle"
                                                                type="button"
                                                                onClick={() =>
                                                                    handleClick(
                                                                        elem?.id
                                                                    )
                                                                }
                                                            >
                                                                {elem?.name}
                                                            </button>
                                                        )
                                                    )}
                                            </div>
                                        </div>
                                        <div className="form__row form__controls">
                                            <button
                                                onClick={() =>
                                                    changeStep("type")
                                                }
                                                className="btn form__back-link btn--transparent"
                                                type="button"
                                            >
                                                {entities?.common_back_button
                                                    ?.value || ""}
                                            </button>
                                            <button
                                                // disabled={!isSubmitActive}
                                                className="btn btn--transparent btn--bg-green"
                                                type="submit"
                                            >
                                                {entities
                                                    ?.common_continue_button
                                                    ?.value || ""}
                                            </button>
                                        </div>
                                    </>
                                );
                            }}
                        </Form>
                    </div>

                    <ConfirmSelectAllSubtypesModal
                        entities={entities}
                        visible={visible}
                        changeVisible={setVisible}
                        handleChange={handleSelectAllSubtypes}
                    />
                </div>
            </div>
        </>
    );
};

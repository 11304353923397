import React, { ReactElement, useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import cn from "classnames";

import Icon from "../Icon";

export const BODY_CLASS_OPENED = "is-active";

const Modal: React.FC<{
    visible: boolean;
    onClose: any;
    title?: string;
    description?: string;
    modificators?: string[];
    children: ReactElement<any, any>;
    modalClasses?: string;
    asterisk?: boolean;
}> = (props: any) => {
    const {
        children,
        modificators,
        onClose,
        visible,
        title,
        description,
        modalClasses,
        asterisk
    } = props;

    const [showModal, setShowModal] = useState<boolean>(false);
    const container = document.getElementById('root') || document.body

    useEffect(() => {
        if (visible) {
            setShowModal(true);
            hiddenOverflow(true);
        } else {
            hiddenOverflow(false);
            setShowModal(false);
        }
    }, [visible]);

    const hiddenOverflow = (showHidden) => {
        const body = document.documentElement;
        if (body) {
            if (showHidden) {
                body.style.overflow = "hidden";
            } else {
                body.style.overflow = "";
            }
        }
    };

    const handleClose = () => {
        hiddenOverflow(false);
        setShowModal(false);
        onClose();
    };

    if (!showModal) {
        return null;
    }

    return ReactDOM.createPortal(
        <dialog
            id="dialog"
            onClick={(event: any) => event.stopPropagation()}
            open={showModal}
            className={cn("modal", {
                [BODY_CLASS_OPENED]: showModal,
                [modificators?.join(" ")]: modificators?.length,
            })}
        >
            <div
                className={cn("modal__wrapper", {
                    [`${modalClasses}`]: !!modalClasses,
                })}
            >
                <div className="modal__overlay" onClick={handleClose} />
                <div className="modal__content">
                    <div className="modal__content-title">
                        {title}{title && asterisk && <span className="asterisk">*</span>}
                    </div>

                    <p className="modal__description" dangerouslySetInnerHTML={{ __html: description || "" }} />

                    {children}

                    <button
                        className="modal__close-btn"
                        type="button"
                        onClick={handleClose}
                    >
                        <Icon type="close" width={24} height={24} />
                    </button>
                </div>
            </div>
        </dialog>,
        container
    );
};

export default Modal;
